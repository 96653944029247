import { WeChatIcon } from 'components/icons/icons';
import { Colors } from 'src/theme/colors';
import { HeadingSecondary } from 'components/typography/heading';
import { TextLight } from 'components/typography/text';
import React from 'react';
import { Column } from 'native-base';
import { useTranslation } from 'react-i18next';

export const EmptyChat = () => {
  const { t } = useTranslation();

  return (
    <Column
      flex={1}
      space={4}
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <WeChatIcon color={Colors.primaryLight} size={180} />
      <HeadingSecondary>{t('chat.noMessagesTitle')}</HeadingSecondary>
      <TextLight textAlign="center">{t('chat.noMessagesSubtitle')}</TextLight>
    </Column>
  );
};
