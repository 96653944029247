export enum EGender {
  male,
  female,
  neutral,
}

export enum ThreadType {
  Exercise = 'exercise',
  Muscle = 'muscle',
  Problem = 'problem',
}

export enum FiltersType {
  Exercises = 'exercises',
  Muscles = 'muscles',
}

export enum PremiumType {
  Exercises = 'exercises',
  Muscles = 'muscles',
  Premium = 'premium',
}

export type ExerciseType = 'free' | 'overlay';
