import {
  CancelAccountIcon,
  DarkIcon,
  EnglishIcon,
  FriendsIcon,
  IconProps,
  LightIcon,
  LogoutIcon,
  PaymentIcon,
  PrivacyIcon,
  SlovakIcon,
} from 'components/icons/icons';
import { Routes } from 'src/navigation/routes';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Column, useColorMode } from 'native-base';
import { Colors } from 'src/theme/colors';
import { useAuthUser, useUserRelationships } from 'src/state/state';
import { DarkColumn } from 'components/layout/views';
import { isWeb } from '../utils/constants';
import { useLanguage } from '../hooks/useLanguage';
import { ButtonItem, LinkItem } from 'components/profile/types';
import { ProfileButton } from 'components/profile/ProfileButton';
import { ProfileLink } from 'components/profile/ProfileLink';

const iconProps: IconProps = {
  primary: true,
  size: 6,
  color: Colors.primary,
};

type Props = {
  onDelete: () => void;
  onLogout: () => void;
  logoutLoading: boolean;
};
export const ProfileMenu = ({ onDelete, onLogout, logoutLoading }: Props) => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const { colorMode, toggleColorMode } = useColorMode();
  const relationships = useUserRelationships();
  const requestsCount = relationships?.pendingFriendRequests.length || 0;
  const { currentLanguage, changeLanguage } = useLanguage();

  if (!user) {
    return null;
  }

  // const changeColorMode = () => {
  //   mmkv.set(COLOR_MODE_KEY, colorMode === 'dark' ? 'light' : 'dark');
  //   toggleColorMode();
  // };

  const links: LinkItem[] = [
    {
      title: t('routes.friends'),
      icon: <FriendsIcon {...iconProps} size={6} />,
      target: Routes.Friends,
      badgeNumber: requestsCount,
    },
    {
      title: t('routes.subscription'),
      icon: <PaymentIcon {...iconProps} />,
      target: Routes.Subscription,
    },
    ...(isWeb
      ? []
      : ([
          {
            title: t('routes.privacy'),
            icon: <PrivacyIcon {...iconProps} />,
            target: Routes.PrivacyPolicy,
          },
          {
            title: t('routes.termsOfUse'),
            icon: <PrivacyIcon {...iconProps} />,
            target: Routes.TermsOfUse,
          },
        ] as LinkItem[])),
  ];

  const languageButton =
    currentLanguage === 'en'
      ? {
          title: t('general.english'),
          icon: <EnglishIcon />,
          onPress: () => changeLanguage('sk'),
        }
      : {
          title: t('general.slovak'),
          icon: <SlovakIcon />,
          onPress: () => changeLanguage('en'),
        };

  const buttons: ButtonItem[] = [
    ...(isWeb ? [] : [languageButton]),
    {
      title:
        colorMode === 'dark' ? t('general.darkMode') : t('general.lightMode'),
      icon:
        colorMode === 'dark' ? (
          <DarkIcon color={Colors.bgWhite} />
        ) : (
          <LightIcon color={Colors.black} />
        ),
      onPress: toggleColorMode,
    },
    {
      title: t('general.deleteAccount'),
      icon: <CancelAccountIcon {...iconProps} color={Colors.error} />,
      onPress: onDelete,
      color: Colors.error,
    },
    {
      title: t('general.logout'),
      icon: <LogoutIcon {...iconProps} color={Colors.warning} />,
      onPress: onLogout,
      color: Colors.warning,
      loading: logoutLoading,
    },
  ];

  return (
    <Column w="full">
      <DarkColumn borderRadius={10}>
        {links.map((item, index) => (
          <ProfileLink
            item={item}
            key={item.title}
            showDivider={index < links.length - 1}
          />
        ))}
      </DarkColumn>
      <Column space={4} mt={4}>
        {buttons.map((item) => (
          <ProfileButton key={item.title} item={item} />
        ))}
      </Column>
    </Column>
  );
};
