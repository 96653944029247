import { Animated, StyleSheet } from 'react-native';
import { useColorMode } from 'native-base';
import Svg, { Path } from 'react-native-svg';
import { Colors } from 'src/theme/colors';
import React from 'react';

const AnimatedSvg = Animated.createAnimatedComponent(Svg);

export const TabBarSvg = (props: { translateX: Animated.Value }) => {
  const { colorMode } = useColorMode();
  return (
    <AnimatedSvg
      width={110}
      height={60}
      viewBox="0 0 110 60"
      style={[
        styles.activeBackground,
        {
          transform: [{ translateX: props.translateX }],
        },
      ]}
    >
      <Path
        fill={colorMode === 'light' ? Colors.bgLight : Colors.black}
        d="M20 0H0c11.046 0 20 8.953 20 20v5c0 19.33 15.67 35 35 35s35-15.67 35-35v-5c0-11.045 8.954-20 20-20H20z"
      />
    </AnimatedSvg>
  );
};

const styles = StyleSheet.create({
  activeBackground: {
    position: 'absolute',
  },
});
