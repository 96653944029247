import { ImageSourcePropType } from 'react-native';

export const muscleGroupImages: Record<string, ImageSourcePropType> = {
  neck: require('../../../assets/muscles/muscleGroups/neck.jpg'),
  clavicleF: require('../../../assets/muscles/muscleGroups/clavicleF.jpg'),
  upperArmF: require('../../../assets/muscles/muscleGroups/upperArmF.jpg'),
  elbowF: require('../../../assets/muscles/muscleGroups/elbowF.jpg'),
  forearmF: require('../../../assets/muscles/muscleGroups/forearmF.jpg'),
  chestF: require('../../../assets/muscles/muscleGroups/chestF.jpg'),
  ribsF: require('../../../assets/muscles/muscleGroups/ribsF.jpg'),
  bellyF: require('../../../assets/muscles/muscleGroups/bellyF.jpg'),
  pelvisF: require('../../../assets/muscles/muscleGroups/pelvisF.jpg'),
  thighF: require('../../../assets/muscles/muscleGroups/thighF.jpg'),
  lowLegF: require('../../../assets/muscles/muscleGroups/lowLegF.jpg'),
  backMidB: require('../../../assets/muscles/muscleGroups/backMidB.jpg'),
  scapulaB: require('../../../assets/muscles/muscleGroups/scapulaB.jpg'),
  upperArmB: require('../../../assets/muscles/muscleGroups/upperArmB.jpg'),
  elbowB: require('../../../assets/muscles/muscleGroups/elbowB.jpg'),
  forearmB: require('../../../assets/muscles/muscleGroups/forearmB.jpg'),
  ribsB: require('../../../assets/muscles/muscleGroups/ribsB.jpg'),
  backLowB: require('../../../assets/muscles/muscleGroups/backLowB.jpg'),
  pelvisB: require('../../../assets/muscles/muscleGroups/pelvisB.jpg'),
  thighB: require('../../../assets/muscles/muscleGroups/thighB.jpg'),
  lowLegB: require('../../../assets/muscles/muscleGroups/lowLegB.jpg'),
  footB: require('../../../assets/muscles/muscleGroups/footB.jpg'),
  footF: require('../../../assets/muscles/muscleGroups/footF.jpg'),
};
