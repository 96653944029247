import { Column, Row, Text, useBreakpointValue } from 'native-base';
import { Colors } from 'src/theme/colors';
import { Logo } from 'components/Logo';
import { Link } from 'components/links/Link';
import { Routes } from 'src/navigation/routes';
import { useTranslation } from 'react-i18next';
import { InstagramIcon, YoutubeIcon } from 'components/icons/icons';
import { ExternalLink } from 'components/links/ExternalLink';
import { useMenuDimensions } from '../../hooks/useMenuDimensions';
import AppStoreSk from '../../../assets/badges/appStoreSk.png';
import AppStoreEn from '../../../assets/badges/appStoreEn.png';
import GooglePlaySk from '../../../assets/badges/googlePlaySk.png';
import GooglePlayEn from '../../../assets/badges/googlePlayEn.png';
import { isSlovak } from '../../utils/language';
import {
  APP_STORE_URL,
  INSTAGRAM_EN,
  INSTAGRAM_SK,
  PLAY_STORE_URL,
  YOUTUBE_EN,
  YOUTUBE_SK,
} from '../../links';

type Content = {
  youtube: string;
  instagram: string;
  appStore: string;
  googlePlay: string;
};
const content: Record<string, Content> = {
  en: {
    youtube: YOUTUBE_EN,
    instagram: INSTAGRAM_EN,
    appStore: AppStoreEn,
    googlePlay: GooglePlayEn,
  },
  sk: {
    youtube: YOUTUBE_SK,
    instagram: INSTAGRAM_SK,
    appStore: AppStoreSk,
    googlePlay: GooglePlaySk,
  },
};

export const Footer = () => {
  const { t } = useTranslation();
  const { paddingBottom } = useMenuDimensions();
  const isSmall = useBreakpointValue([true, true, false]);
  const isLarge = useBreakpointValue({ base: false, xl: true });
  const language = isSlovak() ? 'sk' : 'en';

  const logo = <Logo size={20} />;
  const links = (
    <Column space={4} alignItems={['center', 'center', 'flex-start']}>
      <Link to={{ screen: Routes.PrivacyPolicy }} color={Colors.bgWhite}>
        {t('routes.privacy')}
      </Link>
      <Link to={{ screen: Routes.TermsOfUse }} color={Colors.bgWhite}>
        {t('routes.termsOfUse')}
      </Link>
      <Link to={{ screen: Routes.Contact }} color={Colors.bgWhite}>
        {t('routes.contact')}
      </Link>
    </Column>
  );
  const socialIcons = (
    <Column
      space={4}
      alignItems={{ base: 'center', md: 'flex-start' }}
      alignSelf={{ base: 'center', md: 'flex-start' }}
    >
      <Text color={Colors.bgWhite}>{t('footer.followMe')}</Text>
      <Row space={[4, 4, 0.5, 4]}>
        <ExternalLink ml={0} isExternal href={content[language].instagram}>
          <InstagramIcon primary size={8} />
        </ExternalLink>
        <ExternalLink isExternal href={content[language].youtube}>
          <YoutubeIcon primary size={8} />
        </ExternalLink>
      </Row>
    </Column>
  );

  const stores = (
    <Row space={4}>
      <ExternalLink isExternal href={PLAY_STORE_URL}>
        <img
          src={content[language].googlePlay}
          alt="Google Play"
          width="auto"
          height={50}
        />
      </ExternalLink>
      <ExternalLink isExternal href={APP_STORE_URL}>
        <img
          src={content[language].appStore}
          alt="App Store"
          width="auto"
          height={50}
        />
      </ExternalLink>
    </Row>
  );

  if (isSmall) {
    return (
      <Column
        p={10}
        pb={`${paddingBottom + 40}px`}
        bg={Colors.black}
        _dark={{ bg: Colors.bgDark }}
        space={8}
        justifyContent="center"
        alignItems="center"
      >
        {logo}
        {links}
        {socialIcons}
        {stores}
      </Column>
    );
  }

  return (
    <Column
      p={10}
      bg={Colors.black}
      _dark={{ bg: Colors.bgDark }}
      space={12}
      justifyContent="center"
      alignItems="center"
    >
      <Row space={[6, 6, 6, 16]} alignItems="center">
        {logo}
        {links}
        {socialIcons}
        {isLarge && stores}
      </Row>

      {isLarge || <Row>{stores}</Row>}
    </Column>
  );
};
