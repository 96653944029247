import * as React from 'react';
import { useEffect } from 'react';
import { useColorMode } from 'native-base';
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
} from '@react-navigation/native';
import LinkingConfiguration from 'src/navigation/LinkingConfiguration';
import { useAuth } from 'src/hooks/useAuth';
import { StatusBar } from 'expo-status-bar';
import { navigationRef } from 'src/navigation/navigationConfig';
import { isWeb } from 'src/utils/constants';
import { useRecoilState } from 'recoil';
import { currentRouteState, useAuthUser } from 'src/state/state';
import { BottomTabNavigator } from './BottomTabNavigator';
import { AppVersionModal } from 'components/AppVersionModal';
import { logScreenView } from 'src/firebase/analytics';
import { useConversations } from 'src/hooks/useConversations';
import { WebLayout } from 'components/layout/WebLayout';
import { useCheckAppVersion } from '../hooks/useCheckAppVersion';
import { useExercises } from '../hooks/useExercises';
import { useLanguage } from '../hooks/useLanguage';
import * as SplashScreen from 'expo-splash-screen';
import { UserNameModal } from 'components/modals/UserNameModal';

export const Navigation = () => {
  useAuth();
  useLanguage();
  const { colorMode } = useColorMode();
  const [route, setRoute] = useRecoilState(currentRouteState);
  const user = useAuthUser();
  const isAppVersionValid = useCheckAppVersion();
  const { fetchConversations } = useConversations();
  const { fetchAllExercises } = useExercises();

  useEffect(() => {
    return fetchConversations();
  }, [fetchConversations]);

  useEffect(() => {
    fetchAllExercises();
  }, [fetchAllExercises]);

  if (!isAppVersionValid) {
    SplashScreen.hideAsync();
    return <AppVersionModal isOpen />;
  }

  if (user === null) {
    return null;
  }

  return (
    <NavigationContainer
      // @ts-ignore
      ref={navigationRef}
      onReady={() => {
        setRoute(navigationRef.current?.getCurrentRoute()?.name);
      }}
      onStateChange={() => {
        const prevRoute = route;
        const newRoute = navigationRef.current?.getCurrentRoute()?.name;
        if (prevRoute !== newRoute) {
          setRoute(navigationRef.current?.getCurrentRoute()?.name);
          logScreenView(newRoute);
        }
      }}
      linking={LinkingConfiguration}
      theme={colorMode === 'dark' ? DarkTheme : DefaultTheme}
    >
      <StatusBar style={colorMode === 'dark' ? 'light' : 'dark'} />
      {isWeb ? <WebLayout /> : <BottomTabNavigator />}
      <UserNameModal isOpen={!!(user && !user.name)} />
    </NavigationContainer>
  );
};
