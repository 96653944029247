import { Column, Row, Spinner, useBreakpointValue } from 'native-base';
import { PropsWithChildren } from 'react';
import { useHeaderHeight } from '@react-navigation/elements';
import { HeadingPrimary } from 'components/typography/heading';
import { isWeb } from 'src/utils/constants';

type Props = {
  loading?: boolean;
  transparentHeader: boolean;
  title?: string;
  rightButton?: React.ReactNode;
  mainButton?: React.ReactNode;
};

export const ScreenContent = ({
  loading,
  children,
  transparentHeader,
  title,
  rightButton,
  mainButton,
}: PropsWithChildren<Props>) => {
  const headerHeight = useHeaderHeight();
  const showTitle = isWeb && !!title;
  const showIconButton = useBreakpointValue([true, true, false]);

  return (
    <Column flex={1} pt={transparentHeader ? `${headerHeight}px` : 0}>
      {showTitle && (
        <Row mb={5} justifyContent="space-between" alignItems="center">
          <HeadingPrimary>{title}</HeadingPrimary>
          {showIconButton && rightButton}
        </Row>
      )}
      {loading ? (
        <Spinner m="auto" />
      ) : (
        <Column space={4} flex={1}>
          {isWeb && !showIconButton && mainButton}
          {children}
        </Column>
      )}
    </Column>
  );
};
