import { Column, Text } from 'native-base';
import { GoogleIcon, UserOutlineIcon } from 'components/icons/icons';
import { HeadingSecondary } from 'components/typography/heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/theme/colors';
import { Link } from 'components/links/Link';
import { Routes } from 'src/navigation/routes';
import { isIOS } from '../../utils/constants';
import { AppleLogin } from 'components/dashboard/AppleLogin';
import { LanguageSwitcher } from 'components/LanguageSwitcher';
import { LoginButton } from 'components/dashboard/LoginButton';

type Props = {
  onLogin: () => void;
};

export const LoginInfo = ({ onLogin }: Props) => {
  const { t } = useTranslation();

  return (
    <Column
      alignItems="center"
      flex={1}
      justifyContent="center"
      space={8}
      p={4}
    >
      <UserOutlineIcon color={Colors.primaryLight} size={100} />
      <HeadingSecondary textAlign="center">
        {t('dashboard.loginSubtitle')}
      </HeadingSecondary>
      <Column alignItems="center" space={2}>
        <Text textAlign="center">{t('dashboard.loginDescription')}</Text>
        <Link to={{ screen: Routes.PrivacyPolicy }}>
          <Text color={Colors.primary}>{t('routes.privacy')}</Text>
        </Link>
      </Column>
      <Column space={4}>
        <LoginButton
          onPress={onLogin}
          leftIcon={<GoogleIcon color={Colors.black} />}
        >
          {t('general.googleLogin')}
        </LoginButton>
        {isIOS && <AppleLogin />}
      </Column>
      <Column space={4}>
        <LanguageSwitcher />
      </Column>
    </Column>
  );
};
