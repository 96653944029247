import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  #root {
    overflow: hidden;
  }

  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6b6b6b;
  }
`;
