import { ConversationsList } from 'components/chat/ConversationsList';
import { Routes } from 'src/navigation/routes';
import { useTranslation } from 'react-i18next';
import { AppWebStackParamList } from 'src/navigation/types/appWebStack';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { Column } from 'native-base';
import { HeadingPrimary } from 'components/typography/heading';
import { isWeb } from 'src/utils/constants';
import { useCallback } from 'react';

export const ConversationsScreen = () => {
  const { t } = useTranslation();

  const getLink = useCallback(
    (conversationId: string): To<AppWebStackParamList> => {
      return {
        screen: Routes.ConversationThreads,
        params: { conversationId },
      };
    },
    []
  );

  return (
    <Column flex={1}>
      {isWeb && <HeadingPrimary p={4}>{t('routes.chat')}</HeadingPrimary>}
      <ConversationsList getLink={getLink} />
    </Column>
  );
};
