import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MusclesStackScreenProps } from '../../navigation/types/musclesStack';
import { Routes } from '../../navigation/routes';
import { IMuscle } from '../../@types/api';
import { MUSCLES_LIMIT } from '../../firebase/constants';
import { IconButton, Pressable } from 'native-base';
import { NumberBadge } from 'components/NumberBadge';
import { BodyIcon, FilterIcon, GridIcon } from 'components/icons/icons';
import { Colors } from '../../theme/colors';
import { FiltersModal } from 'components/modals/FiltersModal';
import { MusclesList } from 'components/muscles/MusclesList';
import { FiltersType } from '../../@types/enums';
import { getMusclesConfig } from '../../data/muscles/musclesConfig';
import { useIsPremium } from 'hooks/useIsPremium';
import { BodyMuscles } from 'components/muscles/BodyMuscles';
import { useMMKVBoolean } from 'react-native-mmkv';
import { MUSCLES_SHOW_BODY } from '../../utils/storage';
import { isWeb } from '../../utils/constants';
import { Screen } from 'components/layout/Screen';
import { SCREEN_MAX_WIDTH } from '../../theme/constants';

type Props = MusclesStackScreenProps<Routes.Muscles>;
type Filters = Record<string, boolean>;

export const MusclesScreen = ({ navigation, route }: Props) => {
  const { t } = useTranslation();
  const { hasPremiumMuscles } = useIsPremium();
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Filters>({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const musclesConfig = getMusclesConfig();
  const [showBody = isWeb, setShowBody] = useMMKVBoolean(MUSCLES_SHOW_BODY);

  useEffect(() => {
    if (route.params?.type) {
      setShowBody(route.params?.type === 'body');
    }
  }, [route.params?.type, setShowBody]);

  const filteredMuscles = Object.values(musclesConfig)
    .filter((muscle: IMuscle) =>
      Object.keys(selectedFilters)
        .filter((filter) => !!filter)
        .every((keyword: string) => {
          if (Object.keys(selectedFilters).includes('origin')) {
            return muscle.originJoints.concat(['origin']).includes(keyword);
          } else if (Object.keys(selectedFilters).includes('insertion')) {
            return muscle.insertJoints.concat(['insertion']).includes(keyword);
          } else {
            return muscle.originJoints
              .concat(muscle.insertJoints)
              .includes(keyword);
          }
        })
    )
    .slice(0, MUSCLES_LIMIT * (page + 1));

  const hasAllMuscles = filteredMuscles.length < (page + 1) * MUSCLES_LIMIT;

  const bodyButton = useMemo(
    () => (
      <IconButton
        alignSelf="flex-start"
        icon={showBody ? <GridIcon size={5} /> : <BodyIcon size={5} />}
        onPress={() => setShowBody(!showBody)}
      />
    ),
    [setShowBody, showBody]
  );
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable px={4} onPress={() => setFiltersOpen(true)}>
          <NumberBadge value={Object.keys(selectedFilters).length} />
          <FilterIcon
            color={Colors.primary}
            _dark={{ color: Colors.bgWhite }}
          />
        </Pressable>
      ),
      headerLeft: () => bodyButton,
    });
  }, [bodyButton, navigation, selectedFilters, setShowBody, showBody]);

  const fetchMore = async () => {
    setLoading(true);
    setPage(page + 1);
    setLoading(false);
  };

  return (
    <>
      {showBody ? (
        <Screen
          maxW={SCREEN_MAX_WIDTH}
          scrollEnabled={false}
          title={t('routes.muscles')}
          height="100%"
          mainButton={bodyButton}
          rightButton={bodyButton}
        >
          <BodyMuscles />
        </Screen>
      ) : (
        <MusclesList
          loading={loading}
          muscles={filteredMuscles}
          title={t('routes.muscles')}
          onShowFilters={() => setFiltersOpen(true)}
          fetchMore={
            !hasPremiumMuscles || hasAllMuscles || loading
              ? undefined
              : fetchMore
          }
          filtersCount={Object.keys(selectedFilters).length}
          bodyButton={bodyButton}
        />
      )}

      <FiltersModal
        isOpen={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filtersType={FiltersType.Muscles}
      />
    </>
  );
};
