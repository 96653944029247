import { IconButton, IInputProps } from 'native-base';
import { SendIcon } from 'components/icons/icons';
import { Colors } from 'src/theme/colors';
import { TextInput } from 'components/form/TextInput';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

type Props = IInputProps & {
  onSend: (text: string) => void;
  sendDisabled?: boolean;
};

export const MessageInput = ({ onSend, sendDisabled, ...props }: Props) => {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const disabled = sendDisabled || text.trim().length === 0;

  const onSendMessage = () => {
    if (!disabled) {
      onSend(text.trim());
      setText('');
    }
  };

  return (
    <TextInput
      w="100%"
      placeholder={t('chat.messagePlaceholder')}
      value={text}
      onChangeText={setText}
      rightElement={
        <IconButton
          icon={
            <SendIcon color={disabled ? Colors.grayLight : Colors.primary} />
          }
          disabled={disabled}
          onPress={onSendMessage}
        />
      }
      onSubmitEditing={disabled ? undefined : onSendMessage}
      {...props}
    />
  );
};
