import React, { useCallback, useMemo, useState } from 'react';
import { Screen } from 'components/layout/Screen';
import { Body } from 'components/Body';
import frontPoints from 'src/data/problems/frontBodyPointsProblems.json';
import backPoints from 'src/data/problems/backBodyPointsProblems.json';
import { getLocalizedValue } from 'src/utils/utils';
import { getProblemsConfig } from 'src/data/problems/problemsConfig';
import { ProblemsBottomModal } from 'components/modals/ProblemsBottomModal';
import { useTranslation } from 'react-i18next';
import { IPoint } from 'src/@types/types';

export const ProblemsScreen = () => {
  const { t } = useTranslation();

  const [selectedPoint, setSelectedPoint] = useState<IPoint>();
  const problemsConfig = getProblemsConfig();

  const problems = useMemo(
    () =>
      selectedPoint?.problems?.map((problemId) => problemsConfig[problemId]) ||
      [],
    [problemsConfig, selectedPoint?.problems]
  );

  const onClose = useCallback(() => {
    setSelectedPoint(undefined);
  }, []);

  return (
    <Screen scrollEnabled={false} title={t('routes.problems')} height={'100%'}>
      <Body
        frontPoints={Object.values(frontPoints)}
        backPoints={Object.values(backPoints)}
        onPointPress={(point) => setSelectedPoint(point)}
      />
      <ProblemsBottomModal
        problems={problems}
        title={selectedPoint && getLocalizedValue(selectedPoint?.name)}
        isOpen={!!selectedPoint}
        onClose={onClose}
      />
    </Screen>
  );
};
