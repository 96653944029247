import { useRecoilState } from 'recoil';
import { userState } from 'src/state/state';
import { useCallback, useMemo } from 'react';
import { getMusclesConfig } from '../data/muscles/musclesConfig';

export const useFavoriteMuscles = () => {
  const [user] = useRecoilState(userState);

  const musclesConfig = getMusclesConfig();

  const favoriteMuscles = useMemo(() => {
    const allMuscles =
      user?.favoriteMuscles?.map((id) => musclesConfig[id]) || [];
    return allMuscles.filter((muscle) => !!muscle);
  }, [musclesConfig, user?.favoriteMuscles]);

  const isFavorite = useCallback(
    (id: string) => {
      return !!user?.favoriteMuscles?.find((muscle) => muscle === id);
    },
    [user?.favoriteMuscles]
  );

  return {
    favoriteMuscles,
    isFavorite,
  };
};
