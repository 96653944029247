import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Routes } from 'src/navigation/routes';
import { useTranslation } from 'react-i18next';
import { useColorMode } from 'native-base';
import { ConversationsStackParamList } from 'src/navigation/types/conversationStack';
import { ConversationsScreen } from 'components/screens/ConversationsScreen';
import { isAndroid, isIOS } from 'src/utils/constants';
import { ConversationThreadsScreen } from 'components/screens/ConversationThreadsScreen';
import { MessagesScreen } from 'components/screens/MessagesScreen';
import { StackHeader } from 'components/layout/StackHeader';

const Stack = createNativeStackNavigator<ConversationsStackParamList>();

export const ConversationsStack = () => {
  const { colorMode } = useColorMode();
  const { t } = useTranslation();

  return (
    <Stack.Navigator
      initialRouteName={Routes.Conversations}
      screenOptions={{
        headerShown: true,
        headerBlurEffect: isIOS
          ? colorMode === 'dark'
            ? 'dark'
            : 'light'
          : undefined,
        headerTransparent: isIOS,
        header: isAndroid ? (props) => <StackHeader {...props} /> : undefined,
      }}
    >
      <Stack.Screen
        name={Routes.Conversations}
        component={ConversationsScreen}
        options={{
          title: t('routes.chat'),
        }}
      />
      <Stack.Screen
        name={Routes.ConversationThreads}
        component={ConversationThreadsScreen}
        options={{
          title: t('routes.chat'),
        }}
      />
      <Stack.Screen
        name={Routes.Messages}
        component={MessagesScreen}
        options={{
          title: t('routes.chat'),
        }}
      />
    </Stack.Navigator>
  );
};
