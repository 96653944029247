import {
  HeadingSecondary,
  HeadingTertiary,
} from 'components/typography/heading';
import { Text } from 'native-base';
import { PropsWithChildren } from 'react';

export const TermsOfUseEn = () => {
  return (
    <>
      <HeadingSecondary my={2}>General Note:</HeadingSecondary>
      <Text my={4}>
        The following General Terms and Conditions of Business for Using
        RecoveryTom App are intended for use with our international customers
        for which we do not provide localized terms and conditions.
      </Text>
      <HeadingSecondary my={2}>1. Introduction</HeadingSecondary>
      <Text my={4}>
        This App is operated by RecoveryTom. The terms “we”, “us”, and “our”
        refer to RecoveryTom. The use of our website is subject to the following
        terms and conditions of use, as amended from time to time (the “Terms”).
        The Terms are to be read together by you with any terms, conditions or
        disclaimers provided in the pages of our website. Please review the
        Terms carefully. The Terms apply to all users of our App, including
        without limitation, users who are browsers, customers, merchants,
        vendors and/or contributors of content. If you access and use the app,
        you accept and agree to be bound by and comply with the Terms and our
        Privacy Policy. If you do not agree to the Terms or our Privacy Policy,
        you are not authorized to access our App, use any of our App’s services.
      </Text>
      <HeadingTertiary my={4}>
        1.1 Terms and conditions for participating
      </HeadingTertiary>
      <Text my={4}>
        A condition for opening a user account and using the RecoveryTom App is
        that you are at least 18 years of age and have full legal capacity.
      </Text>
      <HeadingSecondary my={2}>2. RecoveryTom’s prices</HeadingSecondary>
      <HeadingTertiary my={4}>
        2.1 Services free of charge or for a fee
      </HeadingTertiary>
      <Text my={4}>
        The scope of the services included in RecoveryTom is available for use
        by you depends on the type of RecoveryTom App and whether you use the
        RecoveryTom App free of charge or for a fee. If you use it free of
        charge you only have access to certain basic functions and information
        of the respective RecoveryTom App. A more extensive range of functions
        is available to you as part of a subscription for the respective
        RecoveryTom App.
      </Text>
      <HeadingSecondary my={2}>3. Your Health</HeadingSecondary>
      <HeadingTertiary my={4}>
        3.1 Terms and conditions with regard to your health
      </HeadingTertiary>
      <Text my={4}>
        Use of the RecoveryTom App is at your own risk. In any case a condition
        for the use of the RecoveryTom App is that you must be in a good general
        state of health. If you have knowledge of any pre-existing medical
        conditions we advise you to seek medical advice from a doctor before you
        start the RecoveryTom App. This applies in particular if you have
        knowledge of one or more of the following medical
        complaints/conditions/procedures:
      </Text>
      <Li>cardiovascular disease</Li>
      <Li>lung or respiratory disease (including asthma)</Li>
      <Li>spinal and/or joint problems</Li>
      <Li>neuromuscular disease</Li>
      <Li>surgical procedures</Li>
      <Li>any other health issues</Li>
      <Text mt={4}>
        The following general rules apply: Listen to what your body is telling
        you. Before using the RecoveryTom App for the first time or while using
        the App, if you have any doubts about your health (e.g. because you are
        experiencing considerable pain, a general malaise, shortness of breath,
        nausea or dizziness) consult your doctor before starting with
        RecoveryTom App.
      </Text>
      <HeadingTertiary my={4}>
        3.2 No substitute for medical advice
      </HeadingTertiary>
      <Text my={4}>
        The services and information offered by RecoveryTom App do not
        constitute medical advice or a doctor&apos;s advice. Nor are they a
        substitute for a medical examination or treatment by a doctor.
      </Text>
      <HeadingSecondary my={2}>4. User Account</HeadingSecondary>
      <HeadingTertiary my={4}>4.1 Registration process</HeadingTertiary>
      <Text my={4}>
        By using RecoveryTom, you agree to the Terms of Use and Privacy policy.
        If you want to use the extended functionality of the application, you
        must open a user account. You can sign in with your Google account on
        all platforms or with Apple ID if you use iOS. In this case there is a
        process login completed when you enter your account information and
        click on &quot;Confirm&quot;.
      </Text>
      <HeadingSecondary my={2}>5. Conclusion of a Contract</HeadingSecondary>
      <Text my={4}>
        How the respective contract is formed depends on the method by which you
        register for RecoveryTom App for the first time and whether you sign up
        for additional fee-based service.
      </Text>
      <HeadingTertiary my={4}>
        5.1 Online registration on the website
      </HeadingTertiary>
      <Text my={4}>
        When registering via our websites the user contract between us comes
        into effect once the registration process has been completed.
      </Text>
      <HeadingTertiary my={4}>5.2 Registration via mobile apps</HeadingTertiary>
      <Text my={4}>
        When registering via mobile apps, the formation of the user contract
        depends on the rules of the app store supplier (for example Apple,
        Google, etc.). The contract is generally formed when you click on the
        &quot;Install&quot; field in the relevant app store and, where
        necessary, enter your password in question. Please note that in order to
        use the RecoveryTom App it is still necessary to open a cost-free user
        account with us.
      </Text>
      <HeadingTertiary my={4}>
        5.3 Conclusion of a contract for subscription
      </HeadingTertiary>
      <Text my={4}>
        You can acquire additional functionality, within a subscription. If you
        acquire the additional services via our RecoveryTom website, the
        contract becomes effective once you click the “Subscribe” or equivalent
        button and successfully enter your payment information. If you purchase
        the additional service via your mobile app, the contract is formed when
        you click on the field &quot;Buy now&quot;.
      </Text>
      <HeadingSecondary my={2}>6. Duration and scope</HeadingSecondary>
      <HeadingTertiary my={4}>6.1 User contract</HeadingTertiary>
      <Text my={4}>
        The user contract concluded between you and us once you register your
        account is valid for an indefinite period. To use certain RecoveryTom
        functionality, it is necessary to purchase subscription. As soon as you
        have opened a user account pursuant to Section 5, you are a user for the
        purposes of these General Terms and Conditions of Business and can
        obtain a subscription. Further information about this can be found on
        our website. As a RecoveryTom user, you are responsible for all
        activities by any users associated with your user account.
      </Text>
      <HeadingSecondary my={2}>7. User contract</HeadingSecondary>
      <Text my={4}>
        The fee is collected for the relevant minimum term when the contract is
        concluded for the purchase of a subscription. If the subscription is
        renewed automatically, the fee is collected in advance at the start of
        the relevant renewal period.
      </Text>
      <HeadingSecondary my={2}>8. Payment Methods</HeadingSecondary>
      <Text my={4}>
        If you purchase RecoveryTom subscription via in-app purchases, the
        respective app-store supplier will bill you. Please consult this
        supplier to find out what payment methods are available. If you pay for
        RecoveryTom subscription via our RecoveryTom website, we use Stripe as
        our payment provider. Please consult this supplier to find out what
        payment methods are available. If we incur costs and/or expenses because
        a payment is declined and this is your fault (e.g. because there are
        insufficient funds in the account or the credit card limit has already
        been exhausted), then we are entitled to bill you for the actual costs
        and/or expenses incurred. If you incur fees when using a payment method,
        we will inform you of this during the purchase process. Payment
        providers may, at their discretion, offer you the option to pay in
        installments for a fee. This fee will be charged directly by the
        respective payment provider and is therefore payable directly to them.
        The terms and conditions of the respective payment provider apply.
        Please note that if you cancel your order, we will not be able to refund
        these fees. Where there is a legitimate reason, we reserve the right for
        each purchase to refrain from offering certain payment methods and to
        specify alternative payment methods. If your selected means of payment
        is frozen (e.g. due to theft) or if there are other security breaches
        (e.g. unauthorized disclosure of username and password), please notify
        us or your payment processor immediately.
      </Text>
      <HeadingSecondary my={2}>9. Liability for Defects</HeadingSecondary>
      <HeadingTertiary my={4}>9.1 Statutory Provisions</HeadingTertiary>
      <Text my={4}>
        Statutory provisions apply to claims due to defective services. Your
        consumer rights remain unaffected in any case.
      </Text>
      <HeadingTertiary my={4}>9.2 Disclaimer of guarantees</HeadingTertiary>
      <Text my={4}>
        We do not make any representations or guarantees that the use of the
        RecoveryTom App will bring the result intended by you. We do not promise
        a concrete success. Also, the actual training result will depend on
        factors which cannot be influenced, such as, for example, physical
        disposition and preconditions. Consequently, results may vary strongly
        between individuals despite the same use of the RecoveryTom App. We also
        do not guarantee that the available content is suitable for all users,
        or that particular functionality will be available or supported for a
        specific period, or that our RecoveryTom App will be available with no
        interruptions or faults. Insofar as a paid user contract has been
        agreed, RecoveryTom will provide the agreed functionality. However, we
        reserve the right to modify, extend, terminate, or remove our
        RecoveryTom App, content and functionality, either in whole or in part,
        and/or to discontinue the operation of our RecoveryTom App either in
        whole or in part.
      </Text>
      <HeadingSecondary my={2}>10. Liability</HeadingSecondary>
      <HeadingTertiary my={4}>10.1 General</HeadingTertiary>
      <Text my={4}>
        Insofar as you are provided with guides or instructions in connection
        with the RecoveryTom App it is imperative that you follow them.
        Otherwise you risk being injured and your general health. Insofar as you
        use equipment or training tools it is your responsibility to ensure that
        such equipment and tools are in good working condition and installed
        and/or set up properly. You need to observe and respect our health
        safety notices in clause 3.
      </Text>
      <HeadingTertiary my={4}>
        10.2 Liability for services provided free of charge
      </HeadingTertiary>
      <Text my={4}>
        For services provided free of charge, we will be liable, regardless of
        the legal basis, exclusively for damage due to wilful conduct or gross
        negligence or the absence of a guaranteed feature. Our liability is not
        limited for wilful misconduct. In the event of gross negligence or the
        absence of a guaranteed feature our liability is limited to reasonable,
        foreseeable damage. Otherwise, our liability is excluded.
      </Text>
      <HeadingTertiary my={4}>
        10.3 Liability for services provided for a fee
      </HeadingTertiary>
      <Text my={4}>
        In the case of services provided for a fee we have, regardless of the
        legal basis, unlimited liability in principle for damage due to wilful
        conduct or gross negligence or the absence of a guaranteed feature. If
        we breach a material contractual obligation as a result of slight
        negligence, our liability is limited to reasonable, foreseeable damage.
        A material contractual obligation is any obligation that is necessary to
        fulfil the purpose of the contract, and on the fulfilment of which you
        as the consumer can rely or ought to be able to rely. Our liability in
        the event of any injury to life, limb or health that is our fault
        remains unaffected by the above-mentioned limitations. Otherwise, our
        liability is excluded.
      </Text>
      <HeadingSecondary my={2}>
        11. Rights of Use over RecoveryTom Content
      </HeadingSecondary>
      <Text my={4}>
        Depending on which services have been enabled for you, or which services
        you have purchased, the RecoveryTom contains content which is protected
        by copyright and we hold the respective rights. We grant you a simple,
        revocable, non-transferable right to the non-commercial use of this
        content in accordance with these contractual provisions. Purely for the
        avoidance of doubt we draw your attention to the fact that, in
        particular, distributing our content or making it publicly available,
        e.g. on websites other than RecoveryTom website or RecoveryTom Apps, is
        not permitted. The same applies for copying, sale or distribution, the
        creation of derivative works, and any activity that could disrupt or
        harm our RecoveryTom Apps. The right of use will lapse when your access
        to the respective service is no longer enabled (e.g. after you have
        canceled your subscription) or when your user contract ends.
      </Text>
      <HeadingSecondary my={2}>
        12. Responsibility for User-Generated Content
      </HeadingSecondary>
      <HeadingTertiary my={4}>
        12.1 Disclaimer of responsibility for third party content
      </HeadingTertiary>
      <Text my={4}>
        You are solely responsible for content that you post within the
        RecoveryTom App. We accept no responsibility for this content, nor do we
        monitor it. You are prohibited from advertising commercial websites or
        other products through your user account.
      </Text>
      <HeadingTertiary my={4}>
        12.2 Compliance with statutory provisions
      </HeadingTertiary>
      <Text my={4}>
        When supplying your own content you are obliged to comply with all the
        applicable laws and other legislation of the Slovak Republic. Regardless
        of whether this constitutes a criminal act, it is generally prohibited
        to provide content that is pornographic, sexual, violent, racist,
        instigating, discriminatory, explicit, insulting and/or libelous in
        nature. In addition you are also obliged to refrain from infringing any
        third-party rights. This applies in particular to personality rights of
        third parties as well as to third-party intellectual property rights
        (such as, for example, copyrights and trademark rights). In particular
        you must also hold the necessary rights over your profile picture or any
        other picture you post. We are hereby authorized to delete or remove
        content that is illegal or that conflicts with the previously stated
        guideline, at any time and without forewarning. If you infringe the
        above-mentioned principles we are entitled to give you a warning or to
        temporarily block your user account or to cancel the user contract for
        good cause in accordance with clause 13.3.
      </Text>
      <HeadingTertiary my={4}>12.3 Indemnification</HeadingTertiary>
      <Text my={4}>
        If you infringe the principles mentioned in clause 13.2 and that this is
        your fault (i.e. because you acted either negligently or with intent),
        you are obliged to indemnify us against any third-party claims arising
        from such infringement. This indemnity also includes the costs of a
        suitable defense. You are obligated to provide assistance in clarifying
        the disputed situation. We reserve the right to assert claims for
        damages and other claims.
      </Text>
      <HeadingSecondary my={2}>13. Ending the Contract</HeadingSecondary>
      <HeadingTertiary my={4}>13.1 User contract</HeadingTertiary>
      <Text my={4}>
        You have the right to cancel your user account at any time, without
        stating any reasons, thereby also ending your user contract. To do this
        you simply have to select the necessary settings in your profile. Please
        note that after you have cancelled your user account we will or may
        delete all the content and you will no longer have access to content
        that you already purchased. If at the time of deleting your account you
        still have a current subscription that has not yet expired, any sum that
        you have already paid will not be refunded, not even on a pro rata
        basis. We are entitled to cancel the user contract without stating any
        reasons by giving at least two (2) weeks written notice, but no earlier
        than at the end of the minimum contractual term or at the end of the
        respective renewal period of your subscription.
      </Text>
      <HeadingTertiary my={4}>13.2 Subscription</HeadingTertiary>
      <Text my={4}>
        You can cancel the relevant subscription without giving reasons at any
        time with effect from the end of the minimum term or at the end of the
        applicable extension or billing period. For canceling subscription
        bought on our website follow guidance in our privacy policy.
        Subscriptions purchased via in-app purchase must be canceled using the
        settings in the respective app store. Your user account will continue to
        exist after you have canceled your subscription. We are entitled to
        cancel your subscription with effect from the end of the minimum
        contractual term or with effect from the end of the respective renewal
        period by giving at least two (2) weeks&apos; written notice.
      </Text>
      <HeadingTertiary my={4}>13.3 Cancellation for good cause</HeadingTertiary>
      <Text my={4}>
        The right to cancel for good cause remains unaffected in the case of
        either party. In particular we are entitled to cancel your user contract
        or your subscription with immediate effect, and to cancel your user
        account, if you seriously or repeatedly breach the provisions of the
        user contract and/or these General Terms and Conditions of Business, or
        if you are in arrears with your payment obligations.
      </Text>
      <HeadingSecondary my={2}>14. Personal Data</HeadingSecondary>
      <Text my={4}>
        We process your personal data in accordance with our privacy policy, the
        latest version of which can be referenced at our website.
      </Text>
      <HeadingSecondary my={2}>
        15. Changes to the General Terms and Conditions of Business
      </HeadingSecondary>
      <Text my={4}>
        We hereby reserve the right to modify and adjust the general terms of
        business with future effect if this is required by the legal,
        regulatory, or technical environment, and if these changes are
        reasonable and take your interests into consideration. We will advise
        you of the changes by our website/application no later than two (2)
        weeks before the new version of the General Terms and Conditions of
        Business is scheduled to enter into force. If you do not object to the
        validity of the new General Terms and Conditions of Business within such
        period and continue to use RecoveryTom, then the new General Terms and
        Conditions of Business will be deemed to have been accepted. In the
        event that you do object, we expressly reserve our rights of ordinary
        cancellation of account.
      </Text>
      <HeadingSecondary my={2}>16. Final Provisions</HeadingSecondary>
      <HeadingTertiary my={4}>16.1 Applicable law</HeadingTertiary>
      <Text my={4}>
        The relationship between the parties is governed exclusively by Slovak
        law under exclusion of the UN Convention on Contracts for the
        International Sale of Goods (CISG). In relation to business transactions
        with consumers within the European Union, the law of the consumer’s
        place of residence may also be applicable where such law contains
        consumer law provisions that it is mandatory to apply.
      </Text>
      <HeadingTertiary my={4}>16.2 Place of jurisdiction</HeadingTertiary>
      <Text my={4}>
        If you do not have a place of general jurisdiction in Slovakia or in
        another EU Member State, or if you have moved your permanent place of
        residence to a country outside the EU after these General Terms and
        Conditions of Business have entered into effect, or if your permanent
        place of residence or usual place of residence at the time the complaint
        is filed is not known, then the exclusive place of jurisdiction for all
        disputes arising from this contract will be our place of business.
      </Text>
      <HeadingTertiary my={4}>16.3 Language of the contract</HeadingTertiary>
      <Text my={4}>The language of the contract is English.</Text>
      <HeadingTertiary my={4}>16.4 Severability clause</HeadingTertiary>
      <Text my={4}>
        Should any individual provisions of these General Terms and Conditions
        of Business be or become invalid in whole or in part, this will not
        affect the validity of the remaining provisions.
      </Text>
      <HeadingTertiary my={4}>
        17. Information about the Supplier
      </HeadingTertiary>
      <Text my={1}>Name: Tomáš Janečka</Text>
      <Text my={1}>Email: recoverytom.issues@gmail.com</Text>
      <Text my={1}>
        Residence: Bánovce nad Bebravou, Malé Chlievany, Slovakia
      </Text>
    </>
  );
};

const Li = (props: PropsWithChildren) => {
  return (
    <Text>
      {`\u2022`} {props.children}
    </Text>
  );
};
