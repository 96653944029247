import { Colors } from 'src/theme/colors';
import { IInputProps, Input, useColorMode } from 'native-base';

export const TextInput = (props: IInputProps) => {
  const { colorMode } = useColorMode();
  return (
    <Input
      borderRadius={10}
      bg={Colors.bgWhite}
      _dark={{ bg: Colors.bgDark }}
      _input={{
        selectionColor:
          colorMode === 'light' ? Colors.grayLight : Colors.bgWhite,
      }}
      {...props}
    />
  );
};
