export enum Routes {
  Root = 'Root',
  NotFound = 'NotFound',
  BottomTabs = 'BottomTabs',
  DashboardStack = 'DashboardStack',
  Dashboard = 'Dashboard',
  Favorites = 'Favorites',
  FavoriteMuscles = 'FavoriteMuscles',

  ExercisesStack = 'ExercisesStack',
  Exercises = 'Exercises',
  ExerciseDetail = 'ExerciseDetail',

  MusclesStack = 'MusclesStack',
  Muscles = 'Muscles',
  MuscleDetail = 'MuscleDetail',

  ProblemsStack = 'ProblemsStack',
  Problems = 'Problems',
  ProblemDetail = 'ProblemDetail',

  ConversationsStack = 'ConversationsStack',
  Chat = 'Chat',
  Conversations = 'Conversations',
  ConversationThreads = 'ConversationThreads',
  Messages = 'Messages',

  Profile = 'Profile',
  Friends = 'Friends',
  PrivacyPolicy = 'PrivacyPolicy',
  TermsOfUse = 'TermsOfUse',
  Subscription = 'Subscription',
  Contact = 'Contact',
}
