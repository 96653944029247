import { StyledProps } from 'native-base';
import { Routes } from '../navigation/routes';
import React from 'react';
import { isWeb } from '../utils/constants';
import { getLocalizedValue } from '../utils/utils';
import { IFavoriteExercise } from 'src/@types/types';
import { Preview } from 'components/Preview';
import { Colors } from 'src/theme/colors';
import { useExerciseImageUrl } from 'src/hooks/useExerciseImageUrl';
import { useIsPremium } from 'hooks/useIsPremium';

type Props = {
  exercise: IFavoriteExercise;
  onPress?: () => void;
  withOverlay?: boolean;
  disabled?: boolean;
} & StyledProps;

export const ExercisePreview = ({ exercise, ...props }: Props) => {
  const uri = useExerciseImageUrl(exercise.id);
  const { hasPremiumExercises } = useIsPremium();
  return (
    <Preview
      hasPremium={hasPremiumExercises}
      name={getLocalizedValue(exercise.name)}
      to={
        isWeb
          ? { screen: Routes.ExerciseDetail, params: { id: exercise.id } }
          : {
              screen: Routes.ExercisesStack,
              params: {
                screen: Routes.ExerciseDetail,
                params: { id: exercise.id },
                initial: false,
              },
            }
      }
      imageSource={{ uri }}
      labelColor={Colors.bgDarkLight}
      {...props}
    />
  );
};
