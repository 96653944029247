import { IMusclesConfig } from 'src/@types/types';

export const musclesConfigEn: IMusclesConfig = {
  levatorScapulae: {
    id: 'levatorScapulae',
    showPremium: true,
    name: 'Levator scapulae',
    origin: ['Transverse processes of vertebrae C1-C4'],
    insertion: ['Medial border of scapula'],
    function: [
      'Draws scapula superomedially, rotates glenoid cavity inferiorly',
      'Lateral flexion of neck, extension of the neck',
    ],
    innervation:
      'Anterior rami of the nerves C3 and C4, dorsal scapular nerve (branch of the C5)',
    bloodSupply:
      'Transverse cervical and ascending cervical arteries (branches of the thyrocervical trunk)',
    description:
      'When the spine is fixed, levator scapulae elevates the scapula and rotates its inferior angle medially. It often works in combination with other muscles like the rhomboids and pectoralis minor to produce downward rotation of the scapula. Elevating or rotating one shoulder at a time would require muscles to stabilize the cervical spine and keep it immobile so it does not flex or rotate. Elevating both at once with equal amounts of pull on both side of cervical spinal origins would counteract these forces. Downward rotation would be prevented by co-contraction of other muscles that elevate the spine, the upper fibers of the trapezius, which is an upward rotator.',
    joints: [
      'cervicalSpine',
      'scapula',
      'neck',
      'spineMid',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['c1', 'c2', 'c3', 'c4', 'spine'],
    insertJoints: ['scapula'],
    movements: ' Elevates the scapula, tilting the head to the sides',
    musclesSameCategory: [
      'sternocleidomastoid',
      'scaleneMuscles',
      'omohyoid',
      'sternohyoid',
      'spleniusCapitis',
      'semispinalisCapitis',
    ],
  },
  sternocleidomastoid: {
    id: 'sternocleidomastoid',
    showPremium: true,
    name: 'Sternocleidomastoid',
    origin: [
      'Sternal head: superior part of anterior surface of manubrium sterni',
      'Clavicular head: superior surface of medial third of the clavicle',
    ],
    insertion: [
      'Lateral surface of the mastoid process and Lateral half of superior the nuchal line of the occipital bone',
    ],
    function: ['Head side rotation, neck flexion and extension  '],
    innervation:
      'Anterior rami of spinal nerves C4-C6; anterior rami of spinal nerves C3-C8; anterior rami of spinal nerves C6-C8',
    bloodSupply:
      'Occipital, superficial cervical, suprascapular, and transverse cervical artery',
    description:
      "This muscle's function is to rotate the head to the opposite side or obliquely rotate the head. It also causes the neck to flex. When both sides of the muscle contract, the neck flexes and the head extends. When only one side acts, the head rotates to the opposite side and flexes laterally to the same side.",
    joints: ['head', 'clavicle', 'neck', 'sternum', 'upperBody', 'fullBody'],
    originJoints: ['sternum', 'clavicle'],
    insertJoints: ['head'],
    movements: 'Head side rotation, backwards, down',
    musclesSameCategory: [
      'levatorScapulae',
      'scaleneMuscles',
      'omohyoid',
      'sternohyoid',
      'spleniusCapitis',
      'semispinalisCapitis',
    ],
  },
  scaleneMuscles: {
    id: 'scaleneMuscles',
    showPremium: true,
    name: 'Scalene muscles',
    origin: [
      'Anterior tubercle of transverse processes of vertebrae C3-C6',
      'Posterior tubercles of transverse processes of vertebrae C2-C7',
      'Posterior tubercles of transverse processes of vertebrae C5-C7',
    ],
    insertion: [
      'Anterior scalene tubercle of rib 1',
      'Superior border of rib 1',
      'External surface of rib 2',
    ],
    function: ['Neck flexion, elevates rib 1'],
    innervation: 'Accessory nerve (CN XI), branches of cervical plexus (C2-C3)',
    bloodSupply: 'Ascending cervical branch of the inferior thyroid artery',
    description:
      'The anterior and middle scalene muscles lift the first rib and bend the neck to the same side as the acting muscle; the posterior scalene lifts the second rib and tilts the neck to the same side. Because they elevate the upper ribs, they also act as accessory muscles of respiration, along with the sternocleidomastoids.',
    joints: [
      'cervicalSpine',
      'rib1',
      'rib2',
      'ribs',
      'ribsTop',
      'neck',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'spine'],
    insertJoints: ['r1', 'r2', 'ribs'],
    movements:
      'During respiration elevates 1st and 2nd rib, flex and rotation of cervical spine',
    musclesSameCategory: [
      'levatorScapulae',
      'sternocleidomastoid',
      'omohyoid',
      'sternohyoid',
      'spleniusCapitis',
      'semispinalisCapitis',
    ],
  },
  omohyoid: {
    id: 'omohyoid',
    name: 'Omohyoid',
    origin: [
      'Inferior belly: superior border of scapula near the suprascapular notch (incisura scapulae)',
      'Superior belly: intermediate tendon',
    ],
    insertion: [
      'Inferior belly: intermediate tendon',
      'Superior belly: body of hyoid bone',
    ],
    function: [
      'Depresses and retracts hyoid and larynx and reestablish breathing following the act of swallowing',
    ],
    innervation: 'Anterior rami of spinal nerves C1-C3',
    bloodSupply: 'Superior thyroid artery',
    description:
      'The infrahyoid muscles, along with the suprahyoid muscles, are responsible for the positioning of the hyoid bone and the larynx. Specifically, the infrahyoid muscles, and omohyoid, depress the hyoid bone following its elevation during the act of swallowing.  In addition Omohyoid muscle in contraction pulls on the sheath and maintains a low pressure in the internal jugular vein',
    joints: ['scapula', 'hyoidBone', 'neck', 'back', 'upperBody', 'fullBody'],
    originJoints: ['scapula'],
    insertJoints: ['hyoidBone'],
    movements:
      'Swallowing, maintains a low pressure in the internal jugular vein',
    musclesSameCategory: [
      'levatorScapulae',
      'scaleneMuscles',
      'sternohyoid',
      'sternocleidomastoid',
      'spleniusCapitis',
      'semispinalisCapitis',
    ],
  },
  sternohyoid: {
    id: 'sternohyoid',
    name: 'Sternohyoid',
    origin: ['Manubrium of sternum, medial end of clavicle'],
    insertion: ['Inferior border of body of hyoid bone'],
    function: ['Depress the hyoid bone'],
    innervation: 'Lateral branches of posterior rami of spinal nerves C2-C3',
    bloodSupply: 'Superior thyroid artery',
    description:
      'The role of the sternothyoid muscle is to depress the hyoid bone and the larynx during speech and swallowing, along with the other infrahyoid muscles.',
    joints: [
      'hyoidBone',
      'sternum',
      'clavicle',
      'neck',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['sternum', 'clavicle'],
    insertJoints: ['hyoidBone'],
    movements:
      'Depresses the hyoid bone, helps with speech, primarily to do with volume, swallowing and chewing',
    musclesSameCategory: [
      'levatorScapulae',
      'scaleneMuscles',
      'omohyoid',
      'sternocleidomastoid',
      'spleniusCapitis',
      'semispinalisCapitis',
    ],
  },
  spleniusCapitis: {
    id: 'spleniusCapitis',
    name: 'Splenius capitis',
    origin: [
      'Lower half of the nuchal ligament and the spinous processes of vertebrae C7-T3',
    ],
    insertion: ['Mastoid process of the temporal bone'],
    function: [
      'Biletaral contraction causes extension of the head on the neck',
      'Unilaterally contraction in synergy with sternocleidomastoid results in lateral flexion and rotation of the head',
    ],
    innervation:
      'Descending branches of greater occipital nerve (C2) and spinal nerve C3',
    bloodSupply: 'Muscular branches of occipital artery',
    description:
      'The muscle prime mover for head extension. The splenius capitis can also allow lateral flexion and rotation of the cervical spine.',
    joints: [
      'cervicalSpine',
      'thoracicSpine',
      'head',
      'spineMid',
      'neck',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['c7', 't1', 't2', 't3', 'spine'],
    insertJoints: ['head'],
    movements: 'Moving head backwards, turning head to sides',
    musclesSameCategory: [
      'levatorScapulae',
      'scaleneMuscles',
      'omohyoid',
      'sternocleidomastoid',
      'sternohyoid',
      'semispinalisCapitis',
    ],
  },
  semispinalisCapitis: {
    id: 'semispinalisCapitis',
    name: 'Semispinalis capitis',
    origin: [
      'Articular processes of vertebrae C4-C7',
      'Transverse processes of vertebrae T1-T6',
    ],
    insertion: ['Between superior and inferior nuchal lines of occipital bone'],
    function: [
      'Bilateral contraction: extension of head, cervical and thoracic spine ',
      'Unilateral contraction: lateral flexion of head, cervical and thoracic spine, rotation of head',
    ],
    innervation: 'Lateral and medial pectoral nerves (C5-T1)',
    bloodSupply: 'Occipital artery',
    description:
      'Lateral rotation of the neck, the lateral flexion of the neck, and the extension of the neck, such as when bending the neck backward to look up at the ceiling. The semispinalis capitis also helps to maintain the correct posture of the neck and head region.',
    joints: [
      'cervicalSpine',
      'thoracicSpine',
      'spineMid',
      'neck',
      'head',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: [
      'c4',
      'c5',
      'c6',
      'c7',
      't1',
      't2',
      't3',
      't4',
      't5',
      't6',
      'spine',
    ],
    insertJoints: ['head'],
    movements: 'Bending neck backwards to look up at the celing',
    musclesSameCategory: [
      'levatorScapulae',
      'scaleneMuscles',
      'omohyoid',
      'sternocleidomastoid',
      'sternohyoid',
      'spleniusCapitis',
    ],
  },
  pectoralisMajor: {
    id: 'pectoralisMajor',
    showPremium: true,
    name: 'Pectoralis major',
    origin: [
      'Clavicular part: anterior surface of the sternal half of the clavicle',
      'Sternocostal part: anterior surface of sternum, Costal cartilages of ribs 1-6',
      'Abdominal part: aponeurosis of the abdominal external oblique muscle (Anterior layer of rectus sheath)',
    ],
    insertion: ['Crest of greater tubercle of humerus'],
    function: [
      'Shoulder joint: Arm adduction, Arm internal rotation, Arm flexion (clavicular head), arm extension (sternocostal head);',
      'Scapulothoracic joint: Draws scapula anteroinferiorly',
    ],
    innervation: 'Lateral and medial pectoral nerves (C5-T1)',
    bloodSupply:
      'Pectoral branches of thoracoacromial artery, perforating branches of internal thoracic artery',
    description:
      'The pectoralis major has four actions which are primarily responsible for movement of the shoulder joint. The first action is flexion of the humerus, as in throwing a ball underhand, and in lifting a child. Secondly, it adducts the humerus, as when flapping the arms. Thirdly, it rotates the humerus medially, as occurs when arm-wrestling. Fourthly the pectoralis major is also responsible for keeping the arm attached to the trunk of the body',
    joints: [
      'clavicle',
      'sternum',
      'ribs',
      'ribsTop',
      'ribsMid',
      'humerus',
      'chest',
      'shoulder',
      'upperArm',
      'upperBody',
      'fullBody',
    ],
    originJoints: [
      'clavicle',
      'sternum',
      'r1',
      'r2',
      'r3',
      'r4',
      'r5',
      'r6',
      'ribs',
    ],
    insertJoints: ['humerus'],
    movements: 'Internal rotation of shoulder, Adduction of shoulder',
    musclesSameCategory: [
      'pectoralisMinor',
      'serratusAnterior',
      'bicepsBrachii',
      'coracobrachialis',
      'deltoid',
      'latissimusDorsi',
      'teresMajor',
    ],
  },
  pectoralisMinor: {
    id: 'pectoralisMinor',
    name: 'Pectoralis minor',
    origin: ['Anterior surface, costal cartilages of ribs 3-5'],
    insertion: ['Medial border and coracoid process of scapula'],
    function: [
      'Scapulothoracic joint: draws scapula anteroinferiorly, stabilizes scapula on thoracic wall',
    ],
    innervation: 'Medial and lateral pectoral nerves (C5-T1)',
    bloodSupply:
      'Thoracoacromial (pectoral and deltoid branches), superior thoracic and lateral thoracic artery',
    description:
      'The primary actions of this muscle include the stabilization, depression, abduction or protraction, internal rotation and downward rotation of the scapula.',
    joints: [
      'ribs',
      'scapula',
      'ribsMid',
      'chest',
      'shoulder',
      'upperBody',
      'fullBody',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['ribs', 'r3', 'r4', 'r5'],
    insertJoints: ['scapula'],
    movements:
      'It pulls scapula downward, forward (protraction) accompanied by a rotating shoulder joint, auxiliary inspiratory muscle',
    musclesSameCategory: [
      'pectoralisMajor',
      'serratusAnterior',
      'bicepsBrachii',
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'subscapularis',
      'omohyoid',
      'levatorScapulae',
      'latissimusDorsi',
    ],
  },
  serratusAnterior: {
    id: 'serratusAnterior',
    showPremium: true,
    name: 'Serratus anterior',
    origin: [
      'Superior part: Ribs 1-2',
      'Middle part: Ribs-3-6',
      'Inferior part: Ribs 7-8/9',
    ],
    insertion: [
      'Superior part: Anterior surface of superior angle of scapula',
      'Anterior surface of medial border of scapula',
      'Anterior surface of inferior angle and medial border of scapula',
    ],
    function: [
      'Scapulothoracic joint: Draws scapula anterolaterally, Suspends scapula on thoracic wall, Rotates scapula',
    ],
    innervation: 'Long thoracic nerve (C5- C7)',
    bloodSupply:
      'Superior and lateral thoracic arteries, thoracodorsal artery branches',
    description:
      'All three parts described above pull the scapula forward around the thorax, which is essential for anteversion of the arm. As such, the muscle is an antagonist to the rhomboids. However, when the inferior and superior parts act together, they keep the scapula pressed against the thorax together with the rhomboids and therefore these parts also act as synergists to the rhomboids.',
    joints: [
      'ribs',
      'scapula',
      'ribsTop',
      'ribsMid',
      'ribsBot',
      'chest',
      'back',
      'shoulder',
      'upperBody',
      'fullBody',
      'midBody',
    ],
    originJoints: [
      'ribs',
      'r1',
      'r2',
      'r3',
      'r4',
      'r5',
      'r6',
      'r7',
      'r8',
      'r9',
    ],
    insertJoints: ['scapula'],
    movements:
      "Holds the scapula against the chest, protraction of scapula (boxer's muscle), auxiliary inspiratory muscle",
    musclesSameCategory: [
      'pectoralisMinor',
      'subscapularis',
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'omohyoid',
      'levatorScapulae',
      'latissimusDorsi',
    ],
  },
  supraspinatus: {
    id: 'supraspinatus',
    name: 'Supraspinatus',
    origin: ['Supraspinous fossa of scapula'],
    insertion: ['Greater tubercle of humerus'],
    function: [
      'Shoulder joint: abduction of arm, stabilization of the humeral head in the glenoid cavity',
    ],
    innervation: 'Suprascapular nerve (C5, C6)',
    bloodSupply: 'Suprascapular artery',
    description:
      'The supraspinatus muscle performs abduction of the arm, and pulls the head of the humerus medially towards the glenoid cavity. It independently prevents the head of the humerus from slipping inferiorly. The supraspinatus works in cooperation with the deltoid muscle to perform abduction, including when the arm is in an adducted position.',
    joints: [
      'scapula',
      'humerus',
      'back',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'External rotation of upper limb, auxiliary muscle in arm abduction',
    musclesSameCategory: [
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'subscapularis',
      'levatorScapulae',
      'pectoralisMinor',
      'latissimusDorsi',
      'trapezius',
      'rhomboidMinor',
      'rhomboidMajor',
      'deltoid',
      'tricepsBrachii',
      'omohyoid',
    ],
  },
  infraspinatus: {
    id: 'infraspinatus',
    name: 'Infraspinatus',
    origin: ['Infraspinous fossa of scapula'],
    insertion: ['Greater tubercle of humerus'],
    function: [
      'Shoulder joint: Arm external rotation, Stabilizes humeral head in glenoid cavity',
    ],
    innervation: 'Suprascapular nerve (C5, C6)',
    bloodSupply: 'Suprascapular artery, circumflex scapular arteries',
    description:
      'The infraspinatus is the main external rotator of the shoulder. When the arm is fixed, it adducts the inferior angle of the scapula. Its synergists are teres minor and the deltoid. The infraspinatus and teres minor rotate the head of the humerus outward (external, or lateral, rotation). They also assist in carrying the arm backward.',
    joints: [
      'scapula',
      'humerus',
      'back',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'External rotation of shoulder joint/arm, adduction and supination of arm  ',
    musclesSameCategory: [
      'supraspinatus',
      'teresMajor',
      'teresMinor',
      'subscapularis',
      'levatorScapulae',
      'pectoralisMinor',
      'trapezius',
      'latissimusDorsi',
      'trapezius',
      'rhomboidMinor',
      'rhomboidMajor',
      'deltoid',
      'tricepsBrachii',
      'omohyoid',
    ],
  },
  teresMajor: {
    id: 'teresMajor',
    name: 'Teres major',
    origin: [
      'Inferior angle and lower part of the lateral border of the scapula',
    ],
    insertion: ['Intertubercular sulcus (medial lip) of the humerus'],
    function: [
      'Shoulder joint: Extension and internal rotation of the humerus (arm)',
    ],
    innervation: 'Subscapular nerve (C5-C7)',
    bloodSupply:
      'Thoracodorsal branch of subscapular artery and posterior circumflex humeral artery',
    description:
      'The teres major is a medial rotator and adductor of the humerus and assists the latissimus dorsi in drawing the previously raised humerus downwards and backwards. It also helps stabilise the humeral head in the glenoid cavity.',
    joints: [
      'scapula',
      'humerus',
      'back',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements: 'Medial rotation and adduction of the humerus',
    musclesSameCategory: [
      'supraspinatus',
      'infraspinatus',
      'teresMinor',
      'subscapularis',
      'pectoralisMinor',
      'levatorScapulae',
      'latissimusDorsi',
      'trapezius',
      'rhomboidMinor',
      'rhomboidMajor',
      'deltoid',
      'tricepsBrachii',
      'omohyoid',
    ],
  },
  teresMinor: {
    id: 'teresMinor',
    showPremium: true,
    name: 'Teres minor',
    origin: ['Lateral border of scapula'],
    insertion: ['Greater tubercle of humerus '],
    function: [
      'Shoulder joint: Arm external rotation, arm adduction, Stabilizes humeral head in glenoid cavity ',
    ],
    innervation: 'Axillary nerve (C5, C6)',
    bloodSupply: 'Suprascapular artery, dorsal scapular artery',
    description:
      'Teres minor helps hold the humeral head in the glenoid cavity of the scapula. Teres minor with the infraspinatus work in tandem with the posterior deltoid to externally (laterally) rotate the humerus, as well as adduction. When working in synergy with teres major, latissimus dorsi and pectoralis major, teres minor adducts the from the abducted position (lowers the angle of arm and body).',
    joints: [
      'scapula',
      'humerus',
      'back',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'With infraspinatus external rotation of humerus, In synergy with teres major, latissimus dorsi and pectoralis major adduction of abducted arm',
    musclesSameCategory: [
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'subscapularis',
      'pectoralisMinor',
      'levatorScapulae',
      'latissimusDorsi',
      'trapezius',
      'rhomboidMinor',
      'rhomboidMajor',
      'deltoid',
      'tricepsBrachii',
      'omohyoid',
    ],
  },
  subscapularis: {
    id: 'subscapularis',
    name: 'Subscapularis',
    origin: ['Subscapular fossa of scapula'],
    insertion: ['Lesser tubercle of humerus'],
    function: [
      'Shoulder joint: Arm internal rotation, Stabilizes humeral head in glenoid cavity',
    ],
    innervation: 'Upper and lower subscapular nerves (C5 - C6)',
    bloodSupply: 'Suprascapular, axillary and subscapular artery',
    description:
      'The subscapularis muscle is the only medial (internal) rotator of all the rotator cuff muscles and in lesser extend also adducts the arm. The sequence of these two movements is seen in walking when your arms swing. When the arm is raised, it draws the humerus forward and downward. It is a powerful defense to the front of the shoulder-joint, preventing displacement of the head of the humerus.',
    joints: [
      'scapula',
      'humerus',
      'back',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'Medial (internal) rotation of shoulder, In raised arm position it draws the humerus forward and downward',
    musclesSameCategory: [
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'serratusAnterior',
      'pectoralisMinor',
      'omohyoid',
      'levatorScapulae',
      'trapezius',
      'latissimusDorsi',
      'rhomboidMinor',
      'rhomboidMajor',
      'deltoid',
      'tricepsBrachii',
    ],
  },
  rhomboidMinor: {
    id: 'rhomboidMinor',
    name: 'Rhomboid minor',
    origin: ['Nuchal ligament and spinous processes of vertebrae C7-T1'],
    insertion: ['Medial border near the base of the spine of the scapula'],
    function: [
      'Scapulothoracic joint: Draws scapula superomedially, Rotates glenoid cavity inferiorly; Supports position of scapula',
    ],
    innervation: 'Dorsal scapular nerve (C4-C5)',
    bloodSupply:
      'Dorsal scapular artery, deep branch of transverse cervical artery, dorsal branch of upper five or six posterior intercostal arteries',
    description:
      'The rhomboid major helps to hold the scapula (and thus the upper limb) onto the ribcage. Both rhomboids (major and minor) also act to retract the scapula, pulling it towards the vertebral column (backbone/spine). The rhomboids work collectively with the levator scapulae muscles to elevate the medial border of the scapula, downwardly rotating the scapula.',
    joints: [
      'scapula',
      'cervicalSpine',
      'thoracicSpine',
      'spineMid',
      'neck',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['c7', 't1', 'spine'],
    insertJoints: ['scapula'],
    movements: 'Retraction of scapula, holds the scapula onto the ribcage',
    musclesSameCategory: [
      'rhomboidMajor',
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'serratusAnterior',
      'serratusPosteriorSuperior',
      'levatorScapulae',
      'trapezius',
      'latissimusDorsi',
      'omohyoid',
    ],
  },
  rhomboidMajor: {
    id: 'rhomboidMajor',
    name: 'Rhomboid major',
    origin: ['Spinous process of vertebrae T2-T5'],
    insertion: ['Medial border of the scapula'],
    function: [
      'Scapulothoracic joint: Draws scapula superomedially, Rotates glenoid cavity inferiorly; Supports position of scapula',
    ],
    innervation: 'Dorsal scapular nerve (C4-C5)',
    bloodSupply:
      'Dorsal scapular artery, deep branch of transverse cervical artery, dorsal branch of upper five or six posterior intercostal arteries',
    description:
      'The rhomboid major helps to hold the scapula (and thus the upper limb) onto the ribcage. Both rhomboids (major and minor) also act to retract the scapula, pulling it towards the vertebral column (backbone/spine). The rhomboids work collectively with the levator scapulae muscles to elevate the medial border of the scapula, downwardly rotating the scapula.',
    joints: [
      'scapula',
      'thoracicSpine',
      'spineMid',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['t2', 't3', 't4', 't5', 'spine'],
    insertJoints: ['scapula'],
    movements: 'Retraction of scapula, holds the scapula onto the ribcage',
    musclesSameCategory: [
      'rhomboidMajor',
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'serratusAnterior',
      'serratusPosteriorSuperior',
      'levatorScapulae',
      'latissimusDorsi',
      'omohyoid',
    ],
  },
  serratusPosteriorSuperior: {
    id: 'serratusPosteriorSuperior',
    showPremium: true,
    name: 'Serratus posterior superior',
    origin: ['Nuchal ligament, spinous processes of vertebrae C7-T3'],
    insertion: ['Superior borders of ribs 2-5'],
    function: [
      'The serratus posterior superior muscle elevates the second to fifth ribs. This aids deep respiration.',
    ],
    innervation: 'Intercostal nerves (T1-T5)',
    bloodSupply: 'Posterior intercostal arteries',
    description:
      'The serratus posterior superior elevates the ribs during inspiration (accessory muscle of inspiration) and has a stabilization role for thorax.',
    joints: [
      'thoracicSpine',
      'cervicalSpine',
      'ribs',
      'ribsMid',
      'ribsTop',
      'spineMid',
      'neck',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['c7', 't1', 't2', 't3', 'spine'],
    insertJoints: ['ribs', 'r2', 'r3', 'r4', 'r5'],
    movements: 'Elevates the ribs',
    musclesSameCategory: [
      'rhomboidMajor',
      'rhomboidMinor',
      'spleniusCapitis',
      'semispinalisCapitis',
      'serratusPosteriorInferior',
    ],
  },
  serratusPosteriorInferior: {
    id: 'serratusPosteriorInferior',
    name: 'Serratus posterior inferior',
    origin: ['T11-L2 vertebrae spinous process'],
    insertion: ['Inferior borders of ribs 9-12'],
    function: [
      'Depress the ribs during expiration (accessory muscle of expiration)',
    ],
    innervation: 'Intercostal nerves (T9-T12)',
    bloodSupply: 'Posterior intercostal arteries',
    description:
      'The serratus posterior inferior draws the lower ribs backward and downward to assist in rotation and extension of the trunk. Depress the ribs during expiration.',
    joints: [
      'thoracicSpine',
      'lumbarSpine',
      'ribs',
      'ribsBot',
      'spineBot',
      'back',
      'midBody',
      'fullBody',
    ],
    originJoints: ['t11', 't12', 'l1', 'l2', 'spine'],
    insertJoints: ['ribs', 'r9', 'r10', 'r11', 'r12'],
    movements: 'Depresses the ribs',
    musclesSameCategory: [
      'serratusPosteriorSuperior',
      'quadratusLumborum',
      'psoasMinor',
      'psoasMajor',
      'internalOblique',
      'externalObliques',
      'latissimusDorsi',
      'trapezius',
    ],
  },
  latissimusDorsi: {
    id: 'latissimusDorsi',
    name: 'Latissimus dorsi',
    origin: [
      'Vertebral part: Spinous processes of vertebrae T7-T12',
      'Iliac part: Posterior third of crest of ilium',
      'Costal part: Ribs 9-12',
      'Scapular part: Inferior angle of scapula',
    ],
    insertion: [
      'Intertubercular sulcus of the humerus, between pectoralis major and teres major muscles',
    ],
    function: [
      'Shoulder joint: Arm internal rotation, Arm adduction, Arm extension; Assists in respiration',
    ],
    innervation: 'Thoracodorsal nerve (C6-C8)',
    bloodSupply:
      'Thoracodorsal artery, perforating arteries of the 9th-11th posterior intercostal arteries, and 1st-3rd lumbar arteries',
    description:
      'Latissimus dorsi works collaboratively with the teres major and pectoralis major to perform actions of the upper extremity. Together, these muscles will work to adduct, medially rotate and extend the arm at the glenohumeral joint. The muscle is also active in moving the trunk forward and upward when the upper extremities are fixed overhead, as in climbing or performing an activity such as a chin up.',
    joints: [
      'thoracicSpine',
      'ribs',
      'ribsBot humerus',
      'scapula',
      'pelvis',
      'spineMid',
      'spineBot',
      'back',
      'upperArm',
      'shoulder',
    ],
    originJoints: [
      't7',
      't8',
      't9',
      't10',
      't11',
      't12',
      'spine',
      'pelvis',
      'ribs',
      'r9',
      'r10',
      'r11',
      'r12',
      'scapula',
    ],
    insertJoints: ['humerus'],
    movements:
      'Climbing muscle, extends the flexed arm,  adducts and internally rotates the arm, If the humerus is fixed against the scapula, it pulls the pectoral girdle backward as a whole',
    musclesSameCategory: [
      'serratusPosteriorInferior',
      'trapezius',
      'teresMajor',
      'pectoralisMajor',
      'tricepsBrachii',
      'quadratusLumborum',
    ],
  },
  trapezius: {
    id: 'trapezius',
    name: 'Trapezius',
    origin: [
      'Descending part (superior fibers): medial third of the superior nuchal line, external occipital protuberance, nuchal ligament',
      'Middle fibres: spinous processes and supraspinous ligaments of vertebrae T1-T4 (or C7-T3)',
      'Inferior fibres: spinous processes and supraspinous ligaments of vertebrae T4-T12',
    ],
    insertion: [
      'Superior fibres: lateral third of clavicle',
      'Middle fibres: medial acromial margin, superior crest of spine of scapula',
      'Inferior fibres: lateral apex of the medial end of scapular spine',
    ],
    function: [
      'Superior fibers: Scapulothoracic joint: draws scapula superomedially, Atlantooccipital joint: extension of head and neck, lateral flexion of head and neck, Altantoaxial joint: rotation of head',
      'Middle fibres: capulothoracic joint: draws scapula medially',
      'Inferior fibres: Scapulothoracic joint: Draws scapula inferomedially',
    ],
    innervation:
      'Sensory function: the ventral rami of C3, C4; Motor function: cranial nerve XI',
    bloodSupply:
      'Occipital artery (descending part), superficial or transverse cervical artery (transverse part), dorsal scapular artery (ascending part)',
    description:
      'The main function of the trapezius is stabilizing the scapula and controlling it during movements of the shoulder and upper limb. Contraction of the trapezius muscle can have two effects: movement of the scapulae when the spinal origins are stable, and movement of the spine when the scapulae are stable. Its main function is to stabilize and move the scapula. The upper fibers elevate the scapulae, the middle fibers retract the scapulae, and the lower fibers depress the scapulae. In addition to scapular translation, the trapezius induces scapular rotation. The upper and lower fibers tend to rotate the scapula around the sternoclavicular articulation so that the acromion and inferior angles move up and the medial border moves down (upward rotation). The upper and lower fibers work in tandem with serratus anterior to upwardly rotate the scapulae, and work in opposition to the levator scapulae and the rhomboids, which effect downward rotation. An example of trapezius function is an overhead press. When activating together, the upper and lower fibers also assist the middle fibers (along with other muscles such as the rhomboids) with scapular retraction/adduction.',
    joints: [
      'cervicalSpine',
      'thoracicSpine',
      'head',
      'scapula',
      'shoulder',
      'spineMid',
      'neck',
      'back',
    ],
    originJoints: [
      'head',
      'spine',
      'c7',
      't1',
      't2',
      't3',
      't4',
      't5',
      't6',
      't7',
      't8',
      't9',
      't10',
      't11',
      't12',
    ],
    insertJoints: ['scapula', 'clavicle'],
    movements:
      'Primarily scapula stabilization, scapula/shoulders elevation, arm elevation about the horizont, head rotation, tilting the head to the sides, head extension',
    musclesSameCategory: [
      'latissimusDorsi',
      'deltoid',
      'levatorScapulae',
      'rhomboidMajor',
      'rhomboidMinor',
      'supraspinatus',
      'infraspinatus',
      'semispinalisCapitis',
      'spleniusCapitis',
    ],
  },
  deltoid: {
    id: 'deltoid',
    name: 'Deltoid',
    origin: [
      ' Anterior (front) part: lateral 1/3 of Clavicle (clavicular part)',
      'Intermediate (mid) part: acromion ',
      'Posterior part: spine of Scapula',
    ],
    insertion: ['Deltoid tuberosity of humerus'],
    function: [
      'Clavicular part: flexion and internal rotation of the arm',
      'Acromial part: abduction of the arm beyond the initial 15\u00b0',
      'Spinal part: extension and external rotation of the arm',
    ],
    innervation: 'Axillary nerve (C5, C6)',
    bloodSupply:
      'Deltoid and acromial branches of thoracoacromial artery, subscapular artery, anterior and posterior circumflex humeral arteries, deltoid branch of deep brachial artery',
    description:
      'When all its fibers contract simultaneously, the deltoid is the prime mover of arm abduction along the frontal plane. The anterior fibers assist the pectoralis major to flex the shoulder. The anterior deltoid also works in tandem with the subscapularis, pecs and lats to internally (medially) rotate the humerus. The intermediate fibers perform basic shoulder abduction when the shoulder is internally rotated, and perform shoulder transverse abduction when the shoulder is externally rotated. The posterior fibers assist the latissimus dorsi to extend the shoulder. Other transverse extensors, the infraspinatus and teres minor, also work in tandem with the posterior deltoid as external (lateral) rotators, antagonists to strong internal rotators like the pecs and lats.',
    joints: [
      'scapula',
      'clavicle',
      'humerus',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula', 'clavicle'],
    insertJoints: ['humerus'],
    movements:
      'Stabilization of the glenohumeral joint, moving arm forward, backward and to sides (abduction)',
    musclesSameCategory: [
      'trapezius',
      'pectoralisMajor',
      'bicepsBrachii',
      'coracobrachialis',
      'supraspinatus',
      'infraspinatus',
      'teresMinor',
      'teresMajor',
    ],
  },
  coracobrachialis: {
    id: 'coracobrachialis',
    name: 'Coracobrachialis',
    origin: ['Coracoid process of the scapula'],
    insertion: ['Anteromedial surface of the humeral shaft'],
    function: ['Flexion and adduction of the arm at the shoulder joint.'],
    innervation: 'Musculocutaneous nerve (C5- C7)',
    bloodSupply: 'Muscular branches of brachial artery',
    description:
      'The main actions of the coracobrachialis muscle are bending the arm (flexion) and pulling the arm towards the trunk (adduction) at the shoulder joint. When the arm is abducted and extended, the coracobrachialis muscle acts as a strong antagonist to the deltoid muscle.',
    joints: [
      'scapula',
      'humerus',
      'shoulder',
      'upperArm',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'Bending (flexion) arm and pulling the arm to the trunk (adduction)',
    musclesSameCategory: [
      'pectoralisMinor',
      'bicepsBrachii',
      'tricepsBrachii',
      'pectoralisMajor',
      'deltoid',
      'subscapularis',
      'teresMajor',
      'brachioradialis',
    ],
  },
  bicepsBrachii: {
    id: 'bicepsBrachii',
    name: 'Biceps brachii',
    origin: [
      'Short head - Apex of the Coracoid process of the scapula',
      'Long head - Supraglenoid tubercle of the scapula',
    ],
    insertion: ['Radial tuberosity of the radius', 'Deep fascia of forearm'],
    function: [
      'Flexion and supination of the forearm at the elbow joint, weak flexor of the arm at the glenohumeral join',
    ],
    innervation: 'Musculocutaneous nerve (C5- C6)',
    bloodSupply: 'Branches of brachial artery',
    description:
      'The biceps is a two-joint muscle. In the shoulder joint both muscle heads partially enforce opposite movements. The long head pulls the arm away from the trunk (abduction) and turns it inwards (inward rotation) whereas the short head pulls the arm back towards the trunk (adduction). When both heads contract simultaneously it leads to an arm bend (flexion).',
    joints: [
      'scapula',
      'humerus',
      'radius',
      'elbow',
      'shoulder',
      'upperArm',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['radius'],
    movements: 'Flexion and supination of forearm, abduction in shoulder joint',
    musclesSameCategory: [
      'coracobrachialis',
      'pectoralisMinor',
      'brachioradialis',
      'pronatorTeres',
      'tricepsBrachii',
      'deltoid',
      'pectoralisMajor',
      'brachialis',
    ],
  },
  brachialis: {
    id: 'brachialis',
    name: 'Brachialis',
    origin: ['Distal half of anterior surface of humerus'],
    insertion: ['Coronoid process of the ulna; Tuberosity of ulna '],
    function: ['Elbow joint: Strong flexion of forearm'],
    innervation: 'Musculocutaneous nerve (C5,C6); Radial nerve (C7)',
    bloodSupply:
      'Brachial artery, radial recurrent artery, (occasionally) branches from the superior and inferior ulnar collateral arteries',
    description:
      'The brachialis is known as the workhorse of the elbow. It is a major flexor of the forearm at the elbow joint, flexing the elbow while it is in all positions. The brachialis is the only pure flexor of the elbow joint\u2013producing the majority of force during elbow flexion. It is not affected by pronation or supination of the forearm, and does not participate in pronation and supination due to its lack of attachment to the radius.',
    joints: [
      'humerus',
      'ulna',
      'upperArm',
      'elbow',
      'upperBody',
      'fullBody',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['ulna'],
    movements: 'Strong flexion of forearm',
    musclesSameCategory: [
      'bicepsBrachii',
      'brachioradialis',
      'pronatorTeres',
      'tricepsBrachii',
      'pronatorTeres',
    ],
  },
  tricepsBrachii: {
    id: 'tricepsBrachii',
    name: 'Triceps brachii',
    origin: [
      'Long head - Infraglenoid tubercle of scapula',
      'Medial head - posterior fascia of humerus',
      'Lateral head - posterior fascia of humerus',
    ],
    insertion: ['Olecranon process of ulna'],
    function: ['Extension of forearm'],
    innervation: 'Radial nerve (C6-C8)',
    bloodSupply:
      'Arteria brachialis profounda, superior ulnar collateral artery',
    description:
      'The triceps is an extensor muscle of the elbow joint and an antagonist of the biceps and brachialis muscles.',
    joints: [
      'scapula',
      'humerus',
      'radius',
      'upperArm',
      'elbow',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula', 'humerus'],
    insertJoints: ['ulna'],
    movements: 'Extension of forearm, flexion and adduction in shoulder joint',
    musclesSameCategory: [
      'infraspinatus',
      'teresMinor',
      'teresMajor',
      'brachialis',
      'bicepsBrachii',
      'brachioradialis',
      'extensorCarpiRadialisLongus',
    ],
  },
  brachioradialis: {
    id: 'brachioradialis',
    name: 'Brachioradialis',
    origin: [
      'Lateral supracondylar rigid of the humerus and lateral intermuscular septum',
    ],
    insertion: ['Styloid process of the radius'],
    function: ['Flexion of forearm at elbow joint'],
    innervation: 'Radial nerve (C5-C6)',
    bloodSupply:
      'Arteria radialis, arteria recurrens radialis, arteria collateralis radialis',
    description:
      'The brachioradialis muscle works in synergy with biceps brachii and brachialis to flex the forearm at the elbow. Brachioradialis is a powerful forearm flexor when the forearm is semi pronated, meaning that the palm is perpendicular to the ground. When the forearm is pronated, the brachioradialis tends to supinate as it flexes. In a supinated position, it tends to pronate as it flexes. Simply it do the other movement depending on the actual position.',
    joints: [
      'humerus',
      'radius',
      'upperArm',
      'elbow',
      'forearm',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['radius'],
    movements:
      'Strong forearm flexor at the elbow joint, pronation and supination of forearm',
    musclesSameCategory: [
      'pronatorTeres',
      'extensorCarpiRadialisLongus',
      'extensorCarpiRadialisBrevis',
      'anconeus',
    ],
  },
  pronatorTeres: {
    id: 'pronatorTeres',
    name: 'Pronator teres',
    origin: [
      'Humeral head: medial supracondylar ridge of humerus',
      'Ulnar head: Coronoid process of ulna',
    ],
    insertion: ['Lateral surface of radius (distal to supinator)'],
    function: [
      'Pronation of forearm at the proximal radioulnar joint, flexion of the forearm at the elbow joint',
    ],
    innervation: 'Median nerve (C6, C7)',
    bloodSupply: 'Branches of brachial, radial and ulnar arteries',
    description:
      'Main action of pronator teres is the pronation of the forearm. The muscle pulls the radius medially, causing its head to rotate around the proximal part of ulna at the proximal radioulnar joint. It also weakly flexes the elbow, or assists in flexion at the elbow when there is strong resistance.',
    joints: [
      'humerus',
      'radius',
      'ulna',
      'elbow',
      'forearm',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus', 'ulna'],
    insertJoints: ['radius'],
    movements: 'Pronation of forearm, weak flexion of elbow',
    musclesSameCategory: [
      'brachioradialis',
      'brachialis',
      'bicepsBrachii',
      'TricepsBrachii',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'flexorDigitorumSuperficialis',
      'flexorDigitorumProfundus',
    ],
  },
  flexorCarpiRadialis: {
    id: 'flexorCarpiRadialis',
    name: 'Flexor carpi radialis',
    origin: ['Medial epicondyle of humerus'],
    insertion: ['Bases of metacarpal bones 2-3'],
    function: ['Wrist joint: Wrist flexion, wrist abduction '],
    innervation: 'Median nerve (C6, C7)',
    bloodSupply: 'Anterior/posterior recurrent ulnar artery, radial artery',
    description:
      'Flexor carpi radialis pulls the hand proximally and laterally, meaning that it is able to produce the combined motion of wrist flexion and wrist abduction.',
    joints: [
      'humerus',
      'fingersHand',
      'elbow',
      'hand',
      'forearm',
      'fingers4',
      'hand',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand', 'h2', 'h3', 'h4'],
    movements: 'Wrist flexion and abduction, pronation of forearm',
    musclesSameCategory: [
      'pronatorTeres',
      'brachioradialis',
      'anconeus',
      'flexorCarpiUlnaris',
      'palmarisLongus',
      'flexorDigitorumSuperficialis',
      'flexorDigitorumProfundus',
    ],
  },
  flexorCarpiUlnaris: {
    id: 'flexorCarpiUlnaris',
    name: 'Flexor carpi ulnaris',
    origin: [
      'Medial epicondyle of humerus',
      ' Olecranon and posterior border of ulna',
    ],
    insertion: ['Pisiform bone, hamate bone, base of metacarpal bone 5'],
    function: ['Wrist joint: Wrist flexion, wrist adduction'],
    innervation: 'Ulnar nerve (C7-T1)',
    bloodSupply: 'Posterior ulnar recurrent artery, ulnar artery',
    description:
      'Flexor carpi ulnaris can move the hand sideways as well as flexing it. Contracting with flexor carpi radialis and palmaris longus, flexor carpi ulnaris produces flexion of the hand at the wrist joint. When it contracts alongside the extensor carpi ulnaris muscle in the posterior compartment, their counteracting forces produce adduction of the hand at the wrist.',
    joints: [
      'humerus',
      'ulna',
      'fingersHand',
      'elbow',
      'forearm',
      'fingers4',
      'hand',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['ulna', 'humerus'],
    insertJoints: ['hand', 'h5'],
    movements: 'Wrist flexion and adduction',
    musclesSameCategory: [
      'pronatorTeres',
      'brachioradialis',
      'anconeus',
      'flexorCarpiRadialis',
      'palmarisLongus',
      'flexorDigitorumSuperficialis',
      'flexorDigitorumProfundus',
    ],
  },
  palmarisLongus: {
    id: 'palmarisLongus',
    name: 'Palmaris longus',
    origin: ['Medial epicondyle of humerus'],
    insertion: ['Flexor retinaculum, palmar aponeurosis'],
    function: ['Wrist joint: Wrist flexion', 'Tenses palmar aponeurosis'],
    innervation: 'Median nerve (C7, C8)',
    bloodSupply: 'Anterior ulnar recurrent artery, median artery',
    description:
      'Palmaris longus helps the flexor carpi ulnaris and flexor carpi radialis muscles to perform a balanced flexion of the hand on the wrist. It also acts to stabilize the elbow joint when fully extended, as does the other forearm muscles that attach to the humerus and thus cross the elbow joint. Fibers attaching to the palmar aponeurosis tighten this fascial sheath during the muscle\u2019s contraction, resulting in a weak flexion of the 2nd to 5th metacarpophalangeal joints as the aponeurosis is being pulled towards the wrist. Tightening of the palmar aponeurosis also contributes to maintaining the hand\u2019s grip while holding objects.',
    joints: [
      'humerus',
      'fingersHand',
      'hand',
      'forearm',
      'elbow',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand'],
    movements: 'Wrist flexion, tenses palmar aponeurosis',
    musclesSameCategory: [
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'pronatorTeres',
      'flexorDigitorumSuperficialis',
      'flexorDigitorumProfundus',
    ],
  },
  flexorDigitorumSuperficialis: {
    id: 'flexorDigitorumSuperficialis',
    name: 'Flexor digitorum superficialis',
    origin: [
      'Humeroulnar head: Medial epicondyle of humerus, coronoid process of ulna',
      'Radial head: Proximal half of anterior border of radius',
    ],
    insertion: ['Sides of middle phalanges of digits 2-5'],
    function: ['2-5 Finger flexion (not thumb)'],
    innervation: 'Median nerve (C8, T1)',
    bloodSupply: 'Ulnar artery, radial artery, median artery',
    description:
      'The main function of flexor digitorum superficialis is flexion of the digits 2-5 at the proximal interphalangeal and metacarpophalangeal joints. Unlike the flexor digitorum profundus, flexor digitorum superficialis has independent muscle slips for all four digits. This allows it to flex the digits individually at their proximal interphalangeal joints. In addition, flexor digitorum superficialis aids the aids flexion of the wrist.',
    joints: [
      'humerus',
      'ulna',
      'radius',
      'fingersHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus', 'ulna', 'radius'],
    insertJoints: ['hand', 'h2', 'h3', 'h4', 'h5'],
    movements: '2 - 5 finger flexion',
    musclesSameCategory: [
      'flexorDigitorumProfundus',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'pronatorTeres',
      'palmarisLongus',
      'pronatorQuadratus',
    ],
  },
  flexorDigitorumProfundus: {
    id: 'flexorDigitorumProfundus',
    name: 'Flexor digitorum profundus',
    origin: [
      'Proximal half of anterior surface of ulna, interosseous membrane',
    ],
    insertion: ['Palmar surfaces of distal phalanges of digits 2-5'],
    function: ['2-5 Finger flexion (not thumb)'],
    innervation: 'Digits 2-3: Median nerve; Digits 4-5: Ulnar nerve (C8, T1)',
    bloodSupply:
      'Ulnar, common interosseous, ulnar collateral, ulnar recurrent, anterior interosseous, median arteries',
    description:
      'Flexor digitorum profundus muscle is a powerful flexor of the fingers. As it pulls the distal phalanges towards the hand, it causes flexion of the digits 2-5 at the metacarpophalangeal and interphalangeal joints. The muscle can act on its own but it usually works in synergy with the flexor digitorum superficialis, lumbricals and flexor digiti minimi brevis muscles to perform this action. This muscle is also an accessory wrist flexor, aiding the flexor carpi ulnaris, flexor carpi radialis, palmaris longus, flexor digitorum superficialis and flexor pollicis longus muscles to flex the hand at the wrist joint. The actions of the flexor digitorum profundus are important for establishing and maintaining a strong grip of the hand.',
    joints: [
      'ulna',
      'fingersHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['ulna'],
    insertJoints: ['hand', 'h2', 'h3', 'h4', 'h5'],
    movements: '2 - 5 finger flexion, wrist flexion',
    musclesSameCategory: [
      'flexorDigitorumSuperficialis',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'pronatorTeres',
      'palmarisLongus',
      'pronatorQuadratus',
    ],
  },
  pronatorQuadratus: {
    id: 'pronatorQuadratus',
    name: 'Pronator quadratus',
    origin: ['Distal anterior surface of ulna'],
    insertion: ['Distal anterior surface of radius'],
    function: ['Proximal radioulnar joint: Forearm pronation'],
    innervation: 'Median nerve (anterior interosseous nerve, C7, C8)',
    bloodSupply: 'Anterior interosseous artery',
    description:
      'The pronator quadratus muscle is responsible for forearm pronation by exerting its effect on the proximal radioulnar joint. During this movement, the head of the radius rotates around the ulna, causing the palm to face posteriorly or inferiorly if the forearm is flexed. The pronator teres and brachioradialis muscles assist in this action of the pronator quadratus. The location of the pronator quadratus muscle in the distal forearm makes it important in providing protection. During weight-bearing activities, when upward pressure is applied, the pronator quadratus muscle stabilizes and protects the distal radioulnar joint by holding together the distal ends of the radius and ulna.',
    joints: ['ulna', 'radius', 'forearm', 'hand', 'upperBody', 'fullBody'],
    originJoints: ['ulna'],
    insertJoints: ['radius'],
    movements: 'Forearm pronation',
    musclesSameCategory: [
      'flexorDigitorumProfundus',
      'supinator',
      'flexorDigitorumSuperficialis',
      'flexorPollicisLongus',
      'pronatorTeres',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'palmarisLongus',
      'brachioradialis',
    ],
  },
  supinator: {
    id: 'supinator',
    showPremium: true,
    name: 'Supinator',
    origin: [
      'Lateral epicondyle of humerus, radial collateral ligament, annular ligament, supinator crest of ulna',
    ],
    insertion: [
      'Lateral, posterior, and anterior surfaces of proximal third of radius',
    ],
    function: ['Proximal radioulnar joint: Forearm supination'],
    innervation: 'Posterior interosseous nerve (C7, C8)',
    bloodSupply:
      'Radial recurrent artery, posterior interosseous artery, posterior interosseous recurrent artery',
    description:
      "The supinator muscle is responsible for laterally rotating the radius at the proximal radioulnar joint. This action aligns the radius parallel to the ulna, resulting in the hand assuming a supine position (facing anteriorly, palm up, as when holding a bowl of fruit). During slow and unopposed supination, the supinator muscle functions as the primary mover. An example of this is the slow rotation of the hand when grabbing fruit from the bowl. However, when supination requires a quick, forceful movement or occurs against resistance, the biceps brachii muscle assists the supinator muscle. It's worth noting that the biceps brachii muscle cannot act as a supinator when the forearm is fully extended. Therefore, the most powerful supination movement occurs when the elbow joint is flexed to 90 degrees. This action is seen when turning a screwdriver or pulling out the cork from a bottle of wine.",
    joints: [
      'humerus',
      'ulna',
      'radius',
      'elbow',
      'forearm',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus', 'ulna'],
    insertJoints: ['radius'],
    movements: 'Forearm supination',
    musclesSameCategory: [
      'brachialis',
      'brachioradialis',
      'pronatorTeres',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'palmarisLongus',
      'flexorPollicisLongus',
      'flexorDigitorumProfundus',
      'flexorDigitorumSuperficialis',
      'pronatorQuadratus',
    ],
  },
  flexorPollicisLongus: {
    id: 'flexorPollicisLongus',
    name: 'Flexor pollicis longus',
    origin: ['Anterior surface of radius and interosseous membrane'],
    insertion: ['Palmar surface of distal phalanx of thumb'],
    function: [
      'Metacarpophalangeal and interphalangeal joint 1; Thumb flexion',
    ],
    innervation: 'Median nerve (anterior interosseous nerve C7, C8)',
    bloodSupply:
      'Medial part: anterior interosseous artery (ulnar artery); lateral part: radial artery',
    description:
      "The primary role of the flexor pollicis longus muscle is to flex the interphalangeal joint of the thumb, which is crucial for gripping activities. This muscle is unique in its ability to flex the thumb's interphalangeal joint, making it essential for hand gripping tasks. Additionally, the flexor pollicis longus muscle contributes to thumb flexion at the metacarpophalangeal joint and wrist flexion. When you flex your thumb, this muscle can be palpated easily in the forearm's lower third",
    joints: [
      'radius',
      'fingersHand',
      'thumbHand',
      'forearm',
      'hand',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['radius'],
    insertJoints: ['hand', 'h1'],
    movements: 'Thumb flexion',
    musclesSameCategory: [
      'flexorDigitorumProfundus',
      'supinator',
      'flexorDigitorumSuperficialis',
      'pronatorQuadratus',
      'pronatorTeres',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'palmarisLongus',
      'brachioradialis',
    ],
  },
  anconeus: {
    id: 'anconeus',
    name: 'Anconeus',
    origin: ['Lateral epicondyle of humerus'],
    insertion: ['Lateral surface of olecranon'],
    function: [
      'Assists in forearm extension at the elbow joint',
      'Stabilization of elbow joint',
    ],
    innervation: 'Radial nerve (C7-C8)',
    bloodSupply: 'Posterior interosseous recurrent artery',
    description:
      'Functionally, the anconeus is a continuation of the triceps brachii muscle, exhibiting the same action at the elbow. Thereby, its contraction leads to the extension of the forearm. Additional function is abduction of ulna, especially during pronation moves of of forearm.',
    joints: ['humerus', 'radius', 'elbow', 'forearm', 'upperBody', 'fullBody'],
    originJoints: ['humerus'],
    insertJoints: ['radius'],
    movements: 'Forearm extension, stabilization of elbow joint',
    musclesSameCategory: [
      'brachioradialis',
      'extensorCarpiRadialisLongus',
      'extensorDigitorum',
      'flexorCarpiUlnaris',
      'extensorCarpiUlnaris',
      'extensorDigitiMinimi',
      'tricepsBrachii',
    ],
  },
  extensorCarpiRadialisLongus: {
    id: 'extensorCarpiRadialisLongus',
    name: 'Extensor carpi radialis longus',
    origin: ['Lateral supracondylar ridge of humerus'],
    insertion: ['Posterior aspect of base of metacarpal bone 2'],
    function: ['Wrist joints: Hand extension, hand abduction'],
    innervation: 'Radial nerve (C5-C8)',
    bloodSupply:
      'Radial recurrent artery, radial collateral artery, radial artery',
    description:
      'This muscle is an extensor at the wrist joint and travels along the radial side of the arm, so it will also abduct (radial abduction) the hand at the wrist. Together with other forearm extensors, extensor carpi radialis longus plays an important role in hand gripping.',
    joints: [
      'humerus',
      'fingersHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand', 'h2'],
    movements: 'Hand extension and abduction, hand gripping',
    musclesSameCategory: [
      'extensorCarpiRadialisBrevis',
      'extensorDigitorum',
      'extensorPollicisLongus',
      'extensorDigitiMinimi',
      'extensorCarpiUlnaris',
      'extensorPollicisBrevis',
      'anconeus',
    ],
  },
  extensorCarpiRadialisBrevis: {
    id: 'extensorCarpiRadialisBrevis',
    name: 'Extensor carpi radialis brevis',
    origin: ['Lateral epicondyle of humerus'],
    insertion: ['Posterior aspect of base of metacarpal bone 3'],
    function: [
      'Wrist joints: Hand extension, hand abduction (radial deviation)',
    ],
    innervation: 'Radial nerve (C5- C6)',
    bloodSupply: 'Radial recurrent artery, radial artery, deep brachial artery',
    description:
      'Extensor carpi radialis brevis works together with extensor carpi ulnaris and extensor carpi radialis longus to extend the hand at the wrist joint. This action is vital in a sequence of muscle contractions needed for clenching a fist or making a grip. It is also abductor of hand. It serves to manipulate the wrist so that the fingers moves away from the palm.',
    joints: [
      'humerus',
      'fingesHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand', 'h3'],
    movements: 'Extension and abduction of hand',
    musclesSameCategory: [
      'extensorCarpiRadialisLogus',
      'extensorDigitorum',
      'extensorPollicisLongus',
      'extensorDigitiMinimi',
      'extensorCarpiUlnaris',
      'extensorPollicisBrevis',
      'anconeus',
    ],
  },
  extensorDigitorum: {
    id: 'extensorDigitorum',
    name: 'Extensor digitorum',
    origin: ['Lateral epicondyle of humerus'],
    insertion: ['Extensor expansions of digits 2-5'],
    function: [
      'Metacarpophalangeal and Interphalangeal joints 2-5: Finger extension',
    ],
    innervation: 'Posterior interosseous nerve (C7, C8)',
    bloodSupply:
      'Posterior interosseous artery, radial recurrent artery, anterior interosseous artery',
    description:
      'Main function is extension of four medial fingers in metacarpophalangeal and proximal and distal interphalangeal joints. Action of this muscle opposes the action of two flexors of the fingers - flexor digitorum superficialis and flexor digitorum profundus. This muscle participates in the grip action, it contributes to the opening of the hand for dropping the objects. As it crosses the wrist, the contraction participates also in the extension of the wrist. ',
    joints: [
      'humerus',
      'fingersHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand', 'h2', 'h3', 'h4', 'h5'],
    movements: 'Extension of four medial fingers, extension of wrist',
    musclesSameCategory: [
      'extensorCarpiRadialisLogus',
      'extensorCarpiRadialisBrevis',
      'extensorPollicisLongus',
      'extensorDigitiMinimi',
      'extensorCarpiUlnaris',
      'extensorPollicisBrevis',
      'anconeus',
    ],
  },
  extensorPollicisLongus: {
    id: 'extensorPollicisLongus',
    name: 'Extensor pollicis longus',
    origin: [
      'Posterior surface of middle third of ulna',
      'Interosseus membrane',
    ],
    insertion: ['Posterior aspect of base of distal phalanx of thumb'],
    function: [
      'Wrist joints: Weak hand extension ',
      'Metacarpophalangeal and interphalangeal joint of thumb: Thumb extension',
    ],
    innervation: 'Posterior interosseous nerve (C7, C8)',
    bloodSupply: 'Posterior interosseous artery, anterior interosseous artery',
    description:
      'The main action is extension of the thumb at the metacarpophalangeal and interphalangeal joints. Extension at the metacarpophalangeal joint occurs in synergy with extensor pollicis brevis muscle. When the thumb reaches the full extension or abduction, extensor pollicis longus can also assist in adduction of the thumb. ',
    joints: [
      'ulna',
      'fingersHand',
      'forearm',
      'hand',
      'thumbHand',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['ulna'],
    insertJoints: ['hand', 'h1'],
    movements: 'Extension of thumb',
    musclesSameCategory: [
      'extensorCarpiUlnaris',
      'extensorCarpiRadialisLogus',
      'extensorCarpiRadialisBrevis',
      'extensorDigitorum',
      'extensorDigitiMinimi',
      'extensorPollicisBrevis',
      'anconeus',
    ],
  },
  extensorPollicisBrevis: {
    id: 'extensorPollicisBrevis',
    name: 'Extensor pollicis brevis',
    origin: [
      'Posterior surface of distal third of radius',
      'Interosseus membrane',
    ],
    insertion: ['Posterior aspect of base of proximal phalanx of thumb'],
    function: [
      'Carpometacarpal and metacarpophalangeal joint 1: Thumb extension',
    ],
    innervation: 'Posterior interosseous nerve (C7, C8)',
    bloodSupply: 'Posterior interosseous artery, anterior interosseous artery',
    description:
      'Together with extensor pollicis longus, extensor pollicis brevis is in charge of extension of the thumb in the first metacarpophalangeal joint. It also extends the thumb in the carpometacarpal joint of the thumb. This movement is important in the grip, because it enables letting go of an object. As it crosses the wrist, extensor pollicis brevis also participates in the extension and abduction of the wrist.',
    joints: [
      'radius',
      'fingersHand',
      'forearm',
      'hand',
      'thumbHand',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['radius'],
    insertJoints: ['hand', 'h1'],
    movements: 'Extension of thumb, extension and abduction of wrist',
    musclesSameCategory: [
      'extensorCarpiUlnaris',
      'extensorCarpiRadialisLogus',
      'extensorCarpiRadialisBrevis',
      'extensorDigitorum',
      'extensorDigitiMinimi',
      'extensorPollicisLongus',
      'anconeus',
    ],
  },
  extensorDigitiMinimi: {
    id: 'extensorDigitiMinimi',
    name: 'Extensor digiti minimi',
    origin: ['Lateral epicondyle of humerus'],
    insertion: ['Extensor expansion of digit 5'],
    function: ['Metacarpophalangeal joint 5: Finger extension'],
    innervation: 'Posterior interosseous nerve (C7, C8)',
    bloodSupply:
      'Radial recurrent artery, anterior interosseous artery, posterior interosseous artery',
    description:
      'Extensor digiti minimi functions primarily to extend the fifth digit at its metacarpophalangeal joint. It also contributes to extension of the wrist and all the fifth finger joints from the ulnar side of the hand, via it\u2019s attachment into the extensor expansion and by acting along with extensor carpi ulnaris muscle. Having its own extensor muscle, the little finger can extend independently of digits 2-4. This is because the digits 2-4 are primarily extended by a single muscle that inserts to all of them; the extensor digitorum.',
    joints: [
      'humerus',
      'fingersHand',
      'littleFinger',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand', 'h5'],
    movements: 'Extension of little finger',
    musclesSameCategory: [
      'extensorPollicisBrevis',
      'extensorCarpiUlnaris',
      'extensorCarpiRadialisLogus',
      'extensorCarpiRadialisBrevis',
      'extensorDigitorum',
      'extensorPollicisLongus',
      'anconeus',
    ],
  },
  extensorCarpiUlnaris: {
    id: 'extensorCarpiUlnaris',
    name: 'Extensor carpi ulnaris',
    origin: ['Lateral epicondyle of humerus, posterior border of ulna'],
    insertion: ['Base of metacarpal bone 5'],
    function: ['Wrist joint: Hand extension and adduction'],
    innervation: 'Posterior interosseous nerve (C7, C8)',
    bloodSupply: 'Radial recurrent artery, posterior interosseous artery',
    description:
      'Working together with extensor carpi radialis brevis and extensor carpi radialis longus, the extensor carpi ulnaris muscle contributes to a balanced extension of wrist without deviating the hand in the transverse plane. This action is important for activities of the hand, such as strongly clenching a fist or making a power grip. In this process, extension of the wrist blocks the forearm flexors from on acting upon it. Instead, these flexors remain able to act solely on the digits, flexing them to produce an effective grip such as that seen in a tennis backhand. Similarly, synergistic action of flexor carpi ulnaris and extensor carpi ulnaris results in balanced adduction (ulnar deviation) of the hand at the wrist. That is, adduction without added flexion or extension. This action is necessary for processes like hammering, throwing, golfing and swinging a baseball bat. Extensor carpi ulnaris also contributes to the stability of the distal radioulnar joint.',
    joints: [
      'humerus',
      'ulna',
      'fingersHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus', 'ulna'],
    insertJoints: ['hand', 'h5'],
    movements: 'Extension and adduction of hand',
    musclesSameCategory: [
      'extensorPollicisBrevis',
      'extensorDigitiMinimi',
      'extensorCarpiRadialisLogus',
      'extensorCarpiRadialisBrevis',
      'extensorDigitorum',
      'extensorPollicisLongus',
      'anconeus',
    ],
  },
  rectusAbdominis: {
    id: 'rectusAbdominis',
    name: 'Rectus abdominis',
    origin: ['Pubic symphysis, pubic crest'],
    insertion: ['Xiphoid process, costal cartilages of ribs 5-7'],
    function: ['Trunk flexion, compresses abdominal viscera, expiration'],
    innervation: 'Intercostal nerves (T7-T11), subcostal nerve (T12)',
    bloodSupply:
      'Inferior epigastric and superior epigastric arteries; contributions from posterior intercostal, subcostal and deep circumflex arteries',
    description:
      'The rectus abdominis is an important postural muscle. It is responsible for flexing the lumbar spine, as when doing a crunch. The rib cage is brought up to where the pelvis is when the pelvis is fixed, or the pelvis can be brought towards the rib cage (posterior pelvic tilt) when the rib cage is fixed, such as in a leg-hip raise. Moreover, working together with other abdominal muscles, this muscle compresses the abdominal viscera and increases the intra-abdominal pressure, which has an important function in processes such as forced breathing and labor.',
    joints: ['pelvis', 'ribs', 'ribsMid', 'core', 'midBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['ribs', 'r5', 'r6', 'r7'],
    movements: 'Flexion of trunk (lumbar spine), forcerd breathing',
    musclesSameCategory: [
      'internalOblique',
      'externalOblique',
      'tranversusAbdominis',
      'pectoralisMajor',
      'psoasMinor',
      'psoasMajor',
      'pectineus',
      'adductorLongus',
      'adductorBrevis',
      'gracilis',
    ],
  },
  tranversusAbdominis: {
    id: 'tranversusAbdominis',
    name: 'Tranversus abdominis',
    origin: [
      'Internal surfaces of costal cartilages of ribs 7-12',
      'Thoracolumbar fascia',
      'Anterior two thirds of iliac crest',
      'Iliopectineal arch',
    ],
    insertion: [
      'Linea alba',
      'Aponeurosis of internal abdominal oblique muscle',
      'Pubic crest',
    ],
    function: [
      'Bilateral contraction - Compresses abdominal viscera, expiration',
      'Unilateral contraction - Trunk rotation',
    ],
    innervation:
      'Intercostal nerves (T7-T11), subcostal nerve (T12), iliohypogastric nerve (L1), ilioinguinal nerve (L1)',
    bloodSupply:
      'Lower posterior intercostal and subcostal arteries, superior and inferior epigastric arteries, superficial and deep circumflex arteries,posterior lumbar arteries',
    description:
      'The transverse abdominal helps to compress the ribs and viscera, providing thoracic and pelvic stability. Along with other muscles of the abdominal wall, transversus abdominis plays an important role in maintaining normal abdominal wall tension. Therefore, these muscles have a protective as well as a supportive role, holding the abdominal organs in place. Tranversus participates in compression of the intra-abdominal viscera thereby increasing the intra-abdominal pressure. This action is facilitating expulsive functions such as forced expiration,  and final stages of childbirth.',
    joints: ['ribs', 'pelvis', 'core', 'midBody', 'fullBody'],
    originJoints: ['ribs', 'r7', 'r8', 'r9', 'r10', 'r11', 'r12', 'pelvis'],
    insertJoints: ['pelvis'],
    movements:
      'Thoracic and pelvis stability, maintain abdominal wall tension - holding organs in place',
    musclesSameCategory: [
      'internalOblique',
      'externalOblique',
      'rectusAbdominis',
      'psoasMinor',
      'psoasMajor',
      'quadratusLumborum',
    ],
  },
  internalOblique: {
    id: 'internalOblique',
    name: 'Internal oblique',
    origin: ['Thoracolumbar fascia', 'Iliac crest', 'Inguinal ligament'],
    insertion: [
      'Inferior margins of 10-12 ribs and adjacent costal cartilages',
      'Linea alba',
      'Pectineal line',
    ],
    function: [
      'Bilateral contraction - Trunk flexion, compresses abdominal viscera, expiration',
      'Unilateral contraction - Trunk lateral flexion (ipsilateral), trunk rotation (ipsilateral)',
    ],
    innervation:
      'Anterior rami of intercostal nerves (T7-T11), Subcostal nerve (T12), Iliohypogastric nerve (L1), Ilioinguinal nerve (L1)',
    bloodSupply:
      'Lower posterior intercostal and subcostal arteries, superior and inferior epigastric arteries, superficial and deep circumflex arteries,posterior lumbar arteries',
    description:
      'As an accessory muscle of respiration, it acts as an antagonist (opponent) to the diaphragm, helping to reduce the volume of the chest cavity during exhalation. When the diaphragm contracts, it pulls the lower wall of the chest cavity down, increasing the volume of the lungs which then fill with air. Conversely, when the internal obliques contract they compress the organs of the abdomen, pushing them up into the diaphragm which intrudes back into the chest cavity reducing the volume of the air-filled lungs, producing an exhalation. Second function is that its contraction causes ipsilateral rotation and side-bending. It acts with the external oblique muscle of the opposite side to achieve this torsional movement of the trunk.',
    joints: ['pelvis', 'ribs', 'core', 'midBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['ribs', 'r10', 'r11', 'r12'],
    movements: 'Breathing, side bending',
    musclesSameCategory: [
      'externalOblique',
      'rectusAbdominis',
      'tranversusAbdominis',
      'psoasMinor',
      'psoasMajor',
      'quadratusLumborum',
      'serratusPosteriorInferior',
    ],
  },
  externalOblique: {
    id: 'externalOblique',
    name: 'External oblique',
    origin: ['External surfaces of ribs 5-12'],
    insertion: ['Linea alba', 'Pubic tubercle', 'Interior half of iliac crest'],
    function: [
      'Bilateral contraction - Trunk flexion, compresses abdominal viscera, expiration',
      'Unilateral contraction - Trunk lateral flexion (ipsilateral), trunk rotation (contralateral)',
    ],
    innervation:
      'Motor: Intercostal nerves (T7- T11), Subcostal nerve (T12), Sensory: Iliohypogastric nerve (L1) ',
    bloodSupply: 'Lower intercostal arteries and the subcostal artery',
    description:
      'When acting unilaterally and in synergy with the contralateral internal abdominal oblique, it rotates the trunk to the opposite side. Working together with the ipsilateral abdominal and back muscles, it contributes to lateral flexion of the trunk on the same side. When contracting bilaterally, the muscle works together with internal abdominal oblique and rectus abdominis to flex the trunk anteriorly. This activity also increases the tone of abdominal wall and positive intra-abdominal pressure, which is part of various physiological processes such as forced exhalation and labor.',
    joints: [
      'pelvis',
      'ribs',
      'ribsMid',
      'ribsBot',
      'core',
      'midBody',
      'fullBody',
    ],
    originJoints: ['ribs', 'r5', 'r6', 'r7', 'r8', 'r9', 'r10', 'r11', 'r12'],
    insertJoints: ['pelvis'],
    movements:
      'Flexion of the torso and contralateral rotation of torso, forced breathing',
    musclesSameCategory: [
      'externalOblique',
      'rectusAbdominis',
      'tranversusAbdominis',
      'psoasMinor',
      'psoasMajor',
      'quadratusLumborum',
      'serratusPosteriorInferior',
    ],
  },
  quadratusLumborum: {
    id: 'quadratusLumborum',
    name: 'Quadratus lumborum',
    origin: ['Iliac crest, iliolumbar ligament'],
    insertion: [
      'Inferior border of rib 12',
      'Transverse processes of vertebrae L1-L4',
    ],
    function: [
      'Bilateral contraction - fixes Ribs 12 during inspiration, trunk extension',
      'Unilateral contraction - lateral flexion of trunk (ipsilateral)',
    ],
    innervation: 'Subcostal nerve (T12), anterior rami of spinal nerves L1-L4',
    bloodSupply: 'Lumbar, median sacral, iliolumbar and subcostal arteries',
    description:
      'This muscle contributes to the stabilization and movement of the spine and the pelvis. A bilateral contraction leads to an extension of the lumbar vertebral column. When the muscle is only activated on one side, the trunk is bent towards that direction (lateral flexion). In addition, the muscle fixes the 12th rib during movements of the thoracic cage and this way supports expiration (accessory muscle of expiration).',
    joints: [
      'pelvis',
      'lumbarSpine',
      'ribs',
      'ribsBot',
      'core',
      'spineBot',
      'hip',
      'midBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['spine', 'l1', 'l2', 'l3', 'l4', 'ribs', 'r12'],
    movements:
      'Stabilization and movement of mubar spine, supposrts expiration, bending to sides',
    musclesSameCategory: [
      'psoasMinor',
      'psoasMajor',
      'iliacus',
      'externalOblique',
      'internalOblique',
      'tranversusAbdominis',
      'serratusPosteriorInferior',
    ],
  },
  psoasMinor: {
    id: 'psoasMinor',
    name: 'Psoas minor',
    origin: ['Vertebral bodies of T12 & L1 vertebrae'],
    insertion: ['Iliopubic eminence, pecten pubis'],
    function: ['Weak trunk flexion'],
    innervation: 'Anterior ramus of spinal nerve L1',
    bloodSupply: 'Lumbar arteries',
    description:
      'Psoas minor is very weak muscle that does not contribute in any special functionality, thus its exact function is not entirely clear. It is suggested that it assists in trunk flexion.',
    joints: [
      'pelvis',
      'lumbarSpine',
      'core',
      'spineBot',
      'midBody',
      'fullBody',
      'lowerBody',
    ],
    originJoints: ['spine', 't12', 'l1'],
    insertJoints: ['pelvis'],
    movements: 'Weak trunk flexion',
    musclesSameCategory: [
      'psoasMajor',
      'iliacus',
      'sartorius',
      'quadratusLumborum',
      'externalOblique',
      'internalOblique',
      'tranversusAbdominis',
      'serratusPosteriorInferior',
    ],
  },
  psoasMajor: {
    id: 'psoasMajor',
    name: 'Psoas major',
    origin: [
      'Vertebral bodies of T12-L4',
      'Intervertebral discs between T12-L4',
      'Transverse processes of L1-L5 vertebrae',
    ],
    insertion: ['Lesser trochanter of femur as iliopsoas tendon'],
    function: [
      'Hip joint: Flexion of the thigh/trunk, lateral rotation of the thigh, lateral flexion of the trunk',
    ],
    innervation: 'Anterior rami of spinal nerves L1-L3',
    bloodSupply: 'Lumbar branch of iliolumbar artery',
    description:
      'The actions of psoas major are flexion and lateral rotation of the thigh at the hip. It also flexes the trunk at the hip and flexes the trunk laterally. Psoas major is also a postural muscle. During standing, it stabilizes the vertebral column by maintaining normal lumbar lordosis and indirect thoracic kyphosis.',
    joints: [
      'thoracicSpine',
      'lumbarSpine',
      'pelvis',
      'spineBot',
      'core',
      'femur',
      'midBody',
      'fullBody',
      'lowerBody',
    ],
    originJoints: ['spine', 't12', 'l1', 'l2', 'l3', 'l4', 'l5'],
    insertJoints: ['femur'],
    movements:
      'Flexion and lateral rotation of thigh, flexion of trunk at the hip, stabilization of lumbar spine',
    musclesSameCategory: [
      'psoasMinor',
      'iliacus',
      'sartorius',
      'quadratusLumborum',
      'externalOblique',
      'internalOblique',
      'tranversusAbdominis',
      'serratusPosteriorInferior',
    ],
  },
  iliacus: {
    id: 'iliacus',
    name: 'Iliacus',
    origin: ['Fossa iliaca'],
    insertion: ['Trochanter minor femoris'],
    function: ['Thigh/ trunk flexion'],
    innervation: 'Femoral nerve (L1-L3)',
    bloodSupply:
      'Arteria iliolumbalis, arteria circumflexa iliaca profunda, arteria obturatoria, arteria femoralis',
    description:
      'The iliacus muscle works in synergy with the psoas major muscle to produce movements in the hip joint. When its proximal attachment is fixed, the muscle contributes to the flexion of the thigh. On the other hand, with its distal attachment fixed, the muscle helps to bring the trunk forwards against resistance. These actions are essential for lower limb functions such as walking, running and jumping.',
    joints: [
      'pelvis',
      'thigh',
      'core',
      'hip',
      'femur',
      'midBody',
      'fullBody',
      'lowerBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'Hip join movement, flexion of the thigh',
    musclesSameCategory: [
      'psoasMinor',
      'psoasMajor',
      'sartorius',
      'quadratusLumborum',
      'externalOblique',
      'internalOblique',
      'tranversusAbdominis',
      'serratusPosteriorInferior',
    ],
  },
  sartorius: {
    id: 'sartorius',
    name: 'Sartorius',
    origin: ['Anterior superior iliac spine (ASIS)'],
    insertion: ['Proximal end of tibia below medial condyle '],
    function: [
      'Hip joint: thigh flexion, thigh abduction, thigh external rotation',
      'Knee joint: leg flexion, leg internal rotation',
    ],
    innervation: 'Femoral nerve (L2-L3)',
    bloodSupply:
      'Proximal third: branches of femoral artery, deep femoral artery, artery of quadriceps, lateral circumflex femoral artery; Middle third: branches of femoral artery; Distal third: branches of femoral and descending genicular arteries',
    description:
      'The sartorius muscle crosses both the hip and knee joints, producing movements on both of them. At the hip joint it is capable of flexion, external rotation and abduction of the leg. The contraction of sartorius can also cause flexion of the knee joint and inward, or medial, rotation of the tibia against the femur.',
    joints: [
      'pelvis',
      'tibia',
      'thigh',
      'knee',
      'hip',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['tibia'],
    movements:
      'Flexion, abduction and lateral rotation of the hip, extension of the knee',
    musclesSameCategory: [
      'psoasMinor',
      'psoasMajor',
      'iliacus',
      'gluteusMedius',
      'gluteusMinimus',
      'pectineus',
      'gracilis',
      'adductorLongus',
      'tensorFaciaeLatae',
    ],
  },
  tensorFaciaeLatae: {
    id: 'tensorFaciaeLatae',
    name: 'Tensor fasciae latae',
    origin: [
      'Outer lip of anterior iliac crest',
      'Anterior superior iliac spine (ASIS)',
    ],
    insertion: ['Lateral condyle of tibia'],
    function: [
      'Hip joint: Thigh internal rotation, (Weak abduction)',
      'Knee joint: Leg external rotation, (Weak leg flexion/ extension)',
      'Stabilizes hip & knee joints',
    ],
    innervation: 'Superior gluteal nerve (L4-S1)',
    bloodSupply: 'Ascending branch of lateral circumflex femoral artery',
    description:
      'The tensor fasciae latae plays a crucial role in maintaining stability in the hip and thigh. It helps to counteract the pressure on the femur as the thigh meets the pelvis by sustaining tension in the iliotibial tract. Additionally, the muscle is responsible for hip abduction, flexion, and internal rotation. It works in conjunction with other hip and thigh muscles to provide stability during physical activity.',
    joints: [
      'pelvis',
      'tibia',
      'hip',
      'thigh',
      'knee',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['tibia'],
    movements:
      'Stability of hip and thigh, hip abduction, flexion and internal rotation',
    musclesSameCategory: [
      'gluteusMedius',
      'gluteusMaximus',
      'gluteusMinimus',
      'vastusLateralis',
      'rectusFemoris',
      'externalOblique',
      'internalOblique',
    ],
  },
  rectusFemoris: {
    id: 'rectusFemoris',
    name: 'Rectus femoris',
    origin: ['Anterior inferior iliac spine', 'Supraacetabular groove'],
    insertion: ['Tibial tuberosity'],
    function: ['Hip joint: Thigh flexion', 'Knee joint: Leg extension'],
    innervation: 'Femoral nerve (L2-L4)',
    bloodSupply:
      'Femoral, lateral femoral circumflex, superficial circumflex iliac arteries',
    description:
      'The rectus femoris is one of the four muscles that make up the quadriceps muscle group in the thigh. Its main function is to extend the knee, bringing the lower leg forward, as well as to flex the hip, bringing the thigh towards the abdomen. This muscle is an important muscle in sports such as soccer, cycling, and running, as it is used in activities that require quick, explosive movements of the legs. It also plays an important role in maintaining balance and stability in the hip and knee during activities such as standing, walking and running.',
    joints: [
      'quadriceps',
      'pelvis',
      'knee',
      'tibia',
      'thigh',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['tibia', 'knee'],
    movements: 'Knee extension, hip flexion',
    musclesSameCategory: [
      'vastusIntermedius',
      'vastusMedialis',
      'vastusLateralis',
      'tensorFaciaeLatae',
      'sartorius',
      'pectineus',
      'adductorLongus',
      'adductorBrevis',
      'adductorMagnus',
    ],
  },
  vastusMedialis: {
    id: 'vastusMedialis',
    name: 'Vastus medialis',
    origin: [
      'Intertrochanteric line',
      'Pectineal line of femur',
      'Linea aspera',
      'Medial supracondylar line of femur',
    ],
    insertion: ['Tibial tuberosity', 'Patella'],
    function: ['Knee joint: Leg extension'],
    innervation: 'Femoral nerve (L2-L4}',
    bloodSupply: 'Femoral, deep femoral, descending genicular artery',
    description:
      ' It is involved in knee extension of the knee joint, along with the other muscles which make up the quadriceps muscle. When the muscle contracts, it pulls on the patella, which in turn straightens the knee joint, extending the leg at the knee. In addition to its primary function, the vastus medialis also plays a role in stabilizing the knee joint during movement. When the knee is straight, the vastus medialis works to keep the patella properly aligned and centered in the femoral groove. This helps to prevent the patella from dislocating or moving out of place.',
    joints: [
      'quadriceps',
      'knee',
      'thigh',
      'pelvis',
      'femur',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur'],
    insertJoints: ['tibia', 'knee'],
    movements: 'Extension and stabilization of knee',
    musclesSameCategory: [
      'vastusIntermedius',
      'vastusLateralis',
      'rectusFemoris',
      'sartorius',
      'pectineus',
      'adductorLongus',
      'adductorBrevis',
      'adductorMagnus',
    ],
  },
  vastusLateralis: {
    id: 'vastusLateralis',
    name: 'Vastus lateralis',
    origin: [
      'Intertrochanteric line',
      'Greater trochanter',
      'Gluteal tuberosity',
      'Linea aspera of femur',
    ],
    insertion: ['Tibial tuberosity', 'Patella'],
    function: ['Knee joint: Extension of knee'],
    innervation: 'Femoral nerve (L2-L4)',
    bloodSupply:
      'Lateral circumflex femoral and deep femoral arteries; artery to quadriceps',
    description:
      'The vastus lateralis also called the vastus externus, is the largest and most powerful part of the quadriceps femoris, a muscle in the thigh. Together with other muscles of the quadriceps group, it serves to extend the knee joint, moving the lower leg forward. It arises from a series of flat, broad tendons attached to the femur, and attaches to the outer border of the patella. It ultimately joins with the other muscles that make up the quadriceps in the quadriceps tendon, which travels over the knee to connect to the tibia.',
    joints: [
      'quadriceps',
      'thigh',
      'pelvis',
      'knee',
      'femur',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur'],
    insertJoints: ['tibia', 'knee'],
    movements: 'Extension of knee',
    musclesSameCategory: [
      'vastusIntermedius',
      'vastusMedialis',
      'rectusFemoris',
      'sartorius',
      'pectineus',
      'adductorLongus',
      'adductorBrevis',
      'adductorMagnus',
    ],
  },
  vastusIntermedius: {
    id: 'vastusIntermedius',
    showPremium: true,
    name: 'Vastus intermedius',
    origin: ['Anterior surface of femoral shaft'],
    insertion: ['Tibial tuberosity'],
    function: ['Knee joint: Leg extension'],
    innervation: 'Femoral nerve (L2-L4)',
    bloodSupply: 'Artery to quadriceps, deep femoral arteries',
    description:
      'The vastus intermedius arises from the front and lateral surfaces of the body of the femur in its upper two-thirds, sitting under the rectus femoris muscle and from the lower part of the lateral intermuscular septum. Its fibers end in a superficial aponeurosis, which forms the deep part of the quadriceps femoris tendon. ',
    joints: [
      'quadriceps',
      'thigh',
      'pelvis',
      'knee',
      'femur',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur'],
    insertJoints: ['tibia', 'knee'],
    movements: 'Extension of knee',
    musclesSameCategory: [
      'vastusLateralis',
      'vastusMedialis',
      'rectusFemoris',
      'sartorius',
      'pectineus',
      'adductorLongus',
      'adductorBrevis',
      'adductorMagnus',
    ],
  },
  gracilis: {
    id: 'gracilis',
    name: 'Gracilis',
    origin: ['Anterior body of pubis', 'Inferior pubic ramus', 'Ischial ramus'],
    insertion: ['Medial surface of proximal tibia'],
    function: [
      'Hip joint: Thigh flexion, thigh adduction',
      'Knee joint: leg flexion, leg internal rotation',
    ],
    innervation: 'Obturator nerve (L2-L3)',
    bloodSupply: 'Deep femoral artery (via artery to the adductors)',
    description:
      'Strong leg flexion and medial (internal) rotation around the knee joint when the knee is in a semiflexed position. Weak thigh flexion and adduction around the hip joint, simply aiding the other, more powerful thigh adductors. The most important function of the gracilis is to help the hamstring muscles flex the knee, for example during the initial swing phase in walking. ',
    joints: ['pelvis', 'knee', 'tibia', 'thigh', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['tibia'],
    movements:
      'Leg flexion, medial rotation of leg, thight flexion and adduction',
    musclesSameCategory: [
      'adductorBrevis',
      'adductorLongus',
      'pectineus',
      'sartorius',
    ],
  },
  adductorLongus: {
    id: 'adductorLongus',
    name: 'Adductor longus',
    origin: [
      'Body of pubis inferior to pubic crest',
      'Lateral to the pubic symphysis',
    ],
    insertion: ['Middle third of linea aspera of femur'],
    function: [
      'Hip joint: Thigh flexion, Thigh adduction, Thigh external rotation',
      'Pelvis stabilization',
    ],
    innervation: 'Obturator nerve (L2-L4)',
    bloodSupply: 'Deep femoral artery, obturator artery',
    description:
      'The main action of the adductor group of muscles is to adduct the thigh at the hip joint. The adductor longus muscle also participates in external/lateral rotation and flexion of the thigh. The adductors help to stabilize the stance while standing, and also have an important role in balancing the body on the lower limb during walking.',
    joints: ['pelvis', 'thigh', 'femur', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements:
      'Adduction of the thigh, weak external rotation and flexion of thight',
    musclesSameCategory: [
      'adductorBrevis',
      'adductorMagnus',
      'gracilis',
      'psoasMajor',
      'pectineus',
      'rectusFemoris',
      'vastusIntermedius',
      'vastusMedialis',
      'vastusLateralis',
      'rectusAbdominis',
      'tranversusAbdominis',
    ],
  },
  adductorBrevis: {
    id: 'adductorBrevis',
    showPremium: true,
    name: 'Adductor brevis',
    origin: ['Anterior body of pubis', 'Inferior pubic ramus'],
    insertion: ['Linea aspera of femur (medial lip)'],
    function: [
      'Hip joint: thigh flexion, thigh adduction, thigh external rotation',
      'Pelvis stabilization',
    ],
    innervation: 'Obturator nerve (L2-L4)',
    bloodSupply: 'Arteria profunda femoris',
    description:
      'The main function of the adductor brevis muscle is adduction of the thigh. This action is particularly active when the thigh is in a flexed position and during the gait cycle. Adductor brevis also has a role in external rotation and flexion of the hip. ',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'Adduction and flexion of the thigh, pelvis stabilization  ',
    musclesSameCategory: [
      'adductorLongus',
      'adductorMagnus',
      'gracilis',
      'psoasMajor',
      'pectineus',
      'rectusFemoris',
      'vastusIntermedius',
      'vastusMedialis',
      'vastusLateralis',
      'rectusAbdominis',
      'tranversusAbdominis',
    ],
  },
  adductorMagnus: {
    id: 'adductorMagnus',
    name: 'Adductor magnus',
    origin: [
      'Adductor part: ramus inferior ossis pubis, ramus ossis ischii',
      'Ischiocondylar part: tuber ischiadicum',
    ],
    insertion: [
      'Adductor part: tuberositas glutea femoris, linea aspera femoris (medial lip), linea supracondylaris medialis femoris',
      'Ischiocondylar part: tuberculum adductorium femoris',
    ],
    function: [
      'Adductor part: Thigh flexion, thigh adduction, thigh external rotation',
      'Hamstring part: Thigh extension, thigh internal rotation ',
    ],
    innervation:
      'Adductor part: Obturator nerve (L2-L4); Ischiocondylar part: Divisio tibialis nervi ischiadici (L4)',
    bloodSupply:
      'Arteria profunda femoris; Arteria femoralis, arteria poplitea, arteriae genu',
    description:
      'The adductor magnus muscle is a powerful adductor of the thigh, working in a coordinated fashion with the adductor longus and brevis and pectineus muscles. In addition to thigh adduction, all of these muscles serve as important stabilizers of the pelvis on the lower limb during walking. Besides adducting the thigh, the adductor part also contributes to the flexion of the thigh. The hamstring part adducts the thigh as well, but also cooperates with the hamstring group of muscles and assists the extension of the thigh. The fibers of the adductor part of the muscle that attach to the linea aspera can also act as lateral rotators due to their oblique attachment. It is also believed that, together with the adductor longus, this muscle acts as a medial rotator of the thigh.',
    joints: [
      'pelvis',
      'femur',
      'thigh',
      'knee',
      'hip',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements:
      'Flexion, adduction, external rotation, extension and internal rotation of the thigh',
    musclesSameCategory: [
      'adductorLongus',
      'adductorBrevis',
      'gracilis',
      'psoasMajor',
      'pectineus',
      'rectusFemoris',
      'vastusIntermedius',
      'vastusMedialis',
      'vastusLateralis',
      'rectusAbdominis',
      'tranversusAbdominis',
    ],
  },
  pectineus: {
    id: 'pectineus',
    name: 'Pectineus',
    origin: ['Superior pubic ramus'],
    insertion: ['Pectineal line of femur', 'Linea aspera of femur'],
    function: [
      'Hip joint: Thigh flexion, thigh adduction, thigh external rotation, thigh internal rotation',
      'Pelvis stabilization',
    ],
    innervation: 'Femoral nerve (L2, L3)',
    bloodSupply: 'Medial femoral circumflex artery, obturator artery',
    description:
      'Due to the course of its fibers, pectineus both flexes and adducts the thigh at the hip joint when it contracts. When the lower limb is in the anatomical position, contraction of the muscle first causes flexion to occur at the hip joint. This flexion can go as far as the thigh being at a 45 degree angle to the hip joint. At that point, the angulation of the fibers is such that the contracted muscle fibers now pull the thigh towards the midline, producing thigh adduction.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements:
      'Flexion, adduction, external and internal rotation of the thigh, Pelvis stabilization',
    musclesSameCategory: [
      'adductorLongus',
      'adductorBrevis',
      'adductorMagnu',
      's gracilis',
      'psoasMajor',
      'rectusFemoris',
      'vastusIntermedius',
      'vastusMedialis',
      'vastusLateralis',
      'rectusAbdominis',
      'tranversusAbdominis',
    ],
  },
  gluteusMaximus: {
    id: 'gluteusMaximus',
    showPremium: true,
    name: 'Gluteus maximus',
    origin: [
      'Lateroposterior surface of sacrum and coccyx',
      'Gluteal surface of ilium',
      'Thoracolumbar fascia',
      'Sacrotuberous ligament',
    ],
    insertion: ['Iliotibial tract', 'Gluteal tuberosity of femur'],
    function: [
      'Hip joint: Thigh extension, thigh external rotation, thigh abduction (superior part), thigh adduction (inferior part)',
    ],
    innervation: 'Inferior gluteal nerve (L5, S1, S2)',
    bloodSupply: 'Inferior gluteal and superior gluteal arteries',
    description:
      'The gluteus maximus muscle exhibits four actions on the hip joint; extension, external rotation, abduction and adduction of the thigh. When its proximal attachment is fixed, gluteus maximus acts as the main extensor of the hip joint, pulling the shaft of the femur posteriorly. The external rotation of the thigh happens simultaneously with the extension, assisting in raising the medial longitudinal arch of the foot. On the contrary, when its distal attachment is fixed, gluteus maximus pulls the pelvis posteriorly. As it usually happens in synergy with the same action of the hamstring muscles, this action of gluteus maximus helps to bring the trunk from a flexed to an upright position. Furthermore, these muscles help in maintaining the upright posture by balancing the pelvis on femoral heads. The superior portion of the muscle participates in the abduction of the thigh, while the inferior part participates in its adduction. Additionally, the latter portion tenses the fascia lata and stabilizes the femur from the lateral aspect of the knee joint.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis', 'spine', 'sacrum', 'coccyx'],
    insertJoints: ['femur'],
    movements: 'Extension, external rotation, abduction and adduction of thigh',
    musclesSameCategory: [
      'gluteusMedius',
      'gluteusMinimus',
      'piriformis',
      'tensorFasciaeLatae',
      'bicepsFemoris',
      'semimembranosus',
      'semitendonosus',
      'quadratusFemoris',
    ],
  },
  gluteusMedius: {
    id: 'gluteusMedius',
    name: 'Gluteus medius',
    origin: [
      'Gluteal surface of ilium (between anterior and posterior gluteal lines)',
    ],
    insertion: ['Lateral aspect of greater trochanter of femur'],
    function: [
      'Hip joint: Thigh abduction, thigh internal rotation',
      'Pelvis stabilization',
    ],
    innervation: 'Superior gluteal nerve (L4-S1)',
    bloodSupply:
      'Deep branch of superior gluteal artery, trochanteric anastomosis ',
    description:
      'The gluteus medius plays a crucial role in stability, balance, and posture, and is important for many everyday activities and movements, such as walking, running, and climbing stairs. When the proximal attachment of gluteus medius is fixed, the muscle can contract as a whole or it can contract with its anterior fibers only. In the former case, the axis of the movement goes through the hip joint and muscle pulls the greater trochanter superiorly and abducts the thigh. In the latter case, as the axis of the movement tilts anteriorly, the muscle causes the internal rotation of the thigh.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'Thigh abduction and internal rotation, pelvis stabilization',
    musclesSameCategory: [
      'gluteusMinimus',
      'gluteusMaximus',
      'piriformis',
      'tensorFasciaeLatae',
      'sartorius',
      'pectineus',
      'psoasMajor',
      'psoasMinor',
      'quadratusFemoris',
    ],
  },
  gluteusMinimus: {
    id: 'gluteusMinimus',
    name: 'Gluteus minimus',
    origin: ['Gluteal surface of the ilium'],
    insertion: ['Anterior aspect of greater trochanter of femur'],
    function: [
      'Hip joint: Abduction of thigh, internal rotation of thigh',
      'Pelvis stabilization',
    ],
    innervation: 'Superior gluteal nerve (L4-S1)',
    bloodSupply:
      'Deep branch of superior gluteal artery;  trochanteric anastomosis; medial circumflex femoral artery; inferior gluteal arteries',
    description:
      'The functions of the gluteus minimus muscle are closely related to those of gluteus medius, as these two muscles act in synergy on the hip joint. When their pelvic attachments are fixed, both muscles pull the greater trochanter superiorly and abduct the thigh. However, if only their anterior portions contract, the axis of the movement tilts anteriorly and they produce the internal rotation of the thigh.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'Thigh abduction and internal rotation, pelvis stabilization',
    musclesSameCategory: [
      'gluteusMedius',
      'gluteusMaximus',
      'piriformis',
      'tensorFasciaeLatae',
      'sartorius',
      'psoasMajor',
      'psoasMinor',
      'quadratusFemoris',
    ],
  },
  piriformis: {
    id: 'piriformis',
    name: 'Piriformis',
    origin: [
      'Anterior surface of the sacrum (between the S2 and S4)',
      'Gluteal surface of ilium',
    ],
    insertion: ['Greater trochanter of the femur'],
    function: [
      'Hip joint: Thigh external rotation, Thigh abduction',
      'Stabilizes head of femur in acetabulum',
    ],
    innervation: 'Nerve to piriformis (S1-S2)',
    bloodSupply:
      'Superior gluteal artery, inferior gluteal artery, gemellar branches of the internal pudendal',
    description:
      'In combination with the other posterior hip muscles, the piriformis stabilizes the hip joint by steadying the head of the femur inside the acetabulum of the hip bone. It also contributes to the external rotation of the hip joint, in addition to abduction of the thigh while in the flexed position.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis', 'spine', 'sacrum'],
    insertJoints: ['femur'],
    movements: 'Stabilization and external rotation  of hip joint',
    musclesSameCategory: [
      'gluteusMedius',
      'gluteusMinimus',
      'gluteusMaximus',
      'tensorFasciaeLatae',
      'sartorius',
      'psoasMajor',
      'psoasMinor',
      'quadratusFemoris',
    ],
  },
  quadratusFemoris: {
    id: 'quadratusFemoris',
    name: 'Quadratus femoris',
    origin: ['Ischial tuberosity'],
    insertion: ['Intertrochanteric crest of femur'],
    function: [
      'Hip joint: thigh external rotation',
      'Stabilizes head of femur in acetabulum ',
    ],
    innervation: 'Nerve to quadratus femoris (L4-S1)',
    bloodSupply: 'Inferior gluteal artery',
    description:
      'The main action of the quadratus femoris muscle is external (lateral) rotation of the thigh. Although this is a small muscle it is a pretty powerful rotator of the hip, acting synergically with the other external rotators: gluteus maximus, piriformis, superior gemellus and inferior gemellus and obturator internus muscles. Acting together, these muscles produce a strong external rotation of the thigh which is limited by antagonistic muscles and the iliofemoral ligament. Quadratus femoris muscle is unusual in that it can assist in the production of both abduction and adduction movements of the leg. When the leg is in the anatomical position, quadratus femoris, pectineus and the inferior fibers of gluteus maximus assist the thigh adductor muscles (adductor longus, magnus and brevis) to adduct the thigh at the hip joint. In contrast, when the hip is flexed, quadratus femoris, along with piriformis, superior and inferior gemelli, obturator internus and obturator externus all act to abduct the thigh.  Due to its specific location inside the hip joint, the quadratus femoris muscle is one of the most important stabilizers of this joint. It keeps the head of the femur in place and thus prevents injuries in numerous activities.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'External rotation of hip, stabilizes head of femur',
    musclesSameCategory: [
      'piriformis',
      'gluteusMedius',
      'gluteusMinimus',
      'gluteusMaximus',
      'iliacus',
      'psoasMajor',
      'psoasMinor',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  bicepsFemoris: {
    id: 'bicepsFemoris',
    name: 'Biceps femoris',
    origin: [
      'Long head: (inferomedial impression of) ischial tuberosity, sacrotuberous ligament ',
      'Short head: linea aspera of femur (lateral lip), lateral supracondylar line of femur',
    ],
    insertion: ['(Lateral aspect of) head of fibula'],
    function: [
      'Hip joint: thigh extension, thigh external rotation ',
      'Knee joint: leg flexion, leg external rotation',
      'Pelvis stabilization',
    ],
    innervation:
      'Long head: tibial division of sciatic nerve (L5-S2); Short head: common fibular division of sciatic nerve (L5-S2) ',
    bloodSupply:
      'Inferior gluteal artery, perforating arteries, popliteal artery',
    description:
      'In general, the biceps femoris muscle acts on both the knee and hip joints. Although, due to its attachments, the short head of this muscle acts only on the knee joint while the long head acts on both. When acting on the hip joint, biceps femoris produces the movement of hip extension. This action is the strongest when the trunk is bent forward and is to be brought in an upright position. The biceps femoris is also sometimes described as assisting with external rotation (when the hip joint is in an extended position). When acting at the knee joint, the most prominent action of the biceps femoris muscle is flexion of the leg. This occurs when the lower limb is in an anatomical position. In contrast, when the knee is semiflexed, biceps femoris acts to produce external rotation of the leg at the knee. Together with other hamstring muscles, biceps femoris stabilizes pelvis, especially during the forward flexion of the trunk occurs.',
    joints: [
      'pelvis',
      'femur',
      'thigh',
      'fibula',
      'hamstrings',
      'knee',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur', 'pelvis'],
    insertJoints: ['fibula'],
    movements:
      'Extension and external rotation of the thigh, flexion and external rotation of leg, pelvis stabilization',
    musclesSameCategory: [
      'semimembranosus',
      'semitendonosus',
      'adductorMagnus',
      'adductorLongus',
      'sartorius',
      'gastrocnemius',
      'popliterius',
      'plantaris',
    ],
  },
  semitendinosus: {
    id: 'semitendinosus',
    name: 'Semitendonosus',
    origin: ['(Posteromedial impression of) Ischial tuberosity'],
    insertion: [
      'Proximal end of tibia below medial condyle (via pes anserinus)',
    ],
    function: [
      'Hip joint: Thigh extension, thigh internal rotation, stabilizes pelvis',
      'Knee joint: Leg flexion, leg internal rotation',
    ],
    innervation: 'Tibial division of sciatic nerve (L5-S2)',
    bloodSupply:
      'First perforating branch of deep femoral artery, medial femoral circumflex artery, inferior gluteal artery and inferior medial geniculate artery',
    description:
      'Attaching between the hip and leg, the semitendinosus produces movements on both hip and knee joints producing thigh extension, internal rotation, pelvis stabilization (hip joint), leg flexion and internal rotation (knee joint). There are two functions when  its tibial attachment is fixed. When the body\u2019s in the anatomical position, it internally rotates the thigh. If the trunk is flexed anteriorly, this muscle extends the thigh. In case ischial attachment is fixed, semitendinosus has two functions on the knee joint. When the lower limb is in the anatomical position, it flexes the leg. If the knee is semiflexed, semitendinosus internally rotates the leg acting together with all the hamstring muscles. Besides stabilizing the pelvis together with all the short hip muscles that attach between the pelvis and proximal femur, semitendinosus also acts to stabilize the knee.',
    joints: [
      'pelvis',
      'femur',
      'thigh',
      'tibia',
      'hamstrings',
      'knee',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['tibia'],
    movements:
      'Extension and internal rotation of the thigh, flexion and internal rotation of leg, pelvis stabilization',
    musclesSameCategory: [
      'semimembranosus',
      'bicepsFemoris',
      'adductorMagnus',
      'adductorLongus',
      'sartorius',
      'gastrocnemius',
      'popliterius',
      'plantaris',
    ],
  },
  semimembranosus: {
    id: 'semimembranosus',
    name: 'Semimembranosus',
    origin: ['Superolateral impression of ischial tuberosity'],
    insertion: ['Medial condyle of tibia'],
    function: [
      'Hip joint: thigh extension and internal rotation',
      'Knee joint: flexion and internal rotation of the leg',
      'Pelvis stabilization',
    ],
    innervation: 'Tibial division of sciatic nerve (L5 \u2013 S2)',
    bloodSupply: 'Perforating branches of femoral and popliteal arteries',
    description:
      'Semimembranosus extends across both the hip and knee joints and is consequently responsible for multiple movements about the joints. However, semimembranosus works in conjunction with the other hamstring muscles to carry out its function. When the feet are firmly planted on the ground, semimembranosus causes extension at the hip, which pulls the upper torso to go into an erect position. Semimembranosus (along with semitendinosus) can also cause internal rotation of the thigh when the hip is fully extended. When the legs are suspended off the ground, it causes flexion of the knee and internal rotation of the leg on the thigh. Semimembranosus and the other posterior thigh muscles are inactive whenever an individual is standing symmetrically. However, once the individual tilts too far forward, semimembranosus is activated and counteracts the forward movement; thus stabilizing the hip.',
    joints: [
      'pelvis',
      'tibia',
      'thigh',
      'hamstrings',
      'knee',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['tibia'],
    movements:
      'Extension and internal rotation of the thigh, flexion and internal rotation of leg, pelvis stabilization',
    musclesSameCategory: [
      'semitendonosus',
      'bicepsFemoris',
      'adductorMagnus',
      'adductorLongus',
      'sartorius',
      'gastrocnemius',
      'popliterius',
      'plantaris',
    ],
  },
  gastrocnemius: {
    id: 'gastrocnemius',
    name: 'Gastrocnemius',
    origin: [
      'Lateral head: Posterolateral aspect of lateral condyle of the femur',
      'Medial head: Posterior surface of medial femoral condyle, popliteal surface of femoral shaft ',
    ],
    insertion: ['Posterior surface of the calcaneus via the calcaneal tendon'],
    function: [
      'Talocrural joint: Foot plantar flexion',
      'Knee joint: Leg flexion',
    ],
    innervation: 'Tibial nerve (S1, S2)',
    bloodSupply: 'Lateral and medial sural arteries',
    description:
      'The gastrocnemius is a powerful plantar flexor of the foot at the talocrural joint (ankle). It also flexes the leg at the knee. The actions of gastrocnemius are usually considered along with soleus, as the triceps surae group. They are the chief plantar flexors of the foot. The muscles are usually large and powerful. Gastrocnemius provides the force behind propulsion for walking, running and jumping.',
    joints: [
      'femur',
      'calcaneus',
      'foot',
      'knee',
      'lowLeg',
      'ankle',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur'],
    insertJoints: ['calcaneus', 'foot'],
    movements: 'Plantar flexion of foot, leg flexion',
    musclesSameCategory: [
      'soleus',
      'popliterius',
      'plantaris',
      'fibularisLongus',
      'fibularisPosterior',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  soleus: {
    id: 'soleus',
    name: 'Soleus',
    origin: [
      'Soleal line',
      'Medial and posterior border of tibia',
      'Head of fibula',
    ],
    insertion: ['Posterior surface of calcaneus'],
    function: ['Talocrural joint: Foot plantar flexion'],
    innervation: 'Tibial nerve (S1, S2)',
    bloodSupply: 'Popliteal artery and peroneal artery',
    description:
      "The soleus\u2019 function is closely related to that of the gastrocnemius muscle. Together, they constitute a chief plantar flexor - their contraction results in the plantar flexion of the upper ankle joint, enabling the lifting of the heel against gravity when walking or jumping. As the body's center of gravity is anterior to the ankle joint, the body has a natural tendency to lean forward. This is counteracted by a continuous state of plantar flexion produced mainly by the soleus when standing. For this reason, the soleus consists mostly of type 1 slow, fatigue-resistant fibers. The gastrocnemius muscle is more involved in locomotion where it contributes slightly to the flexion of the knee as it crosses multiple joints. When the knee is flexed, plantar flexion attributes of the gastrocnemius are very limited, making the soleus the main muscle responsible for plantar flexion.",
    joints: [
      'tibia',
      'fibula',
      'calcaneus',
      'foot',
      'lowLeg',
      'ankle',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['tibia', 'fibula'],
    insertJoints: ['calcaneus', 'foot'],
    movements: 'Foot plantarflexion',
    musclesSameCategory: [
      'gastrocnemius',
      'popliterius',
      'plantaris',
      'fibularisLongus',
      'fibularisPosterior',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  popliterius: {
    id: 'popliterius',
    name: 'Popliterius',
    origin: [
      'Lateral condyle of femur',
      'Posterior horn of lateral meniscus of knee joint',
    ],
    insertion: ['Posterior surface of proximal tibia'],
    function: ['Unlocks knee joint; Knee joint stabilization'],
    innervation: 'Tibial nerve (L4-S1)',
    bloodSupply:
      'Inferior medial and lateral genicular arteries (popliteal artery), posterior tibial recurrent artery, posterior tibial artery, nutrient artery of tibia',
    description:
      'Despite its anatomical classification, the role of the popliteus muscle as a flexor of the knee is quite insignificant. However, the popliteus muscle plays an important role in initiating flexion of the fully extended (\u201clocked\u201d) knee. Thus, the popliteus is referred to as \u201cthe key to unlock the knee\u201d, making it essential for the processes of walking, standing up, and sitting down. The popliteus muscle is also the main stabilizer of the dorsal knee region via its connections to the posterior joint capsule and popliteofibular ligament. In addition, the popliteus muscle pulls the lateral meniscus dorsally during knee flexion and femoral lateral rotation, thus preventing its entrapment.',
    joints: ['knee', 'tibia', 'lowLeg', 'lowerBody', 'fullBody'],
    originJoints: ['knee', 'femur'],
    insertJoints: ['tibia'],
    movements: 'Unlocks knee, knee stabilization',
    musclesSameCategory: [
      'plantaris',
      'soleus',
      'gastrocnemius',
      'fibularisLongus',
      'fibularisPosterior',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  plantaris: {
    id: 'plantaris',
    name: 'Plantaris',
    origin: [
      'Lateral supracondylar line of femur',
      'Oblique popliteal ligament of knee',
    ],
    insertion: ['Posterior surface of calcaneus'],
    function: [
      'Talocrural joint: foot plantar flexion',
      'Knee joint: knee flexion',
    ],
    innervation: 'Tibial nerve (S1, S2)',
    bloodSupply:
      'Superficially: lateral sural and popliteal arteries; Deeply: superior lateral genicular artery',
    description:
      'It is involved in plantar flexion of the foot at the ankle joint and in knee flexion at the knee joint. In reality, however, it weakly assists the gastrocnemius and soleus muscles to perform these two movements. ',
    joints: [
      'knee',
      'femur',
      'calcaneus',
      'foot',
      'lowLeg',
      'ankle',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur', 'knee'],
    insertJoints: ['calcaneus', 'foot'],
    movements: 'Plantar flexion of foot, knee flexion',
    musclesSameCategory: [
      'popliterius',
      'soleus',
      'gastrocnemius',
      'fibularisLongus',
      'fibularisPosterior',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  fibularisLongus: {
    id: 'fibularisLongus',
    name: 'Fibularis longus',
    origin: ['Head of fibula', 'Proximal 2/3 of lateral surface of fibula'],
    insertion: ['Medial cuneiform bone', 'Metatarsal bone 1'],
    function: [
      'Talocrural joint: Foot plantar flexion',
      'Subtalar joint: Foot eversion',
      'Supports longitudinal and transverse arches of foot',
    ],
    innervation: 'Superficial fibular nerve (L5, S1)',
    bloodSupply: 'Fibular artery',
    description:
      'The fibularis longus, together with the fibularis brevis and the tibialis posterior, extend the foot downward away from the body at the ankle (plantar flexion). The fibularis longus also tilts the sole of the foot away from the midline of the body (eversion). Because of the angle at which it crosses the sole of the foot, it plays an important role in maintaining the transverse arch of the foot. Together, the fibularis muscles help to steady the leg upon the foot, especially in standing on one leg.',
    joints: [
      'fibula',
      'foot',
      'lowLeg',
      'ankle',
      'thumbFoot',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['fibula'],
    insertJoints: ['foot', 'f1'],
    movements: 'Plantar flexion of foot, foot eversion, supports foot arches',
    musclesSameCategory: [
      'popliterius',
      'soleus',
      'gastrocnemius',
      'fibularisPosterior',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  tibialisPosterior: {
    id: 'tibialisPosterior',
    showPremium: true,
    name: 'Tibialis posterior',
    origin: [
      'Posterior surface of tibia',
      'Posterior surface of fibula and interosseous membrane',
    ],
    insertion: [
      'Tuberosity of navicular bone',
      'All cuneiform bones, cuboid bone',
      'Bases of metatarsal bones 2-4',
    ],
    function: [
      'Talocrural joint: Foot plantarflexion',
      'Subtalar joint: Foot inversion',
      'Supports medial longitudinal arch of foot',
    ],
    innervation: 'Tibial nerve (L4, L5)',
    bloodSupply: 'Branches of the posterior tibial artery',
    description:
      'Through its action on the ankle joint, tibialis posterior helps the other, more powerful foot flexors to elevate the heel when the foot is planted on the ground. This facilitates walking, running and various fitness exercises, such as calf raises. In addition, contraction of tibialis posterior approximates the tibia and fibula. This brings the malleoli together during plantar flexion, improving their grip on the talus and supporting the ankle. Inversion of the foot also has several important functions. Through this action, tibialis posterior resists the tendency of the body to sway laterally when standing on one leg, thus facilitating balance. This muscle also plays a support role by elevating, tensing and reinforcing the medial longitudinal arch of the foot. This action helps to distribute the body weight when the foot is planted on the ground.',
    joints: [
      'tibia',
      'fibula',
      'foot',
      'ankle',
      'lowLeg',
      'fingers4Foot',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['tibia', 'fibula'],
    insertJoints: ['foot', 'f2', 'f3', 'f4'],
    movements:
      'Plantarflexion and inversion of the foot, medial longitudinal arch of foot',
    musclesSameCategory: [
      'popliterius',
      'soleus',
      'gastrocnemius',
      'fibularisLongus',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  flexorDigitorumLongus: {
    id: 'flexorDigitorumLongus',
    name: 'Flexor digitorum longus',
    origin: ['Posterior surface of tibia'],
    insertion: ['Bases of distal phalanges of digits 2-5'],
    function: [
      'Metatarsophalangeal and interphalangeal joints 2-5: toe flexio',
      'Talocrural joint: foot plantar flexion',
      'Subtalar joint: foot inversion',
    ],
    innervation: 'Tibial nerve (L5, S1, S2)',
    bloodSupply: 'Posterior tibial artery',
    description:
      'As the name suggests, the main function of this muscle is plantarflexion of the foot at the ankle joint and the second to fifth phalanges at the metatarsophalangeal and interphalangeal joints. Plantarflexion is aided by the triceps surae muscle. Due to its attachments and course around the medial malleolus, flexor digitorum longus assists in foot inversion at the subtalar joint. The actions of flexor digitorum longus are crucial for the gait cycle. When the foot is off the ground, flexor digitorum longus muscle flexes the four lateral toes. This action starts in the distal interphalangeal joints and is followed by flexion in proximal interphalangeal and metatarsophalangeal joints respectively. When the foot is placed on the ground, flexor digitorum longus acts in synergy with the lumbrical and interossei muscles to maintain balance by keeping the toes in firm contact with the ground.',
    joints: [
      'tibia',
      'fingersFoot',
      'lowLeg',
      'foot',
      'ankle',
      'fingers4Foot',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['tibia'],
    insertJoints: ['fingersFoot', 'fingers4Foot'],
    movements: 'Toe flexion, foot plantarflexion and inversion',
    musclesSameCategory: [
      'popliterius',
      'soleus',
      'gastrocnemius',
      'fibularisLongus',
      'tibialisPosterior',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  tibialisAnterior: {
    id: 'tibialisAnterior',
    name: 'Tibialis anterior',
    origin: ['Lateral surface of tibia', 'Interosseus membrane'],
    insertion: ['Medial cuneiform bone', 'Base of metatarsal bone 1'],
    function: [
      'Talocrural joint: foot dorsiflexion',
      'Subtalar joint: foot inversion',
    ],
    innervation: 'Deep fibular nerve (L4, L5)',
    bloodSupply:
      'Branches of anterior tibial artery: Anterior and medial muscular branches; anterior tibial recurrent, dorsalis pedis and anterior medial malleolar arteries; Branches of posterior tibial artery: Medial malleolar and calcaneal arteries\n',
    description:
      'Tibialis anterior dorsiflexes the foot at the talocrural joint and inverts it at the subtalar joint. It plays an important role in the activities of walking, hiking and kicking the ball by stabilizing the ankle joint as the foot hits the floor and pull it clear of the ground as the leg continues moving. It also supports the medial part of the longitudinal arch of the foot during any movement.',
    joints: [
      'tibia',
      'foot',
      'thumbFoot',
      'ankle',
      'lowLeg',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['tibia'],
    insertJoints: ['foot', 'f1'],
    movements: 'Dorsiflexion and inversion of the foot',
    musclesSameCategory: [
      'fibularisLongus',
      'fibularisBrevis',
      'extensorDigitorumLongus',
      'extensorHallucisLongus',
    ],
  },
  fibularisBrevis: {
    id: 'fibularisBrevis',
    name: 'Fibularis brevis',
    origin: ['Distal 2/3 of the lateral surface of fibula'],
    insertion: ['Tuberosity of the 5th metatarsal bone'],
    function: [
      'Talocrural joint: Foot plantar flexion',
      'Subtalar joint: Foot eversion',
    ],
    innervation: 'Superficial fibular nerve (L5, S1)',
    bloodSupply: 'Anterior tibial artery',
    description:
      'Fibularis brevis crosses both the subtalar (talocalcaneal) and talocrural (ankle) joints and therefore contributes to the actions produced across these joints. The main function of fibularis brevis is to evert the foot at the subtalar joint, which helps to restore the foot to its anatomical position after it has been inverted. This is particularly important when running or walking on uneven terrain. Since fibularis brevis counteracts inversion of the foot, it may also prevent the body from falling to the opposite side when the individual is balancing on one leg. The muscle also causes plantarflexion of the foot at the ankle joint while it is everting it.',
    joints: [
      'fibula',
      'fingersFoot',
      'littleToe',
      'lowLeg',
      'fingers4Foot',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['fibula'],
    insertJoints: ['foot', 'f5'],
    movements: 'Plantar flexion and eversion of the foot',
    musclesSameCategory: [
      'fibularisLongus',
      'tibialisAnterior',
      'extensorDigitorumLongus',
      'extensorHallucisLongus',
    ],
  },
  extensorDigitorumLongus: {
    id: 'extensorDigitorumLongus',
    name: 'Extensor digitorum longus',
    origin: [
      'Medial surface of fibula',
      'Lateral tibial condyle',
      'Interosseus membrane',
    ],
    insertion: ['Distal and middle phalanges of digits 2-5'],
    function: [
      'Metatarsophalangeal and interphalangeal joints 2-5: Toe extension',
      'Talocrural joint: Foot dorsiflexion',
      'Subtalar joint: Foot eversion',
    ],
    innervation: 'Deep fibular nerve (L5, S1)',
    bloodSupply:
      'Anterior tibial, fibular, anterior lateral malleolar, lateral tarsal, metatarsal, plantar, and digital arteries',
    description:
      'The primary action of the extensor digitorum longus is to extend the lateral four toes at metatarsophalangeal joint. This means that when acting independently, it is unable to extend the entire length of the toes, extending only at metatarsophalangeal, while at the interphalangeal joints the toes remain flexed. However, contracting together with lumbricals which are the main extensors of the interphalangeal joints, this muscle contributes to extension at every joint between the bones of the lateral four toes.\nActing in synergy with tibialis anterior, extensor hallucis longus and fibularis tertius, this muscle participates in dorsiflexion of the foot when their proximal attachments are fixed. When the distal attachments are fixed and the body is in the anatomical position, all four muscles bring the trunk and lower limb to the front.',
    joints: [
      'fibula',
      'foot',
      'fingersFoot',
      'fingers4Foot',
      'ankle',
      'lowLeg',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['fibula'],
    insertJoints: ['foot', 'f2', 'f3', 'f4', 'f5'],
    movements: 'Toe extension, foot dorsiflexion, foot eversion',
    musclesSameCategory: [
      'fibularisLongus',
      'tibialisAnterior',
      'fibularisBrevis',
      'extensorHallucisLongus',
    ],
  },
  extensorHallucisLongus: {
    id: 'extensorHallucisLongus',
    name: 'Extensor hallucis longus',
    origin: [
      'Middle third of medial surface of fibula',
      'Interosseous membrane',
    ],
    insertion: ['Base of distal phalanx of great toe'],
    function: [
      'Metatarsophalangeal and interphalangeal joint 1: toe extension',
      'Talocrural joint: foot dorsiflexion',
    ],
    innervation: 'Deep fibular nerve (L5, S1) ',
    bloodSupply: 'Anterior tibial artery',
    description:
      'The main function is the extension of the big toe (hallux). This action happens in both metatarsophalangeal and interphalangeal joints of hallux. This extension is a crucial movement in walking and running. Working together with other muscles from the anterior compartment of the leg, extensor hallucis longus muscle dorsiflexes the foot in the ankle joint. When the foot is fixed on the floor (e.g. walking or doing squats) the muscle pulls the body slightly forward and prevents losing gravity and falling backwards. ',
    joints: [
      'fibula',
      'foot',
      'greatToe',
      'lowLeg',
      'ankle',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['fibula'],
    insertJoints: ['foot', 'f1'],
    movements: 'Toe extension, foot dorsiflexion',
    musclesSameCategory: [
      'fibularisLongus',
      'tibialisAnterior',
      'fibularisBrevis',
      'extensorDigitorumLongus',
    ],
  },
};
