import { Column, Image, ScrollView, Text } from 'native-base';
import React, { useMemo } from 'react';
import { Routes } from 'src/navigation/routes';
import { getMusclesConfig } from 'src/data/muscles/musclesConfig';
import { Link } from 'components/links/Link';
import { DarkColumn } from 'components/layout/views';
import { getLocalizedValue } from 'src/utils/utils';
import { IPoint } from 'src/@types/types';
import { PremiumOverlay } from 'components/PremiumOverlay';
import { IMuscle } from 'src/@types/api';
import { muscleGroupImages } from '../../data/muscles/muscleGroupImages';
import { Modal } from 'components/modals/Modal';
import { useIsPremium } from 'hooks/useIsPremium';

type Props = {
  point?: IPoint;
  onClose: () => void;
  isOpen: boolean;
};
export const MusclesGroupModal = ({ onClose, point, isOpen }: Props) => {
  const { hasPremiumMuscles } = useIsPremium();
  const musclesConfig = getMusclesConfig();

  const muscles = useMemo(
    () =>
      Object.values(musclesConfig).filter(
        (m) => !!point?.muscles?.includes(m.id)
      ),
    [musclesConfig, point?.muscles]
  );

  const renderMuscle = (muscle: IMuscle, withLink = true) => {
    if (withLink)
      return (
        <Link
          my={0.5}
          key={muscle.id}
          to={{
            screen: Routes.MuscleDetail,
            params: { id: muscle.id },
          }}
          action={onClose}
        >
          <Text>{muscle.name}</Text>
        </Link>
      );
    return <Text key={muscle.id}>{muscle.name}</Text>;
  };

  const renderMuscles = () => {
    if (hasPremiumMuscles) {
      return muscles.map((muscle) => renderMuscle(muscle));
    }
    return (
      <>
        {renderMuscle(muscles[0])}
        <Column mt={1} py={1} borderRadius={8} overflow="hidden">
          {muscles.slice(1).map((muscle) => renderMuscle(muscle, false))}
          <PremiumOverlay hasPremium={hasPremiumMuscles} />
        </Column>
      </>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      type="center"
      margin={16}
      p={0}
      containerHeight={[450, 500]}
    >
      {point ? (
        <DarkColumn borderRadius={8} p={4} pt={2} space={2} flex={1}>
          <Text bold>{getLocalizedValue(point.name)}</Text>
          <Image
            width="full"
            h={220}
            resizeMode="contain"
            source={muscleGroupImages[point.id]}
            alt="muscle"
          />
          <ScrollView>{renderMuscles()}</ScrollView>
        </DarkColumn>
      ) : (
        <></>
      )}
    </Modal>
  );
};
