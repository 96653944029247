import {
  Center,
  Column,
  IModalProps,
  Pressable,
  Row,
  ScrollView,
} from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IFriend } from 'src/@types/api';
import { CheckIcon } from 'components/icons/icons';
import { sendMessage } from 'src/firebase/conversations';
import { FriendRow } from 'components/FriendRow';
import { TextLight } from 'components/typography/text';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Modal } from 'components/modals/Modal';
import { ThreadType } from 'src/@types/enums';
import { ILocalizedValue } from 'src/@types/types';
import { useAuthUser, useUserRelationships } from 'src/state/state';
import { MessageInput } from 'components/chat/MessageInput';

type Props = IModalProps & {
  type: ThreadType;
  name: ILocalizedValue;
  threadId: string;
};

export const SendMessageModal = ({
  isOpen,
  onClose,
  type,
  name,
  threadId,
}: Props) => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const relationships = useUserRelationships();
  const friends = relationships?.friends || [];

  const [selectedFriends, setSelectedFriends] = useState<
    Record<string, IFriend>
  >({});
  const { bottom } = useSafeAreaInsets();
  const selectedFriendsCount = Object.keys(selectedFriends).length;

  if (!user) {
    return null;
  }
  const onSend = async (text: string) => {
    sendMessage(
      user,
      text,
      Object.values(selectedFriends),
      type,
      name,
      threadId
    );
    onClose();
  };

  const onSelect = (friend: IFriend) => {
    setSelectedFriends((friends) => {
      if (friends[friend.uid]) {
        const newFriends = { ...friends };
        delete newFriends[friend.uid];
        return newFriends;
      }
      return {
        ...friends,
        [friend.uid]: friend,
      };
    });
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} px={0}>
      <Row w="full" px={4}>
        <MessageInput
          sendDisabled={!selectedFriendsCount}
          maxH={150}
          flex={1}
          numberOfLines={2}
          multiline
          onSend={onSend}
        />
      </Row>
      {friends.length === 0 ? (
        <Center flex={1}>
          <TextLight>{t('exercise.noFriends')}</TextLight>
        </Center>
      ) : (
        <ScrollView
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{
            paddingHorizontal: 16,
            paddingBottom: bottom + 16,
          }}
        >
          <Column space={2}>
            {friends.map((friend, index) => (
              <Pressable key={index} onPress={() => onSelect(friend)}>
                <FriendRow friend={friend}>
                  {selectedFriends[friend.uid] && <CheckIcon primary />}
                </FriendRow>
              </Pressable>
            ))}
          </Column>
        </ScrollView>
      )}
    </Modal>
  );
};
