import { IconButton, IIconButtonProps } from 'native-base';
import { IconProps } from 'components/icons/icons';
import { Colors } from 'src/theme/colors';
import React from 'react';

type Props = IIconButtonProps & {
  Icon: React.FC<IconProps>;
  size?: number | string;
  position?: 'absolute' | 'relative';
};

export const FloatingButton = ({
  Icon,
  size = 7,
  position = 'absolute',
  ...props
}: Props) => {
  const isAbsolute = position === 'absolute';
  return (
    <IconButton
      position={position}
      bottom={isAbsolute ? 16 : 0}
      right={isAbsolute ? [2, 4, 8] : 0}
      icon={<Icon color={Colors.bgWhite} size={size} />}
      backgroundColor={Colors.primary}
      zIndex={10}
      shadow={4}
      {...props}
    />
  );
};
