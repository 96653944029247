import { Center, ICenterProps, Text } from 'native-base';
import { Colors } from 'src/theme/colors';

type Props = ICenterProps & {
  value?: string | number;
};

export const NumberBadge = ({ value, ...props }: Props) => {
  return value ? (
    <Center
      zIndex={1000}
      bg={Colors.badge}
      position="absolute"
      w="20px"
      h="20px"
      right={0}
      borderRadius={20}
      {...props}
    >
      <Text fontSize={11} color="white">
        {value}
      </Text>
    </Center>
  ) : null;
};
