import { IMuscle } from 'src/@types/api';
import { StyledProps } from 'native-base';
import { Routes } from '../navigation/routes';
import React from 'react';
import { isWeb } from '../utils/constants';
import { musclesImages } from 'src/data/muscles/musclesImages';
import { Colors } from 'src/theme/colors';
import { Preview } from 'components/Preview';
import { useIsPremium } from 'hooks/useIsPremium';

type Props = {
  muscle: IMuscle;
  onPress?: () => void;
  withOverlay?: boolean;
  disabled?: boolean;
} & StyledProps;

export const MusclePreview = React.memo(({ muscle, ...props }: Props) => {
  const { hasPremiumMuscles } = useIsPremium();

  return (
    <Preview
      hasPremium={hasPremiumMuscles}
      name={muscle.name}
      to={
        isWeb
          ? { screen: Routes.MuscleDetail, params: { id: muscle.id } }
          : {
              screen: Routes.MusclesStack,
              params: {
                screen: Routes.MuscleDetail,
                params: { id: muscle.id },
                initial: false,
              },
            }
      }
      imageSource={musclesImages[muscle.id]}
      labelColor={Colors.bgMuscle}
      {...props}
    />
  );
});

MusclePreview.displayName = 'MusclePreview';
