import { IScrollViewProps, ScrollView } from 'native-base';

type Props = IScrollViewProps;

export const Slider = (props: Props) => {
  return (
    <ScrollView
      horizontal
      w="full"
      contentContainerStyle={{ paddingBottom: 8 }}
      {...props}
    />
  );
};
