import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Routes } from 'src/navigation/routes';
import { useTranslation } from 'react-i18next';
import { isAndroid, isIOS } from 'src/utils/constants';
import { useColorMode } from 'native-base';
import { MusclesStackParamList } from 'src/navigation/types/musclesStack';
import { MusclesScreen } from 'components/screens/MusclesScreen';
import { MuscleDetailScreen } from 'components/screens/MuscleDetailScreen';
import { StackHeader } from 'components/layout/StackHeader';

const Stack = createNativeStackNavigator<MusclesStackParamList>();

export const MusclesStack = () => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  return (
    <Stack.Navigator
      initialRouteName={Routes.Muscles}
      screenOptions={{
        headerShown: true,
        headerBlurEffect: isIOS
          ? colorMode === 'dark'
            ? 'dark'
            : 'light'
          : undefined,
        headerTransparent: isIOS,
        header: isAndroid ? (props) => <StackHeader {...props} /> : undefined,
      }}
    >
      <Stack.Screen
        name={Routes.Muscles}
        component={MusclesScreen}
        options={{
          title: t('routes.muscles'),
        }}
      />
      <Stack.Screen
        name={Routes.MuscleDetail}
        component={MuscleDetailScreen}
        getId={({ params }) => params.id}
      />
    </Stack.Navigator>
  );
};
