import { ITextProps, Text } from 'native-base';

export const MessageText = (props: ITextProps) => {
  return (
    <Text
      {...props}
      flex={1}
      // @ts-ignore
      style={{ overflowWrap: 'break-word', overflow: 'hidden' }}
    />
  );
};
