import { Screen } from 'components/layout/Screen';
import { useTranslation } from 'react-i18next';
import { HeadingTertiary } from 'components/typography/heading';
import { Text } from 'native-base';
import styled from 'styled-components';

export const ContactScreen = () => {
  const { t } = useTranslation();

  return (
    <Screen title={t('routes.contact')}>
      <HeadingTertiary>{t('contact.title')}</HeadingTertiary>
      <Text>
        {t('contact.subtitle1')}
        <StyledA href="mailto:recoverytom.issues@gmail.com">
          recoverytom.issues@gmail.com
        </StyledA>
        {t('contact.subtitle2')}
      </Text>
    </Screen>
  );
};

const StyledA = styled.a`
  text-decoration: underline;
  font-family: Roboto-Regular;
  &:hover {
    opacity: 0.7;
  }
`;
