import { IMusclesConfig } from 'src/@types/types';

export const musclesConfigSk: IMusclesConfig = {
  levatorScapulae: {
    id: 'levatorScapulae',
    name: 'Zdvíhač lopatky (Levator Scapulae)',
    origin: ['Priečne výbežky stavcov C1-C4'],
    insertion: ['Mediálny okraj lopatky'],
    function: [
      'Priťahuje lopatku superomediálne, otáča glenoidnú dutinu smerom nadol',
      'Bočná flexia krku, extenzia krku',
    ],
    innervation:
      'Predné ramená nervov C3 a C4, dorzálny lopatkový nerv (vetva C5)',
    bloodSupply:
      'Priečne krčné a vzostupné krčné tepny (vetvy thyrocervikálneho kmeňa)',
    description:
      'Keď je chrbtica fixovaná, dvýhač lopatky (levator scapulae) dvíha lopatku a otáča jej spodný uhol mediálne. Často pracuje v kombinácii s ďalšími svalmi, ako sú kosoštvorcové svaly (rombické) a prsné svaly, aby sa dosiahla rotácia lopatky smerom nadol. Zdvihnutie alebo rotácia jedného ramena naraz by si vyžadovali svaly, ktoré stabilizujú krčnú chrbticu a udržiavajú ju v nehybnosti, aby sa neohýbala alebo nerotovala. Zdvíhanie oboch naraz s rovnakým ťahom na obe strany východísk krčnej chrbtice by pôsobilo proti týmto silám. Rotácii smerom nadol by zabránila ko-kontrakcia iných svalov, ktoré zdvíhajú chrbticu, horných vlákien trapézového svalu, ktorý je rotátorom smerom nahor.',
    joints: [
      'cervicalSpine',
      'scapula',
      'neck',
      'spineMid',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['c1', 'c2', 'c3', 'c4', 'spine'],
    insertJoints: ['scapula'],
    movements: 'Zdvíha lopatku a nakláňa hlavu do strán',
    musclesSameCategory: [
      'sternocleidomastoid',
      'scaleneMuscles',
      'omohyoid',
      'sternohyoid',
      'spleniusCapitis',
      'semispinalisCapitis',
    ],
  },
  sternocleidomastoid: {
    id: 'sternocleidomastoid',
    showPremium: true,
    name: 'Kývač hlavy (Sternocleidomastoid)',
    origin: [
      'Hlava sterna: horná časť predného povrchu manubria sterni',
      'Hlava kľúčnej kosti: horná plocha mediálnej tretiny kľúčnej kosti',
    ],
    insertion: [
      'Laterálna plocha mastoidného výbežku a laterálna polovica hornej nuchálnej línie okcipitálnej kosti',
    ],
    function: ['Otáčanie hlavy do strán, flexia a extenzia krku'],
    innervation:
      'Predné ramená miechových nervov C4-C6; predné ramená miechových nervov C3-C8; predné ramená miechových nervov C6-C8',
    bloodSupply:
      'Okcipitálna, povrchová krčná, supraskapulárna a priečna krčná tepna',
    description:
      'Úlohou tohto svalu je otáčať hlavu na opačnú stranu alebo šikmo otáčať hlavu. Spôsobuje tiež ohýbanie (flexiu) krku. Keď sa obe strany svalu stiahnu, krk sa ohne (flexia) a hlava sa natiahne (extenzia). Keď pôsobí len jedna strana, hlava sa otáča na opačnú stranu a bočne (laterálne) sa ohýba na tú istú stranu.',
    joints: ['head', 'clavicle', 'neck', 'sternum', 'upperBody', 'fullBody'],
    originJoints: ['sternum', 'clavicle'],
    insertJoints: ['head'],
    movements: 'Otáčanie hlavy do strán, dozadu, nadol',
    musclesSameCategory: [
      'levatorScapulae',
      'scaleneMuscles',
      'omohyoid',
      'sternohyoid',
      'spleniusCapitis',
      'semispinalisCapitis',
    ],
  },
  scaleneMuscles: {
    id: 'scaleneMuscles',
    name: 'Šikmé svaly (Scalene muscles)',
    origin: [
      'Predný tuberkulus priečnych výbežkov stavcov C3-C6',
      'Zadné hrbolčeky priečnych výbežkov stavcov C2-C7',
      'Zadné hrbolčeky priečnych výbežkov stavcov C5-C7',
    ],
    insertion: [
      'Predný skalenový tuberkulus rebra 1',
      'Horný okraj rebra 1',
      'Vonkajší povrch rebra 2',
    ],
    function: ['Ohnutie krku, zdvihnutie rebier 1'],
    innervation: 'Prídavný nerv (CN XI), vetvy krčného plexu (C2-C3)',
    bloodSupply: 'Vzostupná krčná vetva dolnej štítnej tepny',
    description:
      'Predný a stredný šikmý sval dvíhajú prvé rebro a ohýbajú (flexia) krk na rovnakú stranu ako pôsobiaci sval; zadný skalenový sval dvíha druhé rebro a nakláňa krk na rovnakú stranu. Keďže zdvíhajú horné rebrá, spolu so zdvíhačom hlavy (sternocleidomastoid) pôsobia aj ako pomocné dýchacie svaly.',
    joints: [
      'cervicalSpine',
      'rib1',
      'rib2',
      'ribs',
      'ribsTop',
      'neck',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'spine'],
    insertJoints: ['r1', 'r2', 'ribs'],
    movements: 'Počas dýchania dvíha 1. a 2. rebro, flexia a rotácia krčnej c',
    musclesSameCategory: [
      'levatorScapulae',
      'sternocleidomastoid',
      'omohyoid',
      'sternohyoid',
      'spleniusCapitis',
      'semispinalisCapitis',
    ],
  },
  omohyoid: {
    id: 'omohyoid',
    name: 'Lopatkovojazylkový sval (Omohyoids)',
    origin: [
      'Spodné bruško: horný okraj lopatky v blízkosti nadlopatkového zárezu (incisura scapulae)',
      'Horné bruško: stredná šľacha',
    ],
    insertion: ['Spodné bruško: stredná šľacha', 'Horná bruško: telo jazylky'],
    function: [
      'Stláča a sťahuje jazylku a hrtan a obnovuje dýchanie po prehltnutí',
    ],
    innervation: 'Predné ramená miechových nervov C1-C3',
    bloodSupply: 'Horná štítna tepna',
    description:
      'Infrahyoidné svaly spolu so suprahyoidnými svalmi sú zodpovedné za polohu jazylky a hrtana. Konkrétne infrahyoidné svaly a omohyoidné svaly stláčajú jazylku po jej zdvihnutí počas prehĺtania.  Okrem toho omohyoidný sval pri kontrakcii sťahuje pošvu a udržiava nízky tlak vo vnútornej krčnej žile.',
    joints: ['scapula', 'hyoidBone', 'neck', 'back', 'upperBody', 'fullBody'],
    originJoints: ['scapula'],
    insertJoints: ['hyoidBone'],
    movements: 'Prehĺtanie, udržuje nízky tlak vo vnútornej krčnej žile',
    musclesSameCategory: [
      'levatorScapulae',
      'scaleneMuscles',
      'sternohyoid',
      'sternocleidomastoid',
      'spleniusCapitis',
      'semispinalisCapitis',
    ],
  },
  sternohyoid: {
    id: 'sternohyoid',
    showPremium: true,
    name: 'Mostíkovoštítny sval (Sternohyoid)',
    origin: ['Manubrium hrudnej kosti, mediálny koniec kľúčnej kosti'],
    insertion: ['Spodný okraj tela jazylky'],
    function: ['Stláča jazylku'],
    innervation: 'Laterálne vetvy zadných ramien miechových nervov C2-C3',
    bloodSupply: 'Horná štítna tepna',
    description:
      'Úlohou sternothyoidného svalu je stláčať jazylku a hrtan počas reči a prehĺtania spolu s ostatnými infrahyoidnými svalmi.',
    joints: [
      'hyoidBone',
      'sternum',
      'clavicle',
      'neck',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['sternum', 'clavicle'],
    insertJoints: ['hyoidBone'],
    movements:
      'Stláča jazylku, pomáha pri reči, predovšetkým pri hlasitosti, prehĺtaní a žuvaní.',
    musclesSameCategory: [
      'levatorScapulae',
      'scaleneMuscles',
      'omohyoid',
      'sternocleidomastoid',
      'spleniusCapitis',
      'semispinalisCapitis',
    ],
  },
  spleniusCapitis: {
    id: 'spleniusCapitis',
    name: 'Remenný sval (Splenius capitis)',
    origin: ['Dolná polovica zátylkového väzu a tŕňové výbežky stavcov C7-T3'],
    insertion: ['Mastoidný výbežok spánkovej kosti'],
    function: [
      'Biletárna kontrakcia spôsobuje predĺženie (extenzia) hlavy na krku',
      'Jednostranná kontrakcia v súčinnosti so sternocleidomastoidom vedie k laterálnej flexii a rotácii hlavy',
    ],
    innervation:
      'Zostupné vetvy veľkého zátylkového nervu (C2) a miechového nervu C3',
    bloodSupply: 'Svalové vetvy okcipitálnej artérie',
    description:
      'Svalový hlavný motor pri predĺžení (extenzia) hlavy. Remenný sval (splenius capitis) umožnuje aj bočnú flexiu a rotáciu krčnej chrbtice.',
    joints: [
      'cervicalSpine',
      'thoracicSpine',
      'head',
      'spineMid',
      'neck',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['c7', 't1', 't2', 't3', 'spine'],
    insertJoints: ['head'],
    movements: 'Pohyb hlavy dozadu, otáčanie hlavy do strán',
    musclesSameCategory: [
      'levatorScapulae',
      'scaleneMuscles',
      'omohyoid',
      'sternocleidomastoid',
      'sternohyoid',
      'semispinalisCapitis',
    ],
  },
  semispinalisCapitis: {
    id: 'semispinalisCapitis',
    name: 'Polotŕňový sval (Semispinalis capitis)',
    origin: ['Kĺbové výbežky stavcov C4-C7', 'Priečne výbežky stavcov T1-T6'],
    insertion: ['Medzi hornou a dolnou zátylkovou líniou týlnej kosti'],
    function: [
      'Obojstranná kontrakcia: predĺženie (extenzia) hlavy, krčnej a hrudnej chrbtice',
      'Jednostranná kontrakcia: bočná flexia hlavy, krčnej a hrudnej chrbtice, rotácia hlavy',
    ],
    innervation: 'Laterálne a mediálne prsné nervy (C5-T1)',
    bloodSupply: 'Okcipitálna tepna',
    description:
      'Bočná rotácia krku, bočné ohnutie krku a natiahnutie krku, napríklad pri ohnutí krku dozadu, keď sa pozriete na strop. Polotŕňový sval (semispinalis capitis) tiež pomáha udržiavať správne držanie tela v oblasti krku a hlavy.',
    joints: [
      'cervicalSpine',
      'thoracicSpine',
      'spineMid',
      'neck',
      'head',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: [
      'c4',
      'c5',
      'c6',
      'c7',
      't1',
      't2',
      't3',
      't4',
      't5',
      't6',
      'spine',
    ],
    insertJoints: ['head'],
    movements: 'Ohnutie krku dozadu, aby sa pozrel na strop',
    musclesSameCategory: [
      'levatorScapulae',
      'scaleneMuscles',
      'omohyoid',
      'sternocleidomastoid',
      'sternohyoid',
      'spleniusCapitis',
    ],
  },
  pectoralisMajor: {
    id: 'pectoralisMajor',
    name: 'Veľký prsný sval (Pectoralis major)',
    origin: [
      'Klavikulárna časť: predná plocha hrudnej polovice kľúčnej kosti',
      'Sternokostálna časť: predná plocha hrudnej kosti, kostné chrupavky rebier 1-6',
      'Brušná časť: aponeuróza vonkajšieho šikmého brušného svalu (predná vrstva priameho svalu)',
    ],
    insertion: ['Hrebeň väčšieho tuberkula ramennej kosti'],
    function: [
      'Ramenný kĺb: Addukcia ramena, vnútorná rotácia ramena, flexia ramena (klavikulárna hlava), extenzia ramena (sternokostálna hlava);',
      'lopatkovo-hrudný kĺb: Priťahuje lopatku anteroinferiórne',
    ],
    innervation: 'Laterálne a mediálne prsné nervy (C5-T1)',
    bloodSupply:
      'Pektorálne vetvy torakoakromiálnej tepny, perforujúce vetvy vnútornej hrudnej tepny',
    description:
      'Veľký prsný sval má štyri činnosti, ktoré sú primárne zodpovedné za pohyb ramenného kĺbu. Prvou činnosťou je flexia ramennej kosti, ako napríklad pri hádzaní lopty a pri zdvíhaní dieťaťa. Po druhé, addukuje ramennú kosť, ako napríklad pri mávaní rukami. Po tretie sa ramenná kosť otáča mediálne, ako je to pri zápasení s rukami. Po štvrté, veľký prsný sval je zodpovedný aj za to, že je ruka pripevnená k trupu tela.',
    joints: [
      'clavicle',
      'sternum',
      'ribs',
      'ribsTop',
      'ribsMid',
      'humerus',
      'chest',
      'shoulder',
      'upperArm',
      'upperBody',
      'fullBody',
    ],
    originJoints: [
      'clavicle',
      'sternum',
      'r1',
      'r2',
      'r3',
      'r4',
      'r5',
      'r6',
      'ribs',
    ],
    insertJoints: ['humerus'],
    movements: 'Vnútorná rotácia ramena, Addukcia ramena',
    musclesSameCategory: [
      'pectoralisMinor',
      'serratusAnterior',
      'bicepsBrachii',
      'coracobrachialis',
      'deltoid',
      'latissimusDorsi',
      'teresMajor',
    ],
  },
  pectoralisMinor: {
    id: 'pectoralisMinor',
    showPremium: true,
    name: 'Malý prsný sval (Pectoralis minor)',
    origin: ['Predný povrch, rebrové chrupavky rebier 3-5'],
    insertion: ['Mediálny okraj a korakoidný výbežok lopatky'],
    function: [
      'Lopatkovo-hrudný kĺb: priťahuje lopatku anteroinferiorne, stabilizuje lopatku na hrudnej stene',
    ],
    innervation: 'Mediálne a laterálne prsné nervy (C5-T1)',
    bloodSupply:
      'Torakoakromiálna (prsná a deltová vetva), horná hrudná a bočná hrudná tepna',
    description:
      'Medzi základné činnosti tohto svalu patrí stabilizácia, depresia, abdukcia alebo protrakcia, vnútorná rotácia a rotácia lopatky smerom nadol.',
    joints: [
      'ribs',
      'scapula',
      'ribsMid',
      'chest',
      'shoulder',
      'upperBody',
      'fullBody',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['ribs', 'r3', 'r4', 'r5'],
    insertJoints: ['scapula'],
    movements:
      'Ťahá lopatku smerom nadol, dopredu (protrakcia) sprevádzaná rotáciou ramenného kĺbu, pomocným inspiračným svalom',
    musclesSameCategory: [
      'pectoralisMajor',
      'serratusAnterior',
      'bicepsBrachii',
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'subscapularis',
      'omohyoid',
      'levatorScapulae',
      'latissimusDorsi',
    ],
  },
  serratusAnterior: {
    id: 'serratusAnterior',
    name: 'Predný pílovitý sval (Serratus Anterior)',
    origin: [
      'Vyššia časť: Rebrá 1-2',
      'Stredná časť: Rebrá-3-6',
      'Nižšia časť: rebrá 7-8/9',
    ],
    insertion: [
      'Vyššia časť: Predná plocha horného uhla lopatky',
      'Predný povrch mediálneho okraja lopatky',
      'Predná plocha dolného uhla a mediálny okraj lopatky',
    ],
    function: [
      'Lopatkovito-hrudný kĺb: priťahuje lopatku anterolaterálne, drží lopatku na hrudnej stene, rotuje lopatkou',
    ],
    innervation: 'Dlhý hrudný nerv (C5- C7)',
    bloodSupply: 'Horné a bočné hrudné tepny, vetvy torakodorzálnej tepny',
    description:
      'Všetky tri vyššie opísané časti ťahajú lopatku dopredu okolo hrudníka, čo je nevyhnutné pre predklon ramena. Sval ako taký je antagonistom rombických svalov. Keď však dolná a horná časť pôsobia spoločne, udržiavajú lopatku pritlačenú k hrudníku spolu s rombickými svalmi, a preto tieto časti pôsobia aj ako synergisty k rombickým svalom.',
    joints: [
      'ribs',
      'scapula',
      'ribsTop',
      'ribsMid',
      'ribsBot',
      'chest',
      'back',
      'shoulder',
      'upperBody',
      'fullBody',
      'midBody',
    ],
    originJoints: [
      'ribs',
      'r1',
      'r2',
      'r3',
      'r4',
      'r5',
      'r6',
      'r7',
      'r8',
      'r9',
    ],
    insertJoints: ['scapula'],
    movements:
      'Drží lopatku pri hrudníku, protrakcia lopatky (boxerský sval), pomocný inspiračný sval',
    musclesSameCategory: [
      'pectoralisMinor',
      'subscapularis',
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'omohyoid',
      'levatorScapulae',
      'latissimusDorsi',
    ],
  },
  supraspinatus: {
    id: 'supraspinatus',
    showPremium: true,
    name: 'Nadtrňový sval (Supraspinatus)',
    origin: ['Supraspinózna jamka lopatky'],
    insertion: ['Väčší tuberkulus ramennej kosti'],
    function: [
      'Ramenný kĺb: abdukcia ramena',
      'Stabilizácia hlavice ramennej kosti v glenoidálnej dutine',
    ],
    innervation: 'Supraskapulárny nerv (C5, C6)',
    bloodSupply: 'Supraskapulárna artéria',
    description:
      'Nadtŕňový sval (supraspinatus) vykonáva abdukciu ramena a ťahá hlavicu ramennej kosti mediálne smerom ku glenoidálnej dutine. Samostatne zabraňuje skĺznutiu hlavice ramennej kosti smerom nadol. Nadtŕňový sval spolupracuje s deltovým svalom pri vykonávaní abdukcie, a to aj vtedy, keď je ruka v addukovanej polohe.',
    joints: [
      'scapula',
      'humerus',
      'back',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'Vonkajšia rotácia hornej končatiny, pomocný sval pri abdukcii ramena',
    musclesSameCategory: [
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'subscapularis',
      'levatorScapulae',
      'pectoralisMinor',
      'latissimusDorsi',
      'trapezius',
      'rhomboidMinor',
      'rhomboidMajor',
      'deltoid',
      'tricepsBrachii',
      'omohyoid',
    ],
  },
  infraspinatus: {
    id: 'infraspinatus',
    name: 'Podtŕňový sval (Infraspinatus)',
    origin: ['Infraspinózna jamka lopatky'],
    insertion: ['Väčší tuberkulus ramennej kosti'],
    function: [
      'Ramenný kĺb: externá rotácia',
      'Stabilizuje hlavicu ramennej kosti v glenoidálnej dutine',
    ],
    innervation: 'Supraskapulárny nerv (C5, C6)',
    bloodSupply: 'Supraskapulárna artéria, cirkumflexné lopatkové artérie',
    description:
      'Nadtŕňový sval (infraspinatus) je hlavný vonkajší rotátor ramena. Keď je rameno fixované, addukuje dolný uhol lopatky. Jeho synergistami sú menší oblý sval (teres minor) a deltový sval. Nadtŕňový sval (infraspinatus) a menší oblý sval (teres minor) otáčajú hlavicu ramennej kosti smerom von (vonkajšia alebo bočná rotácia). Pomáhajú tiež pri posúvaní ramena dozadu.',
    joints: [
      'scapula',
      'humerus',
      'back',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'Vonkajšia rotácia ramenného kĺbu/ramena, addukcia a supinácia ramena',
    musclesSameCategory: [
      'supraspinatus',
      'teresMajor',
      'teresMinor',
      'subscapularis',
      'levatorScapulae',
      'pectoralisMinor',
      'trapezius',
      'latissimusDorsi',
      'trapezius',
      'rhomboidMinor',
      'rhomboidMajor',
      'deltoid',
      'tricepsBrachii',
      'omohyoid',
    ],
  },
  teresMajor: {
    id: 'teresMajor',
    name: 'Väčší oblý sval (Teres major)',
    origin: ['Dolný uhol a dolná časť bočného okraja lopatky'],
    insertion: ['Intertuberkulárna ryha (mediálny okraj) ramennej kosti'],
    function: [
      'Extenzia a vnútorná rotácia ramennej kosti',
      'Stabilizuje hlavu ramennej kosti v glenoidnej dutine',
    ],
    innervation: 'Podlopatkový nerv (C5-C7)',
    bloodSupply:
      'Torakodorzálna vetva subskapulárnej artérie a zadná cirkumflexná humerálna artéria',
    description:
      'Väčší oblý sval (teres major) je mediálny rotátor a adduktor ramennej kosti a pomáha najširšiemu svalu chrbtu (latissimus dorsi) pri ťahaní už predtým zdvihnutej ramennej kosti smerom nadol a dozadu. Pomáha tiež stabilizovať hlavu humeru v glenoidálnej dutine.',
    joints: [
      'scapula',
      'humerus',
      'back',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'Medial\u00a0rotation\u00a0and\u00a0adduction\u00a0of the humerus',
    musclesSameCategory: [
      'supraspinatus',
      'infraspinatus',
      'teresMinor',
      'subscapularis',
      'pectoralisMinor',
      'levatorScapulae',
      'latissimusDorsi',
      'trapezius',
      'rhomboidMinor',
      'rhomboidMajor',
      'deltoid',
      'tricepsBrachii',
      'omohyoid',
    ],
  },
  teresMinor: {
    id: 'teresMinor',
    showPremium: true,
    name: 'Menší oblý sval (Teres minor)',
    origin: ['Bočný okraj lopatky'],
    insertion: ['Väčší tuberkulus ramennej kosti'],
    function: [
      'Ramenný kĺb: externá rotácia, addukcia',
      'Stabilizuje hlavu ramennej kosti v glenoidnej dutine',
    ],
    innervation: 'Axilárny nerv (C5, C6)',
    bloodSupply: 'Supraskapulárna artéria, dorzálna lopatková artéria',
    description:
      'Menší oblý sval (teres minor) pomáha držať hlavicu ramennej kosti v glenoidálnej dutine lopatky. Menší oblý sval (teres minor) spolu s podtŕňovým svalom (infraspinatus) pracujú v tandeme so zadným deltoidom na vonkajšej (laterálnej) rotácii ramennej kosti, ako aj na addukcii. Pri súčinnosti s väčším oblým svalom (teres major), najširším svalom chrbta (latissimus dorsi) a veľkým prsným svalom (pectoralis major), menší oblý sval (teres minor) addukuje z abdukovanej polohy (znižuje uhol ramena a tela).',
    joints: [
      'scapula',
      'humerus',
      'back',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'Pri vonkajšej rotácii ramenného kĺbu infraspinatus, v súčinnosti s teres major, latissimus dorsi a pectoralis major addukcia abdukovanej paže',
    musclesSameCategory: [
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'subscapularis',
      'pectoralisMinor',
      'levatorScapulae',
      'latissimusDorsi',
      'trapezius',
      'rhomboidMinor',
      'rhomboidMajor',
      'deltoid',
      'tricepsBrachii',
      'omohyoid',
    ],
  },
  subscapularis: {
    id: 'subscapularis',
    name: 'Podlopatkový sval (Subscapularis)',
    origin: ['Podlopatková jamka lopatky'],
    insertion: ['Malý tuberkulus ramennej kosti'],
    function: [
      'Ramenný kĺb: interná rotácia',
      'Stabilizuje hlavu ramennej kosti v glenoidálnej dutine',
    ],
    innervation: 'Horné a dolné podlopatkové nervy (C5 - C6)',
    bloodSupply: 'Supraskapulárna, axilárna a subskapulárna tepna',
    description:
      'Podlopatkový sval je jediným mediálnym (vnútorným) rotátorom zo všetkých svalov rotátorovej manžety a v menšej miere tiež addukuje rameno. Sled týchto dvoch pohybov je vidieť pri chôdzi, keď sa ramená švihajú. Keď je rameno zdvihnuté, priťahuje ramennú kosť dopredu a nadol. Je to silná obrana prednej časti ramenného kĺbu, ktorá zabraňuje posunu hlavice ramennej kosti.',
    joints: [
      'scapula',
      'humerus',
      'back',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'Mediálna (vnútorná) rotácia ramena, vo zdvihnutej polohe ramena ťahá ramennú kosť dopredu a nadol',
    musclesSameCategory: [
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'serratusAnterior',
      'pectoralisMinor',
      'omohyoid',
      'levatorScapulae',
      'trapezius',
      'latissimusDorsi',
      'rhomboidMinor',
      'rhomboidMajor',
      'deltoid',
      'tricepsBrachii',
    ],
  },
  rhomboidMinor: {
    id: 'rhomboidMinor',
    name: 'Malý rombický sval (Rhomboid minor)',
    origin: ['Nuchálny väz a tŕňové výbežky stavcov C7-T1'],
    insertion: ['Mediálny okraj v blízkosti základne chrbtice lopatky'],
    function: [
      'Lopatkovito-hrudný kĺb: Podporuje polohu lopatky, otáča glenoidnú dutinu smerom nadol.',
    ],
    innervation: 'Dorzálny lopatkový nerv (C4-C5)',
    bloodSupply:
      'Dorzálna lopatková tepna, hlboká vetva priečnej krčnej tepny, dorzálna vetva horných piatich alebo šiestich zadných medzirebrových tepien',
    description:
      'Veľký kosoštvorcový sval pomáha držať lopatku (a tým aj hornú končatinu) na hrudnom koši, takže aj malý kosoštvorcový sval. Oba kosoštvorce (veľký aj malý) pôsobia aj na vťahovanie lopatky, čím ju priťahujú k chrbtici. Kosoštvorcové svaly pracujú spoločne s dvíhačom lopatky (levator scapulae) na zdvihnutí mediálneho okraja lopatky, čím sa lopatka otáča smerom nadol.',
    joints: [
      'scapula',
      'cervicalSpine',
      'thoracicSpine',
      'spineMid',
      'neck',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['c7', 't1', 'spine'],
    insertJoints: ['scapula'],
    movements: 'Retrakcia lopatky, drží lopatku na hrudnom koši',
    musclesSameCategory: [
      'rhomboidMajor',
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'serratusAnterior',
      'serratusPosteriorSuperior',
      'levatorScapulae',
      'trapezius',
      'latissimusDorsi',
      'omohyoid',
    ],
  },
  rhomboidMajor: {
    id: 'rhomboidMajor',
    showPremium: true,
    name: 'Veľký rombický sval (Rhomboid major)',
    origin: ['Spinózny výbežok stavcov T2-T5'],
    insertion: ['Mediálny okraj lopatky'],
    function: [
      'Lopatkovito-hrudný kĺb: Podporuje polohu lopatky, otáča glenoidnú dutinu smerom nadol.',
    ],
    innervation: 'Dorzálny lopatkový nerv (C4-C5)',
    bloodSupply:
      'Dorzálna lopatková tepna, hlboká vetva priečnej krčnej tepny, dorzálna vetva horných piatich alebo šiestich zadných medzirebrových tepien',
    description:
      'Veľký a malý kosoštvorcový sval pomáhajú držať lopatku (a tým aj hornú končatinu) na hrudnom koši. Oba kosoštvorce (veľký aj malý) pôsobia aj na vťahovanie lopatky, čím ju priťahujú k chrbtici. Kosoštvorcové svaly pracujú spoločne s dvíhačom lopatky (levator scapulae) na zdvihnutí mediálneho okraja lopatky, čím sa lopatka otáča smerom nadol.',
    joints: [
      'scapula',
      'thoracicSpine',
      'spineMid',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['t2', 't3', 't4', 't5', 'spine'],
    insertJoints: ['scapula'],
    movements: 'Retrakcia lopatky, drží lopatku na hrudnom koši',
    musclesSameCategory: [
      'rhomboidMajor',
      'supraspinatus',
      'infraspinatus',
      'teresMajor',
      'teresMinor',
      'serratusAnterior',
      'serratusPosteriorSuperior',
      'levatorScapulae',
      'latissimusDorsi',
      'omohyoid',
    ],
  },
  serratusPosteriorSuperior: {
    id: 'serratusPosteriorSuperior',
    name: 'Zadný horný pílovitý sval (Serratus posterior superior)',
    origin: ['Nuchálny väz, tŕňové výbežky stavcov C7-T3'],
    insertion: ['Horné okraje rebier 2-5'],
    function: [
      'Zadný horný pílovitý sval (serratus posterior superior) dvíha druhé až piate rebro. To napomáha hlbokému dýchaniu.',
    ],
    innervation: 'Medzirebrové nervy (T1-T5)',
    bloodSupply: 'Zadné medzirebrové tepny',
    description:
      'Zadný horný pílovitý sval (serratus posterior superior) dvíha rebrá počas nádychu (akcesorný sval inspíria) a má stabilizačnú úlohu pre hrudník.',
    joints: [
      'thoracicSpine',
      'cervicalSpine',
      'ribs',
      'ribsMid',
      'ribsTop',
      'spineMid',
      'neck',
      'back',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['c7', 't1', 't2', 't3', 'spine'],
    insertJoints: ['ribs', 'r2', 'r3', 'r4', 'r5'],
    movements: 'Nadvyhuje rebrá',
    musclesSameCategory: [
      'rhomboidMajor',
      'rhomboidMinor',
      'spleniusCapitis',
      'semispinalisCapitis',
      'serratusPosteriorInferior',
    ],
  },
  serratusPosteriorInferior: {
    id: 'serratusPosteriorInferior',
    name: 'Zadný dolný pílovitý sval (Serratus posterior inferior)',
    origin: ['Spinózny výbežok stavcov T11-L2'],
    insertion: ['Spodné okraje rebier 9-12'],
    function: ['Stláčanie rebier počas výdychu (pomocný výdychový sval)'],
    innervation: 'Medzirebrové nervy (T9-T12)',
    bloodSupply: 'Zadné medzirebrové tepny',
    description:
      'Zadný dolný pílovitý sval (serratus posterior inferior) priťahuje spodné rebrá dozadu a nadol, čím pomáha pri rotácii a extenzii trupu. Počas výdychu stláča rebrá.',
    joints: [
      'thoracicSpine',
      'lumbarSpine',
      'ribs',
      'ribsBot',
      'spineBot',
      'back',
      'midBody',
      'fullBody',
    ],
    originJoints: ['t11', 't12', 'l1', 'l2', 'spine'],
    insertJoints: ['ribs', 'r9', 'r10', 'r11', 'r12'],
    movements: 'Stláča rebrá',
    musclesSameCategory: [
      'serratusPosteriorSuperior',
      'quadratusLumborum',
      'psoasMinor',
      'psoasMajor',
      'internalOblique',
      'externalObliques',
      'latissimusDorsi',
      'trapezius',
    ],
  },
  latissimusDorsi: {
    id: 'latissimusDorsi',
    name: 'Najširší sval chrbta (Latissimus dorsi)',
    origin: [
      'Časť chrbtice: Spinózne výbežky stavcov T7-T12',
      'Iliakálna časť: Zadná tretina hrebeňa ilia',
      'Rebrová časť: Rebrá 9-12',
      'Lopatková časť: Dolný uhol lopatky',
    ],
    insertion: [
      'Intertuberkulárna ryha na ramennej kosti, medzi veľkým prsným svalom (pectoralis major) a väčším oblým svalom (teres major)',
    ],
    function: [
      'Ramenný kĺb: Rameno: vnútorná rotácia ramena, addukcia ramena, extenzia ramena; pomáha pri dýchaní',
    ],
    innervation: 'Torakodorzálny nerv (C6-C8)',
    bloodSupply:
      'Torakodorzálna tepna, perforujúce tepny 9.-11. zadnej medzirebrovej tepny a 1.-3. bedrovej tepny',
    description:
      'Najširší sval chrbta (latissimus dorsi) spolupracuje s väčším oblým svalom (teres major) a veľkým prsným svalom (pectoralis major) pri vykonávaní činností hornej končatiny. Tieto svaly spoločne pracujú na addukcii, mediálnej rotácii a extenzii ramena v glenohumerálnom kĺbe. Sval je aktívny aj pri pohybe trupu dopredu a nahor, keď sú horné končatiny zafixované nad hlavou, ako napríklad pri šplhaní alebo vykonávaní činnosti, ako je napríklad zdvih nadhmatom.',
    joints: [
      'thoracicSpine',
      'ribs',
      'ribsBot humerus',
      'scapula',
      'pelvis',
      'spineMid',
      'spineBot',
      'back',
      'upperArm',
      'shoulder',
    ],
    originJoints: [
      't7',
      't8',
      't9',
      't10',
      't11',
      't12',
      'spine',
      'pelvis',
      'ribs',
      'r9',
      'r10',
      'r11',
      'r12',
      'scapula',
    ],
    insertJoints: ['humerus'],
    movements:
      'Lezecký sval, predlžuje ohnutú ruku, addukuje a vnútorne otáča rameno, Ak je ramenná kosť fixovaná proti lopatke, ťahá hrudný pás ako celok dozadu.',
    musclesSameCategory: [
      'serratusPosteriorInferior',
      'trapezius',
      'teresMajor',
      'pectoralisMajor',
      'tricepsBrachii',
      'quadratusLumborum',
    ],
  },
  trapezius: {
    id: 'trapezius',
    name: 'Lichobežníkový sval (Trapezius)',
    origin: [
      'Zostupná časť (horné vlákna): mediálna tretina hornej nuchálnej línie, vonkajší okcipitálny výbežok, nuchálny väz',
      'Stredné vlákna: spinózne výbežky a supraspinózne väzy stavcov T1-T4 (alebo C7-T3)',
      'Spodné vlákna: spinózne výbežky a supraspinózne väzy stavcov T4-T12',
    ],
    insertion: [
      'Horné vlákna: laterálna tretina kľúčnej kosti',
      'Stredné vlákna: mediálny akromiálny okraj, horný hrebeň chrbtice lopatky',
      'Spodné vlákna: laterálny vrchol mediálneho konca lopatkového chrbta',
    ],
    function: [
      'Vrchné vlákna: lopatkovo-hrudný kĺb: priťahuje lopatku superomediálne; atlantookcipitálny kĺb: extenzia hlavy a krku, laterálna flexia hlavy a krku; altantoaxiálny kĺb: rotácia hlavy',
      'Stredné vlákna: kapulothorakálny kĺb: ťahá lopatku mediálne',
      'Spodné vlákna: lopatkovo-hrudný kĺb: Priťahuje lopatku inferomediálne',
    ],
    innervation:
      'Senzorická funkcia: ventrálne ramená C3, C4; motorická funkcia: kraniálny nerv XI',
    bloodSupply:
      'Okcipitálna artéria (zostupná časť), povrchová alebo priečna krčná artéria (priečna časť), dorzálna lopatková artéria (vzostupná časť)',
    description:
      'Hlavnou funkciou trapézového svalu je stabilizácia lopatky a jej ovládanie počas pohybov ramenom a hornou končatinou. Kontrakcia trapézového svalu môže mať dva účinky: pohyb lopatiek, keď je chrbtica stabilná, a pohyb chrbtice, keď sú lopatky stabilné. Jeho hlavnou funkciou je stabilizácia a pohyb lopatiek. Horné vlákna zdvíhajú lopatky, stredné vlákna lopatky sťahujú a dolné vlákna lopatky stláčajú. Okrem translácie lopatiek trapézy vyvolávajú rotáciu lopatiek. Horné a dolné vlákna majú tendenciu otáčať lopatku okolo sternoklavikulárneho skĺbenia tak, že akromion a dolné uhly sa pohybujú nahor a mediálny okraj sa pohybuje nadol (rotácia nahor). Horné a dolné vlákna pracujú v tandeme s predným pílovitým svalom (serratus anterior) na rotácii lopatky smerom nahor a pracujú v opozícii voči zdvíhaču lopatky (levator scapulae) a rombickým svalom, ktoré spôsobujú rotáciu smerom nadol. Príkladom funkcie trapézov je tlak nad hlavou. Pri spoločnej aktivácii horných a dolných vlákien pomáhajú stredným vláknam (spolu s ďalšími svalmi, ako sú rombické svaly) pri retrakcii/addukcii lopatiek.',
    joints: [
      'cervicalSpine',
      'thoracicSpine',
      'head',
      'scapula',
      'shoulder',
      'spineMid',
      'neck',
      'back',
    ],
    originJoints: [
      'head',
      'spine',
      'c7',
      't1',
      't2',
      't3',
      't4',
      't5',
      't6',
      't7',
      't8',
      't9',
      't10',
      't11',
      't12',
    ],
    insertJoints: ['scapula', 'clavicle'],
    movements:
      'Predovšetkým stabilizácia lopatky, elevácia lopatky/ramien, elevácia ramena okolo horizontály, rotácia hlavy, predklon hlavy do strán, extenzia hlavy',
    musclesSameCategory: [
      'latissimusDorsi',
      'deltoid',
      'levatorScapulae',
      'rhomboidMajor',
      'rhomboidMinor',
      'supraspinatus',
      'infraspinatus',
      'semispinalisCapitis',
      'spleniusCapitis',
    ],
  },
  deltoid: {
    id: 'deltoid',
    name: 'Deltový sval (Deltoid)',
    origin: [
      'Predná časť: bočná 1/3 kľúčnej kosti (klavikulárna časť)',
      'Stredná časť: akromion',
      'Zadná časť: chrbtica lopatky',
    ],
    insertion: ['Deltoidná tuberozita ramennej kosti'],
    function: [
      'Klavikulárna časť: flexia a vnútorná rotácia ramena',
      'Akromiálna časť: abdukcia ramena nad pôvodných 15°',
      'Časť chrbtice: extenzia a vonkajšia rotácia ramena',
    ],
    innervation: 'Axilárny nerv (C5, C6)',
    bloodSupply:
      'Deltová a akromiálna vetva torakoakromiálnej tepny, podlopatková tepna, predná a zadná cirkumflexná ramenná tepna, deltová vetva hlbokej ramennej tepny',
    description:
      'Keď sa všetky jeho vlákna stiahnu súčasne, deltový sval je hlavným motorom abdukcie ramena pozdĺž frontálnej roviny. Predné vlákna pomáhajú veľkému prsnému (pectoralis major) svalu pri flexii ramena. Predný deltoid tiež pracuje v tandeme s podlopatkovým svalom (subscapularis), prsnými svalmi (pectoralis major and minor) a najširším svalom chrbta (latissimus dorsi) pri vnútornej (mediálnej) rotácii ramennej kosti. Stredné vlákna vykonávajú základnú abdukciu ramena pri vnútornej rotácii ramena a vykonávajú priečnu abdukciu ramena pri vonkajšej rotácii ramena. Zadné vlákna pomáhajú najširšiemu svalu chrbta (latissimus dorsi) pri extenzii ramena. \u010ealšie priečne extenzory, podtŕňový sval (infraspinatus) a menší oblý sval (teres minor), tiež pracujú v tandeme so zadným deltoidom ako vonkajšie (bočné) rotátory, antagonisti silných vnútorných rotátorov, ako sú prsné svaly a najširší sval chrbta.',
    joints: [
      'scapula',
      'clavicle',
      'humerus',
      'upperArm',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula', 'clavicle'],
    insertJoints: ['humerus'],
    movements:
      'Stabilizácia glenohumerálneho kĺbu, pohyb ruky dopredu, dozadu a do strán (abdukcia)',
    musclesSameCategory: [
      'trapezius',
      'pectoralisMajor',
      'bicepsBrachii',
      'coracobrachialis',
      'supraspinatus',
      'infraspinatus',
      'teresMinor',
      'teresMajor',
    ],
  },
  coracobrachialis: {
    id: 'coracobrachialis',
    name: 'Zobákovoramenný sval (Coracobrachialis)',
    origin: ['Korakoidný výbežok lopatky'],
    insertion: ['Anteromediálny povrch drieku humeru'],
    function: ['Flexia a addukcia ramena v ramennom kĺbe'],
    innervation: 'Muskulokutánny nerv (C5- C7)',
    bloodSupply: 'Svalové vetvy brachiálnej artérie',
    description:
      'Hlavné činnosti zobákovoramenného svalu (coracobrachialis) sú ohýbanie ramena (flexia) a priťahovanie ramena k trupu (addukcia) v ramennom kĺbe. Pri abdukcii a extenzii ramena pôsobí zobákovoramenný sval (coracobrachialis) ako silný antagonista deltového svalu.',
    joints: [
      'scapula',
      'humerus',
      'shoulder',
      'upperArm',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['humerus'],
    movements:
      'Ohnutie (flexia) ramena a pritiahnutie ramena k trupu (addukcia)',
    musclesSameCategory: [
      'pectoralisMinor',
      'bicepsBrachii',
      'tricepsBrachii',
      'pectoralisMajor',
      'deltoid',
      'subscapularis',
      'teresMajor',
      'brachioradialis',
    ],
  },
  bicepsBrachii: {
    id: 'bicepsBrachii',
    name: 'Dvojhlavý sval ramena (Biceps brachii)',
    origin: [
      'Krátka hlava - vrchol korakoidného výbežku lopatky',
      'Dlhá hlava - Supraglenoidný tuberkulus lopatky',
    ],
    insertion: ['Radiálna tuberozita radia', 'Hlboká fascia predlaktia'],
    function: [
      'Flexia a supinácia predlaktia v lakťovom kĺbe, slabá flexia ramena v glenohumerálnom spoji',
    ],
    innervation: 'Muskulokutánny nerv (C5- C6)',
    bloodSupply: 'Vetvy brachiálnej artérie',
    description:
      'Biceps je dvojkĺbový sval. V ramennom kĺbe obe svalové hlavy čiastočne vykonávajú opačné pohyby. Dlhá hlava ťahá rameno smerom od trupu (abdukcia) a otáča ho dovnútra (rotácia dovnútra), zatiaľ čo krátka hlava ťahá rameno späť k trupu (addukcia). Keď sa obe hlavy stiahnu súčasne, vedie to k ohnutiu ruky(flexii).',
    joints: [
      'scapula',
      'humerus',
      'radius',
      'elbow',
      'shoulder',
      'upperArm',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula'],
    insertJoints: ['radius'],
    movements: 'Flexia a supinácia predlaktia, abdukcia v ramennom kĺbe',
    musclesSameCategory: [
      'coracobrachialis',
      'pectoralisMinor',
      'brachioradialis',
      'pronatorTeres',
      'tricepsBrachii',
      'deltoid',
      'pectoralisMajor',
      'brachialis',
    ],
  },
  brachialis: {
    id: 'brachialis',
    name: 'Ramenný sval (Brachialis)',
    origin: ['Distálna polovica prednej plochy ramennej kosti (humerus)'],
    insertion: [
      'Koronoidný výbežok lakťovej kosti (ulna); tuberozita lakťovej kosti',
    ],
    function: ['Lakťový kĺb: Silná flexia predlaktia'],
    innervation: 'Muskulokutánny nerv (C5,C6); radiálny nerv (C7)',
    bloodSupply:
      'Brachiálna artéria, radiálna rekurentná artéria, (príležitostne) vetvy z hornej a dolnej ulnárnej kolaterálnej artérie',
    description:
      'Brachialis je známy ako pracovný kôň lakťa. Je to hlavný ohýbač predlaktia v lakťovom kĺbe, ktorý ohýba lakeť vo všetkých polohách. Brachialis je jediný čistý flexor lakťového kĺbu - vytvára väčšinu sily počas flexie lakťa. Nie je ovplyvnený pronáciou alebo supináciou predlaktia a nezúčastňuje sa na pronácii a supinácii z dôvodu chýbajúceho úponu na vretennej kosti (radius).',
    joints: [
      'humerus',
      'ulna',
      'upperArm',
      'elbow',
      'upperBody',
      'fullBody',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['ulna'],
    movements: 'Silné ohnutie predlaktia',
    musclesSameCategory: [
      'bicepsBrachii',
      'brachioradialis',
      'pronatorTeres',
      'tricepsBrachii',
      'pronatorTeres',
    ],
  },
  tricepsBrachii: {
    id: 'tricepsBrachii',
    name: 'Trojhlavý sval ramena (Triceps brachii)',
    origin: [
      'Dlhá hlava - Infraglenoidný tuberkulus lopatky',
      'Mediálna hlava - zadná fascia ramennej kosti',
      'Laterálna hlava - zadná fascia ramennej kosti',
    ],
    insertion: ['Olekranónový výbežok lakťovej kosti (ulna)'],
    function: ['Predĺženie (extenzia) predlaktia'],
    innervation: 'Radiálny nerv (C6-C8)',
    bloodSupply: 'Artéria profunda brachii, horná lakťová kolaterálna artéria',
    description:
      'Triceps je extenzorový sval lakťového kĺbu a antagonista svalov bicepsu a brachialisu.',
    joints: [
      'scapula',
      'humerus',
      'radius',
      'upperArm',
      'elbow',
      'shoulder',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['scapula', 'humerus'],
    insertJoints: ['ulna'],
    movements: 'Extenzia predlaktia, flexia a addukcia v ramennom kĺbe',
    musclesSameCategory: [
      'infraspinatus',
      'teresMinor',
      'teresMajor',
      'brachialis',
      'bicepsBrachii',
      'brachioradialis',
      'extensorCarpiRadialisLongus',
    ],
  },
  brachioradialis: {
    id: 'brachioradialis',
    name: 'Ramennovretenný sval (Brachioradialis)',
    origin: [
      'Laterálna suprakondylická tuhá časť ramennej kosti (humerus) a laterálna medzisvalová priehradka',
    ],
    insertion: ['Styloidný výbežok vretennej kosti (radius)'],
    function: ['Ohnutie predlaktia v lakťovom kĺbe'],
    innervation: 'Radiálny nerv (C5-C6)',
    bloodSupply:
      'Radiálna artéria, radiálna rekurentná artéria, radiálna kolaterálna artéria',
    description:
      'Ramennovretenný sval (brachioradialis) pracuje spolu s dvojhlavým svalom ramena (bicepsom brachii) a ramenným svalom (brachialis) na ohýbaní predlaktia v lakti. Ramennovretenný sval (brachioradialis) je silný ohýbač predlaktia, keď je predlaktie polopronované, čo znamená, že dlaň je kolmo k zemi. Keď je predlaktie pronačnej polohe, ramennovretenný sval (brachioradialis) má pri flexii tendenciu supinovať. V supinovanej polohe má pri ohýbaní tendenciu pronovať. Jednoducho to robí iný pohyb v závislosti od aktuálnej polohy.',
    joints: [
      'humerus',
      'radius',
      'upperArm',
      'elbow',
      'forearm',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['radius'],
    movements:
      'Silná flexia predlaktia v lakťovom kĺbe, pronácia a supinácia predlaktia',
    musclesSameCategory: [
      'pronatorTeres',
      'extensorCarpiRadialisLongus',
      'extensorCarpiRadialisBrevis',
      'anconeus',
    ],
  },
  pronatorTeres: {
    id: 'pronatorTeres',
    showPremium: true,
    name: 'Oblý pronátor (Pronator teres)',
    origin: [
      'Hlava ramennej kosti: mediálny suprakondylický hrebeň ramennej kosti (humerus)',
      'Hlavica lakťovej kosti: Koronoidný výbežok lakťovej kosti',
    ],
    insertion: [
      'Laterálna plocha vretennej kosti (radius, distálne od supinátora)',
    ],
    function: [
      'Pronácia predlaktia v proximálnom radioulnárnom kĺbe, flexia predlaktia v lakťovom kĺbe',
    ],
    innervation: 'Stredový nerv (C6, C7)',
    bloodSupply: 'Vetvy brachiálnej, radiálnej a ulnárnej tepny',
    description:
      'Hlavnou činnosťou oblého pronátoru (pronator teres) je pronácia predlaktia. Sval ťahá vretennú kosť (radius) mediálne, čím spôsobuje rotáciu jeho hlavičky okolo proximálnej časti lakťovej kosti (ulna) v proximálnom radioulnárnom kĺbe. Taktiež slabo ohýba lakeť alebo pri silnom odpore pomáha pri flexii v lakti.',
    joints: [
      'humerus',
      'radius',
      'ulna',
      'elbow',
      'forearm',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus', 'ulna'],
    insertJoints: ['radius'],
    movements: 'Pronation of forearm, weak flexion of elbow',
    musclesSameCategory: [
      'brachioradialis',
      'brachialis',
      'bicepsBrachii',
      'TricepsBrachii',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'flexorDigitorumSuperficialis',
      'flexorDigitorumProfundus',
    ],
  },
  flexorCarpiRadialis: {
    id: 'flexorCarpiRadialis',
    name: 'Vretenný ohýbač zápästia (Flexor carpi radialis)',
    origin: ['Mediálny epikondyl ramennej kosti (humerus)'],
    insertion: ['Základy záprstných kostí 2-3'],
    function: ['Zápästný kĺb: flexia zápästia, abdukcia zápästia'],
    innervation: 'Stredový nerv (C6, C7)',
    bloodSupply: 'Predná/zadná rekurentná lakťová artéria, radiálna artéria',
    description:
      'Vretenný ohýbač zápästia (flexor carpi radialis) ťahá ruku proximálne a laterálne, čo znamená, že je schopný vykonávať kombinovaný pohyb flexie a abdukcie zápästia.',
    joints: [
      'humerus',
      'fingersHand',
      'elbow',
      'hand',
      'forearm',
      'fingers4',
      'hand',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand', 'h2', 'h3', 'h4'],
    movements: 'Flexia a abdukcia zápästia, pronácia predlaktia',
    musclesSameCategory: [
      'pronatorTeres',
      'brachioradialis',
      'anconeus',
      'flexorCarpiUlnaris',
      'palmarisLongus',
      'flexorDigitorumSuperficialis',
      'flexorDigitorumProfundus',
    ],
  },
  flexorCarpiUlnaris: {
    id: 'flexorCarpiUlnaris',
    showPremium: true,
    name: 'Lakťový ohýbač zápästia (Flexor carpi ulnaris)',
    origin: [
      'Mediálny epikondyl ramennej kosti (humerus)',
      'Olekranon a zadný okraj lakťovej kosti',
    ],
    insertion: [
      'Najmenšia zápästná kostička , kladivková kosť, základňa záprstnej kosti 5',
    ],
    function: ['Zápästný kĺb: flexia zápästia, addukcia zápästia'],
    innervation: 'Ulnárny nerv (C7-T1)',
    bloodSupply: 'Zadná ulnárna rekurentná artéria, ulnárna artéria',
    description:
      'Lakťový ohýbač zápästia (flexor carpi ulnaris) dokáže pohybovať rukou do strán, rovnako ako ju ohýbať. Pri kontrakcii s vretenným ohýbačom zápästia (flexor carpi radialis) a dlhým dlaňovým svalom (palmaris longus) spôsobuje lakťový ohýbač zápästia (flexor carpi ulnaris) ohnutie ruky v zápästnom kĺbe. Keď sa stiahne spolu s vystiračom malíčka (extenzorom carpi ulnaris) v zadnom oddiele, ich protichodné sily spôsobia addukciu ruky v zápästí.',
    joints: [
      'humerus',
      'ulna',
      'fingersHand',
      'elbow',
      'forearm',
      'fingers4',
      'hand',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['ulna', 'humerus'],
    insertJoints: ['hand', 'h5'],
    movements: 'Flexia a addukcia zápästia',
    musclesSameCategory: [
      'pronatorTeres',
      'brachioradialis',
      'anconeus',
      'flexorCarpiRadialis',
      'palmarisLongus',
      'flexorDigitorumSuperficialis',
      'flexorDigitorumProfundus',
    ],
  },
  palmarisLongus: {
    id: 'palmarisLongus',
    name: 'Dlhý dlaňový sval (Palmaris longus)',
    origin: ['Mediálny epikondyl ramennej kosti (humerus)'],
    insertion: ['Priečny karpálny väz, dlaňová aponeuróza'],
    function: ['Zápästný kĺb: flexia zápästia', 'Napína dlaňovú aponeurózu'],
    innervation: 'Stredový nerv (C7, C8)',
    bloodSupply: 'Predná ulnárna rekurentná artéria, mediánna artéria',
    description:
      'Dlhý dlaňový sval (palmaris longus) pomáha lakťovému ohýbaču zápästia (flexor carpi ulnaris) a vretennému ohýbaču zápästia (flexor carpi radialis) vykonávať vyváženú flexiu ruky na zápästí. Pôsobí tiež na stabilizáciu lakťového kĺbu pri úplnom vystretí, rovnako ako ostatné svaly predlaktia, ktoré sa upínajú na ramennú kosť, a tak prekrížujú lakťový kĺb. Vlákna pripájajúce sa na dlaňovú aponeurózu napínajú tento fasciálny obal počas kontrakcie svalu, čo vedie k slabej flexii 2. až 5. metakarpofalangeálneho kĺbu, pretože aponeuróza je ťahaná smerom k zápästiu. Sťahovanie dlaňovej aponeurózy tiež prispieva k udržaniu úchopu ruky pri držaní predmetov.',
    joints: [
      'humerus',
      'fingersHand',
      'hand',
      'forearm',
      'elbow',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand'],
    movements: 'Ohýbanie zápästia, napína palmárnu aponeurózu',
    musclesSameCategory: [
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'pronatorTeres',
      'flexorDigitorumSuperficialis',
      'flexorDigitorumProfundus',
    ],
  },
  flexorDigitorumSuperficialis: {
    id: 'flexorDigitorumSuperficialis',
    name: 'Povrchový ohýbač prstov (Flexor digitorum superficialis)',
    origin: [
      'Humeroulnárna hlava: Mediálny epikondyl ramennej kosti (humerus)',
      'Radiálna hlava: Proximálna polovica predného okraja radia',
      'Koronoidný výbežok lakťovej kosti (ulna)',
    ],
    insertion: ['Strany stredných článkov prstov 2-5'],
    function: ['Ohýbanie prstov 2-5 (nie palca)'],
    innervation: 'Stredový nerv (C8, T1)',
    bloodSupply: 'Ulnárna artéria, radiálna artéria, mediánna artéria',
    description:
      'Hlavnou funkciou povrchový ohýbač prstov (flexora digitorum superficialis) je ohýbanie prstov 2-5 v proximálnych interfalangeálnych a metakarpofalangeálnych kĺboch. Na rozdiel od hĺbkového ohýbača prstov (flexor digitorum profundus) má povrchový ohýbač prstov (flexor digitorum superficialis) nezávislé svalové sklzy pre všetky štyri prsty. To mu umožňuje ohýbať jednotlivé prsty v proximálnych interfalangeálnych kĺboch. Okrem toho povrchový ohýbač prstov (flexor digitorum superficialis) napomáha pomocnej flexii zápästia.',
    joints: [
      'humerus',
      'ulna',
      'radius',
      'fingersHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus', 'ulna', 'radius'],
    insertJoints: ['hand', 'h2', 'h3', 'h4', 'h5'],
    movements: 'Ohýbanie 2 - 5 prstov',
    musclesSameCategory: [
      'flexorDigitorumProfundus',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'pronatorTeres',
      'palmarisLongus',
      'pronatorQuadratus',
    ],
  },
  flexorDigitorumProfundus: {
    id: 'flexorDigitorumProfundus',
    name: 'Hĺbkový ohýbač prstov (Flexor digitorum profundus)',
    origin: [
      'Proximálna polovica prednej plochy lakťovej kosti (ulna), interoseálna membrána',
    ],
    insertion: ['Dlaňové plochy distálnych článkov prstov 2-5'],
    function: ['Ohýbanie prstov 2-5 (nie palca)'],
    innervation: 'Prsty 2-3: stredný nerv; prsty 4-5: lakťový nerv (C8, T1)',
    bloodSupply:
      'Ulnárna, spoločná interoseálna, ulnárna kolaterálna, ulnárna rekurentná, predná interoseálna, mediánna artéria',
    description:
      'Hĺbkový ohýbač prstov (flexor digitorum profundus) je silný ohýbač prstov. Priťahuje distálne články prstov smerom k ruke a spôsobuje tak flexiu 2. až 5. prsta v metakarpofalangeálnych a interfalangeálnych kĺboch. Sval môže pôsobiť samostatne, ale zvyčajne pri tejto činnosti spolupracuje s povrchovým ohýbač prstov (flexorom digitorum superficialis), lumbrikálami a krátky ohýbač malíčka (flexorom digiti minimi brevis). Tento sval je tiež akcesorným ohýbačom zápästia, ktorý pomáha svalom lakťový ohýbač zápästia (flexor carpi ulnaris), vonkajší ohýbač zápästia (flexor carpi radialis), dlhý dlaňový sval (palmaris longus), hĺbkový ohýbač prstov (flexor digitorum superficialis) a dlhý ohýbač palca (flexor pollicis longus) ohýbať ruku v zápästnom kĺbe. Činnosť hĺbkového ohýbača prstov (flexor digitorum profundus) je dôležitá na vytvorenie a udržanie pevného úchopu ruky.',
    joints: [
      'ulna',
      'fingersHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['ulna'],
    insertJoints: ['hand', 'h2', 'h3', 'h4', 'h5'],
    movements: 'Ohýbanie prstov 2-5, ohyb zápästia',
    musclesSameCategory: [
      'flexorDigitorumSuperficialis',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'pronatorTeres',
      'palmarisLongus',
      'pronatorQuadratus',
    ],
  },
  pronatorQuadratus: {
    id: 'pronatorQuadratus',
    name: 'Štvoruhlý privracač (Pronator quadratus)',
    origin: ['Distálna predná plocha lakťovej kosti (ulna)'],
    insertion: ['Distálna predná plocha vretennej kosti (radius)'],
    function: ['Proximálny radioulnárny kĺb: Pronácia predlaktia'],
    innervation: 'Stredový nerv (predný medzikostný nerv, C7, C8)',
    bloodSupply: 'Predná interoseálna artéria',
    description:
      'Štvoruhlý sval (pronator quadratus) je zodpovedný za pronáciu predlaktia tým, že pôsobí na proximálny radioulnárny kĺb. Počas tohto pohybu sa hlavička radia otáča okolo ulny, čo spôsobuje, že dlaň smeruje dozadu alebo nadol, ak je predlaktie ohnuté. Oblý sval v pronácií (pronator teres) a ramennovretenný sval (brachioradialis) mu pomáhajú v tom pomáhajú. Umiestnenie štvoruhlého svalu (pronator quadratus) v distálnej časti predlaktia ho robí dôležitým pri poskytovaní ochrany, počas činností spojených s prenášaním váhy, keď je vyvíjaný tlak smerom nahor, štvoruhlý sval  (pronator quadratus) stabilizuje a chráni distálny radioulnárny kĺb tým, že drží spolu distálne konce vretennej (radius) a lakťovej kosti (ulna).',
    joints: ['ulna', 'radius', 'forearm', 'hand', 'upperBody', 'fullBody'],
    originJoints: ['ulna'],
    insertJoints: ['radius'],
    movements: 'Pronácia predlaktia',
    musclesSameCategory: [
      'flexorDigitorumProfundus',
      'supinator',
      'flexorDigitorumSuperficialis',
      'flexorPollicisLongus',
      'pronatorTeres',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'palmarisLongus',
      'brachioradialis',
    ],
  },
  supinator: {
    id: 'supinator',
    name: 'Supinátor (Supinator)',
    origin: [
      'Laterálny epikondyl ramennej kosti (humerus), radiálny kolaterálny väz, prstencovitý väz',
      ' Supinátorový hrebeň lakťovej kosti (ulna)',
    ],
    insertion: [
      'Bočné, zadné a predné plochy proximálnej tretiny vretennej kosti (radius)',
    ],
    function: ['Proximálny radioulnárny kĺb: Supinácia predlaktia'],
    innervation: 'Zadný medzikostný nerv (C7, C8)',
    bloodSupply:
      'Radiálna rekurentná artéria, zadná interoseálna artéria, zadná interoseálna rekurentná artéria',
    description:
      'Supinator je zodpovedný za laterálnu rotáciu v proximálnom radioulnárnom kĺbe. Tento úkon vyrovnáva vretennú kosť (radius) paralelne s lakťovou kosťou (ulnou), čo vedie k tomu, že ruka zaujme polohu dlaňou nahor, ako keď držíte misku s ovocím. Počas pomalej a neoponovanej supinácie funguje supinátor ako primárny sval toho pohybu. Príkladom je pomalá rotácia ruky pri chytaní ovocia z misky. Keď si však supinácia vyžaduje rýchly, silový pohyb alebo prebieha proti odporu, dvojhlavý sval (biceps brachii) pomáha supinátoru. Stojí za zmienku, že dvojhlavý sval (biceps brachii) nemôže slúžiť ako supinátor, keď je predlaktie úplne vystreté. Preto najsilnejší supinačný pohyb nastáva, keď je lakťový kĺb ohnutý na 90 stupňov. Túto činnosť vidíme pri otáčaní skrutkovačom alebo pri vyťahovaní korku z fľaše vína.',
    joints: [
      'humerus',
      'ulna',
      'radius',
      'elbow',
      'forearm',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus', 'ulna'],
    insertJoints: ['radius'],
    movements: 'Supinácia predlaktia',
    musclesSameCategory: [
      'brachialis',
      'brachioradialis',
      'pronatorTeres',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'palmarisLongus',
      'flexorPollicisLongus',
      'flexorDigitorumProfundus',
      'flexorDigitorumSuperficialis',
      'pronatorQuadratus',
    ],
  },
  flexorPollicisLongus: {
    id: 'flexorPollicisLongus',
    name: 'Dlhý ohýbač palca (Flexor Pollicis Longus)',
    origin: ['Predná plocha vretennej kosti (radia) a interoseálna membrána'],
    insertion: ['Palmárna plocha distálnej palcovej kosti palca'],
    function: [
      'Metakarpofalangeálny (Metacarpophalangeal) a interfalangeálny (interphalangeal) kĺb 1; ohyb palca',
    ],
    innervation: 'Stredový nerv (predný medzikostný nerv C7, C8)',
    bloodSupply:
      'Mediálna časť: predná interoseálna artéria (ulnárna artéria); laterálna časť: radiálna artéria',
    description:
      'Hlavnou úlohou dlhého ohýbača palca (flexor pollicis longus) je ohýbať interfalangeálny kĺb palca, čo je kľúčové pre úchopové činnosti. Tento sval je jedinečný svojou schopnosťou ohýbať medzilopatkový kĺb palca, vďaka čomu je nevyhnutný pri úchopových činnostiach ruky. Okrem toho dlhý ohýbač palca (flexor pollicis longus) prispieva k ohýbaniu palca v metakarpofalangeálnom kĺbe a ohýbaniu zápästia. Pri ohýbaní palca možno tento sval ľahko nahmatať v dolnej tretine predlaktia.',
    joints: [
      'radius',
      'fingersHand',
      'thumbHand',
      'forearm',
      'hand',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['radius'],
    insertJoints: ['hand', 'h1'],
    movements: 'Ohýbanie palca',
    musclesSameCategory: [
      'flexorDigitorumProfundus',
      'supinator',
      'flexorDigitorumSuperficialis',
      'pronatorQuadratus',
      'pronatorTeres',
      'flexorCarpiRadialis',
      'flexorCarpiUlnaris',
      'palmarisLongus',
      'brachioradialis',
    ],
  },
  anconeus: {
    id: 'anconeus',
    name: 'Lakťový sval (Anconeus)',
    origin: ['Bočný epikondyl ramennej kosti'],
    insertion: ['Laterálny povrch olekranónu'],
    function: [
      'Pomáha pri extenzii predlaktia v lakťovom kĺbe',
      'Stabilizácia lakťového kĺbu',
    ],
    innervation: 'Radiálny nerv (C7-C8)',
    bloodSupply: 'Zadná interoseálna rekurentná artéria',
    description:
      'Funkčne je lakťový sval (anconeus) pokračovaním svalu triceps brachii, ktorý vykazuje rovnakú činnosť v lakti. Jeho kontrakcia vedie k extenzii predlaktia. \u010ealšou funkciou je abdukcia lakťovej kosti, najmä počas pronačných pohybov predlaktia.',
    joints: ['humerus', 'radius', 'elbow', 'forearm', 'upperBody', 'fullBody'],
    originJoints: ['humerus'],
    insertJoints: ['radius'],
    movements: 'Extenzia predlaktia, stabilizácia lakťového kĺbu',
    musclesSameCategory: [
      'brachioradialis',
      'extensorCarpiRadialisLongus',
      'extensorDigitorum',
      'flexorCarpiUlnaris',
      'extensorCarpiUlnaris',
      'extensorDigitiMinimi',
      'tricepsBrachii',
    ],
  },
  extensorCarpiRadialisLongus: {
    id: 'extensorCarpiRadialisLongus',
    name: 'Dlhý vonkajší vystierač zápästia (Extensor carpi radialis longus)',
    origin: ['Laterálny suprakondylický hrebeň ramennej kosti'],
    insertion: ['Zadná strana bázy záprstnej kosti 2'],
    function: ['Zápästné kĺby: Extenzia ruky, abdukcia ruky'],
    innervation: 'Radiálny nerv (C5-C8)',
    bloodSupply:
      'Radiálna rekurentná artéria, radiálna kolaterálna artéria, radiálna artéria',
    description:
      'Tento sval je extenzorom v zápästnom kĺbe a pohybuje sa pozdĺž vretennej (radiálnej) strany ruky, takže tiež abdukuje (radiálna abdukcia) ruku v zápästí. Spolu s ostatnými extenzormi predlaktia zohráva dlhý vonkajší vystierač zápästia (extenzor carpi radialis longus) dôležitú úlohu pri uchopení ruky.',
    joints: [
      'humerus',
      'fingersHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand', 'h2'],
    movements: 'Extenzia a abdukcia ruky, uchopenie ruky',
    musclesSameCategory: [
      'extensorCarpiRadialisBrevis',
      'extensorDigitorum',
      'extensorPollicisLongus',
      'extensorDigitiMinimi',
      'extensorCarpiUlnaris',
      'extensorPollicisBrevis',
      'anconeus',
    ],
  },
  extensorCarpiRadialisBrevis: {
    id: 'extensorCarpiRadialisBrevis',
    name: 'Krátky vonkajší vystierač zápästia (Extensor carpi radialis brevis)',
    origin: ['Bočný epikondyl ramennej kosti'],
    insertion: ['Zadná strana bázy záprstnej kosti 3'],
    function: [
      'Zápästné kĺby: Extenzia ruky, abdukcia ruky (radiálna deviácia)',
    ],
    innervation: 'Radiálny nerv (C5- C6)',
    bloodSupply:
      'Radiálna rekurentná artéria, radiálna artéria, hlboká brachiálna artéria',
    description:
      'Krátky vonkajší vystierač zápästia (extensor carpi radialis brevis) spolupracuje s vnútorným vystieračom zápästia (extensor carpi ulnaris) a dlhým vonkajším vystieračom zápästia (extensor carpi radialis longus) na predĺžení (extenzia) ruky v zápästnom kĺbe. Táto činnosť je dôležitá v sekvencii svalových kontrakcií potrebných na zovretie päste alebo na vytvorenie úchopu. Je to tiež abduktor ruky. Slúži na manipuláciu so zápästím tak, aby sa prsty pohybovali smerom od dlane.',
    joints: [
      'humerus',
      'fingesHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand', 'h3'],
    movements: 'Extension and abduction of hand',
    musclesSameCategory: [
      'extensorCarpiRadialisLogus',
      'extensorDigitorum',
      'extensorPollicisLongus',
      'extensorDigitiMinimi',
      'extensorCarpiUlnaris',
      'extensorPollicisBrevis',
      'anconeus',
    ],
  },
  extensorDigitorum: {
    id: 'extensorDigitorum',
    name: 'Vystierač prstov (Extensor digitorum)',
    origin: ['Bočný epikondyl ramennej kosti'],
    insertion: ['Extenzorové expanzie prstov 2-5'],
    function: [
      'Metakarpofalangeálne a interfalangeálne kĺby 2-5: Extenzia prstov',
    ],
    innervation: 'Zadný medzikostný nerv (C7, C8)',
    bloodSupply:
      'Zadná interoseálna artéria, radiálna rekurentná artéria, predná interoseálna artéria',
    description:
      'Hlavnou funkciou je extenzia štyroch mediálnych prstov v metakarpofalangeálnych a proximálnych a distálnych interfalangeálnych kĺboch. Tento sval pôsobí proti pôsobeniu dvoch flexorov prstov - povrchový ohýbač prstov (flexor digitorum superficialis) a hĺbkový ohýbač prstov (flexor digitorum profundus). Tento sval sa podieľa na úchopovej činnosti, prispieva k otvoreniu ruky na púšťanie predmetov. Keďže prechádza cez zápästie, jeho kontrakcia sa podieľa aj na extenzii zápästia.',
    joints: [
      'humerus',
      'fingersHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand', 'h2', 'h3', 'h4', 'h5'],
    movements: 'Extenzia štyroch mediálnych prstov, extenzia zápästia',
    musclesSameCategory: [
      'extensorCarpiRadialisLogus',
      'extensorCarpiRadialisBrevis',
      'extensorPollicisLongus',
      'extensorDigitiMinimi',
      'extensorCarpiUlnaris',
      'extensorPollicisBrevis',
      'anconeus',
    ],
  },
  extensorPollicisLongus: {
    id: 'extensorPollicisLongus',
    showPremium: true,
    name: 'Dlhý vystierač palca (Extensor pollicis longus)',
    origin: [
      'Zadná plocha strednej tretiny lakťovej kosti',
      'Interosseálna membrána',
    ],
    insertion: ['Zadná strana bázy distálnej falangy palca'],
    function: [
      'Zápästné kĺby: Slabé predĺženie ruky (extenzia)',
      'Metakarpofalangeálny a interfalangeálny kĺb palca: Predĺženie palca (extenzia)',
    ],
    innervation: 'Zadný medzikostný nerv (C7, C8)',
    bloodSupply: 'Zadná interoseálna artéria, predná interoseálna artéria',
    description:
      'Hlavnou činnosťou je predĺženie (extenzia) palca v metakarpofalangeálnych a interfalangeálnych kĺboch. Extenzia v metakarpofalangeálnom kĺbe prebieha v súčinnosti s krátkym vystieračom palca (extensor pollicis brevis). Keď palec dosiahne úplnú extenziu alebo abdukciu, dlhý vystierač palca (extensor pollicis longus) môže pomáhať aj pri addukcii palca.',
    joints: [
      'ulna',
      'fingersHand',
      'forearm',
      'hand',
      'thumbHand',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['ulna'],
    insertJoints: ['hand', 'h1'],
    movements: 'Predĺženie palca (extenzia)',
    musclesSameCategory: [
      'extensorCarpiUlnaris',
      'extensorCarpiRadialisLogus',
      'extensorCarpiRadialisBrevis',
      'extensorDigitorum',
      'extensorDigitiMinimi',
      'extensorPollicisBrevis',
      'anconeus',
    ],
  },
  extensorPollicisBrevis: {
    id: 'extensorPollicisBrevis',
    showPremium: true,
    name: 'Krátky vystierač palca (Extensor pollicis brevis)',
    origin: [
      'Zadná plocha distálnej tretiny vretennej kosti (radia)',
      'Interosseálna membrána',
    ],
    insertion: ['Zadná strana základne proximálnej falangy palca'],
    function: [
      'Karpometakarpálny a metakarpofalangeálny kĺb 1: extenzia palca',
    ],
    innervation: 'Zadný medzikostný nerv (C7, C8)',
    bloodSupply: 'Zadná interoseálna artéria, predná interoseálna artéria',
    description:
      'Spolu s dlhým vystieračom palca (extensor pollicis longus) je krátky vystieračom palca (extensor pollicis brevis) zodpovedný za extenziu palca v prvom metakarpofalangeálnom kĺbe. Taktiež predlžuje palec v karpometakarpálnom kĺbe palca. Tento pohyb je dôležitý pri úchope, pretože umožňuje pustiť predmet. Keďže prechádza cez zápästie, krátky vystierač palca (extensor pollicis brevis) sa podieľa aj na extenzii a abdukcii zápästia.',
    joints: [
      'radius',
      'fingersHand',
      'forearm',
      'hand',
      'thumbHand',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['radius'],
    insertJoints: ['hand', 'h1'],
    movements: 'Extenzia palca, extenzia a abdukcia zápästia',
    musclesSameCategory: [
      'extensorCarpiUlnaris',
      'extensorCarpiRadialisLogus',
      'extensorCarpiRadialisBrevis',
      'extensorDigitorum',
      'extensorDigitiMinimi',
      'extensorPollicisLongus',
      'anconeus',
    ],
  },
  extensorDigitiMinimi: {
    id: 'extensorDigitiMinimi',
    name: 'Vystierač malíčka (Extensor Digiti Minimi)',
    origin: ['Bočný epikondyl ramennej kosti (humerus)'],
    insertion: ['Extenzorová expanzia malíčku'],
    function: ['Metakarpofalangeálny kĺb 5: natiahnutie prsta (extenzia)'],
    innervation: 'Zadný medzikostný nerv (C7, C8)',
    bloodSupply:
      'Radiálna rekurentná artéria, predná interoseálna artéria, zadná interoseálna artéria',
    description:
      'Vystierač malíčka (extensor digiti minimi) slúži predovšetkým na predĺženie piateho prstu v metakarpofalangeálnom kĺbe. Podieľa sa aj na extenzii zápästia a všetkých kĺbov piateho prsta z lakťovej (ulnárnej) strany ruky, a to prostredníctvom jeho úponu do extenzorového rozšírenia a pôsobením spolu s vnútorným vystieračom zápästia (extensor carpi ulnaris). Malíček, ktorý má vlastný extenzorový sval, sa môže predlžovať nezávisle od 2 - 4 prstov. Je to preto, že prsty 2 - 4 sa primárne predlžujú pomocou jediného svalu, ktorý sa vkladá do všetkých prstov: vystierač prstov (extenzorov digitorum).',
    joints: [
      'humerus',
      'fingersHand',
      'littleFinger',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus'],
    insertJoints: ['hand', 'h5'],
    movements: 'Predĺženie (extenzia) malíčka',
    musclesSameCategory: [
      'extensorPollicisBrevis',
      'extensorCarpiUlnaris',
      'extensorCarpiRadialisLogus',
      'extensorCarpiRadialisBrevis',
      'extensorDigitorum',
      'extensorPollicisLongus',
      'anconeus',
    ],
  },
  extensorCarpiUlnaris: {
    id: 'extensorCarpiUlnaris',
    name: 'Vnútorný vystierač zápästia (Extensor Carpi Ulnaris)',
    origin: [
      'Bočný epikondyl ramennej kosti (humerus)',
      'zadný okraj lakťovej kosti (ulna)',
    ],
    insertion: ['Základňa záprstnej kosti 5'],
    function: ['Zápästný kĺb: Extenzia a addukcia ruky'],
    innervation: 'Zadný medzikostný nerv (C7, C8)',
    bloodSupply: 'Radiálna rekurentná artéria, zadná interoseálna artéria',
    description:
      'V spolupráci s dlhým vonkajším vystieračom zápästia (extensor carpi radialis brevis) a krátkym vonkajším vystieračom zápästia  (extensor carpi radialis longus) prispieva tento sval k vyváženej extenzii zápästia bez vychýlenia ruky v priečnej rovine. Táto činnosť je dôležitá pri činnostiach ruky, ako je silné zovretie päste alebo silový úchop. Pri tomto procese extenzia zápästia blokuje pôsobenie flexorov predlaktia. Namiesto toho zostávajú tieto flexory schopné pôsobiť výlučne na prsty a ohýbať ich tak, aby vznikol účinný úchop, ako je to napríklad pri tenisovom bekhende. Podobne, synergické pôsobenie lakťového ohýbača prstov (flexora carpi ulnaris) a vnútorného vystierača zápästia (extenzora carpi ulnaris) vedie k vyváženej addukcii (ulnárnej deviácii) ruky v zápästí. To znamená addukciu bez pridanej flexie alebo extenzie. Táto činnosť je potrebná pri procesoch, ako sú údery kladivom, hádzanie, golf a švihanie bejzbalovou palicou. Tento sval prispieva aj k stabilite distálneho radioulnárneho kĺbu.',
    joints: [
      'humerus',
      'ulna',
      'fingersHand',
      'elbow',
      'forearm',
      'hand',
      'fingers4',
      'upperBody',
      'fullBody',
    ],
    originJoints: ['humerus', 'ulna'],
    insertJoints: ['hand', 'h5'],
    movements: 'Extenzia a addukcia ruky',
    musclesSameCategory: [
      'extensorPollicisBrevis',
      'extensorDigitiMinimi',
      'extensorCarpiRadialisLogus',
      'extensorCarpiRadialisBrevis',
      'extensorDigitorum',
      'extensorPollicisLongus',
      'anconeus',
    ],
  },
  rectusAbdominis: {
    id: 'rectusAbdominis',
    name: 'Priamy sval brucha (Rectus abdominis)',
    origin: ['Lonová spona, hrebeň lonovej kosti'],
    insertion: ['Xiphoidný výbežok, rebrové chrupavky rebier 5-7'],
    function: ['Ohnutie trupu, stláča brušné vnútornosti, výdych'],
    innervation: 'Medzirebrové nervy (T7-T11), podrebrové nervy (T12)',
    bloodSupply:
      'Dolné epigastrické a horné epigastrické tepny; účasť zadných medzirebrových, podrebrových a hlbokých obtokových tepien',
    description:
      'Priamy brušný sval je dôležitý posturálny sval. Je zodpovedný za ohýbanie bedrovej chrbtice, napríklad pri drepoch. Pri fixácii panvy sa hrudný kôš priblíži k miestu, kde sa nachádza panva, alebo sa panva môže priblížiť k hrudnému košu (zadný sklon panvy), keď je hrudný kôš fixovaný, ako napríklad pri zdvihu nôh a bokov. Okrem toho tento sval v spolupráci s ostatnými brušnými svalmi stláča brušné vnútornosti a zvyšuje vnútrobrušný tlak, čo má dôležitú funkciu pri procesoch, ako je napríklad vynútené dýchanie a pôrod.',
    joints: ['pelvis', 'ribs', 'ribsMid', 'core', 'midBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['ribs', 'r5', 'r6', 'r7'],
    movements:
      'Stabilita hrudníka a panvy, udržiavanie napätia brušnej steny - udržiavanie orgánov na mieste',
    musclesSameCategory: [
      'internalOblique',
      'externalOblique',
      'tranversusAbdominis',
      'pectoralisMajor',
      'psoasMinor',
      'psoasMajor',
      'pectineus',
      'adductorLongus',
      'adductorBrevis',
      'gracilis',
    ],
  },
  tranversusAbdominis: {
    id: 'tranversusAbdominis',
    name: 'Priečny sval brucha (Tranversus abdominis)',
    origin: [
      'Vnútorné plochy rebrových chrupaviek rebier 7-12',
      'Thorakolumbálna fascia',
      'Predné dve tretiny bedrového hrebeňa',
      'Iliopektinálny oblúk (Iliopectineal arch)',
    ],
    insertion: [
      'Linea alba',
      'Aponeuróza vnútorného brušného šikmého svalu',
      'Hrebeň lonovej kosti',
    ],
    function: [
      'Bilaterálna kontrakcia - stláča brušné vnútornosti, výdych',
      'Jednostranná kontrakcia - rotácia trupu',
    ],
    innervation:
      'Medzirebrové nervy (T7-T11), podrebrový nerv (T12), iliohypogastrický nerv (L1), ilioinguinálny nerv (L1)',
    bloodSupply:
      'Dolné zadné medzirebrové a podrebrové tepny, horné a dolné epigastrické tepny, povrchové a hlboké cirkumflexné tepny, zadné bedrové tepny',
    description:
      'Priečny brušný sval (tranversus abdominis) pomáha stláčať rebrá a vnútornosti, čím zabezpečuje stabilitu hrudníka a panvy. Spolu s ostatnými svalmi brušnej steny zohráva priečny brušný sval dôležitú úlohu pri udržiavaní normálneho napätia brušnej steny. Tieto svaly majú teda ochrannú aj podpornú úlohu, pretože udržiavajú brušné orgány na svojom mieste. Tranversus sa podieľa na kompresii vnútrobrušných vnútorností, čím zvyšuje vnútrobrušný tlak. Táto činnosť uľahčuje expulzívne funkcie, ako je vynútený výdych a záverečné štádium pôrodu.',
    joints: ['ribs', 'pelvis', 'core', 'midBody', 'fullBody'],
    originJoints: ['ribs', 'r7', 'r8', 'r9', 'r10', 'r11', 'r12', 'pelvis'],
    insertJoints: ['pelvis'],
    movements:
      'Stabilita hrudníka a panvy, udržiavanie napätia brušnej steny - udržiavanie orgánov na mieste',
    musclesSameCategory: [
      'internalOblique',
      'externalOblique',
      'rectusAbdominis',
      'psoasMinor',
      'psoasMajor',
      'quadratusLumborum',
    ],
  },
  internalOblique: {
    id: 'internalOblique',
    name: 'Vnútorný šikmý sval brucha (Internal oblique)',
    origin: [
      'Thorakolumbálna fascia',
      'Hrebeň bedrovej kosti',
      'Inguinálny väz (Inguinal ligament)',
    ],
    insertion: [
      'Spodné okraje 10-12 rebier a priľahlých rebrových chrupaviek',
      'Linea alba',
      'Pectineálna línia',
    ],
    function: [
      'Bilaterálna kontrakcia - flexia trupu, stláča brušné vnútornosti, výdych',
      'Jednostranná kontrakcia - bočná flexia trupu (ipsilaterálna), rotácia trupu (ipsilaterálna)',
    ],
    innervation:
      'Predné ramená medzirebrových nervov (T7-T11), Podrebrový nerv (T12), Iliohypogastrický nerv (L1), Ilioinguinálny nerv (L1)',
    bloodSupply:
      'Dolné zadné medzirebrové a podrebrové tepny, horné a dolné epigastrické tepny, povrchové a hlboké cirkumflexné tepny, zadné bedrové tepny',
    description:
      'Ako pomocný dýchací sval pôsobí ako antagonista (protivník) bránice a pomáha zmenšovať objem hrudnej dutiny počas výdychu. Keď sa bránica stiahne, stiahne spodnú stenu hrudnej dutiny nadol, čím zväčší objem pľúc, ktoré sa potom naplnia vzduchom. Naopak, keď sa vnútorné šikmé svaly stiahnu, stlačia orgány brucha a zatlačia ich do bránice, ktorá zasahuje späť do hrudnej dutiny, čím sa zmenší objem pľúc naplnených vzduchom, čo spôsobí výdych. Druhá funkcia spočíva v tom, že ich kontrakcia spôsobuje ipsilaterálnu rotáciu a ohýbanie do strán. Na dosiahnutie tohto torzného pohybu trupu pôsobí spolu s vonkajším šikmým svalom (external oblique) opačnej strany.',
    joints: ['pelvis', 'ribs', 'core', 'midBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['ribs', 'r10', 'r11', 'r12'],
    movements: 'Breathing, side bending',
    musclesSameCategory: [
      'externalOblique',
      'rectusAbdominis',
      'tranversusAbdominis',
      'psoasMinor',
      'psoasMajor',
      'quadratusLumborum',
      'serratusPosteriorInferior',
    ],
  },
  externalOblique: {
    id: 'externalOblique',
    name: 'Vonkajší šikmý sval brucha (External oblique)',
    origin: ['Vonkajšie plochy rebier 5-12'],
    insertion: [
      'Linea alba',
      'Tŕň sedacej kosti (Pubic tubercle)',
      'Vnútorná polovica bedrového hrebeňa',
    ],
    function: [
      'Bilaterálna kontrakcia - flexia trupu, stláča brušné vnútornosti, výdych',
      'Jednostranná kontrakcia - bočná flexia trupu (ipsilaterálna), rotácia trupu (kontralaterálna)',
    ],
    innervation:
      'Motorický: Medzirebrové nervy (T7- T11), Podrebrový nerv (T12), Senzorický: Iliohypogastrický nerv (L1)',
    bloodSupply: 'Dolné medzirebrové tepny a podrebrové tepny',
    description:
      'Ak pôsobí jednostranne a v súčinnosti s kontralaterálnym vnútorným šikmým brušným svalom (imternal oblique), otáča trup na opačnú stranu. V spolupráci s ipsilaterálnymi brušnými a chrbtovými svalmi prispieva k laterálnej flexii trupu na tej istej strane. Pri obojstrannej kontrakcii sval spolupracuje s vnútorným šikmým svalom brucha (internal oblique) a priamym svalom brucha (rectus badominis) na flexii trupu smerom dopredu. Táto činnosť tiež zvyšuje tonus brušnej steny a pozitívny vnútrobrušný tlak, ktorý je súčasťou rôznych fyziologických procesov, ako je napríklad vynútený výdych a pôrod.',
    joints: [
      'pelvis',
      'ribs',
      'ribsMid',
      'ribsBot',
      'core',
      'midBody',
      'fullBody',
    ],
    originJoints: ['ribs', 'r5', 'r6', 'r7', 'r8', 'r9', 'r10', 'r11', 'r12'],
    insertJoints: ['pelvis'],
    movements:
      'Flexia trupu a kontralaterálna rotácia trupu, vynútené dýchanie',
    musclesSameCategory: [
      'externalOblique',
      'rectusAbdominis',
      'tranversusAbdominis',
      'psoasMinor',
      'psoasMajor',
      'quadratusLumborum',
      'serratusPosteriorInferior',
    ],
  },
  quadratusLumborum: {
    id: 'quadratusLumborum',
    name: 'Štvoruhlý driekový sval (Quadratus lumborum)',
    origin: ['Hrebeň bedrovej kosti, iliolumbálny väz (iliolumbar ligament)'],
    insertion: ['Spodný okraj rebra 12', 'Priečne výbežky stavcov L1-L4'],
    function: [
      'Bilaterálna kontrakcia - fixuje rebrá 12 počas vdychu, predĺženie (extension) trupu',
      'Jednostranná kontrakcia - bočné ohnutie trupu (ipsilaterálne)',
    ],
    innervation: 'Podrebrový nerv (T12), predné ramená miechových nervov L1-L4',
    bloodSupply: 'Bedrové, stredné krížové, iliolumbálne a subkostálne tepny',
    description:
      'Tento sval prispieva k stabilizácii a pohybu chrbtice a panvy. Obojstranná kontrakcia vedie k predĺženiu bedrovej chrbtice. Keď je sval aktivovaný len na jednej strane, trup sa ohýba týmto smerom (laterálna flexia). Okrem toho sval fixuje 12. rebro pri pohyboch hrudného koša a týmto spôsobom podporuje výdych (akcesorný výdychový sval).',
    joints: [
      'pelvis',
      'lumbarSpine',
      'ribs',
      'ribsBot',
      'core',
      'spineBot',
      'hip',
      'midBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['spine', 'l1', 'l2', 'l3', 'l4', 'ribs', 'r12'],
    movements:
      'Stabilizácia a pohyb bedrovej chrbtice, výdych, predklon do strán',
    musclesSameCategory: [
      'psoasMinor',
      'psoasMajor',
      'iliacus',
      'externalOblique',
      'internalOblique',
      'tranversusAbdominis',
      'serratusPosteriorInferior',
    ],
  },
  psoasMinor: {
    id: 'psoasMinor',
    name: 'Malý bedrovodriekový sval (Psoas minor)',
    origin: ['Telá stavcov T12 a L1'],
    insertion: ['Iliopubic eminence, pecten pubis'],
    function: ['Slabá flexia trupu'],
    innervation: 'Predný ramus miechového nervu L1',
    bloodSupply: 'Bedrové tepny',
    description:
      'Malý bedrovodriekový sval (psoas minor) je veľmi slabý sval, ktorý neprispieva k žiadnej špeciálnej funkcii, preto jeho presná funkcia nie je úplne jasná. Predpokladá sa, že pomáha pri ohýbaní trupu.',
    joints: [
      'pelvis',
      'lumbarSpine',
      'core',
      'spineBot',
      'midBody',
      'fullBody',
      'lowerBody',
    ],
    originJoints: ['spine', 't12', 'l1'],
    insertJoints: ['pelvis'],
    movements: 'Slabá flexia trupu',
    musclesSameCategory: [
      'psoasMajor',
      'iliacus',
      'sartorius',
      'quadratusLumborum',
      'externalOblique',
      'internalOblique',
      'tranversusAbdominis',
      'serratusPosteriorInferior',
    ],
  },
  psoasMajor: {
    id: 'psoasMajor',
    name: 'Veľký bedrovodriekový sval (Psoas major)',
    origin: [
      'Telá stavcov T12-L4',
      'Medzistavcové platničky medzi T12-L4',
      'Priečne výbežky stavcov L1-L5',
    ],
    insertion: ['Malý trochanter stehennej kosti ako iliopsoasová šľacha'],
    function: [
      'Bedrový kĺb: Flexia stehna/trupu, bočná rotácia stehna, bočná flexia trupu',
    ],
    innervation: 'Predné ramená miechových nervov L1-L3',
    bloodSupply: 'Bedrová vetva iliolumbálnej tepny',
    description:
      'Veľký bedrovodriekový sval (psoas major) pôsobí na flexiu a laterálnu rotáciu stehna v bedre. Taktiež ohýba trup v bedrovom kĺbe a ohýba trup do strán. Veľký bedrovodriekový sval (psoas major) je tiež posturálny sval. Počas stoja stabilizuje chrbticu tým, že udržiava normálnu bedrovú lordózu a nepriamu hrudnú kyfózu.',
    joints: [
      'thoracicSpine',
      'lumbarSpine',
      'pelvis',
      'spineBot',
      'core',
      'femur',
      'midBody',
      'fullBody',
      'lowerBody',
    ],
    originJoints: ['spine', 't12', 'l1', 'l2', 'l3', 'l4', 'l5'],
    insertJoints: ['femur'],
    movements:
      'Flexia a laterálna rotácia stehna, flexia trupu v bedre, stabilizácia bedrovej chrbtice',
    musclesSameCategory: [
      'psoasMinor',
      'iliacus',
      'sartorius',
      'quadratusLumborum',
      'externalOblique',
      'internalOblique',
      'tranversusAbdominis',
      'serratusPosteriorInferior',
    ],
  },
  iliacus: {
    id: 'iliacus',
    name: 'Bedrovostehenný sval (Iliacus)',
    origin: ['Fossa iliaca'],
    insertion: ['Malý trochanter stehennej kosti'],
    function: ['Ohýbanie (flexia) stehna/ trupu'],
    innervation: 'Stehenný nerv (L1-L3)',
    bloodSupply:
      'Iliolumbarna artéria, arteria circumflexa iliaca profunda, arteria obturatoria, femorálna artéria',
    description:
      'Bedrovostehenný sval (iliacus) pracuje v súčinnosti s veľkým bedrovodriekovým svalom (psoas major), čím vytvára pohyby v bedrovom kĺbe. Keď je jeho proximálny úpon fixovaný, sval prispieva k flexii stehna. Na druhej strane, ak je jeho distálny úpon fixovaný, sval pomáha privádzať trup dopredu proti odporu. Tieto činnosti sú nevyhnutné pre funkcie dolných končatín, ako je chôdza, beh a skoky.',
    joints: [
      'pelvis',
      'thigh',
      'core',
      'hip',
      'femur',
      'midBody',
      'fullBody',
      'lowerBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'Pohyb v bedrovom kĺbe, flexia stehna',
    musclesSameCategory: [
      'psoasMinor',
      'psoasMajor',
      'sartorius',
      'quadratusLumborum',
      'externalOblique',
      'internalOblique',
      'tranversusAbdominis',
      'serratusPosteriorInferior',
    ],
  },
  sartorius: {
    id: 'sartorius',
    name: 'Krajčírsky sval (Sartorius)',
    origin: ['Predný horný bedrový tŕň (ASIS)'],
    insertion: ['Proximálny koniec holennej kosti pod mediálnym kondylom'],
    function: [
      'Bedrový kĺb: flexia stehna, abdukcia stehna, vonkajšia rotácia stehna',
      'Kolenný kĺb: flexia nohy, vnútorná rotácia nohy',
    ],
    innervation: 'Stehenný nerv (L2-L3)',
    bloodSupply:
      'Proximálna tretina: vetvy femorálnej artérie, hlbokej femorálnej artérie, artérie kvadricepsu, laterálnej cirkumflexnej femorálnej artérie; stredná tretina: vetvy femorálnej artérie; distálna tretina: vetvy femorálnej a zostupnej genikulárnej artérie',
    description:
      'Krajčírsky sval (sartorius) prechádza cez bedrový aj kolenný kĺb a vytvára pohyby v oboch kĺboch. V bedrovom kĺbe je schopný flexie, vonkajšej rotácie a abdukcie nohy. Kontrakcia svalu sartorius môže spôsobiť aj flexiu kolenného kĺbu a vnútornú alebo mediálnu rotáciu holennej kosti voči stehennej kosti.',
    joints: [
      'pelvis',
      'tibia',
      'thigh',
      'knee',
      'hip',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['tibia'],
    movements:
      'Flexia, abdukcia a bočná rotácia bedrového kĺbu, extenzia kolena',
    musclesSameCategory: [
      'psoasMinor',
      'psoasMajor',
      'iliacus',
      'gluteusMedius',
      'gluteusMinimus',
      'pectineus',
      'gracilis',
      'adductorLongus',
      'tensorFaciaeLatae',
    ],
  },
  tensorFaciaeLatae: {
    id: 'tensorFaciaeLatae',
    name: 'Napínač širokej pokrývky (Tensor fasciae latae)',
    origin: [
      'Vonkajší okraj predného hrebeňa bedrovej kosti',
      'Predný horný bedrový tŕň (ASIS)',
    ],
    insertion: ['Bočný kondyl holennej kosti'],
    function: [
      'Bedrový kĺb: (Slabá abdukcia)',
      'Kolenný kĺb: (slabá flexia/extenzia nohy)',
      'Stabilizuje bedrové a kolenné kĺby',
    ],
    innervation: 'Horný gluteálny nerv (L4-S1)',
    bloodSupply: 'Vzostupná vetva laterálnej cirkumflexnej femorálnej artérie',
    description:
      'Napínač širokej pokrývky (tensor fasciae latae) zohráva kľúčovú úlohu pri udržiavaní stability bedrového kĺbu a stehna. Pomáha vyrovnávať tlak na stehennú kosť, keď sa stehno stretáva s panvou, tým, že udržiava napätie v iliotibiálnom trakte. Okrem toho je tento sval zodpovedný za abdukciu, flexiu a vnútornú rotáciu bedrového kĺbu. Pracuje v spojení s ostatnými svalmi bedrového kĺbu a stehna, aby zabezpečil stabilitu počas fyzickej aktivity.',
    joints: [
      'pelvis',
      'tibia',
      'hip',
      'thigh',
      'knee',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['tibia'],
    movements:
      'Stabilita bedrového kĺbu a stehna, abdukcia bedrového kĺbu, flexia a vnútorná rotácia',
    musclesSameCategory: [
      'gluteusMedius',
      'gluteusMaximus',
      'gluteusMinimus',
      'vastusLateralis',
      'rectusFemoris',
      'externalOblique',
      'internalOblique',
    ],
  },
  rectusFemoris: {
    id: 'rectusFemoris',
    name: 'Priamy sval stehna (Rectus femoris)',
    origin: [
      'Predný dolný bedrový tŕň',
      'Supraacetabulárna drážka (Supraacetabular groove)',
    ],
    insertion: ['Tibiálna tuberozita (Tibial tuberosity)'],
    function: [
      'Bedrový kĺb: Ohýbanie (flexia) stehna',
      'Kolenný kĺb: Predĺženie (extenzia) nohy',
    ],
    innervation: 'Stehenný nerv (L2-L4)',
    bloodSupply:
      'Femorálna, laterálna femorálna cirkumflexná, povrchová cirkumflexná iliakálna artéria',
    description:
      'Priamy stehenný sval (rectus femoris) je jedným zo štyroch svalov, ktoré tvoria skupinu štvorhlavých svalov stehna. Jeho hlavnou funkciou je extenzia kolena, čím sa dolná končatina posúva dopredu, ako aj flexia bedrového kĺbu, čím sa stehno približuje k bruchu. Tento sval je dôležitým svalom pri športoch, ako je futbal, cyklistika a beh, pretože sa používa pri činnostiach, ktoré si vyžadujú rýchle, výbušné pohyby nôh. Zohráva tiež dôležitú úlohu pri udržiavaní rovnováhy a stability v bedrovom a kolennom kĺbe počas činností, ako je státie, chôdza a beh.',
    joints: [
      'quadriceps',
      'pelvis',
      'knee',
      'tibia',
      'thigh',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['tibia', 'knee'],
    movements: 'Extenzia v kolene, flexia v bedre',
    musclesSameCategory: [
      'vastusIntermedius',
      'vastusMedialis',
      'vastusLateralis',
      'tensorFaciaeLatae',
      'sartorius',
      'pectineus',
      'adductorLongus',
      'adductorBrevis',
      'adductorMagnus',
    ],
  },
  vastusMedialis: {
    id: 'vastusMedialis',
    name: 'Vnútorná hlava štvorhlavého svalu (Vastus medialis)',
    origin: [
      'Intertrochanterická línia (Intertrochanteric line)',
      'Pectineálna línia stehennej kosti',
      'Linea aspera',
      'Mediálna suprakondylická línia stehennej kosti',
    ],
    insertion: ['Tibiálna tuberozita (Tibial tuberosity)', 'Jabĺčko (Patella)'],
    function: ['Kolenný kĺb: Predĺženie (extenzia) nohy'],
    innervation: 'Stehenný nerv (L2-L4}',
    bloodSupply: 'Femorálna, hlboká femorálna, zostupná genikulárna artéria',
    description:
      'Spolu s ostatnými svalmi, ktoré tvoria štvorhlavý sval stehna, sa podieľa na extenzii kolenného kĺbu. Keď sa sval stiahne, ťahá za jabĺčko (patellu), ktorá následne narovnáva kolenný kĺb a predlžuje nohu v kolene. Okrem svojej primárnej funkcie zohráva vnútorná hlava štvorhlavého svalu (vastus medialis) aj úlohu pri stabilizácii kolenného kĺbu počas pohybu. Keď je koleno narovnané, vastus medialis pracuje na tom, aby udržal jabĺčko správne zarovnané a vycentrované vo femorálnej drážke. To pomáha predchádzať vykĺbeniu alebo posunu jabĺčka z miesta.',
    joints: [
      'quadriceps',
      'knee',
      'thigh',
      'pelvis',
      'femur',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur'],
    insertJoints: ['tibia', 'knee'],
    movements: 'Predĺženie a stabilizácia kolena',
    musclesSameCategory: [
      'vastusIntermedius',
      'vastusLateralis',
      'rectusFemoris',
      'sartorius',
      'pectineus',
      'adductorLongus',
      'adductorBrevis',
      'adductorMagnus',
    ],
  },
  vastusLateralis: {
    id: 'vastusLateralis',
    showPremium: true,
    name: 'Vonkajšia hlava štvorhlavého svalu (Vastus lateralis)',
    origin: [
      'Intertrochanterická línia (Intertrochanteric line)',
      'Veľký trochanter (Greater trochanter)',
      'Gluteálna tuberozita (Gluteal tuberosity)',
      'Linea aspera stehennej kosti',
    ],
    insertion: ['Tibiálna tuberozita (Tibial tuberosity)', 'Jabĺčko (Patella)'],
    function: ['Kolenný kĺb: Predĺženie (extenzia) kolena'],
    innervation: 'Stehenný nerv (L2-L4)',
    bloodSupply:
      'Laterálna cirkumflexná femorálna a hlboká femorálna artéria; artéria ku kvadricepsu',
    description:
      'Vonkajšia hlava štvorhlavého svalu (vastus lateralis), nazývaný aj vastus externus, je najväčšou a najsilnejšou časťou štvorhlavého svalu stehna (quadriceps femoris). Spolu s ostatnými svalmi skupiny štvorhlavého svalu stehna slúži na predĺženie kolenného kĺbu, čím sa dolná končatina posúva dopredu. Vzniká zo série plochých, širokých šliach pripojených k stehennej kosti a upína sa na vonkajší okraj jabĺčka (patella). Nakoniec sa spája s ostatnými svalmi, ktoré tvoria štvorhlavý sval stehna, do šľachy štvorhlavého svalu, ktorá prechádza cez koleno a pripája sa k holennej kosti.',
    joints: [
      'quadriceps',
      'thigh',
      'pelvis',
      'knee',
      'femur',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur'],
    insertJoints: ['tibia', 'knee'],
    movements: 'Predĺženie kolena',
    musclesSameCategory: [
      'vastusIntermedius',
      'vastusMedialis',
      'rectusFemoris',
      'sartorius',
      'pectineus',
      'adductorLongus',
      'adductorBrevis',
      'adductorMagnus',
    ],
  },
  vastusIntermedius: {
    id: 'vastusIntermedius',
    name: 'Prostredná hlava štvorhlavého svalu (Vastus intermedius)',
    origin: ['Predný povrch stehennej kosti'],
    insertion: ['Tibiálna tuberozita (Tibial tuberosity)'],
    function: ['Kolenný kĺb: Predĺženie (extenzia) kolena'],
    innervation: 'Stehenný nerv (L2-L4)',
    bloodSupply:
      'Artéria do štvorhlavého stehenného svalu, hlboké stehenné tepny',
    description:
      'V horných dvoch tretinách tela stehnovej kosti vyrastá prostredná hlava štvorhlavého svalu (vastus intermedius) z prednej a bočnej plochy tela stehnovej kosti, sedí pod priamym stehenným svalom a z dolnej časti bočnej medzisvalovej priehradky. Jeho vlákna sa končia povrchovou aponeurózou, ktorá tvorí hlbokú časť šľachy štvorhlavého svalu stehenného.',
    joints: [
      'quadriceps',
      'thigh',
      'pelvis',
      'knee',
      'femur',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur'],
    insertJoints: ['tibia', 'knee'],
    movements: 'Predĺženie kolena',
    musclesSameCategory: [
      'vastusLateralis',
      'vastusMedialis',
      'rectusFemoris',
      'sartorius',
      'pectineus',
      'adductorLongus',
      'adductorBrevis',
      'adductorMagnus',
    ],
  },
  gracilis: {
    id: 'gracilis',
    name: 'Štíhly sval stehna (Gracilis)',
    origin: [
      'Predná časť pubisu',
      'Spodný pubický ramus',
      'Rameno sedacej kosti (Ischial ramus)',
    ],
    insertion: ['Mediálny povrch proximálnej holennej kosti'],
    function: [
      'Bedrový kĺb: flexia stehna, addukcia stehna',
      'Kolenný kĺb: flexia nohy, vnútorná rotácia nohy',
    ],
    innervation: 'Obturátorový nerv (L2-L3) (Obturator nerve)',
    bloodSupply: 'Hlboká femorálna artéria (cez artériu k adduktorom)',
    description:
      'Silná flexia nohy a mediálna (vnútorná) rotácia okolo kolenného kĺbu, keď je koleno v poloflekčnej polohe. Slabá flexia a addukcia stehna okolo bedrového kĺbu, kde však pomáhajú iné, silnejšie adduktory stehna. Najdôležitejšou funkciou štíhleho svalu stehna (gracilis) je pomáhať šľachovým svalom pri flexii kolena, napríklad počas počiatočnej švihovej fázy pri chôdzi.',
    joints: ['pelvis', 'knee', 'tibia', 'thigh', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['tibia'],
    movements: 'Flexia nohy, mediálna rotácia nohy, flexia a addukcia stehna',
    musclesSameCategory: [
      'adductorBrevis',
      'adductorLongus',
      'pectineus',
      'sartorius',
    ],
  },
  adductorLongus: {
    id: 'adductorLongus',
    name: 'Dlhý pritahovač (Adductor longus)',
    origin: [
      'Telo pubisu pod pubickým hrebeňom',
      'Laterálne od pubickej symfýzy',
    ],
    insertion: ['Stredná tretina linea aspera stehennej kosti'],
    function: [
      'Bedrový kĺb: flexia stehna, addukcia stehna, vonkajšia rotácia stehna',
      'Stabilizácia panvy',
    ],
    innervation: 'Obturátorový nerv (L2-L4)',
    bloodSupply: 'Hlboká femorálna artéria, obturátorová artéria',
    description:
      'Hlavnou činnosťou skupiny adduktorových svalov je addukcia stehna v bedrovom kĺbe. Dlhý pritahovač (adductor longus) sa podieľa aj na vonkajšej/bočnej rotácii a flexii stehna. Adduktory pomáhajú stabilizovať postoj pri státí a majú tiež dôležitú úlohu pri vyrovnávaní tela na dolnej končatine počas chôdze.',
    joints: ['pelvis', 'thigh', 'femur', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'Addukcia stehna, slabá vonkajšia rotácia a flexia stehna',
    musclesSameCategory: [
      'adductorBrevis',
      'adductorMagnus',
      'gracilis',
      'psoasMajor',
      'pectineus',
      'rectusFemoris',
      'vastusIntermedius',
      'vastusMedialis',
      'vastusLateralis',
      'rectusAbdominis',
      'tranversusAbdominis',
    ],
  },
  adductorBrevis: {
    id: 'adductorBrevis',
    showPremium: true,
    name: 'Krátky priťahovač (Adductor brevis)',
    origin: ['Predná časť pubisu', 'Spodný pubický ramus'],
    insertion: ['Linea aspera stehennej kosti (mediálny okraj)'],
    function: [
      'Bedrový kĺb: flexia stehna, addukcia stehna, vonkajšia rotácia stehna',
      'Stabilizácia panvy',
    ],
    innervation: 'Obturátorový nerv (L2-L4)',
    bloodSupply: 'Artéria profunda femoris',
    description:
      'Hlavnou funkciou krátheho priťahovaču (adductor brevis) je addukcia stehna. Táto činnosť je aktívna najmä vtedy, keď je stehno vo flexii a počas cyklu chôdze. Adductor brevis má tiež úlohu pri vonkajšej rotácii a flexii bedrového kĺbu.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'Addukcia a flexia stehna, stabilizácia panvy',
    musclesSameCategory: [
      'adductorLongus',
      'adductorMagnus',
      'gracilis',
      'psoasMajor',
      'pectineus',
      'rectusFemoris',
      'vastusIntermedius',
      'vastusMedialis',
      'vastusLateralis',
      'rectusAbdominis',
      'tranversusAbdominis',
    ],
  },
  adductorMagnus: {
    id: 'adductorMagnus',
    name: 'Veľký priťahovač (Adductor magnus)',
    origin: [
      'Adduktorová časť: ramus inferior ossis pubis, ramus ossis ischii',
      'Ischiocondylar časť: tuber ischiadicum',
    ],
    insertion: [
      'Adduktorová časť: tuberositas glutea femoris, linea aspera femoris (mediálny pysk), linea supracondylaris medialis femoris',
      'Ischiocondylar časť: tuberculum adductorium femoris',
    ],
    function: [
      'Adduktorová časť: flexia stehna, addukcia stehna, vonkajšia rotácia stehna',
      'Šľachová časť: Vnútorná rotácia stehna, extenzia stehna',
    ],
    innervation:
      'Adduktorová časť: obturátorový nerv (L2-L4); Ischiocondylar časť: Divisio tibialis ischiadic nerv (L4)',
    bloodSupply:
      'Arteria profunda femoris; Arteria femoralis, arteria poplitea, arteriae genu',
    description:
      'Veľký priťahovač (adductor magnus) je silný adduktor stehna, ktorý pracuje koordinovane so veľkým (adductor longus) a krátkym (brevis) priťahovačom a hrebeňovým svalom (pectineus). Okrem addukcie stehna slúžia všetky tieto svaly ako dôležité stabilizátory panvy na dolnej končatine počas chôdze. Okrem addukcie stehna sa adduktorová časť podieľa aj na flexii stehna. Podkolenná časť tiež addukuje stehno, ale spolupracuje aj so skupinou podkolenných svalov a napomáha extenzii stehna. Vlákna adduktorovej časti svalu, ktoré sa upínajú na linea aspera, môžu vďaka svojmu šikmému úponu pôsobiť aj ako bočné rotátory. Predpokladá sa tiež, že spolu s adductor longus pôsobí tento sval ako mediálny rotátor stehna.',
    joints: [
      'pelvis',
      'femur',
      'thigh',
      'knee',
      'hip',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements:
      'Flexion, adduction, external rotation, extension and internal rotation of the thigh',
    musclesSameCategory: [
      'adductorLongus',
      'adductorBrevis',
      'gracilis',
      'psoasMajor',
      'pectineus',
      'rectusFemoris',
      'vastusIntermedius',
      'vastusMedialis',
      'vastusLateralis',
      'rectusAbdominis',
      'tranversusAbdominis',
    ],
  },
  pectineus: {
    id: 'pectineus',
    name: 'Hrebeňový sval (Pectineus)',
    origin: ['Horný pubický ramus'],
    insertion: [
      'Pectineálna línia stehennej kosti',
      'Linea aspera stehennej kosti',
    ],
    function: [
      'Bedrový kĺb: flexia stehna, addukcia stehna, vonkajšia rotácia stehna, vnútorná rotácia stehna',
      'Stabilizácia panvy',
    ],
    innervation: 'Stehenný nerv (L2, L3)',
    bloodSupply:
      'Mediálna femorálna cirkumflexná artéria, obturátorová artéria',
    description:
      'Vzhľadom na priebeh svojich vlákien hrebeňový sval (pectineus) pri kontrakcii ohýba aj addukuje stehno v bedrovom kĺbe. Keď je dolná končatina v anatomickej polohe, kontrakcia svalu najprv spôsobí flexiu v bedrovom kĺbe. Táto flexia môže viesť až k tomu, že stehno zviera s bedrovým kĺbom uhol 45 stupňov. V tomto bode je uhlovosť vlákien taká, že kontrahované svalové vlákna teraz ťahajú stehno smerom k stredovej čiare, čím vzniká addukcia stehna.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements:
      'Flexia, addukcia, vonkajšia a vnútorná rotácia stehna, stabilizácia panvy',
    musclesSameCategory: [
      'adductorLongus',
      'adductorBrevis',
      'adductorMagnu',
      's gracilis',
      'psoasMajor',
      'rectusFemoris',
      'vastusIntermedius',
      'vastusMedialis',
      'vastusLateralis',
      'rectusAbdominis',
      'tranversusAbdominis',
    ],
  },
  gluteusMaximus: {
    id: 'gluteusMaximus',
    name: 'Veľký sedací sval (Gluteus Maximus)',
    origin: [
      'Lateroposteriórny povrch krížovej kosti a kostrče',
      'Zadná časť lopaty bedrovej kosti',
      'Thorakolumbálna fascia',
      'Sakrotuberózny väz',
    ],
    insertion: ['Iliotibiálny trakt', 'Sedacia drsnatina stehennej kosti'],
    function: [
      'Bedrový kĺb: extenzia stehna, vonkajšia rotácia stehna, abdukcia stehna (horná časť), addukcia stehna (dolná časť)',
    ],
    innervation: 'Dolný gluteálny nerv (L5, S1, S2)',
    bloodSupply: 'Dolné gluteálne a horné gluteálne tepny',
    description:
      'Veľký sedací sval vykonáva štyri činnosti na bedrový kĺb: extenziu, vonkajšiu rotáciu, abdukciu a addukciu stehna. Keď je jeho proximálny úpon fixovaný, gluteus maximus pôsobí ako hlavný extenzor bedrového kĺbu a ťahá driek stehennej kosti smerom dozadu. Vonkajšia rotácia stehna sa deje súčasne s extenziou a pomáha pri zdvíhaní mediálnej pozdĺžnej klenby nohy. Naopak, keď je jeho distálny úpon fixovaný, gluteus maximus ťahá panvu dozadu. Keďže sa to zvyčajne deje v súčinnosti s rovnakou činnosťou hamstringových svalov, táto činnosť gluteus maximus pomáha dostať trup z ohnutej do vzpriamenej polohy. Okrem toho tieto svaly pomáhajú udržiavať vzpriamené držanie tela tým, že vyrovnávajú panvu na hlavách stehenných kostí. Horná časť svalu sa podieľa na abdukcii stehna, zatiaľ čo dolná časť sa podieľa na jeho addukcii. Okrem toho druhá časť napína fascia latae a stabilizuje stehennú kosť z laterálnej strany kolenného kĺbu.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis', 'spine', 'sacrum', 'coccyx'],
    insertJoints: ['femur'],
    movements: 'Extenzia, vonkajšia rotácia, abdukcia a addukcia stehna',
    musclesSameCategory: [
      'gluteusMedius',
      'gluteusMinimus',
      'piriformis',
      'tensorFasciaeLatae',
      'bicepsFemoris',
      'semimembranosus',
      'semitendonosus',
      'quadratusFemoris',
    ],
  },
  gluteusMedius: {
    id: 'gluteusMedius',
    name: 'Stredný sedací sval (Gluteus Medius)',
    origin: [
      'Vonkajšia plocha lopaty bedrovej kosti (medzi prednou a zadnou gluteálnou líniou)',
    ],
    insertion: [
      'Laterálny aspektveľkého chochola (greater trochanter) stehennej kosti',
    ],
    function: [
      'Bedrový kĺb: Abdukcia stehna, vnútorná rotácia stehna',
      'Stabilizácia panvy',
    ],
    innervation: 'Horný gluteálny nerv (L4-S1)',
    bloodSupply:
      'Hlboká vetva hornej gluteálnej tepny, trochanterická anastomóza',
    description:
      'Stredný sedací sval (gluteus medius) hrá kľúčovú úlohu pri stabilite, rovnováhe a držaní tela a je dôležitý pri mnohých každodenných činnostiach a pohyboch, ako je chôdza, beh a stúpanie po schodoch. Keď je proximálny úpon stredného sedacieho svalu (gluteus medius) fixovaný, sval sa môže kontrahovať ako celok alebo sa môže kontrahovať len svojimi prednými vláknami. V prvom prípade os pohybu prechádza cez bedrový kĺb a sval ťahá veľký trochanter smerom hore a abdukuje stehno. V druhom prípade, keď sa os pohybu nakláňa dopredu, sval spôsobuje vnútornú rotáciu stehna.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'Abdukcia a vnútorná rotácia stehna, stabilizácia panvy',
    musclesSameCategory: [
      'gluteusMinimus',
      'gluteusMaximus',
      'piriformis',
      'tensorFasciaeLatae',
      'sartorius',
      'pectineus',
      'psoasMajor',
      'psoasMinor',
      'quadratusFemoris',
    ],
  },
  gluteusMinimus: {
    id: 'gluteusMinimus',
    name: 'Malý sedací sval (Gluteus Minimus)',
    origin: ['Vonkajšia plocha lopaty bedrovej kosti'],
    insertion: [
      'Predná strana veľkého chochola (greater trochanter) stehennej kosti',
    ],
    function: [
      'Bedrový kĺb: Abdukcia stehna, vnútorná rotácia stehna',
      'Stabilizácia panvy',
    ],
    innervation: 'Horný gluteálny nerv (L4-S1)',
    bloodSupply:
      'Hlboká vetva hornej gluteálnej artérie; trochanterická anastomóza; mediálna cirkumflexná femorálna artéria; dolné gluteálne artérie',
    description:
      'Funkcie malého sedacieho svalu (gluteus minimus) úzko súvisia s funkciami stredného sedacieho svalu (gluteus medius), pretože tieto dva svaly pôsobia v súčinnosti na bedrový kĺb. Keď sú ich panvové úpony fixované, oba svaly ťahajú veľký trochanter smerom hore a abdukujú stehno. Ak sa však kontrahujú len ich predné časti, os pohybu sa nakláňa smerom dopredu a vytvárajú vnútornú rotáciu stehna.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'Abdukcia a vnútorná rotácia stehna, stabilizácia panvy',
    musclesSameCategory: [
      'gluteusMedius',
      'gluteusMaximus',
      'piriformis',
      'tensorFasciaeLatae',
      'sartorius',
      'psoasMajor',
      'psoasMinor',
      'quadratusFemoris',
    ],
  },
  piriformis: {
    id: 'piriformis',
    name: 'Hruškovitý sval (Piriformis)',
    origin: [
      'Predná plocha krížovej kosti (medzi S2 a S4)',
      'Gluteálny povrch ilia',
    ],
    insertion: ['Väčší trochanter stehennej kosti'],
    function: [
      'Bedrový kĺb: Vonkajšia rotácia stehna, abdukcia stehna',
      'Stabilizuje hlavicu stehennej kosti v acetabule',
    ],
    innervation: 'Nerv do piriformis (S1-S2)',
    bloodSupply:
      'Horná gluteálna artéria, dolná gluteálna artéria, gemelárne vetvy vnútornej pudendálnej tepny',
    description:
      'V kombinácii s ostatnými zadnými svalmi bedrového kĺbu stabilizuje piriformis bedrový kĺb tým, že stabilizuje hlavicu stehennej kosti v acetabule bedrovej kosti. Okrem abdukcie stehna v ohnutej polohe prispieva aj k vonkajšej rotácii bedrového kĺbu.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis', 'spine', 'sacrum'],
    insertJoints: ['femur'],
    movements: 'Stabilizácia a externá rotácia bedrového kĺbu',
    musclesSameCategory: [
      'gluteusMedius',
      'gluteusMinimus',
      'gluteusMaximus',
      'tensorFasciaeLatae',
      'sartorius',
      'psoasMajor',
      'psoasMinor',
      'quadratusFemoris',
    ],
  },
  quadratusFemoris: {
    id: 'quadratusFemoris',
    name: 'Štvorcový sval stehna (Quadratus Femoris)',
    origin: ['Ischiálna tuberozita'],
    insertion: ['Intertrochanterický hrebeň stehennej kosti'],
    function: [
      'Bedrový kĺb: vonkajšia rotácia stehna',
      'Stabilizuje hlavicu stehennej kosti v panve',
    ],
    innervation: 'Nerv do štvorhranného stehenného svalu (L4-S1)',
    bloodSupply: 'Dolná gluteálna artéria',
    description:
      'Hlavnou činnosťou štvorcového svalu stehna (quadratus femoris) je vonkajšia (externá/laterálna) rotácia stehna. Hoci ide o malý sval, je to pomerne výkonný rotátor bedrového kĺbu, ktorý pôsobí synergicky s ostatnými vonkajšími rotátormi: veľký sedací sval (gluteus maximus), hruškovitý sval (piriformis), dvojité svaly (superior gemellus a inferior gemellus) a sval zapchávač (obturator internus). Tieto svaly spoločne vytvárajú silnú vonkajšiu rotáciu stehna, ktorá je obmedzená antagonistickými svalmi a iliofemorálnym väzom. štvorcový sval stehna (quadratus femoris) je nezvyčajný v tom, že môže pomáhať pri vytváraní abdukčných aj addukčných pohybov nohy. Keď je noha v anatomickej polohe, štvorcový sval stehna (quadratus femoris), hrebeňový sval (pectineus) a spodné vlákna velkého sedacieho svalu (gluteus maximus) pomáhajú addukčným svalom stehna, dlhý priťahovač (adductor longus), veľký (magnus) a krátky (brevis) priťahovač, addukovať stehno v bedrovom kĺbe. Naopak, pri flexii v bedrovom kĺbe pôsobí štvorcový sval (quadratus femoris) spolu s hruškovitým svalom (piriformis), dvojitým svalom (superior a inferior gemelli),  zapchávačom (internus a externus obturator) na abdukciu stehna.  Vzhľadom na svoje špecifické umiestnenie vo vnútri bedrového kĺbu je štvorcový sval stehna (quadratus femoris ) jedným z najdôležitejších stabilizátorov tohto kĺbu. Udržuje hlavicu stehennej kosti na svojom mieste, a tým zabraňuje zraneniam pri mnohých činnostiach.',
    joints: ['pelvis', 'femur', 'thigh', 'hip', 'lowerBody', 'fullBody'],
    originJoints: ['pelvis'],
    insertJoints: ['femur'],
    movements: 'External rotation of hip, stabilizes head of femur',
    musclesSameCategory: [
      'piriformis',
      'gluteusMedius',
      'gluteusMinimus',
      'gluteusMaximus',
      'iliacus',
      'psoasMajor',
      'psoasMinor',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  bicepsFemoris: {
    id: 'bicepsFemoris',
    name: 'Dvojhlavý sval stehna (Biceps Femoris)',
    origin: [
      'Dlhá hlava: (inferomediálny odtlačok) sedacieho hrboľa, sakrotuberózny väz',
      'Krátka hlava: linea aspera stehennej kosti (laterálny okraj), laterálna suprakondylická línia stehennej kosti',
    ],
    insertion: ['(Laterálna strana) hlavy ihlicovej kosti'],
    function: [
      'Bedrový kĺb: extenzia stehna, vonkajšia rotácia stehna, abdukcia stehna (horná časť), addukcia stehna (dolná časť)',
      'Kolenný kĺb: flexia nohy, vonkajšia rotácia nohy',
      'Stabilizácia panvy',
    ],
    innervation:
      'Dlhá hlava: holenná časť sedacieho nervu (L5-S2); Krátka hlava: spoločná fibulárna časť sedacieho nervu (L5-S2)',
    bloodSupply:
      'Dolná gluteálna artéria, perforujúce artérie, popliteálna artéria',
    description:
      'Vo všeobecnosti dvojhlavý stehenný sval pôsobí na kolenný aj bedrový kĺb. Hoci krátka hlava tohto svalu pôsobí vzhľadom na svoje úpony len na kolenný kĺb, zatiaľ čo dlhá hlava pôsobí na oba. Keď pôsobí na bedrový kĺb, dvojhlavý sval stehna (biceps femoris) vytvára pohyb extenzie bedrového kĺbu. Táto činnosť je najsilnejšia, keď je trup ohnutý dopredu a má sa dostať do vzpriamenej polohy. dvojhlavý stehenný sval (biceps femoris) sa niekedy opisuje aj ako pomocník pri vonkajšej rotácii (keď je bedrový kĺb v natiahnutej polohe). Pri pôsobení v kolennom kĺbe je najvýraznejšou činnosťou tohto svalu ohýbanie nohy. K tomu dochádza, keď je dolná končatina v anatomickej polohe. Naopak, keď je koleno semiflexnuté, dvojhlavý sval (biceps femoris) pôsobí tak, že vytvára vonkajšiu rotáciu nohy v kolene. Spolu s ostatnými hamstringovými svalmi stabilizuje biceps femoris panvu, najmä keď dochádza k prednej flexii trupu.',
    joints: [
      'pelvis',
      'femur',
      'thigh',
      'fibula',
      'hamstrings',
      'knee',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur', 'pelvis'],
    insertJoints: ['fibula'],
    movements:
      'Extenzia a vonkajšia rotácia stehna, flexia a vonkajšia rotácia nohy, stabilizácia panvy',
    musclesSameCategory: [
      'semimembranosus',
      'semitendonosus',
      'adductorMagnus',
      'adductorLongus',
      'sartorius',
      'gastrocnemius',
      'popliterius',
      'plantaris',
    ],
  },
  semitendinosus: {
    id: 'semitendinosus',
    name: 'Pološlachovitý sval (Semitendonosus)',
    origin: ['(Posteromediálny odtlačok) Ischial tuberosity'],
    insertion: [
      'Proximálny koniec holennej kosti pod mediálnym kondylom (cez pes anserinus)',
    ],
    function: [
      'Bedrový kĺb: Stehno: extenzia stehna, vnútorná rotácia stehna, stabilizuje panvu',
      'Kolenný kĺb: flexia nohy, vnútorná rotácia nohy',
    ],
    innervation: 'Tibiálna časť sedacieho nervu (L5-S2)',
    bloodSupply:
      'Prvá perforujúca vetva hlbokej femorálnej artérie, mediálna femorálna cirkumflexná artéria, dolná gluteálna artéria a dolná mediálna genikulárna artéria',
    description:
      'Pološlachovitý sval (semitendinosus) sa pripája medzi bedrový kĺb a nohu a vykonáva pohyby v bedrovom aj kolennom kĺbe, pričom vytvára extenziu stehna, vnútornú rotáciu, stabilizáciu panvy (bedrový kĺb), flexiu nohy a vnútornú rotáciu (kolenný kĺb). Keď je jeho holenné uchytenie pevné, má dve funkcie. Keď je telo v anatomickej polohe, vnútorne rotuje stehno. Ak je trup ohnutý dopredu, tento sval stehno predlžuje (extenzia). V prípade, že je sedací úpon fixovaný, pološlachovitý sval (semitendinosus) má dve funkcie na kolenný kĺb. Keď je dolná končatina v anatomickej polohe, flexuje nohu. Ak je koleno poloohnuté, semitendinosus vnútorne otáča nohu, pričom pôsobí spolu so všetkými podkolennými svalmi. Okrem toho, že semitendinosus stabilizuje panvu spolu so všetkými krátkymi bedrovými svalmi, ktoré sa upínajú medzi panvou a proximálnym stranou stehennej kosti, pôsobí aj na stabilizáciu kolena.',
    joints: [
      'pelvis',
      'femur',
      'thigh',
      'tibia',
      'hamstrings',
      'knee',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['tibia'],
    movements:
      'Extenzia a vnútorná rotácia stehna, flexia a vnútorná rotácia nohy, stabilizácia panvy',
    musclesSameCategory: [
      'semimembranosus',
      'bicepsFemoris',
      'adductorMagnus',
      'adductorLongus',
      'sartorius',
      'gastrocnemius',
      'popliterius',
      'plantaris',
    ],
  },
  semimembranosus: {
    id: 'semimembranosus',
    name: 'Poloblanitý sval (Semimembranosus)',
    origin: ['Superolaterálny odtlačok sedacieho hrboľa'],
    insertion: ['Mediálny kondyl holennej kosti'],
    function: [
      'Bedrový kĺb: extenzia a vnútorná rotácia stehna',
      'Kolenný kĺb: flexia a vnútorná rotácia nohy',
      'Stabilizácia panvy',
    ],
    innervation: 'Tibiálna časť sedacieho nervu (L5 - S2)',
    bloodSupply: 'Perforujúce vetvy femorálnych a popliteálnych tepien',
    description:
      'Poloblanitý sval (semimembranosus) sa tiahne naprieč v bedrovým aj kolenným kĺbom, a preto je zodpovedný za viaceré pohyby v tých kĺboch. Semimembranosus však pri plnení svojej funkcie spolupracuje s ostatnými hamstringovými svalmi. Keď sú nohy pevne položené na zemi, semimembranosus spôsobuje extenziu v bedrovom kĺbe, ktorá ťahá hornú časť trupu, aby sa dostal do vzpriamenej polohy. Poloblanitý sval (semimembranosus) spolu ss poloblanitým svalom (semitendinosus) môžu tiež spôsobiť vnútornú rotáciu stehna, keď je bedro úplne vystreté. Keď sú nohy nad zemou, spôsobuje flexiu kolena a vnútornú rotáciu nohy na stehne. Poloblanitý sval (semimembranosus) a ostatné zadné svaly stehna sú neaktívne vždy, keď človek stojí symetricky. Akonáhle sa však človek nakloní príliš dopredu, semimembranosus sa aktivuje a pôsobí proti pohybu dopredu; tým stabilizuje bedro.',
    joints: [
      'pelvis',
      'tibia',
      'thigh',
      'hamstrings',
      'knee',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['pelvis'],
    insertJoints: ['tibia'],
    movements:
      'Extenzia a vnútorná rotácia stehna, flexia a vnútorná rotácia nohy, stabilizácia panvy',
    musclesSameCategory: [
      'semitendonosus',
      'bicepsFemoris',
      'adductorMagnus',
      'adductorLongus',
      'sartorius',
      'gastrocnemius',
      'popliterius',
      'plantaris',
    ],
  },
  gastrocnemius: {
    id: 'gastrocnemius',
    name: 'Dvojhlavý sval lýtka (Gastrocnemius)',
    origin: [
      'Bočná hlava: Posterolaterálny aspekt laterálneho kondylu stehennej kosti',
      'Mediálna hlava: Zadný povrch mediálneho femorálneho kondylu, popliteálny povrch femorálneho drieku',
    ],
    insertion: ['Zadná plocha päty cez pätovú šľachu'],
    function: [
      'Talokrurálny kĺb (členok): Plantárna flexia chodidla',
      'Kolenný kĺb: Ohyb (flexia) nohy',
    ],
    innervation: 'Tibiálny nerv (S1, S2)',
    bloodSupply: 'Laterálne a mediálne surálne tepny',
    description:
      'Dvojhlavý sval lýtka (gastrocnemius) je silný plantárny ohýbač nohy v talokrurálnom kĺbe (členku). Zároveň ohýba nohu v kolene. Činnosť gastrocnemiusu sa zvyčajne považuje spolu so šikmým svalom lýtka (soleusom) za skupinu triceps surae. Sú to hlavné plantárne ohýbače chodidla. Svaly sú zvyčajne veľké a silné. Dvojhlavý sval lýtka (gastrocnemius) zabezpečuje silu pohonu pri chôdzi, behu a skokoch.',
    joints: [
      'femur',
      'calcaneus',
      'foot',
      'knee',
      'lowLeg',
      'ankle',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur'],
    insertJoints: ['calcaneus', 'foot'],
    movements: 'Plantárna flexia chodidla, flexia nohy',
    musclesSameCategory: [
      'soleus',
      'popliterius',
      'plantaris',
      'fibularisLongus',
      'fibularisPosterior',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  soleus: {
    id: 'soleus',
    name: 'Šikmý lýtkový sval (Soleus)',
    origin: [
      'Soleal line',
      'Mediálny a zadný okraj holennej kosti',
      'Hlava ihlicovej kosti',
    ],
    insertion: ['Zadná plocha päty'],
    function: ['Talokrurálny kĺb (členok): plantárna flexia chodidla'],
    innervation: 'Tibiálny nerv (S1, S2)',
    bloodSupply: 'Popliteálna artéria a peroneálna artéria',
    description:
      'Funkcia svalu šikmého svalu lýtka (soleus) úzko súvisí s funkciou dvojhlavého svalu lýtka (gastrocnemius). Spoločne tvoria hlavný plantárny flexor - ich kontrakcia vedie k plantárnej flexii horného členkového kĺbu, čo umožňuje zdvihnutie päty proti gravitácii pri chôdzi alebo skokoch. Keďže ťažisko tela je pred členkovým kĺbom, telo má prirodzenú tendenciu nakláňať sa dopredu. Proti tomu pôsobí nepretržitý stav plantárnej flexie, ktorý pri státí vytvára práve soleus. Z tohto dôvodu soleus pozostáva prevažne z pomalých vlákien typu 1, odolných voči únave. Dvojhlavý sval lýtka (gastrocnemius) sa viac podieľa na pohyblivosti, kde sa mierne podieľa na flexii kolena, keďže prechádza cez viaceré kĺby. Keď je koleno ohnuté, vlastnosti dvojitého svalu lýtka (gastrocnemiusu) pri plantárnej flexii sú veľmi obmedzené, takže šikmý lýtkový sval (soleus) je hlavným svalom zodpovedným za plantárnu flexiu.',
    joints: [
      'tibia',
      'fibula',
      'calcaneus',
      'foot',
      'lowLeg',
      'ankle',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['tibia', 'fibula'],
    insertJoints: ['calcaneus', 'foot'],
    movements: 'Plantarflexia chodidla',
    musclesSameCategory: [
      'gastrocnemius',
      'popliterius',
      'plantaris',
      'fibularisLongus',
      'fibularisPosterior',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  popliterius: {
    id: 'popliterius',
    name: 'Zákolenný sval (Popliterius)',
    origin: [
      'Bočný kondyl stehennej kosti',
      'Zadný roh laterálneho menisku kolenného kĺbu',
    ],
    insertion: ['Zadný povrch proximálnej holennej kosti'],
    function: ['Odblokovanie kolenného kĺbu; stabilizácia kolenného kĺbu'],
    innervation: 'Tibiálny nerv (L4-S1)',
    bloodSupply:
      'Dolná mediálna a laterálna genitálna artéria (popliteálna artéria), zadná tibiálna rekurentná artéria, zadná tibiálna artéria, živná artéria holennej kosti',
    description:
      'Napriek jeho anatomickému členeniu je úloha podkolenného svalu ako ohýbača kolena pomerne bezvýznamná. Podkolenný sval však zohráva dôležitú úlohu pri iniciácii flexie plne vystretého ("uzamknutého") kolena. Preto sa podkolenný sval označuje ako "kľúč na odomknutie kolena", vďaka čomu je nevyhnutný pre procesy chôdze, vstávania a sedenia. Zákolenný sval (popliteus) je tiež hlavným stabilizátorom dorzálnej oblasti kolena prostredníctvom svojich spojení so zadným kĺbovým puzdrom a popliteofibulárnym väzom. Okrem toho tento sval (popliteus) ťahá laterálny meniskus dorzálne počas flexie kolena a laterálnej rotácie stehna, čím zabraňuje jeho zachyteniu.',
    joints: ['knee', 'tibia', 'lowLeg', 'lowerBody', 'fullBody'],
    originJoints: ['knee', 'femur'],
    insertJoints: ['tibia'],
    movements: 'Odblokovanie kolena, stabilizácia kolena',
    musclesSameCategory: [
      'plantaris',
      'soleus',
      'gastrocnemius',
      'fibularisLongus',
      'fibularisPosterior',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  plantaris: {
    id: 'plantaris',
    name: 'Plantaris',
    origin: [
      'Laterálna suprakondylická línia stehennej kosti',
      'Šikmý podkolenný väz kolena',
    ],
    insertion: ['Zadná plocha päty'],
    function: [
      'Talokrurálny kĺb (členok): plantárna flexia chodidla',
      'Kolenný kĺb: ohyb (flexia) kolena',
    ],
    innervation: 'Tibiálny nerv (S1, S2)',
    bloodSupply:
      'Povrchovo: laterálna surálna a popliteálna artéria; hlboko: horná laterálna genikulárna artéria',
    description:
      'Podieľa sa na plantárnej flexii nohy v členkovom kĺbe a na flexii kolena v kolennom kĺbe. V skutočnosti však pri vykonávaní týchto dvoch pohybov slabo pomáha dvojhlavému svalu lýtka (gastrocnemius) a šikmému svalu lýtka (soleus).',
    joints: [
      'knee',
      'femur',
      'calcaneus',
      'foot',
      'lowLeg',
      'ankle',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['femur', 'knee'],
    insertJoints: ['calcaneus', 'foot'],
    movements: 'Plantárna flexia chodidla, flexia kolena',
    musclesSameCategory: [
      'popliterius',
      'soleus',
      'gastrocnemius',
      'fibularisLongus',
      'fibularisPosterior',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  fibularisLongus: {
    id: 'fibularisLongus',
    name: 'Dlhý lýtkový (ihlicový) sval (Fibularis Longus)',
    origin: [
      'Hlava ihlicovej kosti (fibula)',
      'Proximálne 2/3 laterálnej plochy ihlicovej kosti',
    ],
    insertion: ['Vnútorná klinová kosť', '1. metatarzus'],
    function: [
      'Talocrural joint (členok): plantárna flexia chodidla',
      'Subtalárny kĺb: Everzia chodidla',
      'Podporuje pozdĺžnu a priečnu klenbu chodidla',
    ],
    innervation: 'Povrchový fibulárny nerv (L5, S1)',
    bloodSupply: 'Fibulárna tepna',
    description:
      'Dlhý lýtkový sval spolu s krátkym lýtkovým svalom a zadným holenným svalom predlžujú (extenzia) chodidlo smerom nadol od tela v členku (plantárna flexia). Dlhý lýtkový sval (fibularis longus) tiež odkláňa chodidlo od stredovej línie tela (everzia). Vzhľadom na uhol, pod ktorým prechádza cez chodidlo, zohráva dôležitú úlohu pri udržiavaní priečnej klenby chodidla. Spoločne dlhý a krátky lýtkový sval pomáhajú stabilizovať nohu na chodidle, najmä pri státí na jednej nohe.',
    joints: [
      'fibula',
      'foot',
      'lowLeg',
      'ankle',
      'thumbFoot',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['fibula'],
    insertJoints: ['foot', 'f1'],
    movements:
      'Plantárna flexia chodidla, vybočenie chodidla, podpora klenby chodidla',
    musclesSameCategory: [
      'popliterius',
      'soleus',
      'gastrocnemius',
      'fibularisPosterior',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  tibialisPosterior: {
    id: 'tibialisPosterior',
    name: 'Zadný pýšťalový sval (Tibialis Posterior)',
    origin: [
      'Zadný povrch holennej kosti',
      'Zadný povrch ihlicovej kosti a interoseálna membrána',
    ],
    insertion: [
      'Tuberozita nartovej kosti (naviculárnej)',
      'Všetky klinové (cuneiform) kosti, kocková (cuboid) kosť',
      'Základy metatarzálnych kostí 2-4',
    ],
    function: [
      'Talokrurálny kĺb (členok): Plantarflexia chodidla',
      'Subtalárny kĺb: Inverzia chodidla',
      'Podporuje mediálnu pozdĺžnu klenbu chodidla',
    ],
    innervation: 'Tibiálny nerv (L4, L5)',
    bloodSupply: 'Vetvy zadnej holennej tepny',
    description:
      'Svojím pôsobením na členkový kĺb pomáha zadný pýšťalový sval (tibialis posterior) ostatným, silnejším flexorom nohy pri zdvíhaní päty, keď je chodidlo položené na zemi. To uľahčuje chôdzu, beh a rôzne kondičné cvičenia, ako je napríklad zdvíhanie lýtok. Okrem toho kontrakcia tibialis posterior približuje holennú a lýtkovú kosť. Tým sa pri plantárnej flexii zbližujú malleoly, zlepšuje sa ich uchopenie na tale a podporuje sa členok. Inverzia chodidla má tiež niekoľko dôležitých funkcií. Prostredníctvom tejto činnosti tibialis posterior odoláva tendencii tela kývať sa do strán, keď stojí na jednej nohe, čím uľahčuje rovnováhu. Tento sval plní aj podpornú úlohu tým, že dvíha, napína a spevňuje mediálnu pozdĺžnu klenbu chodidla. Táto činnosť pomáha rozložiť hmotnosť tela, keď je chodidlo položené na zemi.',
    joints: [
      'tibia',
      'fibula',
      'foot',
      'ankle',
      'lowLeg',
      'fingers4Foot',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['tibia', 'fibula'],
    insertJoints: ['foot', 'f2', 'f3', 'f4'],
    movements:
      'Plantarflexia a inverzia chodidla, mediálna pozdĺžna klenba chodidla',
    musclesSameCategory: [
      'popliterius',
      'soleus',
      'gastrocnemius',
      'fibularisLongus',
      'flexorDigitorumLongus',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  flexorDigitorumLongus: {
    id: 'flexorDigitorumLongus',
    name: 'Dlhý ohýbač prstov (Flexor Digitorum Longus)',
    origin: ['Zadný povrch holennej kosti'],
    insertion: ['Základy distálnych článkov prstov 2-5'],
    function: [
      'Metatarzofalangeálne a interfalangeálne kĺby 2-5: ohyb prstov',
      'Talokrurálny kĺb (členok): plantárna flexia chodidla',
      'Subtalárny kĺb: inverzia chodidla',
    ],
    innervation: 'Tibiálny nerv (L5, S1, S2)',
    bloodSupply: 'Zadná holenná tepna',
    description:
      'Ako už názov napovedá, hlavnou funkciou tohto svalu je plantárnaflexia nohy v členkovom kĺbe a druhého až piateho článku v metatarzofalangeálnom a interfalangeálnom kĺbe. Plantarflexii napomáha sval triceps surae (dvojhlavý a šikmý sval lýtka). Vďaka svojim úponom a priebehu okolo mediálneho malleolu pomáha flexor digitorum longus pri inverzii chodidla v subtalárnom kĺbe. Činnosť dlhého ohýbača prstov (flexora digitorum longus) je rozhodujúca pre cyklus chôdze. Keď sa chodidlo odlepí od zeme, dlhý ohýbač prstov (flexor digitorum longus) ohýba štyri laterálne prsty. Táto činnosť sa začína v distálnych interfalangeálnych kĺboch a po nej nasleduje flexia v proximálnych interfalangeálnych, resp. metatarzofalangeálnych kĺboch. Keď je chodidlo položené na zemi, flexor digitorum longus pôsobí v súčinnosti s lumbriálnymi a interoseálnymi svalmi na udržanie rovnováhy tým, že udržiava prsty v pevnom kontakte so zemou.',
    joints: [
      'tibia',
      'fingersFoot',
      'lowLeg',
      'foot',
      'ankle',
      'fingers4Foot',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['tibia'],
    insertJoints: ['fingersFoot', 'fingers4Foot'],
    movements: 'Flexia prstov, plantarflexia chodidla a inverzia',
    musclesSameCategory: [
      'popliterius',
      'soleus',
      'gastrocnemius',
      'fibularisLongus',
      'tibialisPosterior',
      'flexorHallucisLongus',
      'sartorius',
      'semimembranosus',
      'semitendonosus',
      'bicepsFemoris',
    ],
  },
  tibialisAnterior: {
    id: 'tibialisAnterior',
    name: 'Predný pýšaťalový sval (Tibialis Anterior)',
    origin: ['Laterálny povrch holennej kosti', 'Interosseálna membrána'],
    insertion: ['Mediálna klinová kosť', 'Základňa metatarzálnej kosti 1'],
    function: [
      'Talokrurálny kĺb (členok): dorzálna flexia chodidla',
      'Subtalárny kĺb: inverzia chodidla',
    ],
    innervation: 'Hlboký fibulárny nerv (L4, L5)',
    bloodSupply:
      'Vetvy prednej holennej tepny: Predné a mediálne svalové vetvy; predná tibiálna rekurentná, dorsalis pedis a predná mediálna malleolárna artéria; vetvy zadnej tibiálnej artérie: Mediálne malleolárne a kalkaneálne tepny',
    description:
      'Predný pýšťalový sval (Tibialis anterior) dorziflexuje chodidlo v talokrurálnom kĺbe a invertuje ho v subtalárnom kĺbe. Zohráva dôležitú úlohu pri činnostiach chôdze, turistiky a kopania do lopty tým, že stabilizuje členkový kĺb pri dopade chodidla na zem a pri ďalšom pohybe nohy ho ťahá od zeme. Podporuje aj mediálnu časť pozdĺžnej klenby chodidla počas akéhokoľvek pohybu.',
    joints: [
      'tibia',
      'foot',
      'thumbFoot',
      'ankle',
      'lowLeg',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['tibia'],
    insertJoints: ['foot', 'f1'],
    movements: 'Dorziflexia a inverzia chodidla',
    musclesSameCategory: [
      'fibularisLongus',
      'fibularisBrevis',
      'extensorDigitorumLongus',
      'extensorHallucisLongus',
    ],
  },
  fibularisBrevis: {
    id: 'fibularisBrevis',
    name: 'Krátky lýtkový (ihlicový) sval (Fibularis Brevis)',
    origin: ['Distálne 2/3 laterálnej plochy ihlicovej kosti (fibula)'],
    insertion: ['Tuberozita 5. metatarzálnej kosti'],
    function: [
      'Talocrural joint (členok): Foot plantar flexion',
      'Subtalárny kĺb: Everzia chodidla',
    ],
    innervation: 'Povrchový fibulárny nerv (L5, S1)',
    bloodSupply: 'Predná holenná tepna',
    description:
      'Krátky lýtkový sval (fibularis brevis) prechádza cez subtalárne (talokalkaneálne) aj talokrurálne (členkové) kĺby, a preto sa podieľa na činnosti vykonávanej v týchto kĺboch. Hlavnou funkciou Krátkeho lýtkového svalu (fibularis brevis) je evertovať chodidlo v subtalárnom kĺbe, čo pomáha obnoviť anatomickú polohu chodidla po jeho prevrátení. To je dôležité najmä pri behu alebo chôdzi po nerovnom teréne. Keďže fibularis brevis pôsobí proti inverzii chodidla, môže tiež zabrániť pádu tela na opačnú stranu, keď človek balansuje na jednej nohe. Sval tiež spôsobuje plantarflexiu chodidla v členkovom kĺbe, keď ho evertuje.',
    joints: [
      'fibula',
      'fingersFoot',
      'littleToe',
      'lowLeg',
      'fingers4Foot',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['fibula'],
    insertJoints: ['foot', 'f5'],
    movements: 'Plantárna flexia a everzia chodidla',
    musclesSameCategory: [
      'fibularisLongus',
      'tibialisAnterior',
      'extensorDigitorumLongus',
      'extensorHallucisLongus',
    ],
  },
  extensorDigitorumLongus: {
    id: 'extensorDigitorumLongus',
    name: 'Dlhý vystierač prstov (Extensor Digitorum Longus)',
    origin: [
      'Mediálny povrch ihlicovej kosti',
      'Laterálny tibiálny kondyl',
      'Interosseálna membrána',
    ],
    insertion: ['Distálne a stredné články prstov 2-5'],
    function: [
      'Metatarzofalangeálne a interfalangeálne kĺby 2-5: Extenzia prstov na nohe',
      'Talokrurálny kĺb (členok): Dorzálna flexia chodidla',
      'Subtalárny kĺb: Everzia chodidla',
    ],
    innervation: 'Hlboký fibulárny nerv (L5, S1)',
    bloodSupply:
      'Predná holenná, fibulárna, predná laterálna malleolárna, laterálna tarzálna, metatarzálna, plantárna a digitálna artéria',
    description:
      'Hlavnou úlohou dlhých vystieračov (extenzorov) kolena je predlžovať štyri bočné prsty na metatarzofalangeálnom kĺbe. To znamená, že pri samostatnom pôsobení nie je schopný predĺžiť celú dĺžku prstov, predlžuje sa len v metatarzofalangeálnom kĺbe, zatiaľ čo v interfalangeálnych kĺboch zostávajú prsty ohnuté. Ak sa však tento sval stiahne spolu s lumbrikálami, ktoré sú hlavnými extenzory interfalangeálnych kĺbov, prispieva k extenzii v každom kĺbe medzi kosťami štyroch bočných prstov.\nPôsobením v súčinnosti s predným pýšťalovým svalom (tibialis anterior), dlhým vystieračom palca (extensor hallucis longus) a tretím lýtkovým svalom (fibularis tertius) sa tento sval podieľa na dorzálnej flexii chodidla, keď sú ich proximálne úpony fixované. Keď sú distálne úpony fixované a telo je v anatomickej polohe, všetky štyri svaly privádzajú trup a dolnú končatinu dopredu.',
    joints: [
      'fibula',
      'foot',
      'fingersFoot',
      'fingers4Foot',
      'ankle',
      'lowLeg',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['fibula'],
    insertJoints: ['foot', 'f2', 'f3', 'f4', 'f5'],
    movements: 'Extenzia prstov, dorzálna flexia chodidla, everzia chodidla',
    musclesSameCategory: [
      'fibularisLongus',
      'tibialisAnterior',
      'fibularisBrevis',
      'extensorHallucisLongus',
    ],
  },
  extensorHallucisLongus: {
    id: 'extensorHallucisLongus',
    name: 'Dlhý vystierač palca (Extensor Hallucis Longus)',
    origin: [
      'Stredná tretina mediálneho povrchu ihlicovej kosti',
      'Interoseálna membrána',
    ],
    insertion: ['Základňa distálnej falangy veľkého prsta'],
    function: [
      'Metatarzofalangeálny a interfalangeálny kĺb 1: natiahnutie (extenzia) palca',
      'Talocrural joint: foot dorsiflexion',
    ],
    innervation: 'Hlboký fibulárny nerv (L5, S1)',
    bloodSupply: 'Predná holenná tepna',
    description:
      'Hlavnou funkciou je predĺženie palca na nohe (hallux). Táto činnosť prebieha v metatarzofalangeálnych aj interfalangeálnych kĺboch halluxu. Toto predĺženie je kľúčovým pohybom pri chôdzi a behu. V spolupráci s ďalšími svalmi z predného oddielu nohy dlhý vystierač palca (extenzor hallucis longus) dorzálne ohýba chodidlo v členkovom kĺbe. Keď je chodidlo fixované na podlahe (napr. pri chôdzi alebo robení drepov), sval ťahá telo mierne dopredu a zabraňuje strate gravitácie a pádu dozadu.',
    joints: [
      'fibula',
      'foot',
      'greatToe',
      'lowLeg',
      'ankle',
      'lowerBody',
      'fullBody',
    ],
    originJoints: ['fibula'],
    insertJoints: ['foot', 'f1'],
    movements: 'Natiahnutie prstov, dorzálna flexia chodidla',
    musclesSameCategory: [
      'fibularisLongus',
      'tibialisAnterior',
      'fibularisBrevis',
      'extensorDigitorumLongus',
    ],
  },
};
