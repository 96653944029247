import {
  HeadingSecondary,
  HeadingTertiary,
} from 'components/typography/heading';
import { Image, Text } from 'native-base';
import { ExternalLink } from 'components/links/ExternalLink';
import { PropsWithChildren } from 'react';

export const PolicyEN = () => {
  return (
    <>
      <HeadingSecondary my={2}>IMPORTANT NOTICE</HeadingSecondary>
      <Text my={4}>
        In case of health problems, injuries, or pregnancies, please consult
        your doctor before starting the exercises in the app. Never start
        exercising without initial warm-up exercises. If you feel pain during
        exercise, you should wait and see a doctor if necessary. Do not overload
        yourself - adjust the training to your personal ability. All information
        is without warranty. The individual person Tomáš Janečka is not
        responsible for secondary disadvantages or damages that would occur as a
        result of the exercises, or the advice provided.
      </Text>
      <HeadingSecondary my={2}>Introductory provisions</HeadingSecondary>
      <Text my={4}>
        These conditions govern the relationship between the individual person
        Tomáš Janečka and the user of the recoverytom.com mobile and web
        application. These conditions contain binding rules and conditions for
        the provision of services by the individual Tomáš Janečka to their
        users, as well as the rights and obligations of the users of the
        services of the individual Tomáš Janečka. The user expresses his consent
        to these conditions by creating a profile in the mobile and web
        application recoverytom.com, which is operated by Tomáš Janečka, or by
        continuing to use the services after being notified of the change in
        these conditions by following these conditions. By agreeing to these
        terms and conditions, these terms and conditions become binding for the
        user, and by this consent the user expresses his will to be bound by
        these terms and conditions for the entire period of provision and use of
        the services, even after the termination of the provision and use of the
        services in terms of the part of these terms and conditions, the content
        of which is the protection of authorized interests of the individual
        person Tomáš Janečka. These terms and conditions are not kept for the
        users, and therefore the individual Tomáš Janečka hereby recommends that
        each user obtain a copy of these terms and conditions for their own
        needs. The use of recoverytom.com mobile and web application services is
        only possible if you agree to these terms and conditions.
      </Text>
      <HeadingSecondary my={2}>
        Principle of anonymous data use
      </HeadingSecondary>
      <Text my={4}>
        In principle, our website can be used without providing personal data.
        The use of individual services and offers on our website and in our app
        can entail divergent regulations which in this case are explained
        separately below. The legal basis for data protection can be found in
        the General Data Protection Regulation (GDPR). When you access our
        website or App, some information, such as IP address, is transferred.
        You are also providing information about the end device used (computer,
        smartphone, tablet etc.), the browser used (Internet Explorer, Safari,
        Firefox etc.), time of visit to the website, the so-called referrer and
        volume of data transferred. We cannot use this data to identify an
        individual user. We only use this information to determine how
        attractive our offers are and to improve their performance or content,
        if necessary, and make their design even more appealing to you. Please
        bear in mind, however, that in the case of a static IP address, personal
        identification is possible by RIPE query in individual cases, although
        we do not perform this. Nevertheless, this website is accessible for
        both static and dynamic IP addresses assigned.
      </Text>
      <HeadingSecondary my={2}>
        Cookies, tracking pixels and similar technologies
      </HeadingSecondary>
      <Text my={4}>
        To improve our web and app service and make your experience as
        comfortable as possible, we use cookies, tracking pixels, or similar
        technologies. Cookies are small text files saved on your computer when
        you visit our website. They help us recognize your browser as yours.
        Cookies save information such as your language settings or the duration
        of your visit to our website. They save data entries you make on the
        website, like when you log in, so you don’t need to enter your data each
        time you use our services. Cookies help us to recognize your preferences
        and adjust our website to your areas of interest. Every time our website
        and app loads, we record how often it is visited and clicked on by using
        tags on our website and app called tracking pixels. These tracking
        pixels do not interfere with your computer. We use cookies and tracking
        pixels for different purposes, which also means they have different
        legal bases. Please review the cookie paragraph in our privacy policy
        below to understand how we use different types of cookies to fulfill
        different purposes and how long we store the collected information.
        These cookies are required for the site to function and cannot be
        disabled. Without these cookies, you will not be able to view our site
        properly.
      </Text>
      <Text fontWeight="bold">Functionality allowed</Text>
      <Li>Provide secure login and user authentication after login</Li>
      <Li>Error tracking and reporting</Li>
      <Li>Country-based pricing and payment-provider targeting</Li>
      <Li>Activation of A/B testing tools and analytical tools</Li>
      <Li>Affiliate tracking</Li>
      <Text my={1}>
        The protection of your personal data is of particular concern to us at
        RecoveryTom team. Therefore, we comply with the legal requirements when
        we collect and process your personal data. Below you will find extensive
        information about the scope and purpose of collecting data.
      </Text>
      <HeadingSecondary my={2}>
        Collection and processing of personal data
      </HeadingSecondary>
      <Text my={4}>
        We only collect personal data if you provide it to us, for example when
        you contact us, by registering a Recovery account, placing an order,
        requesting information, or publishing personal data in our RecoveryTom
        app in your profile or in the feed. We use the personal data you provide
        only to the extent that your data is necessary for rendering or
        processing our services. We store your data for as long as is necessary
        to achieve the intended purpose or until you delete your account or for
        as long as legal retention periods require data to be stored. Your data
        is subsequently deleted in accordance with legal requirements or
        processing is restricted. In the case of use purely for information,
        i.e. if you do not register or send us information another way, we only
        collect personal data which your browser transfers to our servers. If
        you want to view our website, we collect the following data, which we
        require for technical purposes to show you our content and guarantee
        stability and security (legal basis is a legitimate interest pursuant to
        Article 6 (1) (f) GDPR). In the context of the balance of interests in
        accordance with Article 6 (1) (f) GDPR, we have considered and weighed
        our interest in website and app provisioning and your interest in data
        protection compliant processing of your personal data. As the data below
        is technically required for the provision of our service to offer you
        our services and also guarantee stability and security, in particular
        protection against misuse, we have reached the conclusion that, with a
        state-of-the-art oriented data security guarantee, this data can be
        processed whereby appropriate consideration will be given to your
        interest in data protection compliant processing.
      </Text>
      <Image
        source={require('../../../../assets/policy/collectionProcessingDataEN.png')}
        alt="Collection processing data table"
        width="100%"
        height={[300, 400, 650]}
        resizeMode="contain"
        mx="auto"
      />
      <Text my={4}>
        The collection of data for website provision and the storage of data in
        log files is imperative for website and operation. Consequently, users
        may not object to this.
      </Text>
      <HeadingSecondary my={2}>Registration by Google</HeadingSecondary>
      <Text my={4}>
        You can create your RecoveryTom account using your Google account. You
        can register or log in to RecoveryTom app using your Google account. You
        will then be forwarded to Google (where you must be logged in or require
        an account) and receive an explanation of which of your data we need
        from Google, namely your public profile information such as first and
        last name and the email address you are using there. This information is
        required for identification purposes to create a secure RecoveryTom
        account for you. Your Google account and your RecoveryTom account will
        be permanently linked using your email address. We store your email
        information in-house. We can also tell that you have logged in using
        Google. As soon as you log in to Google, you can log in to RecoveryTom
        app. We will not submit any information on you to Google without your
        consent. Important: We do not record your Google login data in any way,
        and cannot post anything to your Google profile without your having
        expressly consented to this. You can learn how Google handles privacy
        settings using Google&apos;s{' '}
        <ExternalLink href={'https://policies.google.com/privacy'}>
          privacy policy
        </ExternalLink>{' '}
        and{' '}
        <ExternalLink href={'https://policies.google.com/terms'}>
          terms of use
        </ExternalLink>
        ; these also include the applicable conditions for the previously
        specified option of logging in and registering to RecoveryTom.
      </Text>
      <Image
        source={require('../../../../assets/policy/googleRegistrationDataEN.png')}
        alt="Collection processing data table"
        width="100%"
        height={[150, 200, 300]}
        resizeMode="contain"
      />
      <HeadingSecondary my={2}>
        Push notifications as part of the user experience
      </HeadingSecondary>
      <Text my={4}>
        We require your consent if you wish to receive our push notifications on
        your mobile iOS device even if the app is not open. Our app only uses
        push notifications if you have given your explicit consent to these. You
        can disable push notifications in settings at any time. If you use an
        Android device, push notifications are permitted automatically unless
        you disable this in your settings.
      </Text>
      <HeadingSecondary my={2}>Google Analytics</HeadingSecondary>
      <Text my={4}>
        We use the Google Analytics service from Google Ireland Limited (Gordon
        House, Barrow Street, Dublin 4, Ireland) to analyze our website
        visitors. Google uses cookies to track the use of the online product or
        service by users and the information is generally transferred to a
        Google server in the USA and stored there. Google will use this
        information on our behalf to evaluate the use of our online products and
        services by users, to compile reports on the activities within these
        online products and services and to provide us with further services
        associated with the use of these online products and services and the
        use of the internet. Pseudonymous user profiles can be created from the
        processed data. We use Google Analytics only with IP anonymization
        enabled. This means that Google will truncate the IP address of users
        within Member States of the European Union or in other states that are
        party to the Agreement on the European Economic Area. Only in
        exceptional cases will the full IP address be transmitted to a Google
        server in the USA and truncated there. The IP address transmitted by the
        user’s browser is not merged with other Google data. Users can prevent
        cookies from being stored by adjusting the settings to their browser
        software accordingly. We have made data protection friendly default
        settings. The legal basis for the use of this service is Art. 6
        paragraph 1 sentence 1 letter f GDPR. Users can prevent the collection
        of data generated by cookies by downloading and installing the browser
        plug-in that is available{' '}
        <ExternalLink href={'https://tools.google.com/dlpage/gaoptout?hl=en'}>
          here
        </ExternalLink>
        . As a guarantee pursuant of Art. 44ff of the General Data Protection
        Regulation (GDPR), Google has signed the EU standard contractual
        clauses. For more information on data processing by Google Analytics,
        please refer to the privacy policy of the provider.
      </Text>
      <HeadingSecondary my={2}>Firebase by Google</HeadingSecondary>
      <Text mt={4}>
        We use the Firebase service from Google Ireland Limited (Gordon House,
        Barrow Street, Dublin 4, Ireland) as our app cloud backend service and
        to derive application behavioral analytics. We use that information to
        see how users interact with our website and app. Some Firebase services
        process personal data. In most cases, the personal data is limited to
        so-called &quot;instance IDs&quot;, which are provided with a time
        stamp. These &quot;Instance IDs&quot; assigned by Firebase are unique
        and thus allow the linking of different events or processes. This data
        does not represent personally identifiable information for us, nor do we
        make any efforts to personalize it subsequently. We process these
        aggregated data to analyze and optimize usage behavior, for example by
        evaluating crash reports. Currently, we use the following Firebase
        services:
      </Text>

      <Li>
        Google Analytics for Firebase: Google Analytics uses the data to provide
        analytics and attribution information.{' '}
        <ExternalLink
          href={'https://support.google.com/firebase/answer/6318039'}
        >
          The precise information collected can vary by the device and
          environment
        </ExternalLink>
        .
      </Li>
      <Li>
        Cloud Firestore, Cloud Functions, Cloud Storage, Firebase
        Authentication: Terms of these services can be found{' '}
        <ExternalLink href={'https://cloud.google.com/terms/'}>
          here
        </ExternalLink>
        .
      </Li>
      <Li>
        Firebase hosting, Firebase Performance Monitoring, Firebase Remote
        Config, Firebase App Check:{' '}
        <ExternalLink href={'https://developers.google.com/terms/'}>
          Google APIs Terms of Service
        </ExternalLink>{' '}
        a{' '}
        <ExternalLink
          href={'https://firebase.google.com/terms/data-processing-terms'}
        >
          Firebase Data Processing and Security Terms
        </ExternalLink>
      </Li>
      <Li>
        Firebase Crashlytics:{' '}
        <ExternalLink href={'https://firebase.google.com/terms/crashlytics'}>
          Firebase Crashlytics & Firebase App Distribution Terms of Service
        </ExternalLink>
      </Li>
      <HeadingSecondary my={2}>Payment service provider</HeadingSecondary>
      <HeadingTertiary my={2}>Stripe</HeadingTertiary>
      <Text my={4}>
        Payment data may be transmitted to the Stripe payment service provider
        for this purpose. If your data is processed outside the EU, the payment
        service provider has undertaken to comply with the EU standard
        contractual clauses. In some cases, the Stripe payment service provider
        also collects this data himself on his own responsibility. For more
        information on the processing of personal data by Stripe payment service
        provider. Stripe, Inc, 354 Oyster Point Boulevard, South San Francisco,
        California, 94080, USA. You can find more information in the privacy
        policy of Stripe{' '}
        <ExternalLink href={'https://stripe.com/en-sk/privacy'}>
          here
        </ExternalLink>
        .
      </Text>
      <HeadingSecondary my={2}>Your rights</HeadingSecondary>
      <HeadingSecondary my={2}>
        Information on the rights of data subjects
      </HeadingSecondary>
      <Text my={4}>
        Each data subject has the right of access in accordance with Article 15
        GDPR, the right to rectification in accordance with Article 16 GDPR, the
        right to erasure in accordance with Article 17 GDPR, the right to
        restriction of processing in accordance with Article 18 GDPR, the right
        to object in Article 21 GDPR and the right to data portability in
        Article 20 GDPR. The limitations according to Articles 34 and 35 BDSG
        apply to the right of access and to the right to erasure.
      </Text>
      <HeadingTertiary my={2}>
        Cancel your RecoveryTom subscription
      </HeadingTertiary>
      <Text my={4}>
        Cancelling your subscription is possible only on the platform where it
        was bought.
      </Text>
      <HeadingTertiary my={2}>
        Deleting your RecoveryTom account
      </HeadingTertiary>
      <Text my={4}>
        Deleting your account will affect all your RecoveryTom user data,
        including your results and progress, and will be deleted permanently.
        Partial account deletion is not possible.
      </Text>
      <HeadingTertiary my={2}>Through the website</HeadingTertiary>
      <Li>Log in to your account on our website.</Li>
      <Li>Go to Profile tab.</Li>
      <Li>Click on &quot;Delete account&quot;.</Li>

      <HeadingTertiary my={2}>Through the application</HeadingTertiary>
      <Li>
        <Text my={4}>Go to Profile on the Dashboard tab at the top right.</Text>
      </Li>
      <Li>
        <Text my={4}>Click on &quot;Delete Account&quot;.</Text>
      </Li>

      <HeadingSecondary my={2}>
        Information on the option to lodge a complaint
      </HeadingSecondary>
      <Text my={4}>
        You also have the right to lodge a complaint with the competent data
        protection authority about our processing of your personal data.
      </Text>
      <HeadingSecondary my={2}>
        Information on withdrawal of consent
      </HeadingSecondary>
      <Text my={4}>
        You can withdraw your consent with us to process personal data at any
        time.
      </Text>
      <HeadingSecondary my={2}>
        CCPA Consumer Rights (Additional California Privacy Rights)
      </HeadingSecondary>
      <Text my={4}>
        This section provides additional details for California consumers about
        the rights afforded to them under the California Consumer Privacy Act or
        “CCPA“. In addition to the rights mentioned above under “information on
        the rights of data subjects”, California consumers have the right not to
        be discriminated against for having exercised their rights under the
        CCPA. In particular, RecoveryTom may not deny you goods or services,
        charge you different prices for goods or services, either by denying
        benefits or imposing penalties, provide you with a different level or
        quality of goods or services or threaten you with any of the above. In
        addition, RecoveryTom does not sell the personal information we collect
        (as defined in the CCPA) and will not sell it without providing you the
        right to opt out.
      </Text>
      <HeadingSecondary my={2}>
        Right in the event that data is processed for direct marketing purposes
      </HeadingSecondary>
      <Text my={4}>
        You have the right pursuant to Article 21 (2) GDPR to object to the
        processing of personal data concerning you. In the event that you object
        to processing for direct marketing purposes, we will no longer process
        your personal data for this purpose. Please note that this withdrawal
        will only apply prospectively. This does not affect processing that took
        place prior to a withdrawal.
      </Text>
      <HeadingSecondary my={2}>
        Information on the right to object in the case of balance of interests
      </HeadingSecondary>
      <Text my={4}>
        If we process your personal data based on a balance of interests, you
        can object to such processing. If you exercise this right to object,
        please state the reasons why we should not process your data as we have
        described. If your objection is justified, we will review the situation
        and either stop or adjust data processing or explain our compelling
        legitimate reasons for processing to you.
      </Text>
    </>
  );
};

const Li = (props: PropsWithChildren) => {
  return (
    <Text>
      {`\u2022`} {props.children}
    </Text>
  );
};
