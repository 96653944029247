import ModalRN from 'react-native-modal';
import React, { PropsWithChildren } from 'react';
import {
  Column,
  Divider,
  IStackProps,
  KeyboardAvoidingView,
  useBreakpointValue,
} from 'native-base';
import { HeadingTertiary } from 'components/typography/heading';
import { BACKDROP_OPACITY } from 'src/theme/constants';
import { Colors } from 'src/theme/colors';

type Props = IStackProps & {
  isOpen?: boolean;
  onClose?: () => void;
  title?: string;
  type?: 'bottom' | 'center';
  containerHeight?: IStackProps['height'];
  margin?: number;
};

export const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  type,
  containerHeight = 350,
  width,
  margin = 0,
  ...props
}: PropsWithChildren<Props>) => {
  const isBig = useBreakpointValue([false, false, true]);
  const isCenter = type === 'center' || isBig;

  return (
    <ModalRN
      isVisible={isOpen}
      onBackdropPress={onClose}
      backdropOpacity={BACKDROP_OPACITY}
      style={{ margin: margin }}
      animationIn={isCenter ? 'fadeIn' : 'slideInUp'}
      animationOut={isCenter ? 'fadeOut' : 'slideOutDown'}
    >
      <KeyboardAvoidingView
        behavior="position"
        position={isCenter ? 'relative' : 'absolute'}
        bottom={isCenter ? undefined : 0}
        maxW="100%"
        w={width || (isCenter ? 400 : '100%')}
        m="auto"
        h={containerHeight}
        maxHeight="100%"
      >
        <Column
          bg={Colors.bgScreen}
          _dark={{
            bg: Colors.bgDark,
          }}
          w="full"
          borderRadius={10}
          overflow="hidden"
          divider={<Divider />}
          h="full"
        >
          {!!title && <HeadingTertiary p={4}>{title}</HeadingTertiary>}
          <Column p={4} space={4} flex={1} {...props}>
            {children}
          </Column>
        </Column>
      </KeyboardAvoidingView>
    </ModalRN>
  );
};
