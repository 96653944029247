import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Routes } from 'src/navigation/routes';
import { useTranslation } from 'react-i18next';
import { isAndroid, isIOS } from 'src/utils/constants';
import { useColorMode } from 'native-base';
import { DashboardStackParamList } from 'src/navigation/types/dashboardStack';
import { DashboardScreen } from 'components/screens/DashboardScreen';
import { FavoritesScreen } from 'components/screens/FavoritesScreen';
import { ExerciseDetailScreen } from 'components/screens/ExerciseDetailScreen';
import { FriendsScreen } from 'components/screens/FriendsScreen';
import { ProfileScreen } from 'components/screens/ProfileScreen';
import { PrivacyPoliciesScreen } from 'components/screens/PrivacyPoliciesScreen';
import { SubscriptionScreen } from 'components/screens/SubscriptionScreen';
import * as React from 'react';
import { StackHeader } from 'components/layout/StackHeader';
import { TermsOfUseScreen } from 'components/screens/TermsOfUseScreen';
import { FavoriteMusclesScreen } from 'components/screens/FavoriteMusclesScreen';

const Stack = createNativeStackNavigator<DashboardStackParamList>();

export const DashboardStack = () => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerBlurEffect: isIOS
          ? colorMode === 'dark'
            ? 'dark'
            : 'light'
          : undefined,
        headerTransparent: isIOS,
        header: isAndroid ? (props) => <StackHeader {...props} /> : undefined,
      }}
    >
      <Stack.Screen
        name={Routes.Dashboard}
        component={DashboardScreen}
        options={{
          title: t('routes.dashboard'),
          headerShown: false,
        }}
      />
      <Stack.Screen
        name={Routes.Favorites}
        component={FavoritesScreen}
        options={{
          title: t('routes.favorites'),
        }}
      />
      <Stack.Screen
        name={Routes.FavoriteMuscles}
        component={FavoriteMusclesScreen}
        options={{
          title: t('routes.favoriteMuscles'),
        }}
      />
      <Stack.Screen
        name={Routes.ExerciseDetail}
        component={ExerciseDetailScreen}
      />
      <Stack.Screen
        name={Routes.Profile}
        component={ProfileScreen}
        options={{
          title: t('routes.profile'),
        }}
      />
      <Stack.Screen
        name={Routes.Friends}
        component={FriendsScreen}
        options={{
          title: t('routes.friends'),
        }}
      />
      <Stack.Screen
        name={Routes.PrivacyPolicy}
        component={PrivacyPoliciesScreen}
        options={{
          title: t('routes.privacy'),
        }}
      />
      <Stack.Screen
        name={Routes.TermsOfUse}
        component={TermsOfUseScreen}
        options={{
          title: t('routes.privacy'),
        }}
      />
      <Stack.Screen
        name={Routes.Subscription}
        component={SubscriptionScreen}
        options={{ title: t('routes.subscription') }}
      />
    </Stack.Navigator>
  );
};
