import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Column,
  IconButton,
  Row,
  Spinner,
  useBreakpointValue,
} from 'native-base';
import { ExercisesSlider } from 'components/exercises/ExercisesSlider';
import { useTranslation } from 'react-i18next';
import { UserIcon } from 'components/icons/icons';
import { useRecoilState } from 'recoil';
import { userState, useUserRelationships } from 'src/state/state';
import { Screen } from 'components/layout/Screen';
import { Routes } from 'src/navigation/routes';
import { DashboardStackScreenProps } from 'src/navigation/types/dashboardStack';
import { SCREEN_MAX_WIDTH } from 'src/theme/constants';
import { MusclesSlider } from 'components/MusclesSlider';
import { DashboardSection } from 'components/dashboard/DashboardSection';
import { getProblemsConfig } from 'src/data/problems/problemsConfig';
import { ProblemsSlider } from 'components/ProblemsSlider';
import { LoginInfo } from 'components/dashboard/LoginInfo';
import { EmptyDashboard } from 'components/dashboard/EmptyDashboard';
import { NumberBadge } from 'components/NumberBadge';
import { Colors } from 'src/theme/colors';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { HeadingPrimary } from 'components/typography/heading';
import * as SplashScreen from 'expo-splash-screen';
import { isWeb } from '../../utils/constants';
import { login } from '../../firebase/auth';
import { useFavoriteMuscles } from 'hooks/useFavoriteMuscles';

type Props = DashboardStackScreenProps<Routes.Dashboard>;

export const DashboardScreen = ({ navigation }: Props) => {
  const { t } = useTranslation();
  const [user] = useRecoilState(userState);
  const relationships = useUserRelationships();
  const [loading, setLoading] = useState(false);

  const { top } = useSafeAreaInsets();
  const showProfileButton = useBreakpointValue([true, true, false]) || !isWeb;
  const problemsConfig = getProblemsConfig();
  const { favoriteMuscles } = useFavoriteMuscles();

  const noData =
    !user?.favorites.length &&
    !user?.favoriteMuscles?.length &&
    !user?.favoriteProblems?.length;

  useEffect(() => {
    if (user && loading) {
      setLoading(false);
    }
  }, [loading, user]);

  const problems = useMemo(() => {
    const allProblems =
      user?.favoriteProblems?.map((id) => problemsConfig[id]) || [];
    return allProblems.filter((problem) => !!problem);
  }, [problemsConfig, user?.favoriteProblems]);

  const onLayoutRootView = useCallback(() => {
    SplashScreen.hideAsync();
  }, []);

  const onLogin = async () => {
    setLoading(true);
    await login();
    setLoading(false);
  };

  const header = (
    <Row
      justifyContent="space-between"
      alignItems="center"
      px={[4]}
      _web={{
        px: [4, 4, 0],
      }}
    >
      <HeadingPrimary>{t('routes.dashboard')}</HeadingPrimary>
      {user && showProfileButton && (
        <Column>
          <IconButton
            icon={
              <UserIcon
                color={Colors.bgDark}
                _dark={{ color: Colors.bgWhite }}
                size={6}
              />
            }
            onPress={() => navigation.navigate(Routes.Profile)}
          />
          <NumberBadge
            value={relationships?.pendingFriendRequests.length || undefined}
          />
        </Column>
      )}
    </Row>
  );

  const renderContent = () => {
    if (user === null || loading) {
      return <Spinner m="auto" />;
    }
    if (!user) {
      return <LoginInfo onLogin={onLogin} />;
    }

    if (noData) {
      return <EmptyDashboard />;
    }
    return (
      <Column pt={4} space={[4, 8]}>
        {user.favorites.length > 0 && (
          <DashboardSection
            title={t('dashboard.favorite')}
            target={Routes.Favorites}
          >
            <ExercisesSlider exercises={user.favorites} />
          </DashboardSection>
        )}
        {favoriteMuscles.length > 0 && (
          <DashboardSection
            title={t('dashboard.favoriteMuscles')}
            target={Routes.FavoriteMuscles}
          >
            <MusclesSlider muscles={favoriteMuscles} />
          </DashboardSection>
        )}
        {problems.length > 0 && (
          <DashboardSection title={t('dashboard.favoriteProblems')}>
            <ProblemsSlider problems={problems} />
          </DashboardSection>
        )}
      </Column>
    );
  };

  return (
    <Column pt={`${top}px`} flex={1}>
      <Screen
        maxWidth={SCREEN_MAX_WIDTH}
        withFooter
        px={0}
        _web={{
          px: [0, 0, 4, 8],
          minH: '100vh',
        }}
      >
        <Column flex={1} onLayout={onLayoutRootView}>
          {header}
          {renderContent()}
        </Column>
      </Screen>
    </Column>
  );
};
