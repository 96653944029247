import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Routes } from 'src/navigation/routes';
import { useTranslation } from 'react-i18next';
import { isAndroid, isIOS } from 'src/utils/constants';
import { useColorMode } from 'native-base';
import { ProblemsStackParamList } from 'src/navigation/types/problemsStack';
import { ProblemsScreen } from 'components/screens/ProblemsScreen';
import { ProblemDetailScreen } from 'components/screens/ProblemDetailScreen';
import { StackHeader } from 'components/layout/StackHeader';

const Stack = createNativeStackNavigator<ProblemsStackParamList>();

export const ProblemsStack = () => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  return (
    <Stack.Navigator
      initialRouteName={Routes.Problems}
      screenOptions={{
        headerShown: true,
        headerBlurEffect: isIOS
          ? colorMode === 'dark'
            ? 'dark'
            : 'light'
          : undefined,
        headerTransparent: isIOS,
        header: isAndroid ? (props) => <StackHeader {...props} /> : undefined,
      }}
    >
      <Stack.Screen
        name={Routes.Problems}
        component={ProblemsScreen}
        options={{
          title: t('routes.problems'),
        }}
      />
      <Stack.Screen
        name={Routes.ProblemDetail}
        component={ProblemDetailScreen}
        getId={({ params }) => params.id}
      />
    </Stack.Navigator>
  );
};
