import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en_US, sk_SK } from 'src/i18n/locales';
import { isIOS, isWeb } from 'src/utils/constants';
import LanguageDetector from 'i18next-browser-languagedetector';
import { NativeModules } from 'react-native';
import { LANGUAGE_KEY, mmkv } from '../utils/storage';

if (isWeb) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        sk: {
          translation: sk_SK,
        },
        en: {
          translation: en_US,
        },
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });
} else {
  const storedLanguage = mmkv.getString(LANGUAGE_KEY);
  const locale = isIOS
    ? NativeModules.SettingsManager.settings.AppleLocale ||
      NativeModules.SettingsManager.settings.AppleLanguages[0]
    : NativeModules.I18nManager.localeIdentifier;
  const language = storedLanguage
    ? storedLanguage
    : locale
    ? locale.slice(0, 2)
    : 'en';
  mmkv.set(LANGUAGE_KEY, language);

  i18n.use(initReactI18next).init({
    resources: {
      sk: {
        translation: sk_SK,
      },
      en: {
        translation: en_US,
      },
    },
    lng: language,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
  });
}

export default i18n;
