import { useRecoilState } from 'recoil';
import { exercisesState } from 'src/state/state';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IExercise } from 'src/@types/api';
import {
  fetchExercise,
  fetchExerciseImageUrl,
  fetchExercises,
} from 'src/firebase/exercises';
import { useIsPremium } from './useIsPremium';

export const useExercises = () => {
  const [exercises, setExercises] = useRecoilState(exercisesState);

  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});
  const { hasPremiumExercises } = useIsPremium();

  const exercisesMap = useRef(getExercisesMap({}, exercises));

  useEffect(() => {
    exercisesMap.current = getExercisesMap(exercisesMap.current, exercises);
  }, [exercises]);

  const getExerciseById = useCallback(
    async (id: string) => {
      if (exercisesMap.current[id]) {
        return exercisesMap.current[id];
      } else {
        const exercise = await fetchExercise(id);
        if (exercise) {
          setExercises((exercises) => [...exercises, exercise]);
        }
        return exercise;
      }
    },
    [exercisesMap, setExercises]
  );

  const getImageUrl = useCallback(
    async (id: string) => {
      if (imageUrls[id]) {
        return imageUrls[id];
      } else {
        const url = await fetchExerciseImageUrl(id);
        setImageUrls((prev) => ({ ...prev, [id]: url }));
        return url;
      }
    },
    [imageUrls]
  );

  const fetchAllExercises = useCallback(async () => {
    const exercisesData = await fetchExercises(!hasPremiumExercises);
    setExercises(exercisesData);
  }, [hasPremiumExercises, setExercises]);

  return { exercises, getExerciseById, getImageUrl, fetchAllExercises };
};

const getExercisesMap = (
  oldMap: Record<string, IExercise>,
  exercises: IExercise[]
) => {
  return exercises.reduce<Record<string, IExercise>>((acc, exercise) => {
    acc[exercise.id] = exercise;
    return acc;
  }, oldMap);
};
