import { useTranslation } from 'react-i18next';
import { useFavoriteMuscles } from 'hooks/useFavoriteMuscles';
import { MusclesList } from 'components/muscles/MusclesList';

export const FavoriteMusclesScreen = () => {
  const { t } = useTranslation();

  const { favoriteMuscles } = useFavoriteMuscles();
  return (
    <MusclesList
      muscles={favoriteMuscles}
      title={t('routes.favoriteMuscles')}
      filtersCount={0}
    />
  );
};
