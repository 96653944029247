import { Row, Text } from 'native-base';
import { ProfileImage } from 'components/ProfileImage';
import { getLocalizedValue } from 'src/utils/utils';
import { IConversation, IConversationThread } from 'src/@types/api';
import { useRecoilValue } from 'recoil';
import { userState } from 'src/state/state';
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import { isWeb } from '../../utils/constants';
import { Routes } from '../../navigation/routes';
import { Link } from 'components/links/Link';
import { ThreadType } from '../../@types/enums';

type Props = IHStackProps & {
  conversation: IConversation;
  thread?: IConversationThread;
};

export const ChatHeader = ({ conversation, thread, ...props }: Props) => {
  const user = useRecoilValue(userState);

  const getLinkDirection = (tType: ThreadType) => {
    if (tType === ThreadType.Exercise) {
      if (isWeb)
        return { screen: Routes.ExerciseDetail, params: { id: thread?.id } };
      else
        return {
          screen: Routes.ExercisesStack,
          params: {
            screen: Routes.ExerciseDetail,
            params: { id: thread?.id },
            initial: false,
          },
        };
    } else if (tType === ThreadType.Muscle) {
      if (isWeb)
        return { screen: Routes.MuscleDetail, params: { id: thread?.id } };
      else
        return {
          screen: Routes.MusclesStack,
          params: {
            screen: Routes.MuscleDetail,
            params: { id: thread?.id },
            initial: false,
          },
        };
    } else {
      if (isWeb)
        return { screen: Routes.ProblemDetail, params: { id: thread?.id } };
      else
        return {
          screen: Routes.ProblemsStack,
          params: {
            screen: Routes.ProblemDetail,
            params: { id: thread?.id },
            initial: false,
          },
        };
    }
  };

  return (
    <Row space={1} alignItems="center" {...props}>
      {conversation?.participants
        .filter(({ uid }) => uid !== user?.uid)
        .map(({ photoUrl }) => (
          <ProfileImage key={photoUrl} size="30px" uri={photoUrl} />
        ))}
      {thread?.threadName && (
        <Link to={getLinkDirection(thread?.type)}>
          <Text bold={true} ml={4}>
            {getLocalizedValue(thread.threadName)}
          </Text>
        </Link>
      )}
    </Row>
  );
};
