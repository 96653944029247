import { Column, Pressable, Row } from 'native-base';
import React from 'react';
import { FiltersType } from '../../@types/enums';
import {
  exercisesJointFilterOptions,
  fingersFootFilterOptions,
  fingersHandFilterOptions,
  mainFilterOptions,
  masteredFilterOptions,
  musclesJointFilterOptions,
  originFilterOptions,
  ribsFilterOptions,
  strengthFilterOptions,
  vertebraFilterOptions,
} from '../../data/exercises/filters/filterOptions';
import { HeadingTertiary } from 'components/typography/heading';
import { TextSmall } from 'components/typography/text';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';

type Props = {
  selectedFilters: Record<string, boolean>;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  filtersType: FiltersType;
};

export const FilterOptions = ({
  selectedFilters,
  setSelectedFilters,
  filtersType,
}: Props) => {
  const { t } = useTranslation();

  const updateFilters = (filter: string) => {
    setSelectedFilters((filters) => {
      const newFilters = { ...filters };
      if (filters[filter]) {
        delete newFilters[filter];
      } else {
        newFilters[filter] = true;
      }
      return newFilters;
    });
  };

  const renderFilters = (filters: string[]) => {
    return (
      <Row flexWrap="wrap" justifyContent="center">
        {filters.map((filter) => {
          // @ts-ignore
          const title = t(`filter.${filter}`) as string;
          return (
            <Pressable key={filter} onPress={() => updateFilters(filter)}>
              <FilterOption isPicked={selectedFilters[filter]}>
                <TextSmall color={selectedFilters[filter] ? 'white' : 'black'}>
                  {title}
                </TextSmall>
              </FilterOption>
            </Pressable>
          );
        })}
      </Row>
    );
  };

  return (
    <Column space={4}>
      {filtersType === FiltersType.Exercises ? (
        <>
          <Column>{renderFilters(masteredFilterOptions)}</Column>
          <HeadingTertiary>{t('filter.main')}</HeadingTertiary>
          <Column>{renderFilters(mainFilterOptions)}</Column>
          <HeadingTertiary>{t('filter.joints')}</HeadingTertiary>
          <Column>{renderFilters(exercisesJointFilterOptions)}</Column>
          <HeadingTertiary>{t('filter.strengthFilters')}</HeadingTertiary>
          <Column>{renderFilters(strengthFilterOptions)}</Column>
        </>
      ) : (
        <>
          <Column>{renderFilters(originFilterOptions)}</Column>
          <HeadingTertiary>{t('filter.vertebra')}</HeadingTertiary>
          <Column>{renderFilters(vertebraFilterOptions)}</Column>
          <HeadingTertiary>{t('filter.ribsHeader')}</HeadingTertiary>
          <Column>{renderFilters(ribsFilterOptions)}</Column>
          <HeadingTertiary>{t('filter.joints')}</HeadingTertiary>
          <Column>{renderFilters(musclesJointFilterOptions)}</Column>
          <HeadingTertiary>{t('filter.fingersHand')}</HeadingTertiary>
          <Column>{renderFilters(fingersHandFilterOptions)}</Column>
          <HeadingTertiary>{t('filter.fingersFoot')}</HeadingTertiary>
          <Column>{renderFilters(fingersFootFilterOptions)}</Column>
        </>
      )}
    </Column>
  );
};

const FilterOption = styled.View<{ isPicked: boolean }>`
  padding: 4px 16px;
  margin: 4px;
  text-align: center;
  background-color: ${(props) =>
    props.isPicked ? 'rgba(51, 107, 204, 1)' : 'rgba(192, 192, 192, 0.7)'};
  border-radius: 20px;
`;
