import { Column, Pressable, Row, Text } from 'native-base';
import {
  HeadingSecondary,
  HeadingTertiary,
} from 'components/typography/heading';
import { TextSmall } from 'components/typography/text';
import { Colors } from '../theme/colors';
import { CheckCircleIcon } from 'components/icons/icons';
import { PremiumType } from '../@types/enums';
import { useTranslation } from 'react-i18next';

export type PremiumItem = {
  icon: React.ReactNode;
  title: string;
  description: string;
  bullets: string[];
  price?: string;
  label?: string;
  type: PremiumType;
};

type Props = {
  item: PremiumItem;
  selected?: boolean;
  disabled?: boolean;
  onPress?: () => void;
};

export const PremiumRow = ({ item, selected, onPress, disabled }: Props) => {
  const { t } = useTranslation();

  const { title, description, bullets, icon, price, label } = item;
  return (
    <Pressable
      bg={disabled ? Colors.grayLightest : Colors.bgWhite}
      _dark={{ bg: disabled ? Colors.blueLight : Colors.bgDark }}
      onPress={onPress}
      disabled={disabled}
      shadow={5}
      borderRadius={16}
      borderWidth={selected ? 2 : 0}
      borderColor={Colors.primary}
      opacity={disabled ? 0.6 : 1}
      p={2}
    >
      {label && (
        <Row
          position="absolute"
          top={0}
          right={-1}
          borderTopRightRadius={16}
          borderBottomLeftRadius={8}
          bg={Colors.warning}
          px={2}
          py={1}
        >
          <Text color={Colors.bgWhite}>{label}</Text>
        </Row>
      )}

      <Column
        position="absolute"
        left={2}
        top={0}
        bottom={0}
        justifyContent="center"
      >
        {icon}
      </Column>

      <Column
        position="absolute"
        right={2}
        top={0}
        bottom={0}
        justifyContent="center"
      >
        <Row alignItems="center">
          <HeadingSecondary alignSelf="center" color={Colors.primary}>
            {price} €
          </HeadingSecondary>
          <Text color={Colors.primary}>{t('subscription.monthly')}</Text>
        </Row>
      </Column>
      <Column space={2} flex={1} ml={12} mb={2}>
        <HeadingTertiary>{title}</HeadingTertiary>
        {bullets.map((bullet) => (
          <Row key={bullet} alignItems="center" space={1}>
            <CheckCircleIcon color={Colors.success} />
            <Text>{bullet}</Text>
          </Row>
        ))}
      </Column>

      <TextSmall ml={12} maxW="100%" mt="auto">
        {description}
      </TextSmall>
    </Pressable>
  );
};
