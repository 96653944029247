import { FC } from 'react';
import { Colors } from 'src/theme/colors';
import { Button as NBButton, IButtonProps } from 'native-base';

export const PrimaryButton: FC<IButtonProps> = (props) => (
  <NBButton
    backgroundColor={Colors.primary}
    _loading={{ bg: Colors.primary, opacity: 0.8 }}
    {...props}
  />
);
