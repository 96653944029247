import { IconButton } from 'native-base';
import { FavoriteIcon, FavoriteOutlineIcon } from 'components/icons/icons';
import { useFavoriteExercise } from 'src/hooks/useFavoriteExercise';
import { useRef } from 'react';
import { IFavoriteExercise } from 'src/@types/types';

type Props = {
  exercise: IFavoriteExercise;
  size?: number | string;
  onPress?: (isFav: boolean) => void;
};

export const FavoriteButton = ({ exercise, size = 5, onPress }: Props) => {
  const { isFavorite, toggleFavorite, disabled } = useFavoriteExercise();
  const isPressed = useRef(false);
  const isFav = isFavorite(exercise.id);

  const onPressHandler = () => {
    if (isPressed.current) return;
    isPressed.current = true;
    toggleFavorite(exercise);
    onPress?.(isFav);

    setTimeout(() => {
      isPressed.current = false;
    }, 500);
  };

  return (
    <IconButton
      colorScheme="primary"
      icon={
        isFav ? (
          <FavoriteIcon size={size} />
        ) : (
          <FavoriteOutlineIcon size={size} />
        )
      }
      disabled={disabled}
      onPress={onPressHandler}
    />
  );
};
