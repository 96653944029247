import { Heading, IHeadingProps } from 'native-base';

// 30px
export const HeadingPrimary = (props: IHeadingProps) => {
  return <Heading {...props} />;
};

// 20px
export const HeadingSecondary = (props: IHeadingProps) => {
  return <Heading size="md" {...props} />;
};

// 16px
export const HeadingTertiary = (props: IHeadingProps) => {
  return <Heading size="sm" {...props} />;
};
