/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  checkoutSessionsCollection,
  subscriptionsCollection,
} from 'src/firebase/refs';
import {
  doc,
  limit,
  onSnapshot,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { ICheckoutSession, ISubscription } from 'src/@types/api';
import { functions } from 'src/firebase/init';
import { httpsCallable } from 'firebase/functions';
import { PremiumType } from '../@types/enums';

export const subscribe = async (
  userId: string,
  productId: unknown,
  to: PremiumType,
  from?: string
) => {
  const url = getCallbackUrl();

  const docRef = doc(checkoutSessionsCollection(userId));
  if (typeof productId === 'string') {
    const session: ICheckoutSession = {
      price: productId,
      success_url: url,
      cancel_url: url,
      trial_from_plan: true,
    };
    await setDoc(docRef, session);
  }
  return onSnapshot(docRef, (snapshot) => {
    const data = snapshot.data();
    if (data?.error) {
      alert(`An error occurred: ${data.error.message}`);
    }
    if (data?.url) {
      window.location.assign(data?.url);
    }
  });
};

export const onFetchSubscription = (
  userId: string,
  callback: (sub: ISubscription) => void
) => {
  const q = query(
    subscriptionsCollection(userId),
    where('status', '==', 'active'),
    limit(1)
  );
  return onSnapshot(q, (snapshot) => {
    if (snapshot?.docs?.length) {
      callback(snapshot.docs[0].data());
    }
  });
};

export const getManagementUrl = async () => {
  const result = await httpsCallable<{ returnUrl: string }, { url: string }>(
    functions,
    'ext-firestore-stripe-payments-createPortalLink'
  )({
    returnUrl: window.location.href,
  });
  return result.data?.url;
};

export const fetchPackages = async () => {
  return [];
};

export const restorePurchase = async () => {
  return;
};

const getCallbackUrl = () => {
  const { hostname, port, protocol } = window.location;
  const portString = port ? `:${port}` : '';
  return `${protocol}//${hostname}${portString}/subscription`;
};
