import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import { BottomTabsParamList } from 'src/navigation/types/bottomTabs';
import { Routes } from 'src/navigation/routes';
import { ExercisesStack } from 'src/navigation/ExercisesStack';
import { useTranslation } from 'react-i18next';
import {
  BodyIcon,
  ChatIcon,
  DashboardIcon,
  GymIcon,
  ProblemsIcon,
} from 'components/icons/icons';
import { Colors } from 'src/theme/colors';
import { ConversationsStack } from 'src/navigation/ConversationsStack';
import { BottomTabBar } from 'components/tabbar/BottomTabBar';
import { isWeb } from '../utils/constants';
import { useUnreadMessages } from 'src/hooks/useUnreadMessages';
import { DashboardStack } from 'src/navigation/DashboardStack';
import { MusclesStack } from './MusclesStack';
import { ProblemsStack } from 'src/navigation/ProblemsStack';
import { useRecoilValue } from 'recoil';
import { userState, useUserRelationships } from '../state/state';
import { usePushNotifications } from 'src/hooks/usePushNotifications';

const BottomTab = createBottomTabNavigator<BottomTabsParamList>();

export const BottomTabNavigator = () => {
  const { t } = useTranslation();
  const { unreadCount } = useUnreadMessages();
  const user = useRecoilValue(userState);
  const relationships = useUserRelationships();
  usePushNotifications();

  return (
    <BottomTab.Navigator
      screenOptions={{
        tabBarActiveTintColor: Colors.primary,
        headerShown: false,
        tabBarBadgeStyle: {
          backgroundColor: Colors.badge,
          color: Colors.bgWhite,
        },
      }}
      initialRouteName={user ? Routes.DashboardStack : Routes.ExercisesStack}
      tabBar={isWeb ? undefined : (props) => <BottomTabBar {...props} />}
    >
      <BottomTab.Screen
        name={Routes.DashboardStack}
        component={DashboardStack}
        options={{
          title: t('routes.dashboard'),
          tabBarIcon: ({ color, size }) => (
            <DashboardIcon color={color} size={size} />
          ),
          tabBarBadge: relationships?.pendingFriendRequests.length || undefined,
        }}
      />
      {user && (
        <BottomTab.Screen
          name={Routes.ConversationsStack}
          component={ConversationsStack}
          options={{
            title: t('routes.chat'),
            tabBarIcon: ({ color, size }) => (
              <ChatIcon color={color} size={size} />
            ),
            lazy: false,
            tabBarBadge: unreadCount || undefined,
          }}
        />
      )}
      <BottomTab.Screen
        name={Routes.ExercisesStack}
        component={ExercisesStack}
        options={{
          title: t('routes.exercises'),
          tabBarIcon: ({ color, size }) => (
            <GymIcon color={color} size={size} />
          ),
        }}
      />
      <BottomTab.Screen
        name={Routes.MusclesStack}
        component={MusclesStack}
        options={{
          title: t('routes.muscles'),
          tabBarIcon: ({ color, size }) => (
            <BodyIcon color={color} size={size} />
          ),
        }}
      />
      <BottomTab.Screen
        name={Routes.ProblemsStack}
        component={ProblemsStack}
        options={{
          title: t('routes.problems'),
          tabBarIcon: ({ color, size }) => (
            <ProblemsIcon color={color} size={size} />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
};
