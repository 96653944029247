import { Platform } from 'react-native';
import { releaseChannel } from 'expo-updates';

export const isWeb = Platform.OS === 'web';
export const isIOS = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isDev = __DEV__;

export const MIN_APP_VERSION_KEY = 'MIN_APP_VERSION';

export const isProd = releaseChannel.toLowerCase().includes('prod');
