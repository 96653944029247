import { onSnapshot } from 'firebase/firestore';
import { appConfigRef } from './refs';
import { IAppConfig } from '../@types/api';

export const fetchAppConfig = (
  callback: (appConfig: IAppConfig | undefined) => void
) => {
  return onSnapshot(appConfigRef, (snapshot) => {
    callback(snapshot.data());
  });
};
