import { isWeb } from '../utils/constants';
import { useLanguage } from '../hooks/useLanguage';
import { TextButton } from 'components/buttons/TextButton';
import { EnglishIcon, SlovakIcon } from 'components/icons/icons';

export const LanguageSwitcher = () => {
  const { changeLanguage, currentLanguage } = useLanguage();
  if (isWeb) {
    return null;
  }
  if (currentLanguage === 'sk') {
    return (
      <TextButton
        leftIcon={<EnglishIcon />}
        onPress={() => changeLanguage('en')}
      >
        EN
      </TextButton>
    );
  }
  if (currentLanguage === 'en') {
    return (
      <TextButton
        leftIcon={<SlovakIcon />}
        onPress={() => changeLanguage('sk')}
      >
        SK
      </TextButton>
    );
  }
  return <></>;
};
