import { Link } from 'components/links/Link';
import { Routes } from 'src/navigation/routes';
import { Column, Row, View } from 'native-base';
import { ExerciseImage } from 'components/exercises/ExerciseImage';
import { getLocalizedValue } from 'src/utils/utils';
import { Colors } from 'src/theme/colors';
import { FavoriteButton } from 'components/buttons/FavoriteButton';
import { TextSmall } from 'components/typography/text';
import { DarkColumn, DarkRow } from 'components/layout/views';
import React from 'react';
import { IFavoriteExercise } from 'src/@types/types';
import { MasteredExerciseButton } from 'components/buttons/MasteredExerciseButton';
import { PremiumOverlay } from 'components/PremiumOverlay';
import { useIsPremium } from 'hooks/useIsPremium';
import { useTranslation } from 'react-i18next';

type Props = {
  exercise: IFavoriteExercise;
  isLoggedIn: boolean;
};

export const Exercise = React.memo(({ exercise, isLoggedIn }: Props) => {
  const { id } = exercise;
  const { hasPremiumExercises } = useIsPremium();
  const hasLikes = exercise.likes !== undefined && exercise.likes > 0;
  const { i18n } = useTranslation();
  return (
    <DarkColumn flex={1} shadow={2} borderRadius={5}>
      <Column flex={1} borderRadius={5} overflow="hidden">
        <View flex={1}>
          <Link
            to={{
              screen: Routes.ExerciseDetail,
              params: { id },
            }}
            h="full"
            w="full"
            _web={{
              pt: '100%',
              h: undefined,
            }}
          >
            <ExerciseImage id={id} />
          </Link>
        </View>
        <DarkRow p={2} pr={[0, 2]} h="60px" alignItems="center">
          <TextSmall flex={1} bold noOfLines={3}>
            {getLocalizedValue(exercise.name, i18n.language)}
          </TextSmall>
          {isLoggedIn && (
            <Row mr={[-2, 0]}>
              <MasteredExerciseButton exerciseId={exercise.id} />
            </Row>
          )}
          <Column alignItems="center">
            {(isLoggedIn || hasLikes) && <FavoriteButton exercise={exercise} />}
            {hasLikes && (
              <TextSmall mt={-2} color={Colors.secondary}>
                {exercise.likes}
              </TextSmall>
            )}
          </Column>
        </DarkRow>
        {exercise.type !== 'free' && (
          <PremiumOverlay hasPremium={hasPremiumExercises} />
        )}
      </Column>
    </DarkColumn>
  );
});

Exercise.displayName = 'Exercise';
