import { Column, Text } from 'native-base';
import { DarkRow } from 'components/layout/views';
import { IConversationLastMessage } from 'src/@types/api';

type Props = {
  data: IConversationLastMessage;
};

export const MessageNotification = ({ data }: Props) => {
  return (
    <DarkRow
      space={6}
      p={4}
      borderRadius={5}
      alignItems="center"
      minW={250}
      shadow={1}
    >
      {/*<ProfileImage size="40px" uri={data.senderPhotoUrl} />*/}
      <Column>
        {/*<Text bold>{data.title}</Text>*/}
        <Text>{data.text}</Text>
      </Column>
    </DarkRow>
  );
};
