import { useRecoilValue } from 'recoil';
import { userPremiumState } from '../state/state';
import { useMemo } from 'react';
import { PremiumType } from '../@types/enums';

export const useIsPremium = () => {
  const premiumRole = useRecoilValue(userPremiumState);

  const hasPremium = useMemo(() => {
    return premiumRole === PremiumType.Premium;
  }, [premiumRole]);

  const hasPremiumExercises = useMemo(() => {
    return (
      premiumRole === PremiumType.Premium ||
      premiumRole === PremiumType.Exercises
    );
  }, [premiumRole]);

  const hasPremiumMuscles = useMemo(() => {
    return (
      premiumRole === PremiumType.Premium || premiumRole === PremiumType.Muscles
    );
  }, [premiumRole]);

  const hasSubscription = useMemo(() => {
    return premiumRole !== undefined;
  }, [premiumRole]);

  return {
    hasPremium,
    hasPremiumExercises,
    hasPremiumMuscles,
    hasSubscription,
    premiumRole,
  };
};
