import { IModalProps } from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextLight } from 'components/typography/text';
import { Modal } from 'components/modals/Modal';
import { HeadingSecondary } from 'components/typography/heading';
import { TextInput } from 'components/form/TextInput';
import { DarkColumn } from 'components/layout/views';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import { updateUser } from '../../firebase/user';
import { useAuthUser } from '../../state/state';

type Props = IModalProps;

export const UserNameModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const [name, setName] = useState(user?.name || '');

  const onSave = () => {
    updateUser({ name, uid: user?.uid });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} p={0} margin={48} type="center">
      <DarkColumn p={6} flex={1} borderRadius={8} space={6} alignItems="center">
        <HeadingSecondary>{t('dashboard.nameTitle')}</HeadingSecondary>
        <TextLight textAlign="center">{t('dashboard.nameSubtitle')}</TextLight>
        <TextInput
          w="100%"
          value={name}
          onChangeText={setName}
          placeholder={t('dashboard.namePlaceholder')}
          autoFocus
          onSubmitEditing={onSave}
        />
        <PrimaryButton
          isDisabled={name.length < 3}
          mt={4}
          w="full"
          onPress={onSave}
        >
          {t('general.save')}
        </PrimaryButton>
      </DarkColumn>
    </Modal>
  );
};
