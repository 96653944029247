import { Column, useBreakpointValue } from 'native-base';
import { Routes } from 'src/navigation/routes';
import { useRecoilState } from 'recoil';
import { currentRouteState } from 'src/state/state';
import { Menu } from 'components/layout/Menu';
import { AppWebStack } from 'src/navigation/AppWebStack';
import { SmallMenu } from 'components/layout/SmallMenu';
import * as React from 'react';
import { MENU_WIDTH } from 'src/theme/constants';

const HIDE_MENU_ROUTES: string[] = [Routes.PrivacyPolicy, Routes.TermsOfUse];

export const WebLayout = () => {
  const [route] = useRecoilState(currentRouteState);
  const showSmallMenu = useBreakpointValue([true, true, false]);
  const showWebMenu = !HIDE_MENU_ROUTES.includes(route || '');
  return (
    <>
      {showWebMenu && !showSmallMenu && <Menu />}
      <Column flex={1} pl={showSmallMenu || !showWebMenu ? 0 : MENU_WIDTH}>
        <Column flex={1} minH="100vh">
          <AppWebStack />
        </Column>
        {showWebMenu && showSmallMenu && <SmallMenu />}
      </Column>
    </>
  );
};
