import { useFavoriteExercise } from 'src/hooks/useFavoriteExercise';
import { ExercisesList } from 'components/exercises/ExercisesList';
import { useTranslation } from 'react-i18next';

export const FavoritesScreen = () => {
  const { t } = useTranslation();

  const { favoriteExercises } = useFavoriteExercise();
  return (
    <ExercisesList
      exercises={favoriteExercises}
      title={t('routes.favorites')}
      filtersCount={0}
    />
  );
};
