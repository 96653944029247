import { Row } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { Routes } from 'src/navigation/routes';
import { useAuthUser } from 'src/state/state';
import { IConversation } from 'src/@types/api';
import { ConversationsStackScreenProps } from 'src/navigation/types/conversationStack';
import { ProfileImage } from 'components/ProfileImage';
import { useConversations } from 'src/hooks/useConversations';
import { Threads } from 'components/chat/Threads';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { AppWebStackParamList } from 'src/navigation/types/appWebStack';

type Props = ConversationsStackScreenProps<Routes.ConversationThreads>;

export const ConversationThreadsScreen = ({ navigation, route }: Props) => {
  const { conversationId } = route.params;
  const user = useAuthUser();
  const { getConversationById } = useConversations();
  const [conversation, setConversation] = useState<IConversation>();

  useEffect(() => {
    getConversationById(conversationId).then(setConversation);
  }, [conversationId, getConversationById]);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <Row space={1}>
          {conversation?.participants
            .filter(({ uid }) => uid !== user?.uid)
            .map(({ photoUrl }) => (
              <ProfileImage key={photoUrl} size={'30px'} uri={photoUrl} />
            ))}
        </Row>
      ),
    });
  }, [conversation?.participants, navigation, user?.uid]);

  const getLink = useCallback(
    (conversationId: string, threadId: string): To<AppWebStackParamList> => {
      return {
        screen: Routes.Messages,
        params: { conversationId, threadId },
      };
    },
    []
  );

  return <Threads conversationId={conversationId} getLink={getLink} />;
};
