import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { ScreenContent } from 'components/layout/ScreenContent';
import { Column, IStackProps, useBreakpointValue } from 'native-base';
import { isIOS, isWeb } from 'src/utils/constants';
import { CONTENT_MAX_WIDTH } from 'src/theme/constants';
import { useMenuDimensions } from 'src/hooks/useMenuDimensions';
import { Footer } from 'components/layout/Footer';

export type ScreenProps = IStackProps & {
  withScroll?: boolean;
  loading?: boolean;
  transparentHeader?: boolean;
  floatingComponent?: React.ReactNode;
  title?: string;
  rightButton?: React.ReactNode;
  mainButton?: React.ReactNode;
  scrollEnabled?: boolean;
  withFooter?: boolean;
};

export const Screen = ({
  children,
  loading,
  transparentHeader = isIOS,
  withScroll = true,
  floatingComponent,
  title,
  rightButton,
  mainButton,
  scrollEnabled,
  withFooter,
  ...props
}: ScreenProps) => {
  const paddingHorizontal = useBreakpointValue([4, 4, 8]);
  const { paddingBottom } = useMenuDimensions();

  const content = (
    <ScreenContent
      transparentHeader={transparentHeader}
      loading={loading}
      title={title}
      rightButton={rightButton}
      mainButton={mainButton}
    >
      {children}
    </ScreenContent>
  );

  if (withScroll) {
    return (
      <>
        <KeyboardAwareScrollView
          scrollEnabled={scrollEnabled}
          keyboardShouldPersistTaps="handled"
          overScrollMode="never"
          contentContainerStyle={{
            flexGrow: 1,
          }}
        >
          <Column
            maxWidth={CONTENT_MAX_WIDTH}
            mx="auto"
            w="full"
            px={paddingHorizontal}
            py={4}
            pb={`${paddingBottom + 16}px`}
            _web={{
              minH: '100vh',
            }}
            minH="100%"
            flexGrow={1}
            {...props}
          >
            {content}
          </Column>
          {withFooter && isWeb && <Footer />}
        </KeyboardAwareScrollView>
        {floatingComponent}
      </>
    );
  } else {
    return (
      <>
        <Column w="full" flex={1} {...props}>
          {content}
        </Column>
        {floatingComponent}
      </>
    );
  }
};
