import { Divider, IModalProps, Pressable, Row, ScrollView } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextLight } from 'components/typography/text';
import { Modal } from 'components/modals/Modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FiltersType } from '../../@types/enums';
import { FilterOptions } from 'components/modals/FilterOptions';

type Props = IModalProps & {
  selectedFilters: Record<string, boolean>;
  setSelectedFilters: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  filtersType: FiltersType;
};
export const FiltersModal = ({
  isOpen,
  onClose,
  selectedFilters,
  setSelectedFilters,
  filtersType,
}: Props) => {
  const { t } = useTranslation();
  const { bottom } = useSafeAreaInsets();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      containerHeight="90%"
      px={0}
      space={0}
    >
      <ScrollView contentContainerStyle={{ padding: 16 }}>
        <FilterOptions
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          filtersType={filtersType}
        />
      </ScrollView>
      <Divider />
      <Row p={4} pb={bottom} justifyContent="space-between">
        <Pressable onPress={() => setSelectedFilters({})}>
          <TextLight>{t('filter.clear')}</TextLight>
        </Pressable>
        <Pressable onPress={onClose}>
          <TextLight>{t('general.close')}</TextLight>
        </Pressable>
      </Row>
    </Modal>
  );
};
