import {
  arrayRemove,
  arrayUnion,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { firestore } from 'src/firebase/init';
import { IFriend, IUser, IUsersRelationships } from 'src/@types/api';
import { EGender } from 'src/@types/enums';
import {
  relationshipsCollection,
  unreadMessagesRef,
  userRef,
  usersCollection,
  usersRelationshipsRef,
} from 'src/firebase/refs';
import { RECOVERY_TOM_EMAIL } from './constants';

export const fetchUser = async (id: string) => {
  const userDataSnap = await getDoc<IUser>(userRef(id));
  return userDataSnap.data();
};

export const onFetchUser = (
  id: string,
  callback: (user: IUser | undefined) => void
) => {
  return onSnapshot(userRef(id), (userSnapshot) =>
    callback(userSnapshot.data())
  );
};

export const onFetchUserRelationships = (
  id: string,
  callback: (user: IUsersRelationships | undefined) => void
) => {
  return onSnapshot(usersRelationshipsRef(id), (userSnapshot) =>
    callback(userSnapshot.data())
  );
};

export const createUser = async (
  uid: string,
  name: string,
  email: string,
  photoUrl: string
) => {
  const batch = writeBatch(firestore);

  const recoveryTomQuery = query(
    usersCollection,
    where('email', '==', RECOVERY_TOM_EMAIL)
  );
  const recoveryTomSnap = await getDocs(recoveryTomQuery);
  const recoveryTomAccount = recoveryTomSnap.docs[0]?.data();
  const newUser: IUser = {
    uid,
    name: name || '',
    email,
    photoUrl,
    favorites: [],
    favoriteMuscles: [],
    favoriteProblems: [],
    gender: EGender.neutral,
  };
  batch.set(userRef(uid), newUser);
  const relationShips: IUsersRelationships = {
    uid,
    name,
    photoUrl,
    email,
    sentFriendRequests: [],
    pendingFriendRequests: [],
    friends: recoveryTomAccount
      ? [
          {
            uid: recoveryTomAccount.uid,
            name: recoveryTomAccount.name,
            photoUrl: recoveryTomAccount.photoUrl,
            email: recoveryTomAccount.email,
          },
        ]
      : [],
  };
  batch.set(usersRelationshipsRef(uid), relationShips);
  const friend = {
    uid,
    name,
    photoUrl,
    email,
  };
  batch.update(usersRelationshipsRef(recoveryTomAccount?.uid), {
    friends: arrayUnion(friend),
  });
  const unreadDoc = unreadMessagesRef(uid);
  batch.set(unreadDoc, {});

  return batch.commit();
};

export const addFavoriteMuscle = async (userId: string, muscleId: string) => {
  return updateDoc(userRef(userId), {
    favoriteMuscles: arrayUnion(muscleId),
  });
};

export const removeFavoriteMuscle = async (
  userId: string,
  muscleId: string
) => {
  return updateDoc(userRef(userId), {
    favoriteMuscles: arrayRemove(muscleId),
  });
};

export const addFavoriteProblem = async (userId: string, problemId: string) => {
  return updateDoc(userRef(userId), {
    favoriteProblems: arrayUnion(problemId),
  });
};

export const removeFavoriteProblem = async (
  userId: string,
  problemId: string
) => {
  return updateDoc(userRef(userId), {
    favoriteProblems: arrayRemove(problemId),
  });
};

export const addMasteredExercise = async (
  userId: string,
  exerciseId: string
) => {
  return updateDoc(userRef(userId), {
    masteredExercises: arrayUnion(exerciseId),
  });
};

export const removeMasteredExercise = async (
  userId: string,
  exerciseId: string
) => {
  return updateDoc(userRef(userId), {
    masteredExercises: arrayRemove(exerciseId),
  });
};

export const sendFriendRequest = async (user: IUser, email: string) => {
  const q = query<IUsersRelationships>(
    relationshipsCollection,
    where('email', '==', email)
  );
  const otherUsers = await getDocs<IUsersRelationships>(q);
  if (otherUsers.empty) {
    throw new Error('No user found');
  }
  const batch = writeBatch(firestore);
  otherUsers.forEach((userSnapshot) => {
    const userData = userSnapshot.data();

    if (userData.uid !== user.uid) {
      batch.update(usersRelationshipsRef(user.uid), {
        sentFriendRequests: arrayUnion({
          uid: userData.uid,
          name: userData.name,
          photoUrl: userData.photoUrl,
          email: userData.email,
        }),
      });

      batch.update(usersRelationshipsRef(userData.uid), {
        pendingFriendRequests: arrayUnion({
          uid: user.uid,
          name: user.name,
          photoUrl: user.photoUrl,
          email: user.email,
        }),
      });
    }
  });
  return batch.commit();
};

export const acceptFriendRequest = async (user: IUser, friend: IFriend) => {
  const batch = writeBatch(firestore);
  const me = {
    uid: user.uid,
    name: user.name,
    photoUrl: user.photoUrl,
    email: user.email,
  };

  batch.update(usersRelationshipsRef(user.uid), {
    pendingFriendRequests: arrayRemove(friend),
    friends: arrayUnion(friend),
  });
  batch.update(usersRelationshipsRef(friend.uid), {
    sentFriendRequests: arrayRemove(me),
    friends: arrayUnion(me),
  });
  return batch.commit();
};

export const declineFriendRequest = async (user: IUser, friend: IFriend) => {
  const batch = writeBatch(firestore);
  const me = {
    uid: user.uid,
    name: user.name,
    photoUrl: user.photoUrl,
    email: user.email,
  };

  batch.update(usersRelationshipsRef(user.uid), {
    pendingFriendRequests: arrayRemove(friend),
  });
  batch.update(usersRelationshipsRef(friend.uid), {
    sentFriendRequests: arrayRemove(me),
  });
  return batch.commit();
};

export const deleteSentRequest = async (user: IUser, friend: IFriend) => {
  const batch = writeBatch(firestore);
  const me = {
    uid: user.uid,
    name: user.name,
    photoUrl: user.photoUrl,
    email: user.email,
  };

  batch.update(usersRelationshipsRef(user.uid), {
    sentFriendRequests: arrayRemove(friend),
  });
  batch.update(usersRelationshipsRef(friend.uid), {
    pendingFriendRequests: arrayRemove(me),
  });
  return batch.commit();
};

export const removeFriend = async (user: IUser, friend: IFriend) => {
  const friendDoc = await getDoc(userRef(friend.uid));
  const batch = writeBatch(firestore);

  batch.update(usersRelationshipsRef(user.uid), {
    friends: arrayRemove(friend),
  });
  if (friendDoc.exists()) {
    batch.update(usersRelationshipsRef(friend.uid), {
      friends: arrayRemove({
        uid: user.uid,
        name: user.name,
        photoUrl: user.photoUrl,
        email: user.email,
      }),
    });
  }
  return batch.commit();
};

export const updateUser = (user: Partial<IUser>) => {
  return updateDoc(userRef(user.uid || ''), user);
};

export const deleteUserFromFriends = async (user: IUser) => {
  const batch = writeBatch(firestore);
  const me = {
    uid: user.uid,
    name: user.name,
    photoUrl: user.photoUrl,
    email: user.email,
  };
  const friendsQuery = query(
    relationshipsCollection,
    where('friends', 'array-contains', me)
  );
  const relationshipsSnap = await getDocs(friendsQuery);
  const friends = relationshipsSnap.docs.map((doc) => doc.data());
  friends.forEach((friend) => {
    batch.update(usersRelationshipsRef(friend.uid), {
      friends: arrayRemove(me),
    });
  });
  const sentRequestsQuery = query(
    relationshipsCollection,
    where('sentFriendRequests', 'array-contains', me)
  );
  const sentRequestsSnap = await getDocs(sentRequestsQuery);
  const sentRequests = sentRequestsSnap.docs.map((doc) => doc.data());
  sentRequests.forEach((friend) => {
    batch.update(usersRelationshipsRef(friend.uid), {
      sentFriendRequests: arrayRemove(me),
    });
  });
  const pendingRequestsQuery = query(
    relationshipsCollection,
    where('pendingFriendRequests', 'array-contains', me)
  );
  const pendingRequestsSnap = await getDocs(pendingRequestsQuery);
  const pendingRequests = pendingRequestsSnap.docs.map((doc) => doc.data());
  pendingRequests.forEach((friend) => {
    batch.update(usersRelationshipsRef(friend.uid), {
      pendingFriendRequests: arrayRemove(me),
    });
  });
  return batch.commit();
};
