import React from 'react';
import { Row, useColorMode } from 'native-base';
import Animated, { SlideInLeft, SlideInRight } from 'react-native-reanimated';
import { IMessage } from 'src/@types/api';
import { useAuthUser } from 'src/state/state';
import { Colors } from 'src/theme/colors';
import { ProfileImage } from 'components/ProfileImage';
import { MessageText } from 'components/chat/MessageText';

type Props = {
  reply: IMessage;
  photoUrl?: string;
};

export const MessageMemo = ({ reply, photoUrl }: Props) => {
  const user = useAuthUser();
  const { colorMode } = useColorMode();
  const isMine = reply.senderUid === user?.uid;

  return (
    <Animated.View
      style={{
        flex: 1,
      }}
      entering={isMine ? SlideInRight : SlideInLeft}
    >
      <Row
        w="full"
        alignItems="center"
        justifyContent={isMine ? 'flex-end' : 'flex-start'}
        space={2}
      >
        {photoUrl && !isMine && <ProfileImage size="30px" uri={photoUrl} />}
        <Row
          w="80%"
          my={1}
          py={2}
          p={4}
          borderRadius={10}
          space={1}
          bg={
            isMine
              ? Colors.secondary
              : colorMode === 'light'
              ? Colors.bgWhite
              : Colors.bgDark
          }
        >
          <MessageText textAlign={isMine ? 'right' : 'left'}>
            {reply.text}
          </MessageText>
        </Row>
      </Row>
    </Animated.View>
  );
};

export const Message = React.memo(MessageMemo);
