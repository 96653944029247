import * as React from 'react';
import { NativeBaseProvider } from 'native-base';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { useCustomFonts } from 'src/hooks/useCustomFonts';
import { theme } from 'src/theme/theme';
import { GlobalStyle } from 'src/theme/global';
import { isWeb } from 'src/utils/constants';
import { RecoilRoot } from 'recoil';
import { ToastProvider } from 'react-native-toast-notifications';
import { MessageNotification } from 'components/MessageNotification';
import { Navigation } from 'src/navigation/Navigation';
import * as SplashScreen from 'expo-splash-screen';
import 'src/i18n';
import { colorModeManager } from './src/theme/colorModeManager';

SplashScreen.preventAutoHideAsync();

export default function App() {
  const isLoadingComplete = useCustomFonts();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        {isWeb && <GlobalStyle />}
        <NativeBaseProvider theme={theme} colorModeManager={colorModeManager}>
          <RecoilRoot>
            <ToastProvider
              placement="top"
              offsetTop={60}
              renderToast={(options) => (
                <MessageNotification data={options.data} />
              )}
            >
              <Navigation />
            </ToastProvider>
          </RecoilRoot>
        </NativeBaseProvider>
      </SafeAreaProvider>
    );
  }
}
