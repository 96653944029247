import { Center, ICenterProps, Text } from 'native-base';
import { Colors } from 'src/theme/colors';

type Props = ICenterProps & {
  value?: string | number;
};

export const MenuBadge = ({ value, ...props }: Props) => {
  return value ? (
    <Center
      bg="red.200"
      p={1.5}
      minW="20px"
      h="20px"
      borderRadius={20}
      {...props}
    >
      <Text fontSize={11} color={Colors.badge} fontWeight="600">
        {value}
      </Text>
    </Center>
  ) : null;
};
