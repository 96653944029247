import { IProblemsConfig } from 'src/@types/types';

export const problemsConfigSk: IProblemsConfig = {
  rhomboidSpasm: {
    id: 'rhomboidSpasm',
    name: 'Spasmus na Rhomboide pri lopatke',
    description:
      'Spazmus na rhomboideusoch je stav, ktorý sa vyskytuje, keď svaly rhomboideus major a rhomboideus minor, ktoré sa nachádzajú medzi lopatkami a stavcami, sú príliš stiahnuté alebo napnuté. Tento problém môže mať niekoľko príčin a môže mať rôzne dôsledky na pohyb a pohodlie. Niektoré z možných príčin spazmu na rhomboidoch zahŕňajú:',
    joints: ['neck', 'spineMid', 'scapula', 'cervicalSpine', 'thoracicSpine'],
    keywords: ['ordinary', 'upperBody'],
    causes: [
      'Nadmerné zaťaženie a opakované pohyby: Opakované pohyby alebo nadmerné zaťaženie sú bežnými príčinami spazmu svalov. Napríklad opakované zdvíhanie ťažkých predmetov, opakované pohyby rúk a ramien, alebo nevhodné držanie tela môžu spôsobiť preťaženie a svalový spazmus v oblasti rhomboidov.',
      'Zlá postura: Nezdravá postura, ako napríklad predsunutie hlavy, zaoblenie horného chrbta (hrb) alebo skrčené ramená, môže vytvoriť nerovnováhu svalov a spôsobiť svalový spazmus v oblasti rhomboidov.',
      'Zranenia: Úrazy, ako napríklad nárazy, pády alebo zranenia v oblasti chrbta a lopatiek, môžu spôsobiť spazmus na rhomboidoch.',
      'Stres a napätie: Emocionálny stres a napätie môžu sa prejaviť aj vo fyzickom napätí svalov a prispieť k vzniku spazmu na rhomboidoch.',
    ],
    footer:
      'Príznaky spazmu na rhomboidoch môžu zahŕňať bolesť, napätie a obmedzenie pohybu v oblasti medzi lopatkami a chrbticou. Môžu sa tiež objaviť bolesti hlavy a ramien, ako aj nepríjemné pocity v oblasti lopatiek.' +
      '\n' +
      'Liečba spazmu na rhomboidoch závisí od presnej príčiny a závažnosti problému. Odporúča sa konzultovať s odborníkom na fyzioterapiu alebo rehabilitáciu, ktorý môže poskytnúť vhodné terapeutické postupy, vrátane cvičení na posilnenie a uvoľnenie svalov v oblasti rhomboidov. Masáže, tepelná terapia, protizápalové lieky a techniky na zlepšenie držania tela môžu byť tiež súčasťou liečebného plánu.',
  },
  forwardHead: {
    id: 'forwardHead',
    name: 'Predsunutá hlava',
    description:
      'V tomto prípade je hlava posunutá dopredu vzhľadom na normálnu neutrálnu polohu. Tento posturálny problém môže mať viaceré dopady na držanie tela:',
    joints: ['neck', 'spineMid', 'scapula', 'clavicle', 'cervicalSpine'],
    keywords: ['ordinary', 'upperBody'],
    causes: [
      'Môže spôsobiť nerovnováhu a narušiť optimálnu zarovnanie chrbtice. Keď je hlava posunutá dopredu, svaly v oblasti krku a ramien musia pracovať tvrdšie, aby udržali rovnováhu a podporili hlavu. To môže viesť k nezdravému držaniu tela, ako je zaoblený horný chrbát (hrb), skrčené ramená a predklonené horné telo.',
      'Môže mať vplyv na biomechaniku chrbtice. Tým, že je ťažisko hlavy posunuté dopredu, sa zvyšuje zaťaženie na krčnú chrbticu a hornú časť chrbtice. To môže viesť k zvýšenému riziku bolesti a nepohodlia v tejto oblasti.',
      'Môže mať negatívny vplyv na dýchanie. Nezdravé držanie hlavy a horného tela môže obmedziť plné dýchanie, čo môže mať vplyv na kvalitu dýchania a celkovú funkciu pľúc.',
      'Tento posturálny problém môže tiež ovplyvniť svalovú rovnováhu v krku, ramenách a chrbte. Niektoré svaly sa môžu predlžovať a oslabovať, zatiaľ čo iné sa môžu skracovať a stiahnuť. Toto nerovnovážne napätie svalov môže viesť k nepríjemnostiam, bolestiam a obmedzeniu pohybu.',
    ],
    footer:
      'Ak máte podozrenie, že trpíte predsunutou hlavou a jej dopadom na držanie tela, odporúča sa konzultovať s odborníkom na posturálnu terapiu alebo fyzioterapeutom. Tí vám môžu poskytnúť vhodné cvičenia a techniky na korekciu držania tela a posilnenie svalov, aby sa zlepšila postura a minimalizovali príznaky spojené s predsunutou hlavou. Vyskúšajte preto rôzne strečovacie cviky spojené s týmto problémom a teda cviky na zamerané na krk.',
  },
  midBackPain: {
    id: 'midBackPain',
    name: 'Medzilopatková bolesť',
    description:
      'Bolesť v strede chrbta medzi lopatkami je častou sťažnosťou, ktorá môže byť spôsobená rôznymi faktormi. Niektoré z možných príčin zahŕňajú:',
    joints: ['cervicalSpine', 'scapula', 'spineMid'],
    keywords: ['ordinary', 'upperBody'],
    causes: [
      'Svalové napätie: Nadmerné napätie svalov v oblasti stredného chrbta medzi lopatkami môže viesť k bolesti. To môže byť spôsobené napríklad dlhým sedením v nevhodnej pozícii, zlým držaním tela alebo nadmerným zaťažením svalov.',
      'Zápal medzistavcovej platničky: Zápal alebo podráždenie medzistavcovej platničky v blízkosti stredného chrbta môže spôsobiť bolesť medzi lopatkami.',
      'Osteoartróza: Degeneratívne ochorenie kĺbov, ako napríklad osteoartróza, môže postihnúť stavce v oblasti stredného chrbta a spôsobiť bolesť.',
      'Zranenia: Úrazy, ako napríklad pády, nárazy alebo zranenia pri športe, môžu mať za následok bolesť v tejto oblasti.',
      'Stres a napätie: Psychický stres a napätie môžu sa prejaviť aj ako bolesť v strednej časti chrbta medzi lopatkami.',
    ],
    footer:
      'Je dôležité vyhľadať lekára alebo fyzioterapeúta, ak trpíte bolesťou v tejto oblasti, najmä ak je intenzívna, trvá dlhší čas alebo je spojená s ďalšími príznakmi. Lekár môže vykonať dôkladné vyšetrenie a prípadne odporučiť ďalšie diagnostické testy, ako napríklad röntgenové snímky alebo magnetickú rezonanciu, a navrhnúť vhodnú liečbu, ktorá zahŕňa odpočinok, fyzioterapiu, protizápalové lieky alebo iné terapeutické metódy. Ak dostanete odporúčanie na fyzioterapeutickú liečbu, skúste rôzne strečovacie cviky spojené s týmto problémom.',
  },
  lowBackPain: {
    id: 'lowBackPain',
    name: 'Bolesť v spodnom chrbáte',
    description:
      'Bolesť v spodnej časti chrbta, teda v oblasti spodnej chrbtice, je pomerne bežným problémom, ktorý môže mať niekoľko príčin. Niektoré z možných príčin bolesti v tejto oblasti sú:',
    joints: ['spineBot'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      'Svalové napätie a nadmerné zaťaženie: Nadmerné zaťaženie svalov v spodnej časti chrbta, ako napríklad zdvíhanie ťažkých predmetov, dlhé sedenie v nevhodnej pozícii alebo nevhodné držanie tela, môže spôsobiť svalové napätie a bolesť v tejto oblasti.',
      'Ischias: Ischias je stav, pri ktorom je stlačený alebo podráždený ischiatický nerv, ktorý sa nachádza v dolnej časti chrbtice. To môže spôsobiť bolesť, necitlivosť a brnenie v spodnej časti chrbta až do dolných končatín.',
      'Hernia medzistavcovej platničky: Pri hernii platničky sa vnútorná časť medzistavcovej platničky vyhĺbi do priestoru medzi stavcami a môže tlačiť na okolité nervy, čo spôsobuje bolesť v spodnej časti chrbta.',
      'Zápalové ochorenia: Zápalové ochorenia, ako napríklad spondylitída alebo artritída, môžu postihnúť stavce v spodnej časti chrbtice a spôsobiť bolesť a zápal.',
      'Zlomeniny a zranenia: Zlomeniny stavcov alebo iné zranenia spodnej chrbtice môžu mať za následok bolesť v tejto oblasti.',
    ],
    footer:
      'Ak trpíte bolesťou v spodnej časti chrbta, je vhodné konzultovať to s lekárom, aby sa zistila presná príčina bolesti a navrhla vhodná liečba. To môže zahŕňať odpočinok, fyzioterapiu, protizápalové lieky, injekcie alebo iné metódy liečby, v závislosti od závažnosti a príčiny bolesti. Ak dostanete odporúčanie na fyzioterapeutickú liečbu, skúste rôzne strečovacie cviky spojené s týmto problémom.',
  },
  elbowPain: {
    id: 'elbowPain',
    name: 'Bolesť v lakti',
    description:
      'Bolesť v lakťovom kĺbe môže byť veľmi nepríjemná a obmedzujúca. Existuje niekoľko častých príčin bolesti v lakti:',
    joints: ['elbow'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      'Tenisový lakť: Tenisový lakť, známy aj ako laterálna epikondylitída, je zápal alebo podráždenie šliach v okolí vonkajšej časti lakťa. Táto podmienka môže byť spôsobená nadmerným opakovaným zaťažením ruky a predlaktia, nielen pri hraní tenisu.',
      'Golferský lakť: Golferský lakť, známy aj ako medzištýlová epikondylitída, je podobnou podmienkou ako tenisový lakť, ale postihuje vnútornú časť lakťa. Opäť môže byť spôsobený opakovaným zaťažením a pohybmi zahŕňajúcimi ohyb a svaly predlaktia.',
      'Nadmerné zaťaženie: Opakované pohyby, ako napríklad zdvíhanie ťažkých predmetov, opakovane opakované pohyby ruky alebo monotónna činnosť, môžu spôsobiť nadmerné zaťaženie šliach a tkanív v lakti, čo vedie k bolesti.',
      'Trauma alebo poranenie: Úrazy ako pády, nárazy do lakťa alebo zranenia pri športe môžu spôsobiť bolesť v lakti. To môže zahŕňať aj zlomeniny kostí v oblasti lakťa.',
      'Zápalové ochorenia: Niektoré zápalové ochorenia, ako napríklad revmatoidná artritída alebo lupus, môžu postihovať aj kĺby vrátane lakťa, čo vedie k bolesti.',
    ],
    footer:
      'Ak trpíte bolesťou v lakti, odporúča sa vyhľadať lekára, ktorý môže vykonať dôkladné vyšetrenie a poskytnúť vhodnú liečbu. Môže to zahŕňať odpočinok, fyzioterapiu, protizápalové lieky, injekcie alebo iné metódy liečby, v závislosti od presnej príčiny bolesti. Ak dostanete odporúčanie na fyzioterapeutickú liečbu, skúste rôzne strečovacie cviky spojené s týmto problémom.',
  },
  footPain: {
    id: 'footPain',
    name: 'Bolesť v chodidle',
    description:
      'Problémy s chodidlom sú bežným problémom, ktorý môže ovplyvniť pohybový aparát a celkovú pohodu. Tu je niekoľko problémov s chodidlom a ich možné príčiny:',
    joints: ['foot', 'ankle'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      'Ploché nohy: Ploché nohy, známe aj ako "pes planus", sú charakterizované znížením alebo úplnou stratou oblúka chodidla. Môže to spôsobiť bolesť a nepríjemné pocity v chodidle a následne ovplyvniť postavu a pohyb.',
      'Členková inverzia: Členková inverzia je stav, pri ktorom sa členok natočí smerom dovnútra. Toto môže spôsobiť nestabilitu členku a nepohodlie pri chôdzi alebo pri športe.',
      'Plantárna fascitída: Plantárna fascitída je zápal plantárnej fascie, tenkej pásomnatej štruktúry, ktorá sa nachádza na spodnej strane chodidla. Je charakterizovaná bolesťou na päte alebo v oblasti pod pätou, najmä pri prvých krokoch ráno alebo po dlhšom odpočinku.',
      'Mozol: Mozol je hrubá a tvrdá vrstva kože, ktorá sa vytvára na miestach opakovaného trenia alebo tlaku na chodidle. Môže to spôsobovať bolesť a nepríjemnosť pri chôdzi.',
      'Chodidlové vredy: Chodidlové vredy sú bolestivé otvory v koži, ktoré sa môžu vyvinúť na chodidle v dôsledku dlhodobého tlaku, zranenia alebo problémov s obehom.',
      'Chodidlové deformity: Chodidlové deformity, ako napríklad halux valgus (vaľgózna deformita veľkého palca) alebo garbusovité prsty, môžu mať vplyv na polohu a funkciu chodidla a spôsobiť bolesť alebo nepríjemnosti.',
    ],
    footer:
      'Liečba problémov s chodidlom závisí od konkrétneho problému a jeho závažnosti. Môže zahŕňať nosenie vhodnej obuvi, používanie vložiek alebo ortéz, fyzioterapiu, cvičenia na posilnenie svalov chodidla a nôh, masáže, ako aj v niektorých prípadoch lieky proti bolesti a zápalu. V prípade závažných problémov môže byť potrebný chirurgický zákrok.' +
      '\n' +
      'Je dôležité konzultovať s lekárom alebo odborníkom na nohy, ako je ortopéd alebo fyzioterapeut, aby sa presne zistila príčina problému s chodidlom a navrhla vhodná liečba a opatrenia na prevenciu.',
  },
  hipPain: {
    id: 'hipPain',
    name: 'Bolesť v bedre',
    description:
      'Problémy spojené s bedrom môžu byť rôznorodé a ovplyvňovať pohybový aparát a celkovú pohodu. Tu je niekoľko častých problémov spojených s bedrom a ich možné príčiny:',
    joints: ['hip', 'pelvis', 'spineBot'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      'Bursitída bedrového kĺbu: Bursitída je zápal sliznice v oblasti bedrového kĺbu. Môže spôsobovať bolesť, opuch a obmedzenie pohybu v tejto oblasti. Príčiny bursitídy môžu zahŕňať opakované preťaženie, úrazy alebo zápalové stavy.',
      'Artritída bedrového kĺbu: Artritída je zápal kĺbu, ktorý môže postihnúť aj bedrový kĺb. Spôsobuje bolesť, stuhnutosť a obmedzenie pohybu. Príčiny artritídy môžu byť rôzne, vrátane degenerácie kĺbov, autoimunitných ochorení alebo traumatických zranení.',
      'Ischiatická neuralgia: Ischiatická neuralgia je stav, pri ktorom je stlačený alebo podráždený ischiatický nerv, ktorý sa nachádza v bedrovej oblasti. Spôsobuje bolesť, necitlivosť a brnenie, ktoré sa môže šíriť pozdĺž zadku až do dolných končatín.',
      'Svalové napätie a zranenia: Napätie svalov v bedrovej oblasti alebo zranenia, ako sú natiahnuté svaly, svalové kŕče alebo svalové svaly, môžu spôsobiť bolesť a obmedzenie pohybu v bedrovej oblasti.',
      'Hernia medzistavcovej platničky: Pri hernii medzistavcovej platničky sa vnútorná časť platničky vyhĺbi do priestoru medzi stavcami a môže tlačiť na okolité nervy, vrátane tých, ktoré prechádzajú cez bedrovú oblasť. To môže spôsobiť bolesť, necitlivosť a brnenie v bedrovej oblasti až do dolných končatín.',
    ],
    footer:
      'Liečba problémov spojených s bedrom závisí od presnej príčiny a závažnosti problému. Odporúča sa konzultovať s lekárom, ako je ortopéd alebo fyzioterapeut, ktorý môže vyhodnotiť váš stav a navrhnúť vhodnú liečbu. To môže zahŕňať fyzioterapiu, rehabilitačné cvičenia, analgetiká, protizápalov. V prípade fyzioterapeutickej liečby vyskúšajte strečingové cviky spojené s týmto problémom.',
  },
  aboveScapulaPain: {
    id: 'aboveScapulaPain',
    name: 'Bolesť nad lopatkou',
    description:
      'Bolesť v oblasti nad lopatkou môže byť spôsobená rôznymi faktormi. Tu sú niektoré z možných príčin tohto typu bolesti:',
    joints: ['scapula', 'spineMid', 'neck'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      'Svalové napätie: Nadmerné používanie svalov v oblasti nad lopatkou môže viesť k napätiu svalov, čo môže spôsobiť bolesť. Príčinou môže byť nadmerné zdvíhanie ťažkých predmetov, opakované pohyby rúk a ramien alebo nevhodná držba tela.',
      'Svalové kŕče: Svalové kŕče sú neočakávané a nekontrolovateľné zúženie svalov, ktoré môže spôsobiť bolesť. Môžu byť spôsobené dehydratáciou, nedostatkom minerálov, nadmerným stresom alebo nedostatočným rozcvičením svalov.',
      'Zápal svalu: Zápal svalu, ako je svalová tendinitída alebo svalová burzitída, môže spôsobiť bolesť v oblasti nad lopatkou. Tieto stavy môžu byť spôsobené opakujúcimi sa pohybmi, zraneniami alebo infekciou.',
      'Zranenie: Úrazy, ako napríklad pád, náraz alebo otras, môžu spôsobiť bolesť v oblasti nad lopatkou. Možné zranenia zahŕňajú svalové natiahnutie, podvrtnutie, modriny alebo zlomeniny kostí.',
      'Posturálne problémy: Nezdravá postura, ako je predsunutie hlavy, zaoblenie horného chrbta (hrb) alebo skrčené ramená, môže vytvoriť nerovnováhu svalov a spôsobiť bolesť v oblasti nad lopatkou.',
    ],
    footer:
      'Ak máte bolesť v oblasti nad lopatkou, je dôležité konzultovať s lekárom, aby sa presne určila príčina a zahájila vhodná liečba. Táto môže zahŕňať odpočinok, fyzioterapiu, cvičenia na posilnenie svalov a zlepšenie držby tela, lieky proti bolesti alebo iné liečebné metódy, ktoré sa prispôsobia vašej konkrétnej situácii. Ak je daný problém liečitelný fyzioterapiou, vyskúšajte príslušné strečingové cviky.',
  },
  shoulderPain: {
    id: 'shoulderPain',
    name: 'Bolesť v ramene',
    description:
      'Bolesť v ramene je častý problém, ktorý môže ovplyvňovať každodenný život a obmedzovať pohyb. Existuje mnoho možných príčin bolesti v ramene, vrátane nasledujúcich:',
    joints: ['scapula', 'spineMid', 'shoulder'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      'Rotátorská manžeta: Toto je skupina svalov a šliach, ktoré stabilizujú ramenný kĺb. Poranenie alebo nadmerné zaťaženie rotátorskej manžety môže viesť k bolesti v ramene.',
      'Tendinitída: Tendinitída je zápal šľachy v ramene, čo môže spôsobiť bolesť a obmedzenie pohybu.',
      'Bursitída: Bursitída je zápal vaky s tekutinou (bursa), ktorá sa nachádza v blízkosti ramenného kĺbu. Ak sa bursa zapáli, môže to spôsobiť bolesť v ramene.',
      'Impingement syndróm: Tento syndróm sa vyskytuje, keď sa priestor medzi kostmi a šľachami v ramene zužuje a spôsobuje tlak a trenie. To môže viesť k bolesti a obmedzeniu pohybu.',
      'Artritída: Artritída je zápal kĺbu a môže postihovať aj ramenný kĺb. Spôsobuje bolesť, opuch a obmedzenie pohybu.',
    ],
    footer:
      'Je dôležité si uvedomiť, že toto je len všeobecný prehľad možných príčin bolesti v ramene. Ak trpíte bolesťou v ramene, odporúča sa konzultovať s lekárom, ktorý vám môže poskytnúť presnú diagnózu a vhodnú liečbu.  Ak dostanete odporúčanie na fyzioterapeutickú liečbu, skúste rôzne strečovacie cviky spojené s týmto problémom.',
  },
};
