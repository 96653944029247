import { useTranslation } from 'react-i18next';
import React from 'react';
import { isWeb } from 'src/utils/constants';
import { Modal } from 'components/modals/Modal';
import {
  Column,
  Divider,
  IconButton,
  Row,
  ScrollView,
  useColorMode,
} from 'native-base';
import { HeadingTertiary } from 'components/typography/heading';
import { CrossIcon } from 'components/icons/icons';
import { Colors } from '../../theme/colors';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SubscriptionPlans } from 'components/subscription/SubscriptionPlans';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SubscriptionModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { top } = useSafeAreaInsets();
  const { colorMode } = useColorMode();
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      containerHeight={isWeb ? 780 : '100%'}
      p={0}
      space={0}
      type={isWeb ? 'center' : 'bottom'}
      width={isWeb ? 600 : '100%'}
    >
      <ScrollView
        contentContainerStyle={{
          padding: 16,
          paddingTop: isWeb ? 16 : top,
          paddingBottom: isWeb ? 16 : 40,
          backgroundColor:
            colorMode === 'light' ? Colors.bgWhite : Colors.black,
          flexGrow: 1,
        }}
      >
        <Column>
          <Row alignItems="center">
            <HeadingTertiary flex={1} textAlign="center">
              {t('subscription.title')}
            </HeadingTertiary>
            <IconButton
              icon={
                <CrossIcon
                  color={Colors.black}
                  _dark={{ color: Colors.bgWhite }}
                />
              }
              onPress={handleClose}
            />
          </Row>
          <Divider mt={1} mb={2} />
          <SubscriptionPlans />
        </Column>
      </ScrollView>
    </Modal>
  );
};
