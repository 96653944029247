import { useEffect, useState } from 'react';
import { useExercises } from 'src/hooks/useExercises';

export const useExerciseImageUrl = (exerciseId: string) => {
  const [uri, setUri] = useState<string>();
  const { getImageUrl } = useExercises();

  useEffect(() => {
    getImageUrl(exerciseId).then(setUri);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseId]);

  return uri;
};
