import { Center, Column, ICenterProps, Row, Text } from 'native-base';
import React from 'react';
import { IMuscle } from 'src/@types/api';
import { useTranslation } from 'react-i18next';
import { Colors } from 'src/theme/colors';

type Props = {
  muscle: IMuscle;
};

export const MuscleTable = ({ muscle }: Props) => {
  const { t } = useTranslation();

  return (
    <Column w="full">
      <MuscleTableRow
        title={t('muscles.origin')}
        value={muscle.origin.join('\n ')}
      />
      <MuscleTableRow
        title={t('muscles.insertion')}
        value={muscle.insertion.join('\n ')}
      />
      <MuscleTableRow
        title={t('muscles.innervation')}
        value={muscle.innervation}
      />
      <MuscleTableRow
        title={t('muscles.bloodSupply')}
        value={muscle.bloodSupply}
      />
      <MuscleTableRow
        title={t('muscles.function')}
        value={muscle.function.join('\n ')}
      />
    </Column>
  );
};

type RowProps = {
  title: string;
  value: string;
};

export const MuscleTableRow = ({ title, value }: RowProps) => {
  return (
    <Row w="full">
      <Cell flex={1 / 3} value={title} />
      <Cell value={value} />
    </Row>
  );
};

type CellProps = ICenterProps & {
  value: string;
};

export const Cell = ({ value, ...props }: CellProps) => {
  return (
    <Center
      p={4}
      borderColor={Colors.bgDark}
      _dark={{ borderColor: Colors.bgWhite }}
      borderWidth={1}
      flex={1}
      {...props}
    >
      <Text textAlign="center">{value}</Text>
    </Center>
  );
};
