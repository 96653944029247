export const requestPermissions = async () => {
  return null;
};

export const registerFCMToken = async (userId: string, tokens: string[]) => {
  return [userId, tokens];
};

export const unregisterFCMToken = async (userId: string) => {
  return userId;
};
