import React, { useCallback } from 'react';
import { FlatList, ListRenderItemInfo } from 'react-native';
import { ProblemPreview } from 'components/ProblemPreview';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { IProblem } from 'src/@types/api';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/modals/Modal';
import { isWeb } from '../../utils/constants';

type Props = {
  problems: IProblem[];
  title?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ProblemsBottomModal = ({
  isOpen,
  title,
  onClose,
  problems,
}: Props) => {
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation();

  const renderProblem = useCallback(
    ({ item, index }: ListRenderItemInfo<IProblem>) => (
      <ProblemPreview
        problem={item}
        flex={1 / 3}
        m={1}
        onPress={onClose}
        withOverlay={index >= 1}
        disabled={isWeb}
      />
    ),
    [onClose]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title || t('routes.problems')}
      px={0}
    >
      <FlatList
        data={problems}
        contentContainerStyle={{
          paddingHorizontal: 12,
          paddingBottom: bottom,
        }}
        renderItem={renderProblem}
        numColumns={3}
      />
    </Modal>
  );
};
