import { IExercise } from 'src/@types/api';
import { Column, Row, Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import { ILocalizedValue } from 'src/@types/types';
import { getLocalizedValue } from 'src/utils/utils';
import React from 'react';

type Props = {
  exercise: IExercise;
};

export const ExerciseDescription = ({ exercise }: Props) => {
  const { t } = useTranslation();

  return (
    <Column space={4}>
      <ExerciseSection
        emoji="☑️"
        title={t('exercise.check')}
        data={exercise.check}
      />
      <ExerciseSection
        emoji="🔔"
        title={t('exercise.subjects')}
        data={exercise.subjects}
        withNumbers
      />
      <ExerciseSection
        emoji="⚙️"
        title={t('exercise.positioning')}
        data={exercise.positioning}
        withNumbers
      />
      <ExerciseSection
        emoji="▶️"
        title={t('exercise.play')}
        data={exercise.play}
        end={`🔁 ${t('exercise.repeat')}`}
        withNumbers
      />
      <ExerciseSection
        emoji="📝️"
        title={t('exercise.notes')}
        data={exercise.notes}
        withNumbers
      />
    </Column>
  );
};

type SectionProps = {
  emoji: string;
  title: string;
  data: ILocalizedValue;
  end?: string;
  withNumbers?: boolean;
};

const ExerciseSection = ({
  emoji,
  title,
  data,
  end,
  withNumbers,
}: SectionProps) => {
  const text = getLocalizedValue(data);
  return (
    <Column space={1}>
      <Text bold>
        {emoji} {title}
      </Text>
      <Column>
        {text.split('\n').map((text, index) => (
          <Row key={text + index}>
            {withNumbers && (
              <Text bold ml={1}>
                {index + 1}.{' '}
              </Text>
            )}
            <Text flex={1}>{text}</Text>
          </Row>
        ))}
      </Column>
      <Text bold>{end}</Text>
    </Column>
  );
};
