import { Link } from 'components/links/Link';
import { Center, Divider, Row, Text, View } from 'native-base';
import React, { Fragment } from 'react';
import { LinkItem } from 'components/profile/types';
import { NumberBadge } from 'components/NumberBadge';
import { RightIcon } from 'components/icons/icons';

type Props = {
  item: LinkItem;
  showDivider?: boolean;
};

export const ProfileLink = ({ item, showDivider }: Props) => {
  return (
    <Fragment>
      <Link to={{ screen: item.target }}>
        <Row
          key={item.title}
          px={4}
          py={4}
          space={2}
          alignItems="center"
          w="full"
        >
          <Center>{item.icon}</Center>
          <View>
            <Text ml={1.5}>{item.title}</Text>
            <NumberBadge value={item.badgeNumber} right={-22} top={-6} />
          </View>
          <RightIcon ml="auto" light />
        </Row>
      </Link>
      {showDivider && <Divider />}
    </Fragment>
  );
};
