import { BACKDROP_OPACITY } from './constants';

export const Colors = {
  // primary
  primary: '#336bcc', // 'primary.500',
  primaryLight: '#477ad1', // 'primary.400',
  primaryDark: '#153568', // 'primary.700',

  // secondary
  secondary: '#00a8f3', // 'secondary.500',
  secondaryLight: '#abe8ff', // 'secondary.100',

  // background
  bgLight: '#f4f4f5', // gray.100
  bgWhite: '#ffffff', // 'light.50',
  bgDarkLight: '#2f2f2f',
  bgDark: '#222020',
  // bgDarker: '#151515',
  bgScreen: '#f2f2f2',
  bgHeaderDark: '#121212',
  blackOverlay: `rgba(0, 0, 0, ${BACKDROP_OPACITY})`,
  bgMuscle: '#bca48f',
  bgProblem: '#A9A9A9',
  bgSecondary: '#F1B808',

  // text
  text: '#1f2937',
  grayLight: '#a1a1aa', // gray.400
  grayLightest: '#e4e4e7', // gray.200
  blueLight: '#64748b', // blueGray.500
  blueLightest: '#cbd5e1', // blueGray.300

  // utils
  error: '#F43F5E', // error.500
  success: '#10B981', // success.500
  warning: '#F97316', // warning.500
  neutral: '#B2B2B2',
  black: '#000000',
  silver: '#C0C0C0',
  whitesmoke: '#f5f5f5',
  badge: '#b91c1c',
  premium: '#F1B808',
};
