import { extendTheme } from 'native-base';
import { Colors } from 'src/theme/colors';

const colors = {
  primary: {
    50: '#e6f0ff',
    100: '#bfd3f5',
    200: '#97b5e9',
    300: '#6f97dc',
    400: '#477ad1',
    500: '#336bcc',
    600: '#336bcc',
    700: '#153568',
    800: '#092041',
    900: '#000b1c',
  },
  secondary: {
    50: '#d8f9ff',
    100: '#abe8ff',
    200: '#7bd8ff',
    300: '#49c8ff',
    400: '#1ab8ff',
    500: '#00a8f3',
    600: '#007bb4',
    700: '#005882',
    800: '#003651',
    900: '#001421',
  },
};

export const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: true,
  },
  colors,
  fontConfig: {
    Roboto: {
      100: {
        normal: 'Roboto-Light',
        italic: 'Roboto-LightItalic',
      },
      200: {
        normal: 'Roboto-Light',
        italic: 'Roboto-LightItalic',
      },
      300: {
        normal: 'Roboto-Light',
        italic: 'Roboto-LightItalic',
      },
      400: {
        normal: 'Roboto-Regular',
        italic: 'Roboto-Italic',
      },
      500: {
        normal: 'Roboto-Medium',
        italic: 'Roboto-MediumItalic',
      },
      600: {
        normal: 'Roboto-Medium',
        italic: 'Roboto-MediumItalic',
      },
      700: {
        normal: 'Roboto-Bold',
        italic: 'Roboto-BoldItalic',
      },
    },
  },
  fonts: {
    heading: 'Roboto',
    body: 'Roboto',
    mono: 'Roboto',
  },
  shadows: {
    '0': {
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.1,
      shadowRadius: 1.0,
      elevation: 1,
    },
    '5': {
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.15,
      shadowRadius: 15,
    },
  },
  components: {
    Input: {
      defaultProps: {
        size: 'lg',
      },
    },
    FormControlLabel: {
      baseStyle: {
        _text: {
          fontSize: 'md',
        },
      },
    },
    Text: {
      baseStyle: {
        fontSize: 'md',
      },
    },
    Heading: {
      sizes: {
        lg: {
          fontSize: '3xl',
        },
      },
    },
    Button: {
      baseStyle: {
        _text: {
          letterSpacing: 'lg',
        },
      },
      defaultProps: {
        size: 'md',
      },
      sizes: {
        sm: {
          _text: {
            fontSize: 'sm',
          },
        },
        md: {
          _text: {
            fontSize: 'md',
          },
        },
      },
    },
    IconButton: {
      defaultProps: {
        borderRadius: 'full',
      },
    },
    Pressable: {
      baseStyle: {
        _pressed: {
          opacity: 0.2,
        },
      },
    },
    Spinner: {
      defaultProps: {
        size: 'lg',
      },
      baseStyle: {
        m: 'auto',
        color: Colors.primary,
      },
    },
  },
});
