import { IIconProps, Icon as IconNB } from 'native-base';
import { Colors } from 'src/theme/colors';
import {
  Entypo,
  FontAwesome,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import English from 'components/icons/flags/english.svg';
import Slovak from 'components/icons/flags/slovakia.svg';

export type IconProps = IIconProps & {
  light?: boolean;
  primary?: boolean;
  secondary?: boolean;
};

const Icon = ({ light, primary, secondary, ...props }: IconProps) => {
  const color = light
    ? Colors.grayLight
    : primary
    ? Colors.primary
    : secondary
    ? Colors.secondary
    : undefined;

  return <IconNB color={color} {...props} />;
};

export const GymIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="fitness-center" size="5" {...props} />
);

export const UserIcon = (props: IconProps) => (
  <Icon as={FontAwesome5} name="user-alt" size="5" {...props} />
);

export const UserOutlineIcon = (props: IconProps) => (
  <Icon as={FontAwesome5} name="user" size="5" {...props} />
);

export const DashboardIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="dashboard" size="5" {...props} />
);

export const DashboardOutlineIcon = (props: IconProps) => (
  <Icon
    as={MaterialCommunityIcons}
    name="view-dashboard-outline"
    size="5"
    {...props}
  />
);

export const EditIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="edit" size="5" {...props} />
);

export const FavoriteIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="favorite" size="5" {...props} />
);

export const FavoriteOutlineIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="favorite-outline" size="5" {...props} />
);

export const MasteredIcon = (props: IconProps) => (
  <Icon
    as={MaterialCommunityIcons}
    name="checkbox-marked"
    size="5"
    {...props}
  />
);

export const MasteredOutlineIcon = (props: IconProps) => (
  <Icon as={MaterialCommunityIcons} name="check-bold" size="5" {...props} />
);

export const ProblemIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="report-problem" size="5" {...props} />
);

export const BodyIcon = (props: IconProps) => (
  <Icon as={Ionicons} name="body" size="5" {...props} />
);

export const BodyOutlineIcon = (props: IconProps) => (
  <Icon as={Ionicons} name="body-outline" size="5" {...props} />
);

export const FilterIcon = (props: IconProps) => (
  <Icon as={Ionicons} name="filter" size="5" {...props} />
);

export const RightIcon = (props: IconProps) => (
  <Icon as={Ionicons} name="chevron-forward" size="5" {...props} />
);

export const BackIcon = (props: IconProps) => (
  <Icon as={Ionicons} name="arrow-back" size="5" {...props} />
);

export const FriendsIcon = (props: IconProps) => (
  <Icon as={MaterialCommunityIcons} name="account-group" size="5" {...props} />
);

export const FriendsOutlineIcon = (props: IconProps) => (
  <Icon
    as={MaterialCommunityIcons}
    name="account-group-outline"
    size="5"
    {...props}
  />
);

export const LogoutIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="logout" size="5" {...props} />
);

export const LoginIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="login" size="5" {...props} />
);

export const CheckIcon = (props: IconProps) => (
  <Icon as={FontAwesome} name="check" size="5" {...props} />
);

export const CheckCircleIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="check-circle" size="5" {...props} />
);

export const CloseIcon = (props: IconProps) => (
  <Icon as={FontAwesome} name="close" size="5" {...props} />
);

export const CrossIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="close" size="5" {...props} />
);

export const SendIcon = (props: IconProps) => (
  <Icon as={FontAwesome} name="send" size="5" {...props} />
);

export const ChatIcon = (props: IconProps) => (
  <Icon as={MaterialCommunityIcons} name="chat" size="5" {...props} />
);

export const WeChatIcon = (props: IconProps) => (
  <Icon as={MaterialCommunityIcons} name="wechat" size="5" {...props} />
);

export const InfoIcon = (props: IconProps) => (
  <Icon as={FontAwesome} name="info" size="5" {...props} />
);

export const ProblemsIcon = (props: IconProps) => (
  <Icon as={Entypo} name="pin" size="5" {...props} />
);

export const RotateIcon = (props: IconProps) => (
  <Icon as={MaterialCommunityIcons} name="rotate-360" size="5" {...props} />
);

export const PaymentIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="payment" size="5" {...props} />
);

export const AddFriendIcon = (props: IconProps) => (
  <FontAwesome5
    name="user-plus"
    {...props}
    size={typeof props.size === 'number' ? props.size * 4 : 16}
  />
);

export const DownIcon = (props: IconProps) => (
  <Icon as={Ionicons} name="chevron-down" size="5" {...props} />
);

export const UpIcon = (props: IconProps) => (
  <Icon as={Ionicons} name="chevron-up" size="5" {...props} />
);

export const PrivacyIcon = (props: IconProps) => (
  <Icon as={MaterialIcons} name="privacy-tip" size="5" {...props} />
);

export const CancelAccountIcon = (props: IconProps) => (
  <Icon as={MaterialCommunityIcons} name="account-cancel" size="5" {...props} />
);

export const InstagramIcon = (props: IconProps) => (
  <Icon as={Entypo} name="instagram" size="5" {...props} />
);

export const YoutubeIcon = (props: IconProps) => (
  <Icon as={Entypo} name="youtube" size="5" {...props} />
);

export const GmailIcon = (props: IconProps) => (
  <Icon as={MaterialCommunityIcons} name="gmail" size="5" {...props} />
);

export const GooglePlayIcon = (props: IconProps) => (
  <Icon as={Entypo} name="google-play" size="5" {...props} />
);

export const GoogleIcon = (props: IconProps) => (
  <Icon as={FontAwesome} name="google" size="5" {...props} />
);

export const AppleIcon = (props: IconProps) => (
  <Icon as={MaterialCommunityIcons} name="apple" size="5" {...props} />
);

export const LightIcon = (props: IconProps) => (
  <Icon as={Ionicons} name="sunny" size="5" {...props} />
);

export const DarkIcon = (props: IconProps) => (
  <Icon as={Ionicons} name="ios-moon" size="5" {...props} />
);

export const PremiumIcon = (props: IconProps) => (
  <Icon as={MaterialCommunityIcons} name="crown" size="5" {...props} />
);

export const GridIcon = (props: IconProps) => (
  <Icon as={Ionicons} name="grid" size="5" {...props} />
);

export const EnglishIcon = () => <English />;
export const SlovakIcon = () => <Slovak />;
