import { IMuscle } from 'src/@types/api';
import { Slider } from 'components/layout/Slider';
import React from 'react';
import { MusclePreview } from 'components/MusclePreview';

type Props = {
  muscles: IMuscle[];
};
export const HEIGHT = 100;

export const MusclesSlider = ({ muscles }: Props) => {
  return (
    <Slider>
      {muscles.map((muscle, index) => (
        <MusclePreview
          w={120}
          height={HEIGHT}
          key={muscle.id}
          muscle={muscle}
          mr={2}
          withOverlay={index >= 1}
        />
      ))}
    </Slider>
  );
};
