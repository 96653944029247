import { Button, Column, Row, Stack, Text } from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISubscription, ISubscriptionRC } from 'src/@types/api';
import { isWeb } from 'src/utils/constants';
import { hexToRGBA } from 'src/utils/colors';
import { updateUser } from 'src/firebase/user';
import { useAuthUser } from 'src/state/state';
import { useIsPremium } from 'hooks/useIsPremium';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import { SubscriptionModal } from 'components/modals/SubscriptionModal';
import { PremiumType } from '../../@types/enums';

type Props = {
  subscription?: ISubscription;
  subscriptionRC?: ISubscriptionRC;
  managementUrl?: string;
};

export const ActiveSubscription = ({
  subscription,
  subscriptionRC,
  managementUrl,
}: Props) => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const { premiumRole } = useIsPremium();
  const [modalOpen, setModalOpen] = useState(false);
  const willRenew = subscriptionRC?.unsubscribe_detected_at === null;
  const showManageBtn = isWeb && subscription && managementUrl;

  const updateCharity = (value: string) => {
    updateUser({ uid: user?.uid, charity: value });
  };

  return (
    <Column alignItems="center" justifyContent="center" space={2}>
      {premiumRole && (
        <Row justifyContent="center">
          <Text>{t('subscription.selectedText1')}</Text>
          <Text bold>{t(`subscription.${premiumRole}`)}.</Text>
        </Row>
      )}
      {/*<CheckIcon color={Colors.success} size={20} mb={4} />*/}
      {/*<Text>{t('subscription.subscriptionTitle')}</Text>*/}
      {subscription ? (
        <Text textAlign="center">
          {subscription.cancel_at_period_end
            ? t('subscription.subscriptionExpiry')
            : t('subscription.subscriptionRenewal')}
          {subscription.current_period_end.toDate().toLocaleDateString()}
        </Text>
      ) : subscriptionRC ? (
        <Text textAlign="center">
          {willRenew
            ? t('subscription.subscriptionRenewal')
            : t('subscription.subscriptionExpiry')}
          {new Date(subscriptionRC.expires_date).toLocaleDateString()}
        </Text>
      ) : null}
      {isWeb || (
        <PrimaryButton onPress={() => setModalOpen(true)} my={8} w={180}>
          {t('subscription.changePlan')}
        </PrimaryButton>
      )}
      {showManageBtn && (
        <>
          <Text>{t('subscription.selectedText2Web')}</Text>
          <PrimaryButton
            my={8}
            mt={4}
            onPress={() => (window.location.href = managementUrl)}
          >
            {t('subscription.manage')}
          </PrimaryButton>
        </>
      )}
      <Text textAlign="center">
        {t('subscription.charity', {
          price: premiumRole === PremiumType.Premium ? 1 : 0.5,
        })}
      </Text>
      {user?.charity ? (
        <Row mb={10}>
          <Text>{t('subscription.supported')}</Text>
          {/*// @ts-ignore*/}
          <Text bold>{t(`subscription.${user?.charity}`)}</Text>
        </Row>
      ) : (
        <Stack flexDir={['column', 'row']} mb={4}>
          <Button
            bg="#ca8142"
            _hover={{ bg: hexToRGBA('#ca8142', 0.8) }}
            onPress={() => updateCharity('sport')}
            m={2}
          >
            {t('subscription.sport')}
          </Button>
          <Button
            bg="#98B2A5"
            _hover={{ bg: hexToRGBA('#98B2A5', 0.8) }}
            onPress={() => updateCharity('dogs')}
            m={2}
          >
            {t('subscription.dogs')}
          </Button>
        </Stack>
      )}
      <SubscriptionModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Column>
  );
};
