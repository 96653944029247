import { Column, IStackProps, Row } from 'native-base';
import { Colors } from 'src/theme/colors';

export const DarkColumn = ({ bg, ...props }: IStackProps) => {
  return (
    <Column
      bg={bg || Colors.bgWhite}
      _dark={{ bg: bg || Colors.bgDark }}
      {...props}
    />
  );
};
export const DarkRow = ({ bg, ...props }: IStackProps) => {
  return (
    <Row
      bg={bg || Colors.bgWhite}
      _dark={{ bg: bg || Colors.bgDark }}
      {...props}
    />
  );
};
