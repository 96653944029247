import { Column, StyledProps } from 'native-base';
import { TextLight } from 'components/typography/text';
import DeviceInfo from 'react-native-device-info';
import { TextButton } from 'components/buttons/TextButton';
import { useCallback, useEffect, useState } from 'react';
import * as Updates from 'expo-updates';
import { isDev, isProd } from 'src/utils/constants';

type Props = StyledProps;

export const OTA = 'p20';

export const AppInfo = (props: Props) => {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const checkForUpdate = useCallback(async () => {
    const update = await Updates.checkForUpdateAsync();
    setUpdateAvailable(update.isAvailable);
  }, []);

  useEffect(() => {
    if (!isDev) {
      checkForUpdate();
    }
  }, [checkForUpdate]);

  const downloadUpdate = async () => {
    await Updates.fetchUpdateAsync();
    await Updates.reloadAsync();
  };

  return (
    <Column {...props} position="absolute" bottom={0}>
      {updateAvailable && !isProd && (
        <TextButton onPress={downloadUpdate}>Download new update</TextButton>
      )}
      <TextLight fontSize={14} textAlign="center" mt="auto">
        {DeviceInfo.getVersion()} ({DeviceInfo.getBuildNumber()}) - {OTA}
      </TextLight>
    </Column>
  );
};
