import { Routes } from 'src/navigation/routes';
import React, { PropsWithChildren } from 'react';
import { Row } from 'native-base';
import { Link } from 'components/links/Link';
import { HeadingSecondary } from 'components/typography/heading';
import { RightIcon } from 'components/icons/icons';
import { Colors } from 'src/theme/colors';
import { DarkColumn } from 'components/layout/views';

type Props = {
  title: string;
  target?: Routes;
};
export const DashboardSection = ({
  title,
  target,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <DarkColumn p={4} space={4} shadow={0}>
      {target ? (
        <Link
          to={{
            screen: target,
          }}
        >
          <Row alignItems="center" space={4}>
            <HeadingSecondary
              color={Colors.grayLight}
              _dark={{ color: Colors.grayLight }}
            >
              {title}
            </HeadingSecondary>
            <RightIcon
              color={Colors.grayLight}
              _dark={{ color: Colors.grayLight }}
            />
          </Row>
        </Link>
      ) : (
        <HeadingSecondary
          color={Colors.grayLight}
          _dark={{ color: Colors.grayLight }}
        >
          {title}
        </HeadingSecondary>
      )}
      {children}
    </DarkColumn>
  );
};
