import { Column, IconButton, Row, Text } from 'native-base';
import { TextLight, TextSecondary } from 'components/typography/text';
import { isIOS, isWeb } from '../../utils/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { PremiumItem, PremiumRow } from 'components/PremiumRow';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import {
  BodyIcon,
  DashboardIcon,
  GoogleIcon,
  GymIcon,
} from 'components/icons/icons';
import { AppleLogin } from 'components/dashboard/AppleLogin';
import { Colors } from '../../theme/colors';
import { ExternalLink } from 'components/links/ExternalLink';
import { PRIVACY_URL, TERMS_URL } from 'components/subscription/NoSubscription';
import { useTranslation } from 'react-i18next';
import { useAuthUser, userPremiumState } from '../../state/state';
import { useRecoilState } from 'recoil';
import { PremiumType } from '../../@types/enums';
import { getUserRole, login } from '../../firebase/auth';
import {
  fetchPackages,
  getManagementUrl,
  subscribe,
} from '../../firebase/subscription';
import {
  PACKAGES_IDENTIFIERS_MOBILE,
  WEB_PRODUCTS,
} from '../../utils/subscriptionConstants';
import { logError } from '../../firebase/analytics';
import { ERRORS } from '../../firebase/constants';

type Package = {
  product: {
    identifier: string;
  };
};

export const SubscriptionPlans = () => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const [premiumRole, setPremium] = useRecoilState(userPremiumState);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [managementUrl, setManagementUrl] = useState('');
  const [packages, setPackages] =
    useState<Record<PremiumType, Package | undefined>>();
  const [selectedPremium, setSelectedPremium] = useState(
    premiumRole || PremiumType.Premium
  );

  const fetchPremium = useCallback(
    async (role?: PremiumType) => {
      const premiumRole = await getUserRole();
      if (role && role !== premiumRole) {
        setTimeout(() => fetchPremium(role), 1000);
      } else {
        setPremium(premiumRole);
        setSelectedPremium(premiumRole || PremiumType.Premium);
        setSubscribeLoading(false);
      }
    },
    [setPremium]
  );

  useEffect(() => {
    fetchPremium();
    if (user) {
      getManagementUrl().then(setManagementUrl);
      fetchPackages().then((packages: Package[]) => {
        const musclesPackage = packages.find(
          (p) =>
            p.product.identifier ===
            PACKAGES_IDENTIFIERS_MOBILE[PremiumType.Muscles]
        );
        const exercisesPackage = packages.find(
          (p) =>
            p.product.identifier ===
            PACKAGES_IDENTIFIERS_MOBILE[PremiumType.Exercises]
        );
        const premiumPackage = packages.find(
          (p) =>
            p.product.identifier ===
            PACKAGES_IDENTIFIERS_MOBILE[PremiumType.Premium]
        );
        setPackages({
          [PremiumType.Muscles]: musclesPackage,
          [PremiumType.Exercises]: exercisesPackage,
          [PremiumType.Premium]: premiumPackage,
        });
      });
    }
  }, [user, fetchPremium]);

  const onSubscribe = async () => {
    setSubscribeLoading(true);
    try {
      const activePackage = premiumRole ? packages?.[premiumRole] : undefined;
      if (user?.uid && (isWeb || packages?.[selectedPremium])) {
        await subscribe(
          user.uid,
          isWeb ? WEB_PRODUCTS[selectedPremium] : packages?.[selectedPremium],
          selectedPremium,
          activePackage?.product.identifier
        );
        fetchPremium(selectedPremium);
        const url = await getManagementUrl();
        setManagementUrl(url);
      }
    } catch (e) {
      logError(e as Error, ERRORS.PURCHASE);
      setSubscribeLoading(false);
    }
  };

  const onGoogleLogin = () => {
    login();
  };

  const rows: PremiumItem[] = [
    {
      type: PremiumType.Muscles,
      title: t('subscription.muscles'),
      description: t('subscription.musclesDesc'),
      price: '2.99',
      icon: <BodyIcon size={8} primary />,
      bullets: [
        t('subscription.musclesCount'),
        t('subscription.problemsCount'),
      ],
    },
    {
      type: PremiumType.Exercises,
      title: t('subscription.exercises'),
      description: t('subscription.exercisesDesc'),
      price: '2.99',
      icon: <GymIcon size={8} primary />,
      bullets: [
        t('subscription.exercisesCount'),
        t('subscription.problemsCount'),
      ],
    },
    {
      type: PremiumType.Premium,
      title: t('subscription.premium'),
      description: t('subscription.premiumDesc'),
      price: '4.99',
      icon: <DashboardIcon size={8} primary />,
      bullets: [
        t('subscription.exercisesCount'),
        t('subscription.musclesCount'),
        t('subscription.problemsCount'),
      ],
      label: t('subscription.freeTrial'),
    },
  ];
  const renderPlans = !isWeb || !premiumRole;
  return (
    <Column space={6}>
      {premiumRole ? (
        <Row justifyContent="center" flexWrap="wrap" px={4}>
          <Text>{t('subscription.selectedText1')}</Text>
          <Text bold>{t(`subscription.${premiumRole}`)}.</Text>
          <Text>
            {t(
              isWeb
                ? 'subscription.selectedText2Web'
                : 'subscription.selectedText2'
            )}
          </Text>
        </Row>
      ) : (
        <Text textAlign="center">{t('subscription.subtitle')}</Text>
      )}
      {renderPlans && (
        <>
          <Column space={2}>
            {rows.map((item) => (
              <PremiumRow
                key={item.title}
                selected={item.type === selectedPremium}
                disabled={premiumRole === item.type}
                onPress={() => setSelectedPremium(item.type)}
                item={item}
              />
            ))}
          </Column>
          {!isWeb && (
            <TextSecondary textAlign="center">
              {t('subscription.description')}
            </TextSecondary>
          )}
          <Column alignItems="center" space={4}>
            {user ? (
              <PrimaryButton
                w={300}
                alignSelf="center"
                onPress={onSubscribe}
                isLoading={subscribeLoading}
                isDisabled={selectedPremium === premiumRole}
              >
                {t('subscription.subscribe')}
              </PrimaryButton>
            ) : (
              <>
                <TextLight textAlign="center">
                  {isIOS
                    ? t('subscription.loginRequiredApple')
                    : t('subscription.loginRequired')}
                </TextLight>

                {isIOS ? (
                  <Row
                    alignItems="center"
                    space={4}
                    justifyContent="center"
                    mt={1}
                  >
                    <IconButton icon={<GoogleIcon />} onPress={onGoogleLogin} />
                    <AppleLogin variant="icon" />
                  </Row>
                ) : (
                  <PrimaryButton
                    leftIcon={<GoogleIcon color={Colors.bgWhite} />}
                    maxW={300}
                    onPress={onGoogleLogin}
                  >
                    {t('general.googleLogin')}
                  </PrimaryButton>
                )}
              </>
            )}
          </Column>
        </>
      )}
      {isWeb && premiumRole && (
        <PrimaryButton
          alignSelf="center"
          onPress={() => (window.location.href = managementUrl)}
        >
          {t('subscription.manage')}
        </PrimaryButton>
      )}
      <Row alignItems="center" justifyContent="center" space={2}>
        <ExternalLink
          href={PRIVACY_URL}
          _text={{ fontSize: 13, color: Colors.primary }}
        >
          {t('subscription.privacy')}
        </ExternalLink>
        <Text color={Colors.primary}>•</Text>
        <ExternalLink
          _text={{ fontSize: 13, color: Colors.primary }}
          href={TERMS_URL}
        >
          {t('routes.termsOfUse')}
        </ExternalLink>
      </Row>
    </Column>
  );
};
