import { useAuthUser } from 'src/state/state';
import { useCallback, useEffect, useState } from 'react';
import { IUnreadMessage } from 'src/@types/api';
import { onFetchUnreadMessages } from 'src/firebase/conversations';

export const useUnreadMessages = () => {
  const user = useAuthUser();
  const [unreadMessages, setUnreadMessages] = useState<IUnreadMessage>({});
  const unreadCount = unreadMessages
    ? Object.values(unreadMessages).reduce(
        (sum, current) => sum + current.length,
        0
      )
    : 0;

  useEffect(() => {
    if (user) {
      return onFetchUnreadMessages(user, setUnreadMessages);
    }
  }, [user]);

  const isUnread = useCallback(
    (conversationId: string, threadId: string) => {
      return unreadMessages[conversationId]?.includes(threadId);
    },
    [unreadMessages]
  );

  return { unreadMessages, unreadCount, isUnread };
};
