import { FC, PropsWithChildren, useState } from 'react';
import { useLinkProps } from '@react-navigation/native';
import { Pressable, Stack, StyledProps } from 'native-base';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { isWeb } from 'src/utils/constants';
import { PlatformProps } from 'native-base/lib/typescript/components/types';
import * as React from 'react';
import { GestureResponderEvent } from 'react-native';

export type LinkProps = StyledProps &
  PlatformProps<StyledProps> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    to: To<any>;
    width?: number | string;
    height?: number | string;
    action?: () => void;
  };

export const Link: FC<PropsWithChildren<LinkProps>> = ({
  to,
  action,
  ...props
}) => {
  const { width, height } = props;
  const [isHovered, setIsHovered] = useState(false);
  const { onPress, accessibilityRole, ...linkProps } = useLinkProps({ to });

  const onClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent
  ) => {
    action?.();
    onPress?.(e);
  };

  if (isWeb) {
    return (
      <a
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          textDecoration: 'none',
          width,
          height,
          opacity: isHovered ? 0.7 : 1,
          fontFamily: 'Roboto-Regular',
        }}
        {...linkProps}
      >
        <Stack flexDirection="row" alignItems="center" {...props} />
      </a>
    );
  }

  return (
    <Pressable
      flexDirection="row"
      alignItems="center"
      onPress={onClick}
      accessibilityRole={accessibilityRole}
      {...linkProps}
      {...props}
    />
  );
};
