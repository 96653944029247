import { IConversation } from 'src/@types/api';
import { Column, Divider, Text } from 'native-base';
import { FriendRow } from 'components/FriendRow';
import { useAuthUser } from 'src/state/state';
import { useMemo } from 'react';
import { getLocalizedValue } from 'src/utils/utils';
import { DarkColumn } from 'components/layout/views';
import { NumberBadge } from 'components/NumberBadge';
import { MessageText } from 'components/chat/MessageText';

type Props = { conversation: IConversation; unreadCount?: number };

export const ConversationRow = ({ conversation, unreadCount }: Props) => {
  const user = useAuthUser();

  const participants = useMemo(
    () => conversation.participants.filter(({ uid }) => uid !== user?.uid),
    [conversation.participants, user?.uid]
  );
  if (!user) {
    return null;
  }
  return (
    <DarkColumn borderRadius={5} p={4} w="full" space={4}>
      <NumberBadge value={unreadCount} top={2} right={2} />
      <Column space={2} flex={1} justifyContent="center">
        {participants.map((friend) => (
          <FriendRow key={friend.uid} friend={friend} />
        ))}
      </Column>
      <Divider />
      <Column flex={1} alignItems="center" space={2}>
        {conversation.lastMessage.threadName && (
          <Text bold textAlign="center">
            {getLocalizedValue(conversation.lastMessage.threadName)}
          </Text>
        )}
        <MessageText textAlign="center" w="100%">
          {conversation.lastMessage.text}
        </MessageText>
      </Column>
    </DarkColumn>
  );
};
