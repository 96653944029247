import { FC } from 'react';
import { Button as NBButton, IButtonProps } from 'native-base';

export const TextButton: FC<IButtonProps> = (props) => (
  <NBButton
    variant="ghost"
    _pressed={{ bgColor: 'contrastThreshold' }}
    _hover={{ bgColor: 'contrastThreshold', opacity: 0.8 }}
    _focus={{ bgColor: 'contrastThreshold', opacity: 0.8 }}
    colorScheme={props.disabled ? 'coolGray' : 'primary'}
    {...props}
  />
);

export const TextButtonSecondary: FC<IButtonProps> = (props) => (
  <NBButton
    colorScheme="secondary"
    _hover={{ bgColor: 'contrastThreshold', opacity: 0.8 }}
    _focus={{ bgColor: 'contrastThreshold', opacity: 0.8 }}
    variant="ghost"
    {...props}
  />
);
