import { Slider } from 'components/layout/Slider';
import { ExercisePreview } from 'components/ExercisePreview';
import { IFavoriteExercise } from 'src/@types/types';

type Props = { exercises: IFavoriteExercise[] };

export const ExercisesSlider = ({ exercises }: Props) => {
  return (
    <Slider>
      {exercises.map((exercise) => (
        <ExercisePreview
          key={exercise.id}
          exercise={exercise}
          w={220}
          height={140}
          mr={2}
          withOverlay={exercise.type !== 'free'}
        />
      ))}
    </Slider>
  );
};
