import { isWeb } from 'src/utils/constants';
import { useBreakpointValue } from 'native-base';

export const useMenuDimensions = () => {
  const menuSize = isWeb ? 48 + 16 : 0;
  const isBottomMenu = useBreakpointValue([true, true, false]) && isWeb;
  const paddingBottom = isBottomMenu ? menuSize : 0;

  return {
    menuSize,
    paddingBottom,
    isBottomMenu,
  };
};
