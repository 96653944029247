import { IModalProps, Modal, Text } from 'native-base';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import { Linking } from 'react-native';
import { isIOS } from '../utils/constants';
import { APP_STORE_URL, PLAY_STORE_URL } from '../links';

type Props = IModalProps;

export const AppVersionModal = (props: Props) => {
  const { t } = useTranslation();
  const onOpenStore = async () => {
    const url = isIOS ? APP_STORE_URL : PLAY_STORE_URL;
    if (await Linking.canOpenURL(url)) {
      Linking.openURL(url);
    }
  };
  return (
    <Modal {...props}>
      <Modal.Content>
        <Modal.Header textAlign="center">
          {t('appVersion.minVersionTitle')}
        </Modal.Header>
        <Modal.Body>
          <Text>{t('appVersion.minVersionSubtitle')}</Text>
          <PrimaryButton mt={4} onPress={onOpenStore} alignSelf="center">
            {t('appVersion.update')}
          </PrimaryButton>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
