import { IProblem } from 'src/@types/api';
import { StyledProps } from 'native-base';
import { Routes } from '../navigation/routes';
import React from 'react';
import { isWeb } from '../utils/constants';
import { problemsImages } from '../data/problems/problemsImages';
import { Colors } from 'src/theme/colors';
import { Preview } from 'components/Preview';
import { useIsPremium } from 'hooks/useIsPremium';

type Props = {
  problem: IProblem;
  onPress?: () => void;
  withOverlay?: boolean;
  disabled?: boolean;
} & StyledProps;

export const ProblemPreview = React.memo(({ problem, ...props }: Props) => {
  const { hasSubscription } = useIsPremium();

  return (
    <Preview
      hasPremium={hasSubscription}
      name={problem.name}
      to={
        isWeb
          ? { screen: Routes.ProblemDetail, params: { id: problem.id } }
          : {
              screen: Routes.ProblemsStack,
              params: {
                screen: Routes.ProblemDetail,
                params: { id: problem.id },
                initial: false,
              },
            }
      }
      imageSource={problemsImages[problem.id]}
      labelColor={Colors.bgProblem}
      {...props}
    />
  );
});

ProblemPreview.displayName = 'ProblemPreview';
