import {
  HeadingSecondary,
  HeadingTertiary,
} from 'components/typography/heading';
import { Image, Text } from 'native-base';
import { ExternalLink } from 'components/links/ExternalLink';
import { PropsWithChildren } from 'react';

export const PolicySK = () => {
  return (
    <>
      <HeadingSecondary my={2}>DÔLEŽITÁ POZNÁMKA</HeadingSecondary>
      <Text my={4}>
        V prípade zdravotných problémov, zranení alebo tehotenstva sa pred
        začatím cvičenia v aplikácii poraďte so svojím lekárom. Nikdy
        nezačínajte cvičiť bez úvodných zahrievacích cvikov. Ak počas cvičenia
        pociťujete bolesť, mali by ste počkať a v prípade potreby navštíviť
        lekára. Nepreťažujte sa – prispôsobte tréning svojim osobným
        schopnostiam. Všetky informácie sú bez záruky. Fyzická osoba Tomáš
        Janečka nezodpovedá za sekundárne nevýhody alebo škody, ktoré by vznikli
        v dôsledku cvičení, prípadne poskytnutých rád.
      </Text>
      <HeadingSecondary my={2}>Úvodné ustanovenia</HeadingSecondary>
      <Text my={4}>
        Tieto podmienky upravujú vzťah medzi jednotlivcom Tomášom Janečkou a
        používateľom mobilnej a webovej aplikácie recoverytom.com. Tieto
        podmienky obsahujú záväzné pravidlá a podmienky poskytovania služieb
        fyzickou osobou Tomáš Janečka ich užívateľom, ako aj práva a povinnosti
        užívateľov služieb fyzickej osoby Tomáš Janečka. Používateľ vyjadruje
        svoj súhlas s týmito podmienkami vytvorením profilu v mobilnej a webovej
        aplikácii recoverytom.com, ktorú prevádzkuje Tomáš Janečka, alebo
        pokračovaním v používaní služieb po upozornení na zmenu týchto podmienok
        dodržiavaním týchto podmienok. Vyjadrením súhlasu s týmito obchodnými
        podmienkami sa tieto obchodné podmienky stávajú pre užívateľa záväznými
        a užívateľ týmto súhlasom vyjadruje svoju vôľu byť viazaný týmito
        obchodnými podmienkami po celú dobu poskytovania a využívania služieb, a
        to aj po uplynutí ukončenie poskytovania a využívania služieb v zmysle
        časti týchto obchodných podmienok, ktorých obsahom je ochrana
        oprávnených záujmov fyzickej osoby Tomáš Janečka. Tieto obchodné
        podmienky nie sú pre užívateľov uchovávané, a preto fyzická osoba Tomáš
        Janečka týmto odporúča každému užívateľovi obstarať si kópiu týchto
        podmienok pre vlastnú potrebu. Používanie mobilných služieb a služieb
        webových aplikácií recoverytom.com je možné len vtedy, ak súhlasíte s
        týmito podmienkami.
      </Text>
      <HeadingSecondary my={2}>
        Princíp používania anonymných údajov
      </HeadingSecondary>
      <Text my={4}>
        V zásade je možné našu webovú stránku používať bez poskytnutia osobných
        údajov. Používanie jednotlivých služieb a ponúk na našej webovej stránke
        a v našej aplikácii môže mať za následok odlišné predpisy, ktoré sú v
        tomto prípade vysvetlené samostatne nižšie. Právny základ ochrany údajov
        možno nájsť vo všeobecnom nariadení o ochrane údajov (GDPR). Keď
        vstúpite na našu webovú stránku alebo do aplikácie, prenesú sa niektoré
        informácie, ako napríklad IP adresa. Poskytujete tiež informácie o
        použitom koncovom zariadení (počítač, smartfón, tablet atď.), použitom
        prehliadači (Internet Explorer, Safari, Firefox atď.), čase návštevy
        webovej stránky, takzvanom referrerovi a objeme dát. prenesené. Tieto
        údaje nemôžeme použiť na identifikáciu jednotlivého používateľa. Tieto
        informácie používame iba na to, aby sme určili, aké atraktívne sú naše
        ponuky, a ak je to potrebné, zlepšili ich výkonnosť alebo obsah a aby
        ich dizajn bol pre Vás ešte príťažlivejší. Majte však na pamäti, že v
        prípade statickej IP adresy je v individuálnych prípadoch možná osobná
        identifikácia dotazom RIPE, aj keď to nevykonávame. Napriek tomu je táto
        webová stránka prístupná pre pridelené statické aj dynamické IP adresy.
      </Text>
      <HeadingSecondary my={2}>
        Cookies, sledovacie pixely a podobné technológie
      </HeadingSecondary>
      <Text my={4}>
        Aby sme zlepšili naše webové a aplikačné služby a spríjemnili vám ich
        používanie, používame súbory cookie, sledovacie pixely alebo podobné
        technológie. Cookies sú malé textové súbory, ktoré sa ukladajú do vášho
        počítača pri návšteve našej webovej stránky. Pomáhajú nám rozpoznať váš
        prehliadač ako váš. Cookies ukladajú informácie, ako sú vaše jazykové
        nastavenia alebo trvanie vašej návštevy na našej webovej stránke.
        Ukladajú údaje, ktoré zadáte na webe, napríklad keď sa prihlásite, takže
        ich nemusíte zadávať pri každom použití našich služieb. Súbory cookie
        nám pomáhajú rozpoznať vaše preferencie a prispôsobiť našu webovú
        stránku oblastiam vášho záujmu. Zakaždým, keď sa naša webová stránka a
        aplikácia načítajú, zaznamenávame, ako často sú navštevované a ako často
        sa na ne kliká, pomocou značiek na našej webovej stránke a v aplikácii
        nazývaných sledovacie pixely. Tieto sledovacie pixely nezasahujú do
        vášho počítača. Súbory cookie a sledovacie pixely používame na rôzne
        účely, čo tiež znamená, že majú rôzne právne základy. Prečítajte si
        odsek o súboroch cookie v našich zásadách ochrany osobných údajov
        nižšie, aby ste pochopili, ako používame rôzne typy súborov cookie na
        splnenie rôznych účelov a ako dlho uchovávame zhromaždené informácie.
        Tieto súbory cookie sú potrebné na fungovanie stránky a nemožno ich
        zakázať. Bez týchto cookies nebudete môcť správne prezerať našu stránku.
      </Text>
      <Text fontWeight="bold">Povolená funkčnosť</Text>
      <Li>
        Poskytnutie bezpečného prihlásenia a overenie používateľa po prihlásení
      </Li>
      <Li>Sledovanie a hlásenie chýb</Li>
      <Li>Ceny podľa krajiny a zacielenie na poskytovateľa platieb</Li>
      <Li>Aktivácia nástrojov A/B testovania a analytických nástrojov</Li>
      <Li>Sledovanie pridružených spoločností</Li>
      <Text my={1}>
        Ochrana vašich osobných údajov je pre nás v tíme RecoveryTom mimoriadne
        dôležitá. Preto pri zhromažďovaní a spracovaní vašich osobných údajov
        dodržiavame zákonné požiadavky. Nižšie nájdete rozsiahle informácie o
        rozsahu a účele zhromažďovania údajov.
      </Text>
      <HeadingSecondary my={2}>
        Zhromažďovanie a spracovanie osobných údajov
      </HeadingSecondary>
      <Text my={4}>
        Osobné údaje zhromažďujeme iba vtedy, ak nám ich poskytnete, napríklad
        keď nás kontaktujete, registráciou účtu Recovery, zadaním objednávky,
        vyžiadaním si informácií alebo zverejnením osobných údajov v našej
        aplikácii RecoveryTom vo vašom profile alebo v informačnom kanáli.
        Osobné údaje, ktoré nám poskytnete, používame iba v rozsahu, v akom sú
        potrebné na poskytovanie alebo spracovanie našich služieb. Vaše údaje
        uchovávame tak dlho, ako je to potrebné na dosiahnutie zamýšľaného
        účelu, alebo kým nevymažete svoj účet, alebo tak dlho, ako si
        uchovávanie údajov vyžaduje zákonná lehota uchovávania. Vaše údaje sú
        následne vymazané v súlade so zákonnými požiadavkami alebo je ich
        spracovanie obmedzené. V prípade čisto informačného použitia, t.j. ak sa
        nezaregistrujete alebo nám nepošlete informácie iným spôsobom,
        zhromažďujeme iba osobné údaje, ktoré váš prehliadač odovzdá na naše
        servery. Ak si chcete prezerať našu webovú stránku, zhromažďujeme
        nasledujúce údaje, ktoré potrebujeme na technické účely, aby sme vám
        mohli ukázať náš obsah a zaručiť stabilitu a bezpečnosť (právnym
        základom je oprávnený záujem podľa článku 6 ods. 1 písm. f) GDPR). V
        kontexte rovnováhy záujmov v súlade s článkom 6 ods. 1 písm. f) GDPR sme
        zvážili náš záujem o poskytovanie webových stránok a aplikácií a váš
        záujem o spracúvanie vašich osobných údajov v súlade s ochranou údajov.
        Keďže nižšie uvedené údaje sú technicky potrebné na poskytovanie našej
        služby, aby sme vám mohli ponúknuť naše služby a tiež zaručiť stabilitu
        a bezpečnosť, najmä ochranu pred zneužitím, dospeli sme k záveru, že pri
        najmodernejšej orientovanej bezpečnosti údajov záruky, tieto údaje môžu
        byť spracované, pričom sa primerane zohľadní váš záujem o spracovanie v
        súlade s ochranou údajov.
      </Text>
      <Image
        source={require('../../../../assets/policy/collectionProcessingDataSK.png')}
        alt="Collection processing data table"
        width="100%"
        height={[300, 400, 650]}
        resizeMode="contain"
        mx="auto"
      />
      <Text my={4}>
        Zhromažďovanie údajov na poskytovanie webovej stránky a ukladanie údajov
        do log súborov je nevyhnutné pre web a prevádzku. Používatelia preto
        proti tomu nemôžu namietať.
      </Text>
      <HeadingSecondary my={2}>
        Registrácia spoločnosťou GoogleRegistrácia spoločnosťou Google
      </HeadingSecondary>
      <Text my={4}>
        Účet RecoveryTom si môžete vytvoriť pomocou účtu Google. Môžete sa
        zaregistrovať alebo prihlásiť do aplikácie RecoveryTom pomocou svojho
        účtu Google. Potom budete presmerovaní na Google (kde musíte byť
        prihlásení alebo musíte mať účet) a dostanete vysvetlenie, ktoré z
        vašich údajov potrebujeme od spoločnosti Google, konkrétne informácie o
        vašom verejnom profile, ako je meno a priezvisko a e-mailová adresa
        ktoré tam používate. Tieto informácie sú potrebné na účely
        identifikácie, aby sme vám mohli vytvoriť bezpečný účet RecoveryTom. Váš
        účet Google a účet RecoveryTom budú natrvalo prepojené pomocou vašej
        e-mailovej adresy. Vaše e-mailové informácie ukladáme interne. Môžeme
        tiež povedať, že ste sa prihlásili pomocou Google. Hneď ako sa
        prihlásite do Google, môžete sa prihlásiť do aplikácie RecoveryTom. Bez
        vášho súhlasu nebudeme spoločnosti Google odosielať žiadne informácie o
        Vás. Dôležité: Vaše prihlasovacie údaje Google žiadnym spôsobom
        nezaznamenávame a nemôžeme nič zverejniť vo vašom profile Google bez
        vášho výslovného súhlasu. Môžete sa dozvedieť, ako spoločnosť Google
        zaobchádza s nastaveniami ochrany osobných údajov pomocou{' '}
        <ExternalLink href={'https://policies.google.com/privacy'}>
          zásad ochrany osobných údajov
        </ExternalLink>{' '}
        a{' '}
        <ExternalLink href={'https://policies.google.com/terms'}>
          podmienok používania spoločnosti Google
        </ExternalLink>
        ; tieto zahŕňajú aj príslušné podmienky pre špecifikovanú možnosť
        prihlásenia a registrácie do RecoveryTom.
      </Text>
      <Image
        source={require('../../../../assets/policy/googleRegistrationDataSK.png')}
        alt="Collection processing data table"
        width="100%"
        height={[150, 200, 300]}
        resizeMode="contain"
      />
      <HeadingSecondary my={2}>
        Push notifikácie ako súčasť používateľského zážitku
      </HeadingSecondary>
      <Text my={4}>
        Vyžadujeme váš súhlas, ak chcete dostávať naše push notifikácie na vašom
        mobilnom iOS zariadení, aj keď aplikácia nie je otvorená. Naša aplikácia
        používa upozornenia push iba vtedy, ak ste s nimi dali výslovný súhlas.
        Upozornenia push môžete kedykoľvek vypnúť v nastaveniach. Ak používate
        zariadenie so systémom Android, upozornenia push sú povolené
        automaticky, pokiaľ to nezakážete v nastaveniach.
      </Text>
      <HeadingSecondary my={2}>Google Analytics</HeadingSecondary>
      <Text my={4}>
        Na analýzu návštevníkov našich webových stránok používame službu Google
        Analytics od spoločnosti Google Ireland limited (Gordon House, Barrow
        Street, Dublin 4, Írsko). Google používa súbory cookie na sledovanie
        používania online produktu alebo služby používateľmi a informácie sa vo
        všeobecnosti prenášajú na server Google v USA a tam sa ukladajú.
        Spoločnosť Google použije tieto informácie v našom mene na vyhodnotenie
        používania našich online produktov a služieb používateľmi, na zostavenie
        správ o aktivitách v rámci týchto online produktov a služieb a na
        poskytovanie ďalších služieb spojených s používaním týchto online
        produktov a služieb. a používanie internetu. Zo spracovaných údajov je
        možné vytvárať pseudonymné užívateľské profily. Google Analytics
        používame iba s povolenou anonymizáciou IP adries. To znamená, že Google
        skráti IP adresy používateľov v rámci členských štátov Európskej únie
        alebo v iných štátoch, ktoré sú zmluvnými stranami Dohody o Európskom
        hospodárskom priestore. Len vo výnimočných prípadoch bude úplná IP
        adresa prenesená na server Google v USA a tam skrátená. IP adresa
        prenášaná prehliadačom používateľa nie je zlúčená s inými údajmi Google.
        Používatelia môžu zabrániť ukladaniu súborov cookie zodpovedajúcim
        prispôsobením nastavení softvéru svojho prehliadača. Nastavili sme
        predvolené nastavenia priateľské k ochrane údajov. Právnym základom
        využívania tejto služby je čl. 6 odsek 1 veta 1 písmeno f GDPR.
        Používatelia môžu zabrániť zhromažďovaniu údajov generovaných súbormi
        cookie stiahnutím a nainštalovaním doplnku prehliadača, ktorý je k
        dispozícii{' '}
        <ExternalLink href={'https://tools.google.com/dlpage/gaoptout?hl=en'}>
          tu
        </ExternalLink>
        . Ako záruka v zmysle čl. 44ff všeobecného nariadenia o ochrane údajov
        (GDPR), spoločnosť Google podpísala štandardné zmluvné doložky EÚ.
        Ďalšie informácie o spracovaní údajov službou Google Analytics nájdete v
        zásadách ochrany osobných údajov poskytovateľa.
      </Text>
      <HeadingSecondary my={2}>Firebase od Googlu</HeadingSecondary>
      <Text mt={4}>
        Firebase od spoločnosti Google Ireland limited (Gordon House, Barrow
        Street, Dublin 4, Írsko) používame ako našu cloudovú backendovú službu
        aplikácie a na odvodenie analýzy správania aplikácií. Tieto informácie
        používame na to, aby sme videli, ako používatelia interagujú s našou
        webovou stránkou a aplikáciou. Niektoré služby Firebase spracúvajú
        osobné údaje. Vo väčšine prípadov sú osobné údaje obmedzené na takzvané
        „inštancie ID“, ktoré sú opatrené časovou pečiatkou. Tieto
        „identifikátory inštancií“ priradené platformou Firebase sú jedinečné, a
        preto umožňujú prepojenie rôznych udalostí alebo procesov. Tieto údaje
        pre nás nepredstavujú osobne identifikovateľné informácie, ani sa
        nesnažíme ich následne personalizovať. Tieto súhrnné údaje spracovávame
        na analýzu a optimalizáciu správania pri používaní, napríklad
        vyhodnocovaním správ o zlyhaní. V súčasnosti používame nasledujúce
        služby Firebase:
      </Text>

      <Li>
        Google Analytics pre Firebase: Google Analytics používa údaje na
        poskytovanie analytických informácií a informácií o pripisovaní.{' '}
        <ExternalLink
          href={'https://support.google.com/firebase/answer/6318039'}
        >
          Presné zhromaždené informácie sa môžu líšiť v závislosti od zariadenia
          a prostredia.
        </ExternalLink>
      </Li>
      <Li>
        Cloud Firestore, Cloud Functions, Cloud Storage, Firebase
        Authentication: Podmienky týchto služieb nájdete{' '}
        <ExternalLink href={'https://cloud.google.com/terms/'}>tu</ExternalLink>
        .
      </Li>
      <Li>
        Firebase hosting, Firebase Performance Monitoring, Firebase Remote
        Config, Firebase App Check:{' '}
        <ExternalLink href={'https://developers.google.com/terms/'}>
          Zmluvné podmienky rozhrania Google API
        </ExternalLink>{' '}
        a{' '}
        <ExternalLink
          href={'https://firebase.google.com/terms/data-processing-terms'}
        >
          podmienky spracovania a zabezpečenia údajov Firebase Firebase
        </ExternalLink>
      </Li>
      <Li>
        Crashlytics:{' '}
        <ExternalLink href={'https://firebase.google.com/terms/crashlytics'}>
          Firebase Crashlytics a podmienky distribúcie aplikácií Firebase
        </ExternalLink>
      </Li>
      <HeadingSecondary my={2}>
        Poskytovateľ platobných služieb
      </HeadingSecondary>
      <HeadingTertiary my={2}>Stripe</HeadingTertiary>
      <Text my={4}>
        Platobné údaje môžu byť na tento účel prenesené poskytovateľovi
        platobných služieb Stripe. Ak sa vaše údaje spracúvajú mimo EÚ,
        poskytovateľ platobných služieb sa zaviazal dodržiavať štandardné
        zmluvné doložky EÚ. V niektorých prípadoch zhromažďuje tieto údaje aj
        poskytovateľ platobných služieb Stripe sám na vlastnú zodpovednosť. Viac
        informácií o spracúvaní osobných údajov poskytovateľom platobných
        služieb Stripe. Stripe, Inc, 354 Oyster Point Boulevard, South San
        Francisco, Kalifornia, 94080, USA. Viac informácií nájdete v zásadách
        ochrany osobných údajov spoločnosti Stripe{' '}
        <ExternalLink href={'https://stripe.com/en-sk/privacy'}>
          tu
        </ExternalLink>
        .
      </Text>
      <HeadingSecondary my={2}>Vaše práva</HeadingSecondary>
      <HeadingSecondary my={2}>
        Informácie o právach dotknutých osôb
      </HeadingSecondary>
      <Text my={4}>
        Každá dotknutá osoba má právo na prístup v súlade s článkom 15 GDPR,
        právo na opravu v súlade s článkom 16 GDPR, právo na vymazanie v súlade
        s článkom 17 GDPR, právo na obmedzenie spracovania v súlade s článkom 18
        GDPR, právo namietať v článku 21 GDPR a právo na prenosnosť údajov v
        článku 20 GDPR. Obmedzenia podľa článkov 34 a 35 BDSG sa vzťahujú na
        právo na prístup a na právo na vymazanie.
      </Text>
      <HeadingTertiary my={2}>
        Zrušenie predplatného účtu RecoveryTom
      </HeadingTertiary>
      <Text my={4}>
        Zrušenie predplatného je možné iba na platforme, kde ste si ho zakúpili.
      </Text>
      <HeadingTertiary my={2}>
        Odstránenie svojho účtu RecoveryTom
      </HeadingTertiary>
      <Text my={4}>
        Odstránenie vášho účtu ovplyvní všetky vaše používateľské údaje
        RecoveryTom vrátane vašich výsledkov a pokroku a budú odstránené
        natrvalo. Čiastočné vymazanie účtu nie je možné.
      </Text>
      <HeadingTertiary my={2}>Prostredníctvom webovej stránky</HeadingTertiary>
      <Li>Prihláste sa do svojho účtu na našej webovej stránke.</Li>
      <Li>Prejdite do Profilu.</Li>
      <Li>Kliknite na &quot;Odstrániť účet&quot;.</Li>

      <HeadingTertiary my={2}>Cez aplikáciu</HeadingTertiary>
      <Li>Prejdite do Profilu na karte Nástenka vpravo hore.</Li>
      <Li>Kliknite na &quot;Vymazať účet&quot;.</Li>

      <HeadingSecondary my={2}>
        Informácie o možnosti podať sťažnosť
      </HeadingSecondary>
      <Text my={4}>
        Máte tiež právo podať sťažnosť príslušnému orgánu na ochranu údajov
        ohľadom nášho spracovania vašich osobných údajov.
      </Text>
      <HeadingSecondary my={2}>Informáciu o odvolaní súhlasu</HeadingSecondary>
      <Text my={4}>
        Svoj súhlas so spracovaním osobných údajov s nami môžete kedykoľvek
        odvolať.
      </Text>
      <HeadingSecondary my={2}>
        Práva spotrebiteľov CCPA (dodatočné práva na ochranu súkromia v
        Kalifornii)
      </HeadingSecondary>
      <Text my={4}>
        Táto časť poskytuje kalifornským spotrebiteľom ďalšie podrobnosti o
        právach, ktoré im poskytuje kalifornský zákon o ochrane súkromia
        spotrebiteľov alebo „CCPA“. Okrem práv uvedených vyššie v časti
        „informácie o právach dotknutých osôb“ majú spotrebitelia v Kalifornii
        právo nebyť diskriminovaní za to, že uplatnili svoje práva podľa zákona
        CCPA. Spoločnosť RecoveryTom vám najmä nesmie odoprieť tovar alebo
        služby, účtovať vám rozdielne ceny za tovar alebo služby, či už
        odmietnutím výhod alebo uložením pokút, poskytnúť vám inú úroveň alebo
        kvalitu tovaru alebo služieb alebo sa vám vyhrážať čímkoľvek z vyššie
        uvedeného. Okrem toho, RecoveryTom nepredáva osobné údaje, ktoré
        zhromažďujeme (ako je definované v CCPA), a nebude ich predávať bez
        toho, aby vám poskytla právo na odstúpenie.
      </Text>
      <HeadingSecondary my={2}>
        Právo v prípade, že sa údaje spracúvajú na účely priameho marketingu
      </HeadingSecondary>
      <Text my={4}>
        Máte právo podľa článku 21 ods. 2 GDPR namietať proti spracovaniu
        osobných údajov, ktoré sa vás týkajú. V prípade, že podáte námietku
        proti spracovaniu na účely priameho marketingu, nebudeme už vaše osobné
        údaje na tento účel spracovávať. Upozorňujeme, že toto odvolanie sa bude
        uplatňovať iba prospektívne. Toto nemá vplyv na spracovanie, ktoré
        prebehlo pred odstúpením od zmluvy.
      </Text>
      <HeadingSecondary my={2}>
        Informácie o práve namietať v prípade rovnováhy záujmov
      </HeadingSecondary>
      <Text my={4}>
        Ak spracúvame vaše osobné údaje na základe rovnováhy záujmov, môžete
        proti takémuto spracúvaniu namietať. Ak uplatníte toto právo namietať,
        uveďte dôvody, prečo by sme nemali spracúvať vaše údaje tak, ako sme
        opísali. Ak je vaša námietka oprávnená, preveríme situáciu a buď
        zastavíme alebo upravíme spracovanie údajov, alebo vám vysvetlíme naše
        presvedčivé oprávnené dôvody na spracovanie.
      </Text>
    </>
  );
};

const Li = (props: PropsWithChildren) => {
  return (
    <Text>
      {`\u2022`} {props.children}
    </Text>
  );
};
