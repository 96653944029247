import {
  arrayRemove,
  arrayUnion,
  collection,
  CollectionReference,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  runTransaction,
  where,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

import { Collections } from 'src/firebase/constants';
import { firestore } from 'src/firebase/init';
import { IExercise, IUser } from 'src/@types/api';
import { filtersRef, userRef } from 'src/firebase/refs';
import { IFavoriteExercise } from 'src/@types/types';

// Create a reference with an initial file path and name
const storage = getStorage();
const imageRef = (id: string) => ref(storage, `exercises/${id}.png`);

const exercisesCollection = collection(
  firestore,
  Collections.Exercises
) as CollectionReference<IExercise>;

const exerciseRef = (id: string) => doc(exercisesCollection, id);

export const fetchExercises = async (free: boolean) => {
  const constraints = [
    ...(free ? [where('type', 'in', ['free', 'overlay'])] : []),
  ];
  const q = query(exercisesCollection, orderBy('id'), ...constraints);
  const exercisesSnap = await getDocs<IExercise>(q);
  const exercises: IExercise[] = [];
  exercisesSnap.forEach((doc) => {
    if (doc.id !== Collections.AllExercises) {
      exercises.push(doc.data());
    }
  });
  return exercises;
};

export const fetchExercise = async (id: string) => {
  const exerciseSnapshot = await getDoc(exerciseRef(id));
  return exerciseSnapshot.data();
};

export const fetchExerciseImageUrl = (id: string) => {
  return getDownloadURL(imageRef(id));
};

export const likeExercise = async (
  user: IUser,
  exercise: IFavoriteExercise
) => {
  return runTransaction(firestore, async (transaction) => {
    const userDoc = userRef(user.uid);
    const userSnapshot = await transaction.get(userDoc);
    const userData = userSnapshot.data();
    const isFavorite = userData?.favorites.find(({ id }) => exercise.id === id);
    if (isFavorite) {
      transaction.update(userDoc, {
        favorites: arrayRemove({ id: exercise.id, name: exercise.name }),
      });
      transaction.update(exerciseRef(exercise.id), { likes: increment(-1) });
    } else {
      transaction.update(userDoc, {
        favorites: arrayUnion({
          id: exercise.id,
          name: exercise.name,
          type: exercise.type,
        }),
      });
      transaction.update(exerciseRef(exercise.id), { likes: increment(1) });
    }
  });
};

export const fetchFilters = async () => {
  const filtersDoc = await getDoc(filtersRef);
  return filtersDoc.data();
};
