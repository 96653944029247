import { IconButton, IStackProps, Row } from 'native-base';
import { DownIcon } from 'components/icons/icons';
import { hexToRGBA } from 'src/utils/colors';
import { Colors } from 'src/theme/colors';
import React from 'react';
import { FlatList } from 'react-native';

type Props = IStackProps & {
  scrollRef: React.RefObject<FlatList>;
};

export const ScrollButton = ({ scrollRef, ...props }: Props) => {
  return (
    <Row
      bottom={20}
      w="full"
      position="absolute"
      justifyContent="center"
      {...props}
    >
      <IconButton
        icon={<DownIcon />}
        backgroundColor={hexToRGBA(Colors.bgWhite, 0.9)}
        shadow={4}
        onPress={() =>
          scrollRef.current?.scrollToOffset({ offset: 0, animated: true })
        }
      />
    </Row>
  );
};
