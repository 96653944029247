import { IConversation } from 'src/@types/api';
import { useCallback, useMemo } from 'react';

export const useConversationPhotos = (conversation?: IConversation) => {
  const isGroupChat = conversation
    ? conversation?.participants.length > 2
    : false;

  const photos = useMemo(
    () =>
      conversation?.participants.reduce<Record<string, string>>(
        (acc, participant) => {
          acc[participant.uid] = participant.photoUrl;
          return acc;
        },
        {}
      ) || {},
    [conversation?.participants]
  );

  const getUserPhoto = useCallback(
    (uid: string) => {
      return photos[uid];
    },
    [photos]
  );

  return { isGroupChat, photos, getUserPhoto };
};
