import { FlatList } from 'react-native';
import { ExercisePreview } from 'components/ExercisePreview';
import React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTranslation } from 'react-i18next';
import { IExercise } from 'src/@types/api';
import { Modal } from 'components/modals/Modal';
import { isWeb } from '../../utils/constants';

type Props = {
  exercises: IExercise[];
  isOpen: boolean;
  onClose: () => void;
};

export const ExercisesBottomModal = ({ isOpen, onClose, exercises }: Props) => {
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('routes.exercises')}
      px={0}
    >
      <FlatList
        data={exercises}
        contentContainerStyle={{
          paddingHorizontal: 12,
          paddingBottom: bottom,
        }}
        scrollIndicatorInsets={{ right: 1 }}
        renderItem={({ item, index }) => (
          <ExercisePreview
            exercise={item}
            flex={1 / 3}
            h={130}
            mx={1}
            my={2}
            onPress={onClose}
            withOverlay={index >= 2}
            disabled={isWeb}
          />
        )}
        numColumns={3}
      />
    </Modal>
  );
};
