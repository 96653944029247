import { ImageSourcePropType } from 'react-native';

export const musclesImages: Record<string, ImageSourcePropType> = {
  levatorScapulae: require('../../../assets/muscles/core/levatorScapulae.png'),
  sternocleidomastoid: require('../../../assets/muscles/core/sternocleidomastoid.png'),
  scaleneMuscles: require('../../../assets/muscles/core/scaleneMuscles.png'),
  omohyoid: require('../../../assets/muscles/core/omohyoid.png'),
  sternohyoid: require('../../../assets/muscles/core/sternohyoid.png'),
  spleniusCapitis: require('../../../assets/muscles/core/spleniusCapitis.png'),
  semispinalisCapitis: require('../../../assets/muscles/core/semispinalisCapitis.png'),
  pectoralisMajor: require('../../../assets/muscles/core/pectoralisMajor.png'),
  pectoralisMinor: require('../../../assets/muscles/core/pectoralisMinor.png'),
  serratusAnterior: require('../../../assets/muscles/core/serratusAnterior.png'),
  supraspinatus: require('../../../assets/muscles/core/supraspinatus.png'),
  infraspinatus: require('../../../assets/muscles/core/infraspinatus.png'),
  teresMajor: require('../../../assets/muscles/core/teresMajor.png'),
  teresMinor: require('../../../assets/muscles/core/teresMinor.png'),
  subscapularis: require('../../../assets/muscles/core/subscapularis.png'),
  rhomboidMinor: require('../../../assets/muscles/core/rhomboidMinor.png'),
  rhomboidMajor: require('../../../assets/muscles/core/rhomboidMajor.png'),
  serratusPosteriorSuperior: require('../../../assets/muscles/core/serratusPosteriorSuperior.png'),
  serratusPosteriorInferior: require('../../../assets/muscles/core/serratusPosteriorInferior.png'),
  latissimusDorsi: require('../../../assets/muscles/core/latissimusDorsi.png'),
  trapezius: require('../../../assets/muscles/core/trapezius.png'),
  deltoid: require('../../../assets/muscles/core/deltoid.png'),
  coracobrachialis: require('../../../assets/muscles/core/coracobrachialis.png'),
  bicepsBrachii: require('../../../assets/muscles/core/bicepsBrachii.png'),
  brachialis: require('../../../assets/muscles/core/brachialis.png'),
  tricepsBrachii: require('../../../assets/muscles/core/tricepsBrachii.png'),
  brachioradialis: require('../../../assets/muscles/core/brachioradialis.png'),
  pronatorTeres: require('../../../assets/muscles/core/pronatorTeres.png'),
  flexorCarpiRadialis: require('../../../assets/muscles/core/flexorCarpiRadialis.png'),
  flexorCarpiUlnaris: require('../../../assets/muscles/core/flexorCarpiUlnaris.png'),
  palmarisLongus: require('../../../assets/muscles/core/palmarisLongus.png'),
  flexorDigitorumSuperficialis: require('../../../assets/muscles/core/flexorDigitorumSuperficialis.png'),
  flexorDigitorumProfundus: require('../../../assets/muscles/core/flexorDigitorumProfundus.png'),
  pronatorQuadratus: require('../../../assets/muscles/core/pronatorQuadratus.png'),
  supinator: require('../../../assets/muscles/core/supinator.png'),
  flexorPollicisLongus: require('../../../assets/muscles/core/flexorPollicisLongus.png'),
  anconeus: require('../../../assets/muscles/core/anconeus.png'),
  extensorCarpiRadialisLongus: require('../../../assets/muscles/core/extensorCarpiRadialisLongus.png'),
  extensorCarpiRadialisBrevis: require('../../../assets/muscles/core/extensorCarpiRadialisBrevis.png'),
  extensorDigitorum: require('../../../assets/muscles/core/extensorDigitorum.png'),
  extensorPollicisLongus: require('../../../assets/muscles/core/extensorPollicisLongus.png'),
  extensorPollicisBrevis: require('../../../assets/muscles/core/extensorPollicisBrevis.png'),
  extensorDigitiMinimi: require('../../../assets/muscles/core/extensorDigitiMinimi.png'),
  extensorCarpiUlnaris: require('../../../assets/muscles/core/extensorCarpiUlnaris.png'),
  rectusAbdominis: require('../../../assets/muscles/core/rectusAbdominis.png'),
  tranversusAbdominis: require('../../../assets/muscles/core/tranversusAbdominis.png'),
  internalOblique: require('../../../assets/muscles/core/internalOblique.png'),
  externalOblique: require('../../../assets/muscles/core/externalOblique.png'),
  quadratusLumborum: require('../../../assets/muscles/core/quadratusLumborum.png'),
  psoasMinor: require('../../../assets/muscles/core/psoasMinor.png'),
  psoasMajor: require('../../../assets/muscles/core/psoasMajor.png'),
  iliacus: require('../../../assets/muscles/core/iliacus.png'),
  sartorius: require('../../../assets/muscles/core/sartorius.png'),
  tensorFaciaeLatae: require('../../../assets/muscles/core/tensorFaciaeLatae.png'),
  rectusFemoris: require('../../../assets/muscles/core/rectusFemoris.png'),
  vastusMedialis: require('../../../assets/muscles/core/vastusMedialis.png'),
  vastusLateralis: require('../../../assets/muscles/core/vastusLateralis.png'),
  vastusIntermedius: require('../../../assets/muscles/core/vastusIntermedius.png'),
  gracilis: require('../../../assets/muscles/core/gracilis.png'),
  adductorLongus: require('../../../assets/muscles/core/adductorLongus.png'),
  adductorBrevis: require('../../../assets/muscles/core/adductorBrevis.png'),
  adductorMagnus: require('../../../assets/muscles/core/adductorMagnus.png'),
  pectineus: require('../../../assets/muscles/core/pectineus.png'),
  gluteusMaximus: require('../../../assets/muscles/core/gluteusMaximus.png'),
  gluteusMedius: require('../../../assets/muscles/core/gluteusMedius.png'),
  gluteusMinimus: require('../../../assets/muscles/core/gluteusMinimus.png'),
  piriformis: require('../../../assets/muscles/core/piriformis.png'),
  quadratusFemoris: require('../../../assets/muscles/core/quadratusFemoris.png'),
  bicepsFemoris: require('../../../assets/muscles/core/bicepsFemoris.png'),
  semitendinosus: require('../../../assets/muscles/core/semitendinosus.png'),
  semimembranosus: require('../../../assets/muscles/core/semimembranosus.png'),
  gastrocnemius: require('../../../assets/muscles/core/gastrocnemius.png'),
  soleus: require('../../../assets/muscles/core/soleus.png'),
  popliterius: require('../../../assets/muscles/core/popliterius.png'),
  plantaris: require('../../../assets/muscles/core/plantaris.png'),
  fibularisLongus: require('../../../assets/muscles/core/fibularisLongus.png'),
  tibialisPosterior: require('../../../assets/muscles/core/tibialisPosterior.png'),
  flexorDigitorumLongus: require('../../../assets/muscles/core/flexorDigitorumLongus.png'),
  tibialisAnterior: require('../../../assets/muscles/core/tibialisAnterior.png'),
  fibularisBrevis: require('../../../assets/muscles/core/fibularisBrevis.png'),
  extensorDigitorumLongus: require('../../../assets/muscles/core/extensorDigitorumLongus.png'),
  extensorHallucisLongus: require('../../../assets/muscles/core/extensorHallucisLongus.png'),
};
