import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
} from 'firebase/firestore';
import { firestore } from 'src/firebase/init';
import { Collections } from 'src/firebase/constants';
import {
  IAppConfig,
  ICheckoutSession,
  IConversation,
  IConversationThread,
  IFilters,
  IMessage,
  ISubscription,
  IUnreadMessage,
  IUser,
  IUsersRelationships,
} from 'src/@types/api';

export const conversationsCollection = collection(
  firestore,
  Collections.UsersConversations
) as CollectionReference<IConversation>;

export const conversationRef = (id: string) => doc(conversationsCollection, id);

export const threadsCollection = (conversationId: string) =>
  collection(
    firestore,
    Collections.UsersConversations,
    conversationId,
    Collections.Threads
  ) as CollectionReference<IConversationThread>;

export const threadRef = (conversationId: string, threadId: string) =>
  doc<IConversationThread>(threadsCollection(conversationId), threadId);

export const messagesCollection = (conversationId: string, threadId: string) =>
  collection(
    firestore,
    Collections.UsersConversations,
    conversationId,
    Collections.Threads,
    threadId,
    Collections.Messages
  ) as CollectionReference<IMessage>;

export const messageRef = (
  conversationId: string,
  threadId: string,
  messageId: string
) => doc<IMessage>(messagesCollection(conversationId, threadId), messageId);

export const unreadMessagesCollection = collection(
  firestore,
  Collections.UnreadMessages
) as CollectionReference<IUnreadMessage>;

export const unreadMessagesRef = (userId: string) =>
  doc<IUnreadMessage>(unreadMessagesCollection, userId);

export const relationshipsCollection = collection(
  firestore,
  Collections.UserRelationships
) as CollectionReference<IUsersRelationships>;

export const usersCollection = collection(
  firestore,
  Collections.Users
) as CollectionReference<IUser>;

export const userRef = (id: string) =>
  doc(firestore, Collections.Users, id) as DocumentReference<IUser>;

export const usersRelationshipsRef = (id: string) =>
  doc(
    firestore,
    Collections.UserRelationships,
    id
  ) as DocumentReference<IUsersRelationships>;

export const checkoutSessionsCollection = (userId: string) =>
  collection(
    firestore,
    Collections.Users,
    userId,
    Collections.CheckoutSessions
  ) as CollectionReference<ICheckoutSession>;

export const subscriptionsCollection = (userId: string) =>
  collection(
    firestore,
    Collections.Users,
    userId,
    Collections.Subscriptions
  ) as CollectionReference<ISubscription>;

export const filtersRef = doc(
  firestore,
  Collections.Filters,
  Collections.AllFilters
) as DocumentReference<IFilters>;

export const appConfigRef = doc(
  firestore,
  Collections.Config,
  Collections.App
) as DocumentReference<IAppConfig>;
