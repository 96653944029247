import { analytics } from 'src/firebase/init';
import { logEvent } from 'firebase/analytics';

export const logScreenView = (route?: string) => {
  if (route) {
    logEvent(analytics, 'screen_view', {
      firebase_screen: route,
      firebase_screen_class: route,
    });
  }
};

export const setUserIdForAnalytics = (userId = '') => {
  return userId;
};

export const setAttributeForAnalytics = (name: string, value: string) => {
  return [name, value];
};

export const logError = (error: Error, name: string) => {
  return [name, error];
};
