import { Screen } from 'components/layout/Screen';
import { useTranslation } from 'react-i18next';
import { TermsOfUseEn } from 'components/policyAndTerms/termsOfUse/TermsOfUseEn';
import { TermsOfUseSk } from 'components/policyAndTerms/termsOfUse/TermsOfUseSk';
import { isSlovak } from '../../utils/language';

export const TermsOfUseScreen = () => {
  const { t } = useTranslation();
  return (
    <Screen title={t('routes.termsOfUse')}>
      {isSlovak() ? <TermsOfUseSk /> : <TermsOfUseEn />}
    </Screen>
  );
};
