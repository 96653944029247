import { isWeb } from 'src/utils/constants';

export enum Collections {
  Exercises = 'exercises',
  AllExercises = 'allExercises',
  Users = 'users',
  UserRelationships = 'usersRelationships',
  UsersConversations = 'usersConversations',
  Participants = 'participants',
  Threads = 'threads',
  Messages = 'messages',
  UnreadMessages = 'unreadMessages',
  CheckoutSessions = 'checkout_sessions',
  Subscriptions = 'subscriptions',
  Filters = 'filters',
  AllFilters = 'allFilters',
  Config = 'config',
  App = 'app',
}

export const CONVERSATIONS_LIMIT = 5;
export const THREADS_LIMIT = 4;
export const THREADS_MESSAGES_LIMIT = 5;
export const MESSAGES_LIMIT = 15;
export const EXERCISES_LIMIT = isWeb ? 16 : 10;
export const MUSCLES_LIMIT = isWeb ? 16 : 10;

export const RECOVERY_TOM_EMAIL = 'recoverytom.mail@gmail.com';

export enum ERRORS {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  GOOGLE_LOGIN = 'GOOGLE_LOGIN',
  APPLE_LOGIN = 'APPLE_LOGIN',
  PURCHASE = 'PURCHASE',
  RESTORE_PURCHASE = 'RESTORE_PURCHASE',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  REVOKE_TOKEN = 'REVOKE_TOKEN',
}

export enum ATTRIBUTES {
  PURCHASE_USER_ID = 'purchase_user_id',
  PURCHASE_IS_ANONYMOUS = 'purchase_is_anonymous',
}
