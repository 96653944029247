import {
  IConversation,
  IConversationThread,
  IFriend,
  IMessage,
  IUnreadMessage,
  IUser,
} from 'src/@types/api';
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Timestamp,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { firestore } from 'src/firebase/init';
import {
  CONVERSATIONS_LIMIT,
  Collections,
  MESSAGES_LIMIT,
  THREADS_LIMIT,
  THREADS_MESSAGES_LIMIT,
} from 'src/firebase/constants';
import {
  conversationRef,
  conversationsCollection,
  threadRef,
  threadsCollection,
  messageRef,
  messagesCollection,
  unreadMessagesRef,
} from 'src/firebase/refs';
import { ILocalizedValue } from 'src/@types/types';
import { ThreadType } from 'src/@types/enums';

export const fetchConversations = async (user: IUser) => {
  const q = query<IConversation>(
    conversationsCollection,
    where(Collections.Participants, 'array-contains', {
      uid: user?.uid,
      name: user?.name,
      photoUrl: user?.photoUrl,
      email: user?.email,
    }),
    orderBy('updatedAt', 'desc')
  );
  const querySnapshot = await getDocs<IConversation>(q);
  const conversations: IConversation[] = [];
  querySnapshot?.forEach((doc) => {
    conversations.push(doc.data());
  });
  return conversations;
};

export const fetchConversationById = async (id: string) => {
  const doc = await getDoc(conversationRef(id));
  return doc.data();
};

export const onFetchConversations = (
  user: IUser,
  callback: (conversations: IConversation[]) => void,
  count = CONVERSATIONS_LIMIT
) => {
  const q = query<IConversation>(
    conversationsCollection,
    where(Collections.Participants, 'array-contains', {
      uid: user?.uid,
      name: user?.name,
      photoUrl: user?.photoUrl,
      email: user?.email,
    }),
    orderBy('updatedAt', 'desc'),
    limit(count)
  );
  return onSnapshot(q, (snapshot) => {
    const conversations: IConversation[] = [];
    snapshot?.forEach((doc) => {
      conversations.push(doc.data());
    });
    callback(conversations);
  });
};

export const onFetchConversationThreads = (
  conversationId: string,
  callback: (threads: IConversationThread[]) => void,
  count = THREADS_LIMIT
) => {
  const q = query(
    threadsCollection(conversationId),
    orderBy('updatedAt', 'desc'),
    limit(count)
  );
  return onSnapshot(q, (snapshot) => {
    const threads: IConversationThread[] = [];
    snapshot?.forEach((message) => threads.push(message.data()));
    callback(threads);
  });
};

export const fetchThreadById = async (
  conversationId: string,
  threadId: string
) => {
  const doc = await getDoc<IConversationThread>(
    threadRef(conversationId, threadId)
  );
  return doc.data();
};

export const onFetchThreadMessages = (
  conversationId: string,
  threadId: string,
  callback: (messages: IMessage[]) => void,
  count = THREADS_MESSAGES_LIMIT
) => {
  const q = query(
    messagesCollection(conversationId, threadId),
    orderBy('createdAt', 'desc'),
    limit(count)
  );
  return onSnapshot(q, (snapshot) => {
    const messages: IMessage[] = [];
    snapshot?.forEach((message) => messages.push(message.data()));
    callback(messages.reverse());
  });
};

export const onFetchLastMessage = (
  conversationId: string,
  threadId: string,
  callback: (reply: IMessage) => void
) => {
  const q = query(
    messagesCollection(conversationId, threadId),
    orderBy('createdAt', 'desc'),
    limit(1)
  );
  return onSnapshot(q, (snapshot) => {
    if (snapshot?.docs?.length) {
      callback(snapshot.docs[0].data());
    }
  });
};

export const fetchMessages = async (
  conversationId: string,
  threadId: string,
  lastReply: IMessage
) => {
  const lastReplyDoc = await getDoc(
    messageRef(conversationId, threadId, lastReply.id)
  );
  const q = query(
    messagesCollection(conversationId, threadId),
    orderBy('createdAt', 'desc'),
    startAfter(lastReplyDoc),
    limit(MESSAGES_LIMIT)
  );
  const querySnapshot = await getDocs<IMessage>(q);
  const messages: IMessage[] = [];
  querySnapshot?.forEach((message) => messages.push(message.data()));
  return messages;
};

export const sendMessage = async (
  user: IUser,
  message: string,
  friendsToSend: IFriend[],
  threadType: ThreadType,
  threadName: ILocalizedValue,
  threadId: string
) => {
  const batch = writeBatch(firestore);
  const id = [user.uid, ...friendsToSend.map((fr: IFriend) => fr.uid)]
    .sort()
    .join('-');

  const conversationDoc = conversationRef(id);
  const conversationSnap = await getDoc(conversationDoc);

  const timestamp = Timestamp.now();
  if (conversationSnap.exists()) {
    batch.update(conversationDoc, {
      updatedAt: timestamp,
      lastMessage: {
        text: message,
        senderPhotoUrl: user.photoUrl,
        senderUid: user.uid,
        senderName: user.name,
        threadName,
      },
    });
  } else {
    batch.set(conversationDoc, {
      participants: [
        {
          uid: user.uid,
          name: user.name,
          photoUrl: user.photoUrl,
          email: user.email,
        },
        ...friendsToSend,
      ],
      createdAt: timestamp,
      updatedAt: timestamp,
      lastMessage: {
        text: message,
        senderPhotoUrl: user.photoUrl,
        senderUid: user.uid,
        senderName: user.name,
        threadName,
      },
      id,
    });
    friendsToSend.forEach((friend) => {
      const unreadDoc = unreadMessagesRef(friend.uid);
      batch.update(unreadDoc, { [id]: [] });
    });
  }

  const threadDoc = threadRef(id, threadId);
  const threadSnap = await getDoc(threadDoc);
  if (threadSnap.exists()) {
    batch.update(threadDoc, {
      updatedAt: timestamp,
      lastMessage: {
        text: message,
        senderPhotoUrl: user.photoUrl,
        senderUid: user.uid,
      },
    });
  } else {
    batch.set(threadDoc, {
      id: threadId,
      senderUid: user.uid,
      type: threadType,
      threadName: threadName,
      createdAt: timestamp,
      updatedAt: timestamp,
      lastMessage: {
        text: message,
        senderPhotoUrl: user.photoUrl,
        senderUid: user.uid,
      },
    });
  }

  const messageDoc = doc(messagesCollection(id, threadId));
  const newMessage: IMessage = {
    id: messageDoc.id,
    createdAt: timestamp,
    text: message,
    senderUid: user.uid,
  };
  batch.set(messageDoc, newMessage);
  friendsToSend.forEach((friend) => {
    const unreadDoc = unreadMessagesRef(friend.uid);
    batch.update(unreadDoc, { [id]: arrayUnion(threadId) });
  });
  return batch.commit();
};

const emptyObj = {};
export const onFetchUnreadMessages = (
  user: IUser,
  callback: (unreadMessages: IUnreadMessage) => void
) => {
  return onSnapshot(unreadMessagesRef(user.uid), (snapshot) => {
    if (snapshot.exists()) {
      callback(snapshot.data() || emptyObj);
    }
  });
};

export const setReadMessage = (
  user: IUser,
  conversationId: string,
  messageId: string
) => {
  return updateDoc(unreadMessagesRef(user.uid), {
    [conversationId]: arrayRemove(messageId),
  });
};
