import { AlertDialog, Button } from 'native-base';
import { useCallback, useEffect, useRef } from 'react';
import { IAlertDialogProps } from 'native-base/lib/typescript/components/composites';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-native';
import { isWeb } from 'src/utils/constants';

export type AlertModalProps = Omit<IAlertDialogProps, 'leastDestructiveRef'> & {
  title: string;
  description: string;
  onDone: () => void;
  onClose: () => void;
  doneLabel: string;
};

export const AlertModal = ({
  title,
  description,
  onClose,
  onDone,
  isOpen,
  doneLabel,
  ...props
}: AlertModalProps) => {
  const cancelRef = useRef();
  const { t } = useTranslation();
  const prevOpen = useRef(isOpen);

  const showAlert = useCallback(() => {
    if (!isWeb) {
      Alert.alert(title, description, [
        {
          text: t('general.cancel'),
          style: 'cancel',
        },
        {
          text: doneLabel,
          onPress: onDone,
          style: 'destructive',
        },
      ]);
      onClose();
    }
  }, [description, doneLabel, onClose, onDone, t, title]);

  useEffect(() => {
    if (isOpen && !prevOpen.current) {
      showAlert();
    }
    prevOpen.current = isOpen;
  }, [isOpen, showAlert]);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      {...props}
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header>{title}</AlertDialog.Header>
        <AlertDialog.Body>{description}</AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button
              variant="unstyled"
              colorScheme="coolGray"
              onPress={onClose}
              ref={cancelRef}
            >
              {t('general.cancel')}
            </Button>
            <Button
              colorScheme="danger"
              onPress={() => {
                onDone();
                onClose();
              }}
            >
              {doneLabel}
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
