import { Spinner } from 'native-base';
import { IConversation } from 'src/@types/api';
import { ConversationRow } from 'components/chat/ConversationRow';
import { useUnreadMessages } from 'src/hooks/useUnreadMessages';
import { useAuthUser } from 'src/state/state';
import React from 'react';
import { ListRenderItemInfo } from 'react-native';
import { FlatListScreen } from 'components/layout/FlatListScreen';
import { Link } from 'components/links/Link';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { AppWebStackParamList } from 'src/navigation/types/appWebStack';
import { EmptyChat } from 'components/chat/EmptyChat';
import { useConversations } from 'src/hooks/useConversations';

type Props = {
  getLink: (conversationId: string) => To<AppWebStackParamList>;
};

export const ConversationsList = ({ getLink }: Props) => {
  const user = useAuthUser();
  const { unreadMessages } = useUnreadMessages();
  const { conversations, fetchConversations, loading, hasAllConversations } =
    useConversations();

  const fetchMore = async () => {
    fetchConversations();
  };

  const renderConversation = ({
    item: conversation,
  }: ListRenderItemInfo<IConversation>) => {
    return (
      <ConversationMemoized
        conversation={conversation}
        unreadCount={unreadMessages[conversation.id]?.length}
        getLink={getLink}
      />
    );
  };

  if (!user) {
    return null;
  }
  if (loading && !conversations.length) {
    return <Spinner m="auto" />;
  }
  if (conversations.length) {
    return (
      <FlatListScreen
        data={conversations}
        renderItem={renderConversation}
        onEndReached={hasAllConversations || loading ? undefined : fetchMore}
        ListFooterComponent={loading ? <Spinner /> : null}
        onEndReachedThreshold={0.1}
      />
    );
  }
  return <EmptyChat />;
};

type ConversationProps = {
  conversation: IConversation;
  unreadCount: number;
  getLink: (conversationId: string) => To<AppWebStackParamList>;
};
const ConversationMemoized = React.memo(
  ({ conversation, unreadCount, getLink }: ConversationProps) => {
    return (
      <Link
        to={getLink(conversation.id)}
        key={conversation.id}
        width="100%"
        m={1}
      >
        <ConversationRow
          conversation={conversation}
          unreadCount={unreadCount}
        />
      </Link>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.conversation.updatedAt.isEqual(
        nextProps.conversation.updatedAt
      ) && prevProps.unreadCount === nextProps.unreadCount
    );
  }
);

ConversationMemoized.displayName = 'ConversationMemoized';
