import { IExercisesConfigYoutube } from 'src/@types/types';
//Zameranie Pozicovanie Pusti Poznamky
export const exercisesStretch: IExercisesConfigYoutube = {
  groundHipStretchKneeFall: {
    id: 'groundHipStretchKneeFall',
    name: {
      en: 'Stretch the hips by dropping the knee',
      sk: 'Streč bedra spadnutím kolena',
    },
    youtubeId: { en: 'C4pr7SgXGoU', sk: 'ETtHTYUuNGQ' },
    keywords: ['stretch', 'ground', 'spineBot', 'pelvis', 'spineMid'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Reduce lower back pain with gravity
Stretch the muscles of the lower back and pelvis

⚙️ Pozicovanie
1️⃣ Lie down on the floor
2️⃣ Bend your knees to a 90 degree angle
3️⃣ Bring one foot to the ankle on the knee of the other leg

▶️ Pusti
Take a deep breath, relax your body
With an exhale, let the knee fall to one side
Come back with a breath

📝 Poznamky
Keep your whole body absolutely relaxed and just let your leg fall
Breathe deeply
 `,
      sk: `🔔 Zameranie
Zníženie bolesti krížov pomocou gravitácie
Natiahnutie svalov dolnej časti chrbta a panvy

⚙️ Pozicovanie
1️⃣ Ľahni si na zem
2️⃣ Ohni kolená do uhla 90 stupňov
3️⃣ Prilož jednu nohu k členku na koleno druhej nohy

▶️ Pusti
Urob hlboký nádych, uvoľni telo
S výdychom nechaj koleno spadnúť na jednu stranu
Vráť sa s nádychom

📝 Poznamky
Maj celé telo absolútne uvoľnené a nechaj iba spadnúť nohu
Dýchaj zhlboka
`,
    },
  },
  standNeckStretchArmsScapulaFix: {
    id: 'standNeckStretchArmsScapulaFix',
    name: {
      en: 'Neck stretch with fixed scapula with hands',
      sk: 'Krk streč so zafixovanou lopatkou rukami',
    },
    youtubeId: { en: 'HgDhhnnD0tE', sk: '5dFuzwvyW6M' },
    keywords: ['stretch', 'stand', 'neck', 'scapula', 'spineMid', 'chest'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of neck and chest muscles

⚙️ Pozicovanie
1️⃣ Stand up naturally

▶️ Pusti
With a breath, place one hand palm out behind your back
Exhale
With an inhale, put your other hand behind your head, palm out behind your neck
With an exhale, roll your head to the side 
⚠️ Make sure you don't move any part of your body other than your head

📝 Poznamky
You can increase the neck stretch by moving your arms
This exercise will relax your neck and increase the mobility of your shoulder blades
Breathe deeply, move slowly and control the movement`,
      sk: `  
🔔 Zameranie
Strečing krku a prsných svalov

⚙️  Pozicovanie
1️⃣ Prirodzene sa postav

▶️ Pusti
S nádychom daj jednu ruku dlaňou von za chrbát
Vydýchni
S nádychom polož druhú ruku za hlavu, dlaňou si chyť krk
S výdychom vytoč hlavu do strany 
⚠️ Daj si pozor aby si nehýbal žiadnou inou časťou tela okrem hlavy

📝 Poznamky
Môžeš zvýšiť streč krku pohybom rúk
Týmto cvikom uvoľníš krk a zvýšiš mobilitu lopatky
Dýchaj zhlboka, hýb sa pomaly a kontroluj pohyb
`,
    },
  },
  groundHandsUp: {
    id: 'groundHandsUp',
    name: {
      en: 'Hands up on the ground',
      sk: 'Ruky hore na zemi',
    },
    youtubeId: { en: 'Nx0etNOrYC0', sk: 'r8dZf-F3Rv0' },
    keywords: [
      'stretch',
      'ground',
      'spineMid',
      'chest',
      'scapula',
      'shoulder',
      'elbow',
      'hand',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Pain test of the pectoral and scapular muscles and their stretching
Test the pain and activation of the rhomboids (Rhomboids)

⚙️ Pozicovanie
1️⃣ Make a start on the ground, lay naturally on the ground
2️⃣ Relax the body

▶️ Pusti
Breathe in, engage core (lower back, lower abdomen)
Exhaling, move your arms in front of your body
Take a deep breath
Exhaling, bring your hands back to the ground
 

📝 Poznamky
Move as slowly as you can
Don't move while inhaling
If you feel pain in your scapula or upper body, try similar exercises targeting these parts`,
      sk: `🔔 Zameranie
Test bolesti prsných a lopatkových svalov a ich natiahnutie
Otestuj bolesť a aktiváciu kosoštvorcových svalov (Rhomboids)

⚙️ Pozicovanie
1️⃣ Urob štart na zemi, prirodzene sa ulož na zemi
2️⃣ Uvoľni telo

▶️ Pusti
Nadýchni sa, zapoj core (spodný chrbát, spodné brucho)
S výdychom presuň ruky pred telo
Sprav hlboký nádych
S výdychom vráť ruky späť na zem
 

📝 Poznamky
Hýb sa najpomalšie ako vieš
Počas nádychu sa nehýb
Pokiaľ cíťiš bolesť v lopatkovej časti tela alebo hornej končatine, vyskúšaj podobné cviky zamerané na tieto časti 
`,
    },
  },
  groundHandsRound: {
    id: 'groundHandsRound',
    name: {
      en: 'Circle hands on the ground',
      sk: 'Kruh rukami na zemi',
    },
    youtubeId: { en: '8b6Cfjeayl4', sk: 'wDTUb0Mxdhg' },
    keywords: [
      'stretch',
      'ground',
      'spineMid',
      'chest',
      'scapula',
      'shoulder',
      'elbow',
      'hand',
      'fingers4Hand',
    ],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Pectoralis and Scapula muscles pain test and stretch
Increasing Scapula mobility

⚙️ Pozicovanie
1️⃣ Make a start on the ground, lay naturally on the ground
2️⃣ Relax the body

▶️ Pusti
Breathe in, engage core (lower back, lower abdomen)
Lift your elbows off the ground as little as possible, a few cm
Exhaling, move your arms over your head, make a circle
Inhale
Exhaling, bring your arms back 
Repeat

📝 Poznamky
Move as slowly as you can
Don't move while inhaling
If you feel pain in your scapula or upper body, try similar exercises targeting these parts`,
      sk: `  
🔔 Zameranie
Test bolesti lopatkových svalov
Strečing prsných svalov

⚙️ Pozicovanie
1️⃣ Urob štart na zemi, prirodzene sa ulož na zemi
2️⃣ Uvoľni telo

▶️ Pusti
Nadýchni sa, zapoj core (spodný chrbát, spodné brucho)
Zdvihni lakte od zeme čo najmenej, pár cm
S výdychom presuň ruky nad hlavu, tým spravíš kruh
Nadýchni sa
S výdychom vráť ruky späť 
 

📝 Poznamky
Hýb sa najpomalšie ako vieš
Počas nádychu sa nehýb
Pokiaľ cíťiš bolesť v lopatkovej Časti tela alebo hornej končatine, vyskúšaj podobné cviky zamerané na tieto časti`,
    },
  },
  rollerHandsRound: {
    id: 'rollerHandsRound',
    name: {
      en: 'Circle hands on the roller',
      sk: 'Kruh rukami na valci',
    },
    youtubeId: { en: 'P39DV7IKdUQ', sk: 'BKo12-mOU4c' },
    keywords: [
      'stretch',
      'roller',
      'spineMid',
      'chest',
      'scapula',
      'spineBot',
      'hand',
      'fingers4Hand',
      'thumb',
      'elbow',
    ],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Spinal fixation
Increase scapular mobility
Stretch the mid-back, massage the rhomboids, stretch the scapular muscles

⚙️Pozicovanie
0️⃣ Use a 90 cm roller
1️⃣ Start by placing the tailbone at the very bottom of the roller
2️⃣ Bend your legs to 90 degrees, this will give you two stable points
3️⃣ Continue placing your spine on the roller
4️⃣ Relax your body

▶️ Pusti
Take a deep breath
Raise your elbows off the ground as little as possible, a few inches
Exhaling, move your arms over your head, this will make a circle
Breathe in
Exhaling, bring your arms back 
Repeat

📝 Poznamky
It is possible to swap the inhale phase for the exhale phase and vice versa
The roller helps well for problem mobility of the shoulder blades
Move slowly and breathe deeply`,
      sk: `  
🔔 Zameranie
Fixácia chrbtice
Zvýšte pohyblivosť lopatky
Natiahnutie strednej časti chrbta, masáž kosoštvorcových svalov (Rhomboidov), natiahnutie lopatkových svalov

⚙️ Pozicovanie
0️⃣ Použi valec 90 cm
1️⃣ Začni umiestnením kostrče na samom spodku valca
2️⃣ Pokrč nohy do 90 stupňov, tým získaš dva stabilné body
3️⃣ Pokračuj v ukladaní chrbtice na valec
4️⃣ Uvoľni svoje telo

▶️ Pusti
Zhlboka sa nadýchni
Zdvihni lakte od zeme čo najmenej, pár cm
S výdychom presuň ruky nad hlavu, tým spravíš kruh
Nadýchni sa
S výdychom vráť ruky späť 
 

📝 Poznamky
Je možné vymeniť fázu nádychu za výdych a naopak
Valec dobre pomáha na problémovú mobilitu lopatiek
Pohybuj sa pomaly a dýchaj zhlboka
`,
    },
  },
  rollerHandsUp: {
    id: 'rollerHandsUp',
    name: {
      en: 'Raising the hands on the roller',
      sk: 'Zdvihnutie rúk na valci',
    },
    youtubeId: { en: 'xZMgLoXFuNw', sk: '7idq3B7Znh4' },
    keywords: [
      'stretch',
      'roller',
      'spineMid',
      'chest',
      'scapula',
      'spineBot',
      'elbow',
    ],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Spinal fixation
Increase scapular mobility
Stretching of the middle back and scapular muscles, massage of the rhomboids

⚙️Pozicovanie
0️⃣️ Use a 90 cm roller
1️⃣ Start by placing the tailbone at the very bottom of the roller
2️⃣ Bend your legs to 90 degrees, this will give you two stable points
3️⃣ Continue placing your spine on the roller
4️⃣ Relax your body

▶️ Pusti
Take a deep breath, relax your body
With an exhale, raise your arms in front of your body
With an inhale, bring your arms back off the ground, a few inches

📝 Poznamky
Help move the upper limbs using the roller, helps with scapular muscle problems
Move your arms very slowly and breathe deeply`,
      sk: `🔔 Zameranie
Fixácia chrbtice
Zvýšenie mobility lopatky
Strečing stredného chrbta a lopatkových svalov, masáž kosoštvorcových svalov (rhomboids)

⚙️ Pozicovanie
0️⃣️ Použi valec 90 cm
1️⃣ Začni umiestnením kostrče na samom spodku valca
2️⃣ Pokrč nohy do 90 stupňov, tým získaš dva stabilné body
3️⃣ Pokračuj v ukladaní chrbtice na valec
4️⃣ Uvoľni svoje telo

▶️ Pusti
Urob hlboký nádych, uvoľni telo
S výdychom zdvihni ruky pred telo
S nádychom vráť ruky späť nad zem, pár cm

📝 Poznamky
Pomoc pohybu horných končatím pomocou valca, pomáha s lopatkovými svalovými problémami
Pohybuj rukami veľmi pomaly a dýchaj zhlboka
`,
    },
  },
  standStickRound: {
    id: 'standStickRound',
    name: {
      en: 'Circles with a stick',
      sk: 'Kruhy s palicou',
    },
    youtubeId: { en: 'OvYv11wMeNk', sk: 'rkxY9zDCC3k' },
    keywords: [
      'stretch',
      'stand',
      'stick',
      'chest',
      'scapula',
      'elbow',
      'hand',
      'spineMid',
      'shoulder',
      'fingers4Hand',
      'thumb',
    ],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Stretching of pectoral and scapular muscles
Wrist flexibility      

⚙️Pozicovanie
0️⃣ Get a long stick
1️⃣ Stretch your arms
2️⃣ Gently grasp the stick in your hands

▶️ Pusti
⚠️ Keep your shoulder blades pulled down
Slowly move one hand backwards with a breath
⚠️ DO NOT move your other hand, use it as resistance in addition to moving your other hand backwards DO NOT move your other hand, use it as resistance of the stick
With an exhale, move your hand back
⚠️ DO NOT move any part of your body other than your hand
⚠️ If you feel pain, slow down the movement even more, exhale and inhale and continue as much as your body allows

📝 Poznamky
This exercise helps well with problematic movement of the upper limbs
Helps with problems such as dropped shoulders (rounded), pain at the shoulder blade and reduces mid and upper back pain  
Breathe deeply, move slowly and control the movement`,
      sk: `🔔 Zameranie
Strečing prsných svalov a lopatkových svalov
Flexibilita zápästia      

⚙️ Pozicovanie
0️⃣ Zober si dlhú palicu
1️⃣ Rozpaž ruky
2️⃣ Jemno si chyť palicu do rúk

▶️ Pusti
⚠️ Drž lopatky stiahnute dole
S nádychom presuň pomaly jednu ruku vzad
⚠️ S druhou rukou nehýb, použi ju ako odpor popri pohybe druhou rukou vzad DO NOT move your other hand, use it as resistance of the stick
S výdychom vráť ruku späť
⚠️ Nehýb s inou časťou tela ako rukou
⚠️ Ak sa objaví bolešt, spomaľ pohyb ešte viac, sprav výdych a nádych a pokračuj koľko ti telo dovolí

📝 Poznamky
Tento cvik dobre pomáha pri problémovom pohybe horných končatín
Pomáha pri problémoch ako spadnuté ramená (zaoblené), bolesť pri lopatke a znižuje bolesť strednej a hornej časti chrbta  
Dýchaj zhlboka, hýb sa pomaly a kontroluj pohyb 
`,
    },
    free: true,
  },
  supraspinatusTestEmptyCan: {
    id: 'supraspinatusTestEmptyCan',
    name: {
      en: 'Supraspinatus pain test - Empty can',
      sk: 'Test bolesti nadtŕňového svalu - Empty can',
    },
    youtubeId: { en: 'b6RBbLQtT4o', sk: '9slKLeNdEHQ' },
    keywords: ['test', 'scapula', 'stand'],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Supraspinatus pain/problem test 

⚙️Pozicovanie
1️⃣ Stand up naturally

▶️ Pusti
With a breath, lift one arm and do an internal rotation so that the thumb is pointing down
With the other hand, perform gentle resistance against the raised arm
 

📝 Poznamky
This test is normally performed by a second person pushing your hand down, but you can also try it yourself
If you feel pain in your shoulder blade or arm the test result is positive`,
      sk: `🔔 Zameranie
Test bolesti/problému nadtŕňového svalu (Supraspinatus) 

⚙️ Pozicovanie
1️⃣ Prirodzene sa postavte

▶️ Pusti
S nádychom zdvihnite jednu ruku a urobte vnútornú rotáciu tak, že palec smeruje dole
S druhou rukou vykonávajte jemný odpor voči zdvihnutej ruke
 

📝 Poznamky
Tento test bežne vykonáva druhá osoba, ktorá tlačí vašu ruku dole, no môžte to skúsiť aj sami
Ak cítite bolesť v lopatke alebo v ramene výsledok testu je pozitívny
`,
    },
  },
  standChairStretchUnderKnee: {
    id: 'standChairStretchUnderKnee',
    name: {
      en: 'Standing hamstring and calf stretch with chair',
      sk: 'Podkolenný streč v stoji so stoličkou',
    },
    youtubeId: { en: 'YRt7y6NdU4w', sk: 'xiQ1sV3dNc4' },
    keywords: ['stretch', 'stand', 'chair', 'pelvis', 'knee', 'ankle', 'foot'],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Stretching of calf hamstrings, calf obliques and calf biceps

⚙️Pozicovanie
0️⃣ Stand near a taller object, such as a chair
1️⃣ Keep your legs straight and perpendicular to the object
2️⃣ Raise your leg about 45 degrees and place it on the chair
3️⃣ Breathe in, lock your core (lower back, lower abdomen)
⚠ Absolutely do not move the rest of your body

▶️ Pusti
With an exhale, move your pelvis downwards
Continue with slow side to side movements of the foot
Slowly bring your foot back to the ground
  with the other leg

📝 Poznamky
This exercise stretches the muscles below the knee (Popliterius, Plantaris, Gastrocnemius) well
You can solve calf, hamstring or knee pain with this
This movement is pronounced in the sagittal plane of the body
Breathe deeply, move slowly, control the movement`,
      sk: `🔔 Zameranie
Strečing zákolenného, šikmého lýtkového a dvojhlavého svalu lýtka

⚙️ Pozicovanie
0️⃣ Postav sa pri nejaký vyšší objekt, napríklad stolička
1️⃣ Nohy maj rovno pri sebe kolmo na ten objekt
2️⃣ Zdvihnite nohu približne o 45 stupňov a polož ju na stoličku
3️⃣ Nadýchni sa, zafixujte jadro (dolná časť chrbta, spodná časť brucha)
⚠ So zvyškom tela absolútne nehýb

▶️ Pusti
S výdychom pohni s panvou smerom nadol
Pokračuj pomalými pohybmi chodidla do strán
Pomaly sa vráť s nohou späť na zem
  s druhou nohou

📝 Poznamky
Tento cvik dobre naťahuje svaly pod kolenom (Popliterius, Plantaris, Gastrocnemius)
Môžete tým vyriešiť bolesť lýtok, podkolenných svalov alebo kolena
Tento pohyb je vyslovene v sagitálnej rovine tela
Dýchajte zhlboka, pohybujte sa pomaly, kontrolujte pohyb`,
    },
  },
  rollerStart: {
    id: 'rollerStart',
    name: {
      en: 'Roller start',
      sk: 'Štart na valci',
    },
    youtubeId: { en: 'Jgcl8k7vnI8', sk: 'ifezQvPLDj0' },
    keywords: ['start', 'roller'],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Always start the exercises on the roller like this to properly fix the spine before the exercise

⚙️Pozicovanie
1️⃣ Take a 90cm roller
2️⃣ Start by placing your tailbone at the very bottom of the roller
3️⃣ Bend your legs to 90 degrees, this will give you two points of stability

▶️ Pusti
Continue to slowly place your spine along the roller
You can help yourself with your hands, for example by pushing off the ground lightly while placing your spine


📝 Poznamky
By lying on the roller you fix your spine`,
      sk: `🔔 Zameranie
Takto vždy začínaj cviky na valci pre poriadne zafixovanie chrbtice pred cvičením

⚙️ Pozicovanie
1️⃣ Zober si 90cm valec
2️⃣ Začni umiestnením kostrče na samom spodku valca
3️⃣ Pokrč nohy do 90 stupňov, získaš tak dva stabilné body

▶️ Pusti
Pokračuj v pomalom ukladaní chrbtice pozdĺž valca
Možeš si pomôcť rukami, napríklad slabým odtláčaním od zeme popri ukladaní chrbtice
 

📝 Poznamky
Ľahnutím na valec si zafixujete chrbticu`,
    },
  },
  standStickBack: {
    id: 'standStickBack',
    name: {
      en: 'Stick backwards in a standing position',
      sk: 'Palica vzad v stoji',
    },
    youtubeId: { en: 'AoB2E5reyCI', sk: 'AoB2E5reyCI' },
    keywords: [
      'stretch',
      'stand',
      'stick',
      'chest',
      'scapula',
      'elbow',
      'spineMid',
      'shoulder',
      'hand',
      'fingers4Hand',
      'thumb',
    ],
    likes: 0,
    description: {
      en: `  
🔔  Zameranie
Stretching of pectoral muscles, scapular muscles and wrist flexibility

⚙️ Preparation
0️⃣ Get a long stick
1️⃣ Stretch your arms
2️⃣ Gently grasp the stick in your hands

▶️ Pusti
⚠️ Keep your shoulder blades down
With a breath, move both hands backwards
With an exhale, move back
⚠️ With the rest of your body, don't move at all
⚠️ If pain appears, slow the movement even more, exhale and inhale and continue as much as your body will allow
 

📝 Poznamky
Use a wooden stick as a resistance object for stretching
Exercise helps improve problems like dropped shoulders, sore shoulder blades or tight chest muscles
Breathe deeply, move slowly and control the movement`,
      sk: `🔔 Zameranie
Natiahnutie prsných svalov, lopatkových svalov a ohybnosť zápästia

⚙️ Pozicovanie
0️⃣ Zober dlhú palicu
1️⃣ Rozpaž ruky
2️⃣ Jemno chyť palicu do dlaní

▶️ Pusti
⚠️ Drž lopatky dole
S nádychom presuň obe ruky dozadu
S výdychom sa vráť späť
⚠️ So zvyškom tela vôbec nehýb
⚠️ Ak sa objaví bolešt, spomaľ pohyb ešte viac, sprav výdych a nádych a pokračuj koľko ti telo dovolí
 

📝 Poznamky
Použi drevennú palicu ako odporový predmet na strečing
Cvik pomáha zlepšiť problémy ako spadnuté ramená, bolesť v lopatke alebo stiahnuté prsné svaly
Dýchaj zhlboka, hýb sa pomaly a kontroluj pohyb 
`,
    },
  },
  groundStart: {
    id: 'groundStart',
    name: {
      en: 'Start ground',
      sk: 'Štart na zemi',
    },
    youtubeId: { en: 'DaiidkoxLMY', sk: 'DaiidkoxLMY' },
    keywords: ['ground', 'start'],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Starting position for exercises on the ground to fix the spine

⚙️ Preparation
1️⃣ Start by sitting on the floor and resting your tailbone on the ground
2️⃣ Bend your legs to 90 degrees to get two stable points

▶️ Pusti
Continue to slowly place your spine on the ground
You can help yourself with your hands, for example by pushing off the ground lightly as you place your spine
 

📝 Poznamky
Lie on the ground to fix your spine`,
      sk: `🔔 Zameranie
Štartovacia poloha pre cviky na zemi pre zafixovanie chrbtice

⚙️ Pozicovanie
1️⃣ Začni tým že si sadneš na zem a kostrč si oprieš o zem
2️⃣ Pokrč nohy do 90 stupňov, získaš tak dva stabilné body

▶️ Pusti
Pokračuj v pomalom ukladaní chrbtice na zem
Možeš si pomôcť rukami, napríklad slabým odtláčaním od zeme popri ukladaní chrbtice
 

📝 Poznamky
Ľahnutím na zem si zafixuješ chrbticu`,
    },
  },
  chairStretchThoracicRotation: {
    id: 'chairStretchThoracicRotation',
    name: {
      en: 'Chest rotation on the chair',
      sk: 'Hrudné rotácie na stoličke',
    },
    youtubeId: { en: '1qMC9pv5c-A', sk: '1qMC9pv5c-A' },
    keywords: [
      'chair',
      'stretch',
      'spineBot',
      'spineMid',
      'chest',
      'scapula',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Improving thoracic rotation, looking for upper body rotation problems

⚙️Pozicovanie
0️⃣ Get a chair
1️⃣ Start by sitting upright in a chair
2️⃣ Take a breath
3️⃣ Raise your forearms to 90 degrees
4️⃣ Raise your elbows to 90 degrees
5️⃣ Move your forearms forward

▶️ Pusti
With an exhale, roll your chest to one side
With an inhale, return to the starting position

📝 Poznamky
Sitting up straight in the chair will fix your spine, it will help to isolate your upper body and won't allow you to move your lower body`,
      sk: `🔔 Zameranie
Zlepšnie hrudnej rotácie, hľadanie rotačných problémov hornej časti tela

⚙️Pozicovanie
0️⃣ Zober si stoličku
1️⃣ Začni tým, že si sadneš vzpriemene na stoličku
2️⃣ Sprav nádych
3️⃣ Zdvihni predlaktia do 90 stupňov
4️⃣ Zdvihni lakte do 90 stupňov
5️⃣ Presuň predlaktia dopredu

▶️ Pusti
S výdychom vytoč hrudník do jednej strany
S nádychom sa vráť späť do začitočnej pozície

📝 Poznamky
Sedením rovno na stoličke si zafixuješ chrbticu, pomôže ti to k izolovaniu hornej časti tela a nedovolí ti hýbať tou spodnou`,
    },
  },
  groundCoreStrengthBasic: {
    id: 'groundCoreStrengthBasic',
    name: {
      en: 'Basic strengthening of the core on the ground',
      sk: 'Základné posilnenie jadra na zemi',
    },
    youtubeId: { en: 'FtvTLS1sofE', sk: 'FtvTLS1sofE' },
    keywords: ['ground', 'strength', 'core', 'pelvis', 'spineMid', 'scapula'],
    likes: 0,
    description: {
      en: `   
🔔  Zameranie
Strengthening the Core, Deep Stabilization System
Reduces lower back pain

⚙️Pozicovanie
1️⃣ Start on the ground on hands and knees
2️⃣ The angle between the pelvis and the legs should be about 100 degrees
3️⃣ Place your hands exactly under your shoulders, 90 degrees

▶️ Pusti
Relax your body
Breathe in, engage Core (lower abdomen, lower back)
Lift your knees a few inches off the ground (2)
Hold the position and hold as long as possible
Repeat

📝 Poznamky
Keep your whole body as relaxed as possible before lifting your knees, really only engage the Core and leave the rest to the body`,
      sk: `🔔 Zameranie
Posilnenie Core, hlbokého stabilizačného systému
Redukuje bolesť spodného chrbátu

⚙️Pozicovanie
1️⃣ Začnite na zemi na kolenách a na rukách
2️⃣ Uhol medzi panvou a nohami má byť cca 100 stupňov
3️⃣ Ruky si daj presne pod ramená, 90 stupňov

▶️ Pusti
Uvoľni telo
Nadýchni sa, zapoj Core (spodné brucho, spodný chrbát)
Zdvihni kolená pár cm od zeme (2)
Zadrž pozíciu a vydrž čo najdlhšie
 

📝 Poznamky
Pred zdvihnutím kolien maj celé telo čo najviac uvoľnené, zapoj naozaj iba Core a zvyšok už nechaj na telo`,
    },
  },
  groundHandsTwist: {
    id: 'groundHandsTwist',
    name: {
      en: 'Dialing hands on the ground',
      sk: 'Vytáčanie rúk na zemi',
    },
    youtubeId: { en: 'cKoB3D5w1vg', sk: 'cKoB3D5w1vg' },
    keywords: [
      'stretch',
      'ground',
      'spineMid',
      'chest',
      'scapula',
      'shoulder',
      'elbow',
      'hand',
      'fingers4Hand',
      'thumb',
    ],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Blade problems test
Increase scapula and wrist mobility

⚙️Pozicovanie
1️⃣ Do a ground start (slowly place your spine on the ground)
2️⃣ Relax your body

▶️ Pusti
Breathe in, engage Core (lower abdomen, lower back)
Lift your elbows off the ground as little as possible, 1/2 inch
With an exhale, dial your wrists, each arm to the opposite side
 

📝 Poznamky
Very safe exercise
Don't move while inhaling
If you feel pain in your shoulder blade or arm, try similar exercises for that part of your body`,
      sk: `🔔 Zameranie
Test problémov s lopatkou
Zvýšenie mobility lopatky a zápästia

⚙️Pozicovanie
1️⃣ Sprav štart na zemi (pomaly si uložíš chrbticu na zem)
2️⃣ Uvoľni si telo

▶️ Pusti
Nadýchni sa, zapoj Core (spodné brucho, spodný chrbát)
Zdvihni lakte od zeme čo najmenej, 1/2 cm
S výdychom vytáčaj zápästia, každú ruku do opačnej strany
 

📝 Poznamky
Veľmi bezpečné cvičenie
Počas nádychu sa nehýbte
Pokiaľ cítiš bolesť v lopatke alebo ramene, skús aj podobné cviky na tú časť tela
`,
    },
  },
  standNeckStretchHandUp: {
    id: 'standNeckStretchHandUp',
    name: {
      en: 'Stretching the neck in a handstand',
      sk: 'Strečing krku v stoji rukou hore',
    },
    youtubeId: { en: 'v-K2Tbv_k5U', sk: 'v-K2Tbv_k5U' },
    keywords: ['stretch', 'stand', 'scapula', 'neck', 'spineMid', 'chest'],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Improves upper limb movement in the sagittal plane of the body
Help neck pain

⚙️Pozicovanie
1️⃣ Stand up naturally
2️⃣ Relax your body

▶️ Pusti
Breathe in, engage Core (lower abdomen, lower back)
Exhaling, roll your head to one side
⚠️ Watch out, keep your chin parallel to the ground
With the next inhale, raise the opposite arm above your head
With an exhale, bring the arm back down and the head to the centre
 

📝 Poznamky
In the beginning I have to turn my head as far as possible to stretch my neck
Don't move your body or neck anymore
Then you try to change the position of the scapula by lifting your arm, thus stretching the muscles that connect the scapula and neck (primarily the Scapula Lifter)`,
      sk: `     
🔔 Zameranie
Zlepši pohyb hornej končatiny v sagitálnej rovine tela
Pomôž bolesti krku

⚙️Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Uvoľni svoje telo

▶️ Pusti
Nadýchni sa, zapoj Core (spodné brucho, spodný chrbát)
S výdychom vytoč hlavu do jednej strany
⚠️ Pozor, drž bradu paralélne so zemou
S ďalším nádychom zdvihni opačnú ruku nad hlavu
S výdychom vráť ruku späť dole a hlavu do stredu
 

📝 Poznamky
Na začiatku musím vytočiť hlavu čo najviac aby si natiahol krk
S telom ani krkom už viac nehýb
Potom sa snažíš zdvihnutím ruky meniť pozíciu lopatky, čím naťahuje svaly, ktoré spájajú lopatku a krk (primárne Zdvíhač lopatky)
`,
    },
  },
  rollerHandsTwist: {
    id: 'rollerHandsTwist',
    name: {
      en: 'Twisting the hands on the roller',
      sk: 'Vytáčanie rúk na valci',
    },
    youtubeId: { en: 'kW7ZQJRT58E', sk: 'kW7ZQJRT58E' },
    keywords: [
      'roller',
      'stretch',
      'spineBot',
      'spineMid',
      'scapula',
      'shoulder',
      'elbow',
      'hand',
      'fingers4Hand',
      'thumb',
    ],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Repairing the connections between the spine and the scapula, i.e. the problems of that area
Test for problems with the forearm

⚙️Pozicovanie
0️⃣ Get a 90cm roller
1️⃣ Make a Start on the roller (slowly place your spine on it)
2️⃣ Relax your body
3️⃣ Extend your arms to a 90 degree angle
4️⃣ Raise your elbows off the ground as little as possible, 1/2cm

▶️ Pusti
Breathe in, lock your core (lower abs, lower back)
With an exhale, roll both wrists out to one side
Inhale
⚠️ Don't move while inhaling
With the next exhale, roll your wrists out to the other side
 

📝 Poznamky
Try to twist your wrists as much as possible
Watch your pain level in your shoulder blade but in a similar area
You can also use finger stretches alongside the dial to increase the stretch of the forearm muscles
📌 forearm muscles have an end in the fingers, so moving them increases forearm stretching`,
      sk: `🔔 Zameranie
Opravovanie spojení medzi chrbticou a lopatkou, čiŽe aj problémov tej oblasti
Test problémov s predlaktím

⚙️Pozicovanie
0️⃣ Zober si 90cm valec
1️⃣ Urob Štart na valci (pomaly si naň ulož chrbticu)
2️⃣ Uvoľni svoje telo
3️⃣ Rozpaž ruky do 90 stupňového uhlu
4️⃣ Zdvihni lakte od zeme čo najmenej, 1/2cm

▶️ Pusti
Nadýchni sa, zafixuj core (spodné brucho, spodný chrbát)
S výdychom vytoč obe zápästia do jednej strany
Nadýchni sa
⚠️ Počas nádychu sa nehýb
S ďalším výdychom vytoč zápästia do druhej strany
 

📝 Poznamky
Snaž sa maximálne vytáčat zápästia
Sleduj si level bolesti v lopatke ale v podobnej oblasti
Môžeš používať aj naťahovanie prstov popri vytáčaní na zvýšenie strečingu predlakťových svalov
📌 predlakťové svaly majú koniec v prstoch, preto ich pohyb zvyšuje strečing predlaktia`,
    },
  },
  standNeckStretchHandBackLow: {
    id: 'standNeckStretchHandBackLow',
    name: {
      en: 'Stretching the neck standing with the arm down backwards',
      sk: 'Strečing krku v stoji roukou dole vzad',
    },
    youtubeId: { en: 'k1GeTobGUHQ', sk: 'k1GeTobGUHQ' },
    keywords: ['stand', 'stretch', 'spineMid', 'scapula', 'neck'],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Neck stretching
Scapula fixation

⚙️Pozicovanie
1️⃣ Stand up naturally
2️⃣ Relax the body

▶️ Pusti
Breathe in, engage core (lower back, lower abdomen)
Exhaling, place one hand behind your back, palm out
Inhale
With the next exhale, roll your head to the opposite side
⚠️ Keep your chin parallel to the ground
With an inhale, bring your head back to the centre

📝 Poznamky
First you have to get your hand behind your back without changing the body position of any other part of your body
⚠️ Don't arch your back
This movement will fix your shoulder blade so it doesn't move anymore
By turning your head, you ensure that the muscles that connect the scapula and the head are stretched, primarily the scapular elevator muscle (levator scapulae)`,
      sk: `🔔 Zameranie
Strečing krku
Zafixovanie lopatky

⚙️Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Uvoľni telo

▶️ Pusti
Nadýchni sa, zapoj core (spodný chrbát, spodné brucho)
S výdychom polož jednu ruku za chrbát dlaňou von
Nadýchni sa
S ďalším výdychom vytoč hlavu do opačnej strany
⚠️ Udržuj si bradu paralélne so zemou
S nádychom vráť hlavu späť do stredu

📝 Poznamky
Najskôr musíš dostať ruku za chrbát bez zmeny polohy tela akejkoľvek inej časti tela
⚠️ Neprehýbaj chrbát
Tento pohyb ti zafixuje lopatku aby sa už nehýbala
Vytáčaním hlavy zabezpečíš to aby sa naťahovali svaly, ktoré spájajú lopatku a hlavu, primárne sval Zdvíhač lopatky (Levator scapulae)
`,
    },
  },
  customSquatDeep: {
    id: 'customSquatDeep',
    name: {
      en: 'Custom deep squat',
      sk: 'Upravený hlbkoký drep',
    },
    youtubeId: { en: '5IR65RkXHzM', sk: '5IR65RkXHzM' },
    keywords: [
      'stretch',
      'stand',
      'pelvis',
      'spineBot',
      'spineMid',
      'hip',
      'knee',
      'ankle',
      'coordination',
    ],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Hip opening
Fixing the spine
Stretching of the pelvic muscles, primarily the Iliopsoas

⚙️Pozicovanie
1️⃣ Stand up naturally and put your feet wider apart, like a wide squat
2️⃣ Open the chest by rolling the arms out (external rotation)
3️⃣ Open the hips by tucking the pelvis under, moving the pelvis slightly forward
4️⃣ Relax the body

▶️ Pusti
Breathe in, lock in your core (lower back, lower abdomen)
⚠️ Don't move your body any more
Exhaling, go SLOWLY into a squat
Keep your knees in place, pointing outward in line with your toes
When you get your thighs parallel to the ground, lock the position and hold
Slowly come back up
⚠️ You must be able to come back up before your legs give out
 

📝 Poznamky
The starting position is important because you don't change it during the movement
Open your chest by pointing your thumbs away from your body
A wide stance of the legs will ensure you open and therefore stretch the pelvic muscles 
Your spine needs to be in one plane throughout the ENTIRE movement, if it starts to get out of alignment in any way, slow down, concentrate on keeping it straight and keep going, you should feel a significant stretch`,
      sk: `🔔 Zameranie
Otvorenie bedier
Zafixovanie chrbtice
Strečing panvových svalov, primárne Iliopsoas

⚙️Pozicovanie
1️⃣ Prirodzene sa postav a daj nohy širšie od seba, ako široký drep
2️⃣ Otvor hrudník vytočením rúk von (externá rotácia)
3️⃣ Otvor bedrá podsadením panvy, mierne panvu posunieš dopredu
4️⃣ Uvoľni telo

▶️ Pusti
Nadýchni sa, zafixuj jadro (spodný chrbát, spodné brucho)
⚠️ Už viac nehýb s telom
S výdychom choď POMALY do drepu
Drž kolená na mieste, smerujúce von v jednej línii so špickami na nohách
Keď dostaneš stehná paralélne so zemou, zafixuj pozíciu a vydrž
Pomaly sa vráť hore
⚠️ Musíš sa stihnúť vrátiť hore skôr než ti poľavia nohy
 

📝 Poznamky
Počiatočná pozícia je dôležitá, pretože ju už nemeníte počas pohybu
Otvorenie hrudníku dosiahneš tým, že tvoje palce budú smerovať od tela
Široký postoj nôh ti zabezpečí otváranie a teda strečing panvových svalov 
Tvoja chrbtica musí byť v jednej rovine počas CELÉHO pohybu, pokiaľ sa ti začne nejakým spôsobom vytáčať, spomaľ, sústreď sa na držanie jej roviny a pokračuj ďalej, mal by si cítiť výrazný strečing
`,
    },
  },
  wallStart: {
    id: 'wallStart',
    name: {
      en: 'Wall start',
      sk: 'Štart o stenu',
    },
    youtubeId: { en: 'nydQ7mbAEr8', sk: 'nydQ7mbAEr8' },
    keywords: ['wall', 'start'],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Fixing the spine against the wall
Preparation for wall exercises

⚙️Pozicovanie
1️⃣ Start by placing your tailbone against the wall
2️⃣ Breathe in
3️⃣ Continue laying your spine against the wall
4️⃣ Relax your body

📝 Poznamky
Try to be as relaxed as possible while placing your spine against the wall
Try to keep your lower back as close to the wall as possible
Don't move your body any more and you're ready for the next exercise`,
      sk: `   
🔔 Zameranie
Zafixovanie chrbtice o stenu
Pozicovanie na cviky pri stene

⚙️Pozicovanie
1️⃣ Začni priložením kostrče o stenu
2️⃣ Nadýchni sa
3️⃣ Pokračuj v pokladaní chrbtice o stenu
4️⃣ Uvoľni telo

📝 Poznamky
Snaž sa byť čo najviac uvoľnený počas ukladanie chrbtice o stenu
Snaž sa mať spodný chrbát priložený čo najviac pri stene
S telom už viac nehýb a si pripravený na ďalší cvik`,
    },
  },
  wallChinTucks: {
    id: 'wallChinTucks',
    name: {
      en: 'Chin tucks',
      sk: 'Priťahovanie brady',
    },
    youtubeId: { en: 'Yrqr3Vd1EhE', sk: 'Yrqr3Vd1EhE' },
    keywords: ['wall', 'stretch', 'neck', 'spineMid'],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Fixing the spine against the wall
Stretching of the neck and spinal muscles
Addressing the postural problem of a forward head

⚙️Pozicovanie
1️⃣ Fix your spine with Start against the wall

▶️ Pusti
Breathe in, tighten your core (lower back, lower abs)
⚠️ Don't move the rest of your body
With an exhale, stretch your neck by pulling your chin towards your body
⚠️ Keep your head still against the wall
 

📝 Poznamky
You can swap the phase of the exhale and inhale:
You inhale when you stretch your neck and exhale when you return your head
Slow movement is essential, feel the neck stretch
It is quite possible that you will feel the stretch all the way between the shoulder blades`,
      sk: `   
🔔 Zameranie
Zafixovanie chrbtice o stenu
Strečing krku a chrbticových svalov
Riešenie posturálneho problému predsunutej hlavy

⚙️Pozicovanie
1️⃣ Sprav Štart o stenu, tým si zafixuješ chrbticu

▶️ Pusti
Nadýchni sa, zafixuj jadro (spodný chrbát, spodné brucho)
⚠️ So zvyškom tela nehýb
S výdychom natiahni krk pritiahnutím brady k telu
⚠️ Stále maj hlavu opretú o stenu
 

📝 Poznamky
Môžeš vymeniť fázu výdychu a nádychu:
Nadýchneš sa keď naťahuješ krk a vydychuješ pri návrate hlavy
Pomalý pohyb je základ, preciťuj strečing krku
Je dosť možné, že budeš cítiť strečing až medzi lopatkami`,
    },
  },
  wallHandRound: {
    id: 'wallHandRound',
    name: {
      en: 'Circle your hands by the wall',
      sk: 'Kruh rukou pri stene',
    },
    youtubeId: { en: 'hl4Tx-kIqPU', sk: 'hl4Tx-kIqPU' },
    keywords: [
      'wall',
      'stretch',
      'neck',
      'spineMid',
      'scapula',
      'chest',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Fixing the spine against the wall
Stretching of the neck, spinal and mid-back muscles
Improving scapular mobility in the sagittal/lateral plane

⚙️Pozicovanie
1️⃣ Fix your spine with Start against the wall
⚠️ Don't move the rest of your body
⚠️ Keep your head against the wall at all times

▶️ Pusti
With a breath, lock in your core (lower back and lower abdomen)
Pull your chin towards your body, (chin tucking exercise)
Keep your neck stretched
With an exhale, lift your arm up
With an inhale, bring your arm back
Repeat

📝 Poznamky
You can swap the phase of exhale and inhale":
with inhale you raise your hand and with exhale you bring your hand back
Slow movement is the key, if you feel pain, slow down even more and continue as long as the pain allows and then come back`,
      sk: `   
🔔 Zameranie
Zafixovanie chrbtice o stenu
Strečing krku, chrbticových svalov a svalov stredného chrbta
Zlepšenie mobility lopatky v sagitálnej/laterálnej rovine

⚙️Pozicovanie
1️⃣ Sprav Štart o stenu, tým si zafixuješ chrbticu
⚠️ So zvyškom tela už nehýb
⚠️ Stále udržuj hlavu opretú o stenu

▶️ Pusti
S nádychom zafixuj jadro (spodný chrbát a spodné brucho)
Pritiahni bradu k telu, (cvik Priťahovanie brady)
Drž krk natiahnutý
S výdychom zdvihni ruku hore
S nádychom vráť ruku späť
 

📝 Poznamky
Môžeš vymeniť fázu výdychu a nádychu":
s nádychom ruku zdvihneš a s výdychom ruku vrátiš späť
Pomaly pohyb je základ, ak cítiš bolesť, spomaľ ešte viac a pokračuj pokiaľ ti to bolesť dovolí a potom sa vráť späť
`,
    },
  },
  wallHeadSide: {
    id: 'wallHeadSide',
    name: {
      en: 'Turning the head against the wall',
      sk: 'Vytáčanie hlavy pri stene',
    },
    youtubeId: { en: 'IxXJIrXkFao', sk: 'IxXJIrXkFao' },
    keywords: ['wall', 'stretch', 'neck', 'spineMid'],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Fixing spine against the wall
Neck and Spine fixators stretch
Forward head posture problem exercise

⚙️Pozicovanie
1️⃣ Fix your spine with Start against the wall
⚠️ Don't move the rest of your body
⚠️ Keep your head against the wall at all times

⚠️ Don't move the rest of your body
⚠️ Keep your head still against your body

▶️ Pusti
With a breath, lock in your core (lower back and lower abdomen)
Pull your chin towards your body, (chin tucking exercise)
Keep your neck stretched
Exhaling, roll your head to one side
⚠ Keep your chin parallel to the ground
With an inhale, return your head to the centre
 

📝 Poznamky
Always do this exercise to both sides
⚠️ Pull up as much as possible with manageable pain, you may feel it in your neck or mid back`,
      sk: `   
🔔 Zameranie
Zafixovanie chrbtice o stenu
Strečing krku a horných chrbticových svalov
Pomoc pri probléme predsunutej hlavy

⚙️Pozicovanie
1️⃣ Sprav Štart o stenu, tým si zafixuješ chrbticu
⚠️ So zvyškom tela už nehýb
⚠️ Stále udržuj hlavu opretú o stenu

⚠️ So zvyškom tela už nehýb
⚠️ Stále maj hlavu priloženú k telu

▶️ Pusti
S nádychom zafixuj jadro (spodný chrbát a spodné brucho)
Pritiahni bradu k telu, (cvik Priťahovanie brady)
Drž krk natiahnutý
S výdychom vytoč hlavu do jednej strany
⚠ Bradu musíš mať paralélne so zemou
S nádychom vrať hlavu do stredu
 

📝 Poznamky
Vždy rob toto cvičenie do oboch strán
⚠️ Vytáčaj čo najviac so zvládnuteľnou bolesťou, môžeš ju cítiť v krku alebo v strede chrbta`,
    },
  },
  standScapulaProtractionMax: {
    id: 'standScapulaProtractionMax',
    name: {
      en: 'Maximum protraction of the shoulder blades',
      sk: 'Maximálna protrakcia lopakty v stoji',
    },
    youtubeId: { en: '68u4uASzuWk', sk: '68u4uASzuWk' },
    keywords: ['stand', 'stretch', 'scapula', 'spineMid', 'neck'],
    likes: 0,
    description: {
      en: `🔔  Zameranie
Scapular protraction and stretching of the interscapular muscles

⚙️Pozicovanie
1️⃣ Stand up naturally
2️⃣ Relax the body

▶️ Pusti
With a breath, lock in your core (lower back and lower abdomen)
Extend arms
Do internal rotation with hands
Bring palms together and cross fingers
With a deep breath, pull your hands forward
⚠️ Keep your hands parallel to the ground during the stretch
Exhale and inhale
With an exhale, bring your arms back
 

📝 Poznamky
You may or may not switch hand crosses after each stretch
Try to move only your arms and keep the rest of your body relaxed
Don't hold your breath, keep breathing, because when you hold your breath the muscles don't contract`,
      sk: `
🔔 Zameranie
Protrakcia lopatiek a strečing medzilopatkových svalov

⚙️Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Uvoľni telo

▶️ Pusti
S nádychom zafixuj jadro (spodný chrbát a spodné brucho)
Predpaž ruky
Sprav internú rotáciu s rukami
Spoj dlane spolu a prekríž prsty
S hlbokým nádychom potiahni ruky smerom dopredu
⚠️ Drž ruky paralélne so zemou počas naťahovanie
Sprav výdych a nádych
S výdychom vráť ruky späť
 

📝 Poznamky
Môžeš a nemusíš vymeniť prekríženie rúk po každom natiahnutí
Snaž sa hýbať iba rukami a zvyšok tela maj uvoľnený
Nezadržuj dych, stále dýchaj, pretože pri zadržanom dychu sa svaly nestrečujú
`,
    },
  },
  standRhomboidStretchOneArm: {
    id: 'standRhomboidStretchOneArm',
    name: {
      en: 'Stretch of the rhomboid muscles by gripping the arm while standing',
      sk: 'Streč kosoštvorcových svalov priťahovaním ruky v stoji',
    },
    youtubeId: { en: 'BN0XTBYJ5KA', sk: 'BN0XTBYJ5KA' },
    keywords: ['stand', 'stretch', 'scapula', 'spineMid'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of the rhomboids using scapular protraction
Interscapular pain test

⚙️Pozicovanie
1️⃣ Stand up naturally
2️⃣ Relax the body

▶️ Pusti
Breathe in, lock your core (lower back and lower abdomen)
Extend one arm
Rotate arm down (internal rotation), thumb pointing towards the ground
With the other hand, grasp the wrist of the raised hand
With an exhale, draw the raised arm across the body to the other side
With an inhale, bring it back
 

📝 Poznamky
After moving your arm across your body, hold the position, inhale and exhale
With the rest of the body, don't move
Repeat with the other arm`,
      sk: `   
🔔 Zameranie
Strečing kosoštvorcových svalov (rhomboids) pomocou protrakcie lopatky
Test bolesti medzilopatkovej časti tela

⚙️Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Uvoľni telo

▶️ Pusti
Nadýchni sa, zafixuj jadro (spodný chrbát a spodné brucho)
Predpaž jednu ruku
Vytoč ruku smerom dolu (interná rotácia), palec smeruje k zemi
Druhou rukou si chyť zápästie zdvihnutej ruky
S výdychom pritiahni zdvihnutú ruku na druhú stranu cez telo
S nádychom ju vráť späť
 

📝 Poznamky
Po presunutí ruky cez telo, zadrž pozíciu, sprav nádych a výdych
So zvyškom tela nehýb
Opakuj s druhou rukou`,
    },
  },
  groundScapulaProtraction: {
    id: 'groundScapulaProtraction',
    name: {
      en: 'Shoulder blade traction on the ground',
      sk: 'Trakcia lopatiek na zemi',
    },
    youtubeId: { en: 'AfQZnPkbQ6Q', sk: 'AfQZnPkbQ6Q' },
    keywords: ['ground', 'stretch', 'scapula', 'spineMid', 'shoulder'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching the interscapular muscles using scapular traction

⚙️Pozicovanie
1️⃣ Start on the ground on your forearms
2️⃣ Straddle legs to hip level
3️⃣ Keep elbows at shoulder level 

▶️ Pusti
Take a deep breath
With an exhale, pull your chest as far off the ground as possible (shoulder blade protraction)
Breathe in and come back out
 

📝 Poznamky
You can swap the inhale and exhale phase, first do a deep exhale, then with an upward movement of the torso, with protraction of the shoulder blades, do an inhale.
(as I do for the second repetition)
DO NOT move with the rest of the body, it must be relaxed
The only movement you do is the movement of the shoulder blades, in other words, the torso`,
      sk: `   
🔔 Zameranie
Strečing medzilopatkových svalov pomocou trakcie lopatiek

⚙️Pozicovanie
1️⃣ Začni na zemi na predlaktiach
2️⃣ Rozkroč nohy na úroveň bedier
3️⃣ Lakte maj na úrovni ramien 

▶️ Pusti
Urob hlboký nádych
S výdychom ťahaj hrudník čo najviac od zeme (protrakcia lopatiek)
S nádychom sa vráť späť
 

📝 Poznamky
Fázu nádychu a výdychu môžeš vymeniť, najprv urob hlboký výdych, potom s pohybom trupu nahor, s protrakciou lopatiek, urob nádych.
(tak ako to robím pri druhom opakovaní)
NEHÝB so zvyškom tela, musí byť uvoľnené
Jediný pohyb, ktorý robíš, je pohyb lopatiek, inými slovami, trupu`,
    },
  },
  groundCoreStrengthFootPos1: {
    id: 'groundCoreStrengthFootPos1',
    name: {
      en: 'Core strengthening with 2nd foot position',
      sk: 'Posilovanie jadra s 2. pozíciou chodidiel',
    },
    youtubeId: { en: 'IL8SPG98b5A', sk: 'IL8SPG98b5A' },
    keywords: [
      'ground',
      'strength',
      'core',
      'pelvis',
      'spineMid',
      'scapula',
      'spineBot',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Strengthening the connection between the pelvis and the lower spine
Increasing the stability of the chest and its connections with other parts

⚙️  Pozicovanie
1️⃣ Start on the ground like you see in the video
2️⃣ The angle of the hips and abdomen must be 90°
3️⃣ Keep your hands exactly under your shoulders
4️⃣ Place the top of your feet on the ground

▶️ Pusti
Relax your body
Activate the core (lower back and lower abdomen) with the nadcyh
Lift your knees very low off the ground, a few inches, the lower the better
Hold your breath and position
Exhale and relax
 

📝 Poznamky
Hold as long as you can
During this exercise,  Zameranie on holding your core and chest, try to keep the rest of your body relaxed
WARNING, this exercise is hard on the ankles, if you have a problem with them, be very careful`,
      sk: `   
🔔 Zameranie
Posilovanie spojení medzi panvou a spodnou chrbticou
Zvýšenie stability hrudníka a jeho spojení s ostatnými časťami

⚙️  Pozicovanie
1️⃣ Začni na zemi ako vidíš na videu
2️⃣ Uhol bedier a bruchom musí byť 90°
3️⃣ Ruky maj presne pod ramenami
4️⃣ Vrchnú časť chodidiel polož na zem

▶️ Pusti
Uvoľni telo
S nádcyhom aktivuj jadro (spodný chrbát a spodné brucho)
Zdvihni kolená veľmi málo od zeme, pár cm, čím menej tým lepšie
Zadrž dych a pozíciu
S výdychom uvoľni
 

📝 Poznamky
Vydrž tak dlho ako vládzeš
Počas tohto cviku sa sústreď na držanie jadra a hrudníku, zvyšok tela sa snaž mať uvoľnený
POZOR, tento cvik je náročný na členky, pokiaľ s nimi máš problém, buď veľmi opatrný`,
    },
  },
  catCow: {
    id: 'catCow',
    name: {
      en: 'Cat Cow',
      sk: 'Mačka a Krava',
    },
    youtubeId: { en: '8VUmRxmoHQI', sk: '8VUmRxmoHQI' },
    keywords: [
      'ground',
      'stretch',
      'spineBot',
      'spineMid',
      'neck',
      'chest',
      'scapula',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of the middle back
Increasing mobility of the spine
Improving the connection between the thoracic and lower spine

⚙️Pozicovanie
1️⃣ Start on the ground on your knees
2️⃣ Spread legs to hip level
3️⃣ Keep hands at shoulder level

▶️ Pusti
Take a deep exhale
Relax your body as much as possible
With a deep breath, lift your chest as high off the ground as possible
Exhale and come back up
 

📝 Poznamky
Your knees and palms are your stabilizing points
⚠️ Don't move the rest of your body
The only movement you make is the movement of your chest/trunk`,
      sk: `   
🔔 Zameranie
Strečing strednej časti chrbta
Zvýšenie mobility chrbtice
Zlepšenie spojení medzi hrudníkom a spodnou chrbticou

⚙️Pozicovanie
1️⃣ Začni na zemi na kolenách
2️⃣ Rozkroč nohy na úroveň bedier
3️⃣ Ruky maj na úrovni ramien

▶️ Pusti
Urob hlboký výdych
Uvoľni telo čo najviac
S hlbokým nádychom zdvihni hrudník čo najvyššie od zeme
S výdychom sa vráť späť
 

📝 Poznamky
Tvoje kolená a dlane sú tvoje stabilizačné body
⚠️ So zvyškom tela nehýb
Jediný pohyb ktorý vykonávaš, je pohyb hruníku/trupu`,
    },
  },
  groundSpineRotationArm: {
    id: 'groundSpineRotationArm',
    name: {
      en: 'Rotation of the spine on the ground with the hand',
      sk: 'Rotácia chrbtice na zemi s rukou',
    },
    youtubeId: { en: 'l6gl3C10deo', sk: 'l6gl3C10deo' },
    keywords: [
      'ground',
      'stretch',
      'spineBot',
      'spineMid',
      'neck',
      'chest',
      'scapula',
      'shoulder',
      'elbow',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Unlocking the deep muscles of the spine with scapular movement (retraction and protraction)

⚙️Pozicovanie
1️⃣ Start on the ground on your knees and place your forearms on the ground
2️⃣ Spread legs to hip level
3️⃣ Keep hands at shoulder level

▶️ Pusti
With a deep breath, raise one arm, this will open the chest on that side (scapular retraction)
With an exhale, move the raised arm under the chest to the other side, try to stretch it as far as possible (scapular protraction)
With an inhalation, bring the arm back
 

📝 Poznamky
You can swap the inhale and exhale phase
With the rest of the body, don't move
The only movement you make is the movement of your arm
The spine naturally rotates with the movement of the arm`,
      sk: `   
🔔 Zameranie
Odomknutie hlbokých svalov chrbtice pomocou pohybu lopatky (retrakcia a protrakcia)

⚙️Pozicovanie
1️⃣ Začni na zemi na kolenách a polož predlaktia na zem
2️⃣ Rozkroč nohy na úroveň bedier
3️⃣ Ruky maj na úrovni ramien

▶️ Pusti
S hlbokým nádychom zdvihni jednu ruku, tým otvoríš hruník na tej strane (retrakcia lopatky)
S výdychom presuň zdvihnutú ruku pod hruníkom na druhú stranu, snaž sa ju natiahnuť co najviac (protrakcia lopatky)
S nádychom vráť ruku späť
 

📝 Poznamky
Môžeš vymeniť fázu nádychu a výdychu
So zvyškom tela nehýb
Jediný pohyb ktorý vykonávaš, je pohyb rukou
Chrbtica sa prirodzene rotuje s pohybom ruky
`,
    },
  },
  groundPelvisStretchKneeToChest: {
    id: 'groundPelvisStretchKneeToChest',
    name: {
      en: 'Pelvic stretch knee to chest with outstretched leg',
      sk: 'Panvový streč koleno ku hrudníku s vystretou nohou',
    },
    youtubeId: { en: 'H7ysyF_GR-g', sk: 'H7ysyF_GR-g' },
    keywords: ['ground', 'stretch', 'pelvis', 'spineBot', 'hip'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Reduction of pain in the lower spine
Stretching of the pelvic muscles (Iliopsoas)

⚙️Pozicovanie
1️⃣ Get down on the ground, do Ground Start (exercise: Ground Start)
2️⃣ Bring your knees to your chest
3️⃣ Inhale, activate core (stapled back, lower abdomen)

▶️ Pusti
With a deep exhale, extend one leg and keep the other leg taped to your chest with both hands
⚠️ Keep your lower back still glued to the ground, you can't have space between the ground and your back
With an inhale, bring the leg back to the chest, repeat with the other leg
  with other leg

📝 Poznamky
Keep the outstretched leg a few inches above the ground, do not place the heel on the ground
Breathe deeply, move the leg slowly and feel the stretch`,
      sk: `   
🔔 Zameranie
Redukcia bolesti v spodnej časti chrbtici
Strečing panvových svalov (Iliopsoas)

⚙️Pozicovanie
1️⃣ Ľahni si na zem, urob Štart na zemi (cvik: Štart zem)
2️⃣ Prilož kolená ku hrudníku
3️⃣ Nadýchni sa, aktivuj jadro (sponý chrbát, spodné brucho)

▶️ Pusti
S hlbokým výdychom vystri jednu nohu a oboma rukami drž druhú nohu prilepenú ku hrudníku
⚠️ Spodný chrbát maj stále prilepený k zemi, nemôžeš mať priestor medzi zemou a chrbátom
S nádychom vráť nohu späť ku hruníku, opakuj s druhou nohou
  with other leg

📝 Poznamky
Vystretú nohu drž pár cm nad zemou, nepokladaj pätu na zem
Dýchaj zhlboka, pohybuj nohou pomaly a preciťuj streč
`,
    },
  },
  groundLowBackStretch: {
    id: 'groundLowBackStretch',
    name: {
      en: 'Relaxation of the lower back',
      sk: 'Uvoľnenie spodného chrbátu',
    },
    youtubeId: { en: 'mZ_3MykwrY8', sk: 'mZ_3MykwrY8' },
    keywords: ['ground', 'stretch', 'spineBot', 'pelvis'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Reduction of pressure in the lower spine

⚙️Pozicovanie
1️⃣ Get down on the ground, do Ground Start (Exercise: Ground Start)

▶️ Pusti
With a deep breath, activate your core and bring your knees to your chest
With both hands, gently press your knees to your chest
Repeat exhale and inhale
 

📝 Poznamky
Try to be as relaxed as possible`,
      sk: `   
🔔 Zameranie
Zníženie tlaku v spodnej chrbtici

⚙️Pozicovanie
1️⃣ Ľahni si na zem, urob Štart na zemi (cvik: Štart zem)

▶️ Pusti
S hlbokým nádychom aktivuj jadro a presuň kolená ku hrudníku
S oboma rukami jemno pritlač kolená k hrudníku
Opakuj výdych a nádych
 

📝 Poznamky
Snaž sa byť čo najviac uvolnený`,
    },
  },
  chairChallengeSagittalPlane: {
    id: 'chairChallengeSagittalPlane',
    name: {
      en: 'Chair challenge in the sagittal plane',
      sk: 'Stoličková výzva v sagitálnej rovine',
    },
    youtubeId: { en: '-I_9KH6LG90', sk: '-I_9KH6LG90' },
    keywords: [
      'stand',
      'chair',
      'strength',
      'challenge',
      'coordination',
      'upperArm',
      'shoulder',
      'chest',
      'core',
      'forearm',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Maximising upper limb movement in the sagittal plane of the body
Increasing arm strength
Improving shoulder joint stabilisation
Improving core cohesion and activation

⚙️Pozicovanie
0️⃣ Pick up a chair (in my case, the legs have square edges)
1️⃣ Stand naturally
2️⃣ Grab the chair by the leg, the lower to the ground the harder it is
3️⃣ With a deep breath, activate your core (lower back and lower abdomen)

▶️ Pusti
⚠️ Do not move ABSOLUTELY any part of your body other than your arm
With an exhale, move your arm in front of you (forearm)
Inhale
With the next exhale, raise your arm above your head
Inhale
With an exhalation, bring the arm back in front of you
Inhale
With an exhale, bring your arm back to the starting position
 

📝 Poznamky
The slower you move the harder it is`,
      sk: `   
🔔 Zameranie
Maximalizovanie pohybu hornej končatiny v sagitálnej rovine tela
Zvýšenie sily ruky
Zlepšenie stabilizácie ramenného kĺbu
Zlepšenie súdržnosti jadra a jeho aktivácia

⚙️Pozicovanie
0️⃣ Zober si stoličku (v mojom prípade majú nohy štvorcové hrany)
1️⃣ Prirodzene sa postav
2️⃣ Chyť stoličku za nohu, čím nižšie k zemi tým to je ťažsie
3️⃣ S hlbokým nádych aktivuj jadro (spodný chrbát a spodné brucho)

▶️ Pusti
⚠️ Nehýb ABSOLÚTNE so žiadnou inou časťou tela okrem ruky
S výdychom presuň ruku pred seba (predpaženie)
Nadýchni sa
S ďalším výdychom zdvihni ruku nad hlavu
Nadýchni sa
S výdychom vráť ruku späť pred seba
Nadýchni sa
S výdychom vráť ruku späť do štartovacej pozície
 

📝 Poznamky
Čím pomalšie sa hýbeš tým to je ťažsie
`,
    },
  },
  groundLowCoreStrengthLegsStraight: {
    id: 'groundLowCoreStrengthLegsStraight',
    name: {
      en: 'Strengthening the core by extending the legs on the ground',
      sk: 'Posilnenie jadra vystretím nôh na zemi',
    },
    youtubeId: { en: 'TH0QUdiJ8KQ', sk: 'TH0QUdiJ8KQ' },
    keywords: ['ground', 'strength', 'core', 'pelvis', 'spineBot'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Core strengthening (lower back and abdomen)
Increase activation of pelvic muscles

⚙️Pozicovanie
1️⃣ Get down on the ground, do Ground Start (Exercise: Ground Start)
2️⃣ Bring your knees to your chest

▶️ Pusti
With a deep breath, activate the core
With an exhale, extend your legs, keep your feet off the ground (a few inches), don't touch the ground with your heels
⚠️ Keep your lower back completely glued to the ground, don't have a gap
Hold the position
With a breath, bring your legs back to your chest
 

📝 Poznamky
Repeating the legs out and back significantly strengthens the core
Slow movement adds significantly to the intensity`,
      sk: `   
🔔 Zameranie
Posilnenie jadra (spodný chrbát a brucho)
Zvýšenie aktivácie panvových svalov

⚙️Pozicovanie
1️⃣ Ľahni si na zem, urob Štart na zemi (cvik: Štart zem)
2️⃣ Prilož kolená k hrudníku

▶️ Pusti
S hlbokým nádychom aktivuj jadro
S výdychom vystri nohy, drž nohy nad zemou (pár cm), nedotýkaj na pätami zeme
⚠️ Maj spodný chrbát úplne prilepený k zemi, nemaj tam židnu medzeru
Zadrž pozíciu
S nádychom vráť nohy späť k hrudníku
 

📝 Poznamky
Opakovaním vystretia a vrátenia nôh výrazne posiľnuješ jadro
Pomalý pohyb výrazne pridáva na intenzite
`,
    },
  },
  chairArmUp: {
    id: 'chairArmUp',
    name: {
      en: 'Raising the chair above the head',
      sk: 'Zdvihnutie stoličky nad hlavu',
    },
    youtubeId: { en: 'eKSFFsYf4kQ', sk: 'eKSFFsYf4kQ' },
    keywords: [
      'stand',
      'chair',
      'strength',
      'coordination',
      'forearm',
      'shoulder',
      'upperArm',
      'chest',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Maximising upper limb movement in the sagittal plane of the body
Increasing arm strength
Improving shoulder joint stabilisation
Improving core cohesion and activation

⚙️Pozicovanie
0️⃣ Pick up a chair (in my case, the legs have square edges)
1️⃣ Stand naturally
2️⃣ Grab the chair by the leg, the lower to the ground the harder it is
3️⃣ With a deep breath, activate your core (lower back and lower abdomen)

▶️ Pusti
With an exhale, move the chair arm overhead
With an inhale, bring it back
 

📝 Poznamky
The slower you move the harder it is
Hold the rest of the calf in place, concentrate on not twisting the different parts of the calf too much to the side, minimum is allowed`,
      sk: `   
🔔 Zameranie
Maximalizovanie pohybu hornej končatiny v sagitálnej rovine tela
Zvýšenie sily ruky
Zlepšenie stabilizácie ramenného kĺbu
Zlepšenie súdržnosti jadra a jeho aktivácia

⚙️Pozicovanie
0️⃣ Zober si stoličku (v mojom prípade majú nohy štvorcové hrany)
1️⃣ Prirodzene sa postav
2️⃣ Chyť stoličku za nohu, čím nižšie k zemi tým to je ťažsie
3️⃣ S hlbokým nádych aktivuj jadro (spodný chrbát a spodné brucho)

▶️ Pusti
S výdychom presuň ruku so stoličkou nad hlavu
S nádychom ju vráť späť
 

📝 Poznamky
Tento cvik je súčasť Stoličkovej výzvy
Čím pomalšie sa hýbeš tým to je ťažsie
Drž zvyšok teľa na miesto, sústreď sa aby sa ti jednotlivé časti teľa veľmi nevykrúcali do strán, minimálne je povolené
`,
    },
  },
  chairArmFront: {
    id: 'chairArmFront',
    name: {
      en: 'Lifting the chair in front of you',
      sk: 'Zdvihnutie stoličky pred seba',
    },
    youtubeId: { en: 'pOOpJhVDLf4', sk: 'pOOpJhVDLf4' },
    keywords: [
      'stand',
      'chair',
      'strength',
      'coordination',
      'forearm',
      'shoulder',
      'core',
      'chest',
      'upperArm',
    ],
    likes: 0,
    description: {
      en: `
🔔 Zameranie   
Maximalizovanie pohybu hornej končatiny v sagitálnej rovine tela
Zvýšenie sily ruky
Zlepšenie stabilizácie ramenného kĺbu
Zlepšenie súdržnosti jadra a jeho aktivácia

⚙️Pozicovanie
0️⃣ Zober si stoličku (v mojom prípade majú nohy štvorcové hrany)
1️⃣ Prirodzene sa postav
2️⃣ Chyť stoličku za nohu, čím nižšie k zemi tým to je ťažsie
3️⃣ S hlbokým nádych aktivuj jadro (spodný chrbát a spodné brucho)

▶️ Pusti
S výdychom presuň ruku so stoličkou pred seba (predpaženie)
S nádychom ju vráť späť
 

📝 Poznamky
Tento cvik je súčasť Stoličkovej výzvy
Čím pomalšie sa hýbeš tým to je ťažsie
Drž zvyšok teľa na miesto, sústreď sa aby sa ti jednotlivé časti teľa veľmi nevykrúcali do strán, minimálne je povolené`,
      sk: `
🔔 Zameranie
Maximising upper limb movement in the sagittal plane of the body
Increasing arm strength
Improving shoulder joint stabilisation
Improving core cohesion and activation

⚙️Pozicovanie
0️⃣ Pick up a chair (in my case, the legs have square edges)
1️⃣ Stand naturally
2️⃣ Grab the chair by the leg, the lower to the ground the harder it is
3️⃣ With a deep breath, activate your core (lower back and lower abdomen)

▶️ Pusti
With an exhale, move the chair arm in front of you (forearm)
With an inhale, bring it back
 

📝 Poznamky
This exercise is part of the Chair Challenge
The slower you move the harder it is
Hold the rest of the calf in place, concentrate on not twisting the different parts of the calf too much to the side, minimum is allowed`,
    },
  },
  groundHamstringsStretchTouchToes: {
    id: 'groundHamstringsStretchTouchToes',
    name: {
      en: 'Hamstring stretch by gripping the toes on the ground',
      sk: 'Hamstringový streč chytením prstov na nohách na zemi',
    },
    youtubeId: { en: 'I_DRBDRAK_M', sk: 'I_DRBDRAK_M' },
    keywords: [
      'ground',
      'stretch',
      'pelvis',
      'spineBot',
      'spineMid',
      'scapula',
      'hip',
      'ankle',
      'foot',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of hamstring muscles (posterior thigh muscles)
Increase mobility
Stretching of the lower and middle back

⚙️Pozicovanie
1️⃣ Sit on the ground
2️⃣ Extend your legs out in front of you

▶️ Pusti
With a deep breath, grasp the tops of the feet with your hands
Hold the position
With an exhale, release

📝 Poznamky
Hold the pose as long as possible`,
      sk: `   
🔔 Zameranie
Strečing hamstringových svalov (zadné stehenné svaly)
Zvýšenie mobility
Naťahovanie spodného a stredného chrbtu

⚙️Pozicovanie
1️⃣ Sadni si na zem
2️⃣ Nohy vystri pred seba

▶️ Pusti
S hlbokým nádychom chyť rukami špičky chodidiel
Drž pozíciu
S výdychom uvoľni

📝 Poznamky
Drž pozíciu čo najdlhšie`,
    },
  },
  mobilityChallengeSitDownStandUp1: {
    id: 'mobilityChallengeSitDownStandUp1',
    name: {
      en: '1. Mobility test sitting on the ground and standing up',
      sk: '1. Test mobility sadnutie na zem a postavenie sa',
    },
    youtubeId: { en: 'KhJb0EFo3lk', sk: 'KhJb0EFo3lk' },
    keywords: ['stand', 'challenge', 'coordination'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Advanced body control, mobility and flexibility test

⚙️Pozicovanie
1️⃣ Stand up naturally
⚠️ Spikes must point straight

▶️ Pusti
With a breath, go slowly into a squat and sit on the floor
⚠️ Your hands must come from the outside of your knees during the movement
⚠️ Don't change the position of your feet
Exhale
With a breath in, get back on your feet

📝 Poznamky
The movement must be smooth`,
      sk: `   
🔔 Zameranie
Pokročilý test kontroly tela, mobility a flexibility

⚙️Pozicovanie
1️⃣ Prirodzene sa postav
⚠️ Špicky musia smerovať rovno

▶️ Pusti
S nádychom choď pomaly do drepu a sadni si na zem
⚠️ Tvoje ruky počas pohybu musia ísť z vonkajšej strany kolien
⚠️ Nemeň pozíciu chodidiel
Vydýchni
S nádychom sa postav späť na nohy

📝 Poznamky
Daný pohyb musí byť plynulý`,
    },
  },
  mobilityChallengeSitDownStandUp2: {
    id: 'mobilityChallengeSitDownStandUp2',
    name: {
      en: '2. Mobility test sitting on the ground and standing up',
      sk: '2. Test mobility sadnutie na zem a postavenie sa',
    },
    youtubeId: { en: 'dD-MmeCpnZU', sk: 'dD-MmeCpnZU' },
    keywords: ['stand', 'challenge', 'coordination'],
    likes: 0,
    description: {
      en: `   
🔔 Zameranie
Pokročilý test kontroly tela, mobility a flexibility

⚙️Pozicovanie
1️⃣ Natural stand
⚠️ The tips of the feet must POINT FORWARD
⚠️ Your Arms during move must go BETWEEN the KNEES
⚠️ Keep knees pointing same way as toes


▶️ Pusti
With Inhale sit down on the ground
⚠️ DO NOT change the position of the feet
Exhale
Stand Up back to start position

📝 Poznamky
It should be one SMOOTH move`,
      sk: `   
🔔 Zameranie
Pokročilý test kontroly tela, mobility a flexibility

⚙️Pozicovanie
1️⃣ Prirodzene sa postav, vytoč nohy smerom von
⚠️ Špicky musia smerovať von

▶️ Pusti
S nádychom choď pomaly do drepu a sadni si na zem
⚠️ Tvoje ruky počas pohybu musia ísť z vnútornej strany kolien
⚠️ Nemeň pozíciu chodidiel
Vydýchni
S nádychom sa postav späť na nohy

📝 Poznamky
Daný pohyb musí byť plynulý`,
    },
  },
  standIliopsoasStretchKneeToChest: {
    id: 'standIliopsoasStretchKneeToChest',
    name: {
      en: 'Iliopsoas stretch in standing knee to chest',
      sk: 'Iliopsoas streč v stoji koleno k hruníku',
    },
    youtubeId: { en: 'OVuhblz-e6g', sk: 'OVuhblz-e6g' },
    keywords: ['stand', 'stretch', 'pelvis', 'coordination'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of the pelvic muscles (Iliopsoas)

⚙️Pozicovanie
1️⃣ Position naturally

▶️ Pusti
With a breath, lift your leg and bring your knee to your chest
With an exhale, press the knee with both hands
With an inhale, bring the leg back
 

📝 Poznamky
Keep the knee pressed in for 2 breaths in and out
Concentrate on the stability of standing on one leg
Always repeat with the other leg`,
      sk: `   
🔔 Zameranie
Strečing panvových svalov (Iliopsoas)

⚙️Pozicovanie
1️⃣ Prirodzene sa postav

▶️ Pusti
S nádychom zdvihni nohu a prilož koleno k hrudníku
S výdychom oboma rukami pritlač koleno
S nádychom vráť nohu späť
 

📝 Poznamky
Drž koleno prilačené po dobu 2 nádychov a výdychov
Sústreď sa na stabilitu státia na jednej nohe
Vždy opakuj aj s druhou nohou
`,
    },
  },
  standQuadricepsStretchHeelToPelvis: {
    id: 'standQuadricepsStretchHeelToPelvis',
    name: {
      en: 'Quadriceps stretching with heel to pelvis',
      sk: 'Quadriceps strečing pätou k panve',
    },
    youtubeId: { en: 'LQTQaYOFuvM', sk: 'LQTQaYOFuvM' },
    keywords: ['stand', 'stretch', 'pelvis', 'hip', 'knee', 'ankle'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Quadriceps stretching (anterior thigh muscles)

⚙️Pozicovanie
1️⃣ Stand naturally, toes pointing forward

▶️ Pusti
With an inhale, bend your leg at the knee and place your heel against your pelvis
Grasp the bent leg with one hand
With an exhale, press the foot against the pelvis and gently move the pelvis forward
With an inhale, bring the leg back
 

📝 Poznamky
Keep the knee pressed in for 2 breaths in and out
Concentrate on the stability of standing on one leg
Always repeat with the other leg`,
      sk: `   
🔔 Zameranie
Quadriceps strečing (predné stehnné svaly)

⚙️Pozicovanie
1️⃣ Prirodzene sa postav, špičky smerujú dopredu

▶️ Pusti
S nádychom pokrč nohu v kolene a prilož pätu k panve
Chyť pokrčenú nohu jednou rukou
S výdychom pritlač nohu o panvu a panvu jemno presuň dopredu
S nádychom vráť nohu späť
 

📝 Poznamky
Drž koleno prilačené po dobu 2 nádychov a výdychov
Sústreď sa na stabilitu státia na jednej nohe
Vždy opakuj aj s druhou nohou`,
    },
  },
  groundRhomboidsStrengthArmsLiftBack: {
    id: 'groundRhomboidsStrengthArmsLiftBack',
    name: {
      en: 'Rectus muscle strengthening with hands back on the ground',
      sk: 'Kosoštvorcové svaly posilnenie rukami vzad na zemi',
    },
    youtubeId: { en: 'J-ThHsfwTgw', sk: 'J-ThHsfwTgw' },
    keywords: ['ground', 'strength', 'spineMid', 'neck', 'scapula'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Strengthening the rhomboids (rhomboids)
Increase scapular mobility, primarily scapular retraction
Helps the problem of dropped/rounded shoulders

⚙️Pozicovanie
1️⃣ Lie on the ground on your stomach facing the ground
2️⃣ Rest hands on the ground

▶️ Pusti
Breathe in and activate your core
Lift your head off the ground a little to activate your neck muscles
With an exhale, lift your arms off the ground and move them behind your back at a 45 degree angle (bring your shoulder blades together)
⚠️ Concentrate on bringing your shoulder blades together (shoulder blade retraction)
With a sigh, bring your hands back to the ground
 

📝 Poznamky
Good exercise for strengthening but also stretching the rhomboids
You can hold the raised hand position for a better strength effect`,
      sk: `   
🔔 Zameranie
Posilnenie kosoštvorcových svalov (rhomboids)
Zvýšenie mobility lopatiek, primárne retrakcia lopatky
Redukcia problému spadnutých/zaoblených ramien

⚙️Pozicovanie
1️⃣ Ľahni si na zem na brucho tvárou k zemi
2️⃣ Upaž ruky na zemi

▶️ Pusti
Nadýchni sa a aktivuj jadro
Trochu zdvihni hlavu od zeme, tým aktivujes krčne svaly
S výdychom zdvihni ruky od zeme a pod 45 stupňovým uhlom ich presuň za chrbát (priťahuj lopatky k sebe)
⚠️ Sústreď sa na priťahovanie lopatiek k sebe (retrakcia lopatky)
S nádychom vráť ruky späť na zem
 

📝 Poznamky
Dobrý cvik na posilnenie ale aj strečing kosoštvorcových svalov
Môžeš zadržať zdvihnutú pozíciu rúk pre lepší silový efekt
`,
    },
    free: true,
  },
  groundRhomboidsStrengthSwimmer: {
    id: 'groundRhomboidsStrengthSwimmer',
    name: {
      en: 'Swimmer on the ground',
      sk: 'Plavec na zemi',
    },
    youtubeId: { en: 'a3sKqxH99xE', sk: 'a3sKqxH99xE' },
    keywords: [
      'ground',
      'strength',
      'spineMid',
      'spineBot',
      'scapula',
      'neck',
      'back',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Increasing the force of the rhomboid muscles
Improvement of scapular mobility, primary retraction
Reduction of dropped/rounded shoulder problem

⚙️Pozicovanie
1️⃣ Lie on the floor on your stomach facing the ground
2️⃣ Rest hands on the ground

▶️ Pusti
With a breath, activate the core (lower back and lower abdomen)
Lift your head a little off the ground
With an exhale, lift your hands off the ground and move them behind your back at a 45 degree angle (bring your shoulder blades together)
⚠️ Concentrate on bringing the shoulder blades together (shoulder blade retraction)
Bend your arms at the elbows
With an exhale, extend your arms out and stretch them forward
With an inhale, bend your arms back again and repeat
 

📝 Poznamky
The slower you do the movement, the more it gains intensity
Bringing the shoulder blades together, i.e. to the spine, is the basis of this exercise`,
      sk: `   
🔔 Zameranie
Zvýšenie sili kosoštvorcových svalov
Zlepšenie mobility lopatiek, primárne retrakcia
Redukcia problému spadnutých/zaoblených ramien

⚙️Pozicovanie
1️⃣ Ľahni si na zem na brucho tvárou k zemi
2️⃣ Upaž ruky na zemi

▶️ Pusti
S nádychom aktivuj jadro (spodný chrbát a spodné brucho)
Zdvihni  hlavu trochu od zeme
S výdychom zdvihni ruky od zeme a pod 45 stupňovým uhlom ich presuň za chrbát (priťahuj lopatky k sebe)
⚠️ Sústreď sa na priťahovanie lopatiek k sebe (retrakcia lopatky)
Zohni ruky v lakťoch
S výdychom vystri ruky a natiahni ich smerom dopredu
S nádychom znovu pokrč ruky vzad a opakuj
 

📝 Poznamky
Čím pomalšie vykonávaš pohyb, tým to viac naberá na intenzite
Priťahovanie lopatiek k sebe teda ku chrbtici je základ tohto cvičenia
`,
    },
  },
  groundSpineRotationThoraxTurning: {
    id: 'groundSpineRotationThoraxTurning',
    name: {
      en: 'Rotation of the spine with the chest on the ground',
      sk: 'Rotácia chrbtice hrudníkom na zemi',
    },
    youtubeId: { en: 'u49eDPHR8V8', sk: 'u49eDPHR8V8' },
    keywords: [
      'ground',
      'stretch',
      'pelvis',
      'spineBot',
      'spineMid',
      'scapula',
      'neck',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Improving thoracic rotation
Help for pain in the middle back
Stretching of the chest and thoracic muscles

⚙️Pozicovanie
1️⃣ Start on the ground on one knee and one foot
⚠️ The angle between the knees must be 90 degrees
2️⃣ Relax the body

▶️ Pusti
With a breath, bend your arms at the elbows, raise your arms to shoulder level, bring your hands together
With an exhale, roll the chest to one side
⚠️ Don't turn your head, keep it in line with your spine
With an inhale return the chest to the centre
Repeat to the other side
  to other side

📝 Poznamky
Deep breathing is important
Keep the other parts of your body completely relaxed, moving only your chest`,
      sk: `   
🔔 Zameranie
Zlepšenie hrudnej rotácia
Pomoc bolesti v strednej časti chrbta
Strečing chrticových svalov a svalov hrudnej časti

⚙️Pozicovanie
1️⃣ Začni na zemi na jednom kolene a jednej nohe
⚠️ Uhol medzi kolenami musí byť 90 stupňov
2️⃣ Uvoľni telo

▶️ Pusti
S nádychom pokrč ruky v lakťoch, zdvihni ruky na úroveň ramien, spoj ruky spolu
S výdychom vytoč hrudník do jednej strany
⚠️ Neotáčaj hlavou, drž ju v jednej rovine s chrbticou
S nádychom vráť hrudník do stredu
Opakuj do druhej strany
  to other side

📝 Poznamky
Hlboké dýchanie je dôležité
Ostatné časti tela maj úplne uvoľnené, hýbeš iba hrudníkom
`,
    },
  },
  standHamstringsStretchGroundTouch: {
    id: 'standHamstringsStretchGroundTouch',
    name: {
      en: 'Hamstring stretch in handstand on the floor',
      sk: 'Hamstringový streč v stoji rukou na zem',
    },
    youtubeId: { en: 'PZq8bdhJXXs', sk: 'PZq8bdhJXXs' },
    keywords: [
      'stand',
      'stretch',
      'coordination',
      'ankle',
      'pelvis',
      'hip',
      'knee',
      'foot',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of hamstring muscles (posterior thigh muscles)

⚙️Pozicovanie
1️⃣ Stand Naturally

▶️ Pusti
With an inhale, touch the ground with one hand while lifting your leg backwards on the same side
With an exhale, come back up

📝 Poznamky
Concentrate on stability on one leg and stretching it
Always keep your back straight
It's a similar movement to the pendulum, arm goes down, leg goes up
This also trains your mind to connect with the movement`,
      sk: `   
🔔 Zameranie
Strečing hamstringových svalov (zadné stehenné svaly)

⚙️Pozicovanie
1️⃣ Prirodzene sa postav

▶️ Pusti
S nádychom sa dotkni zeme jednou rukou a počas toho zdvihni nohu na rovnakej strane vzad
S výdychom sa vráť späť hore

📝 Poznamky
Sústreď sa na stabilitu na jednej nohe a jej strečing
Vždy drž chrbát rovný
Je to podobný pohyb ako kyvadlo, ruka ide dole, noha ide hore
Týmto trénuješ aj spojenie mysle s pohybom
`,
    },
  },
  standForearmStretchFingersPull: {
    id: 'standForearmStretchFingersPull',
    name: {
      en: 'Forearm stretch in standing finger stretch',
      sk: 'Predlaktie streč v stoji ťahanie prstov',
    },
    youtubeId: { en: 'So3mnLg0Caw', sk: 'So3mnLg0Caw' },
    keywords: ['stand', 'stretch', 'elbow', 'hand', 'fingers4Hand'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of the forearm

⚙️Pozicovanie
1️⃣ Stand up naturally

▶️ Pusti
With a breath, raise your arm in front of you (forearm extension)
Rotate the raised arm away from the body (external rotation)
With the other hand, grasp the fingers of the raised hand
With an exhale, pull the fingers of your hand towards you, keeping your arm extended

📝 Poznamky
Hold the fingers extended for a few seconds and then release, repeat a few times and switch hands`,
      sk: `   
🔔 Zameranie
Strečing predlaktia

⚙️Pozicovanie
1️⃣ Prirodzene sa postav

▶️ Pusti
S nádychom zdvihni ruku pred seba (predpaženie)
Vytoč zdvihnutú ruku od tela (externá rotácia)
S druhou rukou si chyť prsti na zdvihnutej ruke
S výdychom ťahaj prsty na ruke k sebe, ruku drž vystretú

📝 Poznamky
Drž potiahnuté prsty niekoľko sekúnd a potom uvoľni, opakuj niekoľko krát a vymeň ruku`,
    },
  },
  standShoulderStretchElbowPull: {
    id: 'standShoulderStretchElbowPull',
    name: {
      en: 'Stretch the shoulders by pulling the elbow while standing',
      sk: 'Streč ramena ťahaním lakťa v stoji',
    },
    youtubeId: { en: 'eobCYwxQfRk', sk: 'eobCYwxQfRk' },
    keywords: ['stand', 'stretch', 'shoulder', 'scapula', 'spineMid', 'elbow'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of scapular muscles

⚙️Pozicovanie
1️⃣ Stand up naturally

▶️ Pusti
With a sigh, bend your arm at the elbow and move it behind your head
With your other hand, grab your bent elbow
With an exhale, pull your elbow down
 

📝 Poznamky
Simple stretch of the scapular muscles`,
      sk: `   
🔔 Zameranie
Strečing lopatkových svalov

⚙️Pozicovanie
1️⃣ Prirodzene sa postav

▶️ Pusti
S nádychom pokrč ruku v lakti a presuň ju za hlavu
Druhou rokou si chyť pokrčený lakeť
S výdychom ťahaj lakeť smerom dolu
 

📝 Poznamky
Jednoduchý streč lopatkových svalov`,
    },
  },
  enduranceStretchHighKnees: {
    id: 'enduranceStretchHighKnees',
    name: {
      en: 'High knees',
      sk: 'Vysoké kolená',
    },
    youtubeId: { en: 'XqWHLqQPrYU', sk: 'XqWHLqQPrYU' },
    keywords: [
      'stand',
      'endurance',
      'hip',
      'knee',
      'pelvis',
      'foot',
      'fullBody',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Coordination training

⚙️Pozicovanie
1️⃣ Stand up naturally

▶️ Pusti
With a breath, activate the core
Bend your arms at the elbows
With an exhale, jump your legs out and try to touch your knees to your palms
 

📝 Poznamky
You can do both inhale and exhale while jumping
Concentrate on the touch of your palms and knees and the gentle impact of your feet on the ground`,
      sk: `   
🔔 Zameranie
Trénovanie koordinácie

⚙️Pozicovanie
1️⃣ Prirodzene sa postav

▶️ Pusti
S nádychom aktivuj jadro
Pokrč ruky v lakťoch
S výdychom vyskakuj nohami a snaž sa dotknúť kolenami dlaní
 

📝 Poznamky
Počas skákanie môžes robiť nádych aj výdych
Sústreď sa na dotyk dlaní a kolien a jemný dopad chodidiel na zem`,
    },
  },
  standStart: {
    id: 'standStart',
    name: {
      en: 'Stand start',
      sk: 'Štart v stoji',
    },
    youtubeId: { en: 'EKn2a_TP-DE', sk: 'EKn2a_TP-DE' },
    keywords: ['stand', 'start'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Relaxation of the body in standing
Positioning for other standing exercises

⚙️Pozicovanie
1️⃣ Stand up naturally

Pusti
Take a small jump
Take a deep breath
Relax your body

📝 Poznamky
Absolutely do not move your body during the jump, let it fall as it feels natural`,
      sk: `   
🔔 Zameranie
Uvoľnenie tela v stoji
Pozicovanie na ďalšie cviky v stoji

⚙️Pozicovanie
1️⃣ Prirodzene sa postav

Pusti
Sprav malý skok
Urob hlboký nádych
Uvoľni telo

📝 Poznamky
Počas skoku absolútne nehýb telom, nechaj ho dopadnúť tak ako mu je prirodzené
`,
    },
  },
  groundCoreStrengthArmLegUp: {
    id: 'groundCoreStrengthArmLegUp',
    name: {
      en: 'Raising hands and feet on the ground',
      sk: 'Zdvihnutie ruky a nohy na zemi',
    },
    youtubeId: { en: 'NzVuYAsdpGA', sk: 'NzVuYAsdpGA' },
    keywords: [
      'ground',
      'strength',
      'coordination',
      'core',
      'spineMid',
      'spineBot',
      'pelvis',
      'neck',
      'scapula',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Help with back pain and postural problems
Increase core stability and strength

⚙️Pozicovanie
1️⃣ Start on the ground on your knees
2️⃣ Straddle legs to hip level
3️⃣ Bring arms to shoulder level

▶️ Pusti
Take a deep breath
With an exhale, lift one leg and the opposite arm
⚠️ Keep your back straight
With an inhale, return the leg and arm to sleep on the floor
 

📝 Poznamky
Pay close attention to your balance when lifting your limbs
Don't move the rest of your body, concentrate on body coordination`,
      sk: `   
🔔 Zameranie
Pomoc s bolesťou chrbátu a posturálnymi problémami
Zvýšenie stability a sily jadra

⚙️Pozicovanie
1️⃣ Začni na zemi na kolenách
2️⃣ Rozkroč nohy na úroveň bedier
3️⃣ Ruky daj na úroveň ramien

▶️ Pusti
Urob hlboký nádych
S výdychom zdvihni jednu nohu a opačnú ruku
⚠️ Chrbát drž stále rovný
S nádychom vráť nohu a ruku spať na zem
 

📝 Poznamky
Dávaj veľký pozor na rovnováhu pri zdvíhaní končatín
So zvyškom tela nehýb, sústreď sa na koordináciu tela
`,
    },
  },
  groundGluteusStretchThighPull1: {
    id: 'groundGluteusStretchThighPull1',
    name: {
      en: '1. Stretch of the gluteal muscles by pulling the thigh',
      sk: '1. Streč sedacích svalov ťahaním stehna',
    },
    youtubeId: { en: 'kPyc4A5Y6sY', sk: 'kPyc4A5Y6sY' },
    keywords: [
      'ground',
      'stretch',
      'spineBot',
      'hip',
      'knee',
      'pelvis',
      'ankle',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of the gluteal and pelvic muscles 

⚙️Pozicovanie
1️⃣ Lie down on the floor, do the exercise: start ground
2️⃣ Place the ankle of one foot on the knee of the other

▶️ Pusti
With a deep breath, grasp the thigh below the knee with both hands
With an exhale, pull your thigh towards your chest
With an inhale, release
  with other leg

📝 Poznamky
Try to keep the chest as relaxed as possible
Breathe deeply, pull slowly`,
      sk: `   
🔔 Zameranie
Strečing sedacích a panvových svalov 

⚙️Pozicovanie
1️⃣ Ľahni si na zem, urob cvik: Štart zem
2️⃣ Prilož členok jednej nohy na koleno druhej

▶️ Pusti
S hlbokým nádychom chyť oboma rukami stehno pod kolenom
S výdychom ťahaj stehno smerom k hrudníku
S nádychom uvoľni
  with other leg

📝 Poznamky
Hrudník sa snaž mať čo najviac uvoľnený
Dýchaj zhlboka, ťahaj pomaly`,
    },
  },
  groundGluteusStretchThighPull2: {
    id: 'groundGluteusStretchThighPull2',
    name: {
      en: '2. Stretch of the gluteal muscles by pulling the thigh',
      sk: '2. Streč sedacích svalov ťahaním stehna',
    },
    youtubeId: { en: 'k1UIdmnuYao', sk: 'k1UIdmnuYao' },
    keywords: [
      'ground',
      'stretch',
      'spineBot',
      'hip',
      'knee',
      'pelvis',
      'ankle',
    ],
    likes: 0,
    description: {
      en: `   
🔔 Zameranie
Stretch Gluteus muscles and Hamstrings
Stretch your pelvis muscles (Iliopsoas)

⚙️Pozicovanie
1️⃣ Lie down on your back
➡️▶️ Do Gound Start: https://youtube.com/shorts/DaiidkoxLMY
2️⃣ Put ankle on the top of other knee

▶️ Pusti
With Deep Inhale grab with both hands your thigh under knee
With Exhale pull the thigh to your chest
Do Inhale
With Exhale straighten your leg 
  with other leg

📝 Poznamky
Keep your Thorax relaxed
Try to lock elbows while pulling then relax Thorax
It is possible to hold the positions
Breathe deeply, Move Slowly, Feel the stretch`,
      sk: `   
🔔 Zameranie
Strečing sedacích a panvových svalov 

⚙️Pozicovanie
1️⃣ Ľahni si na zem, urob cvik: Štart zem
2️⃣ Prilož členok jednej nohy na koleno druhej

▶️ Pusti
S hlbokým nádychom chyť oboma rukami stehno pod kolenom
S výdychom ťahaj stehno smerom k hrudníku
Nadýchni sa
S výdychom vystri potiahnutú nohu
S nádychom uvoľni
  with other leg

📝 Poznamky
Hrudník sa snaž mať čo najviac uvoľnený
Dýchaj zhlboka, ťahaj pomaly`,
    },
  },
  groundPelvisStrengthPelvisUpHold: {
    id: 'groundPelvisStrengthPelvisUpHold',
    name: {
      en: 'Strengthening the pelvis by lifting and holding on the ground',
      sk: 'Posilnenie panvy zdvihnutím a držaním na zemi',
    },
    youtubeId: { en: '_o9kenCxywQ', sk: '_o9kenCxywQ' },
    keywords: [
      'ground',
      'strength',
      'pelvis',
      'spineBot',
      'spineMid',
      'hip',
      'core',
      'thigh',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Strengthening of pelvic and gluteal muscles

⚙️Pozicovanie
1️⃣ Lie down on the floor, do the exercise: start ground
2️⃣ Bend your knees to 90 degrees

▶️ Pusti
Take a deep breath
With an exhale, lift your pelvis a little, use your lower abdomen, place your lower back completely to the ground (no space between your back and the ground)
Lift the pelvis up, activate the sitting muscles
Hold the position for a moment
Release and return to the ground
 

📝 Poznamky
Use the full range of motion that allows you to lift your pelvis
Don't arch your back, keep it straight
 Zameranie on using your lower abdominals and lower back, you're trying to activate the muscles that connect your lower back and pelvis.`,
      sk: `   
🔔 Zameranie
Posilnenie panvových a sedacích svalov

⚙️Pozicovanie
1️⃣ Ľahni si na zem, urob cvik: Štart zem
2️⃣ Pokrč kolená do 90 stupňov

▶️ Pusti
Urob hlboký nádych
S výdychom trochu zdvihni panvu, použi spodné brucho, prilož spodný chrbát úplne k zemi (medzi chrbátom a zemou nie je žiaden priestor)
Zdvihni panvu hore, aktivuj sedacie svaly
Zadrž pozíciu na chvíľu
Uvoľni a vráť sa späť na zem
 

📝 Poznamky
Využi plný rozsah pohybu, ktorý ti dovolí zdvihnúť panvu
Neprehýbaj chrbát, drž ho stále rovný
Zameraj sa na používanie spodnej časti brušných svalov a spodnej časti chrbta, snažíš sa aktivovať svaly, ktoré spájajú spodnú časť chrbta a panvu.
`,
    },
  },
  groundPelvisStrengthPelvisUp: {
    id: 'groundPelvisStrengthPelvisUp',
    name: {
      en: 'Strengthening the pelvis by activating it',
      sk: 'Posilnenie panvy jej aktiváciou',
    },
    youtubeId: { en: 'tMWpM1iNOPY', sk: 'tMWpM1iNOPY' },
    keywords: [
      'ground',
      'strength',
      'pelvis',
      'spineBot',
      'spineMid',
      'hip',
      'thigh',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Strengthening of pelvic muscles

⚙️Pozicovanie
1️⃣ Lie down on the floor, do the exercise: start ground
2️⃣ Bend your knees to 90 degrees

▶️ Pusti
Take a deep breath
With an exhale, activate the lower abdomen and place the lower back fully against the ground (there is no space between the back and the ground)
Relax the abdomen 
 

📝 Poznamky
Try to keep the rest of your body completely relaxed
Repeat several times, at least 10
 Zameranie purely on engaging the abdominals and then the lower back, you are aiming to strengthen the muscles that connect the lower spine and pelvis`,
      sk: `   
🔔 Zameranie
Posilnenie panvových svalov

⚙️Pozicovanie
1️⃣ Ľahni si na zem, urob cvik: Štart zem
2️⃣ Pokrč kolená do 90 stupňov

▶️ Pusti
Urob hlboký nádych
S výdychom aktivuj spodné brucho a prilož spodný chrbát úplne k zemi (medzi chrbátom a zemou nie je žiaden priestor)
Uvoľni brucho 
 

📝 Poznamky
Snaž sa mať zvyšok tela úplne uvoľnený
Opakuj niekoľko krát, minimálne 10
Zameraj sa čisto na zapájanie brušných svalov a potom spodného chrbtu, ide ti o posilnenie svalov, ktoré spájajú spodnú chrbticu a panvu
`,
    },
  },
  standThoraxStretchHandsCrossUp: {
    id: 'standThoraxStretchHandsCrossUp',
    name: {
      en: 'Chest stretch by crossing arms while standing',
      sk: 'Streč hrudníku prekrížením rúk v stoji',
    },
    youtubeId: { en: 'SATv-NE1kEc', sk: 'SATv-NE1kEc' },
    keywords: [
      'stand',
      'stretch',
      'chest',
      'scapula',
      'spineMid',
      'shoulder',
      'elbow',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Chest stretching
Strengthening the deep muscles of the middle back

⚙️Pozicovanie
1️⃣ Natural Standing

▶️ Pusti
Cross your toes with a sigh
⚠️ Keep your elbows crossed
With a deep exhale, activate your core (lower back and lower abdomen) and move your arms up over your head and EVEN a little behind your head
⚠️ Keep the shoulder blades pulled down (shoulder blade depression)
With an inhale, bring the joined hands back
 

📝 Poznamky
It's a good idea to stop in the position where your arms are already overhead, take 1 or 2 breaths in and out, then come back`,
      sk: `   
🔔 Zameranie
Strečing hrudníka
Posilnenie hlbokých svalov stredného chrbtu

⚙️Pozicovanie
1️⃣ Prirodzene sa postav

▶️ Pusti
S nádychom prekríž prsty na rukách
⚠️ Lakte maj pretnuté
S hlbokým výdychom aktivuj jadro (spodný chrbát a spodné brucho) a presuň ruky hore nad hlavu aŽ trochu za hlavu
⚠️ Lopatky drž stiahnuté dole (depresia lopatky)
S nádychom vráť spojené ruky späť
 

📝 Poznamky
Je dobré v pozícii kde máš ruky už nad hlavou zastaviť, spraviť 1 alebo 2 nádychy a výdychy a potom sa vrátiť späť 
`,
    },
    free: true,
  },
  standSpineReleaseBuild: {
    id: 'standSpineReleaseBuild',
    name: {
      en: 'Relaxation and positioning of the spine',
      sk: 'Uvoľnenie a postavenie chrbtice',
    },
    youtubeId: { en: 'KRtr5WwiKi8', sk: 'KRtr5WwiKi8' },
    keywords: [
      'stand',
      'stretch',
      'spineBot',
      'spineMid',
      'pelvis',
      'scapula',
      'neck',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Relaxation of the spine
Stretching the spinal muscles

⚙️Pozicovanie
1️⃣Pozicovanie Naturally

▶️ Pusti
Take a DEEP breath
With a slow exhale, relax the spine vertebrae vertebrae by vertebrae from the neck to the lower back, moving the head to the pelvis
⚠️ If you need to inhale STOP, inhale and continue to move as you exhale
When you reach the lowest point, stop, inhale and exhale
With a slow deep breath you start to build your spine back up vertebra by vertebra
⚠️ If you need to breathe out STOP, breathe out and keep moving with the inhale
 

📝 Poznamky
Very safe exercise
Speed of movement is VERY important here, slow movement is essential
Gravity is used here, which pulls the upper body to the ground and thus stretches the spine and its muscles`,
      sk: `   
🔔 Zameranie
Uvoľnenie chrbtice
Strečing chrbticových svalov

⚙️Pozicovanie
1️⃣ Prirodzene sa postav

▶️ Pusti
Urob HLBOKÝ nádych
S pomalým výdychom uvoľnuješ chrbticu stavec po stavci od krku až po spodný chrbát, pohybom hlavy k panve
⚠️ Pokiaľ sa potrebuješ nadýchnuť ZASTAV SA, nadýchni sa a pokračuj s výdychom v pohybe
Keď dosiahneš najnižší bod, zastav sa, sprav nádych a výdych
S pomalým hlbokým nádychom začneš chrbticu znovu stavať späť stavec po stavci
⚠️ Pokiaľ potrebuješ vydýchnuť ZASTAV SA, vydýchni a pokračuj s nádychom v pohybe
 

📝 Poznamky
Veľmi bezpečný cvik
Rýchlosť pohybu tu je VEĽMI dôležitá, pomalý pohyb je základ
Využíva sa tu gravitácia, ktorá ťahá vrchnú časť tela k zemi a tým naťahuje chrbticu a jej svaly
`,
    },
    free: true,
  },
  groundStretchPelvisButterfly: {
    id: 'groundStretchPelvisButterfly',
    name: {
      en: 'Butterfly stretching on the ground',
      sk: 'Motýľový strečing na zemi',
    },
    youtubeId: { en: 'koK1HEfC8Gg', sk: 'koK1HEfC8Gg' },
    keywords: ['ground', 'stretch', 'pelvis', 'hip', 'knee'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of pelvic muscles

⚙️Pozicovanie
1️⃣ Sit on the floor
2️⃣ Bend your knees to 90 degrees

▶️ Pusti
Bring your feet together so that they are resting on each other
While breathing, keep your back straight and swing your knees up and down
Relax
 

📝 Poznamky
Swing your legs for at least 30 seconds and then release
Keep your back straight`,
      sk: `   
🔔 Zameranie
Strečing panvových svalov

⚙️Pozicovanie
1️⃣ Sadni si na zem
2️⃣ Pokrč kolená do 90 stupňov

▶️ Pusti
Spoj chodidlá dokopy, tak že sa opierajú jedno o druhé
Počas dýchania drž chrbát rovný a kývaj kolenami hore a dole
Uvoľni
 

📝 Poznamky
Kývaj nohami aspoň 30 sekúnd a potom uvoľni
Chrbát drž stále rovný 
`,
    },
  },
  standStretchSideArm: {
    id: 'standStretchSideArm',
    name: {
      en: 'Stretch your arm to the side',
      sk: 'Streč rukou do strany',
    },
    youtubeId: { en: 'h45R6qmPCU8', sk: 'h45R6qmPCU8' },
    keywords: [
      'stand',
      'stretch',
      'scapula',
      'spineMid',
      'neck',
      'spineBot',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of scapular and lateral body muscles

⚙️Pozicovanie
1️⃣ Stand up naturally
2️⃣ Put your feet at shoulder level and a little wider

▶️ Pusti
With a sigh, move one arm in an arc to the other side
⚠️ Keep your arm in line with your body
With an exhale, bring your arm back
 

📝 Poznamky
Repeat to the other side
You can swap the inhale and exhale phase, moving your hand with the exhale and bringing it back with the inhale`,
      sk: `   
🔔 Zameranie
Strečing lopatkových a bočných svalov tela

⚙️Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Chodilá daj na úroveň ramien a trochu širšie

▶️ Pusti
S nadýchom presuň jednu ruku oblúkom na druhú stranu
⚠️ Ruku maj súmerne s telom v jednej línii
S výdychom vráť ruku späť
 

📝 Poznamky
Opakuj aj do druhej strany
Môžeš vymeniť fázu nádychu a výdychu, presúvaš ruku s výdychom a vraciaš ju späť s nádychom
`,
    },
  },
  standStretchSidePelvisArm: {
    id: 'standStretchSidePelvisArm',
    name: {
      en: 'Standing lateral hand stretching',
      sk: 'Bočný strečing rukou v stoji',
    },
    youtubeId: { en: 'gD6i6ulk-FQ', sk: 'gD6i6ulk-FQ' },
    keywords: [
      'stand',
      'stretch',
      'pelvis',
      'scapula',
      'shoulder',
      'spineBot',
      'spineMid',
      'hip',
      'neck',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of scapular and lateral body muscles
Pelvic fixation 

⚙️Pozicovanie
1️⃣ Stand naturally
2️⃣ Keep your feet at shoulder level

▶️ Pusti
Move one foot forward
With a breath, activate the core (lower abdomen and lower back) and bring the pelvis forward a little
With an exhale, lift one arm and make a sideways arc
With an inhale, return the arm to sleep
 

📝 Poznamky
Repeat to the other side
Don't forget to bring your pelvis forward, this will lock it in place and allow the remaining muscles to stretch`,
      sk: `   
🔔 Zameranie
Strečing lopatkových a bočných svalov tela
Zafixovanie panvy 

⚙️Pozicovanie
1️⃣ Prirodzne sa postav
2️⃣ Nohy maj na úrovni ramien

▶️ Pusti
Pohni jednou nohou vpred
S nádychom aktivuj jadro (spodné brucho a spodný chrbát) a predsuň panvu kúsok dopredu
S výdychom zdvihni jednu ruku a urob oblúk do strany
S nádychom vráť ruku spať
 

📝 Poznamky
Opakuj do druhej strany
Nezabudni predsunuť panvu dopredu, tým ju zafixuješ na mieste a zvyšné svaly sa môžu strečovať
`,
    },
  },
  groundSpineRotationKneeFixArmBack: {
    id: 'groundSpineRotationKneeFixArmBack',
    name: {
      en: 'Rotation of the spine with fixation on the knee on the ground',
      sk: 'Rotácia chrbtice s fixáciou o koleno na zemi',
    },
    youtubeId: { en: 'Vt9fLzjMR4E', sk: 'Vt9fLzjMR4E' },
    keywords: [
      'ground',
      'stretch',
      'spineBot',
      'spineMid',
      'neck',
      'scapula',
      'chest',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of the middle spine
Relaxation of the spine using rotational movement

⚙️Pozicovanie
1️⃣ Sit on the floor
2️⃣ Bend your knees to 90 degrees

▶️ Pusti
Take a deep breath
With an exhale, roll your chest to one side
Place the top of your hand (not your palm) on the outside of your knee
Place your other hand behind your back on the floor
Hold the position and breathe deeply
Relax and slowly come back 
 

📝 Poznamky
Hold the position for at least 30 seconds while breathing
Keep your back straight`,
      sk: `   
🔔 Zameranie
Strečing strednej chrbtice
Uvoľnenie chrbtice pomocou rotačného pohybu

⚙️Pozicovanie
1️⃣ Sadni si na zem
2️⃣ Pokrč kolená do 90 stupňov

▶️ Pusti
Urob hlboký nádych
S výdychom vytoč hrudník do jednej strany
Polož hornú časť ruky (nie dlaň) na koleno zvonku
Druhú ruku polož za chrbát na zem
Vydrž v pozícii a dýchaj zhlboka
Uvoľni a pomaly sa vráť späť 
 

📝 Poznamky
Drž v pozícii aspoň 30 sekúnd počas dýchania
Chrbát drž stále rovný
`,
    },
  },
  groundWSit: {
    id: 'groundWSit',
    name: { en: 'W sitting on the ground', sk: 'W sedenie na zemi' },
    youtubeId: { en: 'XImKhdSw8oA', sk: 'XImKhdSw8oA' },
    keywords: ['ground', 'stretch', 'ankle', 'hip', 'knee', 'foot', 'midBody'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Improving ankle mobility

⚙️Pozicovanie
1️⃣ Get on the ground on your knees, the top of your foot resting on the ground
2️⃣ Put feet shoulder level and torso wider

▶️ Pusti
Take a deep breath
With an exhale, very slowly sit on your ankles
Take a deep breath and then rise back up 

📝 Poznamky
Be careful with this exercise and don't overestimate your ankles
Stay seated for a reasonable amount of time so you don't feel a lot of pain in your ankles
Keep your back straight`,
      sk: `   
🔔 Zameranie
Zlepšenie mobility členkov

⚙️Pozicovanie
1️⃣ Daj sa na zem na kolená, vrch chodidla je opretý o zem
2️⃣ Chodidlá daj na úroveň ramien a torchu širšie

▶️ Pusti
Urob hlboký nádych
S výdychom si veľmi pomaly sadni na členky
Dýchaj zhlboka a potom sa zdvihni späť hore

📝 Poznamky
Dávaj pozor pri tomto cviku a nepreceňuj svoje členky
Vydrž v sede primeraný čas aby si necítil veľkú bolesť v členkoch
Drž chrbát rovný
`,
    },
  },
  groundSitSpineStrengthArmsUp: {
    id: 'groundSitSpineStrengthArmsUp',
    name: {
      en: 'Strengthening the spinal muscles with the hands up on the ground',
      sk: 'Posilnenie chrbticových svalov rukami hore na zemi',
    },
    youtubeId: { en: '_dtpSL9Hhy8', sk: '_dtpSL9Hhy8' },
    keywords: [
      'ground',
      'strength',
      'stretch',
      'spineBot',
      'spineMid',
      'neck',
      'scapula',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Improvement of mid-back deep connections/muscles
Strengthening of spinal muscles
Fixing the spine by sitting upright on the floor in a seated position 

⚙️Pozicovanie
1️⃣ Sit on the floor and bend your legs to a 90 degree angle
2️⃣ Place hands half on knees (so they are bent at the elbows)

▶️ Pusti
With a deep breath, move your arms back, they must be in line with your body
With an exhale, extend your arms up, this will pull your shoulder blades down and towards you
⚠️ Keep your back straight
With an inhale, bring your arms back to your body
 

📝 Poznamky
Repeat arm extension at least 10 times
Keep your core active and bring your shoulder blades down and towards you`,
      sk: `   
🔔 Zameranie
Zlepšenie hĺbkových spojení/svalov stredného chrbtu
Posilnenie chrbticových svalov
Zafixovanie chrbtice sedením vzpriamene na zemi v sede 

⚙️Pozicovanie
1️⃣ Sadni si na zem a nohy pokrč do 90 stupňového uhla
2️⃣ Ruky poloč na kolená (takže sú pokrčené v lakťoch)

▶️ Pusti
S hlbokým nádychom presuň ruky dozadu, musia byť v jednej línii s telom
S výdychom vystri ruky hore, tým stiahneš lopatky dole a k sebe
⚠️ Chrbát drž stále rovný
S nádychom vráť ruky späť pri telo
 

📝 Poznamky
Zopakuj vystretie rúk aspoň 10 krát
Drž jadro stále aktívne a lopatky sťahuj poriadne dole a k sebe
`,
    },
  },
  groundSitStretchArms90UpDown: {
    id: 'groundSitStretchArms90UpDown',
    name: {
      en: 'Hands 90° up and down on the ground',
      sk: 'Ruky 90° hore a dole na zemi',
    },
    youtubeId: { en: 'urhN4td34cw', sk: 'urhN4td34cw' },
    keywords: [
      'ground',
      'strength',
      'stretch',
      'spineMid',
      'neck',
      'chest',
      'scapula',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Improvement of mid-back deep connections/muscles
Strengthening of spinal muscles
Improvement of scapular mobility and stretch of the scapular muscles
Fixing the spine by sitting upright on the floor in a seated position 

⚙️Pozicovanie
1️⃣ Sit on the floor in a Turkish sit-up or bend your legs to 90°
2️⃣ Place your hands on your knees
3️⃣ Raise your arms at the elbows
4️⃣ Move your hands next to your calves, the angle at the elbows is 90° 

▶️ Pusti
⚠️ Keep your back straight
With a deep breath, raise your bent arms arms crossed
With an exhale return the arms to sleep in the middle next to the body
Swing your arms away from your body, palms facing behind your back, still keeping a 90° angle at the elbows
Move the arms down behind the back, cross there again
With a breath, bring the arms back to the centre next to the body
Release
 

📝 Poznamky
Repeat at least 10 times`,
      sk: `   
🔔 Zameranie
Zlepšenie hĺbkových spojení/svalov stredného chrbtu
Posilnenie chrbticových svalov
Zlepšenie mobility lopatiek a streč lopatkových svalov
Zafixovanie chrbtice sedením vzpriamene na zemi v sede 

⚙️Pozicovanie
1️⃣ Sadni si na zem do tureckého sedu alebo pokrč nohy do 90°
2️⃣ Polož ruky na kolená
3️⃣ Zdvihni ruky v lakťoch
4️⃣ Presuň ruky vedľa teľa, uhol v lakťoch je 90° 

▶️ Pusti
⚠️ Drž chrbát stále rovný
S hlbokým nádychom zdvihni zohnuté ruky ruky, tým sa prekrížia
S výdychom vráť ruky spať do stredu vedľa tela
Vytoč ruky smerom od tela, dlaňe smerujú za chrbát, stále drž 90° uhol v lakťoch
Presuň ruky dole za chrbát, tam sa znovu prekrížia
S nádychom vráť ruky späť do stredu vedľa tela
Uvoľni
 

📝 Poznamky
Zopakuj to aspoň 10 krát
`,
    },
  },
  groundSitStretchHandsTwist: {
    id: 'groundSitStretchHandsTwist',
    name: {
      en: 'Hands on the ground in a seated position',
      sk: 'Vytáčanie rúk na zemi v sede',
    },
    youtubeId: { en: 'thvQw_y3h5A', sk: 'thvQw_y3h5A' },
    keywords: [
      'ground',
      'stretch',
      'scapula',
      'spineMid',
      'neck',
      'shoulder',
      'elbow',
      'hand',
      'fingers4Hand',
      'thumb',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Increasing scapular mobility and stretching of the scapular muscles
Interscapular pain/problem test

⚙️Pozicovanie
1️⃣ Sit on the floor and bend your knees to 90°
2️⃣ Place hands on knees
3️⃣ Move your bent arms next to your body
4️⃣ Extend your arms next to your body

▶️ Pusti
⚠️ Keep your back straight
With a deep breath, twist the wrists of both hands to opposite sides
With a deep exhale, twist the wrists out to the opposite side
 

📝 Poznamky
Try to rotate your arm as much as possible but still keep your back straight`,
      sk: `   
🔔 Zameranie
Zvýšenie mobility lopatiek a strečing lopatkových svalov
Test bolesti/problému medzilopatkovej oblasti

⚙️Pozicovanie
1️⃣ Sadni si na zem a kolená pokrč do 90°
2️⃣ Polož ruky na kolená
3️⃣ Presuň zohnuté ruky vedľa tela
4️⃣ Upaž ruky vedľa tela

▶️ Pusti
⚠️ Drž chrbát stále vyrovnaný
S hlbokým nádychom vytáčaj zápästia oboch rúk do opačných strán
S hlbokým výdychom vytoč zápästia do duhej strany
 

📝 Poznamky
Snaž sa vytáčať roku čo najviac ale stále drž chrbát vyrovnaný`,
    },
  },
  groundSitStretchScapulaRotationLock: {
    id: 'groundSitStretchScapulaRotationLock',
    name: {
      en: 'Rotation of the shoulder blades on the ground in a sitting position',
      sk: 'Rotácie lopatiek na zemi v sede',
    },
    youtubeId: { en: 'NmYyfM7QUFM', sk: 'NmYyfM7QUFM' },
    keywords: [
      'ground',
      'stretch',
      'spineMid',
      'neck',
      'scapula',
      'shoulder',
      'elbow',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Increasing scapular mobility
Stretching the middle back
Interscapular pain relief

⚙️Pozicovanie
1️⃣ Sit on the floor and bend your legs to 90°
2️⃣ Place your hands on your knees

▶️ Pusti
⚠️ Keep your back straight
With a breath, move your bent arms next to your body
With an exhale, swing one arm downwards
Move both arms to the middle of the back
Grab your fingers, lock your fingers
With an inhale, bring your hands back to the middle and switch directions
  with other side

📝 Poznamky
Move slowly behind the back, feel the stretch
After catching/locking the fingers, take a deep inhale/exhale and then continue with the exercise`,
      sk: `   
🔔 Zameranie
Zvýšenie mobility lopatiek
Strečing strednej časti chrbta
Pomoc bolesti medzilopatkovej časti tela

⚙️Pozicovanie
1️⃣ Sadni si na zem a nohy zohni do 90°
2️⃣ Polož ruky na kolená

▶️ Pusti
⚠️ Drž chrbát stále vyrovnaný
S nádychom presuň zohnuté ruky vedľa tela
S výdychom vytoč jednu ruku smerom dole
Presuň obe ruky do stredu chrbtu
Chyť si prsty, uzamkni prsty
S nádychom vrať ruky späť do stredu a vymeň ich smer
  with other side

📝 Poznamky
Pohyb za chrbtom vykonávaj pomaly, precíť strečing
Po zachytení/zamknutí prstov sprav hlboký nádych/výdych a potom pokračuj ďalej v cviku
`,
    },
  },
  groundStretchSpineBackExtension: {
    id: 'groundStretchSpineBackExtension',
    name: {
      en: 'Spinal extension on the ground',
      sk: 'Extenzia chrbtice na zemi',
    },
    youtubeId: { en: 'A_JRtPM9hNQ', sk: 'A_JRtPM9hNQ' },
    keywords: ['ground', 'stretch', 'spineBot', 'spineMid', 'pelvis'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Release of pressure in the spine
Stretching of abdominal/abdominal and neck muscles

⚙️Pozicovanie
1️⃣ Lie down on the floor facing the ground
2️⃣ Place your hands on the ground at shoulder level next to your head

▶️ Pusti
With a breath in, slowly lift your upper body (back extension) through pre flexion
⚠️ After lifting your torso you can continue with the backward head movement
With an exhale, slowly come back to the ground
 

📝 Poznamky
This is a very safe exercise
Perform it VERY slowly
Keep the rest of your body completely relaxed
This exercise also stretches the pelvic muscles that connect the lower back and pelvis`,
      sk: `   
🔔 Zameranie
Uvoľnenie tlaku v chrbtici
Strečing abdominálnych/brušných a krčných svalov

⚙️Pozicovanie
1️⃣ Ľahni si na zem tvárou k zemi
2️⃣ Ruky polož na zem na úroveň ramien vedľa hlavy

▶️ Pusti
S nádychom pomaly zdvíhaj vrchnú časť tela (extenzia chrbátu) skrz predlakcia
⚠️ Po zdvihnutí trupu môžeš pokračovať s pohybom hlavy vzad
S výdychom sa pomaly vráť späť na zem
 

📝 Poznamky
Ide o veľmi bezpečné cvičenie
Vykonávaj ho VEĽMI pomaly
Zvyšok tela maj úplne uvoľnený
Tento cvik tiež strečuje panvové svaly, ktoré spájajú spodný chrbát a panvu 
`,
    },
  },
  groundStretchStrengthMidBack: {
    id: 'groundStretchStrengthMidBack',
    name: {
      en: 'Stretch and strengthen the deep middle back',
      sk: 'Streč a posilnenie hlbokého stredného chrbtu',
    },
    youtubeId: { en: 'fvPV-qL5zbc', sk: 'fvPV-qL5zbc' },
    keywords: [
      'ground',
      'stretch',
      'strength',
      'spineBot',
      'spineMid',
      'scapula',
      'neck',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Improving scapula and shoulder mobility
Strengthening and stretching of the interscapular muscles

⚙️Pozicovanie
1️⃣ Sit on the floor in a Turkish sit-up or bend your legs 90°
2️⃣ Move your hands behind your back, palms out
3️⃣ Cross your arms

▶️ Pusti
⚠️ Keep your back straight
With a breath, relax your body and pull your shoulder blades as far apart as possible (shoulder blade protraction)
Exhaling, activate the interscapular muscles and pull the shoulder blades as far together as possible (shoulder blade retraction)
 

📝 Poznamky
When retracting the shoulder blades, try to stretch the interscapular muscles as much as possible
Try to squeeze your shoulder blades together as you pull them together, like you're strengthening your back`,
      sk: `   
🔔 Zameranie
Zlepšenie mobility lopatiek a ramena
Posilnenie a strečing medzilopatkových svalov

⚙️Pozicovanie
1️⃣ Sadni si na zem do tureckého sedu alebo pokrč nohy 90°
2️⃣ Ruky presuň za chrbát, dlaňou von
3️⃣ Prekríž ruky

▶️ Pusti
⚠️ chrbát drž stále rovný
S nádychom uvoľni telo a ťahaj lopatky čo najviac od seba (protrakcia lopatiek)
S výdychom aktivuj medzilopatkové svaly a ťahaj lopatky čo najviac k sebe (retrakcia lopatiek)
 

📝 Poznamky
Pri odťahovaní lopatiek sa snaž čo najviac strečnúť medzilopatkové svaly
Pri priťahovaní sa snaž stlačiť loaptky k sebe, ako keď posiluješ chrbát
`,
    },
  },
  groundStretchSpineSpineLegCross: {
    id: 'groundStretchSpineLegCross',
    name: {
      en: 'Mobile spinal stretch by crossing the legs backwards',
      sk: 'Mobilný streč chrbtice prekríženim nohy vzad',
    },
    youtubeId: { en: '4mJUpFyvajM', sk: '4mJUpFyvajM' },
    keywords: ['ground', 'stretch', 'pelvis', 'spineBot', 'spineMid', 'hip'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of pelvic muscles
Improving hip mobility

⚙️Pozicovanie
1️⃣ Lie down on the floor facing the ground

▶️ Pusti
With a breath, slowly lift one leg across your body to the other side and touch the ground with your toes
With an exhale, bring it back
 

📝 Poznamky
Keep the spinal muscles active from the start of the movement to the end
Concentrate on moving one leg, activating the spine and keeping the rest of the body relaxed
Challenging exercise for coordination`,
      sk: `   
🔔 Zameranie
Strečing panvových svalov
Zlepšenie mobility bedier

⚙️Pozicovanie
1️⃣ Ľahni si na zem tvárou k zemi

▶️ Pusti
S nádychom pomaly zdvihni jednu nohu cez telo na druhú stranu a dotkni sa prstami zeme
S výdychom ju vráť späť
 

📝 Poznamky
Drž chrbticové svaly aktívne od žaciatku pohybu po koniec
Sústreď sa na pohyb jednej nohy, aktiváciu chrbtice a zvyšok tela uvoľnený
Náročný cvik na koordináciu, musíš to spraviť na jeden nádych a výdych
`,
    },
  },
  groundStretchThoraxLegCrossArmRound: {
    id: 'groundStretchThoraxLegCrossArmRound',
    name: {
      en: 'Mobile spinal stretch by crossing the legs backwards and arm',
      sk: 'Mobilný streč chrbtice prekríženim nohy vzad a ruky',
    },
    youtubeId: { en: '5z--RnaLjAM', sk: '5z--RnaLjAM' },
    keywords: [
      'ground',
      'stretch',
      'pelvis',
      'spineBot',
      'spineMid',
      'neck',
      'chest',
      'scapula',
      'shoulder',
      'elbow',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Stretching of pelvic, pectoral and scapular muscles
Improving hip mobility

⚙️Pozicovanie
1️⃣ Lie down on the floor facing the ground

▶️ Pusti
With a breath, slowly lift one leg across your body to the other side and touch the ground with your toes
While inhaling after moving the leg, continue with the arm movement around the body
Stop
With an exhale, bring it back first the arm and then the leg
 

📝 Poznamky
Keep the spinal muscles active from the start of the movement to the end
Concentrate on moving one leg, activating the spine and keeping the rest of the body relaxed
Tough exercise to coordinate, you have to do it in one breath in and out
After the leg movement, try to stretch your arm as much as possible, this will stretch your pectoral muscles`,
      sk: `   
🔔 Zameranie
Strečing panvových, prsných a lopatkových svalov
Zlepšenie mobility bedier

⚙️Pozicovanie
1️⃣ Ľahni si na zem tvárou k zemi

▶️ Pusti
S nádychom pomaly zdvihni jednu nohu cez telo na druhú stranu a dotkni sa prstami zeme
Počas nádychu po presune nohy, pokračuj s pohybom ruky okolo tela
Zastav sa
S výdychom ju vráť späť najskôr ruku a potom nohu
 

📝 Poznamky
Drž chrbticové svaly aktívne od žaciatku pohybu po koniec
Sústreď sa na pohyb jednej nohy, aktiváciu chrbtice a zvyšok tela uvoľnený
Náročný cvik na koordináciu, musíš to spraviť na jeden nádych a výdych
Po pohybe nohy sa snažíš čo najviac natiahnúť ruku, tým si natiahneš prsné svaly
`,
    },
  },
  gripTypes: {
    id: 'gripTypes',
    name: {
      en: 'Grip types',
      sk: 'Typy úchopu tyčky',
    },
    youtubeId: { en: 'vOHPyS7InHY', sk: 'vOHPyS7InHY' },
    keywords: ['bars', 'strength', 'pullUps', 'chinUps', 'start'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
See 4 types of grip
The exercise I'm demonstrating is called the Australian Overhand Stroke
Use types 2 and 4 because this grip pulls your body slightly away from the bar as you lift, which is beneficial for other movements such as the Muscle Up
With the 1st and 3rd grip your body is pointing directly towards the bar, which means a different angle where you have more trouble getting your body over the bar
Similarly, in the 2nd and 4th grip you strengthen the flexors of the forearm more
That's why I recommend learning the 2nd and 4th grip

⚙️Pozicovanie
0️⃣ Get a straight bar
1️⃣ Place your hands at shoulder level
2️⃣ Grab the bar with a strong grip
3️⃣ With the opening breath, activate the core (lower back and lower abdomen) and elbows

▶️ Pusti
Pull yourself up to the bar
Exhale when your head is above the bar
Inhale as you move away from the bar, as you move down
 
📝 Poznamky
Use the full potential of your forearms and fingers
By training the thumb on the hand you achieve greater grip stability, as well as training the thumb flexor muscle, which is very important
Taking a thumbless grip engages the extensors of the forearm more because you don't use the opposing force, the thumb`,
      sk: `   
🔔 Zameranie
Pozri sa na 4 typy úchopu tyčky/hrazdy 
Cvik na ktorom to predvádzam sa volá Austrálsky zdvih nadhmatom
Používaj 2. a 4. typ, pretože pri tomto úchope tvoje telo pri zdvihu priťahuješ mierne od tyčky, čo je prospešné pre Ďalšie pohyby ako je napríklad Muscle up
Pri 1. a 3. úchope tvoje telo smeruje primo k tyčke, čo znamená iný uhol pri ktorom máš väčší problém dostať telo nad tyčku
Rovnako tak pri 2. a 4. úchope viac posiluješ flexory predlaktia
Preto odporúčam naučiť sa 2. a 4. úchop

⚙️Pozicovanie
0️⃣ Zober si rovnú tyčku/hrazdu
1️⃣ Ruky polož na úroveň ramien
2️⃣ Chyť tyčku silným úchopom
3️⃣ S úvodným nádychom aktivuj jadro (spodný chrbát a spodné brucho) a lakte

▶️ Pusti
Pritiahni sa ku hrazde
Vydýchni keď máš hlavu nad hrazdou
Nadýchni sa pri pohybe od hrazdy, pri pohybe dole
 

📝 Poznamky
Využitie plného potenciálu predlaktia a prstov
Poučitím palca na ruke dosiahneš väčšej stability úchopu, rovnako tak trénuješ aj sval flexor palca, ktorý je veľmi dôležitý
Pričom úchop bez palca viac zapája extenzory predlaktia, pretože nevyužívaj protistojnú silu, teda palec 
`,
    },
  },
};
