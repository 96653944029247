import { ArrowBackIcon, IconButton, Text } from 'native-base';
import { DarkRow } from 'components/layout/views';
import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Colors } from 'src/theme/colors';
import React from 'react';
import { useNavigation } from '@react-navigation/native';

export const StackHeader = (props: NativeStackHeaderProps) => {
  const { top } = useSafeAreaInsets();
  const navigation = useNavigation();

  return (
    <DarkRow
      shadow={1}
      pt={`${top + 12}px`}
      p={3}
      pl={4}
      _dark={{
        bg: Colors.bgHeaderDark,
      }}
      alignItems="center"
      space={2}
    >
      {props.back && (
        <IconButton
          ml={-2}
          icon={
            <ArrowBackIcon
              _dark={{ color: Colors.bgWhite }}
              color={Colors.bgDark}
            />
          }
          onPress={navigation.goBack}
        />
      )}
      {props.options.headerLeft?.({ canGoBack: !!props.back })}

      <Text numberOfLines={1} fontSize={20} mr="auto">
        {props.options.title}
      </Text>
      {props.options.headerRight?.({ canGoBack: !!props.back })}
    </DarkRow>
  );
};
