import {
  Column,
  Divider,
  KeyboardAvoidingView,
  Row,
  Spinner,
  useBreakpointValue,
} from 'native-base';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuthUser } from 'src/state/state';
import { IConversation, IConversationThread, IMessage } from 'src/@types/api';
import {
  fetchMessages,
  fetchThreadById,
  onFetchLastMessage,
  sendMessage,
  setReadMessage,
} from 'src/firebase/conversations';
import {
  FlatList,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native';
import { Message } from 'components/chat/Message';
import { useUnreadMessages } from 'src/hooks/useUnreadMessages';
import { useConversations } from 'src/hooks/useConversations';
import { MessageInput } from 'components/chat/MessageInput';
import { FlatListScreen } from 'components/layout/FlatListScreen';
import { useMenuDimensions } from 'src/hooks/useMenuDimensions';
import { isWeb } from 'src/utils/constants';
import { CONTENT_MAX_WIDTH } from 'src/theme/constants';
import { ChatHeader } from 'components/chat/ChatHeader';
import { useConversationPhotos } from 'src/hooks/useConversationPhotos';
import { ScrollButton } from 'components/chat/ScrollButton';

type Props = {
  conversationId: string;
  threadId: string;
};

const SCROLL_THRESHOLD = 50;
export const MessagesList = ({ conversationId, threadId }: Props) => {
  const user = useAuthUser();
  const { paddingBottom } = useMenuDimensions();
  const isSmall = useBreakpointValue([true, false]);

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasAllMessages, setHasAllMessages] = useState(false);
  const [conversation, setConversation] = useState<IConversation>();
  const [thread, setThread] = useState<IConversationThread>();
  const [showScrollButton, setShowScrollButton] = useState(false);

  const scrollRef = useRef<FlatList<IMessage>>(null);
  const { isUnread } = useUnreadMessages();
  const isMessageUnread = isUnread(conversationId, threadId);

  const { getConversationById } = useConversations();

  const { getUserPhoto, isGroupChat } = useConversationPhotos(conversation);

  useEffect(() => {
    getConversationById(conversationId).then(setConversation);
  }, [conversationId, getConversationById]);

  useEffect(() => {
    fetchThreadById(conversationId, threadId).then(setThread);
  }, [threadId, conversationId]);

  useEffect(() => {
    if (user) {
      return onFetchLastMessage(conversationId, threadId, (reply) =>
        setMessages((replies) => {
          if (replies[0]?.id === reply.id) {
            return replies;
          }
          return [reply, ...replies];
        })
      );
    }
  }, [conversationId, threadId, user]);

  useEffect(() => {
    if (isMessageUnread && user) {
      setReadMessage(user, conversationId, threadId);
    }
  }, [conversationId, threadId, isMessageUnread, user]);

  const renderReply = useCallback(
    ({ item }: ListRenderItemInfo<IMessage>) => {
      return (
        <Message
          key={item.id}
          reply={item}
          photoUrl={isGroupChat ? getUserPhoto(item.senderUid) : undefined}
        />
      );
    },
    [getUserPhoto, isGroupChat]
  );

  if (!user || !conversation || !thread) {
    return null;
  }

  const fetchMore = async () => {
    setLoading(true);
    const newReplies = await fetchMessages(
      conversationId,
      threadId,
      messages[messages.length - 1]
    );
    setMessages((oldReplies) => [...oldReplies, ...newReplies]);
    setLoading(false);
    if (newReplies.length === 0) {
      setHasAllMessages(true);
    }
  };

  const onSendMessage = (text: string) => {
    sendMessage(
      user,
      text,
      conversation.participants.filter((friend) => friend.uid !== user.uid),
      thread.type,
      thread?.threadName,
      threadId
    );
  };

  const onScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (e.nativeEvent.contentOffset.y > SCROLL_THRESHOLD && !showScrollButton) {
      setShowScrollButton(true);
    } else if (
      e.nativeEvent.contentOffset.y < SCROLL_THRESHOLD &&
      showScrollButton
    ) {
      setShowScrollButton(false);
    }
  };

  const flatList = (
    <>
      <FlatListScreen
        scrollRef={scrollRef}
        data={messages}
        renderItem={renderReply}
        onEndReached={loading || hasAllMessages ? undefined : fetchMore}
        onEndReachedThreshold={0.1}
        ListFooterComponent={loading ? <Spinner /> : undefined}
        keyExtractor={(item) => item.id}
        animated
        inverted
        contentContainerStyle={{
          paddingTop: 0,
        }}
        onScroll={onScroll}
        scrollEventThrottle={16}
      />
      {showScrollButton && <ScrollButton scrollRef={scrollRef} />}
      <Row
        p={4}
        maxW={CONTENT_MAX_WIDTH}
        mx="auto"
        w="full"
        justifyContent="center"
      >
        <MessageInput onSend={onSendMessage} />
      </Row>
    </>
  );

  if (isWeb) {
    return (
      <>
        {isSmall && (
          <>
            <ChatHeader p={4} conversation={conversation} thread={thread} />
            <Divider />
          </>
        )}
        {flatList}
        <Column h={`${paddingBottom}px`} w="full" />
      </>
    );
  }

  return (
    <KeyboardAvoidingView flexGrow={1} behavior="position">
      <Column h="full">{flatList}</Column>
    </KeyboardAvoidingView>
  );
};
