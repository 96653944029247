import { Link } from 'components/links/Link';
import { Routes } from 'src/navigation/routes';
import { Column, View } from 'native-base';
import { TextSmall } from 'components/typography/text';
import { DarkColumn, DarkRow } from 'components/layout/views';
import React from 'react';
import { MuscleImage } from 'components/muscles/MuscleImage';
import { IMuscle } from '../../@types/api';
import { FavoriteMuscleButton } from 'components/buttons/FavoriteMuscleButton';
import { useIsPremium } from 'hooks/useIsPremium';
import { PremiumOverlay } from 'components/PremiumOverlay';

type Props = {
  muscle: IMuscle;
  isLoggedIn: boolean;
};

export const Muscle = React.memo(({ muscle, isLoggedIn }: Props) => {
  const { id } = muscle;
  const { hasPremiumMuscles } = useIsPremium();
  // const hasLikes = muscle.likes !== undefined && muscle.likes > 0;

  return (
    <DarkColumn flex={1} shadow={2} borderRadius={5} overflow="hidden">
      <Column flex={1} borderRadius={5} overflow="hidden">
        <View flex={1}>
          <Link
            to={{
              screen: Routes.MuscleDetail,
              params: { id },
            }}
            h="full"
            w="full"
            _web={{
              pt: '100%',
              h: undefined,
            }}
          >
            <MuscleImage id={id} />
          </Link>
        </View>
        <DarkRow p={2} pr={[0, 2]} h="60px" alignItems="center">
          <TextSmall flex={1} bold noOfLines={3}>
            {muscle.name}
          </TextSmall>
          <Column alignItems="center">
            {isLoggedIn && <FavoriteMuscleButton muscleId={muscle.id} />}
          </Column>
        </DarkRow>
      </Column>
      {muscle.showPremium && <PremiumOverlay hasPremium={hasPremiumMuscles} />}
    </DarkColumn>
  );
});

Muscle.displayName = 'Muscle';
