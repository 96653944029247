import styled from 'styled-components';
import React from 'react';
import { Exercise } from 'components/exercises/Exercise';
import { Screen } from 'components/layout/Screen';
import { SCREEN_MAX_WIDTH } from 'src/theme/constants';
import { useTranslation } from 'react-i18next';
import { TextButton } from 'components/buttons/TextButton';
import { FilterIcon } from 'components/icons/icons';
import { IconButton, Row, Spinner, Text } from 'native-base';
import { Colors } from 'src/theme/colors';
import { useAuthUser } from 'src/state/state';
import { IFavoriteExercise } from 'src/@types/types';
import { NumberBadge } from 'components/NumberBadge';
import { useIsPremium } from 'hooks/useIsPremium';

type Props = {
  exercises: IFavoriteExercise[];
  loading?: boolean;
  title?: string;
  onShowFilters?: () => void;
  fetchMore?: () => void;
  filtersCount: number;
};

export const ExercisesList = ({
  exercises,
  loading,
  title,
  onShowFilters,
  fetchMore,
  filtersCount,
}: Props) => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const { hasPremiumExercises } = useIsPremium();
  const renderExercise = (exercise: IFavoriteExercise) => {
    return (
      <Exercise key={exercise.id} exercise={exercise} isLoggedIn={!!user} />
    );
  };

  const mainButton = (
    <Row alignSelf="flex-start">
      <TextButton leftIcon={<FilterIcon />} onPress={onShowFilters}>
        {t('filter.filters')}
      </TextButton>
      <NumberBadge value={filtersCount} bg={Colors.primary} />
    </Row>
  );

  const filterButton = (
    <Row>
      <IconButton icon={<FilterIcon />} onPress={onShowFilters} />
      <NumberBadge value={filtersCount} bg={Colors.primary} />
    </Row>
  );

  return (
    <Screen
      pb={20}
      maxW={SCREEN_MAX_WIDTH}
      loading={loading && exercises.length === 0}
      title={title}
      mainButton={mainButton}
      rightButton={filterButton}
      withFooter={!hasPremiumExercises}
    >
      {exercises.length ? (
        <>
          <ExercisesBlock>{exercises.map(renderExercise)}</ExercisesBlock>
          {fetchMore && (
            <TextButton onPress={fetchMore}>
              {t('general.fetchMore')}
            </TextButton>
          )}
          {loading && <Spinner style={{ height: 44 }} />}
        </>
      ) : (
        <Text>{t('exercise.noExercises')}</Text>
      )}
    </Screen>
  );
};

const ExercisesBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 16px;

  @media (min-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
`;
