import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { isDev } from 'src/utils/constants';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDwHLC4AY8svxe40Th6qiEd0HpouterHLk',
  authDomain: 'recovery-tom-prod.firebaseapp.com',
  projectId: 'recovery-tom-prod',
  storageBucket: 'recovery-tom-prod.appspot.com',
  messagingSenderId: '560521790755',
  appId: '1:560521790755:web:b2aadd1412847e9a46f896',
  measurementId: 'G-KYN77RH375',
};
const devConfig = {
  apiKey: 'AIzaSyDL_wBIO-H2M0N56tM5W6UHqx98bRXx76Y',
  authDomain: 'recovery-tom-dev-41c50.firebaseapp.com',
  databaseURL:
    'https://recovery-tom-dev-41c50-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'recovery-tom-dev-41c50',
  storageBucket: 'recovery-tom-dev-41c50.appspot.com',
  messagingSenderId: '100082901688',
  appId: '1:100082901688:web:dd6ef74aa8b63fa99e4a4d',
  measurementId: 'G-REXJ4B3F83',
};

const app = initializeApp(isDev ? devConfig : firebaseConfig);

if (!isDev) {
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      '6LeEGP8kAAAAAMmPdgv9My45UwkPR8EHWJquo-KO'
    ),
    isTokenAutoRefreshEnabled: true,
  });
}
// Initialize Firebase
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore();
export const functions = getFunctions(
  app,
  isDev ? 'europe-central2' : 'europe-west3'
);
