import { IExercisesConfigYoutube } from 'src/@types/types';
//Zameranie Pozicovanie Pusti Poznamky varianta
export const exercisesStrength: IExercisesConfigYoutube = {
  squatJump: {
    id: 'squatJump',
    name: { en: 'Jump squat', sk: 'Drep s výskokom' },
    youtubeId: { en: 'xSjk0Vo3nWA', sk: 'xSjk0Vo3nWA' },
    keywords: [
      'strength',
      'stand',
      'squats',
      'explosive',
      'midBody',
      'lowerBody',
      'thigh',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Squat technique with jump

⚙ Pozicovanie
1️⃣ Stand up naturally
2️⃣ Bring your feet to shoulder level

▶️ Pusti
With a breath, activate the core (lower back and lower abdomen)
Bend your arms at the elbows as you move into a squat
With an exhale bounce off the ground as much as possible and use your arms in the lunge
Before hitting the ground, relax your feet a little to reduce the impact
 

📝 Poznamky
Hand movement can greatly help with rebound force and jump height`,
      sk: `  
🔔 Zameranie
Technika drepu s výskokom

⚙ Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Nohy daj na úroveň ramien

▶️ Pusti
S nádychom aktivuj jadro (spodný chrbát a spodné brucho)
Pri presune do drepu zohni ruky v lakťoch
S výdychom sa odraz zo zeme čo najviac a využi ruky pri výskoku
pred dopadom na zem trochu uvoľni chodidlá aby si zredukoval dopad
 

📝 Poznamky
Pohyb rúk vie výrazne pomôcť pri sile odrazu a výške skoku
`,
    },
  },
  squatRegular: {
    id: 'squatRegular',
    name: {
      en: 'Squat regular',
      sk: 'Regulárny drep',
    },
    youtubeId: { en: '1N5avdutYnA', sk: 'LVkmdLLnBqk' },
    keywords: ['strength', 'stand', 'squats', 'midBody', 'lowerBody', 'thigh'],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Common squat technique

⚙️ Pozicovanie
1️⃣ Stand up naturally
2️⃣ Put your feet at shoulder level

▶️ Pusti
With a breath, go to the ground, do a squat
With an exhale, come back up
⚠️ Pay attention to your knees, they must be pointing outwards, not inwards during the squat 
 

📝 Poznamky
Easy exercise, but you need to have it well practiced`,
      sk: `  
🔔 Zameranie
Technika obyčajného drepu

⚙️ Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Nohy daj na úroveň ramien

▶️ Pusti
S nádychom choď k zemi, urob drep
S výdychom sa vráť späť hore
⚠️ Dávaj pozor na kolená, musia smerovať von, nesmú sa počas drepu vytáčať dovnútra 
 

📝 Poznamky
Ľahký cvik no treba ho mať dobre precvičený`,
    },
  },
  handstandRegular: {
    id: 'handstandRegular',
    name: {
      en: 'Handstand regular',
      sk: 'Regulárna stojka',
    },
    youtubeId: { en: 'jZK2NycolcA', sk: 'hCngcevVX00' },
    keywords: [
      'strength',
      'ground',
      'handstand',
      'coordination',
      'midBody',
      'upperBody',
      'core',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Maximising body control
Whole body activation

⚙ Pozicovanie
1️⃣ Start on the ground
2️⃣ Place your hands on the ground at shoulder level or slightly wider
3️⃣ Inhale to activate the core (lower abdomen and lower back)
⚠️ Keep the core fully activated throughout the exercise with the first deep breath
⚠️ Use the full potential of your fingers, place them as wide apart as possible and you should have a small gap under each finger joint

▶️ Pusti
With or without a small exhale, move your lower body from the ground to your hands
⚠️ Lock the position, lock your chest and shoulder blades
Continue with the upward movement of the legs
Lock the position
Slowly come back to the ground
 

📝 Poznamky
It's important to always lock into a position before moving on to the next one`,
      sk: `  
🔔 Zameranie
Maximalizácia kontroly tela
Aktivácia celého tela

⚙ Pozicovanie
1️⃣ Začni na zemi
2️⃣ Polož ruky na zem na úroveň ramien alebo trochu širšie
3️⃣ Nadýchni sa aktivuj jadro (spodné brucho a spodný chrbát)
⚠️ Počas celého cviku drž jadro plne aktivované prvým hlbokým nádychom
⚠️ Využi plný potenciál prstov, daj ich čo najširšie od seba a pod každým člankom prstu by si mal mať malú medzeru

▶️ Pusti
S malým výdychom alebo bez, presuň spodnú časť tela zo zeme na ruky
⚠️ Zafixuj pozíciu, zamkni hrudník a lopatky
Pokračuj s pohybom nôh hore
Zafixuj pozíciu
Pomaly sa vráť späť na zem
 

📝 Poznamky
Je dôležité vždy si zafixovať danú polohu pred prechodom na ďalšiu
`,
    },
  },
  handstandChair: {
    id: 'handstandChair',
    name: {
      en: 'Handstand on chair',
      sk: 'Stojka na stoličke',
    },
    youtubeId: { en: 'LJStOzRvqnc', sk: 'YX3E3nM0-mY' },
    keywords: [
      'strength',
      'chair',
      'handstand',
      'coordination',
      'midBody',
      'upperBody',
      'upperArm',
      'shoulder',
      'core',
      'forearm',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Maximize body control
Maximize handstand movement anywhere

⚙ Pozicovanie
0️⃣ Get a chair
1️⃣ Start on a chair
2️⃣ Place one hand on the back of the chair
3️⃣ Grab the seat with the other hand
4️⃣ Breathe in, tighten your core (lower back, lower abdominal muscles).
⚠️ Keep the core fully activated by taking deep breaths.

▶️ Pusti
With a small exhale (or without), transfer the weight of your lower body from the ground to your hands
⚠️ Block the chest with the shoulder blades
Continue to move your legs upwards
Fix the position
Slowly come back down
 

📝 Poznamky
Practice the handstand wherever you can 
I did this handstand on 11/23/2022, a lot has changed since then`,
      sk: `🔔 Zameranie
Maximalizujte kontrolu nad telom
Maximalizujte pohyb v stojke na rukách kdekoľvek

⚙ Pozicovanie
0️⃣ Zober stoličku
1️⃣ Začni na stoličke
2️⃣ Polož jednu ruku na operadlo stoličky
3️⃣ Druhou rukou sa chyť sedadla
4️⃣ Nadýchnite sa, zafixujte jadro (spodnú časť chrbta, spodné brušné svaly).
⚠️ Udržujte jadro plne aktivované hlbokým nádychom.

▶️ Pusti
S malým výdychom (alebo bez neho) prenes váhu dolnej časti tela zo zeme na ruky
⚠️ Zafixuj hrudník lopatkami
Pokračuj v pohybe nôh nahor
Zafixuj pozíciu
Pomaly sa vráť späť nadol, krok po kroku ako si šiel hore
 

📝 Poznamky
Trénuj stojku všade kde sa dá 
Túto stojku som točil 23.11.2022, odvtedy sa toho veľa zmenilo`,
    },
  },
  handstandWood: {
    id: 'handstandWood',
    name: {
      en: 'Handstand on Woody pads',
      sk: 'Stojka na drevenných podložkách',
    },
    youtubeId: { en: 'G6-NYEROnY4', sk: 'UIvTaQizu9U' },
    keywords: [
      'strength',
      'ground',
      'handstand',
      'coordination',
      'midBody',
      'upperBody',
      'upperArm',
      'shoulder',
      'core',
      'forearm',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Coordination/adaptation of palms and fingers to different surface shapes

⚙ Pozicovanie
0️⃣ Pick up a non-straight oblong object, such as a rounded plate 
1️⃣ Start on the ground in a crouch, place your palms shoulder width apart on the plates
2️⃣ With the initial breath, activate the core

▶️ Pusti
With or without a small exhale, move your lower body from the ground to your hands
⚠️ Lock the position, locking the chest and shoulder blades
Continue with the upward movement of the legs
Lock the position
Spread legs
Lock the position
Slowly return to the ground
Repeat

📝 Poznamky
Keep your toes as far apart as possible
It's important that the surface is firm so you can use the full power of your fingers, in the grass where your fingers sink into the dirt
Concentrate on moving the lower part to your hands, then you can control it well with your fingers`,
      sk: `  
🔔 Zameranie
Koordinácia/prispôsobenie dlaní a prstov na iný tvar povrchu

⚙ Pozicovanie
0️⃣ Zober si nejaký nie rovný podlhovastý objekt, napríklad oblé doštičky 
1️⃣ Začni na zemi v podrepe, dlaňe polož na šírku ramien na doštičky
2️⃣ S prvotným nádychom aktivuj jadro

▶️ Pusti
S malým výdychom alebo bez, presuň spodnú časť tela zo zeme na ruky
⚠️ Zafixuj pozíciu, zamkni hrudník a lopatky
Pokračuj s pohybom nôh hore
Zafixuj pozíciu
Rozpaž nohy
Zafixuj pozíciu
Vráť sa pomaly na zem
 

📝 Poznamky
Prsty maj čo najďalej odseba
Dôležité je aby bol povrch pevný aby ste vedeli využiť plnú silu prstov, v provnaní s trávou kde sa vám prsty ponárajú do hliny
Sústreď sa na presun sdponej časti na ruky, vtedy to vieš dobre kontrolovať prstami
`,
    },
  },
  pushUpClap: {
    id: 'pushUpClap',
    name: {
      en: 'Clap push-up',
      sk: 'Klik s tlesknutím',
    },
    youtubeId: { en: 'Gz620jhQ3Hs', sk: 'Gz620jhQ3Hs' },
    keywords: [
      'strength',
      'ground',
      'pushUps',
      'explosive',
      'coordination',
      'upperBody',
      'midBody',
      'upperArm',
      'shoulder',
      'chest',
    ],
    likes: 0,
    description: {
      en: `
Zameranie
Crank technique with clapping
Hand coordination training after orthrosis from the ground
  
⚙ Pozicovanie
1️⃣ Get into a push-up position, hands at shoulder level
2️⃣ With the first breath, activate the core (lower back and abdomen)

▶️ Pusti
Do a push-up and clap in front of your body while bouncing off the ground
Before hitting the ground, relax your hands a little to soften the fall to the ground

Poznamky
If you can't comfortably clap and hit the ground, I recommend practicing the Explosive Push-Up exercise first, where you practice bouncing off the ground and landing`,
      sk: `
Zameranie
Technika kliku s tlesknutím
Tréning koordinácie rúk po ordraze od zeme
  
⚙ Pozicovanie
1️⃣ Daj sa do pozície v kliku, ruky na úroveň ramien
2️⃣ S prvým nádychom aktivuj jadro (spodný chrbát a brucho)

▶️ Pusti
Urob klik a počas odrazu od zeme tleskni pred telom
Pred dopadom na zem trochu uvoľni ruky aby si zjemnil pád na zem

Poznamky
Ak nedokážeš pohodlne tlesknúť a dopadnúť na zem, odporúčam ti trénovať najskôr cvik Explozívny klik, kde si natrénuješ odraz od zeme a dopad
`,
    },
  },
  pushUpClapDouble: {
    id: 'pushUpClapDouble',
    name: {
      en: 'Double clap push-up',
      sk: 'Klik s dvojitým tlesknutím',
    },
    youtubeId: { en: 'SpQkkDh8MgI', sk: 'SpQkkDh8MgI' },
    keywords: [
      'strength',
      'ground',
      'pushUps',
      'explosive',
      'coordination',
      'upperBody',
      'midBody',
      'upperArm',
      'shoulder',
      'chest',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Double Clap PushUp technique
Increase explosive power

⚙️Pozicovanie
1️⃣ Put yourself in a pushup position, hands at shoulder level
2️⃣ With your first breath, activate your core (lower back and abs)

▶️ Pusti
Go to the ground with the inhale
As you push off the ground you exhale
After pushing off the ground, clap as you would for a normal push-up with one clap
After clapping, you must quickly move your hands behind your back and clap
Quickly move your hands in front of your body, relaxing them a little to soften the impact on the ground 
⚠️ Watch your wrists on impact
 

Poznamky
I recommend to have a properly practiced one-clap push-up later
Bounce off the ground is best practiced with the Explosive push-up exercise, where you try to bounce off the ground as much as possible and control the impact 
Advice for falling to the ground: lift your chin up and land only on your chest`,
      sk: `  
🔔 Zameranie
Double Clap PushUp technique
Increase explosive power

⚙️Pozicovanie
1️⃣ Daj sa do pozície v kliku, ruky na úroveň ramien
2️⃣ S prvým nádychom aktivuj jadro (spodný chrbát a brucho)

▶️ Pusti
K zemi ideš s nádychom
Pri odláčaní od zeme vydychuješ
Po odrazení od zeme tleskni ako pri normálnom kliku s jedným tlesknutím
Po tlesknuti musíš rýchlo presunúť ruky za chrbát a tlesknúť
Rýchlo presuň ruky pred telo, trochu ich uvoľni aby si zjemnil dopad na zem 
⚠️ Dávaj pozor na zápästia pri dopade
 

Poznamky
Odporúčam mať nasjkôr poriadne natrénovaný klik s jedným tlesknutím
Odraz od zeme nejlepšie natrénuješ cvikom: Explozívny klik, kde sa snažíš čo najviac odraziť od zeme a kontroluješ dopad 
Rada pri páde na zem: zdvihni bradu hore a dopadneš iba na hrudník
`,
    },
    free: true,
  },
  pushUpClapTriple: {
    id: 'pushUpClapTriple',
    name: {
      en: 'Triple clap push-up',
      sk: 'Klik s tromi tlesknutiami',
    },
    youtubeId: { en: 'BtCE3dnKTOg', sk: 'BtCE3dnKTOg' },
    keywords: [
      'strength',
      'ground',
      'pushUps',
      'explosive',
      'coordination',
      'upperBody',
      'midBody',
      'upperArm',
      'shoulder',
      'chest',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize explosive power
Maximize controlled hand movement

⚙️ Pozicovanie
1️⃣ Get to Push Up position
2️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
Push as hard as possible
Do a CLAP as basic Clap Push Up
Quickly move your hands behind your back
Clap
Relax the hands a little
Fastly move them in front of body
Clap
Move your hands to start position
Relax arms a little before landing to reduce ground impact
 

📝 Poznamky
Always keep the area around the joints active, do not do full muscle relax
Transfer the energy from ground to behind clap and back to ground
Use the time while moving from starting explosive push up to behind back clap and back to ground to clap 2 more times in front of body`,
      sk: `  
🔔 Zameranie
Technika kliku s troma tlesknutiami
Maximalizovanie koordinácie tela
Maximalizovanie výbušnoti hornej časti tela

⚙ Pozicovanie
1️⃣ Daj sa do pozície v kliku, ruky na úroveň ramien
2️⃣ S prvým nádychom aktivuj jadro (spodný chrbát a brucho)

▶️ Pusti
K zemi ideš s nádychom
Od zeme vydychuješ
Odraz sa od zeme čo najsilnejšie
Hneď po odraze tleskni raz pred telom
Rýchlo presuň ruky za chrbát kde tleskneš znovu
Rýchlo presuň ruky pred telo a pred dopadom tleskni ešte raz, odporúčam tlesknúť čo najbližšie k telu aby si mal čas dať ruky na miesto pred dopadom
Pred dopadom uvoľni trochu ruky aby si zmiernil dopad na zem
 

📝 Poznamky
Veľmi náročný cvik na koordináciu a silu odrazu od zeme 
Musíš mať nasjkôr poriadne natrénovaný klik s jedným tlesknutím a s dvoma tlesknutiami
Neodporúčam skúšať tento cvik pokiaľ neovládaš viac jednoduchších variant explozívnych klikov, môžeš si ľahko ublížiť
Odraz od zeme nejlepšie natrénuješ cvikom: Explozívny klik, kde sa snažíš čo najviac odraziť od zeme a kontroluješ dopad 
Rada pri páde na zem: zdvihni bradu hore a dopadneš iba na hrudník`,
    },
    free: true,
  },
  pushUpDecline: {
    id: 'pushUpDecline',
    name: {
      en: 'Decline push-up',
      sk: 'Klesavý klik',
    },
    youtubeId: { en: '3zStqe_AvMg', sk: '3zStqe_AvMg' },
    keywords: [
      'chair',
      'ground',
      'strength',
      'pushUps',
      'upperBody',
      'midBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase the difficulty by changing vertical position of legs

⚙️Pozicovanie
0️⃣ Get chair or some stable object at least 40cm high
1️⃣ Get push up position, put toes at the border of the chair
2️⃣ Put your hands at shoulder level

▶️ Pusti
Inhale, activate the core first (low back, low abdominals), bend elbows
Do Push Up with Exhale
 

📝 Poznamky
Keep your legs always active`,
      sk: `  
🔔 Zameranie
Increase the difficulty by changing vertical position of legs

⚙️Pozicovanie
0️⃣ Zober si nejaký stabilný vyšší objekt, napríklad stoličku
⚠️Pamätaj čím vyšší predmet, tým to je ťažšie
1️⃣ Daj sa do pozície kliku, ruky na úroveň ramien
2️⃣ Prsty na nohách si daj na okraj toho objekt

▶️ Pusti
K zemi ideš s nádychom, s úvodným nádychom aktivuj jadro
Od zeme vydychuješ
Urob klik
 

📝 Poznamky
Nohy maj stále aktívne
`,
    },
  },
  pushUpDeclineClap: {
    id: 'pushUpDeclineClap',
    name: {
      en: 'Decline clap push-up',
      sk: 'klesavý klik s tlesknutím',
    },
    youtubeId: { en: 'LLUlmomEbh0', sk: 'LLUlmomEbh0' },
    keywords: [
      'chair',
      'ground',
      'strength',
      'pushUps',
      'explosive',
      'coordination',
      'upperBody',
      'midBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Technique of the descending crank with clapping
Improving explosive power
Upper body strengthening

⚙️Pozicovanie
0️⃣ Get chair or some stable object at least 40cm high
1️⃣ Get push up position, put toes at the border of the chair
2️⃣ Put your hands at shoulder level

▶️ Pusti
Inhale, activate the core first (low back, low abdominals), bend elbows
With Exhale CLAP hands
Relax them a little for smooth landing
Get back to START position
 

📝 Poznamky
Keep your toes always active
DO NOT move the chair - balance train
Work with breathe during push and land
Try to PUSH as HARD as possible to BOOST your maximal explosive POWER`,
      sk: `  
🔔 Zameranie
Technika klesavého kliku s tlesknutím
Zlepšenie výbušnej sily
Posilnenie hornej časti tela

⚙️Pozicovanie
0️⃣ Zober si nejaký stabilný vyšší objekt, napríklad stoličku
⚠️Pamätaj čím vyšší predmet, tým to je ťažšie
1️⃣ Daj sa do pozície kliku, ruky na úroveň ramien
2️⃣ Prsty na nohách si daj na okraj toho objekt

▶️ Pusti
K zemi ideš s nádychom, s úvodným nádychom aktivuj jadro
Od zeme vydychuješ
Pri odraze od zeme tleskni
Pred dopadom na zem trochu uvoľni ruky aby si zjemnil pád na zem
 

📝 Poznamky
Ak ti je tento cvik náročný, najskôr natrénuj obyčajný Klesavý klik
Chodidlá a nohy maj stále aktívne
Ak používaš stoličku, snaž sa s ňou nepohnúť, tým trénuješ lepšiu koordináciu
Snaž sa čo najviac odrážať od zeme pre lepšie trénovanie výbušnoti
Keep your toes always active
DO NOT move the chair - balance train
Work with breathe during push and land
Try to PUSH as HARD as possible to BOOST your maximal explosive POWER`,
    },
  },
  squatJump180: {
    id: 'squatJump180',
    name: {
      en: 'Jump squat 180°',
      sk: 'Drep s otočkou o 180°',
    },
    youtubeId: { en: '8Wux7469hiU', sk: '8Wux7469hiU' },
    keywords: [
      'stand',
      'strength',
      'squats',
      'coordination',
      'explosive',
      'midBody',
      'lowerBody',
      'thigh',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Technika drepu s otočkou o 180°
Zlepšenie koordinácie a výbušnosti nôh

⚙️ Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Nohy daj na úroveň ramien

▶️ Pusti
S úvodným nádychom aktivuj jadro, choď k zemi a pokrč ruky v lakťoch
S výdychom sa odraz od zeme, využi pohyb rúk pre silu odrazu
Otoč sa presne o 180°, presne preto aby si trénoval koordináciu
Pred dopadom na zem trochu uvoľni nohy aby si zlejmil pád
 

📝 Poznamky
Ruky ti vedia výrazne dopomôcť k výške skoku a tiež koordinácii
Dávaj pozor na prudkosť otáčania aby si sa nepretočil moc
Vždy trénuj otáčanie do oboch strán aby si zredukoval nesúmernosti tela 
`,
      sk: `  
🔔 Zameranie
Technika drepu s otočkou o 180°
Zlepšenie koordinácie a výbušnosti nôh

⚙️ Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Nohy daj na úroveň ramien

▶️ Pusti
S úvodným nádychom aktivuj jadro, choď k zemi a pokrč ruky v lakťoch
S výdychom sa odraz od zeme, využi pohyb rúk pre silu odrazu
Otoč sa presne o 180°, presne preto aby si trénoval koordináciu
Pred dopadom na zem trochu uvoľni nohy aby si zlejmil pád
 

📝 Poznamky
Ruky ti vedia výrazne dopomôcť k výške skoku a tiež koordinácii
Dávaj pozor na prudkosť otáčania aby si sa nepretočil moc
Vždy trénuj otáčanie do oboch strán aby si zredukoval nesúmernosti tela 
`,
    },
  },
  pushUpClapKnee: {
    id: 'pushUpClapKnee',
    name: {
      en: 'Knee clap push-up',
      sk: 'Klik s tlesknutím o kolená',
    },
    youtubeId: { en: 'F9Tu1LRDVPs', sk: 'F9Tu1LRDVPs' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'explosive',
      'coordination',
      'midBody',
      'lowerBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Maximizing explosive/explosive power of the upper body
Maximising body control
Coordination of arms and legs

⚙ Pozicovanie
1️⃣ Start in a push-up position, bring your hands to shoulder level
2️⃣ With the initial breath, activate the core

▶️ Pusti
Inhale towards the ground
Bounce off the ground as hard as you can
After the bounce, move your knees and hands towards the ground and clap your palms on your knees
Bring your legs and arms back
Before impact, relax the palms and soles of the feet a little for a softer impact
 

📝 Poznamky
Watch your range of motion so you clap in the area below your pelvis
Aim for smooth movement and impact`,
      sk: `  
🔔 Zameranie
Maximalizácia explozívnej/výbušnej sily hornej časti tela
Maximalizácia kontroly tela
Koordinácia rúk a nôh

⚙ Pozicovanie
1️⃣ Začni v pozícii kliku, ruky si daj na úroveň ramien
2️⃣ S prvotným nádychom aktivuj jadro

▶️ Pusti
Nadýchni sa smerom k zemi
Odraz sa od zeme čo najsilnešie
Po odraze pohni kolenami a rukami smerom k seme a tleskni dlaňami o kolená
Vráť nohy a ruky späť
Pred dopadom trochu uvoľni dlane a chodidlá pre jemnejší dopad
 

📝 Poznamky
Dávaj poroz na rozsah pohybu aby si tlieskal v oblasti pod panvou
Snaž sa o plynulý pohyb a dopad`,
    },
  },
  pushUpOneLegUp: {
    id: 'pushUpOneLegUp',
    name: {
      en: 'One leg up push-up',
      sk: 'Klik s jednou nohou hore',
    },
    youtubeId: { en: 'eTCaEzJg5c0', sk: 'eTCaEzJg5c0' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'midBody',
      'lowerBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Improving core activation
Improving coordination

⚙ Pozicovanie
1️⃣ Start in a push-up position, bring your hands to shoulder level
2️⃣ With the initial breath, activate the core

▶️ Pusti
Lift one leg up, aligned with your body/back
With inhale down and exhale up, do a push-up
 

📝 Poznamky
Pay attention to body control/coordination
Focus on engaging core, lower back and lower abs
Always alternate legs 
Try to be as balanced as possible as you do push-ups with your feet on the ground`,
      sk: `  
🔔 Zameranie
Zlepšenie aktivácie jadra
Zlepšenie koordinácie

⚙ Pozicovanie
1️⃣ Začni v pozícii kliku, ruky si daj na úroveň ramien
2️⃣ S prvotným nádychom aktivuj jadro

▶️ Pusti
zdvihni jednu nohu hore, zarovno s telom/chrbátom
S nádychom dole a výdychom hore urob klik
 

📝 Poznamky
Dávaj pozor na kontrolu/koordináciu tela
Sústreď sa na zapájanie jadra, spodného chrbátu a spodného brucha
Vždy vystriedaj nohy 
Snaž sa byť čo najviac vyrovnaný ako pri kliku s nohami na zemi`,
    },
  },
  pushUpInOut: {
    id: 'pushUpInOut',
    name: {
      en: 'In uot push-up',
      sk: 'Dovnútra von klik',
    },
    youtubeId: { en: '_wEyOvLsX1M', sk: '_wEyOvLsX1M' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'explosive',
      'coordination',
      'midBody',
      'lowerBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Improving explosive power
Improve upper limb strength at different angles

⚙ Pozicovanie
1️⃣ Start in a push-up position, bring your hands to shoulder level
2️⃣ With the initial breath, activate the core

▶️ Pusti
With a downward breath, do a push-up
On the up bounce, change your hand position to a Narrow push-up, palms under your chest
Land with a push up
Bounce up again and put your hands in the starting position
Before each impact, relax your hands a little for a better impact
 

📝 Poznamky
You can start with your hands under your chest or wide apart (wide push-up)
With this exercise, you will get well accustomed to using explosive force with different palm positions`,
      sk: `  
🔔 Zameranie
Zlepšenie výbušnej sily
Zlepšenie sily hornej končatiny v rôznych uhloch

⚙ Pozicovanie
1️⃣ Začni v pozícii kliku, ruky si daj na úroveň ramien
2️⃣ S prvotným nádychom aktivuj jadro

▶️ Pusti
S nádychom dole urob klik
Pri odraze hore zmeň pozíciu rúk na Úzky klik, dlane sú pod hrudníkom
Dopadni s nádychom
Znovu sa odraz a daj ruky do začitočnej pozície
Pred každým dopadom trochu uvoľni ruky pre lepší dopad
 

📝 Poznamky
Začať môžeš s rukami pod hrudníkom alebo široko od seba (široký klik)
Pri tomto cviku si dobre zvykneš na používanie výbušnej sily s rôznymi polohami dlaní
`,
    },
  },
  pushUpShoulderTouch: {
    id: 'pushUpShoulderTouch',
    name: {
      en: 'Shoulder touch push-up ',
      sk: 'Klik s dotykom ramena',
    },
    youtubeId: { en: 'FwLJYfQ8NOo', sk: 'FwLJYfQ8NOo' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'midBody',
      'lowerBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Improving shoulder stability
Light core exercise

⚙ Pozicovanie
1️⃣ Start in a push-up position, bring your hands to shoulder level
2️⃣ With the initial inhale, activate the core

▶️ Pusti
With a downward breath, do a push-up
With an exhale, grasp the opposite shoulder with one hand
Return your arm to sleep
Repeat with the other hand

📝 Poznamky
You can try this with different starting hand positions, narrow, medium and wide
Keep your body as straight as possible, balancing the pull of gravity pulling one part of your body down`,
      sk: `  
🔔 Zameranie
Zlepšenie stability ramena
Ľahký cvik na jadro

⚙ Pozicovanie
1️⃣ Začni v pozícii kliku, ruky si daj na úroveň ramien
2️⃣ S prvotným nádychom aktivuj jadro

▶️ Pusti
S nádychom k zemi sprav klik
S výdychom si jednou rokou chyť opačné rameno
Vráť ruku spať
  with other hand

📝 Poznamky
Toto môžeš skúšať s rôznymi začitočnými pozíciami dlaní, úzka, stredna a široká
Drž telo čo najrovnejšie, vyrovnávaj ťah gravitácie, ktorá ti ťahá jednu Časť tela dolu
`,
    },
  },
  pushUpShouldersTouch: {
    id: 'pushUpShouldersTouch',
    name: {
      en: 'Shoulders touch push-up',
      sk: 'Klik s tlapnutím o ramená',
    },
    youtubeId: { en: 'T-WZe24KzdM', sk: 'T-WZe24KzdM' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'explosive',
      'coordination',
      'midBody',
      'lowerBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Zameranie
Maximize Explosive power
Maximize arm mobility
Maximize arm movement precision

⚙ Pozicovanie
1️⃣ Start in a push-up position, bring your arms to shoulder level
2️⃣ With the initial breath, activate the core

▶️ Pusti
With the inhale to the ground, do a push-up
With an exhale, push off the ground as hard as you can and paw at your opposite shoulders with both hands
Bring your arms back and relax them a little before impact 
 

📝 Poznamky
I recommend alternating hands when crossing
Be careful when returning your hands`,
      sk: `  
🔔 Zameranie
Maximize Explosive power
Maximize arm mobility
Maximize arm movement precision

⚙ Pozicovanie
1️⃣ Začni v pozícii kliku, ruky si daj na úroveň ramien
2️⃣ S prvotným nádychom aktivuj jadro

▶️ Pusti
S nádychom k zemi sprav klik
S výdychom sa odraz od zeme čo najsilnejšie a oboma rukami si tlapni po opačných ramenách
Vráť ruky späť a trochu ich uvolni pred dopadom 
 

📝 Poznamky
Odporúčam striedať ruky pri prekrižovaní
Dávaj pozor pri návrate rúk  
`,
    },
  },
  squatJumpInOut: {
    id: 'squatJumpInOut',
    name: {
      en: 'Squat jump in out',
      sk: 'Dovnútra von drep s výskokom',
    },
    youtubeId: { en: '3cV8AxLLBBE', sk: '3cV8AxLLBBE' },
    keywords: [
      'stand',
      'strength',
      'squats',
      'coordination',
      'explosive',
      'midBody',
      'lowerBody',
      'thigh',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Training of smooth landing on the ground from the rebound
Improving coordination

⚙ Positioning
1️⃣ Stand naturally, feet shoulder level and a little closer
2️⃣ With the initial breath, activate the core

▶️ Let go
Do a squat with the inhale
After the lunge, place your feet wider and land on your toes
With a breath, bring them back to the front

📝 Notes
You can try any leg position, narrow, medium, wide
Use your hands for a better spitting motion
Watch the toe impact, it has to be a plinulé`,
      sk: `  
🔔 Zameranie
Tréning plynulého dopadu na zem z doskoku
Zlepšenie koordinácie

⚙ Pozicovanie
1️⃣ Prirodzene sa postav, nohy na úroveň ramien a trochu bližšie
2️⃣ S prvotným nádychom aktivuj jadro

▶️ Pusti
Urob drep s nádychom
Po výskoku daj nohy širšie a dopadni na špičky
S nádychom ich vráť späť dostredu

📝 Poznamky
Môžeš skúšať ľubovoľne pozície nôh, úzka, stredná, široká
Použi ruky pre lepší plinulý pohyb
Dávaj si pozor na dopad na špičky, musí to byť plinulé
`,
    },
  },
  squatOneLegBehind: {
    id: 'squatOneLegBehind',
    name: {
      en: 'Squat with one leg backwards',
      sk: 'Drep s jednou nohou vzad',
    },
    youtubeId: { en: 'pUda81weow4', sk: 'pUda81weow4' },
    keywords: [
      'stand',
      'strength',
      'squats',
      'challenge',
      'coordination',
      'midBody',
      'lowerBody',
      'thigh',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Improving body stability
Increase leg strength

⚙️ Positioning
1️⃣ Stand up naturally
2️⃣ Put your feet at shoulder level and a little closer
3️⃣ With the opening breath, activate the core
4️⃣ Prepare one leg to start with

▶️ Let go
With the inhale go to the ground
As you go down, bend one leg at the knee and place it under your butt
Exhaling, do a squat
⚠️ Watch your balance on one leg
 

📝 Notes
One of the heavy squat variations
Always alternate both legs`,
      sk: `  
🔔 Zameranie
Zlepšenie stability tela
Zvýšenie sily nohy

⚙️ Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Nohy si daj na úroveň ramien a trochu bližšie
3️⃣ S úvodným nádychom aktivuj jadro
4️⃣ Priprav si jednu nohu s ktorou začneš

▶️ Pusti
S nádychom choď k zemi
Počas toho ako ideš dole, pokrč jednu nohu v kolene a daj ju pod zadok
S výdychom sprav drep
⚠️ Dávaj pozor na balans na jednej nohe
 

📝 Poznamky
Jedna z ťažkých variant drepu
Vždy vystriedaj obe nohy 
`,
    },
  },
  squatJumpKneesToElbows: {
    id: 'squatJumpKneesToElbows',
    name: {
      en: 'Jump squat with elbows to knees',
      sk: 'Drep s výskokom lakte ku kolenám',
    },
    youtubeId: { en: 'oQznc8VbvkM', sk: 'oQznc8VbvkM' },
    keywords: [
      'stand',
      'strength',
      'squats',
      'coordination',
      'explosive',
      'midBody',
      'lowerBody',
      'thigh',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Coordination of movement
Leg explosiveness

⚙️ Positioning
1️⃣ Stand up straight, put your feet at shoulder level
2️⃣ Bend your arms at the elbows, parallel to the ground

▶️ Let go
With a breath, activate the core
With an inhale to the ground and an exhale from the ground, do a squat with a jump
Watch for bounce off the ground and elbows touching knees

📝 Notes
Watch the impact of your feet on the ground
Try to keep elbows and knees touching gently, very strong contact can hurt you`,
      sk: `  
🔔 Zameranie
Koordinácia pohybu
Výbušnosť nôh

⚙️ Pozicovanie
1️⃣ Priodzene sa postav, nohy si daj na úroveň ramien
2️⃣ Pokrč ruky v lakťoch, paralélne so zemou

▶️ Pusti
S nádychom aktivuj jadro
S nádychom k zemi a výdychom od zeme sprav drep s výskokom
Dávaj pozor na odraz od zeme a dotyk lakťov s kolenami

📝 Poznamky
Dávaj pozor na dopad chodidiel na zem
Snaž sa dodržať jemný dotyk lakťov a kolien, veľmi silný kontakt ťa môže zraniť
`,
    },
  },
  standBandArmSpreadHold: {
    id: 'standBandArmSpreadHold',
    name: {
      en: 'Holding the expander in a standing position',
      sk: 'Držanie v rozpažení expanderu v stoji',
    },
    youtubeId: { en: 'mOAc74yNWII', sk: 'mOAc74yNWII' },
    keywords: [
      'stand',
      'strength',
      'band',
      'shoulder',
      'scapula',
      'upperArm',
      'forearm',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase the strength of the whole arm
Improve scapula retraction and stability

⚙️ Positioning
0️⃣ Pick up an expander
1️⃣ Stand up naturally
2️⃣ At shoulder level, grab the expander
3️⃣ Raise your arms in front of you
4️⃣ With a breath, activate your core

▶️ Let go
With an exhale, open your arms, pull your shoulder blades together (retraction)
With Exhale Spread your arms, do scapula retraction
⚠️ Keep your shoulder blades pulled down
Keep the expander stretched as long as you can
Release

📝 Notes
By choosing the expander you are adding to the difficulty
Use a lighter expander to feel the strengthening of the interscapular muscles`,
      sk: `   
🔔 Zameranie
Zvýšenie sily celej ruky
Zlepšenie retrakcie lopatky a jej stability

⚙️ Pozicovanie
0️⃣ Zober expander
1️⃣ Prirodzene sa postav
2️⃣ Na úrovni ramien chyť expander
3️⃣ Zdvihni ruky pred seba
4️⃣ S nádychom aktivuj jadro

▶️ Pusti
S výdychom rozpaž ruky, pritiahni lopatky k sebe (retrakcia)
With Exhale Spread your arms, do scapula retraction
⚠️ Drž lopatky stiahuté dole
Drž expander natiahnutý najdlhšie ako vieš
Uvoľni

📝 Poznamky
Voľbou expanderu si pridávaš na obťiažnosti
Radšej použi ľahší expander aby si precítil posilovanie medzilopatkových svalov
`,
    },
  },
  standBandArmSpread: {
    id: 'standBandArmSpread',
    name: {
      en: 'Arms spreding with expander in stance',
      sk: 'Rozpažovanie expanderu v stoji',
    },
    youtubeId: { en: 'Al4D3g3KZ7U', sk: 'Al4D3g3KZ7U' },
    keywords: [
      'stand',
      'strength',
      'band',
      'shoulder',
      'scapula',
      'upperArm',
      'forearm',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase hand strength
Improve scapular retraction

⚙️ Positioning
0️⃣ Pick up an expander
1️⃣ Stand up naturally
2️⃣ Grab the expander at shoulder level
3️⃣ Raise your arms in front of you
4️⃣ With the opening breath, activate the core

▶️ Let go
With an exhale, extend your arms, do a scapular retraction
⚠️ Keep your shoulder blades pulled down, (scapular depression)
With an inhale, bring your arms back to the front

📝 Notes
By choosing the expander you are adding to the difficulty
Use a lighter expander to feel the strengthening of the interscapular muscles`,
      sk: `   
🔔 Zameranie
Zvýšenie sily rúk
Zlepšenie retrakcie lopatky

⚙️ Pozicovanie
0️⃣ Zober expander
1️⃣ Prirodzene sa postav
2️⃣ Chyť expander na úrovni ramien
3️⃣ Zdvihni ruky pred seba
4️⃣ S úvodným nádychom aktivuj jadro

▶️ Pusti
S výdychom rozpaž ruky, urob retrakciu lopatky
⚠️ Lopatky drž stiahnuté dole, (depresia lopatky)
S nádychom vráť ruky dostredu

📝 Poznamky
Voľbou expanderu si pridávaš na obťiažnosti
Radšej použi ľahší expander aby si precítil posilovanie medzilopatkových svalov
`,
    },
  },
  standBandHandUp: {
    id: 'standBandHandUp',
    name: {
      en: 'Raising the hand up with the expander',
      sk: 'Zdvíhanie ruky hore s expanderom',
    },
    youtubeId: { en: '1TQHUEHLTwU', sk: '1TQHUEHLTwU' },
    keywords: ['stand', 'strength', 'band', 'upperArm', 'forearm'],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase arm strength, primarily biceps
Improve shoulder joint stability

⚙️ Positioning
0️⃣ Pick up an expander
1️⃣ Stand up naturally
2️⃣ Grab expander at shoulder level
3️⃣ Raise your arms in front of you
4️⃣ With the opening breath, activate the core

▶️ Let go
With an exhale, bring your arm to your shoulder, use your biceps
⚠️ Keep the scapula pulled down (scapular depression)
With an inhale, bring the arm back
⚠️ Don't fully extend the elbow, keep it activated

📝 Notes
By choosing the expander you are adding to the difficulty`,
      sk: `   
🔔 Zameranie
Zvýšenie sily ruky, primárne biceps
Zlepšenie stability ramenného kĺbu

⚙️ Pozicovanie
0️⃣ Zober expander
1️⃣ Prirodzene sa postav
2️⃣ Chyť expander na úrovni ramien
3️⃣ Zdvihni ruky pred seba
4️⃣ S úvodným nádychom aktivuj jadro

▶️ Pusti
S výdychom pritiahni ruku k ramenu, využi biceps
⚠️ Lopatku drž stiahnutú dole (depresia lopatky)
S nádychom vráť ruku späť
⚠️ Nevystieraj lakeť úplne, drž ho stále aktivovaný

📝 Poznamky
Voľbou expanderu si pridávaš na obťiažnosti
`,
    },
  },
  standBandArmUp: {
    id: 'standBandArmUp',
    name: {
      en: 'Raising the arm with the expander in a standing position',
      sk: 'Zdvíhanie ruky s expanderom v stoji',
    },
    youtubeId: { en: 'Zi3qzWz8i-Q', sk: 'Zi3qzWz8i-Q' },
    keywords: [
      'stand',
      'strength',
      'band',
      'shoulder',
      'scapula',
      'upperArm',
      'forearm',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase upper limb strength
Improving shoulder joint stability

⚙️ Positioning
0️⃣ Pick up an expander
1️⃣ Stand up naturally
2️⃣ Grab the expander at shoulder level
3️⃣ Raise your arms in front of you
4️⃣ With the opening breath, activate the core

▶️ Let go
With an exhale, raise your arm above your head
⚠️ Keep the scapula pulled down (scapular depression)
With an inhale draw your arm back
⚠️ Don't fully extend the elbow
 

📝 Notes
By choosing the expander you are adding to the difficulty`,
      sk: `   
🔔 Zameranie
Zvýšenie sily hornej končatiny
Zlepšenie stability ramenného kĺbu

⚙️ Pozicovanie
0️⃣ Zober expander
1️⃣ Prirodzene sa postav
2️⃣ Chyť expander na úrovni ramien
3️⃣ Zdvihni ruky pred seba
4️⃣ S úvodným nádychom aktivuj jadro

▶️ Pusti
S výdychom zdvihni ruku nad hlavu
⚠️ Lopatku drž stiahnutú dole (depresia lopatky)
S nádychom vŕať ruku späť
⚠️ Nevystieraj úplne lakeť
 

📝 Poznamky
Voľbou expanderu si pridávaš na obťiažnosti`,
    },
  },
  standBandSquare: {
    id: 'standBandSquare',
    name: {
      en: 'Band square in stance',
      sk: 'Štvorec s expanderom',
    },
    youtubeId: { en: 'Xk5WP5jk0kY', sk: 'Xk5WP5jk0kY' },
    keywords: [
      'stand',
      'strength',
      'band',
      'shoulder',
      'scapula',
      'upperArm',
      'forearm',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Improving upper body strength
Core strengthening and activation

⚙️ Positioning
0️⃣ Get an expander
1️⃣ Stand up naturally
2️⃣ Grab the expander at shoulder level
3️⃣ Bend your arms at the elbows and raise your arms to shoulder level
4️⃣ Open chest, lock elbows
5️⃣ With the opening breath, activate the core

▶️ Let go
With an exhale, raise your arms above your head
⚠️ Keep your shoulder blades pulled together and down
Hold the position as long as possible
Relax and bring your arms back to your shoulders

📝 Notes
By choosing an expander you are adding to the difficulty
You can take deep breaths in and out while holding the square`,
      sk: `   
🔔 Zameranie
Zlepšenie sily hornej časti tela
Posilnenie jadra a jeho aktivácia

⚙️ Pozicovanie
0️⃣ Zober si expander
1️⃣ Prirodzene sa postav
2️⃣ Chyť expander na úrovni ramien
3️⃣ Pokrž ruky v lakťoch a zdvihni ruky na úroveň ramien
4️⃣ Otvor hrudník, zafixuj lakte
5️⃣ S úvodným nádychom aktivuj jadro

▶️ Pusti
S výdychom zdvihni ruky nad hlavu
⚠️ Lopatky drž stiahnuté k sebe a dole
Drž pozíciu čo najdlhšie
Uvoľni a vráť ruky späť pri ramená

📝 Poznamky
Voľbou expanderu si pridávaš na obťiažnosti
Počas držania štvorca môžeš robiť hlboké nádychy a výdychy
`,
    },
  },
  pushUpHandstand: {
    id: 'pushUpHandstand',
    name: {
      en: 'Handstand push-up',
      sk: 'Klik v stojke',
    },
    youtubeId: { en: 'WyD6xJkX5Mc', sk: 'WyD6xJkX5Mc' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'handstand',
      'coordination',
      'midBody',
      'upperBody',
      'fullBody',
      'upperArm',
      'forearm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Maximizing body coordination
Increase upper body strength

⚙️ Positioning
1️⃣ Start down on the ground
2️⃣ Bring your hands to shoulder level
3️⃣ With the opening breath, activate the core
⚠️ Keep the core fully activated throughout the movement

▶️ Let go
With a small exhale but without, move your lower body to your hands
Lock the position
Continue with the upward movement of the legs
Lock the position
Do a push-up
⚠️ Fully concentrate on holding stability with shoulders, elbows and toes
Slowly come back down to the ground, one step at a time as you went up 

📝 Notes
Heavy variation of the push-up
It's a good exercise to practice against a wall first, with your feet propped up against the wall and just focusing on the push-up 
The closer your hands/elbows are to your body, the more precise the exercise is`,
      sk: `  
🔔 Zameranie
Maximalizácia koordinácie tela
Zvýšenie sily hornej časti tela

⚙️ Pozicovanie
1️⃣ Začni dole na zemi
2️⃣ Ruky daj na úroveň ramien
3️⃣ S úvodným nádychom aktivuj jadro
⚠️ Jadro už drž plne aktivované počas celého pohybu

▶️ Pusti
S malým výdychom ale bez, presuň spodné telo na ruky
Zafixuj pozíciu
Pokračuj s pohybom nôh hore
Zafixuj pozíciu
Urob klik
⚠️ Plne sa sústreď na držanie stability pomocou ramien, lakťov a prstov na rukách
Pomaly sa vráť späť k zemi, krok po kroku ako si išiel hore 

📝 Poznamky
Ťažká variácia kliku
Cvik je dobre si natrénovať najskôr o stenu, nohy máš opreté o stenu a sústredíš sa iba na klik 
Čím bližšie máš ruky/lakte k telu, tým je precíznejšie vykonávaš cvik
`,
    },
  },
  groundBandAnkleSpread: {
    id: 'groundBandAnkleSpread',
    name: {
      en: 'Ankle extension with expander on the ground',
      sk: 'Rozťahovanie členkov s expanderom na zemi',
    },
    youtubeId: { en: 'uhuFvv4_b9w', sk: 'uhuFvv4_b9w' },
    keywords: [
      'ground',
      'band',
      'strength',
      'thigh',
      'core',
      'hip',
      'pelvis',
      'knee',
      'ankle',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase hip stability
Core activation

⚙️ Positioning
0️⃣ Take an expander 
1️⃣ Lie on the ground on your back, do the Start on the ground exercise
2️⃣ Put the expander on your ankles
3️⃣ Spread your legs to hip level
4️⃣ Lift your legs up, hip angle must be 90°
5️⃣ Arms also at shoulder level

▶️ Let go
With a breath, activate the core
Raise your arms to about 70° off the ground, activate your middle back
Exhaling, straddle your legs
⚠️ Concentrate on turning the feet outwards (foot eversion)
With an inhale, bring the feet back

📝 Notes
Keep your hips and the rest of your body active at all times`,
      sk: `   
🔔 Zameranie
Zvýšenie stability bedra
Aktivácia jadra

⚙️ Pozicovanie
0️⃣ Zober expander 
1️⃣ Ľahni si na zem na chrbát, urob cvik Štart na zemi
2️⃣ Expander si daj na členky
3️⃣ Nohy rozkroč na úroveň bedier
4️⃣ Zdvihni nohy hore, uhol bedier musí byť 90°
5️⃣ Ruky aj na úrovni ramien

▶️ Pusti
S nádychom aktivuj jadro
Zdvihni ruky do cca 70° od zeme, aktivuj stredný chrbát
S výdychom rozkroč nohy
⚠️ Sústreď sa na vytáčanie chodidiel smerom von (everzia chodidla)
S nádychom vráť nohy späť

📝 Poznamky
Bedrá a zvyšok tela drž stále aktívny
`,
    },
  },
  groundBandThighSpread: {
    id: 'groundBandThighSpread',
    name: {
      en: 'Spread the thighs with an expander on the ground',
      sk: 'Rozťahovanie stehien s expanderom na zemi',
    },
    youtubeId: { en: 'I8KC-qad6Bg', sk: 'I8KC-qad6Bg' },
    keywords: [
      'ground',
      'band',
      'strength',
      'thigh',
      'core',
      'pelvis',
      'hip',
      'knee',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase hip stability and strength
Boost core stability

⚙️ Positioning
0️⃣ Pick up an expander 
1️⃣ Lie on the ground on your back, do the Start on the ground exercise
2️⃣ Put the expander on your ankles
3️⃣ Straddle your legs to hip level
4️⃣ Lift your legs up, hip angle must be 90°
5️⃣ Arms also at shoulder level

▶️ Let go
With a breath, activate the core
Lift your head a little off the ground, activate your upper and middle back
Exhaling, straddle the legs
⚠️ Concentrate on turning the feet outwards (foot eversion)
With an inhale, bring the feet back

📝 Notes
Keep your hips and the rest of your body active at all times`,
      sk: `   
🔔 Zameranie
Zvýšenie stability bedra
Aktivácia jadra

⚙️ Pozicovanie
0️⃣ Zober expander 
1️⃣ Ľahni si na zem na chrbát, urob cvik Štart na zemi
2️⃣ Expander si daj na členky
3️⃣ Nohy rozkroč na úroveň bedier
4️⃣ Zdvihni nohy hore, uhol bedier musí byť 90°
5️⃣ Ruky aj na úrovni ramien

▶️ Pusti
S nádychom aktivuj jadro
Zdvihni hlavu trochu od zeme, aktivuj horný a stredný chrbát
S výdychom rozkroč nohy
⚠️ Sústreď sa na vytáčanie chodidiel smerom von (everzia chodidla)
S nádychom vráť nohy späť

📝 Poznamky
Bedrá a zvyšok tela drž stále aktívny
`,
    },
  },
  groundBandAnkleSpreadX: {
    id: 'groundBandAnkleSpreadX',
    name: {
      en: 'Ground Band Ankle Spread X',
      sk: 'Rozťahovanie nôh do X s expanderom na zemi',
    },
    youtubeId: { en: '0h--daeVnl8', sk: '0h--daeVnl8' },
    keywords: [
      'ground',
      'band',
      'strength',
      'coordination',
      'thigh',
      'core',
      'pelvis',
      'hip',
      'knee',
      'ankle',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase hip stability
Core activation

Positioning
0️⃣ Take the expander 
1️⃣ Lie on the ground on your back, do the Start on the ground exercise
2️⃣ Put the expander on your ankles
3️⃣ Straddle your legs to hip level
4️⃣ Lift your legs up, hip angle must be 90°
5️⃣ Arms also at shoulder level

▶️ Let go
With a breath, activate the core
Lift your arms and head off the ground, activate your upper and middle back
Exhaling, straddle your legs in an X
⚠️ Concentrate on turning the feet outwards (foot eversion)
With an inhale, bring the feet back

📝 Notes
Keep your hips and the rest of your body active at all times`,
      sk: `   
🔔 Zameranie
Zvýšenie stability bedra
Aktivácia jadra

Pozicovanie
0️⃣ Zober expander 
1️⃣ Ľahni si na zem na chrbát, urob cvik Štart na zemi
2️⃣ Expander si daj na členky
3️⃣ Nohy rozkroč na úroveň bedier
4️⃣ Zdvihni nohy hore, uhol bedier musí byť 90°
5️⃣ Ruky aj na úrovni ramien

▶️ Pusti
S nádychom aktivuj jadro
Zdvihni ruky a hlavu od zeme, aktivuj horný a stredný chrbát
S výdychom rozkroč nohy do písmena X
⚠️ Sústreď sa na vytáčanie chodidiel smerom von (everzia chodidla)
S nádychom vráť nohy späť

📝 Poznamky
Bedrá a zvyšok tela drž stále aktívny`,
    },
  },
  pushUpPike: {
    id: 'pushUpPike',
    name: {
      en: 'Pike push-up',
      sk: 'Špicatý klik',
    },
    youtubeId: { en: '06Y2RDpQSm8', sk: '06Y2RDpQSm8' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Maximising upper limb strength

⚙️ Positioning
1️⃣ Start in a push-up position, bring your hands to shoulder level
2️⃣ Bring your feet closer to your body
⚠️ Keep your back straight
3️⃣ With the opening breath, activate the core

▶️ Let go
With the inhale go to the ground
With an exhale, do a push-up

📝 Notes
Challenging shoulder exercise
The closer your legs are to your body the harder it is
Keep your legs extended
Don't fully extend your elbows`,
      sk: `  
🔔 Zameranie
Maximalizácia sily hornej končatiny

⚙️ Pozicovanie
1️⃣ Začni v pozícii kliku, ruky daj na úroveň ramien
2️⃣ Nohy prisuň bližšie k telu
⚠️ Chrbát drž vyrovnaný
3️⃣ S úvodným nádychom aktivuj jadro

▶️ Pusti
S nádychom choď k zemi
S výdychom sprav klik

📝 Poznamky
Náročný cvik na ramená
Čím bližšie máš nohy k telu tým to je ťažšie
Nohy maj vystreté
Nevystieraj úplne lakte
`,
    },
  },
  pushUpDiamond: {
    id: 'pushUpDiamond',
    name: {
      en: 'Diamond Push-up',
      sk: 'Diamantové kliky',
    },
    youtubeId: { en: 'gND2UYCuVnI', sk: 'gND2UYCuVnI' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase explosive power
Increase triceps and chest strength

⚙️ Positioning
1️⃣ Start in the push-up position
2️⃣ Place your hands at shoulder level under your chest, index finger and thumb of both hands touching
3️⃣ With the opening breath, activate the core

▶️ Let go
With the inhale go to the ground
With an exhale, do a push-up

📝 Notes
Super exercise for triceps
Don't fully extend your elbows during the exercise
Keep your core active throughout the entire movement`,
      sk: `  
🔔 Zameranie
Increase explosive power
Increase triceps and chest strength

⚙️ Pozicovanie
1️⃣ Začni v pozícii kliku
2️⃣ Ruky daj na úroveň ramien pod hrudník, ukazovák a palec oboch rúk sa dotýkajú
3️⃣ S úvodným nádychom aktivuj jadro

▶️ Pusti
S nádychom choď k zemi
S výdychom sprav klik

📝 Poznamky
Super cvik na triceps
Nevystieraj lakte úplne počas cviku
Drž jadro aktívne počas celého pohybu
`,
    },
  },
  pushUpElbowUp: {
    id: 'pushUpElbowUp',
    name: {
      en: 'Elbow up push-up',
      sk: 'Klik cez lakeť',
    },
    youtubeId: { en: '7SvRIHCMvxg', sk: '7SvRIHCMvxg' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Improving body coordination
Activation of the core

⚙️ Positioning
1️⃣ Start in the push-up position
2️⃣ Bring your hands to shoulder level
3️⃣ With the opening breath, activate the core (lower back and lower abdomen)

▶️ Let go
With a downward breath, place one elbow on the ground
Place the other elbow on the ground
Lift the first elbow off the ground
Lift the other elbow off the ground

📝 Notes
Good exercise for body coordination and stability
Try to move your body as little as possible, balance the part of your body that is pulling you towards the ground`,
      sk: `  
🔔 Zameranie
Zlepšenie koordinácie tela
Aktivácia jadra

⚙️ Pozicovanie
1️⃣ Začni v pozícii kliku
2️⃣ Ruky si daj na úroveň ramien
3️⃣ S úvodným nádychom aktivuj jadro (spodný chrbát a spodné brucho)

▶️ Pusti
S nádychom k zemi polož jeden lakeť na zem
Polož druhý lakeť na zem
Zdvihni prvý lakeť od zeme
Zdvihni druhý lakeť od zeme

📝 Poznamky
Dobrý cvik na koordináciu a stabilitu tela
Snaž sa čo najmenej hýbať s telom, vyrovnávaj tú čassť tela, ktorú ti ťahá k zemi
`,
    },
  },
  pushUpSuperman: {
    id: 'pushUpSuperman',
    name: {
      en: 'Superman push-up',
      sk: 'Supermanový klik',
    },
    youtubeId: { en: 'KGTV5L4j5-8', sk: 'KGTV5L4j5-8' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'explosive',
      'coordination',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase explosive power
Increase body coordination
Increase shoulder, triceps and pectoral muscle strength

⚙️ Positioning
1️⃣ Start in the push-up position
2️⃣ Bring your hands to shoulder level
3️⃣ With the opening breath, activate the core (lower back and lower abdomen)

▶️ Let go
With an exhale push into the ground, bounce as hard as you can
After the bounce, extend your arms in front of your body and lift your feet off the ground
⚠️ Concentrate on returning your hands to the ground early
⚠️ You can help yourself with a small upward motion of your butt as you bounce off the ground
⚠️ Don't arch your back in the air

📝 Notes
Super exercise for coordination combined with explosive power`,
      sk: `  
🔔 Zameranie
Zvýšenie explozívnej sily
Zvýšenie koordinácie tela
Zvýšenie sily ramien, tricepsu a prsných svalov

⚙️ Pozicovanie
1️⃣ Začni v pozícii kliku
2️⃣ Ruky si daj na úroveň ramien
3️⃣ S úvodným nádychom aktivuj jadro (spodný chrbát a spodné brucho)

▶️ Pusti
S výdychom zatlač do zeme, odraz sa čo najsilnejšie
Po odraze predpaž ruky pred telo a zdvihni nohy od zeme
⚠️ Sústreď sa na skorý návrat rúk na zem
⚠️ Môžeš si pomôcť malým pohybom zadku smerom hore pri odraze zo zeme
⚠️ Neprehýbaj chrbát vo vzduchu

📝 Poznamky
Super cvik na koordináciu spojenú s výbušnou silou
`,
    },
  },
  pushUpPseudo: {
    id: 'pushUpPseudo',
    name: {
      en: 'Pseudo push-up',
      sk: 'Pseudo klik',
    },
    youtubeId: { en: '5rh8h1eI3Pg', sk: '5rh8h1eI3Pg' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase body control
Maximising upper limb and chest strength

⚙️ Positioning
1️⃣ Start in the push-up position
2️⃣ Keep your hands close to your body almost at hip level, fingers pointing away from your body
3️⃣ With the opening breath, activate the core

▶️ Let go
With a downward breath
And exhaling from the ground, do a push-up
⚠️ Keep your elbows active


📝 Notes
Great exercise for shoulders, triceps and forearms
Also strengthens the core
Concentrate on keeping your upper body on your hands for this exercise
This exercise prepares you for the very difficult Plench exercise`,
      sk: `  
🔔 Zameranie
Zvýšenie kontroly tela
Maximalizácia sily hornej končatiny a hrudníka

⚙️ Pozicovanie
1️⃣ Začni v pozícii kliku
2️⃣ Ruky maj pri tele skoro na úrovni bedier, prsty smerujú od tela
3️⃣ S úvodným nádychom aktivuj jadro

▶️ Pusti
S nádychom k zemi
A výdychom od zeme sprav klik
⚠️ Lakte drž stále aktívne


📝 Poznamky
Skvelý cvik na ramená, triceps a predlaktia
Posilňuje taktiež jadro
Pri tomto cviku sa sústreď na držanie hornej časti tela na rukách
Tento cvik ťa pripravuje na veľmi ťažký cvik Plenč
`,
    },
  },
  squatWide: {
    id: 'squatWide',
    name: {
      en: 'Squat wide',
      sk: 'Široký drep',
    },
    youtubeId: { en: 'qAqAwEuB0Hs', sk: 'qAqAwEuB0Hs' },
    keywords: ['stand', 'strength', 'squats', 'lowerBody', 'midBody', 'thigh'],
    likes: 0,
    description: {
      en: `🔔 Focus
Wide squat technique

⚙️ Positioning
1️⃣ Stand up naturally
2️⃣ Bring feet to shoulder level and straddle wider, toes pointing out

▶️ Let go
With a breath, go to the ground
With an exhale do a squat
 

📝 Notes
Changing the position of the feet changes the engagement of the thigh muscles
This type is more focused on the outer thighs`,
      sk: `  
🔔 Zameranie
Tehcnika širokého drepu

⚙️ Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Chodidlá daj na úroveň ramien a trodhu širšie, špičky smerujú von

▶️ Pusti
S nádychom choď k zemi
S výdychom sprav drep
 

📝 Poznamky
Zmena polohy chodidel mení zapájanie stehnných svalov
Tento druh je zameraný viac na vonkajšie stehná 
`,
    },
  },
  jumpingJacks: {
    id: 'jumpingJacks',
    name: {
      en: 'Jumping jacks',
      sk: 'Skákajúci panák',
    },
    youtubeId: { en: '1Ssm3lzcy6w', sk: '1Ssm3lzcy6w' },
    keywords: ['stand', 'endurance', 'fullBody'],
    likes: 0,
    description: {
      en: `🔔 Focus
Improving coordination and body endurance
Good warm-up exercise

⚙️ Posing
1️⃣ Stand up naturally
2️⃣ Bring feet to shoulder level

▶️ Let go
With an exhale, straddle your legs and raise your arms in a sideways arc above your head
With an inhale, bring them back
 

📝 Notes
Watch for symmetrical movement and a gentle impact of the feet on the ground`,
      sk: `  
🔔 Zameranie
Zlepšenie koordinácie a výdrže tela
Dobrý rozcvičkový cvik

⚙️ Pozicovanie
1️⃣ Prirodzene sa postav
2️⃣ Chodidlá daj na úroveň ramien

▶️ Pusti
S výdychom rozkroč nohy a ruky bočným oblúkom zdvihni nad hlavu
S nádychom ich vráť späť
 

📝 Poznamky
Dávaj pozor na simetrický pohyb a jemný dopad chodidlami na zem
`,
    },
  },
  pushUpCloseGrip: {
    id: 'pushUpCloseGrip',
    name: { en: 'Close grip push-up', sk: 'Klik na úzko' },
    youtubeId: { en: 'EV3RUPn2_Hs', sk: 'EV3RUPn2_Hs' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase the strength of triceps, shoulders and pectoral muscles

⚙️ Positioning
1️⃣ Start in the push-up position
2️⃣ Bring your hands to shoulder level and a little closer together
3️⃣ With the opening breath, activate the core

▶️ Let go
With the inhale go to the ground
With an exhale, do a push-up
 

📝 Notes
Learn this push-up properly before you go for the Diamond push-up
Concentrate on your hand position and pushing off the ground`,
      sk: `  
🔔 Zameranie
Zvýšenie sily tricepsu, ramien a prsných svalov

⚙️ Pozicovanie
1️⃣ Začni v pozícii kliku
2️⃣ Ruky daj na úroveň ramien a trochu bližšie k sebe
3️⃣ S úvodným nádychom aktivuj jadro

▶️ Pusti
S nádychom choď k zemi
S výdychom sprav klik
 

📝 Poznamky
Nauč sa poriadne tento klik pred tým ako pôjdeš na Diamantový klik
Sústreď sa na pozíciu rúk a na tlačenie od zeme
`,
    },
  },
  pushUpNegative: {
    id: 'pushUpNegative',
    name: { en: 'Negative push-up ', sk: 'Negatívny klik' },
    youtubeId: { en: 'A1akeyH3HtM', sk: 'A1akeyH3HtM' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'upperBody',
      'midBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Beginner variation
Repeat this exercise until you know at least 5 classic push-ups
Improving the strength of the shoulders, triceps and pectoral muscles

⚙️ Positioning
1️⃣ Start in the push-up position
2️⃣ Bring your hands to shoulder level
3️⃣ With the opening breath, activate the core

▶️ Let go
With a slow exhale, go as slowly as possible to the ground
When you place your chest on the ground, lift yourself up to the starting position in a push-up

📝 Notes
If you're a beginner, practice it hard
This is the first exercise you must learn among all push-ups`,
      sk: `  
🔔 Zameranie
Začiatočnícka variácia
Opakuj tento cvik pokiaľ nevieš aspoň 5 klasických klikov
Zlešenie sily ramien, tricepsu a prsných svalov

⚙️ Pozicovanie
1️⃣ Začni v pozícii kliku
2️⃣ Ruky daj na úroveň ramien
3️⃣ S úvodným nádychom aktivuj jadro

▶️ Pusti
S pomalých výdychom choď čo najpomalšie k zemi
Keď položíš hrudník na zem, zdvihni sa do počiatočej pozície v kliku

📝 Poznamky
Pokiaľ si začiatočník, poriadne to natrénuj
Tento cvik za prvý, ktorý sa musíš naučiť spomedzi všetkých klikov
`,
    },
  },
  pushUpSideToSide: {
    id: 'pushUpSideToSide',
    name: { en: 'Side to side push-up', sk: 'Klik do strán' },
    youtubeId: { en: 'tjumZlCyPfc', sk: 'tjumZlCyPfc' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'explosive',
      'coordination',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `🔔 Focus
Increase explosive power
Improving strength of shoulders, triceps and pectoral muscles

⚙️ Positioning
1️⃣ Start in the push-up position
2️⃣ Bring your hands to shoulder level
3️⃣ With the opening breath, activate the core

▶️ Let go
With a downward breath
With an exhale do a push-up, with a push off the ground move your upper body to one side
⚠️ Keep feet in place
Breathe in as you hit the ground
With the next breath, push off the ground to the other side
 

📝 Notes
Good for zigzagging to train the combination of coordination with explosiveness
Keep your core active at all times
Concentrate on a smooth impact on the ground`,
      sk: `  
🔔 Zameranie
Zvýšenie výbušnej sily
Zlešenie sily ramien, tricepsu a prsných svalov

⚙️ Pozicovanie
1️⃣ Začni v pozícii kliku
2️⃣ Ruky daj na úroveň ramien
3️⃣ S úvodným nádychom aktivuj jadro

▶️ Pusti
S nádychom k zemi
S výdychom sprav klik, s odrazom od zeme pohni vrchnú časť tela do jednej strany
⚠️ Chodidlá drž na svojom mieste
Počas dopadu na zem sa nadýchni
S ďalším nádychom sa odraz zo zeme na druhú stranu
 

📝 Poznamky
Dobŕy na cik na tréning kombinácie koordinácie s výbušnoťou
Jadro drž aktívne po celú dobu
Sústreď sa na plinulý dopad na zem
`,
    },
  },
  pushUpArcher: {
    id: 'pushUpArcher',
    name: { en: 'Archer push-up', sk: 'Strelecký klik' },
    youtubeId: { en: 'CEAsBRINlno', sk: 'CEAsBRINlno' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'coordination',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximalizovanie koordinovanej sily hornej časti tela
Zvýšenie sily ramien, tricepsu a prsných svalov

⚙️ Pozicovanie
1️⃣ Začni v pozícii kliku
2️⃣ Ruky daj na úroveň ramien a trochu širšie, ako pri Širokom kliku
3️⃣ S prvotným nádychom aktivuj jadro

▶️ Pusti
S nádychom k zemi choď hrudníkom smerom k jednej ruke, hrudník drž stále paralélne so zemou
S výdychom sprav klik späť dostredu
⚠️ Chodidlá drž na rovnakej pozícii
With Inhale fix core and legs and go down to ground towards the hand
⚠️ HARDER: rotate palm of the other hand along with the arm
With Exhale Do a PUSH UP, while pushing from the ground, move the upper body to the other side to hand
⚠️ Keep the feet in the same position

📝 Poznamky
⚠️ Ťažšia varianta: s tým ako ideš dole k ruke, vytáčaj dlaň smerom von, prsty smerujú od tela 
HARD variation
Mastering Wide Push Ups helps
This is harder variation, in the easier you can just go down to one side and another and do not have to rotate palm while going to one side
Rotating the palm INCREASE the ELBOW forearm connections, because there are many muscle insertions in the hand that originate in the elbow area`,
      sk: `  
🔔 Zameranie
Maximize fully activated upper body muscle "team-work"
Increase Shoulder, Triceps and Chest strength and power

⚙ Pozicovanie
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level and a bit wider (Wide Push Ups)
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale fix core and legs and go down to ground towards the hand
⚠️ HARDER: rotate palm of the other hand along with the arm
With Exhale Do a PUSH UP, while pushing from the ground, move the upper body to the other side to hand
⚠️ Keep the feet in the same position
  to the other side

📝 Poznamky
HARD variation
Mastering Wide Push Ups helps
This is harder variation, in the easier you can just go down to one side and another and do not have to rotate palm while going to one side
Rotating the palm INCREASE the ELBOW forearm connections, because there are many muscle insertions in the hand that originate in the elbow area`,
    },
  },
  pushUpExplosiveSmooth: {
    id: 'pushUpExplosiveSmooth',
    name: { en: 'Explosive Smooth Push Up', sk: 'Explosive Smooth Push Up' },
    youtubeId: { en: 'GVTLVWp8SsI', sk: 'GVTLVWp8SsI' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'explosive',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase explosive power
Increase Shoulder, Triceps and Chest strength and power
The goal is to get use to the FORCE PUSH and have it mastered so the landing, transfer the energy from and to the ground
If you can repeat this with no significant problems, move on other explosive Push Ups
This exercise is STARTING POINT for other explosive types

⚙️ Pozicovanie
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go down to ground, fix core and legs
With Exhale do FORCE PUSH
⚠️ Focus on the push, take it easy and precious
Inhale while getting back to ground
⚠️ Focus on the landing, take it easy and precious
 

📝 Poznamky
Great for ground push/land mind and body coordination
Keep the core activated the whole time`,
      sk: `  
🔔 Zameranie
Increase explosive power
Increase Shoulder, Triceps and Chest strength and power
The goal is to get use to the FORCE PUSH and have it mastered so the landing, transfer the energy from and to the ground
If you can repeat this with no significant problems, move on other explosive Push Ups
This exercise is STARTING POINT for other explosive types

⚙ Pozicovanie
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go down to ground, fix core and legs
With Exhale do FORCE PUSH
⚠️ Focus on the push, take it easy and precious
Inhale while getting back to ground
⚠️ Focus on the landing, take it easy and precious
 

📝 Poznamky
Great for ground push/land mind and body coordination
Keep the core activated the whole time`,
    },
  },
  pushUpBarsGrip1: {
    id: 'pushUpBarsGrip1',
    name: { en: 'Push Up Bars Grip 1', sk: 'Push Up Bars Grip 1' },
    youtubeId: { en: '9GVNGs0A33E', sk: '9GVNGs0A33E' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'bars',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Different hand grip means different muscle activation
Increase Shoulder, Triceps and Chest strength and power
Better Arm activation than grip 2

⚙️ Pozicovanie
0️⃣ Get mini bars
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go down, fix core and legs
With Exhale push 
 

📝 Poznamky
This grip helps you use more Arm power than ground grip`,
      sk: `  
🔔 Zameranie
Different hand grip means different muscle activation
Increase Shoulder, Triceps and Chest strength and power
Better Arm activation than grip 2

⚙ Pozicovanie
0️⃣ Get mini bars
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go down, fix core and legs
With Exhale push 
 

📝 Poznamky
This grip helps you use more Arm power than ground grip`,
    },
  },
  pushUpBarsGrip2: {
    id: 'pushUpBarsGrip2',
    name: { en: 'Push Up Bars Grip 2', sk: 'Push Up Bars Grip 2' },
    youtubeId: { en: 'iA5TOUVVV9s', sk: 'iA5TOUVVV9s' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'bars',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Different hand grip means different muscle activation
Increase Shoulder, Triceps and Chest strength and power
Better Chest activation than grip 1

⚙️ Pozicovanie
0️⃣ Get mini bars
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go down, fix core and legs
With Exhale push 
 

📝 Poznamky
This grip helps you use more Arm power than ground grip`,
      sk: `  
🔔 Zameranie
Different hand grip means different muscle activation
Increase Shoulder, Triceps and Chest strength and power
Better Chest activation than grip 1

⚙ Pozicovanie
0️⃣ Get mini bars
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go down, fix core and legs
With Exhale push 
 

📝 Poznamky
This grip helps you use more Arm power than ground grip`,
    },
  },
  handstandMiniBars: {
    id: 'handstandMiniBars',
    name: { en: 'Handstand Mini Bars', sk: 'Handstand Mini Bars' },
    youtubeId: { en: 'CFyjaO3W4NI', sk: 'CFyjaO3W4NI' },
    keywords: [
      'strength',
      'handstand',
      'bars',
      'coordination',
      'fullBody',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Different hand grip means different muscle activation
Maximize Shoulder, Triceps, Chest and Core strength and power (more like full body)

⚙️ Pozicovanie
0️⃣ Get mini bars
1️⃣ Put hand at shoulder level
2️⃣ Grab bars with strong grip
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)
⚠️ Keep the core fully activated by the deep Inhale

▶️ Pusti
With little Exhale (or without) transfer the weight of the lower body from the ground to the hands
⚠️ Lock your Thorax with Scapulas
Continue with leg up movement
Fix the position
Slowly return back down
 

📝 Poznamky
This grip helps build strong stable wrists and forearm static power`,
      sk: `  
🔔 Zameranie
Different hand grip means different muscle activation
Maximize Shoulder, Triceps, Chest and Core strength and power (more like full body)

⚙ Pozicovanie
0️⃣ Get mini bars
1️⃣ Put hand at shoulder level
2️⃣ Grab bars with strong grip
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)
⚠️ Keep the core fully activated by the deep Inhale

▶️ Pusti
With little Exhale (or without) transfer the weight of the lower body from the ground to the hands
⚠️ Lock your Thorax with Scapulas
Continue with leg up movement
Fix the position
Slowly return back down
 

📝 Poznamky
This grip helps build strong stable wrists and forearm static power`,
    },
  },
  pushUpDiveBomber: {
    id: 'pushUpDiveBomber',
    name: { en: 'Dive Bomber Push Up', sk: 'Dive Bomber Push Up' },
    youtubeId: { en: 'VNKsiBYOvf4', sk: 'VNKsiBYOvf4' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'coordination',
      'fullBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Hard variation
Maximize Shoulder, Triceps and Chest strength and power

⚙️ Pozicovanie
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Put feet closer to hands
⚠️ Keep your back straight as much as possible
4️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go down to Push Up position and more ahead
⚠️ Keep your hand position stable
With Exhale push and return back the same way you came down
 

📝 Poznamky
Great exercise to train strength and mobility in hard way
Keep the movement smooth, if you have problem with the backing phase, you rather master easier and similar exercises like Pike Push Up`,
      sk: `  
🔔 Zameranie
Hard variation
Maximize Shoulder, Triceps and Chest strength and power

⚙ Pozicovanie
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Put feet closer to hands
⚠️ Keep your back straight as much as possible
4️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go down to Push Up position and more ahead
⚠️ Keep your hand position stable
With Exhale push and return back the same way you came down
 

📝 Poznamky
Great exercise to train strength and mobility in hard way
Keep the movement smooth, if you have problem with the backing phase, you rather master easier and similar exercises like Pike Push Up`,
    },
  },
  standDownUp: {
    id: 'standDownUp',
    name: { en: 'Stand Down Up', sk: 'Stand Down Up' },
    youtubeId: { en: 'APgW50MjWwM', sk: 'APgW50MjWwM' },
    keywords: ['stand', 'endurance', 'coordination', 'fullBody'],
    likes: 0,
    description: {
      en: `   
🔔 Zameranie
Increase body coordination and endurance

⚙️ Pozicovanie
1️⃣ Naturally stand still
2️⃣ Put your feet at shoulder level and a bit wider

▶️ Pusti
With Inhale go down to ground and touch ground with palms
⚠️ DO NOT lift heels
⚠️ DO NOT flex your body, keep back straight
Move your feet back to Push Up position
Return feet back to middle and stand up straight with Exhale
 

📝 Poznamky
Keep focus on proper symmetric arm and leg movement
Keep heels on the ground
Make the movement really smooth so you can continue with similar full body movements`,
      sk: `   
🔔 Zameranie
Increase body coordination and endurance

⚙️ Pozicovanie
1️⃣ Naturally stand still
2️⃣ Put your feet at shoulder level and a bit wider

▶️ Pusti
With Inhale go down to ground and touch ground with palms
⚠️ DO NOT lift heels
⚠️ DO NOT flex your body, keep back straight
Move your feet back to Push Up position
Return feet back to middle and stand up straight with Exhale
 

📝 Poznamky
Keep focus on proper symmetric arm and leg movement
Keep heels on the ground
Make the movement really smooth so you can continue with similar full body movements`,
    },
  },
  standDownPushUpJump: {
    id: 'standDownPushUpJump',
    name: { en: 'Stand Down Push Up Jump', sk: 'Stand Down Push Up Jump' },
    youtubeId: { en: 'lBZz_CUvsUg', sk: 'lBZz_CUvsUg' },
    keywords: [
      'stand',
      'endurance',
      'coordination',
      'fullBody',
      'chest',
      'upperArm',
      'shoulder',
      'thigh',
    ],
    likes: 0,
    description: {
      en: `   
🔔 Zameranie
Other name for Burpee
Increase body coordination and endurance
Increase upper body strength

⚙️ Pozicovanie
1️⃣ Naturally stand still
2️⃣ Put your feet at shoulder level and a bit wider

▶️ Pusti
With Inhale go down to ground and touch ground with palms
⚠️ DO NOT lift heels
⚠️ DO NOT flex your body, keep back straight
Move your feet back to Push Up position
Do Push Up
Return feet back to middle and stand up straight with Exhale
Right after stand up move hands up and JUMP
 

📝 Poznamky
Keep focus no proper symetric arm and leg movement
Keep heels on the ground
Make the movement really smooth so you can continue with similar full body movements
EASIER VERSION: You can place body on ground, you do not have to do Push Up
Interesting fact: If you fail discipline in Spartan Race, you gotta do 30 of these, then you can move on in race`,
      sk: `   
🔔 Zameranie
Other name for Burpee
Increase body coordination and endurance
Increase upper body strength

⚙️ Pozicovanie
1️⃣ Naturally stand still
2️⃣ Put your feet at shoulder level and a bit wider

▶️ Pusti
With Inhale go down to ground and touch ground with palms
⚠️ DO NOT lift heels
⚠️ DO NOT flex your body, keep back straight
Move your feet back to Push Up position
Do Push Up
Return feet back to middle and stand up straight with Exhale
Right after stand up move hands up and JUMP
 

📝 Poznamky
Keep focus no proper symetric arm and leg movement
Keep heels on the ground
Make the movement really smooth so you can continue with similar full body movements
EASIER VERSION: You can place body on ground, you do not have to do Push Up
Interesting fact: If you fail discipline in Spartan Race, you gotta do 30 of these, then you can move on in race`,
    },
  },
  groundLSit: {
    id: 'groundLSit',
    name: {
      en: 'Ground L Sit',
      sk: 'Ground L Sit',
    },
    youtubeId: { en: 'JwBvutSiXUY', sk: 'JwBvutSiXUY' },
    keywords: ['ground', 'strength', 'core', 'upperArm', 'shoulder', 'midBody'],
    likes: 0,
    description: {
      en: `   
🔔 Zameranie
Increase Core Activation and stability
Increase ABs strength

⚙️ Pozicovanie
1️⃣ Sit down on the ground with legs straight
2️⃣ Put hand at shoulder level

▶️ Pusti
Do DEEP Inhale
With Exhale the whole legs and buttocks from the ground
Hold
Relax
 

📝 Poznamky
Keep doing for 10-15 seconds then relax and repeat
KEEP your CORE fully activated all the time`,
      sk: `   
🔔 Zameranie
Increase Core Activation and stability
Increase ABs strength

⚙️ Pozicovanie
1️⃣ Sit down on the ground with legs straight
2️⃣ Put hand at shoulder level

▶️ Pusti
Do DEEP Inhale
With Exhale the whole legs and buttocks from the ground
Hold
Relax
 

📝 Poznamky
Keep doing for 10-15 seconds then relax and repeat
KEEP your CORE fully activated all the time`,
    },
  },
  pushUpTypewriter: {
    id: 'pushUpTypewriter',
    name: { en: 'Typewriter Push Up', sk: 'Typewriter Push Up' },
    youtubeId: { en: 'VAVjlGjAoVI', sk: 'VAVjlGjAoVI' },
    keywords: [
      'ground',
      'strength',
      'pushUps',
      'coordination',
      'upperBody',
      'midBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize fully activated upper body muscle "team-work"
Maximize Shoulder, Triceps and Chest strength and power

⚙️ Pozicovanie
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level and a bit wider (Wide Push Ups)
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale fix core and legs and go down to ground towards the hand
⚠️ Rotate palm of the other hand along with the arm
With Exhale, slowly straighten your bend elbow and bend your straight elbow, move the upper body to the other side to hand
⚠️ Keep the feet in the same position
⚠️ Keep your body straight as much as possible

📝 Poznamky
HARD variation
Mastering Wide and Archer Push Ups helps a lot
Rotating the palm INCREASE the ELBOW forearm connections, because there are many muscle insertions in the hand that originate in the elbow area`,
      sk: `  
🔔 Zameranie
Maximize fully activated upper body muscle "team-work"
Maximize Shoulder, Triceps and Chest strength and power

⚙ Pozicovanie
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level and a bit wider (Wide Push Ups)
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale fix core and legs and go down to ground towards the hand
⚠️ Rotate palm of the other hand along with the arm
With Exhale, slowly straighten your bend elbow and bend your straight elbow, move the upper body to the other side to hand
⚠️ Keep the feet in the same position
⚠️ Keep your body straight as much as possible
  to the other side

📝 Poznamky
HARD variation
Mastering Wide and Archer Push Ups helps a lot
Rotating the palm INCREASE the ELBOW forearm connections, because there are many muscle insertions in the hand that originate in the elbow area`,
    },
  },
  standDownPushUp: {
    id: 'standDownPushUp',
    name: { en: 'Typewriter Push Up', sk: 'Typewriter Push Up' },
    youtubeId: { en: 'kCUUs7AV_sE', sk: 'kCUUs7AV_sE' },
    keywords: [
      'ground',
      'stand',
      'strength',
      'pushUps',
      'fullBody',
      'endurance',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize fully activated upper body muscle "team-work"
Maximize Shoulder, Triceps and Chest strength and power

⚙️ Pozicovanie
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level and a bit wider (Wide Push Ups)
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale fix core and legs and go down to ground towards the hand
⚠️ Rotate palm of the other hand along with the arm
With Exhale, slowly straighten your bend elbow and bend your straight elbow, move the upper body to the other side to hand
⚠️ Keep the feet in the same position
⚠️ Keep your body straight as much as possible

📝 Poznamky
HARD variation
Mastering Wide and Archer Push Ups helps a lot
Rotating the palm INCREASE the ELBOW forearm connections, because there are many muscle insertions in the hand that originate in the elbow area`,
      sk: `  
🔔 Zameranie
Maximize fully activated upper body muscle "team-work"
Maximize Shoulder, Triceps and Chest strength and power

⚙ Pozicovanie
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level and a bit wider (Wide Push Ups)
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale fix core and legs and go down to ground towards the hand
⚠️ Rotate palm of the other hand along with the arm
With Exhale, slowly straighten your bend elbow and bend your straight elbow, move the upper body to the other side to hand
⚠️ Keep the feet in the same position
⚠️ Keep your body straight as much as possible
  to the other side

📝 Poznamky
HARD variation
Mastering Wide and Archer Push Ups helps a lot
Rotating the palm INCREASE the ELBOW forearm connections, because there are many muscle insertions in the hand that originate in the elbow area`,
    },
  },
  handstandYogaBlocksHorizontal: {
    id: 'handstandYogaBlocksHorizontal',
    name: {
      en: 'Handstand Yoga Blocks Horizontal',
      sk: 'Handstand Yoga Blocks Horizontal',
    },
    youtubeId: { en: 'iSdc7DrYT9s', sk: 'iSdc7DrYT9s' },
    keywords: [
      'strength',
      'coordination',
      'handstand',
      'yogaBlocks',
      'fullBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Different hand grip means different muscle activation
Maximize Shoulder, Triceps, Chest and Core strength and power (more like full body)
Master this hand position then continue to vertical type
Be careful how you place your hands on the blocks

⚙️ Pozicovanie
0️⃣ Get yoga blocks and place them with the longer part under hand
1️⃣ Put hands at shoulder level on the blocks
2️⃣ Grab blocks with strong grip
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)
⚠️ Keep the core fully activated by the deep Inhale

▶️ Pusti
With little Exhale (or without) transfer the weight of the lower body from the ground to the hands
⚠️ Lock your Thorax with Scapulas
Continue with leg up movement
Fix the position
Slowly return back down

📝 Poznamky
This grip helps build strong stable wrists and forearm static power
Great for increase fingers power
The blocks do not have sharp edges which makes the balance much more difficult`,
      sk: `  
🔔 Zameranie
Different hand grip means different muscle activation
Maximize Shoulder, Triceps, Chest and Core strength and power (more like full body)
Master this hand position then continue to vertical type
Be careful how you place your hands on the blocks

⚙ Pozicovanie
0️⃣ Get yoga blocks and place them with the longer part under hand
1️⃣ Put hands at shoulder level on the blocks
2️⃣ Grab blocks with strong grip
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)
⚠️ Keep the core fully activated by the deep Inhale

▶️ Pusti
With little Exhale (or without) transfer the weight of the lower body from the ground to the hands
⚠️ Lock your Thorax with Scapulas
Continue with leg up movement
Fix the position
Slowly return back down
 

📝 Poznamky
This grip helps build strong stable wrists and forearm static power
Great for increase fingers power
The blocks do not have sharp edges which makes the balance much more difficult`,
    },
  },
  handstandYogaBlocksVertical: {
    id: 'handstandYogaBlocksVertical',
    name: {
      en: 'Handstand Yoga Blocks Vertical',
      sk: 'Handstand Yoga Blocks Vertical',
    },
    youtubeId: { en: 'LkxKV-8UUgw', sk: 'LkxKV-8UUgw' },
    keywords: [
      'strength',
      'coordination',
      'handstand',
      'yogaBlocks',
      'fullBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
VERY HARD exercise, DO NOT try if you cannot do HORIZONTAL block Handstand
Different hand grip means different muscle activation
Maximize Shoulder, Triceps, Chest and Core strength and power (more like full body)
Master this hand position then continue to vertical type
Be careful how you place your hands on the blocks

⚙️ Pozicovanie
0️⃣ Get yoga blocks and place them with the shorter part under hands
1️⃣ Put hands at shoulder level on the blocks
2️⃣ Grab blocks with strong grip
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)
⚠️ Keep the core fully activated by the deep Inhale

▶️ Pusti
With little Exhale (or without) transfer the weight of the lower body from the ground to the hands
⚠️ Lock your Thorax with Scapulas
Continue with leg up movement
Fix the position
Slowly return back down


📝 Poznamky
This grip helps build strong stable wrists and forearm static power
Ultimate exercise for boosting fingers power
The blocks do not have sharp edges so they are not fully attached to the ground, this increase the stability work so much`,
      sk: `  
🔔 Zameranie
VERY HARD exercise, DO NOT try if you cannot do HORIZONTAL block Handstand
Different hand grip means different muscle activation
Maximize Shoulder, Triceps, Chest and Core strength and power (more like full body)
Master this hand position then continue to vertical type
Be careful how you place your hands on the blocks

⚙ Pozicovanie
0️⃣ Get yoga blocks and place them with the shorter part under hands
1️⃣ Put hands at shoulder level on the blocks
2️⃣ Grab blocks with strong grip
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)
⚠️ Keep the core fully activated by the deep Inhale

▶️ Pusti
With little Exhale (or without) transfer the weight of the lower body from the ground to the hands
⚠️ Lock your Thorax with Scapulas
Continue with leg up movement
Fix the position
Slowly return back down
 

📝 Poznamky
This grip helps build strong stable wrists and forearm static power
Ultimate exercise for boosting fingers power
The blocks do not have sharp edges so they are not fully attached to the ground, this increase the stability work so much`,
    },
  },
  barLegRaise: {
    id: 'barLegRaise',
    name: {
      en: 'Leg Raise',
      sk: 'Leg Raise',
    },
    youtubeId: { en: 'gDLyYcgR5HM', sk: 'gDLyYcgR5HM' },
    keywords: ['bars', 'strength', 'core', 'midBody', 'upperBody'],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Core strength
Hard level

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale raise feet toward the bar
Inhale and return them back down 
 

📝 Poznamky
Initial grip fingers is crucial
Keep your abdominal muscles activated during the whole movement
DO NOT release core between reps
Try not to sway your body, keep it strong together, do not move if possible, just leg movement`,
      sk: `  
🔔 Zameranie
Increase Core strength
Hard level

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale raise feet toward the bar
Inhale and return them back down 
 

📝 Poznamky
Initial grip fingers is crucial
Keep your abdominal muscles activated during the whole movement
DO NOT release core between reps
Try not to sway your body, keep it strong together, do not move if possible, just leg movement`,
    },
  },
  barKneeRaise: {
    id: 'barKneeRaise',
    name: {
      en: 'Knee Raise',
      sk: 'Knee Raise',
    },
    youtubeId: { en: 'LjoB_2qWVXw', sk: 'LjoB_2qWVXw' },
    keywords: ['bars', 'strength', 'core', 'midBody', 'upperBody'],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Core strength
Easy level

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale raise knees toward chest
Inhale and return them back down 
 

📝 Poznamky
Initial grip fingers is crucial
Keep your abdominal muscles activated during the whole movement
DO NOT release core between reps
Try not to sway with your body, keep it strong together, do not move if possible, just leg movement`,
      sk: `  
🔔 Zameranie
Increase Core strength
Easy level

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale raise knees toward chest
Inhale and return them back down 
 

📝 Poznamky
Initial grip fingers is crucial
Keep your abdominal muscles activated during the whole movement
DO NOT release core between reps
Try not to sway with your body, keep it strong together, do not move if possible, just leg movement`,
    },
  },
  pullUpCommando: {
    id: 'pullUpCommando',
    name: {
      en: 'Commando Pull Ups',
      sk: 'Commando Pull Ups',
    },
    youtubeId: { en: 'iKEmQrnLOWI', sk: 'iKEmQrnLOWI' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force with regular grip
Train primarily back and biceps muscles strength

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip close to each other
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going down

📝 Poznamky
Initial grip fingers is crucial
Good arm pumping exercise
Typical Army pull up variation`,
      sk: `  
🔔 Zameranie
Increase Pull force with regular grip
Train primarily back and biceps muscles strength

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip close to each other
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going down
  with head going to other side

📝 Poznamky
Initial grip fingers is crucial
Good arm pumping exercise
Typical Army pull up variation`,
    },
  },
  pullUpJumpUp: {
    id: 'pullUpJumpUp',
    name: {
      en: 'Jump Up Pull Ups',
      sk: 'Jump Up Pull Ups',
    },
    youtubeId: { en: 'roWLoDsAt24', sk: 'roWLoDsAt24' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'explosive',
      'coordination',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Pull force and explosive power
Train primarily back, biceps and triceps muscles strength

⚙️ Pozicovanie
0️⃣ Get two bars (as shown in video)
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull as much as possible
While your head is above the bar, move hands to the higher bar
Exhale
Inhale
Exhale with Pull up
Release hands and move to the lower bar
 

📝 Poznamky
Great Explosive and coordination exercise
You can help the jump with legs`,
      sk: `  
🔔 Zameranie
Maximize Pull force and explosive power
Train primarily back, biceps and triceps muscles strength

⚙ Pozicovanie
0️⃣ Get two bars (as shown in video)
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull as much as possible
While your head is above the bar, move hands to the higher bar
Exhale
Inhale
Exhale with Pull up
Release hands and move to the lower bar
 

📝 Poznamky
Great Explosive and coordination exercise
You can help the jump with legs`,
    },
  },
  chinUpLSit: {
    id: 'chinUpLSit',
    name: {
      en: 'L Sit Chin Ups',
      sk: 'L Sit Chin Ups',
    },
    youtubeId: { en: 'ua8vewQ_CzA', sk: 'ua8vewQ_CzA' },
    keywords: [
      'bars',
      'strength',
      'chinUps',
      'core',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force
Increase forearm flexors strength
Increase Core strength

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms to face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Raise legs up to 90 degree angle
Pull and Exhale at top of bar
Inhale while going down
 

📝 Poznamky
Initial grip fingers is crucial
Use your thumb as counter support with the other 4 fingers
Keep your abdominal muscles activated during the whole movement
If you can not do proper 90 degree angle, do more regular L Sit exercise to get comfort with the leg hold`,
      sk: `  
🔔 Zameranie
Increase Pull force
Increase forearm flexors strength
Increase Core strength

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms to face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Raise legs up to 90 degree angle
Pull and Exhale at top of bar
Inhale while going down
 

📝 Poznamky
Initial grip fingers is crucial
Use your thumb as counter support with the other 4 fingers
Keep your abdominal muscles activated during the whole movement
If you can not do proper 90 degree angle, do more regular L Sit exercise to get comfort with the leg hold`,
    },
  },
  pullUpLSit: {
    id: 'pullUpLSit',
    name: {
      en: 'L Sit Pull Ups',
      sk: 'L Sit Pull Ups',
    },
    youtubeId: { en: 'WxdGNASmAhY', sk: 'WxdGNASmAhY' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'core',
      'coordination',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force
Increase Core strength

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Raise legs up to 90 degree angle (better than mine)
Pull and Exhale at top of bar
Inhale while going down
 

📝 Poznamky
Initial grip fingers is crucial
Keep your abdominal muscles activated during the whole movement
If you can not do proper 90 degree angle, do more regular L Sit exercise to get comfort with the leg hold`,
      sk: `  
🔔 Zameranie
Increase Pull force
Increase Core strength

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Raise legs up to 90 degree angle (better than mine)
Pull and Exhale at top of bar
Inhale while going down
 

📝 Poznamky
Initial grip fingers is crucial
Keep your abdominal muscles activated during the whole movement
If you can not do proper 90 degree angle, do more regular L Sit exercise to get comfort with the leg hold`,
    },
  },
  pullUpInOut: {
    id: 'pullUpInOut',
    name: {
      en: 'In Out Pull Ups',
      sk: 'In Out Pull Ups',
    },
    youtubeId: { en: 'WxdGNASmAhY', sk: 'WxdGNASmAhY' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'explosive',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
      'coordination',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force
Increase Explosive power
Train primarily back, biceps and triceps muscles strength

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Change grip
Inhale while going down
 

📝 Poznamky
Good for coordination
Make the grip change movement smooth
DO NOT release core while switching grip`,
      sk: `  
🔔 Zameranie
Increase Pull force
Increase Explosive power
Train primarily back, biceps and triceps muscles strength

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Change grip
Inhale while going down
 

📝 Poznamky
Good for coordination
Make the grip change movement smooth
DO NOT release core while switching grip`,
    },
  },
  dipRegular: {
    id: 'dipRegular',
    name: {
      en: 'Regular Dip',
      sk: 'Regular Dip',
    },
    youtubeId: { en: 'mFU4I51DL9I', sk: 'mFU4I51DL9I' },
    keywords: [
      'bars',
      'strength',
      'dips',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Push force
Train primarily biceps, triceps and shoulder muscles strength
Master this before going on harder Explosive dip variations

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Inhale go down, lock elbows
With Exhale push
 

📝 Poznamky
Great exercise for Upper arm functionality and strength
I used to do a LOT of these, this built my whole shoulder along with Push Ups`,
      sk: `  
🔔 Zameranie
Increase Push force
Train primarily biceps, triceps and shoulder muscles strength
Master this before going on harder Explosive dip variations

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Inhale go down, lock elbows
With Exhale push
 

📝 Poznamky
Great exercise for Upper arm functionality and strength
I used to do a LOT of these, this built my whole shoulder along with Push Ups`,
    },
  },
  pullUpBarSpin: {
    id: 'pullUpBarSpin',
    name: {
      en: 'Pull Up Bar Spin',
      sk: 'Pull Up Bar Spin',
    },
    youtubeId: { en: 'zuAUFbwzuF0', sk: 'zuAUFbwzuF0' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'core',
      'coordination',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase upper body active coordination
One way how to get up above bar

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and activate your low abs to bring thighs toward chest, little swing with legs
When your legs are near the bar activate arms and pull toward bar, this uplift your legs
Move body weight over bar
Inhale
 

📝 Poznamky
Initial grip fingers is crucial
You can do this for reps
I use to go up like this for straight bar exercises
This is also great exercise if you can not do Regular Pull Up properly`,
      sk: `  
🔔 Zameranie
Increase upper body active coordination
One way how to get up above bar

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and activate your low abs to bring thighs toward chest, little swing with legs
When your legs are near the bar activate arms and pull toward bar, this uplift your legs
Move body weight over bar
Inhale
 

📝 Poznamky
Initial grip fingers is crucial
You can do this for reps
I use to go up like this for straight bar exercises
This is also great exercise if you can not do Regular Pull Up properly`,
    },
  },
  pullUpArcher: {
    id: 'pullUpArcher',
    name: {
      en: 'Archer Pull Ups',
      sk: 'Archer Pull Ups',
    },
    youtubeId: { en: 'loCN2L6lwlw', sk: 'loCN2L6lwlw' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'coordination',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
      'core',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Pull Control
Train primarily BACK, biceps and triceps muscles strength
Wider grip cause better lats activation
Maximize your upper body coordination, stability and mobility in fully activated position

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level and wider
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull body toward one hand
When your head is above bar start Exhaling and twisting other arm around the bar
Fix position
Return back with Inhale
 

📝 Poznamky
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
You better get wider initial grip or you will end up like and gotta replace that hand to have comfort in it`,
      sk: `  
🔔 Zameranie
Maximize Pull Control
Train primarily BACK, biceps and triceps muscles strength
Wider grip cause better lats activation
Maximize your upper body coordination, stability and mobility in fully activated position

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level and wider
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull body toward one hand
When your head is above bar start Exhaling and twisting other arm around the bar
Fix position
Return back with Inhale
 

📝 Poznamky
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
You better get wider initial grip or you will end up like and gotta replace that hand to have comfort in it`,
    },
  },
  chinUpClose: {
    id: 'chinUpClose',
    name: {
      en: 'Close Chin Ups',
      sk: 'Close Chin Ups',
    },
    youtubeId: { en: 'yxyGyfrxXjQ', sk: 'yxyGyfrxXjQ' },
    keywords: [
      'bars',
      'strength',
      'chinUps',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force
Train primarily back, biceps muscles strength
Build strong forearms flexors and extensors and their static hold power
This grip helps you use more of arm than back power

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands close to each other
2️⃣ Grab bars with strong grip, palm to face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going down
Unlock elbows and relax pelvis
 

📝 Poznamky
Initial fingers grip is crucial, use your Thumb
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
Different grip means different muscle activation`,
      sk: `  
🔔 Zameranie
Increase Pull force
Train primarily back, biceps muscles strength
Build strong forearms flexors and extensors and their static hold power
This grip helps you use more of arm than back power

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands close to each other
2️⃣ Grab bars with strong grip, palm to face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going down
Unlock elbows and relax pelvis
 

📝 Poznamky
Initial fingers grip is crucial, use your Thumb
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
Different grip means different muscle activation`,
    },
  },
  chinUpRegular: {
    id: 'chinUpRegular',
    name: {
      en: 'Regular Chin Ups',
      sk: 'Regular Chin Ups',
    },
    youtubeId: { en: 'GP0_qbKASfE', sk: 'GP0_qbKASfE' },
    keywords: [
      'bars',
      'strength',
      'chinUps',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force
Train primarily back, biceps muscles strength
Build strong forearms flexors and extensors and their static hold power

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip, palm to face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going down
Unlock elbows and relax pelvis
 

📝 Poznamky
Initial grip fingers is crucial, use your Thumb
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation`,
      sk: `  
🔔 Zameranie
Increase Pull force
Train primarily back, biceps muscles strength
Build strong forearms flexors and extensors and their static hold power

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip, palm to face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going down
Unlock elbows and relax pelvis
 

📝 Poznamky
Initial grip fingers is crucial, use your Thumb
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation`,
    },
  },
  pullUpNegative: {
    id: 'pullUpNegative',
    name: {
      en: 'Negative Pull Ups',
      sk: 'Negative Pull Ups',
    },
    youtubeId: { en: 'Rwmzyi93vKs', sk: 'Rwmzyi93vKs' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'core',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull hold
Train primarily back, biceps and triceps muscles strength
If you can not do Regular Pull Ups for reps, this is great start
Boost core active hold

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip, palm from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going SLOWLY down
Unlock elbows and relax pelvis
 

📝 Poznamky
Initial grip fingers is crucial
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
There is no limit how slow you can go, SLOWER means HARDER`,
      sk: `  
🔔 Zameranie
Increase Pull hold
Train primarily back, biceps and triceps muscles strength
If you can not do Regular Pull Ups for reps, this is great start
Boost core active hold

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip, palm from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going SLOWLY down
Unlock elbows and relax pelvis
 

📝 Poznamky
Initial grip fingers is crucial
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
There is no limit how slow you can go, SLOWER means HARDER`,
    },
  },
  pullUpActiveHang: {
    id: 'pullUpActiveHang',
    name: {
      en: 'Pull Up Active Hang',
      sk: 'Pull Up Active Hang',
    },
    youtubeId: { en: 'QC4Rq8enoss', sk: 'QC4Rq8enoss' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'core',
      'spineBot',
      'spineMid',
      'scapula',
      'shoulder',
      'pelvis',
      'hand',
      'fingers4Hand',
      'elbow',
      'upperBody',
      'midBody',
      'back',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Build core DEEP core
Build Scapulothoracic connections with spine and pelvis

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers

▶️ Pusti
Start with relaxed body
With Exhale, activate pelvis (low back, low abdominals) and activate (depress) scapulas
 

📝 Poznamky
This helped me back pain problems
Even just hanging without any muscle activation greatly helps your body to relax with power of gravity as it pull it down to ground
Then you try to slowly activate and hold the core of body, pelvis, scapulas and thorax
BUT you need to have as many other body parts relaxed as possible`,
      sk: `  
🔔 Zameranie
Build core DEEP core
Build Scapulothoracic connections with spine and pelvis

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers

▶️ Pusti
Start with relaxed body
With Exhale, activate pelvis (low back, low abdominals) and activate (depress) scapulas
 

📝 Poznamky
This helped me back pain problems
Even just hanging without any muscle activation greatly helps your body to relax with power of gravity as it pull it down to ground
Then you try to slowly activate and hold the core of body, pelvis, scapulas and thorax
BUT you need to have as many other body parts relaxed as possible`,
    },
  },
  pullUpWide: {
    id: 'pullUpWide',
    name: {
      en: 'Wide Pull Ups',
      sk: 'Wide Pull Ups',
    },
    youtubeId: { en: 'PijSStmzcRQ', sk: 'PijSStmzcRQ' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'upperBody',
      'midBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force
Train primarily BACK, biceps and triceps muscles strength
Wider grip cause better lats activation

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level and wider
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going down
Unlock elbows and relax pelvis
 

📝 Poznamky
Initial grip fingers is crucial
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation`,
      sk: `  
🔔 Zameranie
Increase Pull force
Train primarily BACK, biceps and triceps muscles strength
Wider grip cause better lats activation

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level and wider
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going down
Unlock elbows and relax pelvis
 

📝 Poznamky
Initial grip fingers is crucial
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation`,
    },
  },
  pullUpRegular: {
    id: 'pullUpRegular',
    name: {
      en: 'Regular Pull Ups',
      sk: 'Regular Pull Ups',
    },
    youtubeId: { en: 'omTyHDjHud0', sk: 'omTyHDjHud0' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'upperBody',
      'midBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force
Train primarily back, biceps and triceps muscles strength
Master this for easier continuation to any other Pull Up variation

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going down
Unlock elbows and relax pelvis
 

📝 Poznamky
Initial grip fingers is crucial
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
If you can do 8 of these, go on harder variations like High Pull Ups`,
      sk: `  
🔔 Zameranie
Increase Pull force
Train primarily back, biceps and triceps muscles strength
Master this for easier continuation to any other Pull Up variation

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
Inhale while going down
Unlock elbows and relax pelvis
 

📝 Poznamky
Initial grip fingers is crucial
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
If you can do 8 of these, go on harder variations like High Pull Ups`,
    },
  },
  pullUpHigh: {
    id: 'pullUpHigh',
    name: {
      en: 'High Pull Ups',
      sk: 'High Pull Ups',
    },
    youtubeId: { en: 'UlwGDKmbG-k', sk: 'UlwGDKmbG-k' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'explosive',
      'upperBody',
      'midBody',
      'back',
      'upperArm',
      'shoulder',
      'coordination',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Start of new Pull Up serie
Maximize Pull force
Preparation for Muscle Up
Get chest to bar high level then it counts

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals)


▶️ Pusti
With Exhale pull
⚠️ Try to pull away from the bar so you can get elbows horizontal with the bar
Inhale while going down
 

📝 Poznamky
Initial grip fingers is crucial
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
It is one of the harder variations
If you can do 8 of these, you good`,
      sk: `  
🔔 Zameranie
Start of new Pull Up serie
Maximize Pull force
Preparation for Muscle Up
Get chest to bar high level then it counts

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals)


▶️ Pusti
With Exhale pull
⚠️ Try to pull away from the bar so you can get elbows horizontal with the bar
Inhale while going down
 

📝 Poznamky
Initial grip fingers is crucial
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
It is one of the harder variations
If you can do 8 of these, you good`,
    },
  },
  dipStraightBar: {
    id: 'dipStraightBar',
    name: {
      en: 'Straight Bar Dip',
      sk: 'Straight Bar Dip',
    },
    youtubeId: { en: 'qkES1_Lcd9E', sk: 'qkES1_Lcd9E' },
    keywords: [
      'bars',
      'strength',
      'dips',
      'upperBody',
      'midBody',
      'upperArm',
      'shoulder',
      'chest',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Push force
Train primarily pectoralis, triceps and shoulder muscles strength

⚙️ Pozicovanie
0️⃣ Get bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Inhale go down, lock elbows
With Exhale push
 

📝 Poznamky
Great exercise for Triceps and Forearms muscles strength
Train the bottom push position for Muscle Up (up bar push)
Lower the chest to bar`,
      sk: `  
🔔 Zameranie
Increase Push force
Train primarily pectoralis, triceps and shoulder muscles strength

⚙ Pozicovanie
0️⃣ Get bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Inhale go down, lock elbows
With Exhale push
 

📝 Poznamky
Great exercise for Triceps and Forearms muscles strength
Train the bottom push position for Muscle Up (up bar push)
Lower the chest to bar`,
    },
  },
  barCat: {
    id: 'barCat',
    name: {
      en: 'Bar Cat',
      sk: 'Bar Cat',
    },
    youtubeId: { en: 'tIX0eqPpyNs', sk: 'tIX0eqPpyNs' },
    keywords: ['bars', 'strength', 'coordination', 'core', 'fullBody', 'back'],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase upper body coordination and mobility
Boost hand grip

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Inhale lift your low body toward the bar
⚠️ Keep Thorax fully activated
With Exhale move legs backwards
With Inhale revert the movement
Relax
 

📝 Poznamky
Initial grip fingers is crucial
Good for beginners to increase bar grip hold, upper body strength and mobility`,
      sk: `  
🔔 Zameranie
Increase upper body coordination and mobility
Boost hand grip

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Inhale lift your low body toward the bar
⚠️ Keep Thorax fully activated
With Exhale move legs backwards
With Inhale revert the movement
Relax
 

📝 Poznamky
Initial grip fingers is crucial
Good for beginners to increase bar grip hold, upper body strength and mobility`,
    },
    free: true,
  },
  pushUpIncline: {
    id: 'pushUpIncline',
    name: {
      en: 'Push Up Incline',
      sk: 'Push Up Incline',
    },
    youtubeId: { en: 'uSrDPD1DNoY', sk: 'uSrDPD1DNoY' },
    keywords: [
      'strength',
      'pushUps',
      'chair',
      'ground',
      'upperBody',
      'midBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Beginner Push Up variation
Do this till you can do at least 5 Regular Push Ups
Increase Shoulder, Triceps and Chest strength and power

⚙️ Pozicovanie
0️⃣ Get some higher object
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go down to the object
With Exhale push
 

📝 Poznamky
Easy variation for beginners
Higher object you use, the easier it will be, the easiest are Wall Push Ups`,
      sk: `  
🔔 Zameranie
Beginner Push Up variation
Do this till you can do at least 5 Regular Push Ups
Increase Shoulder, Triceps and Chest strength and power

⚙ Pozicovanie
0️⃣ Get some higher object
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go down to the object
With Exhale push
 

📝 Poznamky
Easy variation for beginners
Higher object you use, the easier it will be, the easiest are Wall Push Ups`,
    },
  },
  chinUpNegative: {
    id: 'chinUpNegative',
    name: {
      en: 'Negative Chin Ups',
      sk: 'Negative Chin Ups',
    },
    youtubeId: { en: 'oaIQjb3EPvA', sk: 'oaIQjb3EPvA' },
    keywords: [
      'bars',
      'strength',
      'chinUps',
      'upperBody',
      'midBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull Hold
Train primarily back, biceps muscles strength
Build strong forearms flexors and extensors and their static hold power

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip, palm to face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
SLOWLY go down
Then Inhale
 

📝 Poznamky
Initial grip fingers is crucial, use your Thumb
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
Get use to the active bar hold, increase Arm strength stability`,
      sk: `  
🔔 Zameranie
Increase Pull Hold
Train primarily back, biceps muscles strength
Build strong forearms flexors and extensors and their static hold power

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip, palm to face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
SLOWLY go down
Then Inhale
 

📝 Poznamky
Initial grip fingers is crucial, use your Thumb
The initial pelvis activation helps your legs to go rather forward than backwards, it is good for better core activation
Get use to the active bar hold, increase Arm strength stability`,
    },
  },
  pullUpToeTouch: {
    id: 'pullUpToeTouch',
    name: {
      en: 'Toe Touch Pull Up',
      sk: 'Toe Touch Pull Up',
    },
    youtubeId: { en: '2Qz4M9zBhLU', sk: '2Qz4M9zBhLU' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'explosive',
      'upperBody',
      'midBody',
      'coordination',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Pull force
Increase pull explosive power

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
While you are above bar, drop the bar and straighten your arms like you want to touch your toes
Inhale while going down
 

📝 Poznamky
Keep your abdominal muscles activated during the whole movement
Try to reach your toes if possible (I'm still training it)
This is HARD variation
If you master this, you will have no problem with other explosive variations`,
      sk: `  
🔔 Zameranie
Maximize Pull force
Increase pull explosive power

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale at top of bar
While you are above bar, drop the bar and straighten your arms like you want to touch your toes
Inhale while going down
 

📝 Poznamky
Keep your abdominal muscles activated during the whole movement
Try to reach your toes if possible (I'm still training it)
This is HARD variation
If you master this, you will have no problem with other explosive variations`,
    },
  },
  muscleUpRegular: {
    id: 'muscleUpRegular',
    name: {
      en: 'Regular Muscle Up',
      sk: 'Regular Muscle Up',
    },
    youtubeId: { en: '_4DxpI2_ZYo', sk: '_4DxpI2_ZYo' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'upperBody',
      'midBody',
      'coordination',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Pull force
Maximize Core and Upper body strength and mobility

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale Pull
When your chest is near the bar, wrap your hands around the bar
Then Push from the bar with elbows
Inhale while going down
 

📝 Poznamky
Initial grip fingers is crucial
Keep your abdominal muscles activated during the whole movement
HARD variation
If you can do one proper rep, keep doing it for sets even of one repetition
You can help the pull movement with legs, it is harder if you do not use them`,
      sk: `  
🔔 Zameranie
Maximize Pull force
Maximize Core and Upper body strength and mobility

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale Pull
When your chest is near the bar, wrap your hands around the bar
Then Push from the bar with elbows
Inhale while going down
 

📝 Poznamky
Initial grip fingers is crucial
Keep your abdominal muscles activated during the whole movement
HARD variation
If you can do one proper rep, keep doing it for sets even of one repetition
You can help the pull movement with legs, it is harder if you do not use them`,
    },
  },
  dipClap: {
    id: 'dipClap',
    name: {
      en: 'Clap Dips',
      sk: 'Clap Dips',
    },
    youtubeId: { en: 'BZX4PELBgbI', sk: 'BZX4PELBgbI' },
    keywords: [
      'bars',
      'strength',
      'dips',
      'explosive',
      'coordination',
      'upperBody',
      'midBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Push force and body coordination
Train primarily biceps, triceps and shoulder muscles strength

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Inhale go down, lock elbows
With Exhale do force push and clap with hands
With Inhale return back to start position and slow the fall with your elbows
 

📝 Poznamky
Great exercise for Upper arm functionality and strength
You can help the jump with leg movement, it is much harder if you use just your arms for push`,
      sk: `  
🔔 Zameranie
Maximize Push force and body coordination
Train primarily biceps, triceps and shoulder muscles strength

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Inhale go down, lock elbows
With Exhale do force push and clap with hands
With Inhale return back to start position and slow the fall with your elbows
 

📝 Poznamky
Great exercise for Upper arm functionality and strength
You can help the jump with leg movement, it is much harder if you use just your arms for push`,
    },
    free: true,
  },
  dipJumping: {
    id: 'dipJumping',
    name: {
      en: 'Jumping Dips',
      sk: 'Jumping Dips',
    },
    youtubeId: { en: 'x-JnefjjlBI', sk: 'x-JnefjjlBI' },
    keywords: [
      'bars',
      'strength',
      'dips',
      'explosive',
      'coordination',
      'upperBody',
      'midBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Push force and body coordination
Train primarily biceps, triceps and shoulder muscles strength

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Inhale go down, lock elbows
With Exhale do force push and move body forward
With Inhale return back and slow the fall with your elbows
 

📝 Poznamky
Great exercise for Upper arm functionality and strength
Try NOT moving your low body, keep it stable as much as possible
Great exercise to learn Explosive power with dip bars`,
      sk: `  
🔔 Zameranie
Maximize Push force and body coordination
Train primarily biceps, triceps and shoulder muscles strength

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Inhale go down, lock elbows
With Exhale do force push and move body forward
With Inhale return back and slow the fall with your elbows
 

📝 Poznamky
Great exercise for Upper arm functionality and strength
Try NOT moving your low body, keep it stable as much as possible
Great exercise to learn Explosive power with dip bars`,
    },
  },
  pullUpReverse: {
    id: 'pullUpReverse',
    name: {
      en: 'Reverse Pull Ups',
      sk: 'Reverse Pull Ups',
    },
    youtubeId: { en: 'CuhC7S7wX_M', sk: 'CuhC7S7wX_M' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'upperBody',
      'midBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force
This Arm angle targets primarily pectoralis muscles
Get use to different body positions while pulling up to bar

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep
4️⃣ With initial Inhale move your legs up and backwards, so you can relax them and still hold your body with hands
5️⃣ Activate pectoralis, core and legs, get the knee angle 90 degree

▶️ Pusti
With Exhale Pull
With Inhale return
 

📝 Poznamky
Initial grip fingers is crucial
This exercise uses your full body weight into arms
You can feel your pectoralis going full activation`,
      sk: `  
🔔 Zameranie
Increase Pull force
This Arm angle targets primarily pectoralis muscles
Get use to different body positions while pulling up to bar

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep
4️⃣ With initial Inhale move your legs up and backwards, so you can relax them and still hold your body with hands
5️⃣ Activate pectoralis, core and legs, get the knee angle 90 degree

▶️ Pusti
With Exhale Pull
With Inhale return
 

📝 Poznamky
Initial grip fingers is crucial
This exercise uses your full body weight into arms
You can feel your pectoralis going full activation`,
    },
  },
  barsFlow1: {
    id: 'barsFlow1',
    name: {
      en: 'Bars Flow 1',
      sk: 'Bars Flow 1',
    },
    youtubeId: { en: 'yn2wHvC94-c', sk: 'yn2wHvC94-c' },
    keywords: [
      'bars',
      'strength',
      'dips',
      'pullUps',
      'flow',
      'upperBody',
      'midBody',
      'back',
      'upperArm',
      'shoulder',
      'chest',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Train position changing from one exercise to another
Maximize upper body strength, activation and coordination

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Firstly do Regular Dip
Then move legs above bar
Slide down
Do Muscle Up with bent legs
Relax
 

📝 Poznamky
Make it as SMOOTH as possible
Going from one position to another helps your body to learn being active in different position in row
Skill this and add more movements to it and make it even more fascinating
This is considered as beginner flow on dip bars by many calisthenics`,
      sk: `  
🔔 Zameranie
Train position changing from one exercise to another
Maximize upper body strength, activation and coordination

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Firstly do Regular Dip
Then move legs above bar
Slide down
Do Muscle Up with bent legs
Relax
 

📝 Poznamky
Make it as SMOOTH as possible
Going from one position to another helps your body to learn being active in different position in row
Skill this and add more movements to it and make it even more fascinating
This is considered as beginner flow on dip bars by many calisthenics`,
    },
  },
  pullUpAustralianRegular: {
    id: 'pullUpAustralianRegular',
    name: {
      en: 'Regular Australian Pull Ups',
      sk: 'Regular Australian Pull Ups',
    },
    youtubeId: { en: 'e3oNzKcIkRA', sk: 'e3oNzKcIkRA' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'upperBody',
      'midBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Keeping feet on the ground reduce the weight you need to Pull
Increase Pull force

⚙️ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with strong Pull Up grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale Pull
With Inhale return
 

📝 Poznamky
Great exercise for Upper arm functionality and strength for beginners
Keep your body fully activated`,
      sk: `  
🔔 Zameranie
Keeping feet on the ground reduce the weight you need to Pull
Increase Pull force

⚙ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with strong Pull Up grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale Pull
With Inhale return
 

📝 Poznamky
Great exercise for Upper arm functionality and strength for beginners
Keep your body fully activated`,
    },
  },
  chinUpAustralianRegular: {
    id: 'chinUpAustralianRegular',
    name: {
      en: 'Regular Australian Chin Ups',
      sk: 'Regular Australian Chin Ups',
    },
    youtubeId: { en: 'MRK1lqMVVdg', sk: 'MRK1lqMVVdg' },
    keywords: [
      'bars',
      'strength',
      'chinUps',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Keeping feet on the ground reduce the weight you need to Pull
Increase Pull force
Increase forearm flexors and extensors strength

⚙️ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with strong Chin Up grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale Pull
With Inhale return
 

📝 Poznamky
Great exercise for Upper arm functionality and strength for begginers
Keep your body fully activated`,
      sk: `  
🔔 Zameranie
Keeping feet on the ground reduce the weight you need to Pull
Increase Pull force
Increase forearm flexors and extensors strength

⚙ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with strong Chin Up grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale Pull
With Inhale return
 

📝 Poznamky
Great exercise for Upper arm functionality and strength for begginers
Keep your body fully activated`,
    },
  },
  pullUpAustralianGripSwitch: {
    id: 'pullUpAustralianGripSwitch',
    name: {
      en: 'Australian Pull Up Grip Switch',
      sk: 'Australian Pull Up Grip Switch',
    },
    youtubeId: { en: 'xyA-o3PBu6E', sk: 'xyA-o3PBu6E' },
    keywords: [
      'bars',
      'strength',
      'chinUps',
      'coordination',
      'pullUps',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Keeping feet on the ground reduce the weight you need to Pull
Increase Pull force
Increase hand coordination and grip strength

⚙️ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with Chin Up grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale Pull and switch bar grip
With Inhale return
 

📝 Poznamky
Great exercise for Upper arm functionality and strength for beginners
Keep your body fully activated
Keep focus on the switch movement, always grab the bar properly`,
      sk: `  
🔔 Zameranie
Keeping feet on the ground reduce the weight you need to Pull
Increase Pull force
Increase hand coordination and grip strength

⚙ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with Chin Up grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale Pull and switch bar grip
With Inhale return
 

📝 Poznamky
Great exercise for Upper arm functionality and strength for beginners
Keep your body fully activated
Keep focus on the switch movement, always grab the bar properly`,
    },
  },
  pullUpExplosive: {
    id: 'pullUpExplosive',
    name: {
      en: 'Explosive Pull Ups',
      sk: 'Explosive Pull Ups',
    },
    youtubeId: { en: 'gs-F4Apmt94', sk: 'gs-F4Apmt94' },
    keywords: [
      'bars',
      'strength',
      'explosive',
      'pullUps',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force
Increase Explosive power
Train primarily back, biceps and triceps muscles strength

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Force Pull and Exhale at top of bar, let go your bar grip
Inhale while going down
 

📝 Poznamky
Maximize explosive power by maxing the initial pull, then relax hands and focus on the following grab
Make the grip change movement smooth
DO NOT release core anytime`,
      sk: `  
🔔 Zameranie
Increase Pull force
Increase Explosive power
Train primarily back, biceps and triceps muscles strength

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Force Pull and Exhale at top of bar, let go your bar grip
Inhale while going down
 

📝 Poznamky
Maximize explosive power by maxing the initial pull, then relax hands and focus on the following grab
Make the grip change movement smooth
DO NOT release core anytime`,
    },
  },
  pullUpBehindHead: {
    id: 'pullUpBehindHead',
    name: {
      en: 'Behind Head Pull Ups',
      sk: 'Behind Head Pull Ups',
    },
    youtubeId: { en: 'gMBVUYHAfbU', sk: 'gMBVUYHAfbU' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'coordination',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force
Increase upper body active flexibility
Train primarily back, biceps and triceps muscles strength

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale when your head is above bar
Inhale while going down
 

📝 Poznamky
Be careful with the movement, always feel comfortable with the move, especially when you head goes in front of the bar
This trains your middle back and neck muscles to be fully activated in different position`,
      sk: `  
🔔 Zameranie
Increase Pull force
Increase upper body active flexibility
Train primarily back, biceps and triceps muscles strength

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull and Exhale when your head is above bar
Inhale while going down
 

📝 Poznamky
Be careful with the movement, always feel comfortable with the move, especially when you head goes in front of the bar
This trains your middle back and neck muscles to be fully activated in different position`,
    },
  },
  pullUpGripSwitch: {
    id: 'pullUpGripSwitch',
    name: {
      en: 'Grip Switch Pull Ups',
      sk: 'Grip Switch Pull Ups',
    },
    youtubeId: { en: 'bCvqGSIyRXU', sk: 'bCvqGSIyRXU' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'chinUps',
      'explosive',
      'coordination',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Pull force both grips
Increase Explosive power
Train primarily back, biceps and triceps muscles strength

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Force Pull and Exhale at top of bar
When your head is above bar, switch your grip
Inhale while going down
 

📝 Poznamky
Maximize explosive power by maxing the initial pull
Make the grip change movement smooth
DO NOT release core anytime
Great exercise for training the upper arm activation in different angles`,
      sk: `  
🔔 Zameranie
Maximize Pull force both grips
Increase Explosive power
Train primarily back, biceps and triceps muscles strength

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Force Pull and Exhale at top of bar
When your head is above bar, switch your grip
Inhale while going down
 

📝 Poznamky
Maximize explosive power by maxing the initial pull
Make the grip change movement smooth
DO NOT release core anytime
Great exercise for training the upper arm activation in different angles`,
    },
  },
  pullUpTypewriter: {
    id: 'pullUpTypewriter',
    name: {
      en: 'Typewriter Pull Ups',
      sk: 'Typewriter Pull Ups',
    },
    youtubeId: { en: 'fEF52ZVQui8', sk: 'fEF52ZVQui8' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'coordination',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Pull control and active muscles connections
Train primarily back, biceps and triceps muscles strength

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level and wider
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Do Pull Up
When your head is above bar start moving body toward one hand and straighten the other
Inhale and Exhale while moving to the sides
 

📝 Poznamky
Hard Pull Up variation
Maximize body control
Train this for One Arm Pull Ups
DO NOT release the straight arm, keep it bended in elbow, open the palm and have the arm fully activated the whole time
DO NOT release core anytime`,
      sk: `  
🔔 Zameranie
Maximize Pull control and active muscles connections
Train primarily back, biceps and triceps muscles strength

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level and wider
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Do Pull Up
When your head is above bar start moving body toward one hand and straighten the other
Inhale and Exhale while moving to the sides
 

📝 Poznamky
Hard Pull Up variation
Maximize body control
Train this for One Arm Pull Ups
DO NOT release the straight arm, keep it bended in elbow, open the palm and have the arm fully activated the whole time
DO NOT release core anytime`,
    },
  },
  pullUpGripSwitchOneHand: {
    id: 'pullUpGripSwitchOneHand',
    name: {
      en: 'Grip Switch One Hand Pull Ups',
      sk: 'Grip Switch One Hand Pull Ups',
    },
    youtubeId: { en: 'YafaJ7Krdx4', sk: 'YafaJ7Krdx4' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'coordination',
      'chinUps',
      'explosive',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Pull force with different bar grip with each hand
Increase Explosive power
Train strength of back, pectoralis, biceps and triceps muscles

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip, each hand has different grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep
4️⃣ Start at top of bar, so you can get better starting position

▶️ Pusti
With Inhale slowly go down
With Exhale Pull Up
When your head is above bar change grip of one hand

Poznamky
Dobry cvik
`,
      sk: `  
🔔 Zameranie
Maximize Pull force with different bar grip with each hand
Increase Explosive power
Train strength of back, pectoralis, biceps and triceps muscles

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip, each hand has different grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep
4️⃣ Start at top of bar, so you can get better starting position

▶️ Pusti
With Inhale slowly go down
With Exhale Pull Up
When your head is above bar change grip of one hand
with other hand

Poznamky
Dobry cvik`,
    },
  },
  pullUpNeutralGrip: {
    id: 'pullUpNeutralGrip',
    name: {
      en: 'Neutral Grip Pull Ups',
      sk: 'Neutral Grip Pull Ups',
    },
    youtubeId: { en: '5JkHJFFF-CA', sk: '5JkHJFFF-CA' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Pull force with neutral grip
Train primarily back, pectoralis and biceps muscles strength

⚙️ Pozicovanie
0️⃣ Get double straight bars
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull with Exhale
Return with Inhale
 

📝 Poznamky
Good exercise for training the neutral hand grip, this type is the most friendy grip to body, you can lift easily than with the Pull or Chin Up grips`,
      sk: `  
🔔 Zameranie
Increase Pull force with neutral grip
Train primarily back, pectoralis and biceps muscles strength

⚙ Pozicovanie
0️⃣ Get double straight bars
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
Pull with Exhale
Return with Inhale
 

📝 Poznamky
Good exercise for training the neutral hand grip, this type is the most friendy grip to body, you can lift easily than with the Pull or Chin Up grips`,
    },
  },
  pullUpOneArmNegative: {
    id: 'pullUpOneArmNegative',
    name: {
      en: 'One Arm Negative Pull Up',
      sk: 'One Arm Negative Pull Up',
    },
    youtubeId: { en: 'j_1-KZBKeV8', sk: 'j_1-KZBKeV8' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'core',
      'coordination',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize One Arm Pull hold
Train this for One Arm Pull Up

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
4️⃣ Do Pull Up

▶️ Pusti
Move your body slightly toward the one hand
Let of your other hand
As slow as possible move your body down
 

📝 Poznamky
Maximize one arm hold
DO NOT release core anytime
Don't sway your body keep it straight
I can not do this exercise excellent as it is one of the hardest variations, keep trying it like this and once you will learn that movement`,
      sk: `  
🔔 Zameranie
Maximize One Arm Pull hold
Train this for One Arm Pull Up

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
4️⃣ Do Pull Up

▶️ Pusti
Move your body slightly toward the one hand
Let of your other hand
As slow as possible move your body down
 

📝 Poznamky
Maximize one arm hold
DO NOT release core anytime
Don't sway your body keep it straight
I can not do this exercise excellent as it is one of the hardest variations, keep trying it like this and once you will learn that movement`,
    },
  },
  pullVerticalScapulaTraction: {
    id: 'pullVerticalScapulaTraction',
    name: {
      en: 'Vertical Pull Scapula Traction',
      sk: 'Vertical Pull Scapula Traction',
    },
    youtubeId: { en: 'X2vKZKAxTew', sk: 'X2vKZKAxTew' },
    keywords: [
      'bars',
      'strength',
      'stretch',
      'pullUps',
      'coordination',
      'scapula',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Scapula retraction and protraction movement
This builds strong functional rhomboids along with arm

⚙️ Pozicovanie
0️⃣ Get vertical bar
1️⃣ Put feet under the bar, your hand should be between the legs
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Inhale slowly go down, keep the scapula against the thorax
When you reach full arm movement, release scapula (protraction)
With Exhale firstly activate the scapula (retraction)
Then continue with arm pull toward the bar
 

📝 Poznamky
Great exercise for scapula training
I used to do this to help my scapula pain problems as you slowly activate and release it
Focus on the scapula traction movement rather than strength, this is not hard but precise exercise
Keep your back straight and your body perpendicular to the ground`,
      sk: `  
🔔 Zameranie
Increase Scapula retraction and protraction movement
This builds strong functional rhomboids along with arm

⚙ Pozicovanie
0️⃣ Get vertical bar
1️⃣ Put feet under the bar, your hand should be between the legs
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Inhale slowly go down, keep the scapula against the thorax
When you reach full arm movement, release scapula (protraction)
With Exhale firstly activate the scapula (retraction)
Then continue with arm pull toward the bar
 

📝 Poznamky
Great exercise for scapula training
I used to do this to help my scapula pain problems as you slowly activate and release it
Focus on the scapula traction movement rather than strength, this is not hard but precise exercise
Keep your back straight and your body perpendicular to the ground`,
    },
  },
  dipNegative: {
    id: 'dipNegative',
    name: {
      en: 'Negative Dip',
      sk: 'Negative Dip',
    },
    youtubeId: { en: 'IhNPRMlN4w0', sk: 'IhNPRMlN4w0' },
    keywords: [
      'bars',
      'strength',
      'dips',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Push hold
Train primarily biceps, triceps and shoulder muscles strength
This is good exercise for beginners if you can not do dips properly

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Inhale go SLOWLY down, lock elbows
With Exhale push
 

📝 Poznamky
Great exercise for learning Regular Dip
This also builds strong thorax connections as you are fully activating the scapula and shoulder against the thorax`,
      sk: `  
🔔 Zameranie
Increase Push hold
Train primarily biceps, triceps and shoulder muscles strength
This is good exercise for beginners if you can not do dips properly

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Inhale go SLOWLY down, lock elbows
With Exhale push
 

📝 Poznamky
Great exercise for learning Regular Dip
This also builds strong thorax connections as you are fully activating the scapula and shoulder against the thorax`,
    },
  },
  dipBench: {
    id: 'dipBench',
    name: {
      en: 'Bench Dip',
      sk: 'Bench Dip',
    },
    youtubeId: { en: 'CajaQcKDlnI', sk: 'CajaQcKDlnI' },
    keywords: [
      'strength',
      'dips',
      'upperArm',
      'shoulder',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Triceps and Shoulder power

⚙️ Pozicovanie
0️⃣ Get bench
1️⃣ Put hands at shoulder level

▶️ Pusti
With Inhale go down, lock elbows in 90 degree
With Exhale push
 

📝 Poznamky
Good exercise for beginners`,
      sk: `  
🔔 Zameranie
Increase Triceps and Shoulder power

⚙ Pozicovanie
0️⃣ Get bench
1️⃣ Put hands at shoulder level

▶️ Pusti
With Inhale go down, lock elbows in 90 degree
With Exhale push
 

📝 Poznamky
Good exercise for beginners`,
    },
  },
  barCoreLegsToSides: {
    id: 'barCoreLegsToSides',
    name: {
      en: 'Bar Core Legs To Sides',
      sk: 'Bar Core Legs To Sides',
    },
    youtubeId: { en: 'tx95gM7OfC8', sk: 'tx95gM7OfC8' },
    keywords: [
      'bars',
      'strength',
      'core',
      'coordination',
      'midBody',
      'upperBody',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Boost core activation and hold
Increase Thoracic connections

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
Lift legs to the bar
Slowly move legs to the one side then to the other
Return with Inhale

📝 Poznamky
Hard Core exercise`,
      sk: `  
🔔 Zameranie
Boost core activation and hold
Increase Thoracic connections

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
Lift legs to the bar
Slowly move legs to the one side then to the other
Return with Inhale
  with other hand 

📝 Poznamky
Hard Core exercise`,
    },
  },
  muscleUpNegative: {
    id: 'muscleUpNegative',
    name: {
      en: 'Negative Muscle Up',
      sk: 'Negative Muscle Up',
    },
    youtubeId: { en: 'nrS7ziX3BMU', sk: 'nrS7ziX3BMU' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Muscle Up movement
Maximize Core and Upper body strength and mobility

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before the rep
4️⃣ Start above the bar

▶️ Pusti
With Exhale SLOWLY go down
 

📝 Poznamky
Initial grip fingers is very important
Keep your abdominal muscles activated during the whole movement
Train this for mastering Muscle Up grip and the whole movement
Get use to the movement when your elbows are going under the bar, this move is crucial`,
      sk: `  
🔔 Zameranie
Maximize Muscle Up movement
Maximize Core and Upper body strength and mobility

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before the rep
4️⃣ Start above the bar

▶️ Pusti
With Exhale SLOWLY go down
 

📝 Poznamky
Initial grip fingers is very important
Keep your abdominal muscles activated during the whole movement
Train this for mastering Muscle Up grip and the whole movement
Get use to the movement when your elbows are going under the bar, this move is crucial`,
    },
  },
  barsKneeRaise: {
    id: 'barsKneeRaise',
    name: {
      en: 'Bars Knee Raise',
      sk: 'Bars Knee Raise',
    },
    youtubeId: { en: 'BDMcizZHQLg', sk: 'BDMcizZHQLg' },
    keywords: ['bars', 'strength', 'core', 'midBody', 'upperBody'],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Core activation
Boost hand grip hold
Easier variation

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Exhale raise your knees toward the chest
With Inhale return
 

📝 Poznamky
Train this for better grip
Keep your core activated the whole time`,
      sk: `  
🔔 Zameranie
Increase Core activation
Boost hand grip hold
Easier variation

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Exhale raise your knees toward the chest
With Inhale return
 

📝 Poznamky
Train this for better grip
Keep your core activated the whole time`,
    },
  },
  barsLegRaise: {
    id: 'barsLegRaise',
    name: {
      en: 'Bars Leg Raise',
      sk: 'Bars Leg Raise',
    },
    youtubeId: { en: 'C9lQPkuJgBU', sk: 'C9lQPkuJgBU' },
    keywords: ['bars', 'strength', 'core', 'midBody', 'upperBody'],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Core and Quadriceps activation
Boost hand grip hold
Medium variation

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Exhale raise your legs to 90 degree
With Inhale return
 

📝 Poznamky
Train this for better grip
Keep your core activated the whole time
Then try to go higher than 90 deg but slow more
Slowing down the movement make it harder`,
      sk: `  
🔔 Zameranie
Increase Core and Quadriceps activation
Boost hand grip hold
Medium variation

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Exhale raise your legs to 90 degree
With Inhale return
 

📝 Poznamky
Train this for better grip
Keep your core activated the whole time
Then try to go higher than 90 deg but slow more
Slowing down the movement make it harder`,
    },
  },
  barsLSit: {
    id: 'barsLSit',
    name: {
      en: 'Bars L Sit',
      sk: 'Bars L Sit',
    },
    youtubeId: { en: 'tBw6yp-Rim8', sk: 'tBw6yp-Rim8' },
    keywords: [
      'bars',
      'strength',
      'core',
      'midBody',
      'upperBody',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Core and Quadriceps activation
Boost hand grip hold
Maximize Thoracic connections

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Exhale raise your legs to 90 degree and hold
With Inhale return
 

📝 Poznamky
Train this for better grip
Keep your core activated the whole time
Keep the 90 deg angle, do not sway your body`,
      sk: `  
🔔 Zameranie
Increase Core and Quadriceps activation
Boost hand grip hold
Maximize Thoracic connections

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Exhale raise your legs to 90 degree and hold
With Inhale return
 

📝 Poznamky
Train this for better grip
Keep your core activated the whole time
Keep the 90 deg angle, do not sway your body`,
    },
  },
  barsCoreLegStraighten: {
    id: 'barsCoreLegStraighten',
    name: {
      en: 'Bars Core Leg Straighten',
      sk: 'Bars Core Leg Straighten',
    },
    youtubeId: { en: 'zylDz8iWiUY', sk: 'zylDz8iWiUY' },
    keywords: [
      'bars',
      'strength',
      'core',
      'coordination',
      'midBody',
      'upperBody',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Core and Quadriceps activation
Boost hand grip hold
Maximize Thoracic connections
Master this before harder exercises like L Sit or V Sit

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
4️⃣ Lift knees toward the chest

▶️ Pusti
With Exhale straighten one leg
With Inhale return

📝 Poznamky
Train this for better grip
Keep your core activated the whole time
Keep the 90 deg angle, do not sway your body
Try to slow down the movement to increase difficulty`,
      sk: `  
🔔 Zameranie
Increase Core and Quadriceps activation
Boost hand grip hold
Maximize Thoracic connections
Master this before harder exercises like L Sit or V Sit

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
4️⃣ Lift knees toward the chest

▶️ Pusti
With Exhale straighten one leg
With Inhale return

📝 Poznamky
Train this for better grip
Keep your core activated the whole time
Keep the 90 deg angle, do not sway your body
Try to slow down the movement to increase difficulty`,
    },
  },
  pullUpFrontLeverHalf: {
    id: 'pullUpFrontLeverHalf',
    name: {
      en: 'Half Front Lever Pull Ups',
      sk: 'Half Front Lever Pull Ups',
    },
    youtubeId: { en: '4M2v3I9OK4U', sk: '4M2v3I9OK4U' },
    keywords: [
      'bars',
      'strength',
      'core',
      'coordination',
      'pullUps',
      'fullBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Core and Thorax activation
Increase Pull force and body coordination

⚙️ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with Pull Up grip
⚠️ Focus on fingers
3️⃣ Lift knee toward the chest
4️⃣ Straighten your back parallel to the ground
5️⃣ Keep Thorax, abdominals and low back fully activated
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale Pull
With Inhale return
 

📝 Poznamky
Great exercise for Front Lever exercise
Keep the whole upper body fully activated, scapulas depressed and active against the chest, after that start Pulling toward the bar`,
      sk: `  
🔔 Zameranie
Maximize Core and Thorax activation
Increase Pull force and body coordination

⚙ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with Pull Up grip
⚠️ Focus on fingers
3️⃣ Lift knee toward the chest
4️⃣ Straighten your back parallel to the ground
5️⃣ Keep Thorax, abdominals and low back fully activated
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With Exhale Pull
With Inhale return
 

📝 Poznamky
Great exercise for Front Lever exercise
Keep the whole upper body fully activated, scapulas depressed and active against the chest, after that start Pulling toward the bar`,
    },
  },
  handstandBarsPos1: {
    id: 'handstandBarsPos1',
    name: {
      en: 'Handstand Bars Pos 1',
      sk: 'Handstand Bars Pos 1',
    },
    youtubeId: { en: 'o3VxXlWXSkE', sk: 'o3VxXlWXSkE' },
    keywords: [
      'bars',
      'strength',
      'core',
      'coordination',
      'handstand',
      'fullBody',
      'back',
      'upperArm',
      'shoulder',
      'forearm',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Grip and Upper arm activation
Maximize Shoulder and Elbow stability
Boost Core
First position to learn Handstand on bars

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With little Exhale start raising low body and bending elbows to 90 degree
⚠️ Get your back STRAIGHT, lock elbows and shoulders
Hold as long as possible
With Inhale return
 

📝 Poznamky
Master this before going in to upcoming position
Always FIX the position then continue to the following one
Keep Glutes and Low back active`,
      sk: `  
🔔 Zameranie
Maximize Grip and Upper arm activation
Maximize Shoulder and Elbow stability
Boost Core
First position to learn Handstand on bars

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With little Exhale start raising low body and bending elbows to 90 degree
⚠️ Get your back STRAIGHT, lock elbows and shoulders
Hold as long as possible
With Inhale return
 

📝 Poznamky
Master this before going in to upcoming position
Always FIX the position then continue to the following one
Keep Glutes and Low back active`,
    },
  },
  handstandBars: {
    id: 'handstandBars',
    name: {
      en: 'Handstand Bars',
      sk: 'Handstand Bars',
    },
    youtubeId: { en: 'LaP7FBbN6oA', sk: 'LaP7FBbN6oA' },
    keywords: [
      'bars',
      'strength',
      'handstand',
      'coordination',
      'fullBody',
      'back',
      'upperArm',
      'shoulder',
      'forearm',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Grip and Upper body activation
Maximize Shoulder, Thorax, Elbow and hand stability
Maximize movement on the dip bars, learn the smooth flow between positions 1, 2 and 3
This is my official first handstand this kind
Now as I can do it, I can master that smooth move from position to another to master that movement

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With little Exhale start raising low body and bending elbows to 90 degree
⚠️ Get your back STRAIGHT, lock elbows and shoulders
Straighten your legs
Push and straighten elbows too
Hold the position
With Inhale return
 

📝 Poznamky
Practice positions 1 (bend elbows and legs) a 2 (straight legs) and 3 (Push)
Always FIX the position then continue to the following one
Keep Glutes and Low back active
After you complete all of them, try to focus on the Thorax activation, Hand grip and Low back, those 3 points are crucial
Then you can relax feet a bit`,
      sk: `  
🔔 Zameranie
Maximize Grip and Upper body activation
Maximize Shoulder, Thorax, Elbow and hand stability
Maximize movement on the dip bars, learn the smooth flow between positions 1, 2 and 3
This is my official first handstand this kind
Now as I can do it, I can master that smooth move from position to another to master that movement

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With little Exhale start raising low body and bending elbows to 90 degree
⚠️ Get your back STRAIGHT, lock elbows and shoulders
Straighten your legs
Push and straighten elbows too
Hold the position
With Inhale return
 

📝 Poznamky
Practice positions 1 (bend elbows and legs) a 2 (straight legs) and 3 (Push)
Always FIX the position then continue to the following one
Keep Glutes and Low back active
After you complete all of them, try to focus on the Thorax activation, Hand grip and Low back, those 3 points are crucial
Then you can relax feet a bit`,
    },
  },
  muscleUpExplosive: {
    id: 'muscleUpExplosive',
    name: {
      en: 'Explosive Muscle Up',
      sk: 'Explosive Muscle Up',
    },
    youtubeId: { en: 'ITLlkDKiKSA', sk: 'ITLlkDKiKSA' },
    keywords: [
      'bars',
      'strength',
      'explosive',
      'pullUps',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
      'chest',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Pull force
Maximize Core and Upper body strength and mobility

⚙️ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Go up above bar (I prefer to start like this)
3️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
4️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With little Inhale go down 
⚠️ Keep the whole body ACTIVE, do not release any part from initial activation
With Exhale Pull
When your chest is near the bar, wrap your hands around the bar
Then Push AS MUCH AS POSSIBLE from the bar with elbows (like you want to pull the bar out)
Inhale while going down
 

📝 Poznamky
Initial grip fingers is crucial
HARD variation, your limit is your PUSH power
Do this ONLY if you can do Muscle Up properly, no need to hurry up to doing this, do not hurt yourself`,
      sk: `  
🔔 Zameranie
Maximize Pull force
Maximize Core and Upper body strength and mobility

⚙ Pozicovanie
0️⃣ Get straight bar
1️⃣ Put hands at shoulder level
2️⃣ Go up above bar (I prefer to start like this)
3️⃣ Grab bars with strong grip palms from the face
⚠️ Focus on fingers
4️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep

▶️ Pusti
With little Inhale go down 
⚠️ Keep the whole body ACTIVE, do not release any part from initial activation
With Exhale Pull
When your chest is near the bar, wrap your hands around the bar
Then Push AS MUCH AS POSSIBLE from the bar with elbows (like you want to pull the bar out)
Inhale while going down
 

📝 Poznamky
Initial grip fingers is crucial
HARD variation, your limit is your PUSH power
Do this ONLY if you can do Muscle Up properly, no need to hurry up to doing this, do not hurt yourself`,
    },
  },
  barsScissors: {
    id: 'barsScissors',
    name: {
      en: 'Bars Scissors',
      sk: 'Bars Scissors',
    },
    youtubeId: { en: '98_nVzG7oRw', sk: '98_nVzG7oRw' },
    keywords: [
      'bars',
      'strength',
      'core',
      'coordination',
      'midBody',
      'upperBody',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Core and Quadriceps activation
Boost hand grip hold
Maximize Thoracic connections
Similar difficulty and target as L Sit

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
Lift legs into 90 degree angle
Start moving your legs alternately
With Inhale return
 

📝 Poznamky
Train this for better grip
Keep your core activated the whole time
Keep the 90 deg angle, do not sway your body
Do not Inhale while moving with legs, it releases core activation, it is hard to breathe and having the core fully activated`,
      sk: `  
🔔 Zameranie
Increase Core and Quadriceps activation
Boost hand grip hold
Maximize Thoracic connections
Similar difficulty and target as L Sit

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
Lift legs into 90 degree angle
Start moving your legs alternately
With Inhale return
 

📝 Poznamky
Train this for better grip
Keep your core activated the whole time
Keep the 90 deg angle, do not sway your body
Do not Inhale while moving with legs, it releases core activation, it is hard to breathe and having the core fully activated`,
    },
  },
  pushUpNeutralGripDeep: {
    id: 'pushUpNeutralGripDeep',
    name: {
      en: 'Deep Neutral Grip Push Up',
      sk: 'Deep Neutral Grip Push Up',
    },
    youtubeId: { en: 'W_1SnFIAM4M', sk: 'W_1SnFIAM4M' },
    keywords: [
      'bars',
      'strength',
      'pushUps',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
      'core',
      'coordination',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Beginner Push Up variation
Do this till you can do at least 5 Regular Push Ups
Increase Shoulder, Triceps and Chest strength and power
Increase shoulder stability, strength and range of motion

⚙️ Pozicovanie
0️⃣ Get Dip bars
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go deep down
⚠️ Keep Elbows and Shoulders locked, open chest, stretch Pectoralis Major
⚠️ Focus on the initial move after stop
With Exhale do controlled push
⚠️ KEEP your back STRAIGHT
 

📝 Poznamky
Good exercise to increase Chest mobility and range of motion of shoulder
Do it properly, do not hurry up, you may get injured, that is not typical shoulder position`,
      sk: `  
🔔 Zameranie
Beginner Push Up variation
Do this till you can do at least 5 Regular Push Ups
Increase Shoulder, Triceps and Chest strength and power
Increase shoulder stability, strength and range of motion

⚙ Pozicovanie
0️⃣ Get Dip bars
1️⃣ Get to Push Up position
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals)

▶️ Pusti
With Inhale go deep down
⚠️ Keep Elbows and Shoulders locked, open chest, stretch Pectoralis Major
⚠️ Focus on the initial move after stop
With Exhale do controlled push
⚠️ KEEP your back STRAIGHT
 

📝 Poznamky
Good exercise to increase Chest mobility and range of motion of shoulder
Do it properly, do not hurry up, you may get injured, that is not typical shoulder position`,
    },
  },
  dipExplosive: {
    id: 'dipExplosive',
    name: {
      en: 'Explosive Dip',
      sk: 'Explosive Dip',
    },
    youtubeId: { en: 'sMN69k4ZRlY', sk: 'sMN69k4ZRlY' },
    keywords: [
      'bars',
      'strength',
      'dips',
      'explosive',
      'midBody',
      'upperBody',
      'chest',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Maximize Push power
Maximize biceps, triceps and shoulder muscles strength
This is good exercise right from the beginning after you can do at least 10 Regular Dips properly
Build strong big upper arm

⚙️ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Inhale go down, lock elbows
With Exhale push AS MUCH AS POSSIBLE, there is no limit and no need to  care about any other body position changes
⚠️ Only focus on the hand grip during land, lock elbows, fix the position
 

📝 Poznamky
Great exercise for maximizing the power from the very beginning
If you cannot push that much you can let the bar with hand, do not do that, you rather grab the bar with the strongest grip and do the move
This also builds that kind of power, similar way
I used to do A LOT of this`,
      sk: `  
🔔 Zameranie
Maximize Push power
Maximize biceps, triceps and shoulder muscles strength
This is good exercise right from the beginning after you can do at least 10 Regular Dips properly
Build strong big upper arm

⚙ Pozicovanie
0️⃣ Get bars for dips
1️⃣ Put hands at shoulder level
2️⃣ Grab bars with strong grip
⚠️ Focus on fingers
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows

▶️ Pusti
With Inhale go down, lock elbows
With Exhale push AS MUCH AS POSSIBLE, there is no limit and no need to  care about any other body position changes
⚠️ Only focus on the hand grip during land, lock elbows, fix the position
 

📝 Poznamky
Great exercise for maximizing the power from the very beginning
If you cannot push that much you can let the bar with hand, do not do that, you rather grab the bar with the strongest grip and do the move
This also builds that kind of power, similar way
I used to do A LOT of this`,
    },
  },
  chinUpAustralianOneArm: {
    id: 'chinUpAustralianOneArm',
    name: {
      en: 'Australian One Arm Chin Ups',
      sk: 'Australian One Arm Chin Ups',
    },
    youtubeId: { en: 'nv_OZWlj6B8', sk: 'nv_OZWlj6B8' },
    keywords: [
      'bars',
      'strength',
      'chinUps',
      'core',
      'coordination',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Keeping feet on the ground reduce the weight you need to Pull
Maximize One Arm Pull force and stability, Chin Up grip
Master this before One Arm Chin Up

⚙️ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with strong Chin Up grip
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep
4️⃣ Move one hand a bit closer to middle of your chest, so you got triangle stability (feet and hand)
⚠️ Focus on fingers

▶️ Pusti
With Exhale Pull
⚠️ Keep your body straight in line, do not sway, keep feet on the ground
With Inhale return
 

📝 Poznamky
Hard variation great exercise for Upper arm functionality and strength
Keep your body fully activated the whole time
Do not fully extend your elbow
Move the other shoulder toward the bar, it is more natural`,
      sk: `  
🔔 Zameranie
Keeping feet on the ground reduce the weight you need to Pull
Maximize One Arm Pull force and stability, Chin Up grip
Master this before One Arm Chin Up

⚙ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with strong Chin Up grip
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep
4️⃣ Move one hand a bit closer to middle of your chest, so you got triangle stability (feet and hand)
⚠️ Focus on fingers

▶️ Pusti
With Exhale Pull
⚠️ Keep your body straight in line, do not sway, keep feet on the ground
With Inhale return
 

📝 Poznamky
Hard variation great exercise for Upper arm functionality and strength
Keep your body fully activated the whole time
Do not fully extend your elbow
Move the other shoulder toward the bar, it is more natural`,
    },
  },
  pullUpAustralianOneArm: {
    id: 'pullUpAustralianOneArm',
    name: {
      en: 'Australian One Arm Pull Ups',
      sk: 'Australian One Arm Pull Ups',
    },
    youtubeId: { en: '91zj1jjcDMA', sk: '91zj1jjcDMA' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'core',
      'coordination',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Keeping feet on the ground reduce the weight you need to Pull
Maximize One Arm Pull force and stability, Pull Up grip
Master this before One Arm Pull Up

⚙️ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with strong Pull Up grip
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep
4️⃣ Move one hand a bit closer to middle of your chest, so you got triangle stability (feet and hand)
⚠️ Focus on fingers

▶️ Pusti
With Exhale Pull
⚠️ Keep your body straight in line, do not sway, keep feet on the ground
With Inhale return
 

📝 Poznamky
Hard variation great exercise for Upper arm functionality and strength
Keep your body fully activated the whole time
Do not fully extend your elbow
Move the other shoulder toward the bar, it is more natural`,
      sk: `  
🔔 Zameranie
Keeping feet on the ground reduce the weight you need to Pull
Maximize One Arm Pull force and stability, Pull Up grip
Master this before One Arm Pull Up

⚙ Pozicovanie
0️⃣ Get lower bar
1️⃣ Put hands at shoulder level
2️⃣ Grab the bar with strong Pull Up grip
3️⃣ With the initial inhalation, activate pelvis (low back, low abdominals) and elbows
⚠️ DO NOT start with fully straight elbows, bend (activate) them before first rep
4️⃣ Move one hand a bit closer to middle of your chest, so you got triangle stability (feet and hand)
⚠️ Focus on fingers

▶️ Pusti
With Exhale Pull
⚠️ Keep your body straight in line, do not sway, keep feet on the ground
With Inhale return
 

📝 Poznamky
Hard variation great exercise for Upper arm functionality and strength
Keep your body fully activated the whole time
Do not fully extend your elbow
Move the other shoulder toward the bar, it is more natural`,
    },
  },
  pullUpNeutralGripReverse: {
    id: 'pullUpNeutralGripReverse',
    name: {
      en: 'Reverse Neutral Grip Pull Up',
      sk: 'Reverse Neutral Grip Pull Up',
    },
    youtubeId: { en: '91zj1jjcDMA', sk: '91zj1jjcDMA' },
    keywords: [
      'bars',
      'strength',
      'pullUps',
      'midBody',
      'upperBody',
      'back',
      'upperArm',
      'shoulder',
    ],
    likes: 0,
    description: {
      en: `  
🔔 Zameranie
Increase Shoulder, Triceps and Chest strength and power
Increase Core stability
Neutral grip helps you to lift yourself in the most natural hand position
Having feet positioned above the rest of body increases the difficulty, it is reverse than with Australian type where your feet are on the ground reducing the body weight

⚙️ Pozicovanie
0️⃣ Get Bar ladder
1️⃣ Get up putting the bar on the ankles
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals, straighten your back)

▶️ Pusti
With Inhale go down
⚠️ Keep Elbows and Shoulders locked
With Exhale pull, get bar on the chest
⚠️ KEEP your back STRAIGHT
 

📝 Poznamky
Good exercise to increase to build strong shoulders
Keep your Scapulas and shoulders fully active the whole time`,
      sk: `  
🔔 Zameranie
Increase Shoulder, Triceps and Chest strength and power
Increase Core stability
Neutral grip helps you to lift yourself in the most natural hand position
Having feet positioned above the rest of body increases the difficulty, it is reverse than with Australian type where your feet are on the ground reducing the body weight

⚙ Pozicovanie
0️⃣ Get Bar ladder
1️⃣ Get up putting the bar on the ankles
2️⃣ Put hand at shoulder level
3️⃣ Before the initial inhalation, activate the core first (low back, low abdominals, straighten your back)

▶ Pusti
With Inhale go down
⚠️ Keep Elbows and Shoulders locked
With Exhale pull, get bar on the chest
⚠️ KEEP your back STRAIGHT
 

📝 Poznamky
Good exercise to increase to build strong shoulders
Keep your Scapulas and shoulders fully active the whole time`,
    },
  },
};
