import { isIOS } from 'src/utils/constants';
import { FlatList, FlatListProps, StyleSheet } from 'react-native';
import { useHeaderHeight } from '@react-navigation/elements';
import { useMenuDimensions } from 'src/hooks/useMenuDimensions';
import { Spinner } from 'native-base';
import { CONTENT_MAX_WIDTH } from 'src/theme/constants';
import Animated, { Layout } from 'react-native-reanimated';

export type ScreenProps<T> = FlatListProps<T> & {
  loading?: boolean;
  transparentHeader?: boolean;
  animated?: boolean;
  scrollRef?: React.RefObject<FlatList<T>>;
};

export const FlatListScreen = <T extends object>({
  contentContainerStyle,
  loading,
  transparentHeader = isIOS,
  animated,
  scrollRef,
  ...props
}: ScreenProps<T>) => {
  const { inverted } = props;
  const headerHeight = useHeaderHeight();
  const { paddingBottom } = useMenuDimensions();
  const paddingStart = transparentHeader ? headerHeight + 16 : 16;
  const paddingEnd = paddingBottom + 16;

  if (loading) {
    return <Spinner m="auto" />;
  }

  const style = {
    paddingTop: inverted ? paddingEnd : paddingStart,
    paddingBottom: inverted ? paddingStart : paddingEnd,
    ...(typeof contentContainerStyle === 'object'
      ? contentContainerStyle
      : undefined),
  };

  if (animated) {
    return (
      <Animated.FlatList
        // @ts-ignore
        ref={scrollRef}
        contentContainerStyle={[style, styles.container]}
        keyboardShouldPersistTaps="handled"
        // @ts-ignore
        itemLayoutAnimation={Layout.springify()}
        {...props}
      />
    );
  }

  return (
    <FlatList
      ref={scrollRef}
      contentContainerStyle={[style, styles.container]}
      keyboardShouldPersistTaps="handled"
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: CONTENT_MAX_WIDTH,
    marginHorizontal: 'auto',
    paddingHorizontal: 16,
  },
});
