import { Screen } from 'components/layout/Screen';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useAuthUser, userPremiumState } from 'src/state/state';
import {
  getManagementUrl,
  onFetchSubscription,
} from 'src/firebase/subscription';
import { getUserRole } from '../../firebase/auth';
import { ISubscription, ISubscriptionRC } from 'src/@types/api';
import { ActiveSubscription } from 'components/subscription/ActiveSubscription';
import { SubscriptionPlans } from 'components/subscription/SubscriptionPlans';
import { HeadingTertiary } from 'components/typography/heading';

export const SubscriptionScreen = () => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const [isPremium, setPremium] = useRecoilState(userPremiumState);
  const [subscription, setSubscription] = useState<ISubscription>();
  const [subscriptionRC, setSubscriptionRC] = useState<ISubscriptionRC>();
  const [managementUrl, setManagementUrl] = useState('');

  const fetchPremium = useCallback(async () => {
    const isPremium = await getUserRole();
    setPremium(isPremium);
  }, [setPremium]);

  useEffect(() => {
    fetchPremium();
    getManagementUrl().then(setManagementUrl);
  }, [fetchPremium]);

  useEffect(() => {
    const productId = user?.entitlements?.premium?.product_identifier;
    if (productId && user?.subscriptions?.[productId]) {
      setSubscriptionRC(user.subscriptions[productId]);
    }
  }, [user?.subscriptions, user?.entitlements]);

  useEffect(() => {
    if (user?.uid) {
      return onFetchSubscription(user.uid, setSubscription);
    }
  }, [user?.uid]);

  if (!user) {
    return null;
  }

  return (
    <Screen title={t('routes.subscription')}>
      {isPremium ? (
        <ActiveSubscription
          subscription={subscription}
          subscriptionRC={subscriptionRC}
          managementUrl={managementUrl}
        />
      ) : (
        <>
          <HeadingTertiary textAlign="center">
            {t('subscription.title')}
          </HeadingTertiary>
          <SubscriptionPlans />
        </>
      )}
    </Screen>
  );
};
