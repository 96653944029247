import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Screen } from 'components/layout/Screen';
import { Column, Image, Row, Text, useDisclose } from 'native-base';
import { ProblemsStackScreenProps } from 'src/navigation/types/problemsStack';
import { Routes } from 'src/navigation/routes';
import { getProblemsConfig } from 'src/data/problems/problemsConfig';
import { useTranslation } from 'react-i18next';
import { HeadingSecondary } from 'components/typography/heading';
import { FavoriteProblemButton } from 'components/buttons/FavoriteProblemButton';
import { FloatingButton } from 'components/buttons/FloatingButton';
import { BodyIcon, GymIcon } from 'components/icons/icons';
import { getMusclesConfig } from 'src/data/muscles/musclesConfig';
import { MusclesBottomModal } from 'components/modals/MusclesBottomModal';
import { ExercisesBottomModal } from 'components/modals/ExercisesBottomModal';
import { useRecoilState, useRecoilValue } from 'recoil';
import { exercisesState, userState } from 'src/state/state';
import { problemsConfigEn } from 'src/data/problems/problemsEn';
import { problemsConfigSk } from 'src/data/problems/problemsSk';
import { SendMessageModal } from 'components/modals/SendMessageModal';
import { ThreadType } from 'src/@types/enums';
import { SendMessageButton } from 'components/SendMessageButton';
import { problemsImages } from 'src/data/problems/problemsImages';

type Props = ProblemsStackScreenProps<Routes.ProblemDetail>;

export const ProblemDetailScreen = ({ route, navigation }: Props) => {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);

  const { id } = route.params;
  const musclesConfig = getMusclesConfig();
  const problemsConfig = getProblemsConfig();
  const problem = problemsConfig[id];

  const skName = problemsConfigSk[id].name;
  const enName = problemsConfigEn[id].name;

  const [showExercises, setShowExercises] = useState(false);
  const [showMuscles, setShowMuscles] = useState(false);
  const [allExercises] = useRecoilState(exercisesState);

  const { isOpen, onClose, onOpen } = useDisclose(false);

  const exercises = useMemo(
    () =>
      allExercises.filter((exercise) =>
        problem.joints.some((joint) => exercise.keywords.includes(joint))
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allExercises?.length, problem.joints]
  );

  useEffect(() => {
    navigation.setOptions({ title: problem.name });
  }, [problem.name, navigation]);

  const muscles = useMemo(
    () =>
      Object.values(musclesConfig).filter((muscle) =>
        problem?.joints.some((keyword) => muscle.joints.includes(keyword))
      ),
    [musclesConfig, problem?.joints]
  );

  const onShowExercises = () => {
    setShowExercises(true);
  };

  const buttons = (
    <>
      <FloatingButton
        bottom={32}
        onPress={() => onShowExercises()}
        Icon={GymIcon}
      />
      <FloatingButton
        bottom={16}
        onPress={() => setShowMuscles(true)}
        Icon={BodyIcon}
      />
    </>
  );

  const hideMuscles = useCallback(() => {
    setShowMuscles(false);
  }, []);

  const hideExercises = useCallback(() => {
    setShowExercises(false);
  }, []);

  return (
    <Screen floatingComponent={buttons} title={problem.name}>
      <Column alignItems="center" space={4}>
        <Image
          width="full"
          h={180}
          resizeMode="contain"
          source={problemsImages[problem.id]}
          alt="problem"
        />
        {user && (
          <Row
            space={2}
            alignItems="center"
            w="full"
            justifyContent={'space-between'}
          >
            <FavoriteProblemButton problemId={problem.id} />
            <SendMessageButton onPress={onOpen} />
          </Row>
        )}
        <HeadingSecondary textAlign="center">
          {t('problems.description')}
        </HeadingSecondary>
        <Text textAlign="center">{problem.description}</Text>
        {problem.causes.map((text, index) => (
          <Text key={text + index}>
            {<Text>{index + 1}. </Text>}
            {text}
          </Text>
        ))}
        <HeadingSecondary textAlign="center">
          {t('problems.footer')}
        </HeadingSecondary>
        <Text textAlign="center">{problem.footer}</Text>
        <SendMessageModal
          isOpen={isOpen}
          onClose={onClose}
          name={{ sk: skName, en: enName }}
          threadId={problem.id}
          type={ThreadType.Problem}
        />
        <MusclesBottomModal
          muscles={muscles}
          isOpen={showMuscles}
          onClose={hideMuscles}
        />
        <ExercisesBottomModal
          exercises={exercises}
          isOpen={showExercises}
          onClose={hideExercises}
        />
      </Column>
    </Screen>
  );
};
