import {
  HeadingSecondary,
  HeadingTertiary,
} from 'components/typography/heading';
import { Text } from 'native-base';
import { PropsWithChildren } from 'react';

export const TermsOfUseSk = () => {
  return (
    <>
      <HeadingSecondary my={2}>Všeobecná poznámka:</HeadingSecondary>
      <Text my={4}>
        Nasledujúce Všeobecné obchodné podmienky pre používanie aplikácie
        RecoveryTom sú určené taktiež pre našich medzinárodných zákazníkov, pre
        ktorých neposkytujeme lokalizované zmluvné podmienky.
      </Text>
      <HeadingSecondary my={2}>1. Úvod</HeadingSecondary>
      <Text my={4}>
        Túto aplikáciu prevádzkuje RecoveryTom. Výrazy „my“, „nás“ a „náš“ sa
        vzťahujú na RecoveryTom. Používanie našej webovej stránky podlieha
        nasledujúcim podmienkam používania, ktoré sa z času na čas menia (ďalej
        len „Podmienky“). Podmienky si musíte prečítať spolu s akýmikoľvek
        podmienkami a vylúčeniami zodpovednosti uvedenými na našej webstránke.
        Podmienky sa vzťahujú na všetkých používateľov našej aplikácie vrátane
        používateľov, ktorí sú prehliadačmi, zákazníkmi, obchodníkmi, predajcami
        a/alebo prispievateľmi obsahu. Ak používate aplikáciu, súhlasíte s
        našimi Podmienkami a našimi Zásadami ochrany osobných údajov. Ak
        nesúhlasíte s Podmienkami alebo našimi Zásadami ochrany osobných údajov,
        nemáte oprávnenie na prístup k našej aplikácii.
      </Text>
      <HeadingTertiary my={4}>1.1 Podmienky účasti</HeadingTertiary>
      <Text my={4}>
        Podmienkou založenia užívateľského účtu a používania RecoveryTom je, že
        máte minimálne 18 rokov a máte plnú spôsobilosť na právne úkony.
      </Text>
      <HeadingSecondary my={2}>2. Ceny RecoveryTom</HeadingSecondary>
      <HeadingTertiary my={4}>
        2.1 Bezplatné služby alebo za poplatok
      </HeadingTertiary>
      <Text my={4}>
        Rozsah služieb zahrnutých v aplikácii RecoveryTom, ktoré môžete
        používať, závisí od toho, či aplikáciu RecoveryTom používate bezplatne
        alebo za poplatok. Ak ju používate bezplatne, máte prístup len k určitým
        základným funkciám a informáciám. V rámci predplatného príslušnej
        aplikácie RecoveryTom máte k dispozícii širšiu škálu funkcií a
        informácií.
      </Text>
      <HeadingSecondary my={2}>3. Vaše zdravie</HeadingSecondary>
      <HeadingTertiary my={4}>
        3.1 Podmienky týkajúce sa Vášho zdravia
      </HeadingTertiary>
      <Text my={4}>
        Používanie aplikácie RecoveryTom je na vaše vlastné riziko. V každom
        prípade je podmienkou, že musíte byť v dobrom celkovom zdravotnom stave.
        Ak viete o akýchkoľvek už existujúcich zdravotných problémoch,
        odporúčame vám, aby ste pred spustením aplikácie RecoveryTom vyhľadali
        lekársku pomoc. To platí najmä vtedy, ak máte jednu alebo viacero z
        nasledujúcich zdravotných ťažkostí/stavov:
      </Text>
      <Li>srdcovo-cievne ochorenie</Li>
      <Li>ochorenie pľúc alebo dýchacích ciest (vrátane astmy),</Li>
      <Li>problémy s chrbticou a/alebo kĺbmi,</Li>
      <Li>nervovosvalové ochorenie</Li>
      <Li>chirurgické zákroky,</Li>
      <Li>akékoľvek iné zdravotné problémy</Li>
      <Text mt={4}>
        Platia nasledujúce Všeobecné pravidlá: Počúvajte, čo vám vaše telo
        hovorí. Pred prvým použitím aplikácie RecoveryTom alebo počas jej
        používania, ak máte akékoľvek pochybnosti o svojom zdraví (napr. pretože
        pociťujete značné bolesti, celkovú nevoľnosť, dýchavičnosť, nevoľnosť
        alebo závraty), poraďte sa so svojím lekárom skôr, ako začnete s
        aplikáciou RecoveryTom.
      </Text>
      <HeadingTertiary my={4}>
        3.2 Nenahradzovanie lekárskej pomoci
      </HeadingTertiary>
      <Text my={4}>
        Služby a informácie ponúkané aplikáciou RecoveryTom App nepredstavujú
        lekársku pomoc ani radu lekára. Nenahrádzajú ani lekárske vyšetrenie
        alebo ošetrenie u lekára.
      </Text>
      <HeadingSecondary my={2}>4. Používateľský účet</HeadingSecondary>
      <HeadingTertiary my={4}>4.1 Proces registrácie</HeadingTertiary>
      <Text my={4}>
        Používaním aplikácie RecoveryTom súhlasíte s Podmienkami používania a so
        Zásadami ochrany osobných údajov. Ak chcete používať rozšírenú
        funkcionalitu aplikácie, musíte si otvoriť používateľský účet. Môžete sa
        prihlásiť pomocou svojho účtu Google na všetkých platformách alebo
        pomocou Apple ID pokiaľ používate iOS. V tomto prípade je proces
        prihlásenia dokončený, keď zadáte informácie o účte a kliknete na
        „Potvrdiť“.
      </Text>
      <HeadingSecondary my={2}>5. Uzavretie zmluvy</HeadingSecondary>
      <Text my={4}>
        Spôsob vytvorenia príslušnej zmluvy závisí od spôsobu, akým sa prvýkrát
        zaregistrujete do aplikácie RecoveryTom, a od toho, či sa prihlásite k
        ďalšej spoplatnenej službe.
      </Text>
      <HeadingTertiary my={4}>
        5.1 Online registrácia na webovej stránke
      </HeadingTertiary>
      <Text my={4}>
        Pri registrácii prostredníctvom našej webovej stránky nadobúda
        užívateľská zmluva medzi nami platnosť po dokončení procesu registrácie.
      </Text>
      <HeadingTertiary my={4}>
        5.2 Registrácia prostredníctvom mobilných aplikácií
      </HeadingTertiary>
      <Text my={4}>
        Pri registrácii cez mobilné aplikácie závisí vznik užívateľskej zmluvy
        od pravidiel dodávateľa obchodu s aplikáciami (napríklad Apple, Google,
        atď.). Zmluva spravidla vzniká kliknutím na pole „Inštalovať“ v
        príslušnom obchode s aplikáciami a v prípade potreby zadaním príslušného
        hesla. Upozorňujeme, že na používanie aplikácie RecoveryTom je stále
        potrebné otvoriť si u nás bezplatný používateľský účet.
      </Text>
      <HeadingTertiary my={4}>
        5.3 Uzavretie zmluvy na predplatné
      </HeadingTertiary>
      <Text my={4}>
        V rámci predplatného môžete získať ďalšie funkcie. Ak získate dodatočné
        služby prostredníctvom našej webovej stránky RecoveryTom, zmluva
        nadobudne platnosť, keď kliknete na tlačidlo „Prihlásiť sa na odber“
        alebo ekvivalentné tlačidlo a úspešne zadáte svoje platobné údaje. Ak si
        doplnkovú službu zakúpite cez svoju mobilnú aplikáciu, zmluva vzniká
        kliknutím na pole „Kúpiť teraz“.
      </Text>
      <HeadingSecondary my={2}>6. Trvanie a rozsah</HeadingSecondary>
      <HeadingTertiary my={4}>6.1 Užívateľská zmluva</HeadingTertiary>
      <Text my={4}>
        Užívateľská zmluva uzatvorená medzi vami a nami po registrácii účtu je
        platná na dobu neurčitú. Na používanie určitých funkcií RecoveryTom je
        potrebné zakúpiť si predplatné. Hneď ako si otvoríte užívateľský účet
        podľa bodu 5, ste užívateľom za týchto Všeobecných obchodných podmienok
        a môžete si zakúpiť predplatné. Ako používateľ RecoveryTom nesiete
        zodpovednosť za všetky aktivity všetkých používateľov spojených s vaším
        používateľským účtom.
      </Text>
      <HeadingSecondary my={2}>7. Užívateľská zmluva</HeadingSecondary>
      <Text my={4}>
        Poplatok sa vyberá za príslušné minimálne obdobie pri uzatvorení zmluvy
        o kúpe predplatného. Ak sa predplatné obnoví automaticky, poplatok sa
        vyberie vopred na začiatku príslušného obdobia obnovenia.
      </Text>
      <HeadingSecondary my={2}>8. Spôsoby platby</HeadingSecondary>
      <Text my={4}>
        Ak si zakúpite predplatné RecoveryTom prostredníctvom nákupov v
        aplikácii, poplatky vám bude účtovať príslušný dodávateľ obchodu s
        aplikáciami. Ak chcete zistiť, aké spôsoby platby sú k dispozícii,
        obráťte sa na tohto dodávateľa. Ak platíte za predplatné RecoveryTom
        prostredníctvom našej webovej stránky RecoveryTom, ako poskytovateľa
        platieb používame Stripe. Ak chcete zistiť, aké spôsoby platby sú k
        dispozícii, obráťte sa na tohto dodávateľa. Ak nám vzniknú náklady
        a/alebo výdavky z dôvodu odmietnutia platby a je to Vaša chyba
        (napríklad preto, že na účte nie je dostatok prostriedkov alebo sa už
        vyčerpal limit kreditnej karty), sme oprávnení vyúčtovať vám skutočné
        náklady a/alebo vzniknuté výdavky. Ak vám pri použití spôsobu platby
        vzniknú poplatky, budeme vás o tom informovať počas procesu nákupu.
        Poskytovatelia platieb vám môžu podľa vlastného uváženia ponúknuť
        možnosť platby v splátkach za poplatok. Tento poplatok bude účtovaný
        priamo príslušným poskytovateľom platieb, a preto je splatný priamo
        jemu. Platia obchodné podmienky príslušného poskytovateľa platieb.
        Upozorňujeme, že ak svoju objednávku zrušíte, tieto poplatky vám
        nebudeme môcť vrátiť. V prípade legitímneho dôvodu si pri každom nákupe
        vyhradzujeme právo zdržať sa ponúkania určitých spôsobov platby a určiť
        alternatívne spôsoby platby. Ak je vami vybraný platobný prostriedok
        zmrazený (napr. z dôvodu krádeže) alebo ak dôjde k inému narušeniu
        bezpečnosti (napr. neoprávnené zverejnenie používateľského mena a
        hesla), okamžite o tom informujte nás alebo svojho spracovateľa platieb.
      </Text>
      <HeadingSecondary my={2}>9. Zodpovednosť za vady</HeadingSecondary>
      <HeadingTertiary my={4}>9.1 Zákonné ustanovenia</HeadingTertiary>
      <Text my={4}>
        Na reklamácie z dôvodu chybných služieb sa vzťahujú zákonné ustanovenia.
        Vaše spotrebiteľské práva zostanú v každom prípade nedotknuté.
      </Text>
      <HeadingTertiary my={4}>9.2 Vylúčenie záruk</HeadingTertiary>
      <Text my={4}>
        Neposkytujeme žiadne vyhlásenia ani záruky, že používanie aplikácie
        RecoveryTom prinesie vami zamýšľaný výsledok. Nesľubujeme konkrétny
        úspech. Skutočný výsledok tréningu bude tiež závisieť od faktorov, ktoré
        sa nedajú ovplyvniť, ako sú napríklad fyzické dispozície a predpoklady.
        V dôsledku toho sa výsledky môžu medzi jednotlivcami výrazne líšiť, a to
        aj napriek rovnakému používaniu aplikácie RecoveryTom. Taktiež
        nezaručujeme, že dostupný obsah je vhodný pre všetkých používateľov,
        alebo že konkrétna funkcia bude dostupná alebo podporovaná počas
        konkrétneho obdobia, ani že naša aplikácia RecoveryTom bude dostupná bez
        prerušení alebo porúch. Pokiaľ bola dohodnutá zmluva s plateným
        používateľom, RecoveryTom poskytne dohodnutú funkčnosť. Vyhradzujeme si
        však právo upraviť, rozšíriť, ukončiť alebo odstrániť našu aplikáciu
        RecoveryTom, obsah a funkcie, či už úplne alebo čiastočne, a/alebo úplne
        alebo čiastočne prerušiť prevádzku našej aplikácie RecoveryTom.
      </Text>
      <HeadingSecondary my={2}>10. Zodpovednosť</HeadingSecondary>
      <HeadingTertiary my={4}>10.1 Všeobecne</HeadingTertiary>
      <Text my={4}>
        Ak máte k dispozícii príručky alebo pokyny v súvislosti s aplikáciou
        RecoveryTom, je nevyhnutné, aby ste sa nimi riadili. V opačnom prípade
        riskujete zranenie a vaše celkové zdravie. Pokiaľ používate vybavenie
        alebo školiace nástroje, je vašou zodpovednosťou zabezpečiť, aby tieto
        zariadenia a nástroje boli v dobrom prevádzkovom stave a správne
        nainštalované a/alebo nastavené. Musíte dodržiavať a rešpektovať naše
        upozornenia o zdravotnej bezpečnosti v odseku 3.
      </Text>
      <HeadingTertiary my={4}>
        10.2 Zodpovednosť za bezplatné služby
      </HeadingTertiary>
      <Text my={4}>
        Za bezplatné služby ručíme bez ohľadu na právny základ výlučne za škodu
        spôsobenú úmyselným konaním alebo hrubou nedbanlivosťou alebo absenciou
        zaručenej funkcie. Nie sme zodpovední za úmyselné pochybenie. V prípade
        hrubej nedbanlivosti alebo absencie zaručenej funkcie je naša
        zodpovednosť obmedzená na primeranú, predvídateľnú škodu. V opačnom
        prípade je naša zodpovednosť vylúčená.
      </Text>
      <HeadingTertiary my={4}>
        10.3 Zodpovednosť za služby poskytované za poplatok
      </HeadingTertiary>
      <Text my={4}>
        V prípade služieb poskytovaných za poplatok máme bez ohľadu na právny
        základ v zásade neobmedzenú zodpovednosť za škodu spôsobenú úmyselným
        konaním alebo hrubou nedbanlivosťou alebo absenciou zaručenej funkcie.
        Ak porušíme podstatnú zmluvnú povinnosť v dôsledku ľahkej nedbanlivosti,
        naša zodpovednosť je obmedzená na primeranú, predvídateľnú škodu.
        Podstatný zmluvný záväzok je akýkoľvek záväzok, ktorý je potrebný na
        splnenie účelu zmluvy a na splnenie ktorého sa vy ako spotrebiteľ môžete
        alebo by ste sa mali spoľahnúť. Naša zodpovednosť v prípade akéhokoľvek
        zranenia na živote, končatine alebo zdraví, ktoré je našou vinou,
        zostáva vyššie uvedenými obmedzeniami nedotknutá. V opačnom prípade je
        naša zodpovednosť vylúčená.
      </Text>
      <HeadingSecondary my={2}>
        11. Práva na používanie obsahu RecoveryTom
      </HeadingSecondary>
      <Text my={4}>
        V závislosti od toho, ktoré služby ste si zakúpili, obsah RecoveryTom je
        chránený autorským právom a my vlastníme príslušné práva. Udeľujeme vám
        odvolateľné a neprenosné právo na nekomerčné používanie tohto obsahu v
        súlade s týmito zmluvnými ustanoveniami. Len pre vylúčenie pochybností
        upozorňujeme na skutočnosť, že najmä šírenie nášho obsahu alebo jeho
        verejné sprístupnenie, napr. na iných webových stránkach ako RecoveryTom
        alebo v aplikáciach RecoveryTom, nie je povolené. To isté platí pre
        kopírovanie, predaj alebo distribúciu, vytváranie odvodených diel a
        akúkoľvek činnosť, ktorá by mohla narušiť alebo poškodiť naše aplikácie
        RecoveryTom. Právo na používanie zanikne, keď Váš prístup k príslušnej
        službe prestane byť povolený (napríklad po zrušení predplatného) alebo
        keď sa skončí Vaša používateľská zmluva.
      </Text>
      <HeadingSecondary my={2}>
        12. Zodpovednosť za obsah vytvorený používateľmi
      </HeadingSecondary>
      <HeadingTertiary my={4}>
        12.1 Zrieknutie sa zodpovednosti za obsah tretích strán
      </HeadingTertiary>
      <Text my={4}>
        Za obsah, ktorý uverejníte v rámci aplikácie RecoveryTom, nesiete
        výhradnú zodpovednosť. Za tento obsah nepreberáme žiadnu zodpovednosť a
        ani ho nemonitorujeme. Prostredníctvom svojho používateľského účtu máte
        zakázané inzerovať komerčné webové stránky alebo iné produkty.
      </Text>
      <HeadingTertiary my={4}>
        12.2 Dodržiavanie zákonných ustanovení
      </HeadingTertiary>
      <Text my={4}>
        Pri dodávaní vlastného obsahu ste povinní dodržiavať všetky platné
        zákony a ostatné právne predpisy Slovenskej republiky. Bez ohľadu na to,
        či ide o trestný čin, je vo všeobecnosti zakázané poskytovať obsah,
        ktorý má pornografický, sexuálny, násilný, rasistický, podnecujúci,
        diskriminačný, explicitný, urážlivý a/alebo hanlivý charakter. Okrem
        toho ste tiež povinní zdržať sa porušovania práv tretích strán. Týka sa
        to najmä osobnostných práv tretích osôb, ako aj práv duševného
        vlastníctva tretích osôb (ako sú napríklad autorské práva a práva k
        ochranným známkam). Predovšetkým musíte mať tiež potrebné práva na svoj
        profilový obrázok alebo akýkoľvek iný obrázok, ktorý uverejníte. Týmto
        sme oprávnení kedykoľvek a bez predchádzajúceho upozornenia vymazať
        alebo odstrániť obsah, ktorý je nezákonný alebo je v rozpore s vyššie
        uvedenými pokynmi. V prípade, že porušíte vyššie uvedené zásady, sme
        oprávnení vás upozorniť alebo dočasne zablokovať Váš používateľský účet
        alebo z dobrého dôvodu zrušiť používateľskú zmluvu v súlade s bodom
        13.3.
      </Text>
      <HeadingTertiary my={4}>12.3 Odškodnenie</HeadingTertiary>
      <Text my={4}>
        Ak porušíte zásady uvedené v odseku 12.2 a je to Vaša chyba (t. j.
        pretože ste konali nedbanlivo alebo úmyselne), ste povinný nás odškodniť
        za akýchkoľvek nárokov tretích strán vyplývajúcich z takéhoto porušenia.
        Toto odškodnenie zahŕňa aj náklady na vhodnú obhajobu. Ste povinný
        poskytnúť pomoc pri objasňovaní spornej situácie. Vyhradzujeme si právo
        uplatňovať nároky na náhradu škody a iné nároky.
      </Text>
      <HeadingSecondary my={2}>13. Ukončenie zmluvy</HeadingSecondary>
      <HeadingTertiary my={4}>13.1 Užívateľská zmluva</HeadingTertiary>
      <Text my={4}>
        Máte právo kedykoľvek bez udania dôvodu zrušiť svoj užívateľský účet,
        čím zároveň zaniká Vaša užívateľská zmluva. Ak to chcete urobiť, musíte
        vo svojom profile jednoducho vybrať potrebné nastavenia. Upozorňujeme,
        že po zrušení Vášho používateľského účtu vymažeme alebo môžeme odstrániť
        všetok obsah a vy už nebudete mať prístup k obsahu, ktorý ste si už
        zakúpili. Ak máte v čase vymazania svojho účtu stále aktuálne
        predplatné, ktorého platnosť ešte nevypršala, žiadna suma, ktorú ste už
        zaplatili, nebude vrátená, a to ani na pomernom základe. Sme oprávnení
        zrušiť užívateľskú zmluvu bez uvedenia dôvodov písomným oznámením
        minimálne dva (2) týždne vopred, nie však skôr ako na konci minimálneho
        zmluvného obdobia alebo na konci príslušného obdobia obnovenia Vášho
        predplatného.
      </Text>
      <HeadingTertiary my={4}>13.2 Predplatné</HeadingTertiary>
      <Text my={4}>
        Príslušné predplatné môžete kedykoľvek zrušiť bez udania dôvodu s
        účinnosťou od konca minimálneho obdobia alebo na konci príslušného
        predĺženia alebo fakturačného obdobia. Ak chcete zrušiť predplatné
        zakúpené na našej webovej stránke, postupujte podľa pokynov v našich
        Zásadách ochrany osobných údajov. Predplatné zakúpené prostredníctvom
        nákupu v aplikácii je potrebné zrušiť pomocou nastavení v príslušnom
        obchode s aplikáciami. Váš používateľský účet bude existovať aj po
        zrušení predplatného. Sme oprávnení zrušiť vaše predplatné s účinnosťou
        od konca minimálneho zmluvného obdobia alebo s účinnosťou od konca
        príslušného obdobia obnovenia, a to písomným oznámením minimálne jeden
        (2) týždne vopred.
      </Text>
      <HeadingTertiary my={4}>13.3 Zrušenie z dobrého dôvodu</HeadingTertiary>
      <Text my={4}>
        Právo na zrušenie z dobrého dôvodu zostáva nedotknuté v prípade
        ktorejkoľvek strany. Predovšetkým sme oprávnení s okamžitou platnosťou
        zrušiť vašu užívateľskú zmluvu alebo predplatné a zrušiť Váš užívateľský
        účet, ak závažným spôsobom alebo opakovane porušíte ustanovenia
        užívateľskej zmluvy a/alebo týchto Všeobecných obchodných podmienok,
        alebo ak meškáte so svojimi platobnými záväzkami.
      </Text>
      <HeadingSecondary my={2}>14. Osobné údaje</HeadingSecondary>
      <Text my={4}>
        Vaše osobné údaje spracovávame v súlade s našimi Zásadami ochrany
        osobných údajov, ktorých najnovšiu verziu nájdete na našej webstránke.
      </Text>
      <HeadingSecondary my={2}>
        15. Zmeny Všeobecných obchodných podmienok
      </HeadingSecondary>
      <Text my={4}>
        Týmto si vyhradzujeme právo upraviť a doplniť Všeobecné obchodné
        podmienky v budúcnosti, ak to vyžaduje právne, regulačné alebo technické
        prostredie a ak sú tieto zmeny odôvodnené a berú do úvahy Vaše záujmy. O
        zmenách Vás budeme informovať prostredníctvom našej webstránky/aplikácie
        najneskôr dva (2) týždne pred plánovaným nadobudnutím účinnosti novej
        verzie Všeobecných obchodných podmienok. Ak v tejto lehote nevznesiete
        námietky proti platnosti nových Všeobecných obchodných podmienok a
        budete pokračovať v používaní RecoveryTom, budú sa nové Všeobecné
        obchodné podmienky považovať za prijaté. V prípade, že budete namietať,
        výslovne si vyhradzujeme právo na zrušenie účtu.
      </Text>
      <HeadingSecondary my={2}>16. Záverečné ustanovenia</HeadingSecondary>
      <HeadingTertiary my={4}>16.1 Rozhodné právo</HeadingTertiary>
      <Text my={4}>
        Vzťah medzi zmluvnými stranami sa riadi výlučne slovenským právom s
        výnimkou Dohovoru OSN o zmluvách o medzinárodnej kúpe tovaru (CISG). V
        súvislosti s obchodnými transakciami so spotrebiteľmi v rámci Európskej
        únie sa môže uplatňovať aj právo miesta bydliska spotrebiteľa, ak takéto
        právo obsahuje ustanovenia spotrebiteľského práva, ktoré je povinné
        uplatňovať.
      </Text>
      <HeadingTertiary my={4}>16.2 Miesto súdnej príslušnosti</HeadingTertiary>
      <Text my={4}>
        Ak nemáte miesto všeobecnej jurisdikcie v Slovenskej republike alebo v
        inom členskom štáte EÚ, alebo ak ste po nadobudnutí účinnosti týchto
        Všeobecných obchodných podmienok presťahovali svoje trvalé bydlisko do
        krajiny mimo EÚ, alebo ak nie je známe vaše trvalé bydlisko v čase
        podania reklamácie, potom bude výhradným miestom súdnej príslušnosti pre
        všetky spory vyplývajúce z tejto zmluvy naše obchodné miesto.
      </Text>
      <HeadingTertiary my={4}>16.3 Jazyk zmluvy</HeadingTertiary>
      <Text my={4}>Jazykom zmluvy je slovenčina.</Text>
      <HeadingTertiary my={4}>16.4 Klauzula o oddeliteľnosti</HeadingTertiary>
      <Text my={4}>
        V prípade, že niektoré jednotlivé ustanovenia týchto Všeobecných
        obchodných podmienok budú alebo sa stanú úplne alebo čiastočne neplatné,
        nebude to mať vplyv na platnosť ostatných ustanovení.
      </Text>
      <HeadingTertiary my={4}>17. Informácie o dodávateľovi</HeadingTertiary>
      <Text my={1}>Meno: Tomáš Janečka</Text>
      <Text my={1}>Email: recoverytom.issues@gmail.com</Text>
      <Text my={1}>Sídlo: Bánovce nad Bebravou, Malé Chlievany, Slovakia</Text>
    </>
  );
};

const Li = (props: PropsWithChildren) => {
  return (
    <Text>
      {`\u2022`} {props.children}
    </Text>
  );
};
