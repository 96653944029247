import { IImageProps, Image } from 'native-base';
import React from 'react';

type Props = IImageProps & {
  size?: number;
};

export const Logo = ({ size, ...props }: Props) => {
  return (
    <Image
      source={require('../../assets/logo.png')}
      alt="logo"
      w={size}
      h={size}
      {...props}
    />
  );
};
