import { ILocalizedArray, ILocalizedValue } from 'src/@types/types';
import { isSlovak } from './language';

type Lang = 'en' | 'sk';

export const getLocalizedValue = (
  value?: ILocalizedValue,
  language?: string
) => {
  if (!value) {
    return '';
  }
  const lang: Lang = (language as Lang) || (isSlovak() ? 'sk' : 'en');
  return value[lang];
};

export const getLocalizedArrayValue = (value?: ILocalizedArray) => {
  if (!value) {
    return [];
  }
  const lang: Lang = isSlovak() ? 'sk' : 'en';
  return value[lang];
};
