import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column, Row, Text, useDisclose, View } from 'native-base';
import { useTranslation } from 'react-i18next';
import { ExercisesStackScreenProps } from 'src/navigation/types/exercisesStack';
import { Routes } from 'src/navigation/routes';
import YoutubePlayer from 'react-native-youtube-iframe';
import { getLocalizedValue } from 'src/utils/utils';
import { IExercise } from 'src/@types/api';
import { FavoriteButton } from 'components/buttons/FavoriteButton';
import { Colors } from 'src/theme/colors';
import { BodyIcon, ProblemsIcon } from 'components/icons/icons';
import { SendMessageModal } from 'components/modals/SendMessageModal';
import { Screen } from 'components/layout/Screen';
import { useAuthUser } from 'src/state/state';
import { useExercises } from 'src/hooks/useExercises';
import { FloatingButton } from 'components/buttons/FloatingButton';
import { MusclesBottomModal } from 'components/modals/MusclesBottomModal';
import { getMusclesConfig } from 'src/data/muscles/musclesConfig';
import { getProblemsConfig } from 'src/data/problems/problemsConfig';
import { ProblemsBottomModal } from 'components/modals/ProblemsBottomModal';
import { ThreadType } from 'src/@types/enums';
import firebase from 'firebase/compat';
import { SendMessageButton } from 'components/SendMessageButton';
import { MasteredExerciseButton } from 'components/buttons/MasteredExerciseButton';
import FirebaseError = firebase.FirebaseError;
import { ExerciseDescription } from 'components/exercises/ExerciseDescription';

type Props = ExercisesStackScreenProps<Routes.ExerciseDetail>;

export const ExerciseDetailScreen = ({ route, navigation }: Props) => {
  const { id } = route.params;
  const { t } = useTranslation();
  const user = useAuthUser();
  const { isOpen, onClose, onOpen } = useDisclose(false);
  const { getExerciseById } = useExercises();
  const [exercise, setExercise] = useState<IExercise | undefined | null>(null);
  const [showProblems, setShowProblems] = useState(false);
  const [showMuscles, setShowMuscles] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [videoWrapperWidth, setVideoWrapperWidth] = useState(0);
  const isMastered = user?.masteredExercises?.find((ex) => ex === exercise?.id);
  const musclesConfig = getMusclesConfig();
  const problemsConfig = getProblemsConfig();

  useEffect(() => {
    getExerciseById(id)
      .then(setExercise)
      .catch((e: FirebaseError) => {
        if (
          e.code === 'permission-denied' ||
          e.code === 'firestore/permission-denied'
        ) {
          setError(t('exercise.premiumError'));
        }
      })
      .finally(() => setLoading(false));
  }, [getExerciseById, id, t]);

  const muscles = useMemo(
    () =>
      exercise?.keywords.length
        ? Object.values(musclesConfig).filter((muscle) =>
            exercise?.keywords.some((keyword) =>
              muscle.joints.includes(keyword)
            )
          )
        : [],
    [exercise?.keywords, musclesConfig]
  );
  const problems = useMemo(
    () =>
      Object.values(problemsConfig).filter((problem) =>
        exercise?.keywords.some((keyword) => problem.joints.includes(keyword))
      ),
    [exercise?.keywords, problemsConfig]
  );

  useEffect(() => {
    exercise?.name &&
      navigation.setOptions({
        title: getLocalizedValue(exercise.name),
      });
  }, [exercise?.name, navigation]);

  const onPressFavorite = (isFav: boolean) => {
    setExercise((exercise) => {
      if (exercise)
        return {
          ...exercise,
          likes: isFav ? exercise.likes - 1 : exercise.likes + 1,
        };
    });
  };

  const hideProblems = useCallback(() => {
    setShowProblems(false);
  }, []);

  const hideMuscles = useCallback(() => {
    setShowMuscles(false);
  }, []);

  const buttons = exercise ? (
    <>
      <FloatingButton
        bottom={32}
        onPress={() => setShowMuscles(true)}
        Icon={BodyIcon}
      />
      <FloatingButton
        bottom={16}
        onPress={() => setShowProblems(true)}
        Icon={ProblemsIcon}
      />
    </>
  ) : null;

  return (
    <Screen
      loading={loading}
      floatingComponent={buttons}
      title={getLocalizedValue(exercise?.name)}
    >
      {!!error && (
        <Text textAlign="center" m="auto">
          {error}
        </Text>
      )}
      {exercise && (
        <Column
          flex={1}
          space={4}
          w="full"
          onLayout={(e) => setVideoWrapperWidth(e.nativeEvent.layout.width)}
        >
          <View
            h={videoWrapperWidth / 1.7}
            _android={{
              h: 200,
            }}
            onStartShouldSetResponder={() => true}
            onTouchEnd={(e) => e.stopPropagation()}
          >
            <YoutubePlayer
              // @ts-ignore
              width="100%"
              // @ts-ignore
              height="100%"
              videoId={getLocalizedValue(exercise?.youtubeId)}
            />
          </View>
          <Row alignItems="center" w="full" space={4}>
            {user && (
              <>
                <Row alignItems="center">
                  <FavoriteButton
                    exercise={exercise}
                    onPress={onPressFavorite}
                  />
                  <Text color={Colors.primary}>
                    {Math.max(0, exercise.likes)}
                  </Text>
                </Row>
                <Row alignItems="center" ml="5px">
                  <MasteredExerciseButton exerciseId={exercise.id} />
                  {isMastered ? (
                    <Text>{t('filter.mastered')}</Text>
                  ) : (
                    <Text>{t('filter.not-mastered')}</Text>
                  )}
                </Row>
                <Row ml="auto">
                  <SendMessageButton onPress={onOpen} />
                </Row>
              </>
            )}
          </Row>
          <ExerciseDescription exercise={exercise} />
          <MusclesBottomModal
            muscles={muscles}
            isOpen={showMuscles}
            onClose={hideMuscles}
          />
          <ProblemsBottomModal
            problems={problems}
            isOpen={showProblems}
            onClose={hideProblems}
          />
          {user && (
            <SendMessageModal
              isOpen={isOpen}
              onClose={onClose}
              name={exercise.name}
              threadId={exercise.id}
              type={ThreadType.Exercise}
            />
          )}
        </Column>
      )}
    </Screen>
  );
};
