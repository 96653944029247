import { MusclePreview } from 'components/MusclePreview';
import React, { useCallback } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { IMuscle } from 'src/@types/api';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components/modals/Modal';
import { FlatList, ListRenderItemInfo } from 'react-native';
import { isWeb } from '../../utils/constants';

type Props = {
  muscles: IMuscle[];
  isOpen: boolean;
  onClose: () => void;
};

export const MusclesBottomModal = ({ isOpen, onClose, muscles }: Props) => {
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation();

  const renderMuscle = useCallback(
    ({ item, index }: ListRenderItemInfo<IMuscle>) => {
      return (
        <MusclePreview
          muscle={item}
          flex={1 / 3}
          m={1}
          onPress={onClose}
          withOverlay={index >= 1}
          disabled={isWeb}
        />
      );
    },
    [onClose]
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('routes.muscles')} px={0}>
      <FlatList
        data={muscles}
        contentContainerStyle={{
          paddingHorizontal: 12,
          paddingBottom: bottom,
        }}
        renderItem={renderMuscle}
        numColumns={3}
        initialNumToRender={3}
        windowSize={3}
      />
    </Modal>
  );
};
