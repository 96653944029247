import { useEffect, useState } from 'react';
import {
  getUserRole,
  onAuth,
  onRevokeAppleCredentials,
} from '../firebase/auth';
import {
  createUser,
  fetchUser,
  onFetchUser,
  onFetchUserRelationships,
} from 'src/firebase/user';
import { useRecoilState } from 'recoil';
import {
  userPremiumState,
  userRelationshipsState,
  userState,
} from 'src/state/state';
import { registerFCMToken, requestPermissions } from 'src/utils/notifications';
import { isIOS, isWeb } from 'src/utils/constants';
import { logError, setUserIdForAnalytics } from 'src/firebase/analytics';
import { configurePurchases, purchaseLogout } from 'src/utils/purchases';
import { ERRORS } from '../firebase/constants';

export const useAuth = () => {
  const [userId, setUserId] = useState<string>();
  const setUser = useRecoilState(userState)[1];
  const setUserRelationships = useRecoilState(userRelationshipsState)[1];
  const setPremium = useRecoilState(userPremiumState)[1];

  useEffect(() => {
    if (userId) {
      return onFetchUser(userId, setUser);
    }
  }, [setUser, userId]);

  useEffect(() => {
    if (userId) {
      return onFetchUserRelationships(userId, setUserRelationships);
    }
  }, [setUserRelationships, userId]);

  useEffect(() => {
    return onAuth(async (user) => {
      try {
        if (user) {
          setUserIdForAnalytics(user.uid);
          const userFs = await fetchUser(user.uid);
          if (!userFs || !userFs.uid) {
            await createUser(
              user.uid,
              user.displayName || '',
              user.email || '',
              user.photoURL || ''
            );
          }
          setUserId(user.uid);
          await configurePurchases(user.uid);

          const premium = await getUserRole();
          setPremium(premium);

          if (!isWeb) {
            await requestPermissions();
            await registerFCMToken(user.uid, userFs?.fcmTokens || []);
          }
        } else {
          setUser(undefined);
          setUserId(undefined);
          setUserIdForAnalytics(undefined);
          await purchaseLogout();
          setPremium(undefined);
        }
      } catch (e) {
        logError(e as Error, ERRORS.CREATE_ACCOUNT);
      }
    });
  }, [setPremium, setUser, userId]);

  useEffect(() => {
    if (isIOS) {
      return onRevokeAppleCredentials();
    }
  }, []);
};
