import { DarkRow } from 'components/layout/views';
import { Center, Pressable, Spinner, Text } from 'native-base';
import React from 'react';
import { ButtonItem } from 'components/profile/types';

type Props = {
  item: ButtonItem;
};

export const ProfileButton = ({ item }: Props) => {
  return (
    <Pressable
      key={item.title}
      alignItems="center"
      w="full"
      onPress={item.onPress}
      disabled={item.loading}
    >
      <DarkRow space={2} px={4} py={4} w="full" borderRadius={10}>
        {item.loading ? (
          <Spinner size="sm" />
        ) : (
          <>
            <Center>{item.icon}</Center>
            <Text color={item.color} ml={1.5}>
              {item.title}
            </Text>
          </>
        )}
      </DarkRow>
    </Pressable>
  );
};
