import { IconButton } from 'native-base';
import { FavoriteIcon, FavoriteOutlineIcon } from 'components/icons/icons';
import { useRef } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from 'src/state/state';
import { addFavoriteProblem, removeFavoriteProblem } from 'src/firebase/user';

type Props = {
  problemId: string;
  size?: number | string;
  onPress?: (isFav: boolean) => void;
};

export const FavoriteProblemButton = ({ problemId, size = 5 }: Props) => {
  const [user, setUser] = useRecoilState(userState);

  const isPressed = useRef(false);
  const isFavorite = user?.favoriteProblems?.includes(problemId);

  const onPressHandler = () => {
    if (isPressed.current || !user) return;
    isPressed.current = true;
    if (isFavorite) {
      setUser({
        ...user,
        favoriteProblems:
          user.favoriteProblems?.filter((problem) => problem !== problemId) ||
          [],
      });
      removeFavoriteProblem(user.uid, problemId);
    } else {
      setUser({
        ...user,
        favoriteProblems: user.favoriteProblems
          ? [...user.favoriteProblems, problemId]
          : [problemId],
      });
      addFavoriteProblem(user.uid, problemId);
    }

    setTimeout(() => {
      isPressed.current = false;
    }, 500);
  };

  return (
    <IconButton
      colorScheme="primary"
      icon={
        isFavorite ? (
          <FavoriteIcon size={size} />
        ) : (
          <FavoriteOutlineIcon size={size} />
        )
      }
      disabled={!user}
      onPress={onPressHandler}
    />
  );
};
