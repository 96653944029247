import { useRecoilState } from 'recoil';
import { conversationsState, useAuthUser } from 'src/state/state';
import { IConversation } from 'src/@types/api';
import { useCallback, useMemo, useRef } from 'react';
import {
  fetchConversationById,
  onFetchConversations,
} from 'src/firebase/conversations';
import { CONVERSATIONS_LIMIT } from 'src/firebase/constants';

export const useConversations = () => {
  const [conversationsData, setConversationsState] =
    useRecoilState(conversationsState);
  const user = useAuthUser();
  const page = useRef(0);

  const onFetch = useCallback(
    (conversations: IConversation[]) => {
      const hasAll = conversations.length < CONVERSATIONS_LIMIT * page.current;
      setConversationsState({
        conversations,
        loading: false,
        hasAllConversations: hasAll,
      });
    },
    [setConversationsState]
  );

  const fetchConversations = useCallback(() => {
    if (user) {
      setConversationsState((state) => ({
        ...state,
        loading: true,
      }));

      page.current += 1;
      return onFetchConversations(
        user,
        onFetch,
        page.current * CONVERSATIONS_LIMIT
      );
    }
  }, [onFetch, setConversationsState, user]);

  const conversationsMap = useMemo(() => {
    return conversationsData.conversations.reduce<
      Record<string, IConversation>
    >((acc, conversation) => {
      acc[conversation.id] = conversation;
      return acc;
    }, {});
  }, [conversationsData]);

  const getConversationById = useCallback(
    async (id: string) => {
      if (conversationsMap[id]) {
        return conversationsMap[id];
      } else {
        const conversation = await fetchConversationById(id);
        if (conversation) {
          conversationsMap[id] = conversation;
        }
        return conversation;
      }
    },
    [conversationsMap]
  );
  return {
    getConversationById,
    fetchConversations,
    ...conversationsData,
  };
};
