import {
  Column,
  Divider,
  KeyboardAvoidingView,
  useBreakpointValue,
} from 'native-base';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuthUser } from 'src/state/state';
import { IConversation, IConversationThread } from 'src/@types/api';
import { onFetchConversationThreads } from 'src/firebase/conversations';
import { isWeb } from 'src/utils/constants';
import {
  ActivityIndicator,
  FlatList,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native';
import { Thread } from 'components/chat/Thread';
import { useConversations } from 'src/hooks/useConversations';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { AppWebStackParamList } from 'src/navigation/types/appWebStack';
import { FlatListScreen } from 'components/layout/FlatListScreen';
import { ChatHeader } from 'components/chat/ChatHeader';
import { THREADS_LIMIT } from 'src/firebase/constants';
import { ScrollButton } from 'components/chat/ScrollButton';
import { useUnreadMessages } from 'src/hooks/useUnreadMessages';

type Props = {
  conversationId: string;
  getLink: (
    conversationId: string,
    threadId: string
  ) => To<AppWebStackParamList>;
};
const SCROLL_THRESHOLD = 50;

export const Threads = ({ conversationId, getLink }: Props) => {
  const user = useAuthUser();
  const isSmall = useBreakpointValue([true, false]);
  const [threads, setThreads] = useState<IConversationThread[]>([]);
  const { getConversationById } = useConversations();
  const [conversation, setConversation] = useState<IConversation>();
  const [loading, setLoading] = useState(false);
  const [hasAllThreads, setHasAllThreads] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { isUnread } = useUnreadMessages();

  const scrollRef = useRef<FlatList<IConversationThread>>(null);
  const page = useRef(0);

  const onFetch = useCallback(
    (threads: IConversationThread[]) => {
      setThreads(threads);
      if (threads.length < THREADS_LIMIT * page.current) {
        setHasAllThreads(true);
      }
      setLoading(false);
    },
    [setThreads]
  );

  useEffect(() => {
    getConversationById(conversationId).then(setConversation);
  }, [conversationId, getConversationById]);

  const fetchThreads = useCallback(() => {
    if (user) {
      page.current += 1;
      return onFetchConversationThreads(
        conversationId,
        onFetch,
        page.current * THREADS_LIMIT
      );
    }
  }, [conversationId, onFetch, user]);

  useEffect(() => {
    fetchThreads();
  }, [fetchThreads]);

  const fetchMore = async () => {
    if (!loading) {
      setLoading(true);
      fetchThreads();
    }
  };

  const renderThread = useCallback(
    ({ item }: ListRenderItemInfo<IConversationThread>) => {
      return conversation ? (
        <Thread
          conversation={conversation}
          thread={item}
          getLink={getLink}
          isUnread={isUnread(conversation.id, item.id)}
        />
      ) : null;
    },
    [conversation, getLink, isUnread]
  );

  const onScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (e.nativeEvent.contentOffset.y > SCROLL_THRESHOLD && !showScrollButton) {
      setShowScrollButton(true);
    } else if (
      e.nativeEvent.contentOffset.y < SCROLL_THRESHOLD &&
      showScrollButton
    ) {
      setShowScrollButton(false);
    }
  };

  if (!user || !conversation) {
    return null;
  }

  const flatList = (
    <>
      <FlatListScreen
        data={threads}
        renderItem={renderThread}
        inverted
        onEndReached={loading || hasAllThreads ? undefined : fetchMore}
        onEndReachedThreshold={0.1}
        ListFooterComponent={loading ? <ActivityIndicator /> : null}
        onScroll={onScroll}
        scrollRef={scrollRef}
      />
      {showScrollButton && <ScrollButton scrollRef={scrollRef} bottom={10} />}
    </>
  );

  if (isWeb) {
    return (
      <>
        {isSmall && (
          <>
            <ChatHeader p={4} conversation={conversation} />
            <Divider />
          </>
        )}
        {flatList}
      </>
    );
  }
  return (
    <KeyboardAvoidingView flexGrow={1} behavior="position">
      <Column h="full">{flatList}</Column>
    </KeyboardAvoidingView>
  );
};
