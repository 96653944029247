/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { Routes } from 'src/navigation/routes';
import { AppWebStackParamList } from 'src/navigation/types/appWebStack';

export const webUrls = {
  [Routes.Dashboard]: '/home',
  [Routes.Favorites]: '/favorites',
  [Routes.FavoriteMuscles]: '/favorite-muscles',
  [Routes.Exercises]: '/exercises',
  [Routes.ExerciseDetail]: '/exercises/:id',
  [Routes.Muscles]: '/muscles',
  [Routes.MuscleDetail]: '/muscles/:id',
  [Routes.Conversations]: '/conversations',
  [Routes.Chat]: '/chat',
  [Routes.ConversationThreads]: '/conversations/:conversationId',
  [Routes.Messages]: '/conversations/:conversationId/:exerciseId',
  [Routes.Profile]: '/profile',
  [Routes.Friends]: '/friends',
  [Routes.Problems]: '/problems',
  [Routes.ProblemDetail]: '/problems/:id',
  [Routes.PrivacyPolicy]: '/policy',
  [Routes.TermsOfUse]: '/terms',
  [Routes.Subscription]: '/subscription',
  [Routes.Contact]: '/contact',
  [Routes.NotFound]: '*',
};

const linking: LinkingOptions<AppWebStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: webUrls,
  },
};

export default linking;
