export const masteredFilterOptions = ['mastered', 'not-mastered'];

export const mainFilterOptions = [
  'start',
  'stretch',
  'strength',
  'test',
  'wall',
  'ground',
  'stand',
  'roller',
  'chair',
  'stick',
  'band',
  'coordination',
  'challenge',
  'bars',
  'yogaBlocks',
  'flow',
];

export const exercisesJointFilterOptions = [
  'spineBot',
  'spineMid',
  'neck',
  'chest',
  'shoulder',
  'scapula',
  'elbow',
  'hand',
  'pelvis',
  'hip',
  'knee',
  'ankle',
  'foot',
  'thumb',
  'fingers4Hand',
];

export const musclesJointFilterOptions = [
  'head',
  'hyoidBone',
  'clavicle',
  'sternum',
  'scapula',
  'humerus',
  'radius',
  'ulna',
  'pelvis',
  'femur',
  'knee',
  'tibia',
  'fibula',
];

export const strengthFilterOptions = [
  'core',
  'chest',
  'back',
  'shoulder',
  'upperArm',
  'forearm',
  'thigh',
  // 'lowLeg',
  'pullUps',
  'chinUps',
  'pushUps',
  'handstand',
  'dips',
  'squats',
  'explosive',
  'fullBody',
];

export const originFilterOptions = ['origin', 'insertion'];

export const vertebraFilterOptions = [
  'spine',
  'c1',
  'c2',
  'c3',
  'c4',
  'c5',
  'c6',
  'c7',
  't1',
  't2',
  't3',
  't4',
  't5',
  't6',
  't7',
  't8',
  't9',
  't10',
  't11',
  't12',
  'l1',
  'l2',
  'l3',
  'l4',
  'l5',
  'sacrum',
  'coccyx',
];

export const ribsFilterOptions = [
  'ribs',
  'r1',
  'r2',
  'r3',
  'r4',
  'r5',
  'r6',
  'r7',
  'r8',
  'r9',
  'r10',
  'r11',
  'r12',
];

export const fingersHandFilterOptions = ['hand', 'h1', 'h2', 'h3', 'h4', 'h5'];

export const fingersFootFilterOptions = [
  'foot',
  'f1',
  'f2',
  'f3',
  'f4',
  'f5',
  'calcaneus',
];
