import React from 'react';
import { Column } from 'native-base';
import { useTranslation } from 'react-i18next';
import { DashboardOutlineIcon } from 'components/icons/icons';
import { HeadingSecondary } from 'components/typography/heading';
import { TextLight } from 'components/typography/text';
import { Colors } from 'src/theme/colors';

export const EmptyDashboard = () => {
  const { t } = useTranslation();

  return (
    <Column
      alignItems="center"
      flex={1}
      justifyContent="center"
      space={8}
      p={4}
    >
      <DashboardOutlineIcon color={Colors.primaryLight} size={120} />
      <HeadingSecondary>{t('dashboard.emptyTitle')}</HeadingSecondary>
      <TextLight textAlign="center">{t('dashboard.emptySubtitle')}</TextLight>
    </Column>
  );
};
