import { Pressable, Row, Text } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { Routes } from 'src/navigation/routes';
import { useAuthUser } from 'src/state/state';
import { IConversation, IConversationThread } from 'src/@types/api';
import { ConversationsStackScreenProps } from 'src/navigation/types/conversationStack';
import { ProfileImage } from 'components/ProfileImage';
import { getLocalizedValue } from 'src/utils/utils';
import { useConversations } from 'src/hooks/useConversations';
import { MessagesList } from 'components/chat/MessagesList';
import { fetchThreadById } from 'src/firebase/conversations';
import { ThreadType } from 'src/@types/enums';

type Props = ConversationsStackScreenProps<Routes.Messages>;

export const MessagesScreen = ({ navigation, route }: Props) => {
  const user = useAuthUser();
  const { conversationId, threadId } = route.params;

  const [conversation, setConversation] = useState<IConversation>();
  const [thread, setThread] = useState<IConversationThread>();

  const { getConversationById } = useConversations();

  useEffect(() => {
    getConversationById(conversationId).then(setConversation);
  }, [conversationId, getConversationById]);

  useEffect(() => {
    fetchThreadById(conversationId, threadId).then(setThread);
  }, [conversationId, threadId]);

  const onHeaderPress = useCallback(() => {
    if (!thread) {
      return;
    }
    if (thread.type === ThreadType.Exercise) {
      navigation.navigate(Routes.ExercisesStack, {
        screen: Routes.ExerciseDetail,
        params: { id: thread.id },
        initial: false,
      });
    } else if (thread.type === ThreadType.Muscle) {
      navigation.navigate(Routes.MusclesStack, {
        screen: Routes.MuscleDetail,
        params: { id: thread.id },
        initial: false,
      });
    } else {
      navigation.navigate(Routes.ProblemsStack, {
        screen: Routes.ProblemDetail,
        params: { id: thread.id },
        initial: false,
      });
    }
  }, [navigation, thread]);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <Pressable onPress={onHeaderPress}>
          <Row space={1} alignItems="center">
            {conversation?.participants
              .filter(({ uid }) => uid !== user?.uid)
              .map(({ photoUrl }) => (
                <ProfileImage key={photoUrl} size="30px" uri={photoUrl} />
              ))}
            {thread?.threadName && (
              <Text ml={4}>{getLocalizedValue(thread.threadName)}</Text>
            )}
          </Row>
        </Pressable>
      ),
    });
  }, [
    conversation?.participants,
    thread?.threadName,
    navigation,
    user?.uid,
    onHeaderPress,
  ]);

  return <MessagesList conversationId={conversationId} threadId={threadId} />;
};
