import React, { useCallback, useEffect, useState } from 'react';
import { fetchExercises } from 'src/firebase/exercises';
import { ExercisesList } from 'components/exercises/ExercisesList';
import { useRecoilState } from 'recoil';
import { exercisesState, useAuthUser } from 'src/state/state';
import { ExercisesStackScreenProps } from 'src/navigation/types/exercisesStack';
import { Routes } from 'src/navigation/routes';
import { Pressable } from 'native-base';
import { FilterIcon } from 'components/icons/icons';
import { FiltersModal } from 'components/modals/FiltersModal';
import { IExercise, IUser } from 'src/@types/api';
import { NumberBadge } from 'components/NumberBadge';
import { Colors } from 'src/theme/colors';
import { useTranslation } from 'react-i18next';
import { EXERCISES_LIMIT } from 'src/firebase/constants';
import { masteredFilterOptions } from '../../data/exercises/filters/filterOptions';
import { useIsPremium } from 'hooks/useIsPremium';
import { FiltersType } from '../../@types/enums';

type Props = ExercisesStackScreenProps<Routes.Exercises>;
type Filters = Record<string, boolean>;

export const ExercisesScreen = ({ navigation }: Props) => {
  const { t } = useTranslation();
  const { hasPremiumExercises } = useIsPremium();

  const [exercises, setExercises] = useRecoilState(exercisesState);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Filters>({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  const user = useAuthUser();
  const masteredExercises = filterMasteredExercises(
    exercises,
    selectedFilters,
    user
  );

  const filteredExercises = masteredExercises
    .filter((exercise: IExercise) =>
      Object.keys(selectedFilters)
        .filter((filter) => !!filter && !masteredFilterOptions.includes(filter))
        .every((keyword: string) => exercise.keywords.includes(keyword))
    )
    .slice(0, EXERCISES_LIMIT * (page + 1));

  const hasAllExercises =
    filteredExercises.length < (page + 1) * EXERCISES_LIMIT;

  const getAllExercisesFirebase = useCallback(async () => {
    const exercisesData = await fetchExercises(!hasPremiumExercises);

    setExercises(exercisesData);
    setLoading(false);
  }, [hasPremiumExercises, setExercises]);

  useEffect(() => {
    getAllExercisesFirebase();
  }, [getAllExercisesFirebase]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Pressable px={4} onPress={() => setFiltersOpen(true)}>
          <NumberBadge value={Object.keys(selectedFilters).length} />
          <FilterIcon
            color={Colors.primary}
            _dark={{ color: Colors.bgWhite }}
          />
        </Pressable>
      ),
    });
  }, [navigation, selectedFilters]);

  const fetchMore = async () => {
    setLoading(true);
    setLoading(false);
    setPage(page + 1);
  };

  return (
    <>
      <ExercisesList
        loading={loading}
        exercises={filteredExercises}
        title={t('routes.exercises')}
        onShowFilters={() => setFiltersOpen(true)}
        fetchMore={hasAllExercises || loading ? undefined : fetchMore}
        filtersCount={Object.keys(selectedFilters).length}
      />

      <FiltersModal
        isOpen={filtersOpen}
        onClose={() => setFiltersOpen(false)}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        filtersType={FiltersType.Exercises}
      />
    </>
  );
};

const filterMasteredExercises = (
  exercises: IExercise[],
  filters: Filters,
  user?: IUser | null
) => {
  const mastered = filters.mastered;
  const notMastered = filters['not-mastered'];
  if (!user || (mastered && notMastered) || (!mastered && !notMastered)) {
    return exercises;
  }

  if (mastered) {
    return exercises.filter((exercise) => {
      return user?.masteredExercises?.includes(exercise.id);
    });
  }
  if (notMastered) {
    return exercises.filter((exercise) => {
      return !user?.masteredExercises?.includes(exercise.id);
    });
  }
  return exercises;
};
