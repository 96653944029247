import React, { useEffect, useState } from 'react';
import { DarkColumn } from 'components/layout/views';
import {
  Column,
  IconButton,
  Image,
  Row,
  Text,
  useColorMode,
  useDisclose,
  View,
} from 'native-base';
import { useBgColor } from 'src/hooks/useBgColor';
import { useAuthUser } from 'src/state/state';
import { ExerciseImage } from 'components/exercises/ExerciseImage';
import { IConversation, IConversationThread, IMessage } from 'src/@types/api';
import { DownIcon, UpIcon } from 'components/icons/icons';
import { getLocalizedValue } from 'src/utils/utils';
import {
  onFetchThreadMessages,
  sendMessage,
  setReadMessage,
} from 'src/firebase/conversations';
import { Colors } from 'src/theme/colors';
import { ProfileImage } from 'components/ProfileImage';
import styled from 'styled-components/native';
import { Link } from 'components/links/Link';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { AppWebStackParamList } from 'src/navigation/types/appWebStack';
import { ThreadType } from 'src/@types/enums';
import Collapsible from 'react-native-collapsible';
import { useConversationPhotos } from 'src/hooks/useConversationPhotos';
import Animated, { SlideInLeft, SlideInRight } from 'react-native-reanimated';
import { musclesImages } from 'src/data/muscles/musclesImages';
import { MessageInput } from 'components/chat/MessageInput';
import { problemsImages } from '../../data/problems/problemsImages';
import { MessageText } from 'components/chat/MessageText';

type Props = {
  conversation: IConversation;
  thread: IConversationThread;
  getLink: (
    conversationId: string,
    threadId: string
  ) => To<AppWebStackParamList>;
  isUnread: boolean;
};

const ThreadMemoized = ({ conversation, thread, getLink, isUnread }: Props) => {
  const user = useAuthUser();
  const { colorMode } = useColorMode();
  const color = useBgColor();
  const { isOpen, onToggle } = useDisclose();
  const { getUserPhoto, isGroupChat } = useConversationPhotos(conversation);
  const [messages, setMessages] = useState<IMessage[]>([]);

  const isMine = thread.senderUid === user?.uid;

  useEffect(() => {
    if (isOpen) {
      return onFetchThreadMessages(conversation.id, thread.id, setMessages);
    }
  }, [conversation.id, isOpen, thread.id]);

  if (!user) {
    return null;
  }

  const onSendMessage = (text: string) => {
    sendMessage(
      user,
      text,
      conversation.participants.filter((friend) => friend.uid !== user.uid),
      thread.type,
      thread.threadName,
      thread.id
    );
  };

  const readMessage = () => {
    if (isUnread) {
      setReadMessage(user, conversation.id, thread.id);
    }
  };
  const onPlusPress = () => {
    if (!isOpen) {
      readMessage();
    }
    onToggle();
  };

  const renderMessage = (senderUid: string, text: string) => {
    const isMine = senderUid === user.uid;
    const bgColor = isMine
      ? Colors.secondary
      : colorMode === 'light'
      ? Colors.bgScreen
      : Colors.black;

    return (
      <Row
        space={2}
        alignItems="center"
        bg={bgColor}
        borderRadius={10}
        px={2}
        py={1}
        w="80%"
        alignSelf={isMine ? 'flex-end' : 'flex-start'}
      >
        {!isMine && isGroupChat && (
          <ProfileImage size="20px" uri={getUserPhoto(senderUid)} />
        )}
        <MessageText textAlign={isMine ? 'right' : 'left'}>{text}</MessageText>
      </Row>
    );
  };

  const renderAnimatedMessage = (message: IMessage) => {
    const isMine = message.senderUid === user.uid;
    return (
      <Animated.View
        key={message.id}
        entering={isMine ? SlideInRight : SlideInLeft}
      >
        {renderMessage(message.senderUid, message.text)}
      </Animated.View>
    );
  };

  return (
    <DarkColumn
      w={300}
      maxW="90%"
      my={2}
      p={4}
      borderRadius={10}
      space={1}
      alignSelf={isMine ? 'flex-end' : 'flex-start'}
    >
      <Link to={getLink(conversation.id, thread.id)}>
        <View w="full" h={200}>
          {thread.type === ThreadType.Exercise ? (
            <ExerciseImage id={thread.id} />
          ) : (
            <Image
              width="full"
              h="full"
              resizeMode="cover"
              source={
                thread.type === ThreadType.Muscle
                  ? musclesImages[thread.id]
                  : problemsImages[thread.id]
              }
              alt="Message image"
            />
          )}
        </View>
      </Link>
      <Row alignItems="center">
        {isUnread && <Circle />}
        <Text textAlign="center" bold flex={1}>
          {getLocalizedValue(thread.threadName)}
        </Text>
        <IconButton
          icon={isOpen ? <UpIcon /> : <DownIcon />}
          onPress={onPlusPress}
        />
      </Row>

      {isOpen ||
        renderMessage(thread.lastMessage.senderUid, thread.lastMessage.text)}
      {/*// @ts-ignore*/}
      <Collapsible collapsed={!isOpen}>
        <Column space={1}>{messages.map(renderAnimatedMessage)}</Column>
        <Column mt={2}>
          <MessageInput
            bg={color}
            _focus={{
              bg: color,
            }}
            onSend={onSendMessage}
            onFocus={readMessage}
          />
        </Column>
      </Collapsible>
    </DarkColumn>
  );
};

export const Thread = React.memo(
  ThreadMemoized,
  (prevProps, nextProps) =>
    prevProps.thread.updatedAt.isEqual(nextProps.thread.updatedAt) &&
    prevProps.isUnread === nextProps.isUnread
);

const Circle = styled.View`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${Colors.badge};
`;
