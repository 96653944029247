import { IProblemsConfig } from 'src/@types/types';

export const problemsConfigEn: IProblemsConfig = {
  rhomboidSpasm: {
    id: 'rhomboidSpasm',
    name: 'Rhomboid spasm',
    description:
      'A rhomboid spasm is a condition that occurs when the rhomboid major and rhomboid minor muscles, located between the shoulder blades and the vertebrae, are too tight or tight. This problem can have several causes and can have different consequences for movement and comfort. Some of the possible causes of rhomboid spasm include:',
    joints: ['neck', 'spineMid', 'scapula', 'cervicalSpine', 'thoracicSpine'],
    keywords: ['ordinary', 'upperBody'],
    causes: [
      'Overuse and repetitive motions: Repetitive motions or overuse are common causes of muscle spasm. For example, repeated lifting of heavy objects, repeated movements of arms and shoulders, or improper posture can cause overload and muscle spasm in the rhomboid area.',
      'Poor posture: Unhealthy posture, such as forward head, rounding of the upper back (hunchback), or hunched shoulders, can create muscle imbalances and cause muscle spasm in the rhomboid area.',
      'Injuries: Injuries such as bumps, falls, or injuries to the back and shoulder blades can cause rhomboid spasm.',
      'Stress and tension: Emotional stress and tension can also manifest in physical muscle tension and contribute to spasm on the rhomboids.',
    ],
    footer:
      'Symptoms of a rhomboid spasm can include pain, tension and limitation of movement in the area between the shoulder blades and the spine. There may also be headaches and shoulder pain, as well as discomfort in the shoulder blades.' +
      '\n' +
      'Treatment of rhomboid spasm depends on the exact cause and severity of the problem. It is recommended to consult a physical therapy or rehabilitation specialist who can provide appropriate therapeutic procedures, including exercises to strengthen and relax the rhomboid muscles. Massage, heat therapy, anti-inflammatory medications, and techniques to improve posture may also be part of the treatment plan.',
  },
  forwardHead: {
    id: 'forwardHead',
    name: 'Forward head',
    description:
      'In this case, the head is moved forward relative to the normal neutral position. This postural problem can have several effects on posture:',
    joints: ['neck', 'spineMid', 'scapula', 'clavicle'],
    keywords: ['ordinary', 'upperBody'],
    causes: [
      'Can cause imbalance and disrupt optimal spinal alignment. When the head is moved forward, the muscles in the neck and shoulders have to work harder to maintain balance and support the head. This can lead to unhealthy posture, such as a rounded upper back (hump), hunched shoulders, and a hunched upper body.',
      'Can affect the biomechanics of the spine. As the center of gravity of the head is shifted forward, the load on the cervical spine and the upper part of the spine increases. This can lead to an increased risk of pain and discomfort in the area.',
      'Can have a negative impact on breathing. Unhealthy head and upper body posture can limit full breathing, which can affect breathing quality and overall lung function.',
      'This postural problem can also affect muscle balance in the neck, shoulders, and back. Some muscles may lengthen and weaken, while others may shorten and contract. This unbalanced muscle tension can lead to discomfort, pain and limitation of movement.',
    ],
    footer:
      'If you suspect that you are suffering from a forward head and its impact on your posture, it is recommended that you consult a postural therapy specialist or physical therapist. They can provide you with appropriate exercises and techniques to correct posture and strengthen muscles to improve posture and minimize the symptoms associated with forward head. Therefore, try various stretching exercises associated with this problem and therefore exercises aimed at the neck.',
  },
  midBackPain: {
    id: 'midBackPain',
    name: 'Pain between shoulder blades',
    description:
      "A common problem of today's generation also due to lifestyle and phones :(, it stems from the problem of drooping shoulders",
    joints: ['spineMid', 'scapula'],
    keywords: ['ordinary', 'upperBody'],
    causes: [
      'Muscle tension: Excessive muscle tension in the middle back area between the shoulder blades can lead to pain. This can be caused, for example, by sitting for a long time in an inappropriate position, poor posture or excessive muscle strain.',
      'Inflammation of the intervertebral disc: Inflammation or irritation of the intervertebral disc near the middle back can cause pain between the shoulder blades.',
      'Osteoarthritis: Degenerative joint disease, such as osteoarthritis, can affect the vertebrae in the middle back and cause pain.',
      'Injuries: Injuries such as falls, bumps, or sports injuries can result in pain in this area.',
      'Stress and tension: Mental stress and tension can also manifest as pain in the middle of the back between the shoulder blades.',
    ],
    footer:
      'It is important to see a doctor or physical therapist if you experience pain in this area, especially if it is intense, lasts for a long time, or is associated with other symptoms. A doctor can perform a thorough examination and possibly recommend additional diagnostic tests, such as X-rays or magnetic resonance imaging, and suggest appropriate treatment that includes rest, physical therapy, anti-inflammatory drugs, or other therapeutic methods. If you receive a recommendation for physiotherapy treatment, try various stretching exercises associated with this problem. If you receive a referral for physical therapy, try different stretching exercises associated with the problem.',
  },
  lowBackPain: {
    id: 'lowBackPain',
    name: 'Low back pain',
    description:
      'Pain in the lower part of the back, i.e. in the area of the lower spine, is a relatively common problem that can have several causes. Some of the possible causes of pain in this area are:',
    joints: ['spineBot', 'spineMid', 'pelvis', 'hip'],
    keywords: ['ordinary', 'upperBody'],
    causes: [
      'Muscle strain and overuse: Overuse of the muscles in the lower back, such as lifting heavy objects, sitting for long periods in an awkward position or poor posture, can cause muscle tension and pain in this area.',
      'Sciatica: Sciatica is a condition in which the sciatic nerve, located in the lower part of the spine, is compressed or irritated. This can cause pain, numbness and tingling in the lower back and down into the legs.',
      'Herniated intervertebral disc: In a herniated disc, the inner part of the intervertebral disc bulges into the space between the vertebrae and can press on surrounding nerves, causing lower back pain.',
      'Inflammatory diseases: Inflammatory diseases, such as spondylitis or arthritis, can affect the vertebrae in the lower spine and cause pain and inflammation.',
      'Fractures and injuries: Vertebral fractures or other injuries to the lower spine can result in pain in this area.',
    ],
    footer:
      'If you suffer from pain in the lower back, it is advisable to consult a doctor to find out the exact cause of the pain and suggest an appropriate treatment. This may include rest, physical therapy, anti-inflammatory medications, injections, or other treatment methods, depending on the severity and cause of the pain. If you receive a referral for physical therapy, try different stretching exercises associated with the problem.',
  },
  elbowPain: {
    id: 'elbowPain',
    name: 'Elbow pain',
    description:
      'Elbow pain can be very uncomfortable and limiting. There are several common causes of elbow pain:',
    joints: ['elbow'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      'Tennis elbow: Tennis elbow, also known as lateral epicondylitis, is inflammation or irritation of the tendons around the outside of the elbow. This condition can be caused by excessive repetitive strain on the hand and forearm, not just when playing tennis.',
      "Golfer's elbow: Golfer's elbow, also known as interstylar epicondylitis, is a similar condition to tennis elbow, but it affects the inner part of the elbow. Again, it can be caused by repetitive strain and movements involving the flexion and muscles of the forearm.",
      'Overuse: Repetitive motions such as lifting heavy objects, repetitive hand motions, or monotonous activity can cause excessive strain on the tendons and tissues in the elbow, leading to pain.',
      'Trauma or injury: Injuries such as falls, elbow bumps, or sports injuries can cause elbow pain. This can also include broken bones in the elbow area.',
      'Inflammatory diseases: Some inflammatory diseases, such as rheumatoid arthritis or lupus, can also affect the joints, including the elbow, leading to pain.',
    ],
    footer:
      'If you are suffering from elbow pain, it is recommended to see a doctor who can perform a thorough examination and provide appropriate treatment. This may include rest, physical therapy, anti-inflammatory medications, injections, or other treatment methods, depending on the exact cause of the pain.  If you receive a referral for physical therapy, try different stretching exercises associated with the problem.',
  },
  footPain: {
    id: 'footPain',
    name: 'Foot pain',
    description:
      'Foot problems are a common problem that can affect the musculoskeletal system and overall well-being. Here are some foot problems and their possible causes:',
    joints: ['foot', 'ankle'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      "Flat feet: Also known as 'pes planus', flat feet are characterized by a reduction or complete loss of the arch of the foot. It can cause pain and discomfort in the foot and subsequently affect posture and movement.",
      'Ankle inversion: Ankle inversion is a condition in which the ankle turns inward. This can cause ankle instability and discomfort when walking or playing sports.',
      'Plantar Fasciitis: Plantar fasciitis is an inflammation of the plantar fascia, a thin banded structure found on the bottom of the foot. It is characterized by pain in the heel or in the area under the heel, especially during the first steps in the morning or after a long rest.',
      'Callus: A callus is a thick, hard layer of skin that forms at places of repeated friction or pressure on the foot. It can cause pain and discomfort when walking.',
      'Foot ulcers: Foot ulcers are painful openings in the skin that can develop on the foot due to prolonged pressure, injury, or circulation problems.',
      'Foot deformities: Foot deformities such as hallux valgus or clubbed toes can affect the position and function of the foot and cause pain or discomfort.',
    ],
    footer:
      'Treatment for foot problems depends on the specific problem and its severity. It may include wearing appropriate footwear, using insoles or orthotics, physical therapy, exercises to strengthen the foot and leg muscles, massage, and in some cases pain and inflammation medications. In case of serious problems, surgery may be necessary.' +
      '\n' +
      'It is important to consult a doctor or a foot specialist, such as an orthopedist or physical therapist, to determine the exact cause of the foot problem and suggest appropriate treatment and prevention measures.',
  },
  hipPain: {
    id: 'hipPain',
    name: 'Hip pain',
    description:
      'Problems associated with the hip can be diverse and affect the musculoskeletal system and general well-being. Here are some common hip problems and their possible causes:',
    joints: ['hip', 'pelvis', 'spineBot'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      'Hip bursitis: Bursitis is inflammation of the mucous membrane in the hip joint area. It can cause pain, swelling and limitation of movement in the area. Causes of bursitis can include repetitive strain, injury, or inflammatory conditions.',
      'Arthritis of the hip joint: Arthritis is inflammation of the joint, which can also affect the hip joint. It causes pain, stiffness and limitation of movement. The causes of arthritis can be varied, including joint degeneration, autoimmune diseases, or traumatic injuries.',
      'Sciatic neuralgia: Sciatic neuralgia is a condition in which the sciatic nerve, located in the lumbar region, is compressed or irritated. It causes pain, numbness and tingling that can spread along the buttocks to the lower limbs.',
      'Muscle strains and injuries: Muscle strains in the lumbar region or injuries such as pulled muscles, muscle spasms, or muscle strains can cause pain and limitation of movement in the lumbar region.',
      'Herniated intervertebral disc: In a herniated intervertebral disc, the inner part of the disc digs into the space between the vertebrae and can press on surrounding nerves, including those that pass through the lumbar region. This can cause pain, numbness and tingling in the lumbar region down to the lower extremities.',
    ],
    footer:
      'Treatment for hip problems depends on the exact cause and severity of the problem. It is recommended to consult a doctor, such as an orthopedist or physiotherapist, who can evaluate your condition and suggest appropriate treatment. This may include physiotherapy, rehabilitation exercises, analgesics, anti-inflammatories. In the case of physiotherapy treatment, try stretching exercises associated with this problem.',
  },
  aboveScapulaPain: {
    id: 'aboveScapulaPain',
    name: 'Above Scapula area pain',
    description:
      'Pain in the area above the shoulder blade can be caused by various factors. Here are some possible causes of this type of pain:',
    joints: ['scapula', 'spineMid', 'neck'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      'Muscle strain: Overuse of the muscles in the area above the shoulder blade can lead to muscle strain, which can cause pain. The cause may be excessive lifting of heavy objects, repeated movements of the hands and shoulders, or inappropriate body posture.',
      'Muscle spasms: Muscle spasms are unexpected and uncontrollable muscle contractions that can cause pain. They can be caused by dehydration, lack of minerals, excessive stress or insufficient muscle exercise.',
      'Muscle inflammation: Muscle inflammation, such as muscle tendinitis or muscle bursitis, can cause pain in the area above the shoulder blade. These conditions can be caused by repetitive motion, injury, or infection.',
      'Injury: Injuries such as a fall, bump, or concussion can cause pain in the area above the shoulder blade. Possible injuries include muscle strains, sprains, bruises or broken bones.',
      'Postural problems: Unhealthy posture, such as a forward head, rounded upper back (hunchback), or hunched shoulders, can create muscle imbalances and cause pain in the area above the shoulder blade.',
    ],
    footer:
      'If you have pain in the area above the shoulder blade, it is important to consult a doctor to determine the exact cause and start appropriate treatment. This may include rest, physical therapy, exercises to strengthen muscles and improve posture, pain medication, or other treatment methods tailored to your specific situation. If the problem can be treated with physiotherapy, try the appropriate stretching exercises.',
  },
  shoulderPain: {
    id: 'shoulderPain',
    name: 'Shoulder pain',
    description:
      'Shoulder pain is a common problem that can affect daily life and restrict movement. There are many possible causes of shoulder pain, including the following:',
    joints: ['scapula', 'spineMid', 'shoulder'],
    keywords: ['ordinary', 'lowBody'],
    causes: [
      'Rotator cuff: This is a group of muscles and tendons that stabilize the shoulder joint. Injury or overuse of the rotator cuff can lead to shoulder pain.',
      'Tendinitis: Tendinitis is inflammation of the tendon in the shoulder, which can cause pain and limited range of motion.',
      'Bursitis: Bursitis is an inflammation of the fluid-filled sac (bursa) located near the shoulder joint. If the bursa becomes inflamed, it can cause pain in the shoulder.',
      'Impingement syndrome: This syndrome occurs when the space between the bones and tendons in the shoulder narrows, causing pressure and friction. This can lead to pain and restricted movement.',
      'Arthritis: Arthritis is joint inflammation and can also affect the shoulder joint. It causes pain, swelling and restriction of movement.',
    ],
    footer:
      'It is important to note that this is only a general overview of the possible causes of shoulder pain. If you suffer from shoulder pain, it is recommended to consult a doctor who can provide you with an accurate diagnosis and appropriate treatment. If you receive a referral for physical therapy, try different stretching exercises associated with the problem.',
  },
};
