import { likeExercise } from 'src/firebase/exercises';
import { useRecoilState } from 'recoil';
import { exercisesState, userState } from 'src/state/state';
import { useCallback } from 'react';
import { IFavoriteExercise } from 'src/@types/types';

export const useFavoriteExercise = () => {
  const [user, setUser] = useRecoilState(userState);
  const setExercises = useRecoilState(exercisesState)[1];

  const isFavorite = useCallback(
    (id: string) => {
      return !!user?.favorites.find((exercise) => exercise.id === id);
    },
    [user?.favorites]
  );

  const updateExercise = useCallback(
    (exerciseId: string, count: number) => {
      setExercises((exercises) =>
        exercises.map((exercise) => {
          if (exercise.id === exerciseId) {
            return { ...exercise, likes: exercise.likes + count };
          } else {
            return exercise;
          }
        })
      );
    },
    [setExercises]
  );

  const toggleFavorite = useCallback(
    (exercise: IFavoriteExercise) => {
      if (user) {
        likeExercise(user, exercise);
        setUser((user) => {
          if (!user) {
            return user;
          }
          if (isFavorite(exercise.id)) {
            return {
              ...user,
              favorites: user.favorites.filter(
                (favorite) => favorite.id !== exercise.id
              ),
            };
          } else {
            return {
              ...user,
              favorites: [
                ...user.favorites,
                {
                  id: exercise.id,
                  name: exercise.name,
                  type: exercise.type,
                },
              ],
            };
          }
        });
        updateExercise(exercise.id, isFavorite(exercise.id) ? -1 : 1);
      }
    },
    [isFavorite, setUser, updateExercise, user]
  );

  return {
    favoriteExercises: user?.favorites || [],
    isFavorite,
    disabled: !user,
    toggleFavorite,
  };
};
