import { ImageSourcePropType } from 'react-native';

export const problemsImages: Record<string, ImageSourcePropType> = {
  midBackPain: require('../../../assets/problems/midBackPain.jpg'),
  lowBackPain: require('../../../assets/problems/lowBackPain.jpg'),
  forwardHead: require('../../../assets/problems/forwardHead.jpg'),
  elbowPain: require('../../../assets/problems/elbowPain.jpg'),
  footPain: require('../../../assets/problems/footPain.jpg'),
  hipPain: require('../../../assets/problems/hipPain.jpg'),
  aboveScapulaPain: require('../../../assets/problems/aboveScapulaPain.jpg'),
  shoulderPain: require('../../../assets/problems/shoulderPain.jpg'),
  rhomboidSpasm: require('../../../assets/problems/rhomboidSpasm.jpg'),
};
