import { ITextProps, Text } from 'native-base';

// 14px
export const TextSmall = (props: ITextProps) => {
  return <Text fontSize="sm" {...props} />;
};

// 18px
export const TextLarge = (props: ITextProps) => {
  return <Text fontSize="lg" {...props} />;
};

// 14px light
export const TextSecondary = (props: ITextProps) => {
  return (
    <Text fontSize="sm" fontFamily="Roboto-Light" fontWeight={300} {...props} />
  );
};

// 16px light
export const TextLight = (props: ITextProps) => {
  return <Text fontFamily="Roboto-Light" {...props} />;
};
