import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { LANGUAGE_KEY, mmkv } from '../utils/storage';

export const useLanguage = () => {
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    (language: string) => {
      i18n.changeLanguage(language);
      mmkv.set(LANGUAGE_KEY, language);
    },
    [i18n]
  );
  return {
    changeLanguage,
    currentLanguage: i18n.language,
  };
};
