import { DarkColumn } from 'components/layout/views';
import React, { useMemo } from 'react';
import { Routes } from 'src/navigation/routes';
import {
  currentRouteState,
  useAuthUser,
  userRelationshipsState,
} from 'src/state/state';
import { Column, Text, useColorMode } from 'native-base';
import { useRecoilValue } from 'recoil';
import { Colors } from 'src/theme/colors';
import styled from 'styled-components';
import {
  BodyOutlineIcon,
  ChatIcon,
  DashboardOutlineIcon,
  FriendsOutlineIcon,
  GymIcon,
  LoginIcon,
  LogoutIcon,
  ProblemsIcon,
  UserIcon,
} from 'components/icons/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'components/links/Link';
import { ProfileImage } from 'components/ProfileImage';
import { login, logout } from '../../firebase/auth';
import { useUnreadMessages } from 'src/hooks/useUnreadMessages';
import { MenuBadge } from 'components/MenuBadge';
import { IMenuItem } from 'src/@types/types';
import { MENU_WIDTH } from 'src/theme/constants';
import { Logo } from 'components/Logo';

export const Menu = () => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const { colorMode } = useColorMode();
  const route = useRecoilValue(currentRouteState);
  const { unreadCount } = useUnreadMessages();
  const relationships = useRecoilValue(userRelationshipsState);

  const contentItems: IMenuItem[] = useMemo(
    () => [
      { target: Routes.Exercises, Icon: GymIcon, title: t('routes.exercises') },
      {
        target: Routes.Muscles,
        Icon: BodyOutlineIcon,
        title: t('routes.muscles'),
      },

      {
        target: Routes.Problems,
        Icon: ProblemsIcon,
        title: t('routes.problems'),
      },
    ],
    [t]
  );

  const profileItems: IMenuItem[] = useMemo(
    () => [
      {
        target: Routes.Dashboard,
        Icon: DashboardOutlineIcon,
        title: t('routes.dashboard'),
      },
      {
        target: Routes.Chat,
        Icon: ChatIcon,
        loginRequired: true,
        title: t('routes.chat'),
        badge: unreadCount || undefined,
      },
      {
        target: Routes.Profile,
        Icon: UserIcon,
        loginRequired: true,
        title: t('routes.profile'),
      },
      {
        target: Routes.Friends,
        Icon: FriendsOutlineIcon,
        loginRequired: true,
        title: t('routes.friends'),
        iconProps: { size: 6, ml: -1 },
        badge: relationships?.pendingFriendRequests.length || undefined,
      },
    ],
    [relationships?.pendingFriendRequests.length, t, unreadCount]
  );

  const isActive = (target: IMenuItem['target']) => {
    return route === target;
  };
  const activeColor = colorMode === 'light' ? Colors.primary : Colors.bgWhite;
  const inactiveColor =
    colorMode === 'light' ? Colors.blueLight : Colors.grayLight;

  const renderSection = (title: string, items: IMenuItem[]) => {
    return (
      <Column space={1}>
        <Text
          fontSize="xs"
          color={colorMode === 'light' ? Colors.grayLight : 'gray.500'}
          textTransform="uppercase"
        >
          {title}
        </Text>
        {items.map(({ badge, target, Icon, loginRequired, title }) => {
          const active = isActive(target);
          if (loginRequired && !user) {
            return null;
          }
          const color = active ? activeColor : inactiveColor;

          return (
            <Link to={{ screen: target }} key={target} width="100%">
              <MenuRow active={active} dark={colorMode === 'dark'}>
                <Icon color={color} size={5} />
                <Text color={color}>{title}</Text>
                <MenuBadge value={badge} />
              </MenuRow>
            </Link>
          );
        })}
      </Column>
    );
  };

  return (
    <MenuWrapper>
      <DarkColumn p={4} w="full" h="full" space={6}>
        {user ? (
          <ProfileImage size={60} uri={user?.photoUrl} mx="auto" />
        ) : (
          <Logo size={60} mx="auto" />
        )}
        {renderSection(t('menu.content'), contentItems)}
        {renderSection(t('menu.personal'), profileItems)}
        <Column mt="auto" />
        {user ? (
          <MenuRow onClick={logout}>
            <LogoutIcon color={inactiveColor} />
            <Text color={inactiveColor}>{t('general.logout')}</Text>
          </MenuRow>
        ) : (
          <MenuRow onClick={login}>
            <LoginIcon color={inactiveColor} />
            <Text color={inactiveColor}>{t('general.login')}</Text>
          </MenuRow>
        )}
      </DarkColumn>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  height: 100vh;
  width: ${MENU_WIDTH}px;
  box-shadow: 1px 0px 3px 0px rgb(0 0 0 / 20%);
  z-index: 1000;
`;

const MenuRow = styled.div<{ active?: boolean; dark?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px;
  gap: 12px;
  background-color: ${({ active, dark }) =>
    active ? (dark ? '#313334' : '#ebeffd') : 'transparent'};
  border-radius: 5px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
