import { Column, IconButton, Row, Text, useDisclose } from 'native-base';
import { ProfileMenu } from 'components/ProfileMenu';
import { Screen } from 'components/layout/Screen';
import { ProfileImage } from 'components/ProfileImage';
import { useRecoilValue } from 'recoil';
import { userState } from 'src/state/state';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-native';
import { Routes } from 'src/navigation/routes';
import { DashboardStackScreenProps } from 'src/navigation/types/dashboardStack';
import { deleteAccount, logout } from '../../firebase/auth';
import { AlertModal } from 'components/modals/AlertModal';
import { useCallback, useState } from 'react';
import { AppInfo } from 'components/AppInfo';
import { isWeb } from 'src/utils/constants';
import { exercisesStrength } from '../../data/exercises/exercisesStrength';
import { exercisesStretch } from '../../data/exercises/exercisesStretch';
import { TextLight } from 'components/typography/text';
import { EditIcon } from 'components/icons/icons';
import { UserNameModal } from 'components/modals/UserNameModal';

type Props = DashboardStackScreenProps<Routes.Profile>;

export const ProfileScreen = ({ navigation }: Props) => {
  const { t } = useTranslation();
  const { onOpen, isOpen, onClose } = useDisclose();
  const [isUserNameModalOpen, setIsUserNameModalOpen] = useState(false);
  const user = useRecoilValue(userState);
  const [logoutLoading, setLogoutLoading] = useState(false);

  const onDelete = useCallback(async () => {
    if (!user) return;

    await deleteAccount(user);
    Alert.alert(t('profile.deleteAccountSuccess'), '', [
      {
        text: t('general.done'),
        style: 'cancel',
        onPress: () => navigation.navigate(Routes.Dashboard),
      },
    ]);
  }, [navigation, t, user]);

  if (!user) {
    return null;
  }

  const onPressLogout = async () => {
    setLogoutLoading(true);
    await logout();
    setLogoutLoading(false);
    navigation.navigate(Routes.Dashboard);
  };
  const exercisesLength =
    Object.keys(exercisesStrength).length +
    Object.keys(exercisesStretch).length;

  return (
    <Screen title={t('routes.profile')}>
      <Column space={6} flex={1} alignItems="center">
        <ProfileImage size={100} uri={user?.photoUrl} />
        <Column space={2} alignItems="center">
          <Row alignItems="center">
            <Text>{user.name}</Text>
            <IconButton
              icon={<EditIcon />}
              onPress={() => setIsUserNameModalOpen(true)}
            />
          </Row>
          <Text mt={-2}>{user.email}</Text>
          <TextLight>
            {t('profile.masteredExercises')}{' '}
            {user.masteredExercises?.length || 0} / {exercisesLength}
          </TextLight>
        </Column>
        <ProfileMenu
          onDelete={onOpen}
          onLogout={onPressLogout}
          logoutLoading={logoutLoading}
        />
        {isWeb || <AppInfo />}

        <AlertModal
          isOpen={isOpen}
          title={t('profile.confirmDeleteAccountTitle')}
          description={t('profile.confirmDeleteAccountSubtitle')}
          doneLabel={t('general.deleteAccount')}
          onDone={onDelete}
          onClose={onClose}
        />
        <UserNameModal
          isOpen={isUserNameModalOpen}
          onClose={() => setIsUserNameModalOpen(false)}
        />
      </Column>
    </Screen>
  );
};
