import { atom, useRecoilState } from 'recoil';
import { IExercise, IUser, IUsersRelationships } from 'src/@types/api';
import { IConversationsState } from 'src/@types/types';
import { PremiumType } from '../@types/enums';

export const exercisesState = atom<IExercise[]>({
  key: 'exercises',
  default: [],
});

export const userState = atom<IUser | undefined | null>({
  key: 'user',
  default: null,
});

export const userPremiumState = atom<PremiumType | undefined>({
  key: 'userPremium',
  default: undefined,
});

export const userRelationshipsState = atom<IUsersRelationships | undefined>({
  key: 'userRelationships',
  default: undefined,
});

export const conversationsState = atom<IConversationsState>({
  key: 'conversations',
  default: {
    conversations: [],
    loading: true,
    hasAllConversations: false,
  },
});

export const currentRouteState = atom<string | undefined>({
  key: 'currentRoute',
  default: undefined,
});

export const useAuthUser = () => {
  return useRecoilState(userState)[0];
};

export const useUserRelationships = () => {
  return useRecoilState(userRelationshipsState)[0];
};
