import { IconButton } from 'native-base';
import { FavoriteIcon, FavoriteOutlineIcon } from 'components/icons/icons';
import { useRef } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from 'src/state/state';
import { addFavoriteMuscle, removeFavoriteMuscle } from 'src/firebase/user';

type Props = {
  muscleId: string;
  size?: number | string;
  onPress?: (isFav: boolean) => void;
};

export const FavoriteMuscleButton = ({ muscleId, size = 5 }: Props) => {
  const [user, setUser] = useRecoilState(userState);

  const isPressed = useRef(false);
  const isFavorite = user?.favoriteMuscles?.includes(muscleId);

  const onPressHandler = () => {
    if (isPressed.current || !user) return;
    isPressed.current = true;
    if (isFavorite) {
      setUser({
        ...user,
        favoriteMuscles:
          user.favoriteMuscles?.filter((muscle) => muscle !== muscleId) || [],
      });
      removeFavoriteMuscle(user.uid, muscleId);
    } else {
      setUser({
        ...user,
        favoriteMuscles: user.favoriteMuscles
          ? [...user.favoriteMuscles, muscleId]
          : [muscleId],
      });
      addFavoriteMuscle(user.uid, muscleId);
    }

    setTimeout(() => {
      isPressed.current = false;
    }, 500);
  };

  return (
    <IconButton
      colorScheme="primary"
      icon={
        isFavorite ? (
          <FavoriteIcon size={size} />
        ) : (
          <FavoriteOutlineIcon size={size} />
        )
      }
      disabled={!user}
      onPress={onPressHandler}
    />
  );
};
