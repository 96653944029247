import { ILinkProps, Link } from 'native-base';
import { Colors } from 'src/theme/colors';

type Props = ILinkProps & {
  color?: string;
  href: string;
};

export const ExternalLink = ({
  color = Colors.primary,
  href,
  children,
  ...props
}: Props) => {
  return (
    <Link
      ml={2}
      href={href}
      // isExternal
      _text={{ color }}
      _hover={{
        opacity: 0.8,
      }}
      {...props}
    >
      {children}
    </Link>
  );
};
