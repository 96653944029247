import { Column, Image, Row, StyledProps } from 'native-base';
import { Link, LinkProps } from 'components/links/Link';
import { TextSmall } from 'components/typography/text';
import React from 'react';
import { PremiumOverlay } from 'components/PremiumOverlay';
import { hexToRGBA } from 'src/utils/colors';
import { DarkColumn } from 'components/layout/views';
import { ImageSourcePropType } from 'react-native';
import { Colors } from 'src/theme/colors';

type Props = {
  name: string;
  to: LinkProps['to'];
  onPress?: () => void;
  withOverlay?: boolean;
  imageSource: ImageSourcePropType;
  labelColor: string;
  hasPremium: boolean;
} & StyledProps;

export const Preview = ({
  name,
  onPress,
  withOverlay,
  height = 100,
  to,
  imageSource,
  labelColor,
  hasPremium,
  ...props
}: Props) => {
  const showOverlay = withOverlay && !hasPremium;

  const content = (
    <Column w="full">
      <Image
        w="full"
        h={height}
        resizeMode="cover"
        source={imageSource}
        alt={name}
      />
      <Row
        justifyContent="center"
        w="full"
        py={2}
        px={1}
        bg={labelColor}
        _dark={{
          bg: hexToRGBA(labelColor, 0.8),
        }}
      >
        <TextSmall flex={1} color="white" textAlign="center" numberOfLines={1}>
          {name}
        </TextSmall>
      </Row>
    </Column>
  );
  return (
    <DarkColumn
      shadow={2}
      borderRadius={5}
      alignSelf="flex-start"
      _dark={{
        bg: Colors.bgDarkLight,
      }}
      {...props}
    >
      <Column borderRadius={5} overflow="hidden" w="full">
        {showOverlay ? (
          <>
            {content}
            <PremiumOverlay hasPremium={hasPremium} />
          </>
        ) : (
          <Link to={to} width="100%" action={onPress}>
            {content}
          </Link>
        )}
      </Column>
    </DarkColumn>
  );
};
