import { IModalProps } from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendFriendRequest } from 'src/firebase/user';
import { useAuthUser, useUserRelationships } from 'src/state/state';
import { TextInput } from 'components/form/TextInput';
import { TextSmall } from 'components/typography/text';
import { TextButton } from 'components/buttons/TextButton';
import { SendIcon } from 'components/icons/icons';
import { Modal } from 'components/modals/Modal';

type Props = IModalProps;

export const AddFriendModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const relationships = useUserRelationships();

  if (!user) {
    return null;
  }

  const onSubmit = async () => {
    setError('');
    if (user.email === email) {
      setError(t('profile.errorSelf'));
      return;
    }
    if (relationships?.friends.find((friend) => friend.email === email)) {
      setError(t('profile.friendExists'));
      return;
    }
    if (
      relationships?.sentFriendRequests.find(
        (request) => request.email === email
      )
    ) {
      setError(t('profile.requestExists'));
      return;
    }
    setLoading(true);
    try {
      await sendFriendRequest(user, email.trim());
      setLoading(false);
      setEmail('');
      onClose();
    } catch (e) {
      setLoading(false);
      setError(t('profile.noUserFound'));
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('profile.addFriend')}>
      <TextInput
        value={email}
        autoCapitalize="none"
        textContentType="emailAddress"
        onChangeText={setEmail}
        placeholder={t('profile.email')}
      />
      {error ? <TextSmall color="red.500">{error}</TextSmall> : null}
      <TextButton
        leftIcon={<SendIcon />}
        disabled={!email}
        onPress={onSubmit}
        isLoading={loading}
        isLoadingText={t('general.sending')}
      >
        {t('profile.sendRequest')}
      </TextButton>
    </Modal>
  );
};
