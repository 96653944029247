import {
  Column,
  Divider,
  IconButton,
  Pressable,
  Row,
  Text,
  useDisclose,
  View,
} from 'native-base';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Routes } from 'src/navigation/routes';
import {
  AddFriendIcon,
  CloseIcon,
  FriendsOutlineIcon,
  RightIcon,
} from 'components/icons/icons';
import { Colors } from 'src/theme/colors';
import { AddFriendModal } from 'components/modals/AddFriendModal';
import { Screen } from 'components/layout/Screen';
import { useAuthUser, useUserRelationships } from 'src/state/state';
import { NumberBadge } from 'components/NumberBadge';
import { FriendsRequestsModal } from 'components/modals/FriendsRequestsModal';
import {
  HeadingSecondary,
  HeadingTertiary,
} from 'components/typography/heading';
import { IFriend } from 'src/@types/api';
import { ProfileImage } from 'components/ProfileImage';
import {
  acceptFriendRequest,
  declineFriendRequest,
  deleteSentRequest,
  removeFriend,
} from 'src/firebase/user';
import { TextLight, TextSecondary } from 'components/typography/text';
import { DarkColumn } from 'components/layout/views';
import { TextButton } from 'components/buttons/TextButton';
import { DashboardStackScreenProps } from 'src/navigation/types/dashboardStack';

type Props = DashboardStackScreenProps<Routes.Friends>;

export const FriendsScreen = ({ navigation }: Props) => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const { isOpen, onClose, onOpen } = useDisclose(false);
  const [friendsRequestsOpen, setFriendsRequestsOpen] = useState(false);
  const [sentRequestsOpen, setSentRequestsOpen] = useState(false);
  const relationships = useUserRelationships();

  const requestsCount = relationships?.pendingFriendRequests.length || 0;
  const sentRequestsCount = relationships?.sentFriendRequests.length || 0;

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <IconButton
          icon={<AddFriendIcon size={5} color={Colors.primary} />}
          onPress={onOpen}
        />
      ),
    });
  }, [navigation, onOpen]);

  if (!user) {
    return null;
  }

  const renderFriend = (friend: IFriend) => {
    return (
      <Row key={friend.uid} alignItems="center" space={4} my={2}>
        <ProfileImage size="30px" uri={friend.photoUrl} />
        <Column>
          <Text>{friend.name}</Text>
          <TextSecondary>{friend.email}</TextSecondary>
        </Column>
        <IconButton
          ml="auto"
          icon={<CloseIcon color={Colors.grayLight} />}
          onPress={() => removeFriend(user, friend)}
        />
      </Row>
    );
  };
  const hasFriends = !!relationships?.friends.length;
  return (
    <Screen
      title={t('routes.friends')}
      rightButton={
        <IconButton
          colorScheme="primary"
          icon={<AddFriendIcon color={Colors.primary} size={6} />}
          onPress={onOpen}
        />
      }
      mainButton={
        hasFriends ? (
          <TextButton
            leftIcon={<AddFriendIcon color={Colors.primary} size={4} />}
            onPress={onOpen}
            alignSelf="flex-start"
          >
            {t('profile.addFriend')}
          </TextButton>
        ) : null
      }
    >
      <Column alignItems="center" space={4} flex={1}>
        {requestsCount > 0 && (
          <FriendsButton
            title={t('profile.friendRequests')}
            count={requestsCount}
            onPress={() => setFriendsRequestsOpen(true)}
          />
        )}
        {sentRequestsCount > 0 && (
          <FriendsButton
            title={t('profile.sentRequests')}
            onPress={() => setSentRequestsOpen(true)}
          />
        )}
        {hasFriends ? (
          <DarkColumn w="full" p={4} borderRadius={5} space={2}>
            <HeadingTertiary>{t('profile.friends')}</HeadingTertiary>
            <Divider />
            {relationships?.friends.map(renderFriend)}
          </DarkColumn>
        ) : (
          <Column
            flex={1}
            alignItems="center"
            space={6}
            justifyContent="center"
          >
            <FriendsOutlineIcon size={120} color={Colors.primaryLight} />
            <HeadingSecondary>{t('profile.noFriendsTitle')}</HeadingSecondary>
            <TextLight textAlign="center">
              {t('profile.noFriendsSubtitle')}
            </TextLight>
            <TextButton onPress={onOpen}>{t('profile.addFriend')}</TextButton>
          </Column>
        )}
        <AddFriendModal isOpen={isOpen} onClose={onClose} />
        <FriendsRequestsModal
          title={t('profile.friendRequests')}
          isOpen={friendsRequestsOpen}
          onClose={() => setFriendsRequestsOpen(false)}
          requests={relationships?.pendingFriendRequests || []}
          onAccept={(friend) => acceptFriendRequest(user, friend)}
          onRemove={(friend) => declineFriendRequest(user, friend)}
        />
        <FriendsRequestsModal
          title={t('profile.sentRequests')}
          isOpen={sentRequestsOpen}
          onClose={() => setSentRequestsOpen(false)}
          requests={relationships?.sentFriendRequests || []}
          onRemove={(friend) => deleteSentRequest(user, friend)}
        />
      </Column>
    </Screen>
  );
};

type FriendButtonProps = {
  title: string;
  count?: number;
  onPress?: () => void;
};
export const FriendsButton = ({ title, count, onPress }: FriendButtonProps) => {
  return (
    <Pressable
      bg={Colors.bgWhite}
      _dark={{ bg: Colors.bgDark }}
      w="full"
      borderRadius={5}
      flexDirection="row"
      alignItems="center"
      px={4}
      py={2}
      onPress={onPress}
    >
      <View>
        <Text color={Colors.primary}>{title}</Text>
        <NumberBadge value={count} top={-6} right={-22} />
      </View>
      <RightIcon ml="auto" primary />
    </Pressable>
  );
};
