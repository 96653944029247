import {
  Center,
  Column,
  Divider,
  IconButton,
  IModalProps,
  Row,
  ScrollView,
  Text,
} from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFriend } from 'src/@types/api';
import { CheckIcon, CloseIcon } from 'components/icons/icons';
import { Colors } from 'src/theme/colors';
import { ProfileImage } from 'components/ProfileImage';
import { TextLight, TextSecondary } from 'components/typography/text';
import { Modal } from 'components/modals/Modal';

type Props = IModalProps & {
  requests: IFriend[];
  onAccept?: (friend: IFriend) => void;
  onRemove?: (friend: IFriend) => void;
  title: string;
};

export const FriendsRequestsModal = ({
  isOpen,
  onClose,
  requests,
  onAccept,
  onRemove,
  title,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <ScrollView>
        {requests.length === 0 ? (
          <Center flex={1}>
            <TextLight>{t('profile.noRequests')}</TextLight>
          </Center>
        ) : (
          <Column space={2} divider={<Divider />}>
            {requests.map((friend) => (
              <Column key={friend.uid}>
                <Row space={2} alignItems="center">
                  <ProfileImage uri={friend.photoUrl} size="30px" mr={2} />
                  <Text flex={1} numberOfLines={2}>
                    {friend.name}
                  </Text>
                  {onAccept && (
                    <IconButton
                      p={2}
                      icon={<CheckIcon />}
                      onPress={() => onAccept(friend)}
                    />
                  )}
                  {onRemove && (
                    <IconButton
                      p={2}
                      icon={<CloseIcon color={Colors.error} />}
                      onPress={() => onRemove(friend)}
                    />
                  )}
                </Row>
                <TextSecondary ml="46px">{friend.email}</TextSecondary>
              </Column>
            ))}
          </Column>
        )}
      </ScrollView>
    </Modal>
  );
};
