import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, LayoutChangeEvent, StyleSheet } from 'react-native';
import { TabBarIcon } from './TabBarIcon';
import { View } from 'native-base';
import { DarkColumn } from 'components/layout/views';
import { TabBarSvg } from 'components/tabbar/TabBarSvg';
import { StackActions } from '@react-navigation/native';

export const BottomTabBar = ({
  state: { index: activeIndex, routes },
  navigation,
  descriptors,
}: BottomTabBarProps) => {
  const translateX = useRef(new Animated.Value(0)).current;
  const [layout, setLayout] = useState<Record<number, number>>({});

  const { bottom } = useSafeAreaInsets();

  const handleLayout = (event: LayoutChangeEvent, index: number) => {
    event.persist();
    setLayout((layout) => ({
      ...layout,
      [index]: event.nativeEvent.layout.x - 25,
    }));
  };

  const removeIcon = useCallback((index: number) => {
    setLayout((layout) => {
      const newLayout = { ...layout };
      delete newLayout[index];
      return newLayout;
    });
  }, []);

  useEffect(() => {
    const xOffset = layout[activeIndex];

    Animated.timing(translateX, {
      toValue: xOffset,
      duration: 250,
      useNativeDriver: true,
    }).start();
  }, [activeIndex, layout, routes.length, translateX]);

  return (
    <DarkColumn
      pb={`${bottom ? bottom : 8}px`}
      _android={{
        pb: `${bottom + 12}px`,
      }}
    >
      <TabBarSvg translateX={translateX} />

      <View style={styles.tabBarContainer}>
        {routes.map((route, index) => {
          const active = index === activeIndex;
          const { options } = descriptors[route.key];

          return (
            <TabBarIcon
              key={route.key}
              index={index}
              active={active}
              options={options}
              onLayout={(e) => handleLayout(e, index)}
              onPress={() => {
                if (active) {
                  navigation.dispatch(StackActions.popToTop());
                } else navigation.navigate(route.name);
              }}
              onUnmount={removeIcon}
            />
          );
        })}
      </View>
    </DarkColumn>
  );
};

const styles = StyleSheet.create({
  tabBarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
});
