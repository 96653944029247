import { Screen } from 'components/layout/Screen';
import { useTranslation } from 'react-i18next';
import { Column, Divider, Row, useBreakpointValue } from 'native-base';
import { HeadingPrimary } from 'components/typography/heading';
import { ConversationsList } from 'components/chat/ConversationsList';
import { MessagesList } from 'components/chat/MessagesList';
import { Routes } from 'src/navigation/routes';
import { Threads } from 'components/chat/Threads';
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import {
  AppWebStackParamList,
  AppWebStackScreenProps,
} from 'src/navigation/types/appWebStack';
import { ChatHeader } from 'components/chat/ChatHeader';
import { useConversations } from 'src/hooks/useConversations';
import { useCallback, useEffect, useState } from 'react';
import { IConversation, IConversationThread } from 'src/@types/api';
import { EmptyChat } from 'components/chat/EmptyChat';
import { fetchThreadById } from 'src/firebase/conversations';

type Props = AppWebStackScreenProps<Routes.Chat>;

export const ChatScreen = ({ navigation, route }: Props) => {
  const { t } = useTranslation();
  const { conversationId, threadId } = route.params || {};
  const isSmall = useBreakpointValue([true, false, false]);
  const { getConversationById } = useConversations();
  const [thread, setThread] = useState<IConversationThread>();
  const [conversation, setConversation] = useState<IConversation>();
  const { conversations } = useConversations();

  if (isSmall) {
    navigation.replace(Routes.Conversations);
  }
  useEffect(() => {
    if (conversationId) {
      getConversationById(conversationId).then(setConversation);
    }
  }, [conversationId, getConversationById]);

  useEffect(() => {
    if (conversationId && threadId) {
      fetchThreadById(conversationId, threadId).then(setThread);
    }
  }, [conversationId, threadId]);

  const getLink = useCallback(
    (conversationId: string, threadId?: string): To<AppWebStackParamList> => {
      return {
        screen: Routes.Chat,
        params: { conversationId, threadId },
      };
    },
    []
  );

  return (
    <Screen withScroll={false}>
      <Column flex={1} px={4}>
        <Row justifyContent="space-between" p={4}>
          <HeadingPrimary>{t('routes.chat')}</HeadingPrimary>
          {conversation && (
            <ChatHeader conversation={conversation} thread={thread} />
          )}
        </Row>
        {conversations.length ? (
          <Row w="full" flex={1} divider={<Divider />}>
            <Column flexBasis="40%" maxW={350} minW={220}>
              <ConversationsList getLink={getLink} />
            </Column>
            {!!conversationId && (
              <Column flex={1}>
                {threadId ? (
                  <MessagesList
                    conversationId={conversationId}
                    threadId={threadId}
                  />
                ) : (
                  <Threads conversationId={conversationId} getLink={getLink} />
                )}
              </Column>
            )}
          </Row>
        ) : (
          <EmptyChat />
        )}
      </Column>
    </Screen>
  );
};
