import { Column, Text } from 'native-base';
import { HeadingSecondary } from 'components/typography/heading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import { ExternalLink } from 'components/links/ExternalLink';

type Props = {
  onSubscribe: () => void;
  subscribeLoading: boolean;
};

export const TERMS_URL = 'https://recoverytom.com/terms';
export const PRIVACY_URL = 'https://recoverytom.com/policy';

export const NoSubscription = ({ onSubscribe, subscribeLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <Column space={4} alignItems="center" pt={10}>
      <HeadingSecondary mb={4}>
        {t('subscription.noSubscriptionTitle')}
      </HeadingSecondary>
      <Text textAlign="justify">
        {t('subscription.noSubscriptionSubtitle')}
      </Text>
      <Text textAlign="justify">{t('subscription.description')}</Text>
      <Column alignItems="flex-start" alignSelf="flex-start" space={1}>
        <ExternalLink href={PRIVACY_URL} ml={0}>
          {t('routes.privacy')}
        </ExternalLink>
        <ExternalLink href={TERMS_URL} ml={0}>
          {t('routes.termsOfUse')}
        </ExternalLink>
      </Column>
      <PrimaryButton
        w={200}
        mt={4}
        onPress={onSubscribe}
        isLoading={subscribeLoading}
      >
        {t('subscription.subscribe')}
      </PrimaryButton>
    </Column>
  );
};
