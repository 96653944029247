export const APP_STORE_URL =
  'https://apps.apple.com/app/recoverytom/id6448706126';

export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.recoverytom.app';

export const YOUTUBE_SK = 'https://www.youtube.com/@recoverytomsk';
export const YOUTUBE_EN = 'https://www.youtube.com/@recoverytom';

export const INSTAGRAM_SK = 'https://www.instagram.com/_recoverytomsk/';
export const INSTAGRAM_EN = 'https://www.instagram.com/_recoverytom/';
