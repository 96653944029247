import { IconButton } from 'native-base';
import { MasteredOutlineIcon } from 'components/icons/icons';
import { useRef } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from 'src/state/state';
import { addMasteredExercise, removeMasteredExercise } from 'src/firebase/user';
import { Colors } from '../../theme/colors';

type Props = {
  exerciseId: string;
  size?: number | string;
  onPress?: (isFav: boolean) => void;
};

export const MasteredExerciseButton = ({ exerciseId, size = 5 }: Props) => {
  const [user, setUser] = useRecoilState(userState);

  const isPressed = useRef(false);
  const isFavorite = user?.masteredExercises?.includes(exerciseId);

  const onPressHandler = () => {
    if (isPressed.current || !user) return;
    isPressed.current = true;
    if (isFavorite) {
      setUser({
        ...user,
        masteredExercises:
          user.masteredExercises?.filter((problem) => problem !== exerciseId) ||
          [],
      });
      removeMasteredExercise(user.uid, exerciseId);
    } else {
      setUser({
        ...user,
        masteredExercises: user.masteredExercises
          ? [...user.masteredExercises, exerciseId]
          : [exerciseId],
      });
      addMasteredExercise(user.uid, exerciseId);
    }

    setTimeout(() => {
      isPressed.current = false;
    }, 500);
  };
  return (
    <IconButton
      colorScheme="primary"
      icon={
        <MasteredOutlineIcon
          size={size}
          color={isFavorite ? Colors.primary : Colors.blueLightest}
        />
      }
      disabled={!user}
      onPress={onPressHandler}
    />
  );
};
