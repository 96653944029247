import { isDev, isIOS } from './constants';
import { PremiumType } from '../@types/enums';

const PACKAGES_IDENTIFIERS_IOS = {
  [PremiumType.Muscles]: 'muscles_299_1m',
  [PremiumType.Exercises]: 'exercises_299_1m',
  [PremiumType.Premium]: 'rc_599_1m',
};

const PACKAGES_IDENTIFIERS_ANDROID = {
  [PremiumType.Muscles]: 'premium_muscles:premium-muscles-monthly',
  [PremiumType.Exercises]: 'premium_exercises:premium-exercises-monthly',
  [PremiumType.Premium]: 'premium:premium-monthly',
};

export const PACKAGES_IDENTIFIERS_MOBILE = isIOS
  ? PACKAGES_IDENTIFIERS_IOS
  : PACKAGES_IDENTIFIERS_ANDROID;

const PRODUCTS_IDENTIFIERS_WEB_DEV = {
  [PremiumType.Muscles]: 'price_1O2fDqFVZoSjcONVgkudpX6k',
  [PremiumType.Exercises]: 'price_1O2fEXFVZoSjcONVJCCTuWGz',
  [PremiumType.Premium]: 'price_1MmfGmFVZoSjcONVFdMGFuvz',
};

const PRODUCTS_IDENTIFIERS_WEB_PROD = {
  [PremiumType.Muscles]: 'price_1O2vIDFVZoSjcONVIYzrKHH5',
  [PremiumType.Exercises]: 'price_1O2vIgFVZoSjcONVjP9a3dOX',
  [PremiumType.Premium]: 'price_1O7lZuFVZoSjcONVQ34alta5',
};

export const WEB_PRODUCTS = isDev
  ? PRODUCTS_IDENTIFIERS_WEB_DEV
  : PRODUCTS_IDENTIFIERS_WEB_PROD;
