import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ExercisesStackParamList } from 'src/navigation/types/exercisesStack';
import { Routes } from 'src/navigation/routes';
import { ExercisesScreen } from 'components/screens/ExercisesScreen';
import { useTranslation } from 'react-i18next';
import { isAndroid, isIOS } from 'src/utils/constants';
import { useColorMode } from 'native-base';
import { ExerciseDetailScreen } from 'components/screens/ExerciseDetailScreen';
import { StackHeader } from 'components/layout/StackHeader';

const Stack = createNativeStackNavigator<ExercisesStackParamList>();

export const ExercisesStack = () => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  return (
    <Stack.Navigator
      initialRouteName={Routes.Exercises}
      screenOptions={{
        headerShown: true,
        headerBlurEffect: isIOS
          ? colorMode === 'dark'
            ? 'dark'
            : 'light'
          : undefined,
        headerTransparent: isIOS,
        header: isAndroid ? (props) => <StackHeader {...props} /> : undefined,
      }}
    >
      <Stack.Screen
        name={Routes.Exercises}
        component={ExercisesScreen}
        options={{
          title: t('routes.exercises'),
        }}
      />
      <Stack.Screen
        name={Routes.ExerciseDetail}
        component={ExerciseDetailScreen}
      />
    </Stack.Navigator>
  );
};
