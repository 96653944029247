import React, { useState } from 'react';
import { FloatingButton } from 'components/buttons/FloatingButton';
import { RotateIcon } from 'components/icons/icons';
import { BodyImage } from 'components/BodyImage';
import styled from 'styled-components/native';
import { GestureResponderEvent } from 'react-native';
import { IPoint } from 'src/@types/types';

type Props = {
  frontPoints: IPoint[];
  backPoints: IPoint[];
  onPointPress: (point: IPoint, e: GestureResponderEvent) => void;
};

export const Body = ({ frontPoints, backPoints, onPointPress }: Props) => {
  const [isBack, setIsBack] = useState(false);

  const onPress = () => {
    setIsBack((front) => !front);
  };

  const renderPoints = (points: IPoint[]) => {
    return points.map((point) => {
      return (
        <Dot
          key={point.id}
          hitSlop={{ top: 16, left: 16, bottom: 16, right: 16 }}
          onPress={(e) => onPointPress(point, e)}
          left={point.x}
          top={point.y}
        />
      );
    });
  };

  return (
    <>
      <FloatingButton onPress={onPress} Icon={RotateIcon} />
      <BodyImage
        isBack={isBack}
        frontPoints={renderPoints(Object.values(frontPoints))}
        backPoints={renderPoints(Object.values(backPoints))}
      />
    </>
  );
};

const Dot = styled.TouchableOpacity<{ top: number; left: number }>`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 5px;
  //background-color: #4ad2db;
  background-color: #e01d37;
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
`;
