import { Screen } from 'components/layout/Screen';
import { Column, Divider, Image, Row, Text, useDisclose } from 'native-base';
import { MusclesStackScreenProps } from 'src/navigation/types/musclesStack';
import { Routes } from 'src/navigation/routes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getMusclesConfig } from 'src/data/muscles/musclesConfig';
import { useTranslation } from 'react-i18next';
import { HeadingSecondary } from 'components/typography/heading';
import { TextLight } from 'components/typography/text';
import { MusclesSlider } from 'components/MusclesSlider';
import { FavoriteMuscleButton } from 'components/buttons/FavoriteMuscleButton';
import { FloatingButton } from 'components/buttons/FloatingButton';
import { GymIcon, ProblemsIcon } from 'components/icons/icons';
import { getProblemsConfig } from 'src/data/problems/problemsConfig';
import { ProblemsBottomModal } from 'components/modals/ProblemsBottomModal';
import { ExercisesBottomModal } from 'components/modals/ExercisesBottomModal';
import { MuscleTable } from 'components/MuscleTable';
import { useRecoilState, useRecoilValue } from 'recoil';
import { exercisesState, userState } from 'src/state/state';
import { SendMessageModal } from 'components/modals/SendMessageModal';
import { ThreadType } from 'src/@types/enums';
import { musclesConfigEn } from 'src/data/muscles/musclesEn';
import { musclesImages } from 'src/data/muscles/musclesImages';
import { SendMessageButton } from 'components/SendMessageButton';

type Props = MusclesStackScreenProps<Routes.MuscleDetail>;

export const MuscleDetailScreen = ({ route, navigation }: Props) => {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);
  const { id } = route.params;
  const musclesConfig = getMusclesConfig();
  const problemsConfig = getProblemsConfig();
  const muscle = musclesConfig[id];
  const skName = musclesConfigEn[id].name;
  const enName = musclesConfigEn[id].name;

  const [showProblems, setShowProblems] = useState(false);
  const [showExercises, setShowExercises] = useState(false);
  const [allExercises] = useRecoilState(exercisesState);

  const { isOpen, onClose, onOpen } = useDisclose(false);

  const exercises = useMemo(
    () =>
      allExercises.filter((exercise) =>
        muscle.joints.some((joint) => exercise.keywords.includes(joint))
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allExercises?.length, muscle.joints]
  );

  const relatedMuscles = useMemo(
    () =>
      muscle.musclesSameCategory
        ?.map((id) => musclesConfig[id])
        .filter(Boolean),
    [muscle.musclesSameCategory, musclesConfig]
  );

  useEffect(() => {
    navigation.setOptions({ title: muscle.name });
  }, [muscle.name, navigation]);

  const problems = useMemo(
    () =>
      Object.values(problemsConfig).filter((problem) =>
        muscle?.joints.some((keyword) => problem.joints.includes(keyword))
      ),
    [muscle?.joints, problemsConfig]
  );

  const onShowExercises = () => {
    setShowExercises(true);
  };

  const buttons = (
    <>
      <FloatingButton
        bottom={32}
        onPress={() => onShowExercises()}
        Icon={GymIcon}
      />
      <FloatingButton
        bottom={16}
        onPress={() => setShowProblems(true)}
        Icon={ProblemsIcon}
      />
    </>
  );

  const hideProblems = useCallback(() => {
    setShowProblems(false);
  }, []);

  const hideExercises = useCallback(() => {
    setShowExercises(false);
  }, []);

  return (
    <Screen floatingComponent={buttons} title={muscle.name}>
      <Column alignItems="center" space={4}>
        <Image
          width="full"
          h={[350, 500]}
          resizeMode="contain"
          source={musclesImages[muscle.id]}
          alt="muscle"
        />
        {user && (
          <Row
            space={2}
            alignItems="center"
            w="full"
            justifyContent={'space-between'}
          >
            <FavoriteMuscleButton muscleId={muscle.id} />
            <SendMessageButton onPress={onOpen} />
          </Row>
        )}

        <MuscleTable muscle={muscle} />
        <HeadingSecondary mt={2} textAlign="center">
          {t('muscles.description')}
        </HeadingSecondary>
        <Text textAlign="center">{muscle.description}</Text>
        {relatedMuscles && (
          <>
            <Divider />
            <TextLight textAlign="center">
              {t('muscles.relatedMuscles')}
            </TextLight>
            <MusclesSlider muscles={relatedMuscles} />
          </>
        )}
        <SendMessageModal
          isOpen={isOpen}
          onClose={onClose}
          name={{ sk: skName, en: enName }}
          threadId={muscle.id}
          type={ThreadType.Muscle}
        />
        <ProblemsBottomModal
          problems={problems}
          isOpen={showProblems}
          onClose={hideProblems}
        />
        <ExercisesBottomModal
          exercises={exercises}
          isOpen={showExercises}
          onClose={hideExercises}
        />
      </Column>
    </Screen>
  );
};
