import React from 'react';
import { IImageProps, Image } from 'native-base';
import ProfilePicture from '../../assets/profilePicturePlaceholder.png';

type Props = IImageProps & {
  uri?: string | null;
  size: IImageProps['width'];
};

export const ProfileImage = React.memo(({ size, uri, ...props }: Props) => {
  return (
    <Image
      alt="photo"
      w={size}
      h={size}
      borderRadius={size}
      source={uri ? { uri } : ProfilePicture}
      defaultSource={ProfilePicture}
      {...props}
    />
  );
});

ProfileImage.displayName = 'ProfileImage';
