import {
  deleteUser,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  reauthenticateWithPopup,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth';
import { auth } from 'src/firebase/init';
import { IUser } from '../@types/api';
import { deleteUserFromFriends } from './user';
import { logError } from './analytics';
import { ERRORS } from './constants';
import { PremiumType } from '../@types/enums';

export const onAuth = (callback: (user: User | null) => void) => {
  return onAuthStateChanged(auth, (user) => {
    callback(user);
  });
};

export const login = async () => {
  const provider = new GoogleAuthProvider();
  try {
    return await signInWithPopup(auth, provider);
  } catch (e) {
    logError(e as Error, ERRORS.GOOGLE_LOGIN);
  }
};

export const logout = () => {
  return signOut(auth);
};

export const getUserRole = async () => {
  await auth.currentUser?.getIdToken(true);
  const decodedToken = await auth.currentUser?.getIdTokenResult();
  const stripeRole = decodedToken?.claims.stripeRole;
  const revenueCatEntitlements = decodedToken?.claims
    ?.revenueCatEntitlements as string[];
  if (stripeRole === 'premium' || revenueCatEntitlements?.includes('premium')) {
    return PremiumType.Premium;
  } else if (
    stripeRole === 'premium-exercises' ||
    revenueCatEntitlements?.includes('premium-exercises')
  ) {
    return PremiumType.Exercises;
  } else if (
    stripeRole === 'premium-muscles' ||
    revenueCatEntitlements?.includes('premium-muscles')
  ) {
    return PremiumType.Muscles;
  }
};

export const deleteAccount = async (user: IUser) => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const authUser = auth.currentUser;
  if (authUser) {
    await reauthenticateWithPopup(authUser, provider);
    await deleteUserFromFriends(user);
    return deleteUser(authUser);
  }
};

export const onRevokeAppleCredentials = () => {
  return;
};
