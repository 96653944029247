import React, { useEffect, useRef } from 'react';
import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';
import {
  Animated,
  LayoutChangeEvent,
  Pressable,
  StyleSheet,
} from 'react-native';
import { NumberBadge } from 'components/NumberBadge';
import { Colors } from 'src/theme/colors';

type Props = {
  active?: boolean;
  options: BottomTabNavigationOptions;
  onLayout: (e: LayoutChangeEvent) => void;
  onPress: () => void;
  onUnmount: (index: number) => void;
  index: number;
};
export const TabBarIcon = React.memo(
  ({ active, options, onLayout, onPress, onUnmount, index }: Props) => {
    const opacity = useRef(new Animated.Value(active ? 1 : 0.5)).current;

    useEffect(() => {
      Animated.timing(opacity, {
        toValue: active ? 1 : 0.8,
        duration: 1250,
        useNativeDriver: true,
      }).start();
    }, [active, opacity]);

    useEffect(() => {
      return () => onUnmount(index);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Pressable onPress={onPress} onLayout={onLayout} style={styles.component}>
        <NumberBadge value={options.tabBarBadge} top={2} right={1} />
        <Animated.View
          style={[
            styles.iconContainer,
            {
              opacity,
            },
          ]}
        >
          {options.tabBarIcon?.({
            color: active ? Colors.primary : Colors.grayLight,
            size: 8,
            focused: active || false,
          })}
        </Animated.View>
      </Pressable>
    );
  }
);

TabBarIcon.displayName = 'TabBarIcon';

const styles = StyleSheet.create({
  component: {
    height: 60,
    width: 60,
    marginTop: -3,
  },
  componentCircle: {
    flex: 1,
    borderRadius: 30,
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
