import React, { useCallback, useRef, useState } from 'react';
import { MusclesGroupModal } from 'components/modals/MusclesGroupModal';
import { Body } from 'components/Body';
import frontPoints from 'src/data/muscles/frontBodyPointsMuscles.json';
import backPoints from 'src/data/muscles/backBodyPointsMuscles.json';
import { IPoint } from 'src/@types/types';
import { useFocusEffect } from '@react-navigation/native';

export const BodyMuscles = () => {
  const [selectedPoint, setSelectedPoint] = useState<IPoint>();
  const coordinates = useRef({ x: 0, y: 0 });

  const onClose = useCallback(() => {
    setSelectedPoint(undefined);
  }, []);

  useFocusEffect(
    useCallback(() => {
      return () => setSelectedPoint(undefined);
    }, [])
  );

  return (
    <>
      <Body
        frontPoints={Object.values(frontPoints)}
        backPoints={Object.values(backPoints)}
        onPointPress={(point, e) => {
          coordinates.current = {
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY,
          };
          setSelectedPoint(point);
        }}
      />

      <MusclesGroupModal
        isOpen={!!selectedPoint}
        point={selectedPoint}
        onClose={onClose}
      />
    </>
  );
};
