import { Colors } from '../../theme/colors';
import { PrimaryButton } from 'components/buttons/PrimaryButton';
import React from 'react';
import { IButtonProps } from 'native-base';

export const LoginButton = (props: IButtonProps) => {
  return (
    <PrimaryButton
      w={220}
      bg={Colors.bgWhite}
      _hover={{
        bg: Colors.bgWhite,
        opacity: 0.8,
      }}
      _text={{
        color: Colors.black,
        fontWeight: 500,
      }}
      {...props}
    />
  );
};
