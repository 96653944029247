import { useEffect, useState } from 'react';
import { fetchAppConfig } from '../firebase/appConfig';
import { IAppConfig } from '../@types/api';
import DeviceInfo from 'react-native-device-info';
import { isWeb } from '../utils/constants';

export const useCheckAppVersion = () => {
  const [appConfig, setAppConfig] = useState<IAppConfig>();
  const currentVersion = DeviceInfo.getVersion();
  const minAppVersion = appConfig?.minVersion || '1.0.0';

  useEffect(() => {
    return fetchAppConfig(setAppConfig);
  }, []);

  return isWeb || currentVersion >= minAppVersion;
};
