import { ColorMode, StorageManager } from 'native-base';
import { COLOR_MODE_KEY, mmkv } from '../utils/storage';
import { isWeb } from '../utils/constants';

const webColorModeManager: StorageManager = {
  get: async () => {
    return localStorage.getItem(COLOR_MODE_KEY) as ColorMode;
  },
  set: async (value: ColorMode) => {
    const strValue = value ? value.toString() : '';
    localStorage.setItem(COLOR_MODE_KEY, strValue);
  },
};

const nativeColorModeManager = {
  get: async () => {
    return mmkv.getString(COLOR_MODE_KEY) as ColorMode;
  },
  set: async (value: ColorMode) => {
    const strValue = value ? value.toString() : '';
    mmkv.set(COLOR_MODE_KEY, strValue);
  },
};

export const colorModeManager = isWeb
  ? webColorModeManager
  : nativeColorModeManager;
