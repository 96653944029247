import React, { useEffect, useRef } from 'react';
import {
  Animated,
  ImageBackground,
  ScaledSize,
  useWindowDimensions,
} from 'react-native';
import { Column } from 'native-base';
import BodyFront from 'assets/muscles/body-front.png';
import BodyBack from 'assets/muscles/body-back.png';
import styled from 'styled-components/native';
import { useHeaderHeight } from '@react-navigation/elements';
import { useMenuDimensions } from '../hooks/useMenuDimensions';

type Props = {
  isBack: boolean;
  frontPoints?: React.ReactNode;
  backPoints?: React.ReactNode;
};
const imageRealDimensions = {
  width: 375,
  height: 1000,
};

export const BodyImage = ({ isBack, frontPoints, backPoints }: Props) => {
  const headerHeight = useHeaderHeight();
  const { menuSize } = useMenuDimensions();
  const windowDimensions = useWindowDimensions(); // 390px

  const animatedValue = useRef(new Animated.Value(0));
  const animation = animatedValue.current;
  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) {
      Animated.timing(animatedValue.current, {
        toValue: isBack ? 180 : 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
    }
    firstRender.current = false;
  }, [isBack]);

  const frontStyles = {
    transform: [
      {
        rotateY: animation.interpolate({
          inputRange: [0, 180],
          outputRange: ['0deg', '180deg'],
        }),
      },
    ],
    opacity: animation.interpolate({
      inputRange: [89, 90],
      outputRange: [1, 0],
      extrapolate: 'clamp',
    }),
  };
  const backStyles = {
    transform: [
      {
        rotateY: animation.interpolate({
          inputRange: [0, 180],
          outputRange: ['180deg', '0deg'],
        }),
      },
    ],
    opacity: animation.interpolate({
      inputRange: [89, 90],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
  };

  const style = getImageDimensions(
    headerHeight + (menuSize || 55),
    windowDimensions
  );

  return (
    <Column
      alignItems="center"
      justifyContent="center"
      w="full"
      flexGrow={1}
      _web={{
        mt: -10,
      }}
    >
      <StyledView
        style={[frontStyles]}
        pointerEvents={isBack ? 'none' : 'auto'}
      >
        <ImageBackground source={BodyFront} resizeMode="contain" style={style}>
          {frontPoints}
        </ImageBackground>
      </StyledView>
      <StyledView style={backStyles} pointerEvents={isBack ? 'auto' : 'none'}>
        <ImageBackground source={BodyBack} resizeMode="contain" style={style}>
          {backPoints}
        </ImageBackground>
      </StyledView>
    </Column>
  );
};

const getImageDimensions = (content: number, window: ScaledSize) => {
  const maxHeight = window.height - content - 32;
  const compWidth = window.width - 16;
  const compHeight =
    compWidth * (imageRealDimensions.height / imageRealDimensions.width);
  const height = Math.min(maxHeight, compHeight);
  const width =
    height * (imageRealDimensions.width / imageRealDimensions.height);
  return {
    height,
    width,
  };
};

const StyledView = styled(Animated.View)`
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
`;
