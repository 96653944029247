import { IFriend } from 'src/@types/api';
import { Row, Text } from 'native-base';
import { ProfileImage } from 'components/ProfileImage';
import React, { PropsWithChildren } from 'react';

type Props = { friend: IFriend };

export const FriendRow = ({ children, friend }: PropsWithChildren<Props>) => {
  return (
    <Row space={2} alignItems="center">
      <ProfileImage uri={friend.photoUrl} size="30px" mr={2} />
      <Text flex={1} numberOfLines={2}>
        {friend.name}
      </Text>
      {children}
    </Row>
  );
};
