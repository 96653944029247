import { useColorModeValue, useDisclose } from 'native-base';
import React from 'react';
import styled from 'styled-components/native';
import { Colors } from 'src/theme/colors';
import { SubscriptionModal } from 'components/modals/SubscriptionModal';
import { PremiumIcon } from 'components/icons/icons';

type Props = {
  hasPremium: boolean;
};
export const PremiumOverlay = ({ hasPremium }: Props) => {
  const opacity = useColorModeValue(0.4, 0.5);
  const { isOpen, onClose, onOpen } = useDisclose();

  if (hasPremium) {
    return null;
  }

  return (
    <>
      <Overlay colorOpacity={opacity} onPress={onOpen}>
        <PremiumIcon color={Colors.premium} size={10} />
      </Overlay>
      <SubscriptionModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const Overlay = styled.TouchableOpacity<{ colorOpacity: number }>`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: ${({ colorOpacity }) => `rgba(0, 0, 0, ${colorOpacity})`};
  z-index: 10;
  align-items: center;
  justify-content: center;
`;
