import { Colors } from 'src/theme/colors';
import { Row, useColorMode } from 'native-base';
import { hexToRGBA } from 'src/utils/colors';
import { useNavigation } from '@react-navigation/native';
import { Routes } from 'src/navigation/routes';
import { useRecoilState } from 'recoil';
import { currentRouteState, useAuthUser } from 'src/state/state';
import { AppWebStackScreenProps } from 'src/navigation/types/appWebStack';
import React from 'react';
import styled from 'styled-components';
import {
  BodyIcon,
  ChatIcon,
  DashboardIcon,
  GymIcon,
  ProblemsIcon,
} from 'components/icons/icons';
import { IMenuItem } from 'src/@types/types';

const menuItems: IMenuItem[] = [
  { target: Routes.Dashboard, Icon: DashboardIcon },
  { target: Routes.Chat, Icon: ChatIcon, loginRequired: true },
  { target: Routes.Exercises, Icon: GymIcon },
  { target: Routes.Muscles, Icon: BodyIcon },
  { target: Routes.Problems, Icon: ProblemsIcon },
  // {
  //   target: Routes.Friends,
  //   Icon: FriendsIcon,
  //   loginRequired: true,
  //   iconProps: { size: 7, ml: -1 },
  // },
];

export const SmallMenu = () => {
  const user = useAuthUser();
  const navigation =
    useNavigation<AppWebStackScreenProps<Routes.Exercises>['navigation']>();
  const { colorMode } = useColorMode();
  const [route] = useRecoilState(currentRouteState);

  const isActive = (target: IMenuItem['target']) => {
    return route === target;
  };

  const content = (
    <Row
      py={1}
      minW={120}
      borderRadius={40}
      shadow={1}
      bg={hexToRGBA(
        colorMode === 'light' ? Colors.bgDark : Colors.bgWhite,
        0.9
      )}
      justifyContent="space-evenly"
      alignItems="center"
    >
      {menuItems.map(({ target, Icon, iconProps, loginRequired }) => {
        const active = isActive(target);
        if (loginRequired && !user) {
          return null;
        }

        return (
          <StyledButton
            key={target}
            onClick={() => navigation.navigate(target)}
          >
            <Icon
              color={
                active
                  ? Colors.bgWhite
                  : colorMode === 'light'
                  ? Colors.blueLightest
                  : Colors.blueLight
              }
              size={6}
              zIndex={1000}
              {...iconProps}
            />
            <Background active={active} />
          </StyledButton>
        );
      })}
    </Row>
  );

  return (
    <Row position="fixed" bottom={4} justifyContent="center" w="100%">
      {content}
    </Row>
  );
};

const StyledButton = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 4px;
  :hover {
    opacity: 0.8;
  }
`;

const Background = styled.div<{ active: boolean }>`
  background: linear-gradient(90deg, #326bcc, #00a8f3);
  transform: ${({ active }) => (active ? 'scale(1)' : 'scale(0)')};
  transition: transform 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  outline: none;
`;
