import * as React from 'react';
import { useMemo } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import NotFoundScreen from 'components/screens/NotFoundScreen';
import { Routes } from 'src/navigation/routes';
import { AppWebStackParamList } from 'src/navigation/types/appWebStack';
import { DashboardScreen } from 'components/screens/DashboardScreen';
import { ExercisesScreen } from 'components/screens/ExercisesScreen';
import { ExerciseDetailScreen } from 'components/screens/ExerciseDetailScreen';
import { FavoritesScreen } from 'components/screens/FavoritesScreen';
import { useTranslation } from 'react-i18next';
import { ConversationsScreen } from 'components/screens/ConversationsScreen';
import { ConversationThreadsScreen } from 'components/screens/ConversationThreadsScreen';
import { MessagesScreen } from 'components/screens/MessagesScreen';
import { FriendsScreen } from 'components/screens/FriendsScreen';
import { ProfileScreen } from 'components/screens/ProfileScreen';
import { useAuthUser } from 'src/state/state';
import { MusclesScreen } from 'components/screens/MusclesScreen';
import { ProblemsScreen } from 'components/screens/ProblemsScreen';
import { MuscleDetailScreen } from 'components/screens/MuscleDetailScreen';
import { ProblemDetailScreen } from 'components/screens/ProblemDetailScreen';
import { ChatScreen } from 'components/screens/ChatScreen';
import { useBreakpointValue } from 'native-base';
import { PrivacyPoliciesScreen } from 'components/screens/PrivacyPoliciesScreen';
import { SubscriptionScreen } from 'components/screens/SubscriptionScreen';
import { ContactScreen } from 'components/screens/ContactScreen';
import { TermsOfUseScreen } from 'components/screens/TermsOfUseScreen';
import { FavoriteMusclesScreen } from 'components/screens/FavoriteMusclesScreen';

const Stack = createNativeStackNavigator<AppWebStackParamList>();

export const AppWebStack = () => {
  const { t } = useTranslation();
  const user = useAuthUser();
  const headerShown = false; // useBreakpointValue([true, true, false]);
  const emptyBtn = useMemo(() => ({ headerLeft: () => null }), []);
  const isSmall = useBreakpointValue([true, false, false]);

  return (
    <Stack.Navigator
      initialRouteName={user ? Routes.Dashboard : Routes.Exercises}
      screenOptions={{
        headerShown,
      }}
    >
      <Stack.Screen
        name={Routes.Dashboard}
        component={DashboardScreen}
        options={{
          title: t('routes.dashboard'),
          headerShown: false,
          ...emptyBtn,
        }}
      />
      {user && (
        <>
          <Stack.Screen
            name={Routes.Favorites}
            component={FavoritesScreen}
            options={{
              title: t('routes.favorites'),
            }}
          />
          <Stack.Screen
            name={Routes.FavoriteMuscles}
            component={FavoriteMusclesScreen}
            options={{
              title: t('routes.favoriteMuscles'),
            }}
          />
          <Stack.Screen
            name={Routes.Chat}
            component={ChatScreen}
            getId={({ params }) =>
              `${params?.conversationId}-${params?.threadId}`
            }
            options={{
              title: t('routes.chat'),
              ...emptyBtn,
            }}
          />
          {isSmall && (
            <>
              <Stack.Screen
                name={Routes.Conversations}
                component={ConversationsScreen}
                options={{
                  title: t('routes.chat'),
                  ...emptyBtn,
                }}
              />
              <Stack.Screen
                name={Routes.ConversationThreads}
                component={ConversationThreadsScreen}
                options={{
                  title: t('routes.chat'),
                }}
              />
              <Stack.Screen
                name={Routes.Messages}
                component={MessagesScreen}
                options={{
                  title: t('routes.chat'),
                }}
              />
            </>
          )}
          <Stack.Screen
            name={Routes.Profile}
            component={ProfileScreen}
            options={{
              title: t('routes.profile'),
            }}
          />
          <Stack.Screen
            name={Routes.Friends}
            component={FriendsScreen}
            options={{
              title: t('routes.friends'),
            }}
          />
          <Stack.Screen
            name={Routes.Subscription}
            component={SubscriptionScreen}
            options={{ title: t('routes.subscription') }}
          />
        </>
      )}
      <Stack.Screen
        name={Routes.Exercises}
        component={ExercisesScreen}
        options={{
          title: t('routes.exercises'),
          ...emptyBtn,
        }}
      />
      <Stack.Screen
        name={Routes.ExerciseDetail}
        component={ExerciseDetailScreen}
      />
      <Stack.Screen
        name={Routes.Muscles}
        component={MusclesScreen}
        options={{
          title: t('routes.muscles'),
          ...emptyBtn,
        }}
      />
      <Stack.Screen
        name={Routes.MuscleDetail}
        component={MuscleDetailScreen}
        getId={({ params }) => params.id}
      />
      <Stack.Screen
        name={Routes.Contact}
        component={ContactScreen}
        options={{
          title: t('routes.contact'),
        }}
      />
      <Stack.Screen
        name={Routes.PrivacyPolicy}
        component={PrivacyPoliciesScreen}
        options={{
          title: t('routes.privacy'),
        }}
      />
      <Stack.Screen
        name={Routes.TermsOfUse}
        component={TermsOfUseScreen}
        options={{
          title: t('routes.termsOfUse'),
        }}
      />
      <Stack.Screen
        name={Routes.Problems}
        component={ProblemsScreen}
        options={{
          title: t('routes.problems'),
          ...emptyBtn,
        }}
      />
      <Stack.Screen
        name={Routes.ProblemDetail}
        component={ProblemDetailScreen}
        getId={({ params }) => params.id}
      />
      <Stack.Screen
        name={Routes.NotFound}
        component={NotFoundScreen}
        options={{ title: 'Oops!' }}
      />
    </Stack.Navigator>
  );
};
