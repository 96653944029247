import styled from 'styled-components/native';
import ImagePlaceholder from '../../../assets/exercisePlaceholder.jpg';
import { useExerciseImageUrl } from 'src/hooks/useExerciseImageUrl';

type Props = {
  id: string;
};

export const ExerciseImage = ({ id }: Props) => {
  const uri = useExerciseImageUrl(id);

  return (
    <Background
      resizeMode="cover"
      source={{ uri }}
      defaultSource={ImagePlaceholder}
    />
  );
};

const Background = styled.ImageBackground`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
