import styled from 'styled-components/native';
import ImagePlaceholder from '../../../assets/exercisePlaceholder.jpg';
import { musclesImages } from '../../data/muscles/musclesImages';

type Props = {
  id: string;
};

export const MuscleImage = ({ id }: Props) => {
  return (
    <Background
      resizeMode="cover"
      source={musclesImages[id]}
      defaultSource={ImagePlaceholder}
    />
  );
};

const Background = styled.ImageBackground`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
