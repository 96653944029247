import { Screen } from 'components/layout/Screen';
import { useTranslation } from 'react-i18next';
import { PolicyEN } from 'components/policyAndTerms/policy/PolicyEN';
import { PolicySK } from 'components/policyAndTerms/policy/PolicySK';
import { isSlovak } from '../../utils/language';

export const PrivacyPoliciesScreen = () => {
  const { t } = useTranslation();
  return (
    <Screen title={t('routes.privacy')}>
      {isSlovak() ? <PolicySK /> : <PolicyEN />}
    </Screen>
  );
};
