import { TextButtonSecondary } from 'components/buttons/TextButton';
import { SendIcon } from 'components/icons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onPress: () => void;
};

export const SendMessageButton = ({ onPress }: Props) => {
  const { t } = useTranslation();

  return (
    <TextButtonSecondary
      colorScheme="primary"
      leftIcon={<SendIcon />}
      onPress={onPress}
    >
      {t('general.send')}
    </TextButtonSecondary>
  );
};
